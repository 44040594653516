import { taskConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "./";

export const taskActions = {
  addTask,
  getProjectTasks,
  editTask,
  editTasks,
  deleteTask,
  changeOrder
};

//################################################################################
// Add Task
//################################################################################

function addTask(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("createTask", data).then(
      response => {
        if (response.success === true) {
          if (response.tasks) dispatch(success(response.tasks));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(tasks) {
    return { type: taskConstants.ADD_TASK_SUCCESS, payload: tasks };
  }
  // function failure(error) {
  //   return { type: taskConstants.ADD_TASK_FAILURE, payload: error };
  // }
}

//################################################################################
// Add Task
//################################################################################

function getProjectTasks(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getProjectTasks", data).then(
      response => {
        if (response.success === true) {
          dispatch(success(response.tasks));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(tasks) {
    return { type: taskConstants.GET_PROJECT_TASKS_SUCCESS, payload: tasks };
  }
  // function failure(error) {
  //   return { type: taskConstants.GET_PROJECT_TASKS_FAILURE, payload: error };
  // }
}

//################################################################################
// Edit Task
//################################################################################

function editTask(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editTask", data).then(
      response => {
        if (response.success === true) {
          if (response.tasks) dispatch(success(response.tasks));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(task) {
    return { type: taskConstants.EDIT_TASK_SUCCESS, payload: task };
  }
}

//################################################################################
// Edit Task
//################################################################################


function editTasks(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editTasksbasedOnDependencies", data).then(
      response => {
        if (response.success === true) {
          if (response.tasks) dispatch(success(response.tasks));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(task) {
    return { type: taskConstants.EDIT_TASK_SUCCESS, payload: task };
  }
}

//################################################################################
// Delete Task
//################################################################################

function deleteTask(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteTask", data).then(
      response => {
        if (response.success === true) {
          if (response.tasks) dispatch(success(response.tasks));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(tasks) {
    return { type: taskConstants.DELETE_TASK_SUCCESS, payload: tasks };
  }
  // function failure(error) {
  //   return { type: taskConstants.DELETE_TASK_FAILURE, payload: error };
  // }
}

//################################################################################
// change order
//################################################################################

function changeOrder(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("changeTaskOrder", data).then(
      response => {
        if (response.success !== true) {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  // function failure(error) {
  //   return { type: taskConstants.EDIT_TASK_FAILURE, payload: error };
  // }
}
