import {
  projectConstants,
  attachemntConstants,
  issueConstants,
  costConstants,
  taskConstants,
  noteConstants,
} from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "./";
import { history } from "../helpers";

export const projectActions = {
  getAllProjects,
  addProject,
  getProject,
  getProjectUsers,
  getRootProjects,
  getSubProjects,
  getAllArchitects,
  updateParentLocally,
  editProject,
  deleteProject,
  copyProjectPattern,
  changeProjectOrder,
  addUserAndAssign,
  clearingReduxData,
};

//################################################################################
// GET All Projects
//################################################################################

function getAllProjects() {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    dispatch(progressBarActions.projectRequestStart());
    generalServices.service("getAllProjects").then(
      (response) => {
        if (response.success === true) {
          dispatch(success(response.projects));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
          dispatch(progressBarActions.projectRequestEnd());
        }
        dispatch(progressBarActions.stop());
        dispatch(progressBarActions.projectRequestEnd());
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(progressBarActions.projectRequestEnd());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(projects) {
    return {
      type: projectConstants.GET_ALL_PROJECTS_SUCCESS,
      payload: projects,
    };
  }
  // function failure(error) {
  //   return { type: projectConstants.GET_ALL_PROJECTS_FAILURE, payload: error };
  // }
}

//################################################################################
// Add Project
//################################################################################

function addProject(data) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("createProject", data).then(
      (response) => {
        if (response.success === true) {
          dispatch(success(response.project));
          // if (response.savedParent)
          //   dispatch(updateParent(response.savedParent));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(project) {
    return { type: projectConstants.ADD_PROJECTS_SUCCESS, payload: project };
  }
  // function updateParent(parent) {
  //   return { type: projectConstants.UPDATE_PARENT_LOCALLY, payload: parent };
  // }
  // function failure(error) {
  //   return { type: projectConstants.ADD_PROJECTS_FAILURE, payload: error };
  // }
}

//################################################################################
// Get Project
//################################################################################

function getProject(data) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    // setTimeout(() => {
    generalServices.service("getProject", data).then(
      (response) => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.project));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
          dispatch(
            snackBarActions.snackBarError("This Project Does Not Exist")
          );
        }
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(project) {
    return { type: projectConstants.GET_PROJECT_SUCCESS, payload: project };
  }
  // function failure(error) {
  //   return { type: projectConstants.GET_PROJECT_FAILURE, payload: error };
  // }
}

//################################################################################
// Get Project Users
//################################################################################

function getProjectUsers(data) {
  return (dispatch) => {
    dispatch(request());
    dispatch(progressBarActions.start());
    // setTimeout(() => {
    generalServices.service("getProjectUsersInfo", data).then(
      (response) => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.projectUsers));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    // }, 5000);
  };
  function request() {
    return { type: projectConstants.GET_PROJECT_USERS_REQUEST };
  }
  function success(users) {
    return { type: projectConstants.GET_PROJECT_USERS_SUCCESS, payload: users };
  }
  // function failure(error) {
  //   return { type: projectConstants.GET_PROJECT_USERS_FAILURE, payload: error };
  // }
}

//################################################################################
// GET Root Projects
//################################################################################

function getRootProjects() {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("getRootProjects").then(
      (response) => {
        if (response.success === true) {
          dispatch(success(response.projects));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(projects) {
    return {
      type: projectConstants.GET_ROOT_PROJECTS_SUCCESS,
      payload: projects,
    };
  }
  // function failure(error) {
  //   return { type: projectConstants.GET_ROOT_PROJECTS_FAILURE, payload: error };
  // }
}

//################################################################################
// GET Sub Projects
//################################################################################

function getSubProjects(projectId) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    dispatch(request());
    generalServices.service("getRootProjects", projectId).then(
      (response) => {
        if (response.success === true) {
          dispatch(success(response.projects));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function request() {
    return {
      type: projectConstants.GET_SUB_PROJECTS_REQUEST,
      payload: [projectId, undefined],
    };
  }
  function success(projects) {
    return {
      type: projectConstants.GET_SUB_PROJECTS_SUCCESS,
      payload: [projectId, projects],
    };
  }
  // function failure(error) {
  //   return { type: projectConstants.GET_SUB_PROJECTS_FAILURE, payload: error };
  // }
}

//################################################################################
// GET All Architects
//################################################################################

function getAllArchitects() {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("getAllArchitects").then(
      (response) => {
        if (response.success === true) {
          dispatch(success(response.architects));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(Architects) {
    return {
      type: projectConstants.GET_ALL_PROJECT_ARCHITECT_SUCCESS,
      payload: Architects,
    };
  }
  // function failure(error) {
  //   return {
  //     type: projectConstants.GET_ALL_PROJECT_ARCHITECT_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Update Parent Local
//################################################################################

function updateParentLocally(parent, subprojects) {
  return {
    type: projectConstants.UPDATE_PARENT_LOCALLY,
    payload: [parent, subprojects],
  };
}

//################################################################################
// Edit Project
//################################################################################

function editProject(project, allProjects = []) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("editProject", project).then(
      (response) => {
        if (response.success === true) {
          if (allProjects.length > 1) dispatch(editAndOrder(allProjects));
          else dispatch(success(response.project));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(project) {
    return { type: projectConstants.EDIT_PROJECTS_SUCCESS, payload: project };
  }
  function editAndOrder(allProjects) {
    return {
      type: projectConstants.EDIT_AND_ORDER_SUCCESS,
      payload: allProjects,
    };
  }
  // function failure(error) {
  //   return { type: projectConstants.EDIT_PROJECTS_FAILURE, payload: error };
  // }
}

//################################################################################
// Change Project Order
//################################################################################

function changeProjectOrder(project, allProjects = []) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("changeProjectOrder", project).then(
      (response) => {
        if (response.success === true) {
          if (allProjects.length > 1) dispatch(editAndOrder(allProjects));
          else dispatch(success(response.project));
          dispatch(
            snackBarActions.snackBarSuccess("Saved changes successfully")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(project) {
    return {
      type: projectConstants.CHANGE_PROJECT_ORDER_SUCCESS,
      payload: project,
    };
  }
  function editAndOrder(allProjects) {
    return {
      type: projectConstants.EDIT_AND_ORDER_SUCCESS,
      payload: allProjects,
    };
  }
  // function failure(error) {
  //   return {
  //     type: projectConstants.CHANGE_PROJECT_ORDER_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Delete Project
//################################################################################

function deleteProject(projectId) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteProject", projectId).then(
      (response) => {
        if (response.success === true) {
          dispatch(success());
          history.push("/");
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success() {
    return { type: projectConstants.DELETE_PROJECTS_SUCCESS };
  }
  // function failure(error) {
  //   return { type: projectConstants.DELETE_PROJECTS_FAILURE, payload: error };
  // }
}

//################################################################################
// Copy Project Pattern
//################################################################################

function copyProjectPattern(data) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("copyProjectPattern", data).then(
      (response) => {
        if (response.success === true) {
          dispatch(success(response.newProject));
          dispatch(
            snackBarActions.snackBarSuccess(
              "Project copy added to end of the list successfully"
            )
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(newProject) {
    return {
      type: projectConstants.COPY_PROJECT_SUCCESS,
      payload: newProject,
    };
  }
  // function failure(error) {
  //   return {
  //     type: projectConstants.COPY_PROJECT_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Add User And Assign
//################################################################################

function addUserAndAssign(data) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("createUserAndAssign", data).then(
      (response) => {
        if (response.success === true) {
          dispatch(success(response.projectUser));
          dispatch(
            snackBarActions.snackBarSuccess("User added successfully!!")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };

  function success(user) {
    return {
      type: projectConstants.ADD_USER_AND_ASSIGN_SUCCESS,
      payload: { ...user, projectList: [] },
    };
  }
  // function failure(error) {
  //   return {
  //     type: projectConstants.ADD_USER_AND_ASSIGN_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Add User And Assign
//################################################################################

function clearingReduxData(data) {
  return (dispatch) => {
    dispatch(clearingProjectData());
    dispatch(clearingDocumentsData());
    dispatch(clearingIssuesData());
    dispatch(clearingCostsData());
    dispatch(clearingScheduleData());
    dispatch(clearingNotesData());
  };
  function clearingProjectData() {
    return {
      type: projectConstants.CLEARING_PROJECTS_DATA,
    };
  }
  function clearingDocumentsData() {
    return {
      type: attachemntConstants.CLEARING_DOCUMENTS_DATA,
    };
  }
  function clearingIssuesData() {
    return {
      type: issueConstants.CLEARING_ISSUES_DATA,
    };
  }
  function clearingCostsData() {
    return {
      type: costConstants.CLEARING_COSTS_DATA,
    };
  }
  function clearingScheduleData() {
    return {
      type: taskConstants.CLEARING_TASK_DATA,
    };
  }
  function clearingNotesData() {
    return {
      type: noteConstants.CLEARING_NOTES_DATA,
    };
  }
}
