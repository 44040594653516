import { taskConstants } from "../constants";

export function tasks(state = {}, action) {
  switch (action.type) {
    case taskConstants.ADD_TASK_SUCCESS:
      return { ...state, tasks: action.payload };

    case taskConstants.GET_PROJECT_TASKS_SUCCESS:
      return { ...state, tasks: action.payload };

    case taskConstants.EDIT_TASK_SUCCESS: {
      return { ...state, tasks: action.payload };
    }

    case taskConstants.DELETE_TASK_SUCCESS: {
      return { ...state, tasks: action.payload };
    }

    case taskConstants.CLEARING_TASK_DATA:
      return {};

    default:
      return state;
  }
}
