// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { attachmentActions } from "../../actions";
import { attachmentUserActions } from "../../userActions";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// Components //
import { DocumentListItem, AddNewFolder, AlertModalExistFile } from "./";
import DeleteModal from "../Modal/DeleteModal";
import ThemeContext from "../ContextApi/Context";

// Reusable Functions //
// Styles //
import { Colors } from "../../styles/colors";

class DocumentList extends React.PureComponent {
  static contextType = ThemeContext;
  state = {
    openDeleteModal: false,
    openAlertModal: false,
    selectedOptionForDelete: {},
    isAddNewFolder: false,
    pageCapacity: 7,
    pageNumber: 0,
    onItem: {},
    BeforeAlertModalConfirmation: {}
  };

  componentDidMount() {
    this.props.attachments &&
      this.setState({
        allDocuments: this.props.attachments
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.attachments !== this.props.attachments)
      this.setState({
        allDocuments: this.props.attachments
      });
  }

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
        openDeleteModal: true,
        selectedOptionForDelete
      })
      : this.setState({
        openDeleteModal: false
      });
  };

  handleAlertModal = () => {
    this.setState({
      openAlertModal: false
    });
  };

  handleAddNewFolder = ({ name, attachmentNote, parentAttachment = null }) => {
    const { dispatch, role } = this.props;
    const projectId = this.context;

    role === "adminFlag"
      ? dispatch(
        attachmentActions.addFolder({
          projectId,
          name,
          attachmentNote,
          parentAttachment,
          projectDocument: true
        })
      )
      : dispatch(
        attachmentUserActions.addFolder({
          projectId,
          name,
          attachmentNote,
          parentAttachment,
          projectDocument: true
        })
      );

    this.props.handleToggleAddNewFolder();
  };

  handleFilterArray = mainObject => {
    const { pageFilter } = this.props;
    return pageFilter === ""
      ? mainObject.filter(item => !item.mainVersionId && !item.parentAttachment)
      : mainObject.filter(item =>
        item.name.toLowerCase().includes(pageFilter.toLowerCase())
      );
  };

  onDragOver = ({ currentTarget }) => {
    if (
      this.state.onItem.id !== currentTarget.id ||
      this.state.onItem.getAttribute("index") !==
      currentTarget.getAttribute("index")
    ) {
      this.setState(prevState => ({
        onItem: currentTarget,
        onItemIndex: Number(currentTarget.getAttribute("index")),
        previousItem: prevState.onItem
      }));
    }
  };

  handleOnDragStart = draggedItem => {
    this.setState({
      draggedItem
    });
  };

  hanldeOnDragOver = onItem => {
    if (
      this.state.onItem.id !== onItem.id ||
      this.state.onItem.getAttribute("index") !== onItem.getAttribute("index")
    ) {
      this.setState(prevState => ({
        onItem,
        onItemIndex: Number(onItem.getAttribute("index")),
        previousItem: prevState.onItem
      }));
    }
  };

  handleOnDragEnd = () => {
    const { previousItem, allDocuments, draggedItem, onItem } = this.state;
    const projectId = this.context;

    // for preventing actions trigger drag on its own
    if (onItem && previousItem && onItem.id !== draggedItem.id) {
      let newParentId;
      if (
        onItem.getAttribute("id") === "middle" ||
        onItem.getAttribute("id") === "middleTop"
      )
        newParentId = onItem.getAttribute("parentattachment");
      else newParentId = onItem.getAttribute("id");
      const draggedItemIndex = draggedItem.getAttribute("index");
      const draggedItemId = draggedItem.id;
      const oldParentId = draggedItem.getAttribute("parentattachment");
      let onItemIndex = Number(onItem.getAttribute("index"));
      ///// prevent user to move parent into its child
      if (
        this.state.allDocuments
          .filter(document => document.parentAttachment === draggedItemId)
          .map(document => document._id)
          .includes(newParentId)
      ) {
        return;
      }

      /// duplication preventing
      if (onItem.parentAttachment === draggedItemId) return;
      let tempAllDocument = [];
      for (var j = 0; j < allDocuments.length; j++) {
        tempAllDocument.push({ ...allDocuments[j] });
      }
      if (
        tempAllDocument.find(document => document._id === onItem.id) &&
        tempAllDocument.find(document => document._id === onItem.id)
          .isFolder === false
      )
        return;

      let count = -1;
      let onItemInArrayIndex;

      if (onItem.id !== "middle" && onItem.id !== "middleTop") onItemIndex = 0;
      for (var i = 0; i < tempAllDocument.length; i++) {
        if (
          tempAllDocument[i].parentAttachment === newParentId ||
          (!tempAllDocument[i].parentAttachment && !newParentId)
        ) {
          count = count + 1;
        }
        onItemInArrayIndex = i;
        if (count === onItemIndex) {
          break;
        }
      }

      let childsNumber = tempAllDocument.filter(
        document =>
          document.parentAttachment === newParentId ||
          (!document.parentAttachment && !newParentId)
      ).length;

      onItemInArrayIndex =
        onItemIndex === childsNumber
          ? onItemInArrayIndex + 1
          : onItemInArrayIndex;

      const draggedItemInArrayIndex = tempAllDocument.findIndex(
        document => document._id === draggedItemId
      );
      tempAllDocument[draggedItemInArrayIndex].parentAttachment = newParentId;

      tempAllDocument.splice(
        onItemInArrayIndex,
        0,
        tempAllDocument[draggedItemInArrayIndex]
      );
      if (draggedItemInArrayIndex < onItemInArrayIndex)
        tempAllDocument.splice(draggedItemInArrayIndex, 1);
      else tempAllDocument.splice(draggedItemInArrayIndex + 1, 1);

      // this.setState({ allDocuments: [...tempAllDocument] })
      let order;
      if (oldParentId === newParentId || (!oldParentId && !newParentId))
        if (draggedItemIndex > onItemIndex) order = onItemIndex + 1;
        else order = onItemIndex;
      else {
        order = onItemIndex + 1;
      }
      if (onItem.id !== "middle" && onItem.id !== "middletop") order = 1;

      if (newParentId === oldParentId) {
        this.props.role === "adminFlag"
          ? this.props.dispatch(
            attachmentActions.changeAttachmentOrder(
              {
                projectId,
                order,
                attachmentId: draggedItemId,
                parentAttachment: "null"
              },
              tempAllDocument
            )
          )
          : this.props.dispatch(
            attachmentUserActions.changeAttachmentOrder(
              {
                projectId,
                order,
                attachmentId: draggedItemId,
                parentAttachment: "null"
              },
              tempAllDocument
            )
          );
      } else {
        const draggedItemNameInArray = allDocuments.find(
          document => document._id === draggedItemId
        ).name;

        if (
          this.state.allDocuments
            .filter(
              document =>
                document.parentAttachment === newParentId ||
                (!document.parentAttachment && !newParentId)
            )
            .map(document => document.name)
            .includes(draggedItemNameInArray)
        ) {
          this.setState({
            openAlertModal: true,
            BeforeAlertModalConfirmation: {
              attachmentId: draggedItemId,
              projectId,
              parentAttachment: newParentId ? newParentId : "null",
              projectDocument: true,
              order: order + 1,
              tempAllDocument
            }
          });
          return;
        }
        this.props.role === "adminFlag"
          ? this.props.dispatch(
            attachmentActions.editAttachment(
              {
                attachmentId: draggedItemId,
                parentAttachment: newParentId ? newParentId : "null",
                projectDocument: true,
                order: order,
                projectId
              },
              tempAllDocument
            )
          )
          : this.props.dispatch(
            attachmentUserActions.editAttachment(
              {
                attachmentId: draggedItemId,
                parentAttachment: newParentId ? newParentId : "null",
                projectDocument: true,
                order: order,
                projectId
              },
              tempAllDocument
            )
          );
      }
    }
  };

  render() {
    const {
      classes = {},
      isAddNewFolder,
      handleToggleAddNewFolder,
      role
    } = this.props;
    const { allDocuments = [], previousItem } = this.state;
    const projectId = this.context;
    return (
      <Grid container className={classes.container}>
        <div className={classes.table}>
          <div className={classes.thead}>
            <div className={classes.nameTh}>Name</div>
            <div className={classes.noteTh}>Note</div>
            <div className={classes.dateTh}>Date</div>
            <div
              className={classes.actionsTh}
              style={role === "Project-Client" ? { width: "90px" } : {}}
            >
              Actions
            </div>
          </div>

          {allDocuments[0] || isAddNewFolder ? (
            <div className={classes.tbody}>
              {isAddNewFolder && (
                <AddNewFolder
                  handleAddNewFolder={this.handleAddNewFolder}
                  dynamicMargin={0}
                  dynamicRowColor={100}
                  handleToggleAddNewFolder={handleToggleAddNewFolder}
                />
              )}
              {this.handleFilterArray(allDocuments).map((documents, index) => (
                <DocumentListItem
                  key={index}
                  index={index}
                  documents={documents}
                  allDocuments={allDocuments}
                  children={
                    allDocuments.filter(
                      document => document.parentAttachment === documents._id
                    ).length
                  } //passing children to force pureComponent rerender
                  dynamicMargin={0}
                  dynamicRowColor={100}
                  handleDeleteModal={this.handleDeleteModal}
                  parentAttachment={null}
                  handleSetPaginationToZero={this.handleSetPaginationToZero}
                  handlePushToFirstPage={this.handlePushToFirstPage}
                  handleOnDragStart={this.handleOnDragStart}
                  hanldeOnDragOver={this.hanldeOnDragOver}
                  handleOnDragEnd={this.handleOnDragEnd}
                  previousItem={previousItem}
                  role={role}
                />
              ))}
            </div>
          ) : (
              <div className={classes.noData}>No Document</div>
            )}
        </div>
        {/* ////////////////////////////////////////////////////////////// */}
        <DeleteModal
          open={this.state.openDeleteModal}
          selectedOptionForDelete={{
            isFolder: this.state.selectedOptionForDelete.isFolder,
            attachmentId: this.state.selectedOptionForDelete._id,
            parentAttachment: this.state.selectedOptionForDelete
              .parentAttachment
          }}
          adminAction={attachmentActions.deleteAttachment}
          userAction={attachmentUserActions.deleteAttachment}
          handleDeleteModal={this.handleDeleteModal}
          //optional
          modalTitle={"Delete Documents"}
          optionNameForDelete={this.state.selectedOptionForDelete.name}
          messageText={
            this.state.selectedOptionForDelete.children &&
              this.state.selectedOptionForDelete.children[0]
              ? `This action will delete the entire of folder and subfolders, are you sure you want to delete?`
              : null
          }
        />

        <AlertModalExistFile
          open={this.state.openAlertModal}
          selectedOptionForAlert={{
            attachmentId: this.state.BeforeAlertModalConfirmation.attachmentId,
            parentAttachment: this.state.BeforeAlertModalConfirmation
              .parentAttachment,
            order: this.state.BeforeAlertModalConfirmation.order,
            projectId,
            projectDocument: true,
            tempAllDocument: this.state.BeforeAlertModalConfirmation
              .tempAllDocument
          }}
          adminAction={attachmentActions.editAttachment}
          userAction={attachmentUserActions.editAttachment}
          handleAlertModal={this.handleAlertModal}
          //optional
          modalTitle={"SAME FILE EXISTS"}
          optionNameForDelete={this.state.selectedOptionForDelete.name}
          messageText={"The destination already has a file with same name"}
        />
      </Grid>
    );
  }
}

const Styles = {
  container: {
    marginTop: "5px",
    paddingRight: "10px"
  },
  table: {
    width: "100%"
  },
  thead: {
    color: Colors.darkBlue,
    flex: 1,
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    " & > div": {
      padding: "6px 10px",
      margin: "0 3px",
      boxSizing: "border-box"
    }
  },
  nameTh: {
    textAlign: "left",
    width: "30%"
  },
  noteTh: {
    flex: 7,
    textAlign: "left"
  },
  dateTh: {
    width: "90px",
    textAlign: "center"
  },
  actionsTh: {
    width: "170px",
    textAlign: "center"
  },
  tbody: {
    height: "calc(100vh - 270px)",
    overflow: "overlay",
    paddingRight: "7px"
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "35px",
    color: "#00000036",
    fontWeight: "100!important",
    padding: "10px 50px 3px 0",
    borderRadius: "5px",
    marginTop: "0",
    // flexGrow: 100,
    marginBottom: "25px",
    height: "230px"
  },
  // ////////////////////////// pagination ///////////////////////////////////
  pageNumbersHolder: {
    padding: "5px 0px",
    display: "flex",
    flex: 1,
    flexWrap: "no-wrap",
    overflowX: "auto"
  },
  nextButton: {
    padding: "5px 0px"
  },
  pagination: {
    padding: "0px"
  },
  borderTop: {
    borderTop: "1px solid #e1e1e1"
  },
  pageBtn: {
    fontSize: "14px",
    minWidth: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "15px",
    color: "#00000040",
    marginRight: "5px"
  },
  selectedPage: {
    color: "#155794",
    backgroundColor: "rgba(0, 0, 0, 0.03)"
  }
};

const mapStateToProps = state => {
  return {
    attachments: state.attachments,
    role: state.projects.project ? state.projects.project.role : undefined,
    pageFilter: state.filters.pageFilter
  };
};

export default connect(mapStateToProps)(withStyles(Styles)(DocumentList));
