import React from "react";
import { IssueItem } from "./IssueItem";
import { connect } from "react-redux";
import { issueActions } from "../../actions";
import { issueUserActions } from "../../userActions";

// #####[ MATERIAL COMPONENTS ]#################################################
import { withStyles } from "@material-ui/core";

////////////////////modal and its content ////////////////////
import HOCModal from "../Modal/Modal";
import { IssueTaskModalContent } from "./IssueTaskModalContent";

import DeleteModal from "../Modal/DeleteModal";
import ThemeContext from "../ContextApi/Context";

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root: {
    boxSizing: "border-box",
    padding: "10px 0px",
    display: "flex",
    width: "100%"
  },
  headerItem: {
    boxSizing: "border-box",
    color: "#155794",
    padding: "0px 5px",
    margin: "0px 3px",
    fontSize: "15px"
  },
  listHolder: {
    marginBottom: "40px"
  },

  ////////////////////////////// pagination ////////////////////////////////////

  pagination: {
    padding: "0px"
  },
  borderTop: {
    borderTop: "1px solid #e1e1e1"
  },
  issuesLabel: {
    padding: "5px 4px",
    fontSize: "20px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    "& >div": {
      height: "2px",
      flexGrow: 100,
      backgroundColor: "gray",
      marginLeft: "15px",
      marginTop: "5px"
    }
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "35px",
    color: "#00000036",
    fontWeight: "100!important",
    padding: "10px 50px 3px 0",
    borderRadius: "5px",
    marginTop: "0",
    // flexGrow: 100,
    marginBottom: "25px",
    height: "230px"
  }
});

// #############################################################################
// component
// #############################################################################

class IssueList extends React.PureComponent {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.state = {
      pageCapacity: props.pageCapacity ? props.pageCapacity : 7,
      pageNumber: 0,
      alertDeleteIssue: false,
      selectedIssue: {},
      dataProps: [],
      data: [],
      infoModalIsShowed: false,
      selectedOptionForDelete: {},
      openDeleteModal: false,
      value: 0
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { data } = props;
    // return { data }
    const { dataProps } = state;
    if (dataProps !== data) {
      // const rootData = data.filter(issue => issue.parentIssue === null);
      return {
        dataProps: data,
        data
        // data: rootData,
      };
    } else return null;
  }

  // #######################################################################################
  // ####################              Dialog: Delete              ####################
  // #######################################################################################

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
          openDeleteModal: true,
          selectedOptionForDelete
        })
      : this.setState({
          openDeleteModal: false
        });
  };

  // ###########################################################################
  // handle Delete
  // ###########################################################################

  handleChangePage = number => () => {
    this.setState({ pageNumber: number });
  };

  // ###########################################################################
  // handle Delete
  // ###########################################################################

  filterArray = (array = []) => {
    const { pageFilter } = this.props;
    const keyWordLowerCase = pageFilter.toLowerCase();
    const filteredArray = array.filter(item => {
      const name = item.name
        ? item.name.toLowerCase().includes(keyWordLowerCase)
        : false;
      const description = item.description
        ? item.description.toLowerCase().includes(keyWordLowerCase)
        : false;

      const date = new Date(item.dueDate);

      const dueDateMonth = (date.getMonth() + 1)
        .toString()
        .includes(keyWordLowerCase);
      const dueDateYear = date
        .getFullYear()
        .toString()
        .includes(keyWordLowerCase);
      const dueDateDay = date
        .getDate()
        .toString()
        .includes(keyWordLowerCase);

      const fullDate = (
        (date.getMonth() + 1).toString() +
        "-" +
        date.getDate().toString() +
        "-" +
        date.getFullYear().toString()
      ).includes(keyWordLowerCase);

      const assignedTo = (
        item.assignedTo.firstName +
        " " +
        item.assignedTo.lastName
      )
        .toLowerCase()
        .includes(keyWordLowerCase);
      const importantWord = "important";
      const important = item.important
        ? importantWord.includes(keyWordLowerCase)
        : false;
      return (
        name ||
        description ||
        dueDateMonth ||
        dueDateYear ||
        dueDateDay ||
        fullDate ||
        assignedTo ||
        important
      );
    });
    return filteredArray;
  };

  // ###########################################################################
  // Drag And Drop
  // ###########################################################################

  onDragStart = (e, index) => {
    const { data } = this.state;
    this.draggedItem = data[index];
    e.dataTransfer.setData("text/html", e.target.id);
    e.target.style.opacity = "0.4";
  };

  onDragOver = (e, index) => {
    // const { dragChild } = this.state;
    e.preventDefault();
    const { data } = this.state;
    const draggedOverItem = data[index];

    let onDragOverItems = data.filter(item => item !== this.draggedItem);
    if (this.draggedItem === draggedOverItem) {
      return;
    }
    // if(!dragChild) {
    // add the dragged item after the dragged over item
    onDragOverItems.splice(index, 0, this.draggedItem);
    this.setState({ data: onDragOverItems });
    // }
  };

  onDragEnd = (e, index) => {
    e.preventDefault();
    e.target.style.opacity = "1";
    this.draggedIdx = null;
    const projectId = this.context;
    const { data } = this.state;
    const draggedEndItem = data[index];
    const issueTaskId = draggedEndItem._id;
    this.dispatchChangeIssueOrder(projectId, issueTaskId, index + 1);
    this.setState({ dragChild: false });
  };

  handlerInfoModal = bool => {
    this.setState({
      infoModalIsShowed: !!bool
    });
  };

  setClickedRowDataToState = clickedRowDataId => {
    this.setState({
      clickedRowDataId
    });
  };

  handleEdit = issue => {
    const { dispatch, role } = this.props;
    const projectId = this.context;
    role === "adminFlag"
      ? dispatch(issueActions.editIssue({ ...issue, projectId }))
      : dispatch(issueUserActions.editIssue({ ...issue, projectId }));
  };

  // ###########################################################################
  // Order
  // ###########################################################################

  dispatchChangeIssueOrder = (projectId, issueTaskId, order) => {
    const { dispatch, role } = this.props;
    role === "adminFlag"
      ? dispatch(
          issueActions.changeIssueOrder({
            projectId,
            issueTaskId,
            order
          })
        )
      : dispatch(
          issueUserActions.changeIssueOrder({
            projectId,
            issueTaskId,
            order
          })
        );
  };

  handleChangeTabs = value => {
    this.setState({
      value
    });
  };

  header = ({ classes }) => (
    <div className={classes.root}>
      <div className={classes.headerItem} style={{ flex: 3 }}>
        Task
      </div>
      <div className={classes.headerItem} style={{ flex: 4.5 }}>
        Description
      </div>
      <div className={classes.headerItem} style={{ flex: 3 }}>
        Assigned To
      </div>
      <div className={classes.headerItem} style={{ flex: 2 }}>
        Due Date
      </div>
      <div className={classes.headerItem} style={{ flex: 3 }}>
        Delay Time
      </div>
      <div
        style={{
          width: "230px",
          textAlign: "center",
          paddingRight: "10px"
        }}
        className={classes.headerItem}
      >
        Actions
      </div>
    </div>
  );

  // ###########################################################################
  // render
  // ###########################################################################

  render = () => {
    const { classes, projectUsers, role, dispatch, userId } = this.props;

    const { data = [], infoModalIsShowed, value } = this.state;
    const filteredData = this.filterArray(data);

    // const filteredIssuesByPerson = filteredData.filter(
    //   issue => issue.assignedTo._id === this.props.userId
    // );
    const pendingIssues = filteredData.filter(
      item => item.status === "pending"
    );
    const doneIssues = filteredData.filter(item => item.status === "done");
    const undoneIssues = filteredData.filter(item => item.status === "open");
    const rejectedIssues = filteredData.filter(
      item => item.status === "rejected"
    );

    return (
      <React.Fragment>
        {!filteredData[0] ? (
          <section className={classes.noData}>No Issue-Task</section>
        ) : (
          <React.Fragment>
            {/* ########################## pendding tasks  ######################## */}
            {pendingIssues[0] && (
              <>
                <div
                  className={classes.issuesLabel}
                  style={{ color: "#1C5498" }}
                >
                  Pending Tasks{" "}
                  <div style={{ backgroundColor: "#1C5498" }}></div>
                </div>
                {this.header({ classes })}
              </>
            )}

            {pendingIssues.length > 0 && (
              <div className={classes.listHolder}>
                {pendingIssues.map((item, idx) => (
                  <IssueItem
                    projectUsers={projectUsers}
                    key={item._id}
                    data={{ ...item, status: "pending" }}
                    handleEdit={this.handleEdit}
                    handleDeleteModal={this.handleDeleteModal}
                    setClickedRowDataToState={this.setClickedRowDataToState}
                    handlerInfoModal={this.handlerInfoModal}
                    role={role}
                    dispatch={dispatch}
                    handleChangeTabs={this.handleChangeTabs}
                    isMine={item.assignedTo._id === userId}
                  />
                ))}
              </div>
            )}

            {/* ########################## Open tasks       ######################## */}
            {undoneIssues[0] && (
              <>
                <div
                  className={classes.issuesLabel}
                  style={{ color: "#666668" }}
                >
                  Open Tasks <div style={{ backgroundColor: "#666668" }}></div>
                </div>
                {this.header({ classes })}
              </>
            )}

            {undoneIssues.length > 0 && (
              <div className={classes.listHolder}>
                {undoneIssues.map((item, idx) => (
                  <IssueItem
                    projectUsers={projectUsers}
                    key={item._id}
                    data={item}
                    handleEdit={this.handleEdit}
                    handleDeleteModal={this.handleDeleteModal}
                    setClickedRowDataToState={this.setClickedRowDataToState}
                    handlerInfoModal={this.handlerInfoModal}
                    role={role}
                    dispatch={dispatch}
                    handleChangeTabs={this.handleChangeTabs}
                    isMine={item.assignedTo._id === userId}
                  />
                ))}
              </div>
            )}
            {/* ########################## Done tasks ######################### */}

            {doneIssues.length > 0 && (
              <>
                <div
                  className={classes.issuesLabel}
                  style={{ color: "#43BA72" }}
                >
                  Done <div style={{ backgroundColor: "#43BA72" }}></div>
                </div>
                {this.header({ classes })}
              </>
            )}

            {doneIssues.length > 0 && (
              <div className={classes.listHolder}>
                {doneIssues.map((item, idx) => (
                  <IssueItem
                    projectUsers={projectUsers}
                    key={item._id}
                    data={item}
                    handleEdit={this.handleEdit}
                    handleDeleteModal={this.handleDeleteModal}
                    setClickedRowDataToState={this.setClickedRowDataToState}
                    handlerInfoModal={this.handlerInfoModal}
                    role={role}
                    dispatch={dispatch}
                    handleChangeTabs={this.handleChangeTabs}
                    isMine={item.assignedTo._id === userId}
                  />
                ))}
              </div>
            )}

            {/* ########################## Rejected ######################### */}

            {rejectedIssues.length > 0 && (
              <>
                <div
                  className={classes.issuesLabel}
                  style={{ color: "#ED2820" }}
                >
                  Rejected <div style={{ backgroundColor: "#ED2820" }}></div>
                </div>
                {this.header({ classes })}
              </>
            )}

            {rejectedIssues.length > 0 && (
              <div className={classes.listHolder}>
                {rejectedIssues.map((item, idx) => (
                  <IssueItem
                    projectUsers={projectUsers}
                    key={item._id}
                    data={{ ...item, status: "rejected" }}
                    handleEdit={this.handleEdit}
                    handleDeleteModal={this.handleDeleteModal}
                    setClickedRowDataToState={this.setClickedRowDataToState}
                    handlerInfoModal={this.handlerInfoModal}
                    role={role}
                    dispatch={dispatch}
                    handleChangeTabs={this.handleChangeTabs}
                    isMine={item.assignedTo._id === userId}
                  />
                ))}
              </div>
            )}

            {/* //////////////////// Delete User Dialog ///////////////////////// */}

            <DeleteModal
              open={this.state.openDeleteModal}
              handleDeleteModal={this.handleDeleteModal}
              selectedOptionForDelete={{
                issueTaskId: this.state.selectedOptionForDelete._id
              }}
              adminAction={issueActions.deleteIssue}
              userAction={issueUserActions.deleteIssue}
              //optional
              modalTitle={"Delete Issue"}
              optionNameForDelete={this.state.selectedOptionForDelete.name}
            />

            {/* ############################################################ */}

            <HOCModal
              isShowed={infoModalIsShowed}
              handlerInfoModal={this.handlerInfoModal}
            >
              <IssueTaskModalContent
                handlerInfoModal={this.handlerInfoModal}
                clickedRowDataId={this.state.clickedRowDataId}
                value={value}
              />
            </HOCModal>

            {/* ############################################################ */}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };
}

const mapStateToProps = state => ({
  projectUsers: state.projects.users ? state.projects.users : [],
  data: state.issues ? state.issues : [],
  role: state.projects.project ? state.projects.project.role : undefined,
  userId: state.authentication.user._id,
  pageFilter: state.filters.pageFilter
});

// #############################################################################
// styles
// #############################################################################
//
const IssueListWithStyles = withStyles(styles)(IssueList);
const IssueListWithStylesConnected = connect(mapStateToProps)(
  IssueListWithStyles
);

// #############################################################################
// export
// #############################################################################

export { IssueListWithStylesConnected as IssueList };
