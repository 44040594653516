import React from "react";

// ######################### redux ###################################
import { projectActions, userActions, filterActions } from "../../../actions";

// ######################### material ui ###################################
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// ######################### components ########################################
import AddAssignUserInline from "../../User-List/AddAssignUserInline";
import { UserList } from "../../";
import ThemeContext from "../../ContextApi/Context";

import { connect } from "react-redux";
// #############################################################################
//
// #############################################################################

class AdminProjectUsers extends React.Component {
  static contextType = ThemeContext;

  componentDidMount = () => {
    const { dispatch } = this.props;
    const projectId = this.context;
    dispatch(projectActions.getProjectUsers({ projectId }));
    dispatch(userActions.getAllUsers());
    dispatch(filterActions.pageFilterEdit(""));
  };

  // ###########################################################################
  // Modal: Update User Role
  // ###########################################################################

  handleUpdateRole = data => {
    this.props.dispatch(userActions.updateProjectRole(data));
  };

  // ###########################################################################
  // Render
  // ###########################################################################
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.projectInfo}>
        <Grid container>
          <Grid item xs={12}>
            {/* //////////////////// Assign User to a project ////////////////////////// */}
            <AddAssignUserInline />

            {/* ////////////////////  User Table /////////////////////////// */}
            <UserList
              handleEdit={this.handleUpdateRole}
              searchQuery={this.props.searchQuery}
              pageCapacity={10}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

// #############################################################################
//
// #############################################################################

const styles = theme => ({
  projectInfo: {
    fontSize: "0.85rem"
  }
});

// #############################################################################
//
// #############################################################################

const mapToStateProps = state => ({});

const AdminProjectUsersStyles = withStyles(styles)(AdminProjectUsers);
const AdminProjectUsersConnected = connect(mapToStateProps)(
  AdminProjectUsersStyles
);

// #############################################################################
//
// #############################################################################

export { AdminProjectUsersConnected as AdminProjectUsers };
