import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import Header from "./Header";
import addingCommaToCost from "../reusebleFunctions/addingCommaToCost";

// #############################################################################
// Component
// #############################################################################
const styles = theme => ({
  tableCaption: {
    backgroundColor: "#256598",
    color: "white",
    width: "calc(100% - 4px)",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    left: "2px",
    position: "relative"
  },
  tableCaptionContainer: {
    backgroundColor: "transparent",
    color: "white",
    width: "calc(100% + 8px)",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    left: "-4px",
    position: "relative"
  },
  tableSubCaption: {
    backgroundColor: "#A5B3BF",
    color: "black",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    marginTop: "5px",
    border: "1px solid #eff0f5",
    height: "45px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontWeight: "bold"
  },
  tableBody: {
    width: "100%"
  },
  headStyle: {
    height: "35px",
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "#A8B2BE"
  },
  dataStyle: {
    height: "35px",
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "white",
    color: "#505152"
  },
  subCaptionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dataStyleParent: {
    height: "35px",
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "#A8B2BE",
    color: "black",
    width: "20px"
  },
  phaseStyle: {
    height: "35px",
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "white",
    color: "#000000",
    fontWeight: "bold"
  }
});

// #############################################################################
// component
// ##########################################################
class CostReportTab extends React.PureComponent {
  render() {
    const { classes, report, project } = this.props;
    return (
      <div>
        <Header />
        {/* Table for Team members */}
        <table className={classes.tableBody}>
          <caption className={classes.tableCaption}>{"TEAM MEMBERS"}</caption>
          <thead>
            <tr>
              <td className={classes.headStyle}>{"Real Estate Manager"}</td>
              <td className={classes.headStyle}>{"Construction Manager"}</td>
              <td className={classes.headStyle}>{"Architect PM"}</td>
              <td className={classes.headStyle}>{"Developer"}</td>
              <td className={classes.headStyle}>{"General Contractor"}</td>
            </tr>
          </thead>

          {project && (
            <tbody>
              <tr>
                <td className={classes.dataStyle}>
                  {project.realEstateManager !== null &&
                    project.realEstateManager.firstName +
                      " " +
                      project.realEstateManager.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.constructionManager !== null &&
                    project.constructionManager.firstName +
                      " " +
                      project.constructionManager.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.architectPM !== null &&
                    project.architectPM.firstName +
                      " " +
                      project.architectPM.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.developer !== null &&
                    project.developer.firstName +
                      " " +
                      project.developer.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.generalContractor !== null &&
                    project.generalContractor.firstName +
                      " " +
                      project.generalContractor.lastName}
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <br />
        <br />

        {/* Table for Team schedule */}
        <table className={classes.tableBody}>
          <caption className={classes.tableCaptionContainer}>
            <div className={classes.tableCaption}>{"COST"}</div>
            <Grid container className={classes.subCaptionContainer}>
              <Grid item xs={4} className={classes.tableSubCaption}>
                Total Budget: {addingCommaToCost(report.totals.budgetTotal)}
              </Grid>
              <Grid item xs={4} className={classes.tableSubCaption}>
                Total Revised Cost:{" "}
                {addingCommaToCost(report.totals.revisedTotal)}
              </Grid>
              <Grid item xs={4} className={classes.tableSubCaption}>
                Total Actual Cost:{" "}
                {addingCommaToCost(report.totals.actualTotal)}
              </Grid>
            </Grid>
          </caption>
          <thead>
            <tr>
              <td
                className={classes.headStyle}
                style={{ color: "transparent" }}
              >
                {"..."}
              </td>
              <td className={classes.headStyle}>{"Description"}</td>
              <td className={classes.headStyle}>{"Budget"}</td>
              <td className={classes.headStyle}>{"Revised"}</td>
              <td className={classes.headStyle}>{"Actual"}</td>
              <td className={classes.headStyle}>{"Note"}</td>
            </tr>
          </thead>
          <tbody>
            {report.costs.map(report => {
              if (report.flatSubCosts.length) {
                // check if it has child
                return (
                  <React.Fragment key={report._id}>
                    {/* render parent node one time */}
                    <tr>
                      <td
                        className={classes.dataStyleParent}
                        rowSpan={report.flatSubCosts.length + 2}
                      >
                        {report.name}
                      </td>
                    </tr>
                    {report.flatSubCosts.map(subTask => {
                      return (
                        // to render children nodes
                        <tr key={subTask._id}>
                          <td className={classes.dataStyle}>{subTask.note}</td>
                          <td className={classes.dataStyle}>
                            {addingCommaToCost(subTask.budget)}
                          </td>
                          <td className={classes.dataStyle}>
                            {addingCommaToCost(subTask.revised)}
                          </td>
                          <td className={classes.dataStyle}>
                            {addingCommaToCost(subTask.actual)}
                          </td>
                          <td className={classes.dataStyle}>{subTask.note}</td>
                        </tr>
                      );
                    })}
                    <td className={classes.phaseStyle}>{"Phase Total"}</td>
                    <td className={classes.phaseStyle}>
                      {addingCommaToCost(report.budget)}
                    </td>
                    <td className={classes.phaseStyle}>
                      {addingCommaToCost(report.revised)}
                    </td>
                    <td className={classes.phaseStyle}>
                      {addingCommaToCost(report.actual)}
                    </td>
                    <td className={classes.phaseStyle}>{report.note}</td>
                  </React.Fragment>
                );
              } else {
                // if it has no child we got here
                return (
                  <tr key={report._id}>
                    <td className={classes.dataStyleParent}>{report.name}</td>
                    <td className={classes.phaseStyle}>{"Phase Total"}</td>
                    <td className={classes.phaseStyle}>
                      {addingCommaToCost(report.budget)}
                    </td>
                    <td className={classes.phaseStyle}>
                      {addingCommaToCost(report.revised)}
                    </td>
                    <td className={classes.phaseStyle}>
                      {addingCommaToCost(report.actual)}
                    </td>
                    <td className={classes.phaseStyle}>{report.note}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const CostReportTabWithStyles = withStyles(styles)(CostReportTab);

export { CostReportTabWithStyles as CostReportTab };
