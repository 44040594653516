import { progressBarConstants } from "../constants";

export function progressBar(state = { status: false }, action) {
  switch (action.type) {
    case progressBarConstants.PROGRESS_START:
      return { ...state, status: true };
    case progressBarConstants.PROGRESS_STOP:
      return { ...state, status: false };
    case progressBarConstants.ISSUE_REQUEST_START:
      return { ...state, issueRequest: true };
    case progressBarConstants.ISSUE_REQUEST_END:
      return { ...state, issueRequest: false };
    case progressBarConstants.PROJECT_REQUEST_START:
      return { ...state, projectRequest: true };
    case progressBarConstants.PROJECT_REQUEST_END:
      return { ...state, projectRequest: false };
    case progressBarConstants.CLIENT_REQUEST_START:
      return { ...state, clientRequest: true };
    case progressBarConstants.CLIENT_REQUEST_END:
      return { ...state, clientRequest: false };
    case progressBarConstants.USERS_REQUEST_START:
      return { ...state, usersRequest: true };
    case progressBarConstants.USERS_REQUEST_END:
      return { ...state, usersRequest: false };
    default:
      return state;
  }
}
