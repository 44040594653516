import React from 'react';

// #####[ MATERIAL ]############################################################
import { withStyles } from '@material-ui/core/styles';

// #####[ MATERIAL ICONS ]######################################################
import CloseIcon from "@material-ui/icons/Close";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";


// #############################################################################
// Styles 
// #############################################################################

const styles = theme => ({
  root:{
    position:"relative",
    backgroundColor:"white",
    boxSizing: "border-box",
    display: "flex",
    minWidth: "125px",
    border:"1px solid #cccccc",
    borderRadius:"4px",
    alignItems: "center",
    height:"38px",
    transition: "border-color 200ms, box-shadow 200ms",
    "&:hover":{
      borderColor:"#b3b3b3",
    },
    "&:focus-within":{
      borderColor: "#2483ff",
      boxShadow: "0px 0px 0px 1px #2483ff",
    }

  },
  input: {
    border:"none",
    fontSize: "15px",
    opacity:"0",
    flex:"1",
    height:"38px",
    minWidth: "90px"
  },
  closeIcon:{
    color:"#cccccc",
    padding:"0px 5px",
    borderLeft: "1px solid #cccccc",
    transition:"color 200ms",
    "&:hover":{
      color:"#cc000078"
    },
    "&:active":{
      color:"#a90000a3"
    },
    

  },
  fileIcon: {
    color:"#cccccc",
    padding:"0px 5px",
    position: "absolute",
    transition:"color 200ms "
  },
  label: {
    position:"absolute",
    paddingLeft:"35px",
    fontSize:"14px",
    color: "#000000bf",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "calc(100% - 150px)"
  },
  fileSelected:{
    color:"#2556848c"
  },
  placeholder:{
    color:"#cccccc"
  },
  fileSize:{
    position:"absolute",
    right:"40px",
    fontSize:"13px",
    color:"#00000069"
  }
})

// #############################################################################
// Component
// #############################################################################

class ReactFileInput extends React.PureComponent {

  //############################################################################
  // this function will handle the change in the input
  //############################################################################

  handleChange = event => {
    if (this.props.onChange) this.props.onChange(event);
  };

  //############################################################################
  // this function will handle the change in the input
  //############################################################################
  
  clear = () => {
    if (this.props.onChange) this.props.onChange({target:{name: this.props.name, value:"", files: []}});
  }

  //############################################################################
  // Render
  //############################################################################

  render = () => {
    const { classes, name, required, autoComplete, style } = this.props;
    const { disabled, value, htmlFor, placeholder, className } = this.props;
    const fileSize = (value.size / 1000000).toFixed(2);
    return (
      <div className={classes.root}>
        <InsertDriveFileIcon classes={{root:`${classes.fileIcon} ${value && value.name ? classes.fileSelected: null}`}}/>
        <label className={classes.label}>{value && value.name ? value.name : <label className={classes.placeholder}>{ placeholder }</label> }</label>
        <label className={classes.fileSize}>{ !isNaN(fileSize) ? `${fileSize}MB` : null}</label>
        <input
          style={style}
          onClick={this.handleFocus}
          className={`${classes.input} ${className}`}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          htmlFor={htmlFor}
          file={value}
          disabled={disabled}
          autoComplete={autoComplete}
          required={required}
          type="File"
          name={name}
          placeholder={placeholder}
        />
        <CloseIcon onClick={this.clear} classes={{root:classes.closeIcon}}/>
      </div>
    );
  }
}


// #############################################################################
// Connect Material UI styles to the component
// #############################################################################

const ReactFileInputWithStyles = withStyles(styles)(ReactFileInput);

export { ReactFileInputWithStyles as ReactFileInput }
