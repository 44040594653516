import { constructionConstants } from "../constants";

export function constructions(state = [], action) {
  switch (action.type) {
    /////get/////
    case constructionConstants.GET_ALL_FORMS_SUCCESS:
      return action.payload;

    case constructionConstants.ADD_FORM_SUCCESS:
      return [...state, action.payload];

    case constructionConstants.EDIT_FORM_SUCCESS:
      return state.map(item =>
        item._id !== action.payload._id ? item : action.payload
      );

    case constructionConstants.DELETE_FORM_SUCCESS:
      return state.filter(item => item._id !== action.payload);

    default:
      return state;
  }
}
