import { combineReducers } from "redux";
import { authentication } from "./authentication.reducers";
import { users } from "./users.reducers";
import { projects } from "./projects.reducers";
import { tasks } from "./task.reducers";
import { costs } from "./cost.reducers";
import { progressBar } from "./progressBar.reducers";
import { snackBar } from "./snackbar.reducer";
import { history } from "../helpers";
import { issues } from "./issues.reducers";
import { notes } from "./note.reducers";
import { comments } from "./comment.reducers";
import { attachments } from "./attachment.reducers";
import { activities } from "./activity.reducers";
import { companies } from "./client.reducers";
import { constructions } from "./constructions.reducer";
import { construction } from "./construction.reducers";
import { reports } from "./reports.reducers";
import { filters } from "./filter.reducers";
import { userConstants } from "../constants";

const appReducer = combineReducers({
  authentication,
  users,
  projects,
  tasks,
  costs,
  progressBar,
  snackBar,
  issues,
  notes,
  comments,
  attachments,
  activities,
  companies,
  constructions,
  reports,
  filters,
  construction
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    history.push("/login");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
