import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import EmailIcon from "@material-ui/icons/Email";
// import LockIcon from "@material-ui/icons/Lock";

import { userActions, snackBarActions } from "../actions";

import { CustomSnackbar } from "../components";
// import Logo from "../components/img/PM.jpg";
import loginPageSvg from "../components/img/loginPageSvg.png";

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh",
    height: "100%",
    // justifyContent: 'center',
    alignItems: "center"
    // backgroundColor: "#9bddfe",
  },
  card: {
    // borderRadius: "60px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: "40px"
  },
  logoContainer: {
    flex: 2,
    color: "white",
    alignItems: "center",
    display: "flex"
  },
  logo: {
    color: "white"
  },
  formContainer: {
    flex: 1,
    width: "100%"
  },
  title: {
    color: "#020058",
    textAlign: "center"
  },
  submitButton: {
    width: "240px",
    height: "50px",
    fontSize: "21px",
    borderRadius: "7px",
    backgroundColor: "#155794",
    transition: "300ms",
    color: "white",
    margin: "25px 0",
    "&:hover": {
      backgroundColor: "#1962a6"
    }
  },
  loginLink: {
    marginTop: "25px"
  },
  link: {
    color: "#7D7D7D",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "&:hover": {
      color: "#2DA2C2"
    }
  },
  rememberMeCheckbox: {
    color: "#7D7D7D",
    fontSize: "14px"
  },
  inputsWraper: {
    boxShadow: "0 0 15px #e0e0e0",
    boxSizing: "border-box",
    borderRadius: "2px"
  },
  borderbottom: {
    borderBottom: "1px solid #E1E1E1"
  },
  email: {
    // margin: "10px",
    boxSizing: "border-box",
    borderRadius: "0",
    margin: "10px 10px 0 10px",
    borderBottom: "1px solid #E1E1E1",
    paddingBottom: "20px",
    flexGrow: "100"
  },
  password: {
    boxSizing: "border-box",
    borderRadius: "0",
    margin: " 7px 10px 25px 10px",
    flexGrow: "100"
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px rgb(250, 255, 189) inset"
    },
    "&:focus:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px rgb(250, 255, 189) inset"
    }
  },
  blueLine: {
    width: "3px",
    height: "80px",
    backgroundColor: "#155794"
  }
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      email: "",
      focus: "",
      rememberMe: false
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { email, password, rememberMe } = this.state;
    // dispatch(progress.start());
    dispatch(userActions.login({ email, password, rememberMe }));
  };

  handleCloseSnackBar = () => {
    this.props.dispatch(snackBarActions.snackBarClose());
  };

  handleCheckbox = ({ target: { name, checked } }) => {
    this.setState({
      [name]: checked
    });
  };

  render() {
    const { classes, snackBar } = this.props;
    const { focus, rememberMe } = this.state;
    return (
      <div className={classes.root}>
        <Grid container justify={"center"}>
          <Grid item md={6} style={{ position: "relative" }}>
            <img
              src={loginPageSvg}
              alt="PM Design"
              style={{ height: "100vh" }}
            />
            {/* <img
              src={Logo}
              alt="logo"
              style={{
                position: "absolute",
                left: "86px",
                top: "450px",
                width: "220px"
              }}
              ref={image => (this.imageElement = image)} */}
            {/* /> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            container
            justify="center"
            alignItems="center"
          >
            <Grid className={classes.card}>
              <div className={classes.logoContainer}>
                <Typography
                  className={classes.logo}
                  variant={"h2"}
                ></Typography>
              </div>
              <div className={classes.formContainer}>
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                  <Grid
                    className={classes.inputsWraper}
                    container
                    direction="column"
                  >
                    <Grid container>
                      <span
                        className={classes.blueLine}
                        style={{
                          visibility: focus === "email" ? "visible" : "hidden"
                        }}
                      >
                        <span style={{ visibility: "hidden" }}>1</span>
                      </span>
                      <TextField
                        required
                        type="email"
                        name={"email"}
                        label="Email Address"
                        className={classes.email}
                        value={this.state.email}
                        onChange={this.handleChange}
                        InputProps={{
                          disableUnderline: true,
                          className: classes.input
                        }}
                        autoComplete="off"
                        autoFocus
                        onFocus={() => this.setState({ focus: "email" })}
                        onBlur={() => this.setState({ focus: "" })}
                      />
                    </Grid>
                    <Grid container>
                      <span
                        className={classes.blueLine}
                        style={{
                          visibility:
                            focus === "password" ? "visible" : "hidden"
                        }}
                      >
                        <span style={{ visibility: "hidden" }}>1</span>
                      </span>
                      <TextField
                        name="password"
                        required
                        label="Password"
                        value={this.state.password}
                        className={classes.password}
                        onChange={this.handleChange}
                        type="password"
                        autoComplete="off"
                        InputProps={{
                          disableUnderline: true,
                          className: classes.input
                        }}
                        onFocus={() => this.setState({ focus: "password" })}
                        onBlur={() => this.setState({ focus: "" })}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    className={classes.loginLink}
                    container
                    justify="space-between"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={rememberMe}
                          onChange={this.handleCheckbox}
                          color="primary"
                          name="rememberMe"
                        />
                      }
                      label="Remember Me"
                      classes={{
                        label: classes.rememberMeCheckbox
                      }}
                    />
                    <Link to={"/reset-password"} className={classes.link}>
                      Forgot Password
                    </Link>
                  </Grid>
                  <Grid container justify="center">
                    <Button type={"submit"} className={classes.submitButton}>
                      Login
                    </Button>
                  </Grid>
                </form>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {snackBar && (
          <CustomSnackbar
            autoHideDuration={4000}
            {...snackBar}
            onClose={this.handleCloseSnackBar}
          />
        )}
      </div>
    );
  }
}

//############################################################################
// connect component to styles and redux
//############################################################################

const mapStateToProps = state => {
  return {
    login: state,
    snackBar: state.snackBar.snackBar
  };
};

//############################################################################
// styles
//############################################################################
const connectedLogin = connect(mapStateToProps)(Login);
const LoginWithStyle = withStyles(styles)(connectedLogin);
export { LoginWithStyle as LoginPage };
