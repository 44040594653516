// React //
import React from "react";
// Redux //
import { connect } from "react-redux";
import { constructionActions, clientActions } from "../../actions";
import { constructionUserActions, clientUserActions } from "../../userActions";

// Material Ui //
// import { withStyles } from "@material-ui/core";

// Components //
import {
  ConstructionDocumentTabItem,
  PMDGAddendumForm,
  PMDGDailyLogForm,
  PMDGRFIForm,
  PMDGShopDrawingsSubmittalLogForm,
  PMDGAddendumPdf,
  PMDGDailyLogPdf,
  PMDGRFIPdf,
  PMDGShopDrawingsSubmittalLogPdf
} from "./";
import DeleteModal from "../Modal/DeleteModal";
// import Button from "@material-ui/core/Button";
import ThemeContext from "../ContextApi/Context";
import { Router, Switch, Route } from "react-router-dom";
import { history } from "../../helpers";

// Reusable Functions //

// Styles //

class ConstructionDocumentTabList extends React.PureComponent {
  static contextType = ThemeContext;
  state = {
    formData: "",
    formName: "",
    openDeleteModal: false,
    selectedOptionForDelete: {}
  };

  componentDidMount() {
    const { dispatch, role } = this.props;
    const projectId = this.context;
    if (role === "admin") {
      dispatch(constructionActions.getAllForms({ projectId }));
      dispatch(clientActions.getAllCompanies());
    } else {
      dispatch(constructionUserActions.getAllForms({ projectId }));
      dispatch(clientUserActions.getAllCompanies());
    }
  }

  handleGoToForm = (formData, formName) => {
    this.setState({
      formData,
      formName
    });
  };
  handleGoToEmptyForm = formName => {
    this.setState({
      formName,
      formData: ""
    });
  };

  handleSave = () => {
    this.setState({
      formName: ""
    });
  };

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
        openDeleteModal: true,
        selectedOptionForDelete
      })
      : this.setState({
        openDeleteModal: false
      });
  };

  handleGoToReport = (formData, formName) => {
    this.setState({
      formData,
      formName
    });
  };

  handleBack = () => {
    this.setState({
      formName: "",
      formData: ""
    });
    history.goBack();
  };

  // ###########################################################################
  // create routes for the selected project
  // ###########################################################################

  routes = () => {
    const { role } = this.props;
    const projectId = this.context;
    return role === "admin"
      ? {
        default: `/admin-dashboard/projects/${projectId}/construction-project`,
        addendum: `/admin-dashboard/projects/${projectId}/construction-project/Addendum/:formId`,
        addLog: `/admin-dashboard/projects/${projectId}/construction-project/addDailyLog/:formId`,
        addRFI: `/admin-dashboard/projects/${projectId}/construction-project/addRFI/:formId`,
        addSubmittal: `/admin-dashboard/projects/${projectId}/construction-project/addShopDrawingsSubmittal/:formId`,
        getendum: `/admin-dashboard/projects/${projectId}/construction-project/getendums/:formId`,
        getLog: `/admin-dashboard/projects/${projectId}/construction-project/getDailyLog/:formId`,
        getRFI: `/admin-dashboard/projects/${projectId}/construction-project/getRFI/:formId`,
        getSubmittal: `/admin-dashboard/projects/${projectId}/construction-project/getShopDrawingsSubmittal/:formId`
      }
      : {
        default: `/user-dashboard/projects/${projectId}/construction-project`,
        addendum: `/user-dashboard/projects/${projectId}/construction-project/Addendum/:formId`,
        addLog: `/user-dashboard/projects/${projectId}/construction-project/addDailyLog/:formId`,
        addRFI: `/user-dashboard/projects/${projectId}/construction-project/addRFI/:formId`,
        addSubmittal: `/user-dashboard/projects/${projectId}/construction-project/addShopDrawingsSubmittal/:formId`,
        getendum: `/user-dashboard/projects/${projectId}/construction-project/getendums/:formId`,
        getLog: `/user-dashboard/projects/${projectId}/construction-project/getDailyLog/:formId`,
        getRFI: `/user-dashboard/projects/${projectId}/construction-project/getRFI/:formId`,
        getSubmittal: `/user-dashboard/projects/${projectId}/construction-project/getShopDrawingsSubmittal/:formId`
      };
  };

  render() {
    const { constructions = [] } = this.props;
    const { formData } = this.state;
    const projectId = this.context;

    return (
      <>
        <Router history={history}>
          <Switch>
            {/* TO DOWNLOAD PDF */}
            <Route
              path={this.routes().getendum}
              render={({ match }) => (
                <>
                  <div ref={this.bodyRef}>
                    <PMDGAddendumPdf
                      match={match}
                    />
                  </div>
                </>
              )}
            />
            <Route
              path={this.routes().getLog}
              render={({ match }) => (
                <>
                  <div ref={this.bodyRef}>
                    <PMDGDailyLogPdf
                      match={match}
                    />
                  </div>
                </>
              )}
            />
            <Route
              path={this.routes().getRFI}
              render={({ match }) => (
                <>
                  <div ref={this.bodyRef}>
                    <PMDGRFIPdf
                      match={match}
                    />
                  </div>
                </>
              )}
            />
            <Route
              path={this.routes().getSubmittal}
              render={({ match }) => (
                <>
                  <div ref={this.bodyRef}>
                    <PMDGShopDrawingsSubmittalLogPdf
                      match={match}
                    />
                  </div>
                </>
              )}
            />

            {/* TO ADD FORM */}
            <Route
              path={this.routes().addendum}
              render={({ match }) => (
                <div>
                  <PMDGAddendumForm
                    handleSave={this.handleSave}
                    match={match}
                  />
                </div>
              )}
            />
            <Route
              path={this.routes().addLog}
              render={({ match }) => (
                <PMDGDailyLogForm handleSave={this.handleSave} match={match} />
              )}
            />
            <Route
              path={this.routes().addRFI}
              render={({ match }) => (
                <PMDGRFIForm
                  formData={formData}
                  handleSave={this.handleSave}
                  match={match}
                />
              )}
            />
            <Route
              path={this.routes().addSubmittal}
              render={({ match }) => (
                <PMDGShopDrawingsSubmittalLogForm
                  formData={formData}
                  handleSave={this.handleSave}
                  match={match}
                />
              )}
            />
            {/* LISTS */}
            <Route
              path={this.routes().default}
              render={() => (
                <>
                  <ConstructionDocumentTabItem
                    handleGoToForm={this.handleGoToForm}
                    handleGoToEmptyForm={this.handleGoToEmptyForm}
                    data={constructions.filter(
                      form => form.name === "PMDG Addendum Form"
                    )}
                    name={"PMDG Addendums"}
                    tempName={"Addendums"}
                    handleDeleteModal={this.handleDeleteModal}
                    handleGoToReport={this.handleGoToReport}
                    projectId={projectId}
                  />

                  <ConstructionDocumentTabItem
                    handleGoToForm={this.handleGoToForm}
                    handleGoToEmptyForm={this.handleGoToEmptyForm}
                    data={constructions.filter(
                      form => form.name === "PMDG Daily Log Form"
                    )}
                    name={"PMDG Daily Log"}
                    tempName={"Daily Log"}
                    handleDeleteModal={this.handleDeleteModal}
                    handleGoToReport={this.handleGoToReport}
                    projectId={projectId}
                  />

                  <ConstructionDocumentTabItem
                    handleGoToForm={this.handleGoToForm}
                    handleGoToEmptyForm={this.handleGoToEmptyForm}
                    data={constructions.filter(
                      form => form.name === "PMDG RFI Form"
                    )}
                    name={"PMDG RFI’s"}
                    tempName={"RFI’s"}
                    handleDeleteModal={this.handleDeleteModal}
                    handleGoToReport={this.handleGoToReport}
                    projectId={projectId}
                  />

                  <ConstructionDocumentTabItem
                    handleGoToForm={this.handleGoToForm}
                    handleGoToEmptyForm={this.handleGoToEmptyForm}
                    data={constructions.filter(
                      form =>
                        form.name === "PMDG Shop Drawings Submittal Log Form"
                    )}
                    name={"PMDG Shop Drawings Submittal Log"}
                    tempName={"Shop Drawings Submittal"}
                    handleDeleteModal={this.handleDeleteModal}
                    handleGoToReport={this.handleGoToReport}
                    projectId={projectId}
                  />
                </>
              )}
            />
          </Switch>
        </Router>

        {/* ////////////////////////////////////////////////////////////// */}
        <DeleteModal
          open={this.state.openDeleteModal}
          selectedOptionForDelete={{
            formId: this.state.selectedOptionForDelete._id,
            formName: this.state.selectedOptionForDelete.name
          }}
          adminAction={constructionActions.deleteForm}
          userAction={constructionUserActions.deleteForm}
          handleDeleteModal={this.handleDeleteModal}
          //optional
          modalTitle={"Delete Form"}
        />
      </>
    );
  }
}

// const Styles = {
//   header: {
//     boxSizing: "border-box",
//     padding: "10px 0px",
//     display: "flex",
//     width: "100%"
//   },
//   headerItem: {
//     boxSizing: "border-box",
//     color: "#155794",
//     padding: "0px 5px",
//     margin: "0px 3px",
//     fontSize: "15px"
//   },
//   backButton: {
//     backgroundColor: "#F44336",
//     color: "white",
//     width: "150px",
//     marginTop: "20px",
//     marginLeft: "7px",
//     "&:hover": {
//       backgroundColor: "#f52c1d"
//     }
//   }
// };
const mapStateToProps = state => ({
  project: state.projects.project,
  constructions: state.constructions,
  companies: state.companies,
  role: state.authentication.user.role
});
export default connect(mapStateToProps)(
  // withStyles(Styles)(
  ConstructionDocumentTabList
  // )
);
