// React //
import React from "react";
// Redux //
// Material Ui //
// Components //
import NoteWrapper from "../../NoteTab/NoteWrapper";
// Reusable Functions //
// Styles //

class AdminProjectNote extends React.PureComponent {
  render() {
    return <NoteWrapper />;
  }
}

export default AdminProjectNote;
