import React from "react";

// #####[ material ui ]##############################################################
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// #####[ redux ]##############################################################
import { connect } from "react-redux";

// #####[ reusable functions ]##############################################################

// #####[ styles ]##############################################################
import { Colors } from "../../styles";

// #####[ components ]##############################################################
import { AttachmentModalTab } from "./AttachmentModalTab";
import { ActivityModalTab } from "./ActivityModalTab";
import { CommentModalTab } from "./CommentModalTab";
class IssueTaskModalContent extends React.PureComponent {
  state = { activitiy: null, value: this.props.value };

  handleChangeTab = (event, value) => {
    this.setState({ value });
  };

  componentWillUnmount() {
    this.setState({
      documents: {},
      activitiy: null
    });
  }

  render() {
    const { classes, handlerInfoModal, issues, clickedRowDataId } = this.props;
    const { value } = this.state;
    const issue = issues.filter(
      issue => issue._id === this.props.clickedRowDataId
    )[0];
    return (
      <div className={classes.modalContainer} tabIndex={1}>
        <Close
          className={classes.closeIcon}
          onClick={() => handlerInfoModal(false)}
        />
        <h3>{issue.name}</h3>

        <p>{issue.description ? issue.description : null} </p>

        <div className={classes.activityAndAttachmentListContainer}>
          {/* NOTE Attachment and Contract Tabs */}
          <section className={classes.attachmentAndContractList}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              className={classes.tabs}
              classes={{ indicator: classes.indicator }}
            >
              <Tab
                classes={{ selected: classes.selected }}
                label="Attach Files"
                className={classes.tab}
              />
              <Tab
                classes={{ selected: classes.selected }}
                label="Activity"
                className={classes.tab}
              />
              <Tab
                classes={{ selected: classes.selected }}
                label="Comments"
                className={classes.tab}
              />
            </Tabs>
            {value === 0 && (
              <AttachmentModalTab clickedRowDataId={clickedRowDataId} />
            )}
            {value === 1 && (
              <ActivityModalTab clickedRowDataId={clickedRowDataId} />
            )}
            {value === 2 && (
              <CommentModalTab clickedRowDataId={clickedRowDataId} />
            )}
          </section>
        </div>
      </div>
    );
  }
}

const styles = {
  modalContainer: {
    width: "900px",
    minHeight: "350px",
    maxHeight: "560px",
    borderRadius: "10px",
    boxShadow: "0 0 10px #545454",
    padding: "30px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EFEFF9",
    "& > h3": {
      color: Colors.darkGreen,
      margin: "0"
    },
    "& > p": {
      fontSize: "13px",
      padding: " 0 0 10px 3px",
      margin: 0,
      color: Colors.grey,
      borderBottom: "1px solid #D1D1DB"
    }
  },
  closeIcon: {
    color: Colors.grey,
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer"
  },
  activityAndAttachmentListContainer: {
    display: "flex",
    height: "100%"
  },

  subContainerActivityHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px 0 5px",
    marginBottom: "10px",
    borderBottom: `2px solid ${Colors.lightGreen}`,
    color: "black",

    "& > h4": {
      margin: 0,
      display: "flex",
      alignItems: "flex-end",
      padding: "5px 6px"
    }
  },

  attachmentAndContractList: {
    width: "50%",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    flexGrow: 100,
    maxHeight: "400px",
    height: "400px",
    minHeight: "400px"
  },

  tabs: {
    marginTop: "15px",
    marginBottom: "10px",
    "& span": { fontWeight: "bold!important", fontSize: "14px!important" },
    borderBottom: "2px solid #00B46D ",
    minHeight: "35px!important",
    height: "35px!important"
  },
  tab: {
    width: "auto",
    flexGrow: "unset!important",
    minWidth: "130px!important",
    minHeight: "35px!important",
    height: "35px!important",
    "& span": {
      padding: "0!important"
    }
  },
  indicator: {
    // backgroundColor: Colors.lightGreen + "!important",
    // height: "2px"
    display: "none"
  },
  selected: {
    color: `white!important`,
    backgroundColor: "#00B46D!important",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px"
  },
  nothingToShow: {
    height: "290px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none!important",
    fontSize: "30px!important",
    fontWeight: "100!important",
    color: "#00000036!important"
  }
};

const mapStateToProps = state => {
  return {
    projectId: state.projects.project._id,
    documents: state.attachments,
    issues: state.issues,
    activities: state.activities,
    userRoleType: state.authentication.user.role
  };
};

const IssueTaskModalContentConnected = connect(mapStateToProps)(
  IssueTaskModalContent
);
const IssueTaskModalContentConnectedAndStyled = withStyles(styles)(
  IssueTaskModalContentConnected
);

export { IssueTaskModalContentConnectedAndStyled as IssueTaskModalContent };
