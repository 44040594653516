import React from "react";
import { connect } from "react-redux";

//Material UI
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AvaterImage from "../../img/noteTabAvatar.png";
import Create from "@material-ui/icons/Create";
import Person from "@material-ui/icons/Person";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import CardTravel from "@material-ui/icons/CardTravel";
import VpnKey from "@material-ui/icons/VpnKey";
import Button from "@material-ui/core/Button";
import { ReactInput } from "../../";
import { snackBarActions, clientActions } from "../../../actions";
import { userUserActions } from "../../../userActions";
import { clientUserActions } from "../../../userActions";
import { API } from "../../../constants";
import { BackButton } from "../../Buttons";

class Profile extends React.PureComponent {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    title: "",
    company: "",
    currentPassword: "",
    newPassword: "",
    repeatNewPassword: "",
    avatar: null
  };

  componentDidMount() {
    this.props.dispatch(userUserActions.getProfileGeneral());
    this.props.role === "admin"
      ? this.props.dispatch(clientActions.getAllCompanies())
      : this.props.dispatch(clientUserActions.getAllCompanies());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      const {
        firstName,
        lastName,
        email,
        title,
        phoneNumber,
        company,
        avatar
      } = this.props.user;
      this.setState({
        firstName,
        lastName,
        phoneNumber,
        title,
        email,
        company,
        avatar
      });
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { firstName, lastName, email, phoneNumber, title } = this.state;
    const document = new FormData();
    document.append("firstName", firstName);
    document.append("lastName", lastName);
    document.append("email", email);
    document.append("phoneNumber", phoneNumber);
    document.append("title", title);
    this.props.dispatch(userUserActions.editProfileGeneral(document));
  };

  handleSubmitPassword = e => {
    e.preventDefault();
    const { currentPassword, newPassword, repeatNewPassword } = this.state;
    if (newPassword === repeatNewPassword) {
      const document = new FormData();
      document.append("password", currentPassword);
      document.append("newPassword", newPassword);
      this.props.dispatch(userUserActions.editProfileGeneral(document));
      this.setState({
        currentPassword: "",
        newPassword: "",
        repeatNewPassword: ""
      });
    } else {
      this.props.dispatch(
        snackBarActions.snackBarError(
          "Password confirmation doesn't match Password"
        )
      );
    }
  };

  handleActiveEdit = ({ target: { files, name } }) => {
    const document = new FormData();
    files && document.append("avatar", files[0]);
    this.props.dispatch(userUserActions.editProfileGeneral(document));
  };

  render() {
    const { classes, user = {}, companies = [] } = this.props;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      title,
      company,
      currentPassword,
      newPassword,
      repeatNewPassword,
      avatar
    } = this.state;
    return (
      <>
        <Grid container className={classes.searchBar}>
          <BackButton />
        </Grid>
        <Grid container direction="column" className={classes.root}>
          <Grid container justify="flex-start">
            <Grid className={classes.logoHolder}>
              <img
                src={avatar ? `${API}/${avatar}` : AvaterImage}
                alt="avatar"
                className={classes.logoImage}
              />
              <div className={classes.editIconWrapper}>
                <input
                  type="file"
                  onChange={this.handleActiveEdit}
                  accept="image/*"
                  name="file"
                  style={{
                    position: "absolute",
                    width: "32px",
                    height: "32px",
                    top: 0,
                    left: 0,
                    opacity: 0,
                    zIndex: 10
                  }}
                />
                <Create
                  onClick={this.handleActiveEdit}
                  className={classes.editIcon}
                />
              </div>
            </Grid>
            <Grid
              item
              xs
              container
              alignItems="flex-start"
              justify="flex-end"
              direction="column"
              className={classes.headerTextWrapper}
            >
              <Grid>
                <Typography variant="h5" className={classes.nameTitle}>
                  {user.firstName} {user.lastName}
                </Typography>
              </Grid>
              <Grid container justify="flex-start" alignItems="center">
                {/* <Warning className={classes.warningIcon} />
                  <Typography
                    variant="subtitle1"
                    className={classes.subtitle}
                    alignItems="center"
                  >
                    You Should Change Your Password
                  </Typography> */}
              </Grid>
            </Grid>
          </Grid>
          {/* //////////////////////////your profle */}
          <Grid
            container
            direction="column"
            className={classes.yourProfileWrapper}
          >
            <form onSubmit={this.handleSubmit}>
              <Typography variant="h5" className={classes.yourProfileText}>
                Your Profile
              </Typography>
              <Grid container className={classes.yourProfileBody}>
                {/* ////////////////////////firstName */}
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  className={classes.inputWrapper}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.textInputTitle}
                  >
                    First Name:
                  </Typography>
                  <Grid style={{ position: "relative" }} container>
                    <ReactInput
                      name="firstName"
                      value={firstName}
                      onChange={this.handleChange}
                      placeholder="First Name"
                      className={classes.textInput}
                      required
                      autocomplete="off"
                    />
                    <Person className={classes.textInputIcon} />
                  </Grid>
                </Grid>

                {/* ////////////////////////lastName */}
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  className={classes.inputWrapper}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.textInputTitle}
                  >
                    Last Name:
                  </Typography>
                  <Grid style={{ position: "relative" }} container>
                    <ReactInput
                      name="lastName"
                      value={lastName}
                      onChange={this.handleChange}
                      placeholder="Last Name"
                      className={classes.textInput}
                      required
                      autocomplete="off"
                    />
                    <Person className={classes.textInputIcon} />
                  </Grid>
                </Grid>

                {/* ////////////////////////email */}
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  className={classes.inputWrapper}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.textInputTitle}
                  >
                    Email Address:
                  </Typography>
                  <Grid style={{ position: "relative" }} container>
                    <ReactInput
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      placeholder="Email Address"
                      className={classes.textInput}
                      required
                      autocomplete="off"
                    />
                    <Email className={classes.textInputIcon} />
                  </Grid>
                </Grid>

                {/* ////////////////////////phoneNumber */}
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  className={classes.inputWrapper}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.textInputTitle}
                  >
                    Mobile Number:
                  </Typography>
                  <Grid style={{ position: "relative" }} container>
                    <ReactInput
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={this.handleChange}
                      placeholder="Mobile Number"
                      className={classes.textInput}
                      type="number"
                      required
                      autocomplete="off"
                    />
                    <Phone className={classes.textInputIcon} />
                  </Grid>
                </Grid>

                {/* ////////////////////////title */}
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  className={classes.inputWrapper}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.textInputTitle}
                  >
                    Title:
                  </Typography>
                  <Grid style={{ position: "relative" }} container>
                    <ReactInput
                      name="title"
                      value={title}
                      onChange={this.handleChange}
                      placeholder="Title"
                      className={classes.textInput}
                      required
                      autocomplete="off"
                    />
                    <Person className={classes.textInputIcon} />
                  </Grid>
                </Grid>

                {/* ////////////////////////company */}
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  className={classes.inputWrapper}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.textInputTitle}
                  >
                    Company:
                  </Typography>
                  <Grid style={{ position: "relative" }} container>
                    <ReactInput
                      name="company"
                      value={company ? company.name : ""}
                      onChange={this.handleChange}
                      placeholder="Company"
                      className={classes.textInput}
                      disabled
                      style={{ backgroundColor: "#E7ECF2" }}
                      autocomplete="off"
                    />
                    <CardTravel className={classes.textInputIcon} />
                  </Grid>
                </Grid>
              </Grid>

              {/* //////////////////////////save button */}
              <Grid
                container
                justify="flex-end"
                className={classes.submitButtonContainer}
              >
                <Button type="submit" className={classes.submitButton}>
                  Save
                </Button>
              </Grid>
            </form>
          </Grid>

          {/* //////////////////////////security */}
          <Grid
            container
            direction="column"
            className={classes.yourProfileWrapper}
          >
            <form onSubmit={this.handleSubmitPassword}>
              <Typography variant="h5" className={classes.yourProfileText}>
                Security
              </Typography>
              <Grid container className={classes.yourProfileBody}>
                {/* ////////////////////////current password */}
                <Grid
                  item
                  xs={4}
                  container
                  direction="column"
                  className={classes.inputWrapper}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.textInputTitle}
                  >
                    Current Password:
                  </Typography>
                  <Grid style={{ position: "relative" }} container>
                    <ReactInput
                      name="currentPassword"
                      value={currentPassword}
                      onChange={this.handleChange}
                      placeholder="Current Password"
                      className={classes.textInput}
                      required
                      autocomplete="off"
                      type="password"
                    />
                    <VpnKey className={classes.keyIcon} />
                  </Grid>
                </Grid>

                {/* ////////////////////////new password */}
                <Grid
                  item
                  xs={4}
                  container
                  direction="column"
                  className={classes.inputWrapper}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.textInputTitle}
                  >
                    New Password:
                  </Typography>
                  <Grid style={{ position: "relative" }} container>
                    <ReactInput
                      name="newPassword"
                      value={newPassword}
                      onChange={this.handleChange}
                      placeholder="New Password"
                      className={classes.textInput}
                      required
                      autocomplete="off"
                      type="password"
                    />
                    <VpnKey className={classes.keyIcon} />
                  </Grid>
                </Grid>

                {/* ////////////////////////repeat new password */}
                <Grid
                  item
                  xs={4}
                  container
                  direction="column"
                  className={classes.inputWrapper}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.textInputTitle}
                  >
                    New Password Confirmation:
                  </Typography>
                  <Grid style={{ position: "relative" }} container>
                    <ReactInput
                      name="repeatNewPassword"
                      value={repeatNewPassword}
                      onChange={this.handleChange}
                      placeholder="New Password Confirmation"
                      className={classes.textInput}
                      required
                      autocomplete="off"
                      type="password"
                    />
                    <VpnKey className={classes.keyIcon} />
                  </Grid>
                </Grid>
              </Grid>

              {/* //////////////////////////save button */}
              <Grid
                container
                justify="flex-end"
                className={classes.submitButtonContainer}
              >
                <Button type="submit" className={classes.submitButton}>
                  Save
                </Button>
              </Grid>
            </form>
          </Grid>

          {/* //////////////////////////Projects */}
          <Grid
            container
            direction="column"
            className={classes.yourProfileWrapper}
          >
            <Typography variant="h5" className={classes.yourProfileText}>
              Projects
            </Typography>
            <Grid className={classes.yourProfileBody}>
              {/* ////////////////////////current password */}
              {user.projects && user.projects[0] ? (
                user.projects.map((project, index) => (
                  <Grid container key={index}>
                    <Grid
                      item
                      xs={4}
                      container
                      direction="column"
                      className={classes.inputWrapper}
                    >
                      <Typography
                        variant="subtitle1"
                        className={classes.textInputTitle}
                      >
                        Project:
                      </Typography>
                      <Grid style={{ position: "relative" }} container>
                        <Grid item xs className={classes.projectData}>
                          {project.name}
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* ////////////////////////new password */}
                    <Grid
                      item
                      xs={4}
                      container
                      direction="column"
                      className={classes.inputWrapper}
                    >
                      <Typography
                        variant="subtitle1"
                        className={classes.textInputTitle}
                      >
                        Company:
                      </Typography>
                      <Grid style={{ position: "relative" }} container>
                        <Grid item xs className={classes.projectData}>
                          {companies[0] &&
                            companies.find(
                              company => company._id === project.client
                            ).name}
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* ////////////////////////repeat new password */}
                    <Grid
                      item
                      xs={4}
                      container
                      direction="column"
                      className={classes.inputWrapper}
                    >
                      <Typography
                        variant="subtitle1"
                        className={classes.textInputTitle}
                      >
                        Role:
                      </Typography>
                      <Grid style={{ position: "relative" }} container>
                        <Grid item xs className={classes.projectData}>
                          {project.role.replace("-", " ")}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography variant="h4" className={classes.noProject}>
                  No Project
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.user,
    role: state.authentication.user.role,
    companies: state.companies
  };
};

const styles = theme => ({
  root: {
    padding: "5px 20px 20px 20px",
    marginTop: "60px"
  },
  logoHolder: {
    width: "150px",
    height: "150px",
    borderRadius: "4px",
    overflow: "visible",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  logoImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
    height: "100%",
    width: "100%",
    boxShadow: "0 0 10px #DDE1ED",
    borderRadius: "100%"
  },
  warningIcon: {
    color: "#F4C904",
    marginRight: "20px"
  },
  subtitle: {
    borderRadius: "20px",
    backgroundColor: "#FBFBFD",
    padding: "4px 10px"
  },
  nameTitle: {
    color: "#125693",
    marginBottom: "10px"
  },
  editIcon: {
    color: "white",
    backgroundColor: "#125693",
    padding: "7px",
    borderRadius: "100%",
    cursor: "pointer"
  },
  headerTextWrapper: {
    marginLeft: "30px"
  },
  yourProfileText: {
    color: "#48484A",
    borderBottom: "2px solid #48484A"
  },
  yourProfileWrapper: {
    marginTop: "40px"
  },
  textInput: {
    paddingLeft: "35px",
    flexGrow: "100",
    "&:disabled": { backgroundColor: "#E7ECF2" }
  },
  textInputIcon: {
    position: "absolute",
    top: "6px",
    left: "6px",
    color: "#AFAFAF"
  },
  yourProfileBody: {
    padding: "30px",
    backgroundColor: "white"
  },
  inputWrapper: {
    padding: "10px"
  },
  textInputTitle: {
    color: "#125693",
    paddingLeft: "5px"
  },
  keyIcon: {
    transform: "scaleX(-1) rotate(45deg)",
    position: "absolute",
    top: "6px",
    left: "6px",
    color: "#AFAFAF"
  },
  projectData: {
    backgroundColor: "#E7ECF1",
    borderRadius: "4px",
    padding: "8px  8px 8px 13px",
    height: "40px",
    boxSizing: "border-box"
  },
  submitButton: {
    color: "white",
    backgroundColor: "#58B272",
    width: "140px",
    "&:hover": {
      backgroundColor: "#66c381"
    }
  },
  submitButtonContainer: {
    marginTop: "30px"
  },
  noProject: {
    fontWeight: "100",
    marginTop: "20px",
    color: "#AFAFAF",
    textAlign: "center"
  },
  fileInput: {
    zIndex: 10,
    width: "130px",
    height: "130px"
    // opacity: 0
  },
  editIconWrapper: {
    position: "absolute",
    zIndex: "10",
    top: "103px",
    right: "3px"
  },
  backButtonWrapper: {
    marginBottom: "30px"
  },
  searchBar: {
    borderBottom: "1px solid #e1e1e1",
    padding: 5,
    position: "fixed",
    zIndex: 1,
    backgroundColor: "#f4f5f9"
  }
});

const connectedProfile = connect(mapStateToProps)(Profile);
const styledProfile = withStyles(styles)(connectedProfile);

export { styledProfile as Profile };
