import React from "react";
import Logo from "../img/PM.jpg";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { clientActions, projectActions } from "../../actions";
import { clientUserActions, projectUserActions } from "../../userActions";
import { API } from "../../constants";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import Copyright from "@material-ui/icons/Copyright";

class Header extends React.PureComponent {
  componentDidMount() {
    const { match, dispatch, role } = this.props;
    const url = match.path.split("/");
    const projectId = url[url.length - 2];
    if (role === "admin") {
      dispatch(projectActions.getProject({ projectId }));
      dispatch(clientActions.getAllCompanies());
    } else {
      dispatch(projectUserActions.getProject({ projectId }));
      dispatch(clientUserActions.getAllCompanies());
    }
  }
  render() {
    const { classes, project = {}, companies = [], address } = this.props;
    const image =
      project.client && companies[0]
        ? companies.find(company => company._id === project.client._id).image
        : "";
    return (
      <div className={classes.root}>
        <div className={classes.logo}>
          <img src={Logo} alt="logo" className={classes.logoImage} />
        </div>
        <div className={classes.data}>
          <Grid container>
            <Grid item xs={6} className={classes.item}>
              <span>
                Project Status:{" "}
                <span className={classes.normalWidth}>{project.status}</span>
              </span>
            </Grid>
            <Grid item xs={6} className={classes.item}>
              <span>
                PMDG Job Code:{" "}
                {project.pmdgjobcode && (
                  <span className={classes.normalWidth}>
                    {project.pmdgjobcode}
                  </span>
                )}
              </span>
            </Grid>
            <Grid item xs={6} className={classes.item}>
              <span>
                Program:{" "}
                {project.parentProgram && (
                  <span className={classes.normalWidth}>
                    {project.parentProgram.name}
                  </span>
                )}
              </span>
            </Grid>
            <Grid item xs={6} className={classes.item}>
              <span>
                Address:
                {address && (
                  <span className={classes.normalWidth}>{address}</span>
                )}
              </span>
            </Grid>
          </Grid>
        </div>
        <div className={classes.logo}>
          {image ? (
            <img
              className={classes.logoImage}
              src={`${API}/${image}`}
              alt="logo"
            />
          ) : (
            <Copyright className={classes.unknownLogo} />
          )}
        </div>
      </div>
    );
  }
}

const Styles = {
  root: {
    display: "flex",
    marginBottom: "35px"
  },
  logo: {
    width: "150px",
    height: "150px",
    borderRadius: "4px",
    overflow: "hidden",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  data: {
    flexGrow: 100,
    // display: "flex",
    fontSize: "20px",
    fontWeight: "500",
    padding: "0 15px 0"
  },
  logoImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
    height: "100%",
    width: "100%"
  },
  normalWidth: {
    fontWeight: "300"
  },
  item: {
    marginTop: "20px"
  },
  unknownLogo: {
    fontSize: "150px",
    color: "#80808A"
  },
  logoContainer: {}
  //   dataContainer: {
  //     display: "flex",
  //     "& span:first-child": {
  //       flex: 1
  //     },
  //     "& span:nth-child(2)": {
  //       flex: 2
  //     }
  //   }
};

const mapStateToProps = state => ({
  projectId: state.projects.project ? state.projects.project._id : undefined,
  companies: state.companies,
  project: state.projects.project,
  role: state.authentication.user.role
});

export default connect(mapStateToProps)(withStyles(Styles)(withRouter(Header)));
