// #############################################################################
// convert the array to object
// #############################################################################

export const convertToObject = (projects = []) => {
  let projectsObject = {};
  projects.forEach(project => {
    projectsObject[project._id] = { ...project };
    projectsObject[project._id].expand = false;
  });
  return projectsObject;
}

// #############################################################################
// find the root project 
// #############################################################################

export const findRootProject = (selectedProject , projects = {}) => {
  if(selectedProject && selectedProject.parentProject !== null){
    if(selectedProject.subProject.length > 0)
      projects[selectedProject._id].expand = true;
    return findRootProject(projects[selectedProject.parentProject] ,projects);
  }
  else {
    if(selectedProject)
      projects[selectedProject._id].expand = true;
    return selectedProject
  }
}

// #############################################################################
// this function checks if the tasks are showing on the board
// #############################################################################

export const isItOnTheBoard = (projectId = "", projects = {}) => {
  return projects[projectId].parentProject === null || (projects[projectId].parentProject !== null && projects[projects[projectId].parentProject].expand)
}

// #############################################################################
// calculates the level of the projects
// #############################################################################

export const calculateLevel = (projects = {}) => {

  const keys = Object.keys(projects);
  keys.forEach(key => {
    projects[key].level = getLevel(key, projects)
  });
  return projects
}

// #############################################################################
// this function will calculates the level of the project down on the project tree
// #############################################################################

export function getLevel(projectId , projects, level = 0){
  if(projects[projectId].parentProject === null) return level;
  else return getLevel(projects[projectId].parentProject, projects, level + 1)
}

// #############################################################################
// this function will find the road lines to parent project and return an array 
// of destinations
// #############################################################################

export const findRoadToParent = (selectedProject, projects, road = []) => {
  if( projects[selectedProject] && projects[selectedProject].parentProject === null)
    return [{start: selectedProject, end: selectedProject}];
  else if(projects[selectedProject]) 
    return [{start: projects[selectedProject].parentProject, end: selectedProject }, ...findRoadToParent(projects[selectedProject].parentProject, projects, road)];
  else return []
}

// #############################################################################
// this function will see if the the start and the end of the line is the part
// of the road to parent
// #############################################################################

export const isItOnTheRoad = (start, end, roadToParent) => {
  let isRoad = false;
  roadToParent.forEach(road => {
    isRoad = isRoad || (start === road.start && end === road.end)
  })
  return isRoad;
}


// #############################################################################
// this function expand the tree to show the selected project from search list
// #############################################################################

export const showProjectOnProjectTree = (id, projects) => {

  const roadToParent = findRoadToParent(id, projects).map(item => item.end)
  const roadToParentWithoutTheSelectedId = roadToParent.filter(item => item !== id)

  const projectClosed = closeAllProjects(projects);
  return expandSelectedProjects(roadToParentWithoutTheSelectedId, projectClosed)
}

// #############################################################################
// this function close all the projects
// #############################################################################

export const closeAllProjects = (projects) => {
  const outputObject = {};

  Object.keys(projects).forEach(key => {
    outputObject[key] = { ...projects[key], expand: false };
  })
  return outputObject;
}

// #############################################################################
// this function will open a list of the projects
// #############################################################################

export const expandSelectedProjects = (idArray, projects) => {
  idArray.forEach(id => { projects[id].expand = true })
  return projects;

}

// #############################################################################
// this function will calculates the width of the chard board based on the
// biggest subProjects array length
// #############################################################################

export const calculateChartWidth = (projects, nodeWidth = 170) => {
  let numberOfSubProjects = 0;
  const padding = 15;

  Object.keys(projects).forEach(key => {
    if(projects[key].expand)
    numberOfSubProjects = Math.max(projects[key].subProject.length, numberOfSubProjects)
  });

  return numberOfSubProjects * nodeWidth + padding;
}


// #############################################################################
// this function will calculates the width of the chard board based on the
// biggest subProjects array length
// #############################################################################

export const calculateOrders = (projectObject) => {
  const keys = Object.keys(projectObject);
  keys.forEach(key => {
    if(projectObject[key].subProject.length > 0 ){
      projectObject[key].subProject.forEach((id, index) => {
        projectObject[id].order = index + 1;
      })
    }

  })
}











