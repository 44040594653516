
// #############################################################################
// this function will add event listeners to the element and document in order
// to track changes in the DOM element
// #############################################################################
export function handlePercent(event, percentRef, progressRef, percentNumberRef, parentList, handleEditTask, togglePercentText){
  const mouseStartPosition = event.clientX;
  const originalWidth = percentRef.current.offsetWidth;

  const onMouseMove = event => {
   moveAt(event ,mouseStartPosition, originalWidth, percentRef, progressRef, percentNumberRef, parentList, togglePercentText);
  }

  const removeEventListeners = event => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', removeEventListeners);
    const complete = moveEnd(percentRef);
    handleEditTask({complete})
  }
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', removeEventListeners);
}

// #############################################################################
// changes the width of the percent bar
// #############################################################################
export function moveAt(event ,mouseStartPosition, originalWidth, percentRef, progressRef, percentNumberRef, parentList, togglePercentText){

  const progressWidth = progressRef.current.offsetWidth;
  const { clientX } =  event;
  const percentInPixel = originalWidth + clientX - mouseStartPosition;
  let width = percentInPixel * 100 / (progressWidth);

  width = width < 0 ? 0 : width > 100 ? 100 : width;
  percentRef.current.style.width = `${width}%`
  percentNumberRef.current.innerText = `${parseInt(width, 10)}%`
  
  percentInPixel > 50 ? togglePercentText(true) : togglePercentText(false);

  if(parentList) updateParentPercent(width, parentList);
}

// #############################################################################
// get the size of the element and return the percentage of the complete
// #############################################################################

export function moveEnd(percentRef){
  return Number(percentRef.current.style.width.split("%")[0]);
}

// #############################################################################
// updates the percentage of parent elements
// #############################################################################

export function updateParentPercent(percent, parent, index = 0){

  const percentage = (parent.sumOfOtherChildsComplete + percent) / parent.childNumber;
  parent.percentNumberNode.innerText = parseInt(percentage, 10) +"%"
  parent.percentNode.style.width = percentage +"%"

  if(parent.parent)
    updateParentPercent(percentage, parent.parent, index + 1);
}







