import React from "react";
import { connect } from "react-redux";
import { projectActions, filterActions } from "../../../actions";
import { Router, Switch, Route, withRouter, Link } from "react-router-dom";
import { history } from "../../../helpers";
import { ReactInput, GeneralInfo } from "../../";
import ThemeContext from "../../ContextApi/Context";
// #############################################################################
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// #####[ MATERIAL ICON ]#######################################################
import SearchIcon from "@material-ui/icons/Search";
import { BackButton } from "../../Buttons";

// #############################################################################
import {
  AdminProjectCosts,
  AdminProjectDashboard,
  AdminProjectUsers,
  AdminProjectSchedule,
  AdminProjectIssues,
  AdminProjectNotes,
  AdminProjectProjectDocuments,
  AdminProjectConstructionDocuments,
  AdminProjectReports,
} from "../ProjectDashboard";

// #############################################################################
// Component
// #############################################################################

class AdminProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      projectId: this.props.match.params.projectId,
    };
  }

  // ###########################################################################
  // get the selected project
  // ###########################################################################

  componentDidMount = () => {
    const projectId = this.props.match.params.projectId;
    this.props.dispatch(projectActions.getProject({ projectId }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.pageFilter !== this.props.pageFilter) {
      this.setState({ filter: this.props.pageFilter });
    }
  }

  // ###########################################################################
  // get the selected project
  // ###########################################################################

  static getDerivedStateFromProps = (props, state) => {
    const projectId = props.match.params.projectId;
    if (state.projectId !== projectId) {
      props.dispatch(projectActions.getProject({ projectId }));
      return { projectId };
    } else {
      return null;
    }
  };

  // ###########################################################################
  // clear search
  // ###########################################################################

  clearSearch = () => {
    this.setState({ filter: "" });
  };

  handleSetFilter = ({ target: { value } }) => {
    this.props.dispatch(filterActions.pageFilterEdit(value));
    this.setState({ filter: value });
  };

  // ###########################################################################
  // create routes for the selected project
  // ###########################################################################

  routes = () => {
    const projectId = this.props.match.params.projectId;
    return {
      default: `/admin-dashboard/projects/${projectId}`,
      projectDashboard: `/admin-dashboard/projects/${projectId}/project-dashboard`,
      projectUsers: `/admin-dashboard/projects/${projectId}/project-users`,
      projectSchedule: `/admin-dashboard/projects/${projectId}/project-schedule`,
      projectCosts: `/admin-dashboard/projects/${projectId}/project-costs`,
      projectIssues: `/admin-dashboard/projects/${projectId}/project-issues`,
      projectNotes: `/admin-dashboard/projects/${projectId}/project-journals`,
      projectDocuments: `/admin-dashboard/projects/${projectId}/project-documents`,
      constructionProject: `/admin-dashboard/projects/${projectId}/construction-project`,
      projectReports: `/admin-dashboard/projects/${projectId}/project-reports`,
    };
  };

  // ###########################################################################
  // Render
  // ###########################################################################

  render() {
    const { classes, project = {} } = this.props;
    const { pathname = "" } = this.props.location;
    const { projectId } = this.state;
    if (project && projectId) {
      return (
        <React.Fragment>
          <ThemeContext.Provider value={this.state.projectId}>
            {/* //////////////////// search top bar ////////////////////////// */}

            <Grid container className={classes.searchBar}>
              <BackButton />
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.searchInputHolder}
              >
                <SearchIcon className={classes.searchIcon} />
                <ReactInput
                  name="filter"
                  value={this.state.filter}
                  onChange={this.handleSetFilter}
                  placeholder="Filter list"
                  className={classes.searchInput}
                  type="text"
                />
              </Grid>
            </Grid>

            <div className={classes.tabButtons}>
              <Link to={this.routes().projectDashboard}>
                <div
                  className={
                    pathname === this.routes().projectDashboard ||
                    pathname === this.routes().default
                      ? classes.linkSelected
                      : null
                  }
                >
                  PROJECT DASHBOARD
                </div>
              </Link>

              <Link to={this.routes().projectUsers}>
                <div
                  className={
                    pathname === this.routes().projectUsers
                      ? classes.linkSelected
                      : null
                  }
                >
                  USERS
                </div>
              </Link>

              <Link to={this.routes().projectSchedule}>
                <div
                  className={
                    pathname === this.routes().projectSchedule
                      ? classes.linkSelected
                      : null
                  }
                >
                  SCHEDULE
                </div>
              </Link>

              <Link to={this.routes().projectCosts}>
                <div
                  className={
                    pathname === this.routes().projectCosts
                      ? classes.linkSelected
                      : null
                  }
                >
                  COSTS
                </div>
              </Link>

              <Link to={this.routes().projectIssues}>
                <div
                  className={
                    pathname === this.routes().projectIssues
                      ? classes.linkSelected
                      : null
                  }
                >
                  ISSUE-TASKS
                </div>
              </Link>

              <Link to={this.routes().projectNotes}>
                <div
                  className={
                    pathname === this.routes().projectNotes
                      ? classes.linkSelected
                      : null
                  }
                >
                  JOURNALS
                </div>
              </Link>

              <Link to={this.routes().projectDocuments}>
                <div
                  className={
                    pathname === this.routes().projectDocuments
                      ? classes.linkSelected
                      : null
                  }
                >
                  DOCUMENTS
                </div>
              </Link>

              <Link to={this.routes().constructionProject}>
                <div
                  className={
                    pathname.includes(this.routes().constructionProject)
                      ? classes.linkSelected
                      : null
                  }
                >
                  CONSTRUCTION
                </div>
              </Link>
              <Link to={this.routes().projectReports}>
                <div
                  className={
                    pathname === this.routes().projectReports
                      ? classes.linkSelected
                      : null
                  }
                >
                  REPORTS
                </div>
              </Link>
            </div>

            <div className={classes.tabPanelContainer}>
              <Router history={history}>
                <Switch>
                  <Route
                    path={this.routes().projectDashboard}
                    render={() => <AdminProjectDashboard />}
                  />
                  <Route
                    path={this.routes().projectUsers}
                    render={() => <AdminProjectUsers />}
                  />
                  <Route
                    path={this.routes().projectSchedule}
                    render={(props) => <AdminProjectSchedule {...props} />}
                  />
                  <Route
                    path={this.routes().projectCosts}
                    render={() => <AdminProjectCosts />}
                  />
                  <Route
                    path={this.routes().projectIssues}
                    render={() => <AdminProjectIssues />}
                  />
                  <Route
                    path={this.routes().projectNotes}
                    render={() => <AdminProjectNotes />}
                  />
                  <Route
                    path={this.routes().projectDocuments}
                    render={() => <AdminProjectProjectDocuments />}
                  />
                  <Route
                    path={this.routes().constructionProject}
                    render={() => (
                      <AdminProjectConstructionDocuments
                        searchQuery={this.state.filter}
                        projectId={projectId}
                        clearSearch={this.clearSearch}
                      />
                    )}
                  />
                  <Route
                    path={this.routes().projectReports}
                    render={() => <AdminProjectReports projectId={projectId} />}
                  />
                  <Route
                    path={this.routes().default}
                    render={() => <AdminProjectDashboard />}
                  />
                </Switch>
              </Router>
            </div>
            <GeneralInfo />
          </ThemeContext.Provider>
        </React.Fragment>
      );
    }
  }
}

// #############################################################################
// Component
// #############################################################################

const styles = (theme) => ({
  // #####[ search bar ]########################################################
  searchBar: {
    borderBottom: "1px solid #e1e1e1",
    padding: 5,
    position: "sticky",
    top: 0,
    zIndex: 1001,
    backgroundColor: "#f4f5f9",
    minHeight: "49px",
    boxSizing: "border-box",
  },
  searchInput: {
    backgroundColor: "#ffffff00",
    color: "#00000099",
    paddingLeft: "40px",
    border: "none",
    zIndex: "0",
    "&:focus": {
      boxShadow: "none",
    },
  },
  searchIcon: {
    color: "#00000070",
    position: "absolute",
    marginLeft: "5px",
    zIndex: "0",
  },
  searchInputHolder: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },

  // #####[ TABS STYLES ]#######################################################
  tabPanelContainer: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    boxSizing: "border-box",
    height: "100%",
  },
  generalInfo: {
    margin: "60px 20px 0px",
  },
  tabs: {
    flex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  tabButtons: {
    display: "flex",
    alignItems: "center",
    top: "49px",
    margin: 0,
    position: "sticky",
    borderBottom: "1px solid #e1e1e1",
    padding: "3px 15px",
    zIndex: "999",
    backgroundColor: "#f4f5f9",
    boxShadow: "0 -2px 9px #0000000d",

    "& > a > div": {
      borderRadius: "5px !important",
      padding: "2px 10px",
    },
    "& > a > div:hover": {
      backgroundColor: "#00000014",
      color: "#0048a79c",
    },
    "& > a": {
      textDecoration: "none",
      fontWeight: 600,
      color: "#000000a1",
      fontSize: 13,
      marginRight: "5px",
      lineHeight: "25px",
    },
  },
  linkSelected: {
    backgroundColor: "#487599 !important",
    color: "white !important",
  },
  info: {
    boxSizing: "border-box",
    width: "100%",
    padding: "0 8px",
    transition: "all 150ms",
    zIndex: "1",
  },
  // ###########################################################################
  waiting: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
});

// #############################################################################
// map state to props
// #############################################################################

const mapStateToProps = (state) => {
  return {
    project: state.projects.project,
    tasks: state.tasks.tasks,
    projectUsers: state.projects.users,
    pageFilter: state.filters.pageFilter,
    issueRequest: state.progressBar.issueRequest,
    clientRequest: state.progressBar.clientRequest,
    projectRequest: state.progressBar.projectRequest,
  };
};
const AdminProjectWithRouter = withRouter(AdminProject);
const AdminProjectConnect = connect(mapStateToProps)(AdminProjectWithRouter);
const AdminProjectStyles = withStyles(styles)(AdminProjectConnect);

// #############################################################################
// Component
// #############################################################################

export { AdminProjectStyles as AdminProject };
