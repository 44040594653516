// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import {
  projectActions,
  constructionActions,
  clientActions
} from "../../actions";
import {
  projectUserActions,
  constructionUserActions,
  clientUserActions
} from "../../userActions";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import WarningIcon from "@material-ui/icons/Warning";
// Components //
import Creatable from "react-select/lib/Creatable";
import Select from "react-select";
import { ReactInput } from "../";
import { DownloadIcon } from "../Icons";

// Reusable Functions //
import TimeMonthDayYear from "../reusebleFunctions/TimeMonthDayYear";
// Styles //
import { Colors } from "../../styles/colors";
import { history } from "../../helpers";

import Close from "@material-ui/icons/Close";
class PMDGAddendumForm extends React.PureComponent {
  state = {
    values: {},
    formName: "",
    categories: "",
    fields: [{ title: "", description: "" }],
    address: "",
    addendumNO: "addendumNO",
    assignedTo: "",
    files: [],
    deletedFormAttachments: []
  };

  componentDidMount() {
    const { dispatch, match, role } = this.props;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];
    const formId = url[url.length - 1];
    const formName =
      url[url.length - 2] === "addendum"
        ? "PMDG Addendum Form"
        : url[url.length - 2] === "addDailyLog"
          ? "PMDG Daily Log Form"
          : url[url.length - 2] === "addRFI"
            ? "PMDG RFI Form"
            : url[url.length - 2] === "addShopDrawingsSubmittal"
              ? "PMDG Shop Drawings Submittal Log Form"
              : "";
    if (role === "admin") {
      dispatch(projectActions.getProjectUsers({ projectId }));
      dispatch(clientActions.getAllCompanies());
      url[url.length - 1] !== "add" &&
        dispatch(constructionActions.getForm({ formId, formName }));
    } else {
      dispatch(projectUserActions.getProjectUsers({ projectId }));
      dispatch(clientUserActions.getAllCompanies());
      url[url.length - 1] !== "add" &&
        dispatch(
          constructionUserActions.getForm({ formId, formName, projectId })
        );
    }
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  componentDidUpdate(prevProps) {
    const { addresses, formData } = this.props;
    if (prevProps.addresses !== addresses) {
      this.setState({
        addresses: addresses
      });
    }
    if (prevProps.formData !== formData) {
      prevProps.formData &&
        !this.isEmpty(formData) &&
        this.setState({
          address: {
            value: formData.address,
            label: formData.address
          },
          assignedTo: formData.assignedTo.map(data => ({
            value: data.firstName + " " + data.lastName,
            label: data.firstName + " " + data.lastName,
            _id: data._id
          })),
          fields: formData.sheets,
          files: formData.attachments
        });
    }
  }

  handleGoToForm = values => {
    this.setState({
      values
    });
  };
  handleGoToEmptyForm = formName => {
    this.setState({
      formName
    });
  };
  handleCategoryChange = address => {
    this.setState({
      address
    });
  };
  handleChange = ({ target: { name, value } }, index) => {
    this.setState(prevState => ({
      fields: prevState.fields.map((field, prevInd) => {
        return prevInd !== index
          ? field
          : name === "title"
            ? { title: value, description: field.description }
            : { title: field.title, description: value };
      })
    }));
  };

  handleAddNewRow = () => {
    this.setState(prevState => ({
      fields: [...prevState.fields, { title: "", description: "" }]
    }));
  };

  handleDeleteRow = index => () => {
    const { fields } = this.state;
    if (fields.length !== 1)
      this.setState(prevState => ({
        fields: prevState.fields.filter(
          (item, filedsIndex) => filedsIndex !== index
        )
      }));
  };

  handleSave = e => {
    e.preventDefault();
    const {
      handleSave,
      addresses,
      dispatch,
      formData,
      role,
      match
    } = this.props;
    const {
      assignedTo,
      fields: sheets,
      address,
      files,
      deletedFormAttachments
    } = this.state;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];

    /////////////////////////////// ///////////////

    const document = new FormData();
    document.append("projectId", projectId);
    document.append(
      "assignedTo",
      assignedTo.map(person => person._id)
    );
    document.append("sheets", JSON.stringify(sheets));
    document.append("address", address.value);
    const isExist = addresses.find(item => item === address.value);
    const finalAddress = isExist ? addresses : [address.value, ...addresses];
    // for (var i = 0; i < finalAddress.length; i++) {
    //   document.append("addresses[]", finalAddress[i]);
    // }
    document.append("addresses", JSON.stringify(finalAddress));
    // document.append("addresses", JSON.stringify(finalAddress));

    document.append("addendumNO", 'addendumNO');

    // for (var i = 0; i < deletedFormAttachments.length; i++) {
    //   document.append("deletedFormAttachments[]", deletedFormAttachments[i]);
    // }
    deletedFormAttachments[0] &&
      document.append(
        "deletedFormAttachments",
        JSON.stringify(deletedFormAttachments)
      );

    ////////////////////////////////////////////////

    if (url[url.length - 1] !== "add") {
      document.append("formId", formData._id);
      document.append("formName", formData.name);

      for (var i = 0; i < files.length; i++) {
        if (!files[i]._id) document.append("documents", files[i]);
      }
      role === "admin"
        ? dispatch(constructionActions.editForm(document))
        : dispatch(constructionUserActions.editForm(document));
      handleSave();
      history.goBack();
    } else {
      for (i = 0; i < files.length; i++) {
        if (!files[i]._id) document.append("documents", files[i]);
      }
      role === "admin"
        ? dispatch(constructionActions.addPMDGAddendumForm(document))
        : dispatch(constructionUserActions.addPMDGAddendumForm(document));
      handleSave();
      history.goBack();
    }
  };

  handleSelectChange = e => {
    this.setState({
      assignedTo: e
    });
  };

  handleInputFile = ({ target: { files } }) => {
    this.setState(prevState => ({
      files: [...prevState.files, ...[...files].map(file => file)]
    }));
  };

  handleRemoveAttachment = index => () => {
    this.setState(prevState => ({
      files: prevState.files.filter((file, index2) => index !== index2),
      deletedFormAttachments: prevState.files[index]._id
        ? [...prevState.deletedFormAttachments, prevState.files[index]._id]
        : prevState.deletedFormAttachments
    }));
  };

  render() {
    const { classes, users, addresses = [], companies, project, formData = {}, match, constructionForms } = this.props;
    const { address, fields, assignedTo, files } = this.state;
    const company =
      companies &&
      project &&
      companies.find(com => com._id === project.client._id);
    const url = match.url.split("/");
    const addOrEdit = url[url.length - 1] === "add"
    return (
      <div className={classes.container}>
        <form onSubmit={this.handleSave}>
          <div className={classes.topItemsContainer}>
            <div className={classes.title}>PMDG Addendums </div>
            <div className={classes.address}></div>
            <span style={{ position: "relative" }}>
              <Creatable
                onChange={this.handleCategoryChange}
                value={address}
                placeholder="Click To Select Office Address"
                className={classes.addressCreate}
                options={addresses.map(address => ({
                  label: address,
                  value: address
                }))}
              />
              <input
                value={address}
                style={{
                  position: "absolute",
                  top: 30,
                  right: 0,
                  left: 0,
                  width: 0,
                  height: 0,
                  opacity: 0,
                  marginRight: "auto",
                  marginLeft: "auto"
                }}
                required
                onChange={() => { }}
              />
            </span>
          </div>
          <div className={classes.infoContainer}>
            <WarningIcon style={{ margin: "5px" }} />
            The following changes shall be incorporated into the Bid and
            superseded and/or supplement the drawing and specification wherever
            they and the addendums conflict.
          </div>
          <div className={classes.formContainer}>
            <div className={classes.topHeaderContainer}>
              <div className={classes.topHeaderTitle}>Pmdg Project Name</div>
              <div className={classes.centerFullHeight}>
                <div
                  className={classes.topHeaderData}
                  style={{ border: "none" }}
                >
                  <strong>Project Name:</strong> &nbsp;{" "}
                  {` ${project && project.name}`}
                </div>
                <div className={classes.topHeaderData}>
                  <strong>Addendums NO:</strong> &nbsp; {addOrEdit ? constructionForms.reduce((repo, item) => item.name === 'PMDG Addendum Form' ? repo + 1 : repo, 0) + 1 : ` ${formData.formNumber}`}
                </div>
                <div className={classes.topHeaderData}>
                  <strong>Client:</strong> &nbsp;{" "}
                  {` ${company ? company.name : null}`}
                </div>
                <div className={classes.topHeaderBlueData}>
                  <strong>Date:</strong> &nbsp;{" "}
                  {` ${TimeMonthDayYear(new Date())}`}
                </div>
              </div>
            </div>
            <div className={classes.otherContentContainer}>
              <div className={classes.forContainer}>
                <Select
                  // options={this.projectStatuses()}
                  name={"assignedTo"}
                  value={assignedTo}
                  onChange={this.handleSelectChange}
                  required
                  placeholder="To"
                  maxMenuHeight={250}
                  isMulti
                  options={users.map(user => ({
                    label: `${user.user.firstName} ${user.user.lastName}`,
                    value: `${user.user.firstName} ${user.user.lastName}`,
                    _id: user.user._id
                  }))}
                />
                <input
                  value={assignedTo}
                  style={{
                    position: "absolute",
                    top: 30,
                    right: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    opacity: 0,
                    marginRight: "auto",
                    marginLeft: "auto"
                  }}
                  required
                  onChange={() => { }}
                />
              </div>
              <div>
                {fields.map((field, index) => (
                  <div className={classes.inputsContainer} key={index}>
                    <ReactInput
                      name="title"
                      value={fields[index].title}
                      onChange={e => this.handleChange(e, index)}
                      required
                      placeholder={`Sheet ${index + 1}`}
                      autoComplete="off"
                      type="text"
                      className={classes.typeInput}
                    />
                    <ReactInput
                      name="description"
                      value={fields[index].description}
                      onChange={e => this.handleChange(e, index)}
                      required
                      placeholder={`Description`}
                      autoComplete="off"
                      type="text"
                      className={classes.descriptionInput}
                    />
                    <Button
                      className={classes.closeIconButton}
                      onClick={this.handleDeleteRow(index)}
                    >
                      <Close className={classes.closeIcon} />
                    </Button>
                  </div>
                ))}
                <Grid
                  container
                  justify="flex-start"
                  className={classes.addNewRowContainer}
                >
                  <Button
                    className={`${classes.addNewRowButton} ${classes.button}`}
                    onClick={this.handleAddNewRow}
                  >
                    <Add className={classes.addIcon} />
                    <span className={classes.addNewlineSpan}>
                      ADD NEW LINE ITEM
                    </span>
                  </Button>
                </Grid>
                <Grid container className={classes.attachmentButtonWrapper}>
                  <Grid item xs={2}>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="raised-button-file"
                      multiple
                      type="file"
                      onChange={this.handleInputFile}
                      name="files"
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        // raised
                        component="span"
                        className={`${classes.attachmentButton} ${classes.button}`}
                      >
                        <Add className={classes.addIcon} />
                        ATTACHMENT
                      </Button>
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    container
                    justify="flex-end"
                    alignItems="flex-start"
                  >
                    {[...files].map((file, index) => (
                      <span className={classes.fileRepresent} key={index}>
                        <Close
                          className={classes.closeIconAttachment}
                          onClick={this.handleRemoveAttachment(index)}
                        />
                        <span className={classes.downloadIconAttachment}>
                          <DownloadIcon link={file.link} />
                        </span>
                        <span className={classes.fileName}>{file.name}</span>
                      </span>
                    ))}
                  </Grid>
                </Grid>

                <div className={classes.saveButtonContainer}>
                  <Button type="submit" className={classes.saveButton}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const Styles = {
  container: {
    padding: "10px 15px"
  },
  topItemsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title: {
    fontSize: "20px",
    color: Colors.darkBlue
  },
  address: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0",
    paddingBottom: "15px"
  },
  addressCreate: {
    width: "300px",
    fontSize: "12px"
  },
  forContainer: {
    marginTop: "20px",
    position: "relative"
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffeb3b14",
    border: `1px solid #969696`,
    color: "#969696",
    borderRadius: "5px",
    padding: "5px",
    marginTop: "20px",
    fontSize: "13px"
  },
  formContainer: {
    backgroundColor: "#fdfdfd",
    marginTop: "15px",
    borderRadius: "4px"
  },
  centerFullHeight: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%"
  },
  topHeaderContainer: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px #a6a6a6"
  },
  otherContentContainer: {
    padding: "20px"
  },
  topHeaderTitle: {
    height: "calc(100% - 25px)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "20px",
    padding: "0 10px",
    color: "green"
  },
  topHeaderData: {
    height: "calc(100% - 25px)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "13px",
    padding: "0 10px",
    borderLeft: "solid 1px #a6a6a6"
  },
  topHeaderBlueData: {
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "13px",
    padding: "0 10px",
    backgroundColor: "#83a9c7",
    color: "white"
  },
  inputsContainer: {
    display: "flex",
    marginTop: "10px"
  },
  typeInput: {
    flex: 2,
    marginRight: "5px"
  },
  descriptionInput: {
    flex: 10,
    marginLeft: "5px"
  },
  addNewRowContainer: {
    marginTop: "20px"
  },
  addNewRowButton: {
    backgroundColor: "#A6A6A6",
    borderRadius: "5px",
    position: "relative",
    "&:hover": {
      backgroundColor: Colors.darkGrey
    }
  },
  addNewlineSpan: {
    color: "white",
    fontSize: "13px",
    marginTop: "2px"
  },
  addIcon: {
    fontSize: "21px",
    color: "white",
    marginRight: "10px"
  },
  saveButtonContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  saveButton: {
    width: "130px",
    backgroundColor: Colors.green,
    color: "white",
    fontSize: "13px",
    padding: "6px 40px!important",
    borderRadius: "5px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: Colors.darkGreen
    }
  },
  cancelButton: {
    width: "130px",
    backgroundColor: Colors.grey,
    color: "white",
    fontSize: "13px",
    padding: "6px 40px!important",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: Colors.darkGrey
    }
  },
  closeIconButton: {
    width: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: Colors.darkGrey,
    marginLeft: "5px"
  },
  attachmentButton: {
    backgroundColor: Colors.darkBlue,
    color: "white",
    "&:hover": {
      backgroundColor: "#1661A8"
    }
  },
  attachmentButtonWrapper: {
    marginTop: "20px"
  },
  button: {
    paddingRight: "20px",
    paddingLeft: "20px",
    borderRadius: "5px"
  },
  input: {
    position: "absolute",
    opacity: 0,
    width: 0
  },
  fileRepresent: {
    borderRadius: "5px",
    border: "1px solid #D6D6D8",
    padding: "30px",
    margin: "5px",
    position: "relative",
    display: "inline-flex"
  },
  closeIconAttachment: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: Colors.grey,
    fontSize: "16px",
    cursor: "pointer"
  },
  downloadIconAttachment: {
    marginRight: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
const mapStateToProps = state => ({
  addresses: state.projects.project
    ? state.projects.project.addresses !== null
      ? state.projects.project.addresses
      : []
    : [],
  users: state.projects.users ? state.projects.users : [],
  role: state.authentication.user.role,
  project: state.projects.project,
  companies: state.companies,
  formData: state.construction,
  constructionForms: state.constructions
});
export default connect(mapStateToProps)(withStyles(Styles)(PMDGAddendumForm));
