import React from "react";
import { ReactInput } from "../";
import Select from "react-select";
import HOCModal from "../Modal/Modal";
import { CommitModalContent } from "./commit-modal-content";
import { issueActions } from "../../actions";
import { issueUserActions } from "../../userActions";
import ThemeContext from "../ContextApi/Context";

// #####[ MATERIAL COMPONENTS ]#################################################
import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import PDatePicker from "components/DatePicker/DatePicker";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import AddAttachmentsButton from "../Buttons/AddAttachment/AddAttachmentsButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Info from "@material-ui/icons/Info";
import Flag from "@material-ui/icons/Flag";
// #####[ MATERIAL ICONS ]######################################################
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CommentIcon from "@material-ui/icons/Comment";

// Actions
import { attachmentActions } from "../../actions";
import { attachmentUserActions } from "../../userActions/";

class IssueItem extends React.PureComponent {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.state = {
      originalData: props.data,
      delay: props.data.delay,
      description: props.data.description,
      assignedTo: {
        value: props.data.assignedTo._id,
        label: `${props.data.assignedTo.firstName} ${props.data.assignedTo.lastName} `
      },
      dueDate: new Date(props.data.dueDate),
      name: props.data.name,
      order: props.data.order,
      _id: props.data._id,
      done: props.data.done,
      editMode: false,
      hasAttachment: props.data.hasAttachment,
      isChangeHappend: false,
      showCommitModal: false,
      acceptedOrDenied: undefined,
      doneCheckbox: false,
      important: props.data.important
    };
  }

  componentDidMount() {
    this.setState({
      doneCheckbox: this.props.data.status === "done" ? true : false
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        doneCheckbox: this.props.data.status === "done" ? true : false
      });
    }
  }
  // ###########################################################################
  // handle inputs change
  // ###########################################################################

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      isChangeHappend: true
    });
  };

  // ###########################################################################
  // handle select
  // ###########################################################################

  handleSelectChange = (option, event) => {
    this.setState({ [event.name]: option, isChangeHappend: true });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleChangePDatePicker = date => {
    this.setState({ dueDate: date, isChangeHappend: true });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleCloseModal = done => {
    this.setState({ showCommitModal: false, done });
  };

  // ###########################################################################
  // handle the display of delay
  // ###########################################################################

  handleDelayTime = dueDate => {
    let delay = Math.abs(dueDate - new Date());
    if (dueDate - new Date() < 0) {
      const month = parseInt(delay / 2592000000, 10);
      delay = delay - month * 2592000000;
      const week = parseInt(delay / 604800000, 10);
      delay = delay - week * 604800000;
      const day = parseInt(delay / 86400000, 10);
      delay = delay - day * 86400000;

      const monthString =
        month > 0 ? (month > 1 ? `${month} Months` : `${month} Month`) : ``;
      const weekString =
        week > 0 ? (week > 1 ? `${week} Weeks` : `${week} Week`) : ``;
      const dayString = day > 0 ? (day > 1 ? `${day} Days` : `${day} Day`) : ``;
      const output = `${monthString}${
        month > 0 ? (week > 0 ? (day > 0 ? `, ` : ` and `) : ``) : ``
      } ${weekString} ${
        (week > 0 || month > 0) && day > 0 ? `and ` : ``
      } ${dayString}`.trim();

      return output === `` ? "today" : output;
    } else return `No Delay`;
  };

  // ###########################################################################
  // Date Formatter
  // ###########################################################################
  dateFormatter = dateString => {
    const date = new Date(dateString);
    return (
      date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()
    );
  };

  // ###########################################################################
  // close edit mode
  // ###########################################################################

  closeEditMode = () => {
    this.setState({
      editMode: false,
      description: this.props.data.description,
      assignedTo: {
        value: this.props.data.assignedTo._id,
        label: `${this.props.data.assignedTo.firstName} ${this.props.data.assignedTo.lastName} `
      },
      dueDate: new Date(this.props.data.dueDate),
      name: this.props.data.name,
      order: this.props.data.order,
      important: this.props.data.important
    });
  };

  // ###########################################################################
  // open edit mode
  // ###########################################################################

  openEditMode = () => {
    this.setState({ editMode: true });
  };

  // ###########################################################################
  // render
  // ###########################################################################

  handleEdit = () => {
    const {
      assignedTo,
      _id,
      done,
      dueDate,
      description,
      name,
      delay,
      order,
      originalData,
      file,
      isChangeHappend,
      important
    } = this.state;
    const projectId = this.context;
    const data = {
      issueTaskId: _id,
      assignedTo:
        assignedTo.value !== originalData.assignedTo._id
          ? assignedTo.value
          : undefined,
      done: done !== originalData.done ? done : undefined,
      dueDate:
        dueDate.toISOString() !== originalData.dueDate ? dueDate : undefined,
      description:
        description !== originalData.description ? description : undefined,
      name: name !== originalData.name ? name : undefined,
      delay: delay !== originalData.delay ? delay : undefined,
      order: order !== originalData.order ? order : undefined,
      important: important !== originalData.important ? important : undefined
    };
    isChangeHappend &&
      this.props.handleEdit(data) &&
      this.setState({ isChangeHappend: false });
    this.setState({ editMode: false, isChangeHappend: false });

    if (file) {
      let document = new FormData();
      document.append("projectId", projectId);
      document.append("issueTaskId", this.props.data._id);
      document.append("document", file);

      if (this.props.role === "adminFlag")
        this.props.dispatch(attachmentActions.addAttachment(document));
      else this.props.dispatch(attachmentUserActions.addAttachment(document));

      this.setState({
        file: null,
        hasAttachment: true,
        isChangeHappend: false
      });
    }
  };

  // ###########################################################################
  // render
  // ###########################################################################

  handleDelete = () => {
    this.props.handleDeleteModal(this.state);
  };

  // ###########################################################################
  // create list of users for the select box
  // ###########################################################################

  decorateUsers = users => {
    return users.map(item => ({
      value: item.user._id,
      label: `${item.user.firstName} ${item.user.lastName}`
    }));
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleIcon = (clickedRowDataId, index) => {
    this.props.handlerInfoModal(true);
    this.props.setClickedRowDataToState(clickedRowDataId);
    this.props.handleChangeTabs(index);
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleBrowseFile = file => {
    this.setState({
      file
    });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handlerInfoModal = bool => {
    this.setState({ showCommitModal: !bool });
  };

  // ###########################################################################
  // handle commit for the selected issue
  // ###########################################################################

  handleCommit = data => {
    const { dispatch, role } = this.props;
    role === "adminFlag"
      ? dispatch(
          issueActions.editIssue({
            ...data,
            role: undefined,
            status: "done"
          })
        )
      : dispatch(
          issueUserActions.editIssue({
            ...data,
            role: undefined,
            status: "done"
          })
        );
    this.setState({
      doneCheckbox: true
    });
  };

  // ###########################################################################
  // handle change check box
  // ###########################################################################

  handleCheckbox = ({ target: { checked } }) => {
    const { dispatch, role, data } = this.props;
    const projectId = this.context;

    if (!checked)
      if (role === "adminFlag") {
        dispatch(
          issueActions.editIssue({
            issueTaskId: data._id,
            status: "open"
          })
        );
      } else {
        dispatch(
          issueUserActions.editIssue({
            issueTaskId: data._id,
            status: "open",
            projectId
          })
        );
      }

    if (checked)
      this.setState({
        showCommitModal: checked
      });
  };

  handleCheckboxChange = e => {
    const { role, dispatch, data, adminRole } = this.props;
    role === "adminFlag" || adminRole
      ? dispatch(
          issueActions.editIssue({
            issueTaskId: data._id,
            status: e,
            projectId: data.project._id
          })
        )
      : dispatch(
          issueUserActions.editIssue({
            issueTaskId: data._id,
            status: e,
            projectId: data.project._id
          })
        );
  };

  handleImportantChange = ({ target }) => {
    this.setState({
      [target.name]: target.checked,
      isChangeHappend: true
    });
  };

  handleFlag = () => {
    this.setState(prevState => ({
      important: !prevState.important
    }));
  };

  // ###########################################################################
  // render
  // ###########################################################################

  render = () => {
    const {
      classes,
      projectUsers,
      role,
      data,
      workSpace,
      isMine,
      widget
    } = this.props;
    const {
      dueDate,
      hasAttachment,
      showCommitModal,
      acceptedOrDenied,
      doneCheckbox,
      name,
      description,
      assignedTo,
      important
    } = this.state;

    const projectId = this.context;
    const delay = this.state.dueDate - new Date();
    if (!this.state.editMode)
      return (
        <React.Fragment>
          <div
            className={`${classes.root} ${
              this.state.done ? classes.checked : null
            }`}
            onDoubleClick={() =>
              role !== "Project-Client" &&
              !workSpace &&
              this.setState({ editMode: true })
            }
            style={
              data.status === "pending" || data.status === "rejected"
                ? { backgroundColor: "white" }
                : {}
            }
          >
            <div
              style={{ flex: 3 }}
              className={`${classes.dataProps} ${classes.issueName}`}
            >
              {important ? (
                <Flag className={classes.flagWithOutTooltip} />
              ) : (
                <Flag className={classes.notFlagWithOutTooltip} />
              )}
              {role !== "Project-Client" ? (
                data.status === "pending" || data.status === "rejected" ? (
                  <Info
                    style={
                      data.status === "pending"
                        ? { color: "#025499" }
                        : { color: "#EC2A25" }
                    }
                    className={classes.infoIcon}
                  />
                ) : (
                  <Checkbox
                    checked={doneCheckbox}
                    onChange={this.handleCheckbox}
                    classes={{
                      root: classes.checkBoxRoot,
                      checked: classes.checkboxColor
                    }}
                    disabled={!isMine}
                  />
                )
              ) : null}
              {workSpace ? (
                <div className={classes.info}>{data.project.name}</div>
              ) : (
                <div className={classes.info}>{`${this.state.name}`}</div>
              )}
            </div>

            {workSpace && (
              <div
                style={{ flex: 3 }}
                className={`${classes.dataProps} ${classes.ownerOfIssue}`}
              >
                <div className={classes.info}>{data.name}</div>
              </div>
            )}

            <div
              style={{ flex: 4.5 }}
              className={`${classes.dataProps} ${classes.issueDescription}`}
            >
              <div className={classes.info}>{this.state.description}</div>
            </div>
            {!workSpace && (
              <div
                style={{ flex: 3 }}
                className={`${classes.dataProps} ${classes.ownerOfIssue}`}
              >
                <div
                  className={classes.info}
                >{`${this.state.assignedTo.label}`}</div>
              </div>
            )}
            <div
              style={{ flex: 2 }}
              className={`${classes.dataProps} ${classes.numbers}`}
            >
              <div className={classes.info}>
                {this.dateFormatter(this.state.dueDate)}
              </div>
            </div>
            <div
              style={{ flex: 3 }}
              className={`${classes.dataProps} ${classes.numbers} ${classes.delayTime}`}
            >
              <div
                className={`${classes.info} ${
                  delay < 0 && !this.state.done ? classes.red : ``
                }`}
              >
                {this.handleDelayTime(this.state.dueDate)}
              </div>
            </div>

            {widget && (
              <div
                style={{ flex: 2 }}
                className={`${classes.dataProps} ${classes.numbers}`}
              >
                <div className={`${classes.info}`}>
                  <div className={classes.statusWrapper}>
                    {data.status === "open" || data.status === "done" ? (
                      <span className={classes.accepted}>Accepted</span>
                    ) : data.status === "pending" ? (
                      <span className={classes.notAccepted}>Not Accepted</span>
                    ) : (
                      <span className={classes.denied}>Denied</span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {widget ? (
              <>
                {!(data.status === "rejected" || data.status === "pending") ? (
                  <div className={classes.widgetActionsWrapper}>
                    <Tooltip title="Comments">
                      <IconButton
                        component="span"
                        className={classes.iconButtonComment}
                        onClick={() => this.handleIcon(data._id, 2)}
                      >
                        <CommentIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="show info">
                      <IconButton
                        onClick={() => this.handleIcon(data._id, 1)}
                        component="span"
                        className="cost-list-info"
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <span style={{ width: "71px" }}></span>
                )}
              </>
            ) : (
              <div className={`${classes.dataProps} ${classes.buttons}`}>
                {data.status !== "rejected" && data.status !== "pending" ? (
                  hasAttachment ? (
                    <Tooltip title="Attach File">
                      <IconButton
                        component="span"
                        className={classes.iconButtonAttach}
                        onClick={() => this.handleIcon(data._id, 0)}
                      >
                        <AttachmentIcon className={classes.rotate45Deg} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <span style={{ margin: "6px" }}>
                      <AttachmentIcon
                        style={{ color: "#EFEFEF", transform: "rotate(45deg)" }}
                      />
                    </span>
                  )
                ) : (
                  <>
                    {isMine && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={acceptedOrDenied}
                              onChange={() => this.handleCheckboxChange("open")}
                              value="accept"
                              color="primary"
                            />
                          }
                          label="Accept"
                          className={classes.checkbox}
                        />
                        {data.status !== "rejected" && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={acceptedOrDenied}
                                onChange={() =>
                                  this.handleCheckboxChange("rejected")
                                }
                                value="deny"
                                color="secondary"
                              />
                            }
                            label="Deny"
                            className={classes.checkbox}
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                {role !== "Project-Client" ? (
                  <React.Fragment>
                    {data.status !== "rejected" && data.status !== "pending" && (
                      <Tooltip title="Comments">
                        <IconButton
                          component="span"
                          className={classes.iconButtonComment}
                          onClick={() => this.handleIcon(data._id, 2)}
                        >
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!workSpace && (isMine || role === "adminFlag") && (
                      <Tooltip title="Edit Issue">
                        <IconButton
                          onClick={this.openEditMode}
                          className={classes.iconButtonEdit}
                          component="span"
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    {data.status !== "rejected" &&
                      data.status !== "pending" &&
                      (isMine || role === "adminFlag") && (
                        <Tooltip title="Delete Issue">
                          <IconButton
                            onClick={this.handleDelete}
                            component="span"
                            className={classes.iconButtonDelete}
                          >
                            <CancelOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                  </React.Fragment>
                ) : null}
                {data.status !== "rejected" && data.status !== "pending" && (
                  <Tooltip title="show info">
                    <IconButton
                      onClick={() => this.handleIcon(data._id, 1)}
                      component="span"
                      className="cost-list-info"
                    >
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}
          </div>

          {/* ################################################################ */}

          <HOCModal
            isShowed={showCommitModal}
            handlerInfoModal={this.handlerInfoModal}
          >
            <CommitModalContent
              role={role}
              projectId={projectId}
              data={data}
              handleClose={this.handleCloseModal}
              handleCommit={this.handleCommit}
            />
          </HOCModal>

          {/* ################################################################ */}
        </React.Fragment>
      );
    // #######################################################################
    else
      return (
        <div
          className={classes.root}
          style={
            data.status === "pending" || data.status === "rejected"
              ? { backgroundColor: "white" }
              : {}
          }
        >
          <div
            style={{ flex: 3 }}
            className={`${classes.dataProps} ${classes.issueName}`}
          >
            {role !== "Project-Client" && (
              <Tooltip title="Flag" className={classes.flagTooltip}>
                <IconButton
                  component="span"
                  onClick={this.handleFlag}
                  className={
                    important ? classes.importantTrue : classes.importantFalse
                  }
                >
                  <Flag className={classes.flagIcon} />
                </IconButton>
              </Tooltip>
            )}
            <ReactInput
              required
              name="name"
              placeholder="Issue Name"
              value={name}
              // className={`${classes.inputs} ${classes.textDecorationLine}`}
              className={classes.inputs}
              onChange={this.handleChange}
              type="text"
            />
          </div>
          <div
            style={{ flex: 4.5 }}
            className={`${classes.dataProps} ${classes.issueDescription}`}
          >
            <ReactInput
              required
              className={classes.inputs}
              name="description"
              placeholder="Description"
              value={description}
              onChange={this.handleChange}
              type="text"
            />
          </div>
          <div
            style={{ flex: 3 }}
            className={`${classes.dataProps} ${classes.numbers}`}
          >
            <Select
              options={this.decorateUsers(projectUsers)}
              name={"assignedTo"}
              onChange={this.handleSelectChange}
              className={classes.inputs}
              required
              placeholder="Assigned To"
              maxMenuHeight={120}
              value={assignedTo}
            />
          </div>
          <div
            style={{ flex: 2 }}
            className={`${classes.dataProps} ${classes.numbers}`}
          >
            {/* <div className={classes.info}>{this.handleDelayTime(this.state.delay)}</div> */}
            <PDatePicker
              onChange={this.handleChangePDatePicker}
              name="dueDate"
              selected={dueDate} //FIXME:
              placeholderText="Due Date"
              className={classes.datePicker}
            />
          </div>
          <div
            style={{ flex: 3 }}
            className={`${classes.dataProps} ${classes.numbers} ${classes.delayTime}`}
          >
            <div
              className={`${classes.info} ${
                delay < 0 && !this.state.done ? classes.red : ``
              }`}
            >
              {this.handleDelayTime(this.state.dueDate)}
            </div>
          </div>

          <div className={`${classes.dataProps} ${classes.buttons}`}>
            {/* <Button className={classes.addButton} onClick={this.handleAddFile}> */}
            <AddAttachmentsButton handleBrowseFile={this.handleBrowseFile} />
            {/* </Button> */}
            <Button className={classes.saveButton} onClick={this.handleEdit}>
              {"SAVE"}
            </Button>
            <Button
              className={classes.cancelButton}
              onClick={this.closeEditMode}
            >
              {"CANCEL"}
            </Button>
          </div>
        </div>
      );
  };
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root: {
    display: "flex",
    boxSizing: "border-box",
    marginBottom: "6px"
  },
  dataProps: {
    boxSizing: "border-box",
    padding: "5px",
    margin: "0 3px",
    backgroundColor: "white",
    borderRadius: "2px",
    display: "flex",
    alignItems: "center",
    color: "#0000008a",
    fontSize: "15px",
    minHeight: "48px"
  },
  iconButtonEdit: {
    padding: "6px",
    color: "#0ad871 !important"
  },
  iconButtonDelete: {
    padding: "6px",
    color: "#ec6a16 !important"
  },
  iconButtonComment: {
    color: "#00000070",
    padding: "6px",
    fontSize: "16px"
  },
  iconButtonAttach: {
    padding: "6px",
    color: "#aaa !important"
  },
  issueDescription: {
    fontSize: "13px",
    color: "#0000006b"
  },
  issueName: {
    color: "#000000c9"
  },
  numbers: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "13px"
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    width: "230px",
    padding: "5px 5px !important"
  },
  cancelButton: {
    backgroundColor: "#bcbcbc",
    color: "white",
    height: "38px",
    margin: "0px 2px",
    fontSize: "10px",
    fontWeight: "900",
    "&:hover": {
      backgroundColor: "#afadad"
    },
    "&:active": {
      backgroundColor: "#afadad"
    }
  },
  saveButton: {
    backgroundColor: "#58b370",
    color: "white",
    height: "38px",
    margin: "0px 2px",
    fontSize: "10px",
    fontWeight: "900",
    "&:hover": {
      backgroundColor: "#4a965e"
    },
    "&:active": {
      backgroundColor: "#4a965e"
    }
  },
  addButton: {
    backgroundColor: "#155794",
    color: "white",
    height: "38px",
    margin: "0px 2px",
    fontSize: "10px",
    fontWeight: "900",
    "&:hover": {
      backgroundColor: "#216FB7"
    },
    "&:active": {
      backgroundColor: "#216FB7"
    }
  },
  ownerOfIssue: { fontSize: "13px" },
  inputs: { width: "100%" },
  info: { padding: "0px 10px" },
  checkBoxRoot: { padding: "5px !important" },
  // checked: { textDecorationLine: "line-through" },
  checkboxColor: { color: "#00d068 !important" },
  red: { color: "red" },
  delayTime: { justifyContent: "flex-start" },
  rotate45Deg: { transform: "rotate(45deg)" },
  chooseFile: {
    backgroundColor: "#155794",
    display: "flex!important",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    fontSize: "10px",
    borderRadius: "5px",
    fontWeight: "900",
    flex: "1",
    width: "100%",
    height: "38px",
    boxsizing: "border-box",
    border: 0,
    cursor: "pointer",
    position: "relative",
    boxSizing: "border-box",
    color: "white!important",
    "& :hover": {
      backgroundColor: "#1f6eb7",
      borderRadius: "5px"
    },
    "& input": {
      opacity: 0,
      width: 0,
      height: 0,
      position: "absolute",
      top: 0,
      left: 0,
      cursor: "pointer"
    },
    "& > span": {
      width: "100%",
      height: "100%",
      zIndex: 100,
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    "& svg": {
      cursor: "pointer"
    }
  },
  doneIcon: {
    fontSize: "16px",
    marginRight: "4px"
  },
  attachmentIcon: {
    transform: "rotate(45deg)",
    fontSize: "16px",
    marginRight: "4px"
  },
  datePicker: {
    fontSize: "15px",
    height: "34px",
    borderRadius: "4px",
    border: "1px solid #C7C7C7",
    paddingLeft: "5px",
    width: "100px",
    "&:focus": {
      border: "1px solid #2483FF",
      boxShadow: "0 0 3px #2483FF"
    }
  },
  checkbox: {
    marginLeft: 0,
    marginRight: "10px",
    "& span": {
      fontSize: "13px",
      padding: "1px"
    }
  },
  formControl: {
    marginRight: 0
  },
  important: {
    color: "#FF0000"
  },
  widgetActionsWrapper: {
    width: "71px",
    minWidth: "71px",
    maxWidth: "71px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  accepted: {
    color: "#00D068"
  },
  notAccepted: {
    color: "#025499"
  },
  denied: {
    color: "#EC2A25"
  },
  infoIcon: {
    padding: "5px!important"
  },
  statusWrapper: {
    width: "70px",
    minWidth: "70px",
    maxWidth: "70px",
    textAlign: "left"
  },
  importantTrue: {
    backgroundColor: "red",
    borderRadius: "100%",
    color: "white",
    padding: "3px!important",
    "&:hover": {
      color: "white",
      backgroundColor: "red"
    }
  },
  importantFalse: {
    color: "gray",
    opacity: "0.3",
    padding: "3px!important",
    "&:hover": {
      color: "gray"
    }
  },
  flagIcon: {
    fontSize: "24px"
  },
  flagTooltip: {
    marginRight: "15px"
  },
  flagWithOutTooltip: {
    backgroundColor: "red",
    borderRadius: "100%",
    color: "white",
    padding: "3px!important",
    marginRight: "15px"
  },
  notFlagWithOutTooltip: {
    color: "gray",
    opacity: "0.3",
    padding: "3px!important",
    marginRight: "15px"
  }
});

// #############################################################################
// connect material styles to the component
// #############################################################################

const IssueItemWithStyles = withStyles(styles)(IssueItem);

// #############################################################################
// export
// #############################################################################

export { IssueItemWithStyles as IssueItem };
