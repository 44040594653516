export const taskConstants = {
  ADD_TASK_SUCCESS: "ADD_TASK_SUCCESS",
  ADD_TASK_FAILURE: "ADD_TASK_FAILURE",

  GET_PROJECT_TASKS_REQUEST: "GET_PROJECT_TASKS_REQUEST",
  GET_PROJECT_TASKS_SUCCESS: "GET_PROJECT_TASKS_SUCCESS",
  GET_PROJECT_TASKS_FAILURE: "GET_PROJECT_TASKS_FAILURE",

  EDIT_TASK_SUCCESS: "EDIT_TASK_SUCCESS",
  EDIT_TASK_FAILURE: "EDIT_TASK_FAILURE",

  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILURE: "DELETE_TASK_FAILURE",

  REMOVE_SUB_TASK_SUCCESS: "REMOVE_SUB_TASK_SUCCESS",
  REMOVE_SUB_TASK_FAILURE: "REMOVE_SUB_TASK_FAILURE",

  CLEARING_TASK_DATA: "CLEARING_TASK_DATA"
};
