import React from "react";
import { withStyles } from "@material-ui/core";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Colors } from "../../styles/colors";

const styles = {
  iconButton: {
    padding: "3px !important",
    color: Colors.grey
  },
  fileIcon: {
    fontSize: "28px"
  }
};

export const FileIcon = React.memo(
  withStyles(styles)(
    ({
      classes,
      onClick,
      color = Colors.grey,
      className = {},
      withoutTooltip
    }) => (
      <>
        {withoutTooltip ? (
          <>
            <InsertDriveFile
              className={classes.fileIcon}
              style={{ color: color }}
            />
          </>
        ) : (
          <Tooltip title={"File"} className={className}>
            <IconButton
              onClick={onClick}
              className={classes.iconButton}
              component="span"
            >
              <InsertDriveFile
                className={classes.fileIcon}
                style={{ color: color }}
              />
            </IconButton>
          </Tooltip>
        )}
      </>
    )
  )
);
