// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { commentActions } from "../../actions";
import { commentUserActions } from "../../userActions";

// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// Components //
import { CancelButton, SaveButton } from "../Buttons";
import AddAttachmentsButton from "../Buttons/AddAttachment/AddAttachmentsButton";

// Reusable Functions //

// Styles //
import { Colors } from "../../styles";

class AddNewComment extends React.PureComponent {
  state = {
    commentText: "",
    attachment: null
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isAddNewComment !== this.props.isAddNewComment)
      this.setState({ isAddNewComment: this.props.isAddNewComment });
  }

  handleCommentCancel = () => {
    this.setState({
      commentText: ""
    });
    this.props.handleAddCommentButton();
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
      isChangeHappend: true
    });
  };

  handleBrowseFile = attachment => {
    this.setState({
      attachment
    });
  };

  handleCommentSubmit = e => {
    // e.preventDefault()
    const {
      dispatch,
      projectId,
      role,
      noteId,
      parentComment,
      handleOpenCommentsInAddingNewComment
    } = this.props;
    const { commentText, attachment } = this.state;

    let document = new FormData();
    document.append("projectId", projectId);
    document.append("text", commentText);
    document.append("noteId", noteId);
    document.append("addOrDelete", "add");
    parentComment && document.append("parentComment", parentComment);
    attachment && document.append("document", attachment);

    dispatch(
      role === "adminFlag"
        ? commentActions.addComment(document)
        : commentUserActions.addComment(document)
    );
    this.setState({
      commentText: ""
    });
    this.props.handleAddCommentButton();
    handleOpenCommentsInAddingNewComment &&
      handleOpenCommentsInAddingNewComment();
  };

  render() {
    const { classes } = this.props;
    const { commentText } = this.state;
    return (
      <>
        <Grid container className={classes.addNewCommentWrapper}>
          <Grid item xs className={classes.textareaWrapper}>
            <textarea
              className={classes.addNewCommentTextarea}
              placeholder="Write your comment"
              value={commentText}
              name="commentText"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid className={classes.actionsWrapper}>
            <AddAttachmentsButton handleBrowseFile={this.handleBrowseFile} />
            <SaveButton
              disabled={!commentText}
              onClick={this.handleCommentSubmit}
            />
            <CancelButton onClick={this.handleCommentCancel} />
          </Grid>
        </Grid>
      </>
    );
  }
}

const Styles = {
  addNewCommentWrapper: {
    margin: "10px 8px 10px 120px",
    paddingTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid" + Colors.lightGrey
  },
  addNewCommentTextarea: {
    borderRadius: "30px",
    width: "100%",
    padding: "9px 10px"
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "7px"
  },
  textareaWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

const mapStateToProps = state => ({
  projectId: state.projects.project._id,
  role: state.projects.project.role
});

export default connect(mapStateToProps)(withStyles(Styles)(AddNewComment));
