import React from 'react'
import { withStyles } from '@material-ui/core'
import { InsertDriveFile, ArrowUpward } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../styles/colors'

const styles = {
    iconButton: {
        padding: '3px !important',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
    },
    arrowUpwardIcon: {
        position: 'absolute',
        color: Colors.darkenThanLightGrey,
        top: '13px', left: '9px', fontSize: '11px'
    }
}

export const UploadFile = React.memo(withStyles(styles)(({ classes, onClick, arrowColor = '#BCBCBC' }) => (
    <Tooltip title='Minimize'>
        <IconButton onClick={onClick} className={classes.iconButton} component="span">
            <InsertDriveFile className={classes.insertIcon} />
            <ArrowUpward className={classes.arrowUpwardIcon} style={{ color: arrowColor }} />
        </IconButton>
    </Tooltip>
))
)
