// this component use handleBrowseFile function via props to save a file into the parent state

import React, { useState } from 'react'
import { withStyles } from "@material-ui/core"
import AttachFile from "@material-ui/icons/AttachFile"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"

const AddAttachmentsButtonWithDropdown = ({ handleBrowseFile, classes }) => {

    const [isAttachmentChoosed, setIsAttachmentChoosed] = useState(false);

    const handleFileChoosen = (e) => {
        setIsAttachmentChoosed(true)
        handleBrowseFile(e.target.files[0])
    }


    return (
        <div className={classes.wrapper}>
            <button
                type='button'
                className={classes.attachmentButton}
            >
                <label className={classes.chooseFile}>
                    {isAttachmentChoosed ?
                        <AttachFile style={{ fontSize: '17px', transform: 'rotate(45deg)', marginRight: '2px' }} /> :
                        <KeyboardArrowDown style={{ width: '20px' }} />
                    }
                    <span>
                        ADD
                    </span>
                    <input type='file' onChange={handleFileChoosen} />
                </label>
            </button>
        </div >
    )
}

const styles = {
    wrapper: {
        position: 'relative',
        display: 'inline-block',
        margin: '0 2px',

    },
    'attachmentButton': {
        backgroundColor: '#155794',
        display: 'flex!important',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        fontSize: '10px',
        borderRadius: '5px',
        fontWeight: '900',
        flex: '1',
        width: '70px',
        height: '38px',
        boxSizing: 'border-box',
        color: '#ffffff',
        border: 0,
        cursor: 'pointer',
        position: 'relative',
        padding: 0,
        '& :hover': {
            backgroundColor: '#216fb7'
        },
        '& input': {
            opacity: 0,
            width: 0,
            height: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer',
        },
        '& > span': {
            width: '70px',
            height: '38px',
            backgroundColor: 'transparet',
            borderRadius: '5px',
            boxSizing: 'border-box',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '& > span > span': {
            paddingRight: '10px'
        },
        '& svg': {
            cursor: 'pointer',
        }
    },
    chooseFile: {
        backgroundColor: '#155794',
        display: 'flex!important',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        fontSize: '10px',
        borderRadius: '5px',
        fontWeight: '900',
        flex: '1',
        width: '100%',
        height: '38px',
        boxsizing: 'border-box',
        border: 0,
        cursor: 'pointer',
        position: 'relative',
        boxSizing: 'border-box',
        color: 'white!important',
        '& :hover': {
            backgroundColor: '#1f6eb7',
            borderRadius: '5px'
        },
        '& input': {
            opacity: 0,
            width: 0,
            height: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer',
        },
        '& > span': {
            width: '45%',
            height: '100%',
            zIndex: 100,
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '& svg': {
            cursor: 'pointer',
        }
    },
}

export default withStyles(styles)(AddAttachmentsButtonWithDropdown)