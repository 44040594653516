import { userConstants } from "../constants";

export function users(state = {}, action) {
  switch (action.type) {
    //GET ALL INVITATIONS
    // case userConstants.GET_ALL_INVITATIONS_SUCCESS:
    //   return { ...state, invitations: action.payload };
    // case userConstants.GET_ALL_INVITATIONS_FAILURE:
    //   return { ...state, msg: action.payload };

    //ADD INVITATIONS
    // case userConstants.ADD_INVITATIONS_SUCCESS:
    //   return { ...state, last_invited: action.payload };
    // case userConstants.ADD_INVITATIONS_FAILURE:
    //   return { ...state, msg: action.payload };

    //GET ALL USERS
    case userConstants.GET_ALL_USERS_SUCCESS:
      return { ...state, users: action.payload };
    case userConstants.GET_ALL_USERS_FAILURE:
      return state;

    //SET PROJECT ROLE
    case userConstants.SET_PROJECT_ROLE_SUCCESS:
      let newlist = [];
      if (state.assignedUsers) {
        newlist = state.assignedUsers.concat(action.payload);
      } else {
        newlist[0] = action.payload;
      }
      return { ...state, assignedUsers: newlist };
    case userConstants.SET_PROJECT_ROLE_FAILURE:
      return state;

    //CLEAR_ASSIGNED_USERS_LOCAL
    case userConstants.CLEAR_ASSIGNED_USERS_LOCAL:
      return { ...state, assignedUsers: [] };

    //GET MY ROLE
    case userConstants.GET_MY_ROLE_REQUEST:
      return { ...state, permit: false, userProject: undefined };
    case userConstants.GET_MY_ROLE_SUCCESS:
      return { ...state, permit: true, userProject: action.payload }; //TODO: add Role
    case userConstants.GET_MY_ROLE_FAILURE:
      return state;

    //GET MY PROJECTS
    case userConstants.GET_MY_PROJECTS_SUCCESS:
      return { ...state, projects: action.payload };
    case userConstants.GET_MY_PROJECTS_FAILURE:
      return state;

    //GET USER
    case userConstants.GET_USER_SUCCESS:
      return { ...state, user: action.payload };
    case userConstants.GET_USER_FAILURE:
      return state;

    //GET USER PROJECTS
    case userConstants.GET_USER_PROJECTS_SUCCESS:
      return { ...state, userProjects: action.payload };
    case userConstants.GET_USER_PROJECTS_FAILURE:
      return state;

    //Add USER
    case userConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        usersRoles: state.usersRoles
          ? [...state.usersRoles, action.payload]
          : [action.payload]
      };
    case userConstants.ADD_USER_FAILURE:
      return state;

    //UPDATE PROJECT ROLE
    case userConstants.UPDATE_PROJECT_ROLE_FAILURE:
      return state;

    //GET ALL USERS ROLES
    case userConstants.GET_ALL_USERS_ROLES_SUCCESS:
      return { ...state, usersRoles: action.payload };
    case userConstants.GET_ALL_USERS_ROLES_FAILURE:
      return state;

    //DELETE PROJECT ROLE
    case userConstants.DELETE_PROJECT_ROLE_FAILURE:
      return state;

    //DELETE USER
    case userConstants.DELETE_USER_SUCCESS: {
      const index = findIndex(state.usersRoles, "_id", action.payload);
      return {
        ...state,
        usersRoles: [
          ...state.usersRoles.slice(0, index),
          ...state.usersRoles.slice(index + 1)
        ]
      };
    }
    case userConstants.DELETE_USER_FAILURE:
      return state;

    //EDIT USER
    case userConstants.EDIT_USER_SUCCESS: {
      return {
        ...state,
        usersRoles: state.usersRoles
          ? state.usersRoles.map(user =>
              user._id !== action.payload.id
                ? user
                : { ...user, ...action.payload }
            )
          : [],
        users: state.users
          ? state.users.map(user =>
              user._id !== action.payload.id
                ? user
                : { ...user, ...action.payload }
            )
          : []
      };
    }

    case userConstants.EDIT_USER_FAILURE:
      return state;

    case userConstants.GET_COMPANY_USERS_SUCCESS:
      return { ...state, companyUsers: action.payload };

    case userConstants.ADD_COMPANY_USER_SUCCESS:
      return {
        ...state,
        companyUsers: [...state.companyUsers, action.payload]
      };

    case userConstants.EDIT_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        companyUsers: state.companyUsers.map(company =>
          company._id === action.payload._id
            ? { ...action.payload, projectList: [] }
            : company
        )
      };

    case userConstants.DELETE_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        companyUsers: state.companyUsers.filter(
          company => company._id !== action.payload.id
        )
      };

    // case userConstants.

    case userConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload
      };

    case userConstants.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.payload }
      };

    default:
      return state;
  }
}

// #############################################################################
// find the index of searched item in the array
// #############################################################################

function findIndex(array, key, searchKey) {
  return array.findIndex(item => item[key] === searchKey);
}
