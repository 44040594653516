import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { history } from "../../helpers/history";

const styles = {
  backButton: {
    margin: 3,
    padding: 4,
    height: "fit-content",
  },
};
export const BackButton = withStyles(styles)(({ classes }) => (
  <IconButton className={classes.backButton} onClick={() => history.goBack()}>
    <ArrowBack/>
  </IconButton>
));
