
// #############################################################################
// this function will recover the expand flag of each task
// #############################################################################
export function recoverExpandVariables(prevTasks = {}, nextTasks = {}){

  const prevKeys = Object.keys(prevTasks);
  const nextKeys = Object.keys(nextTasks);
  
  if (prevKeys.length > 0 && prevKeys.length >= nextKeys.length){
    prevKeys.forEach(key => {
      if(nextTasks[key])
        nextTasks[key] = { ...nextTasks[key], expand: prevTasks[key].expand}
    })
  }
  else if(prevKeys.length < nextKeys.length){
    nextKeys.forEach(key => {
      if(prevTasks[key])
        nextTasks[key] = { ...nextTasks[key], expand: prevTasks[key].expand}
    })
  }
  return nextTasks;
}