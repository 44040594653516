export const projectConstants = {
  GET_ALL_PROJECTS_SUCCESS: "GET_ALL_PROJECTS_SUCCESS",
  GET_ALL_PROJECTS_FAILURE: "GET_ALL_PROJECTS_FAILURE",

  ADD_PROJECTS_SUCCESS: "ADD_PROJECTS_SUCCESS",
  ADD_PROJECTS_FAILURE: "ADD_PROJECTS_FAILURE",

  GET_PROJECT_REQUEST: "GET_PROJECT_REQUEST",
  GET_PROJECT_SUCCESS: "GET_PROJECT_SUCCESS",
  GET_PROJECT_FAILURE: "GET_PROJECT_FAILURE",

  GET_PROJECT_USERS_REQUEST: "GET_PROJECT_USERS_REQUEST",
  GET_PROJECT_USERS_SUCCESS: "GET_PROJECT_USERS_SUCCESS",
  GET_PROJECT_USERS_FAILURE: "GET_PROJECT_USERS_FAILURE",

  GET_ROOT_PROJECTS_SUCCESS: "GET_ROOT_PROJECTS_SUCCESS",
  GET_ROOT_PROJECTS_FAILURE: "GET_ROOT_PROJECTS_FAILURE",

  GET_SUB_PROJECTS_REQUEST: "GET_SUB_PROJECTS_REQUEST",
  GET_SUB_PROJECTS_SUCCESS: "GET_SUB_PROJECTS_SUCCESS",
  GET_SUB_PROJECTS_FAILURE: "GET_SUB_PROJECTS_FAILURE",

  UPDATE_PARENT_LOCALLY: "UPDATE_PARENT_LOCALLY",

  DELETE_PROJECTS_SUCCESS: "DELETE_PROJECTS_SUCCESS",
  DELETE_PROJECTS_FAILURE: "DELETE_PROJECTS_FAILURE",

  GET_ALL_PROJECT_ARCHITECT_SUCCESS: "GET_ALL_PROJECT_ARCHITECT_SUCCESS",
  GET_ALL_PROJECT_ARCHITECT_FAILURE: "GET_ALL_PROJECT_ARCHITECT_FAILURE",

  GET_ATTACHMENTS_SUCCESS: "GET_ATTACHMENTS_SUCCESS",
  GET_ATTACHMENTS_FAILURE: "GET_ATTACHMENTS_FAILURE",

  ADD_ATTACHMENT_SUCCESS: "ADD_ATTACHMENT_SUCCESS",
  ADD_ATTACHMENT_FAILURE: "ADD_ATTACHMENT_FAILURE",

  EDIT_ATTACHMENT_SUCCESS: "EDIT_ATTACHMENT_SUCCESS",
  EDIT_ATTACHMENT_FAILURE: "EDIT_ATTACHMENT_FAILURE",

  DELETE_ATTACHMENT_SUCCESS: "DELETE_ATTACHMENT_SUCCESS",
  DELETE_ATTACHMENT_FAILURE: "DELETE_ATTACHMENT_FAILURE",

  EDIT_PROJECTS_SUCCESS: "EDIT_PROJECTS_SUCCESS",
  EDIT_PROJECTS_FAILURE: "EDIT_PROJECTS_FAILURE",

  COPY_PROJECT_SUCCESS: "COPY_PROJECT_SUCCESS",
  COPY_PROJECT_FAILURE: "COPY_PROJECT_FAILURE",

  EDIT_AND_ORDER_SUCCESS: "EDIT_AND_ORDER_SUCCESS",
  EDIT_AND_ORDER_FAILURE: "EDIT_AND_ORDER_FAILURE",

  CHANGE_PROJECT_ORDER_SUCCESS: "CHANGE_PROJECT_ORDER_SUCCESS",
  CHANGE_PROJECT_ORDER_FAILURE: "CHANGE_PROJECT_ORDER_FAILURE",

  GET_ACTIVITIES_SUCCESS: "GET_ACTIVITIES_SUCCESS",
  GET_ACTIVITIES_FAILURE: "GET_ACTIVITIES_FAILURE",

  ADD_USER_AND_ASSIGN_SUCCESS: "ADD_USER_AND_ASSIGN_SUCCESS",
  ADD_USER_AND_ASSIGN_FAILURE: "ADD_USER_AND_ASSIGN_FAILURE",

  CLEARING_PROJECTS_DATA: "CLEARING_PROJECTS_DATA",

  ADD_ADDRESS_TO_PROJECT_SUCCESS: 'ADD_ADDRESS_TO_PROJECT_SUCCESS',
  ADD_ADDRESS_TO_PROJECT_FAILURE: 'ADD_ADDRESS_TO_PROJECT_FAILURE',
};
