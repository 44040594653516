import {
  attachemntConstants,
  costConstants,
  noteConstants
} from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "../actions";

export const attachmentUserActions = {
  getAttachments,
  addAttachment,
  addFolder,
  editAttachment,
  deleteAttachment,
  changeAttachmentOrder
};

//################################################################################
// GET Attachments
//################################################################################

function getAttachments(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getAttachments", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.attachments));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(attachments) {
    return {
      type: attachemntConstants.GET_ATTACHMENTS_SUCCESS,
      payload: attachments
    };
  }
  // function failure(error) {
  //   return {
  //     type: attachemntConstants.GET_ATTACHMENTS_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Add Attachments
//################################################################################

function addAttachment(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("addAttachment", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(snackBarActions.snackBarSuccess("Successful"));
          if (data.get("costId") !== null)
            dispatch(updateCostList(data.get("costId")));
          else if (data.get("noteId"))
            dispatch(
              addToNoteSuccess(response.attachments, data.get("noteId"))
            );
          else dispatch(success(response.attachments));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(file) {
    return { type: attachemntConstants.ADD_ATTACHMENT_SUCCESS, payload: file };
  }
  function updateCostList(costId) {
    return {
      type: costConstants.ADD_ATTACHMENT_TO_COST_SUCCESS,
      payload: costId
    };
  }
  function addToNoteSuccess(data, noteId) {
    return {
      type: noteConstants.ADD_ATTACHMENT_TO_NOTE_SUCCESS,
      payload: data,
      noteId
    };
  }
  // function failure(error) {
  //   return { type: attachemntConstants.ADD_ATTACHMENT_FAILURE, payload: error };
  // }
}

//################################################################################
// Upload Folder
//################################################################################

function addFolder(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("addFolder", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(snackBarActions.snackBarSuccess("Successful"));
          dispatch(success(response.attachment));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(attachment) {
    return {
      type: attachemntConstants.ADD_FOLDER_SUCCESS,
      payload: attachment
    };
  }
  // function failure(error) {
  //   return { type: attachemntConstants.ADD_FOLDER_FAILURE, payload: error };
  // }
}

//################################################################################
// edit Attachments
//################################################################################

function editAttachment(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editAttachment", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(
            snackBarActions.snackBarSuccess("Attachment edited successfully")
          );
          dispatch(success({ ...data, ...response.attachment }));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(file) {
    return { type: attachemntConstants.EDIT_ATTACHMENT_SUCCESS, payload: file };
  }
  // function updateCostList(costId) {
  //   return { type: costConstants.EDIT_ATTACHMENT_TO_COST_SUCCESS, payload: costId }
  // }
  // function failure(error) {
  //   return {
  //     type: attachemntConstants.EDIT_ATTACHMENT_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Delete Attachment
//################################################################################

function deleteAttachment(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteAttachment", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(
            snackBarActions.snackBarSuccess("The file Successfully removed!")
          );
          if (response.noteId)
            dispatch(
              addToNoteSuccess({
                attachmentId: data.attachmentId,
                noteId: response.noteId
              })
            );
          dispatch(success(data));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(attachment) {
    return {
      type: attachemntConstants.DELETE_ATTACHMENT_SUCCESS,
      payload: attachment
    };
  }
  function addToNoteSuccess(data) {
    return {
      type: noteConstants.DELETE_ATTACHMENT_TO_NOTE_SUCCESS,
      payload: data
    };
  }
  // function failure(error) {
  //   return {
  //     type: attachemntConstants.DELETE_ATTACHMENT_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Change Attachment Order
//################################################################################

function changeAttachmentOrder(data, allNewDocuments) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("changeAttachmentOrder", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(
            snackBarActions.snackBarSuccess("Attachment edited successfully!")
          );
          dispatch(success(data));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(attachment) {
    return {
      type: attachemntConstants.CHANGE_ATTACHMENT_ORDER_SUCCESS,
      payload: allNewDocuments
    };
  }
  // function failure(error) {
  //   return {
  //     type: attachemntConstants.CHANGE_ATTACHMENT_ORDER_FAILURE,
  //     payload: error
  //   };
  // }
}
