import React from "react";
import { withStyles } from "@material-ui/core";
import Header from "./Header";

// #############################################################################
// Component
// #############################################################################
const styles = theme => ({
  tableCaption: {
    backgroundColor: "#256598",
    color: "white",
    width: "calc(100% - 4px)",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    left: "2px",
    position: "relative"
  },
  tableCaptionContainer: {
    backgroundColor: "transparent",
    color: "white",
    width: "calc(100% + 8px)",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    left: "-4px",
    position: "relative"
  },
  tableSubCaption: {
    backgroundColor: "#A5B3BF",
    color: "black",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    marginTop: "5px",
    border: "1px solid #eff0f5",
    height: "45px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontWeight: "bold"
  },
  tableBody: {
    width: "100%"
  },
  headStyle: {
    height: "35px",
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "#A8B2BE"
  },
  dataStyle: {
    height: "35px",
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "white",
    color: "#505152"
  },
  subCaptionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dataStyleParent: {
    height: "35px",
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "#A8B2BE",
    color: "black",
    width: "20px"
  }
});

// #############################################################################
// component
// ##########################################################
class IssueAndCostReportTab extends React.PureComponent {
  formatDate = strData => {
    // to format given data
    if (strData === null || strData === undefined || strData === "") {
      return "";
    } else {
      let date = new Date(strData);
      return `${date.getMonth() + 1}/${date.getDay()}/${date.getFullYear()}`;
    }
  };

  render() {
    const { classes, report, project } = this.props;
    return (
      <div>
        <Header />
        {/* Table for Team members */}
        <table className={classes.tableBody}>
          <caption className={classes.tableCaption}>{"TEAM MEMBERS"}</caption>
          <thead>
            <tr>
              <td className={classes.headStyle}>{"Real Estate Manager"}</td>
              <td className={classes.headStyle}>{"Construction Manager"}</td>
              <td className={classes.headStyle}>{"Architect PM"}</td>
              <td className={classes.headStyle}>{"Developer"}</td>
              <td className={classes.headStyle}>{"General Contractor"}</td>
            </tr>
          </thead>

          {project && (
            <tbody>
              <tr>
                <td className={classes.dataStyle}>
                  {project.realEstateManager !== null &&
                    project.realEstateManager.firstName +
                      " " +
                      project.realEstateManager.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.constructionManager !== null &&
                    project.constructionManager.firstName +
                      " " +
                      project.constructionManager.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.architectPM !== null &&
                    project.architectPM.firstName +
                      " " +
                      project.architectPM.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.developer !== null &&
                    project.developer.firstName +
                      " " +
                      project.developer.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.generalContractor !== null &&
                    project.generalContractor.firstName +
                      " " +
                      project.generalContractor.lastName}
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <br />
        <br />

        {/* Table for Team ISSUES-TASKS */}
        <table className={classes.tableBody}>
          <caption className={classes.tableCaptionContainer}>
            <div className={classes.tableCaption}>{"ISSUES-TASKS"}</div>
          </caption>
          <thead>
            <tr>
              <td className={classes.headStyle}>{"Description"}</td>
              <td className={classes.headStyle}>{"Due Date"}</td>
              <td className={classes.headStyle}>{"Status"}</td>
              <td className={classes.headStyle}>{"Assigned To"}</td>
              <td className={classes.headStyle}>{"Email"}</td>
            </tr>
          </thead>
          <tbody>
            {report &&
              report.issueTasks &&
              report.issueTasks.map(report => {
                return (
                  <tr key={report._id}>
                    <td className={classes.dataStyle}>{report.description}</td>
                    <td className={classes.dataStyle}>
                      {this.formatDate(report.dueDate)}
                    </td>
                    <td className={classes.dataStyle}>{report.status}</td>
                    <td className={classes.dataStyle}>
                      {report.assignedTo.firstName + report.assignedTo.lastName}
                    </td>
                    <td className={classes.dataStyle}>
                      {report.assignedTo.email}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

const IssueAndCostReportTabWithStyles = withStyles(styles)(
  IssueAndCostReportTab
);

export { IssueAndCostReportTabWithStyles as IssueAndCostReportTab };
