import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";

// #############################################################################
// component
// #############################################################################

class TaskTotal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // ###########################################################################
  // this function will calculates the total stats and finish and the day of
  // project
  // ###########################################################################

  calculateTotal = (tasks = []) => {
    let start = Number.MAX_VALUE;
    let finish = 0;
    let estimated = 0;

    tasks.forEach(task => {
      const startDate = new Date(task.actualStartDate).getTime();
      const finishDate = new Date(task.actualFinishDate).getTime();

      start = Math.min(start, startDate);
      finish = Math.max(finish, finishDate);
    });

    const [weekends ,workDays] =  this.removeWeekends(start, finish);
    estimated= `${weekends + workDays} Days - ${workDays} BD - ${weekends} WE`;

    start = this.convertToDateString(start);
    finish = this.convertToDateString(finish);

    if (tasks.length > 0) return { start, finish, estimated };
    else return { start: "", finish: "", estimated: "" };
  };

  // ###########################################################################
  // this function will converts the date to some sort of date string
  // ###########################################################################

  convertToDateString = time => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec"
    ];
    const date = new Date(time);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    return `${day} ${month} ${year}`;
  };


  // #############################################################################
  // this function will count the weekends and work days and also return the time line
  // #############################################################################

  removeWeekends = (startDate = new Date(),  finishDate = new Date()) => {

    const start = new Date(startDate);
    const finish = new Date(finishDate);

    let weekends = 0;  // weekend counter
    let workDays = 0;  // work days counter
    let timeline = []; // time line for days between the two dates

    // this loop runs till date is less than finish date
    for(let date = new Date(start); this.dateInRow(date) <= this.dateInRow(finish); date = new Date (date.setDate(date.getDate() + 1))){

      // adds the date to time line
      timeline.push(new Date(date));
      // this condition will add to weekend counter and workdays
      if(date.getDay() === 0 || date.getDay() === 6)
        ++weekends;
      else
        ++workDays;
    }
    // return the result in array
    return [weekends ,workDays, timeline];
  }

  // ###########################################################################
  // this function will return the date in number form
  // ###########################################################################

  dateInRow = (date) => {
    const time = new Date(date)
    return time.getFullYear() * 10000 + time.getMonth() * 100 + time.getDate()
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const { classes, tasks = [] } = this.props;
    const { start = "", finish = "", estimated = "" } = this.calculateTotal(
      tasks
    );
    return (
      <div className={classes.root}>
        <div style={{ marginRight: 5 }} className={classes.box}>
          Start Date:
          <span className={classes.value}> {start}</span>
        </div>

        <div style={{ margin: "0px 5px" }} className={classes.box}>
          End Date:
          <span className={classes.value}> {finish}</span>
        </div>
        <div style={{ marginLeft: 5 }} className={classes.box}>
          Estimated:
          <span className={classes.value}> {estimated}</span>
        </div>
      </div>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root: {
    display: "flex",
    marginBottom: 10
  },
  box: {
    minHeight: 30,
    background: "white",
    padding: " 6px 10px",
    flex: 1,
    boxSizing: "border-box",
    borderRadius: "4px",
    border: "1px solid #1557947a",
    fontSize: 13,
    color: "#00000087"
  },
  value: {
    color: "#00206d",
    fontSize: 13,
    fontWeight: 500
  }
});

// #############################################################################
// connect material styles to component
// #############################################################################
const TaskTotalWithStyles = withStyles(styles)(TaskTotal);

// #############################################################################
// export
// #############################################################################

export { TaskTotalWithStyles as TaskTotal };
