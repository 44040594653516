import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
//############################################################################
// light theme
//############################################################################

export const light = createMuiTheme({
  colorTransition: "color 300ms, background-color 300ms",
  typography: { useNextVariants: true },
  backgrounds: ["#ffffff", "#fdfdfd", "#f9f9f9", "#f0f0f0", "#ececec", "#e6e6e6"],

});
//############################################################################
// dark theme
//############################################################################

export const dark = createMuiTheme({
  colorTransition: "color 300ms, background-color 300ms",
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    h1: {
      color: "rgba(255, 255, 255, 1)",
      fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
      fontSize: "6rem",
      fontWeight: "300",
      letterSpacing: "-0.01562em",
      lineHeight: 1
    },
    h2: {
      color: "rgba(255, 255, 255, 1)",
      fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
      fontSize: "3.75rem",
      fontWeight: "300",
      letterSpacing: "-0.00833em",
      lineHeight: 1
    },
    h3: {
      color: "rgba(255, 255, 255, 1)",
      fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
      fontSize: "3rem",
      fontWeight: "400",
      letterSpacing: "0",
      lineHeight: 1
    },
    h4: {
      color: "rgba(255, 255, 255, 1)",
      fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
      fontSize: "2.125rem",
      fontWeight: "300",
      letterSpacing: "-0.01562em",
      lineHeight: 1.17
    },
    h5: {
      color: "rgba(255, 255, 255, 1)",
      fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
      fontSize: "1.5rem",
      fontWeight: "400",
      letterSpacing: "-0.01562em",
      lineHeight: 1.33
    },
    h6: {
      color: "rgba(255, 255, 255, 1)",
      fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
      fontSize: "1.25rem",
      fontWeight: "500",
      letterSpacing: "-0.01562em",
      lineHeight: 1.6
    },
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
  },
  palette: {
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(0, 0, 0, 0.14)"
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161"
    },
    backgrounds: ["#1f4048", "#304f59", "#32545f", "#345863", "#355a65", "#3c6976"],
    divider: "rgba(0, 0, 0, 0.12)",
    error: {
      light: "#fff",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    primary: {
      light: "#17adc8",
      main: "#009fbc",
      dark: "#01839b",
      contrastText: "#fff"
    },
    secondary: {
      light: "rgba(243, 106, 52, 0.85)",
      main: "rgba(214, 77, 22, 0.85)",
      dark: "rgba(188, 56, 4, 0.85)",
      contrastText: "#fff"
    },
    text: {
      primary: "rgba(255, 255, 255, 1)",
      secondary: "rgba(255, 255, 255, 0.9)",
      disabled: "rgba(255, 255, 255, 0.75)",
      hint: "rgba(255, 255, 255, 0.75)"
    }
  },
  gradients: [
    "linear-gradient(20deg, #009688 0%, #00BCD4 100%)",
    "linear-gradient(to right, #165a95 0%, #00ffad 100%)"
  ],
  graphPattern: {
    backgroundColor: "rgba(235, 234, 236, 0.24)",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' width='80' height='80'%3E%3Cpath fill='%23cdd9be' fill-opacity='0.2' d='M14 16H9v-2h5V9.87a4 4 0 1 1 2 0V14h5v2h-5v15.95A10 10 0 0 0 23.66 27l-3.46-2 8.2-2.2-2.9 5a12 12 0 0 1-21 0l-2.89-5 8.2 2.2-3.47 2A10 10 0 0 0 14 31.95V16zm40 40h-5v-2h5v-4.13a4 4 0 1 1 2 0V54h5v2h-5v15.95A10 10 0 0 0 63.66 67l-3.47-2 8.2-2.2-2.88 5a12 12 0 0 1-21.02 0l-2.88-5 8.2 2.2-3.47 2A10 10 0 0 0 54 71.95V56zm-39 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm40-40a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM15 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm40 40a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'%3E%3C/path%3E%3C/svg%3E") !important`
  },
  wavePattern: {
    backgroundColor: "#ffaa00",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23ffb100' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23ffb800' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23ffbf00' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23ffc500' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23ffcc00' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23ffd624' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23ffe038' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23ffeb49' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23fff558' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23ffff66' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E")`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
  },
  envelopStrips: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23cc5656;%7D.b%7Bfill:%2318a4c9;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Epattern%3C/title%3E%3Cpolygon class='a' points='128 96 128 128 0 0 32 0 128 96'/%3E%3Cpolygon class='b' points='128 32 128 64 64 0 96 0 128 32'/%3E%3Cpolygon class='a' points='32 128 0 128 0 96 32 128'/%3E%3Cpolygon class='b' points='96 128 64 128 0 64 0 32 96 128'/%3E%3C/svg%3E")`,
    backgroundSize: "128px"
  },
  backgroundImageGreen: "linear-gradient(to right, #67ab93 0%, #76be8e 100%)",
  backgroundImageRed: "linear-gradient(to right, #ffb94f 0%, #ff8406 100%)",
  shadow: ["0px 4px 4px -3px rgba(0, 0, 0, 0.43), 0px 14px 8px -8px #0000001f, 0px 0px 12px -5px #0000007a"],
  shape: {
    borderRadius: ["0px", "5px", "10px", "15px", "20px", "25px"],
    padding: ["0rem", "0.25rem", "0.5rem", "1rem", "1.5rem", "2rem", "3rem", "4rem"],
    margin: ["0rem", "0.25rem", "0.5rem", "1rem", "1.5rem", "2rem", "3rem", "4rem"],
  },
  direction: {
    LTR: "ltr",
    RTL: "rtl"
  }
});
