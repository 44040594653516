import React from "react";
import Select from "react-select";
import { ReactInput } from "../reactInput/reactInput";
import ThemeContext from "../ContextApi/Context";

// #####[ material ui ]#########################################################
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AttachFileIcon from "@material-ui/icons/AttachFile";

////////////////////modal and its content ////////////////////
import HOCModal from "../Modal/Modal";
import AttachmentModalContent from "./AttachmentModalContent";

// #####[ redux ]###############################################################
import { connect } from "react-redux";
import { attachmentActions, filterActions } from "../../actions";
import { attachmentUserActions } from "../../userActions/";

// #####[ colors ]##############################################################
import { Colors } from "../../styles";

// #############################################################################
// handle select file
// #############################################################################

class AttachmentUploadFile extends React.PureComponent {
  static contextType = ThemeContext;

  state = {
    uploadPercentage: 0,
    file: "",
    defaultTags: null,
    tags: [],
    attachmentModalIsShowed: false,
    name: "",
    type: ""
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.type === "checkbox" ? target.checked : target.value
    });
  };

  handlerAttachmentModal = bool => {
    this.setState({
      attachmentModalIsShowed: !!bool
    });
  };

  // ###########################################################################
  // handle select file
  // ###########################################################################

  handleFileChoosen = ({ target }) => {
    target.files[0]
      ? this.setState({
        file: target.files[0],
        name: this.state.name
          ? this.state.name
          : target.files[0].name.replace(
            "." + target.files[0].name.split(".").pop(),
            ""
          ),
        type: target.files[0].name.split(".").pop()
      })
      : this.setState({
        file: "",
        name: "",
        type: "",
        tags: []
      });
  };

  // ###########################################################################
  // handle the percentage of the upload
  // ###########################################################################

  handleUploadPercentage = uploadedSize => {
    const uploadPercentage = (uploadedSize / this.state.file.size) * 100;
    this.setState({ uploadPercentage });

    if (uploadPercentage >= 100)
      this.setState({
        file: "",
        name: "",
        type: "",
        tags: [],
        uploadPercentage: 0
      });
  };

  // ###########################################################################
  // handle submit
  // ###########################################################################

  handlefileSubmit = e => {
    const projectId = this.context;
    e.preventDefault();
    this.dispatch(filterActions.pageFilterEdit(''))
    let document = new FormData();
    const tags = JSON.stringify(this.state.tags.map(item => item.value));

    document.append("projectId", projectId);
    document.append("tags", tags);
    document.append(
      "document",
      this.state.file,
      this.state.name + "." + this.state.type
    );

    if (this.props.role === "adminFlag")
      this.props.dispatch(
        attachmentActions.addAttachment(document, this.handleUploadPercentage)
      );
    else if (this.props.role !== "Project-Client")
      this.props.dispatch(
        attachmentUserActions.addAttachment(
          document,
          this.handleUploadPercentage
        )
      );

    this.setState({
      file: '',
      name: '',
      type: '',
      tags: [],
    })
  };

  // ###########################################################################
  // select options
  // ###########################################################################

  selectOptions = () => [
    { label: "Invoice", value: "Invoice" },
    { label: "Contract", value: "Contract" },
    { label: "Change Order", value: "Change Order" },
    { label: "Misc Document", value: "Misc Document" }
  ];

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const {
      uploadPercentage,
      file,
      tags,
      attachmentModalIsShowed,
      name
    } = this.state;
    const { role, classes } = this.props;
    const isClient = role === "Project-Client" ? true : false;
    return (
      <Grid
        container
        className={classes.assignUserBox}
        justify={"space-evenly"}
      >
        <Grid container>
          <form
            onSubmit={this.handlefileSubmit}
            className={classes.AttachmentUploadFile}
          >
            <div
              className={classes.progressBar}
              style={{ width: `${uploadPercentage}%` }}
            ></div>
            <Grid container>
              <div className={classes.selectAddGrid}>
                <ReactInput
                  className={isClient ? classes.disabledInput : classes.input}
                  placeholder="Attachment Name"
                  onChange={this.handleChange}
                  value={name}
                  name="name"
                  required
                  disabled={role === "Project-Client"}
                  style={
                    role === "Project-Client"
                      ? { backgroundColor: "#F2F2F2" }
                      : {}
                  }
                />

                <Select
                  options={this.selectOptions()}
                  value={tags}
                  isMulti
                  isClearable={false}
                  styles={classes.selectStyle}
                  onChange={selectedValues =>
                    this.setState({ tags: selectedValues })
                  }
                  className={classes.select}
                  placeholder={"Attachment Category"}
                  isDisabled={role === "Project-Client"}
                />
              </div>

              <div className={classes.uploadButtonContainer}>
                <label
                  className={`${classes.chooseFile} 
                    ${isClient ? classes.disabledButton : classes.button}`}
                >
                  {file && (
                    <AttachFileIcon className={classes.attachmentIcon} />
                  )}
                  <span>ADD ATTACHMENT</span>
                  <input
                    type="file"
                    onChange={this.handleFileChoosen}
                    disabled={isClient}
                  />
                </label>
                <button
                  type="submit"
                  className={`${classes.uploadButton}
                              ${
                    file ? classes.button : classes.disabledButton
                    }`}
                  value="Upload"
                  disabled={!file}
                >
                  UPLOAD
                </button>
              </div>
            </Grid>
          </form>
          <div className={classes.projectAttachmentWrapper}>
            <input
              type="button"
              className={`${classes.button} ${classes.projectAttachmentButton}`}
              value="PROJECT ATTACHMENTS"
              onClick={this.handlerAttachmentModal}
            />
          </div>
        </Grid>
        <HOCModal
          isShowed={attachmentModalIsShowed}
          handlerInfoModal={this.handlerAttachmentModal}
        >
          <AttachmentModalContent
            handlerAttachmentModal={this.handlerAttachmentModal}
            clickedRowDataId={this.state.clickedRowDataId}
          />
        </HOCModal>
      </Grid>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = {
  // ###########################################################################
  assignUserBox: {
    position: "relative",
    backgroundColor: "#eff0f5",
    borderRadius: "5px",
    width: "100%",
    display: "flex",
    direction: "row",
    justifyContent: "center",
    marginBottom: "10px 0"
  },
  AttachmentUploadFile: {
    border: "1px solid #bdbdbd",
    padding: "5px",
    flex: 10,
    position: "relative",
    borderRadius: "5px"
  },
  progressBar: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "3px",
    boxSizing: "border-box",
    backgroundColor: "#068aea",
    zIndex: 0,
    transition: "width 100ms linear"
  },
  selectAddGrid: {
    flexGrow: 100,
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px",
    "& >div": {
      zIndex: 10
    }
  },
  "& input": {
    opacity: 0,
    position: "absolute",
    margin: "0",
    padding: "0",
    cursor: "pointer",
    width: "90px",
    height: "42px"
  },
  uploadButtonContainer: {
    // width: "250px",
    marginLeft: "5px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    height: "100%",
    fontSize: "14px",
    fontWeight: "500",
    color: "white",
    borderRadius: "5px",
    padding: "5px",
    margin: "0 4px",
    cursor: "pointer!important",
    transition: "300ms",
    border: "none"
  },
  disabledButton: {
    backgroundColor: `${Colors.darkGrey}!important`,
    width: "100px",
    height: "100%",
    fontSize: "14px",
    fontWeight: "500",
    color: "white",
    borderRadius: "5px",
    padding: "5px",
    margin: "0 4px",
    transition: "300ms",
    border: "none",
    cursor: "default!important"
  },
  chooseFile: {
    width: "155px",
    backgroundColor: Colors.green,
    display: "flex!important",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: "5px",
    // fontWeight: '900',
    // flex: '1',
    // width: '100%',
    // height: '38px',
    boxSizing: "border-box",
    border: 0,
    position: "relative",
    color: "white!important",
    "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "13px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: Colors.darkGreen
    },
    "& input": {
      opacity: 0,
      width: 0,
      height: 0,
      position: "absolute",
      top: 0,
      left: 0
    },
    "& > span": {
      width: "80%",
      height: "100%",
      zIndex: 100,
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center"
    }
  },
  select: {
    flex: 4,
    margin: "0 5px"
  },
  input: {
    flex: 3,
    margin: "0 5px"
  },
  disabledInput: {
    backgroundColor: "#F2F2F2",
    border: "1px solid #E6E6E6",
    flex: 3,
    margin: "0 5px",
    "&:hover": {
      border: "1px solid #E6E6E6"
    }
  },
  uploadButton: {
    width: "100px",
    backgroundColor: Colors.darkBlue,
    "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "13px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: "#3F51B5"
    }
  },
  projectAttachmentWrapper: {
    width: "200px",
    padding: "5px"
  },
  projectAttachmentButton: {
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: Colors.green,
    transition: "300ms",
    "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "13px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: Colors.darkGreen
    }
  },
  attachmentIcon: {
    color: "white",
    fontSize: "22px",
    transform: "rotate(45deg)"
  }
};

// #############################################################################
// connect store and styles to component
// #############################################################################

const mapStateToProps = state => {
  return {
    role: state.projects.project ? state.projects.project.role : undefined
  };
};
const AttachmentUploadFileWithStyles = withStyles(styles)(
  connect(mapStateToProps)(AttachmentUploadFile)
);

// #############################################################################
// export
// #############################################################################

export { AttachmentUploadFileWithStyles as AttachmentUploadFile };
