import { activityConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "../actions";

export const activityUserActions = {
  getActivities
};

//################################################################################
// get Activities
//################################################################################
function getActivities(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getActivities", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.activities));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(activities) {
    return {
      type: activityConstants.GET_ACTIVITIES_SUCCESS,
      payload: activities
    };
  }
  // function failure(error) {
  //   return {
  //     type: activityConstants.GET_ACTIVITIES_FAILURE,
  //     payload: error
  //   };
  // }
}
