// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { noteActions, filterActions } from "../../actions";
import { noteUserActions } from "../../userActions";
// Material Ui //
import Grid from "@material-ui/core/Grid";

// Components //
import AddNoteInline from "./AddNoteInline";
import NoteList from "./NoteList";
import NoteFilter from "./NoteFilter";
import ThemeContext from "../ContextApi/Context";

// Reusable Functions //

// Styles //

class NoteWrapper extends React.PureComponent {
  static contextType = ThemeContext;
  state = {
    fromDate: null,
    toDate: null
  };

  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  componentDidMount() {
    const { dispatch, role } = this.props;
    const projectId = this.context;
    if (role === "admin") {
      dispatch(noteActions.getProjectNotes({ projectId }));
    } else {
      dispatch(noteUserActions.getProjectNotes({ projectId }));
    }
    dispatch(filterActions.pageFilterEdit(""));
  }

  handleFilterNotes = () => {
    const { fromDate, toDate } = this.state;
    const { notes, pageFilter } = this.props;
    return notes
      .filter(note => {
        const date = new Date(note.createDate);
        date.setHours(0, 0, 0, 0);
        if (!fromDate && !toDate) return true;
        else if (fromDate && !toDate) return date >= fromDate;
        else if (!fromDate && toDate) return date <= toDate;
        else return fromDate <= toDate && date >= fromDate && date <= toDate;
      })
      .filter(note => {
        const date = new Date(note.createDate);
        return !(
          !note.text.includes(pageFilter.toLowerCase()) &&
          !(
            date.getFullYear() +
            " " +
            this.monthNames[date.getMonth()]
          ).includes(pageFilter.toLowerCase()) &&
          !(
            note.owner.firstName.toLowerCase() +
            " " +
            note.owner.lastName.toLowerCase()
          ).includes(pageFilter.toLowerCase())
        );
      });
  };

  handleFilterChanges = ({ fromDate, toDate }) => {
    this.setState(prevState => ({
      fromDate: fromDate || fromDate === null ? fromDate : prevState.fromDate,
      toDate: toDate || toDate === null ? toDate : prevState.toDate
    }));
  };

  render() {
    const { role, notes } = this.props;
    return (
      <>
        <Grid container>
          {role && role !== "Project-Client" && (
            <Grid item xs={12}>
              <AddNoteInline />
            </Grid>
          )}
          {notes[0] && (
            <Grid item xs={12} style={{ height: 0, position: "relative" }}>
              <NoteFilter
                handleClear={this.handleClear}
                handleFilterChanges={this.handleFilterChanges}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <NoteList notes={this.handleFilterNotes()} />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => ({
  notes: state.notes ? state.notes : [],
  role: state.authentication.user.role,
  clientRole: state.projects.project ? state.projects.project.role : undefined,
  pageFilter: state.filters.pageFilter
});

export default connect(mapStateToProps)(NoteWrapper);
