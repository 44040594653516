import React from "react";
import { ReactInput } from "../";
import Select from "react-select";
import { CButton } from "../";
import { usaCities } from "../../constants";
import { projectStatus } from "../../constants";

// ######[ MATERIAL COMPOENNTS ]################################################
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import EjectIcon from "@material-ui/icons/Eject";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import Modal from "../Modal/Modal";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import AddClientInline from "../AdminDashboard/clients/AddClientInline";
import Close from "@material-ui/icons/Close";
// #############################################################################
// COMPONENT
// #############################################################################

class ProjectForm extends React.PureComponent {
  state = {
    openAddProject: false,
    name: "",
    parent: "",
    state: "",
    city: "",
    region: "",
    address: "",
    siteNumber: "",
    realEstateManager: null,
    constructionManager: null,
    architectPM: null,
    generalContractor: null,
    architecturalFirm: null,
    developer: null,
    description: "",
    startDate: "",
    estimatedEndDate: "",
    status: "",
    PMDGJobCode: "",
    type: { label: "", value: "" },
    allProjects: [],
    addNewClientModal: false,
    client: ""
  };

  componentDidMount() {
    const {
      companies = [],
      allProjects = [],
      parentProjectForAddingSubProject: fixme
    } = this.props;
    const parentProjectForAddingSubProject = fixme ? fixme : {};

    const parentCompany = companies.find(
      comapany => comapany._id === parentProjectForAddingSubProject.client
    );
    parentProjectForAddingSubProject &&
      this.setState({
        parent: {
          value: parentProjectForAddingSubProject._id,
          label: parentProjectForAddingSubProject.name
        },
        client: parentProjectForAddingSubProject.client
          ? {
            value: parentCompany._id,
            label: parentCompany.name
          }
          : {
            value: parentProjectForAddingSubProject._id,
            label: parentProjectForAddingSubProject.name
          }
      });
    this.setState({
      allProjects: [...companies, ...allProjects]
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.parentProjectForAddingSubProject !==
      this.props.parentProjectForAddingSubProject &&
      this.props.parentProjectForAddingSubProject
    ) {
      const { companies, parentProjectForAddingSubProject: fixme } = this.props;
      const parentProjectForAddingSubProject = fixme ? fixme : {};
      const parentCompany = companies.find(
        comapany => comapany._id === parentProjectForAddingSubProject.client
      );
      this.setState({
        parent: {
          value: parentProjectForAddingSubProject._id,
          label: parentProjectForAddingSubProject.name
        },
        client: parentProjectForAddingSubProject.client
          ? {
            value: parentCompany._id,
            label: parentCompany.name
          }
          : {
            value: parentProjectForAddingSubProject._id,
            label: parentProjectForAddingSubProject.name
          }
      });
    }

    if (prevProps.type !== this.props.type && this.props.type) {
      this.setState({
        type: {
          value: this.props.type,
          label: this.props.type
        }
      });
    }
    if (
      prevProps.allProjects !== this.props.allProjects ||
      prevProps.companies !== this.props.companies
    ) {
      this.setState({
        allProjects: [...this.props.companies, ...this.props.allProjects]
      });
    }
  }

  // #########################################################################
  //
  // #########################################################################

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // #########################################################################
  //
  // #########################################################################

  handleSelectChange = (option, event) => {
    this.setState({ [event.name]: option });
  };

  // #########################################################################
  // handle add project
  // #########################################################################

  handleAddProject = e => {
    e.preventDefault();
    let {
      name,
      parent,
      status,
      state,
      city,
      region,
      address,
      siteNumber,
      realEstateManager,
      constructionManager,
      architectPM,
      generalContractor,
      architecturalFirm,
      developer,
      description,
      PMDGJobCode,
      // client = {},
      type
    } = this.state;
    const { selectedProject } = this.props;
    const startDate = new Date(this.state.startDate).getTime();
    const estimatedEndDate = new Date(this.state.estimatedEndDate).getTime();
    parent = parent ? parent : { value: "null", label: "No Parent" };
    realEstateManager = realEstateManager ? realEstateManager : {};
    constructionManager = constructionManager ? constructionManager : {};
    architectPM = architectPM ? architectPM : {};
    generalContractor = generalContractor ? generalContractor : {};
    architecturalFirm = architecturalFirm ? architecturalFirm : {};
    developer = developer ? developer : {};
    // client = client ? client : {};
    this.props.handleAddProject({
      name,
      status: status.value,
      parent:
        parent.value === "null"
          ? undefined
          : this.props.companies.find(comp => comp._id === parent.value)
            ? "null"
            : parent.value,
      state: state.value,
      city: city.value,
      region,
      address,
      siteNumber,
      realEstateManager: realEstateManager._id,
      constructionManager: constructionManager._id,
      architectPM: architectPM._id,
      generalContractor: generalContractor._id,
      architecturalFirm: architecturalFirm._id,
      developer: developer._id,
      description,
      startDate,
      estimatedEndDate,
      PMDGJobCode,
      //if parent is Client search into companies else serach on allproject thats also containes of programs
      client: !selectedProject
        ? this.props.companies.some(comp => comp._id === parent.value)
          ? parent.value
          : this.props.allProjects.some(pro => pro._id === parent.value).client
        : this.props.allProjects.find(
          project => project._id === selectedProject
        ).client,
      isProgram: type.value === "Program"
    });
    this.setState({
      openAddProject: false,
      name: "",
      status: "",
      state: "",
      city: "",
      region: "",
      address: "",
      siteNumber: "",
      realEstateManager: "",
      constructionManager: "",
      architectPM: "",
      generalContractor: "",
      architecturalFirm: "",
      developer: "",
      description: "",
      startDate: "",
      estimatedEndDate: "",
      PMDGJobCode: "",
      client: ""
    });
  };

  // #########################################################################
  // Drawer: Add Project
  // #########################################################################

  handleToggleAddProject = () => {
    this.setState({
      name: "",
      parent: "",
      status: "",
      state: "",
      city: "",
      region: "",
      address: "",
      siteNumber: "",
      realEstateManager: "",
      constructionManager: "",
      architectPM: "",
      generalContractor: "",
      architecturalFirm: "",
      developer: "",
      description: "",
      startDate: "",
      estimatedEndDate: "",
      PMDGJobCode: "",
      client: "",
      type: { label: '', value: '' }
    });
    if (this.props.handleToggleAddProject) this.props.handleToggleAddProject();
    this.setState(prevState => ({ openAddProject: !prevState.openAddProject }));
    this.props.handleRemoveAddSubProjectFlag();
  };

  // #########################################################################
  // COMPONENT
  // #########################################################################

  dropDownStyle = () => ({
    control: styles => ({ ...styles, height: "38px", fontSize: "14px" })
  });

  // #########################################################################
  // handle the cities
  // #########################################################################

  handleCities = () => {
    const { value } = this.state.state;
    const cities =
      usaCities[value] &&
      usaCities[value].map(city => ({ label: city, value: city }));
    return cities;
  };

  // #########################################################################
  // handle all the project
  // #########################################################################

  decorateAllProjects = (projects = []) => {
    const projectOptions = projects.map(project => ({
      value: project._id,
      label: project.name
    }));
    // return this.state.type.value === 'Program' ?
    //   [{ value: "null", label: "No Parent" }, ...projectOptions] :
    //   projectOptions
    return projectOptions;
  };

  decorateAllStates = (objectDate = {}) => {
    return Object.keys(objectDate).map(item => ({ value: item, label: item }));
  };

  // #########################################################################
  // options of the project status
  // #########################################################################

  projectStatuses = () => {
    return projectStatus.map(item => ({
      label: item.toUpperCase(),
      value: item
    }));
  };

  handleArchitectUsers = () => {
    return this.props.users.map(item => ({
      label: item.firstName + " " + item.lastName,
      value: item.firstName + " " + item.lastName,
      _id: item._id
    }));
  };

  handleEstateManager = () => {
    return this.props.companies.map(item => ({
      label: item.name,
      value: item.name,
      _id: item._id
    }));
  };

  handleUsers = () => {
    return this.props.users.map(item => ({
      label: item.firstName + " " + item.lastName,
      value: item.firstName + " " + item.lastName,
      _id: item._id
    }));
  };

  handleCompanies = () => {
    return this.props.companies.map(item => ({
      label: item.name,
      value: item.name,
      _id: item._id
    }));
  };

  handleOpenModal = bool => {
    this.setState({
      addNewClientModal: !!bool
    });
  };

  // #########################################################################
  // render
  // #########################################################################

  render = () => {
    const { classes, openAddProject, withShadow, selectedProject } = this.props;
    const {
      name,
      parent,
      state,
      city,
      region,
      address,
      siteNumber,
      constructionManager,
      PMDGJobCode,
      architectPM,
      generalContractor,
      architecturalFirm,
      developer,
      description,
      startDate,
      estimatedEndDate,
      status,
      type,
      allProjects,
      client,
      addNewClientModal
    } = this.state;
    const fontSizeStyles = {
      placeholder: defaultStyles => {
        return {
          ...defaultStyles,
          fontSize: "14px"
        };
      }
    };
    // FIXME
    let options = [];
    if (allProjects[0])
      if (selectedProject) {
        options =
          allProjects[0] &&
            allProjects.find(project => project._id === selectedProject).isProgram
            ? [
              { label: "Project", value: "Project" },
              { label: "Program", value: "Program" }
            ]
            : [{ label: "Project", value: "Project" }];
      } else {
        if (parent.value) {
          options = allProjects.find(project => project._id === parent.value)
            .isProgram
            ? [
              { label: "Project", value: "Project" },
              { label: "Program", value: "Program" }
            ]
            : [{ label: "Project", value: "Project" }];
        } else
          options = [
            { label: "Project", value: "Project" },
            { label: "Program", value: "Program" }
          ];
      }
    else options = [{ label: "Project", value: "Project" }];

    return (
      <div
        className={`${classes.drawerHolder} ${
          openAddProject && withShadow ? classes.withShadow : classes.noShadow
          }`}
      >
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={openAddProject ? openAddProject : false}
          classes={{ paper: classes.drawerPaper }}
        >
          <div
            style={{ height: "48px", minHeight: "48px" }}
            className={classes.drawerHeader}
          >
            Add Project
          </div>
          <Divider />
          <List style={{ overflowY: "auto" }}>
            <form onSubmit={this.handleAddProject}>
              <Grid container justify={"space-between"}>
                <Grid container className={classes.division}>
                  {/* client */}
                  {this.props.fullForm ? (
                    <>
                      <Grid className={classes.inputHolder} item xs={10}>
                        <label className={classes.lable}>Select Client</label>
                        <Select
                          options={this.decorateAllProjects(
                            allProjects.filter(project => project.email)
                          )}
                          name={"client"}
                          onChange={this.handleSelectChange}
                          className={classes.select}
                          required
                          placeholder={"Client"}
                          defaultValue="null"
                          value={client}
                          maxMenuHeight={170}
                          styles={this.dropDownStyle()}
                        />
                        <input
                          tabIndex={-1}
                          style={{
                            height: 0,
                            width: 0,
                            padding: 0,
                            margin: 0,
                            opacity: 0,
                            position: "absolute",
                            top: 40,
                            left: 200
                          }}
                          value={client}
                          required
                          onChange={this.handleSelectChange}
                          name="hiddenInput"
                        />
                      </Grid>
                      <Grid className={classes.inputHolder} item xs={2}>
                        <Button
                          className={classes.addClientButton}
                          onClick={this.handleOpenModal}
                        >
                          <Add className={classes.addIcon} />
                        </Button>
                      </Grid>
                    </>
                  ) : null}
                  {/* parent  */}
                  {this.props.fullForm ? (
                    <Grid className={classes.inputHolder} item xs={12}>
                      <label className={classes.lable}>Select Parent</label>
                      <Select
                        options={
                          client
                            ? [...this.decorateAllProjects(
                              allProjects.filter(project =>
                                project.client === client.value
                              )
                            ), { value: client.value, label: client.label }]
                            : []
                        }
                        name={"parent"}
                        onChange={this.handleSelectChange}
                        className={classes.select}
                        required
                        placeholder={"Parent"}
                        defaultValue="null"
                        value={parent}
                        maxMenuHeight={170}
                        styles={this.dropDownStyle()}
                        isDisabled={!client}
                      />
                      <input
                        tabIndex={-1}
                        style={{
                          height: 0,
                          width: 0,
                          padding: 0,
                          margin: 0,
                          opacity: 0,
                          position: "absolute",
                          top: 40,
                          left: 200
                        }}
                        value={parent}
                        required
                        onChange={this.handleSelectChange}
                        name="hiddenInput"
                      />
                    </Grid>
                  ) : null}
                  {this.props.fullForm ? (
                    <Grid
                      className={classes.inputHolder}
                      item
                      xs={12}
                      style={{ position: "relative" }}
                    >
                      <label className={classes.lable}>Type</label>
                      <Select
                        options={
                          !(parent.value && allProjects[0]) ?
                            [] :
                            (allProjects.find(project => project._id === parent.value).isProgram)
                              ? [
                                { label: "Project", value: "Project" },
                                { label: "Program", value: "Program" }
                              ] : allProjects.find(project => project._id === parent.value).email ?
                                [{ label: "Program", value: "Program" }] : [{ label: "Project", value: "Project" }]}

                        name={"type"}
                        onChange={this.handleSelectChange}
                        // className={classes.select}
                        required
                        placeholder="Type"
                        value={type}
                        maxMenuHeight={170}
                        styles={this.dropDownStyle()}
                        isDisabled={!parent.value}
                      />
                    </Grid>
                  ) : null}

                  <Grid className={classes.inputHolder} item xs={12}>
                    <label className={classes.lable}>
                      {type && type.value === "Program" ? "Program" : "Project"}{" "}
                      Name
                    </label>
                    <ReactInput
                      name="name"
                      placeholder={`${
                        type && type.value === "Program" ? "Program" : "Project"
                        } Name`}
                      className={classes.textField}
                      value={name}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      required
                      autoComplete="off"
                      type="text"
                    />
                  </Grid>
                  {/* <Grid className={classes.inputHolder} item xs={6} md={6}>
                    <label className={classes.lable}>Client</label>
                    <Select
                      options={this.handleCompanies()}
                      name={"client"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Client"
                      defaultValue="null"
                      value={this.state.client}
                      maxMenuHeight={170}
                      styles={fontSizeStyles}
                    />
                  </Grid> */}
                  <Grid className={classes.inputHolder} item xs={12}>
                    <label className={classes.lable}>Region</label>
                    <ReactInput
                      name="region"
                      placeholder="Region"
                      className={classes.textField}
                      value={region}
                      onChange={this.handleChange}
                      margin="dense"
                      variant="outlined"
                      autoComplete="off"
                      type="text"
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.division}>
                  <Grid className={classes.inputHolder} item xs={6}>
                    <label className={classes.lable}>
                      Select{" "}
                      {type && type.value === "Program" ? "Program" : "Project"}{" "}
                      Status
                    </label>

                    <Select
                      options={this.projectStatuses()}
                      name={"status"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      required
                      placeholder="Status"
                      defaultValue="null"
                      value={status}
                      maxMenuHeight={170}
                      styles={this.dropDownStyle()}
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={6}>
                    <label className={classes.lable}>PMDG Job Code</label>
                    <ReactInput
                      name="PMDGJobCode"
                      placeholder="PMDG Job Code"
                      className={classes.textField}
                      value={PMDGJobCode}
                      onChange={this.handleChange}
                      margin="dense"
                      variant="outlined"
                      autoComplete="off"
                      type="text"
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={12} md={6}>
                    <label className={classes.lable}>State</label>
                    <Select
                      options={this.decorateAllStates(usaCities)}
                      name={"state"}
                      onChange={this.handleSelectChange}
                      styles={this.dropDownStyle()}
                      required
                      placeholder="Select State"
                      maxMenuHeight={250}
                      value={state}
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={12} md={6}>
                    <label className={classes.lable}>City</label>
                    <Select
                      options={this.handleCities()}
                      name={"city"}
                      onChange={this.handleSelectChange}
                      styles={this.dropDownStyle()}
                      required
                      placeholder="Select City"
                      maxMenuHeight={250}
                      value={city}
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={12} md={12}>
                    <label className={classes.lable}>Site Number</label>
                    <ReactInput
                      name="siteNumber"
                      placeholder="Site Number"
                      className={classes.textField}
                      value={siteNumber}
                      onChange={this.handleChange}
                      margin="dense"
                      variant="outlined"
                      autoComplete="off"
                      type="number"
                      styles={fontSizeStyles}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.division}>
                  <Grid className={classes.inputHolder} item xs={6} md={6}>
                    <label className={classes.lable}>Real Estate Manager</label>
                    <Select
                      options={this.handleUsers()}
                      name={"realEstateManager"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Real Estate Manager"
                      defaultValue="null"
                      value={this.state.realEstateManager}
                      maxMenuHeight={170}
                      styles={fontSizeStyles}
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={12} md={6}>
                    <label className={classes.lable}>
                      Construction Manager
                    </label>
                    <Select
                      options={this.handleUsers()}
                      name={"constructionManager"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Construction Manager"
                      defaultValue="null"
                      value={constructionManager}
                      maxMenuHeight={170}
                      styles={fontSizeStyles}
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={12} md={6}>
                    <label className={classes.lable}>Architectural Firm</label>
                    <Select
                      options={this.handleUsers()}
                      name={"architecturalFirm"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Architectural Firm"
                      defaultValue="null"
                      value={architecturalFirm}
                      maxMenuHeight={170}
                      styles={fontSizeStyles}
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={12} md={6}>
                    <label className={classes.lable}>Architect PM</label>
                    <Select
                      options={this.handleArchitectUsers()}
                      name={"architectPM"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Architect PM"
                      defaultValue="null"
                      value={architectPM}
                      maxMenuHeight={170}
                      styles={fontSizeStyles}
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={12} md={6}>
                    <label className={classes.lable}>Developer</label>
                    <Select
                      options={this.handleArchitectUsers()}
                      name={"developer"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Developer"
                      defaultValue="null"
                      value={developer}
                      maxMenuHeight={170}
                      styles={fontSizeStyles}
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={6}>
                    <label className={classes.lable}>General Contractor</label>
                    <Select
                      options={this.handleArchitectUsers()}
                      name={"generalContractor"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="General Contractor"
                      defaultValue="null"
                      value={generalContractor}
                      maxMenuHeight={170}
                      styles={fontSizeStyles}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.division}
                  style={{ border: "none" }}
                >
                  <Grid className={classes.inputHolder} item xs={6}>
                    <label className={classes.lable}>Start Date</label>
                    <ReactInput
                      name="startDate"
                      type="date"
                      className={classes.textField}
                      variant="outlined"
                      value={startDate}
                      onChange={this.handleChange}
                      margin="dense"
                      autoComplete="off"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={6}>
                    <label className={classes.lable}>Estimated End Date</label>
                    <ReactInput
                      name="estimatedEndDate"
                      type="date"
                      value={estimatedEndDate}
                      className={classes.textField}
                      variant="outlined"
                      onChange={this.handleChange}
                      margin="dense"
                      autoComplete="off"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={12}>
                    <label className={classes.lable}>Address</label>
                    <ReactInput
                      name="address"
                      placeholder="Address"
                      className={classes.textField}
                      value={address}
                      onChange={this.handleChange}
                      margin="dense"
                      variant="outlined"
                      autoComplete="off"
                      type="text"
                    />
                  </Grid>
                  <Grid className={classes.inputHolder} item xs={12}>
                    <label className={classes.lable}>Description</label>
                    <textarea
                      name="description"
                      placeholder="Description"
                      className={classes.textField}
                      value={description}
                      onChange={this.handleChange}
                      autoComplete="off"
                      type="text"
                    />
                  </Grid>

                  <div className={classes.buttonHolder}>
                    <CButton className={classes.button} type={"submit"}>
                      Add{" "}
                      {type && type.value === "Program" ? "Program" : "Project"}
                    </CButton>
                  </div>
                </Grid>
              </Grid>
            </form>
          </List>
          <IconButton
            aria-label="Delete"
            className={`${classes.addButtonOpen} ${
              openAddProject && withShadow ? classes.withBorder : null
              }`}
            style={{ left: openAddProject ? "-45px" : "-156px" }}
            onClick={this.handleToggleAddProject}
          >
            <span className={classes.addProjectThumb}>
              <EjectIcon
                className={`${classes.ejectIcon} ${
                  openAddProject
                    ? classes.ejectIconOpen
                    : classes.ejectIconClose
                  }`}
              />
              {openAddProject ? (
                ""
              ) : (
                  <span className={classes.addButtonText}>ADD PROJECT</span>
                )}
            </span>
          </IconButton>
        </Drawer>
        <Modal
          isShowed={addNewClientModal}
          handlerInfoModal={this.handleOpenModal}
        >
          <Card className={classes.modalCard}>
            <Grid container justify="space-between">
              <Typography variant="h5" className={classes.modalTitle}>
                Add New Client
              </Typography>
              <Close
                className={classes.closeIcon}
                onClick={() => this.handleOpenModal(false)}
              />
            </Grid>

            <AddClientInline handleOpenModal={this.handleOpenModal} />
          </Card>
        </Modal>
      </div>
    );
  };
}

// #############################################################################
// STYLES
// #############################################################################

const drawerWidth = 420;
const styles = theme => ({
  root: {
    display: "flex"
  },
  withShadow: {
    boxShadow: "0px 0px 5px 2000px #002d3e40"
  },
  noShadow: {
    boxShadow: "0px 0px 5px 2000px #00000000"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawer: {
    position: "relative",
    width: drawerWidth,
    flexShrink: 0,
    "&>div": {
      overflowY: "unset"
    }
  },
  drawerHolder: {
    transition: "box-shadow 300ms cubic-bezier(0, 0, 0.2, 1)",
    position: "fixed",
    zIndex: "1200"
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    minHeight: "48px !important",
    padding: "0 22px",
    color: "#000000a3",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start"
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth
  },
  header: {
    flex: 1,
    minHeight: "48px !important",
    backgroundColor: "#f4f5f9",
    borderBottom: "1px #c3c1c2 solid",
    padding: "18px 30px",
    boxSizing: "border-box"
  },
  lable: {
    color: "#00000057",
    position: "absolute",
    fontSize: "12px",
    top: "-4px",
    margin: "0px 3px"
  },
  inputHolder: {
    position: "relative",
    padding: "12px 4px 10px"
  },
  buttonHolder: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 4px"
  },
  button: {
    margin: "0px !important",
    maxWidth: "195px"
  },

  addProjectForm: {
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  textField: {
    width: "100%",
    color: "#333333",
    fontSize: "14px",
    "&::-webkit-inner-spin-button": {
      "- webkit - appearance": "none",
      display: "none"
    },
    "&>label": {
      zIndex: "unset"
    },
    "&>div": {
      borderRadius: "5px"
    }
  },
  ////////////// eject button ////////////////////////////////////////////////
  addButtonText: {
    paddingRight: "10px",
    paddingButton: "2px",
    fontSize: "15px"
  },
  addButtonOpen: {
    position: "absolute",
    padding: "3px",
    top: "4px",
    cursor: "pointer",
    color: "white",
    borderRadius: "5px",
    backgroundColor: "#105596",
    border: "2px solid #00000000",
    "&:hover": {
      backgroundColor: "#1661a8"
    }
  },
  withBorder: {
    border: "2px solid #ffffffa6"
  },
  addProjectThumb: {
    display: "flex",
    alignItems: "center"
  },
  ejectIcon: {
    padding: "3px",
    transition: "transform 200ms ease-in-out",
    transitionDelay: "300ms",
    transform: "rotate(-90deg)"
  },
  ejectIconOpen: {
    transform: "rotate(90deg)"
  },
  division: {
    borderBottom: "4px solid #ededed",
    marginTop: "10px",
    padding: "0 7px"
  },
  addClientButton: {
    backgroundColor: "#155794",
    "&:hover": {
      backgroundColor: "#4C4B94"
    }
  },
  addIcon: {
    color: "white"
  },
  modalCard: {
    maxWidth: "85%"
  },
  modalTitle: {
    padding: "15px 0 0 20px",
    color: "#606061"
  },
  closeIcon: {
    padding: "15px 20px 0 0 ",
    color: "#bdbdbd",
    cursor: "pointer"
  }
});

// #############################################################################
// CONNECT TO MATERIAL STYLES
// #############################################################################

const ProjectFormWithStyles = withStyles(styles)(ProjectForm);

// #############################################################################
// EXPORT
// #############################################################################

export { ProjectFormWithStyles as ProjectForm };
