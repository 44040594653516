import { userConstants } from "../constants";

export function authentication(state = {}, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return { loggedIn: true, user: action.payload };
    case userConstants.LOGIN_FAILURE:
      return { loggedIn: false, msg: action.payload };
    default:
      return state;
  }
}
