import React from 'react';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    modal: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dialog: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}

class SimpleModal extends React.PureComponent {

    state = {
        open: false
    }

    componentDidMount() {
        if (this.props.isShowed && !this.state.open) this.setState({ open: true })
        if (!this.props.isShowed && this.state.open) this.setState({ open: false })
    }

    componentDidUpdate() {
        if (this.props.isShowed && !this.state.open) this.setState({ open: true })
        if (!this.props.isShowed && this.state.open) this.setState({ open: false })
    }


    handleClose = () => {
        this.setState({ open: false })
        this.props.handlerInfoModal(false)
    };

    render() {
        const { children, classes } = this.props
        const { open } = this.state
        return (
            <div>
                <Modal
                    open={open}
                    onClose={this.handleClose}
                    className={classes.modal}
                >
                        {children}
                </Modal>
            </div>
        )
    }
}

const ModalHOC = withStyles(styles)(SimpleModal);
export default ModalHOC