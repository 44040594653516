import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import { TextField, CButton } from "../components";
import { userActions } from "../actions";

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh",
    height: "100%",
    // justifyContent: 'center',
    alignItems: "center"
  },
  card: {
    height: "500px",
    borderRadius: "5px",
    backgroundColor: "#9BDDFE",
    minWidth: "250px",
    maxWidth: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: "40px"
  },
  logoContainer: {
    flex: 2,
    color: "white",
    alignItems: "center",
    display: "flex"
  },
  logo: {
    color: "white"
  },
  formContainer: {
    flex: 2,
    width: "100%"
  },
  title: {
    color: "#020058",
    textAlign: "center"
  },
  submitButton: {
    margin: '24px 0'
  }
});

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: undefined,
      confirm: undefined
    };
  }
  
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  savePassword = e => {
    e.preventDefault();
    //TODO Validation
    const {password, confirm} = this.state;
    const id = this.props.match.params.verificationId;
    if (password === confirm) {
      this.props.dispatch(userActions.verify(id,password));
    } else {
      
    }        
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify={"center"}>
          <Grid item xs={12} sm={6} md={6}>
            <Grid className={classes.card}>
              <div className={classes.logoContainer}>
                <Typography className={classes.logo} variant={"h2"}>
                  Logo
                </Typography>
              </div>
              <div className={classes.formContainer}>
                <form autoComplete="off" onSubmit={this.savePassword}>
                  <Typography className={classes.title} variant={"h6"}>
                    Welcome to Project Manager
                  </Typography>
                  <Typography className={classes.title}>
                    Please Enter Your Password
                  </Typography>
                  <TextField
                    required
                    name={"password"}
                    type={"password"}
                    onChange={this.handleChange}
                    className={classes.input}
                    placeholder={"Password"}
                  >
                    <LockIcon />
                  </TextField>
                  <TextField
                    required
                    autoComplete={"on"}
                    name={"confirm"}
                    onChange={this.handleChange}
                    type={"password"}
                    placeholder={"Confirm Password"}
                  >
                    <LockIcon />
                  </TextField>
                  <CButton type={"Save"} className={classes.submitButton}>
                    Save
                  </CButton>
                </form>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

//############################################################################
// connect component to styles and redux
//############################################################################

const mapStateToProps = state => {
  return {
  };
};

//############################################################################
// styles
//############################################################################
const connectedVerify = connect(mapStateToProps)(Verify);
const VerifyWithStyle = withStyles(styles)(connectedVerify);
export { VerifyWithStyle as Verify };
