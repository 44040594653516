import { convertToObject } from "./"

// #############################################################################
// this function will return the new nested array with new order
// #############################################################################

export function updateOrder(array = [], dragItem, dropItem){
  const convertedToObject = convertToObject(array, "_id")

  // this line will find all the child of the parent element and sort theme by their order
  const subTasks = exportAllChilds(convertedToObject, dragItem.parentTask).sort((a, b) => a.order - b.order);


  // these two lines will remove the child from the list
  let index = findIndex(subTasks, "_id", dragItem._id);
  let subArray = [ ...subTasks.slice(0, index), ...subTasks.slice(index + 1) ];

  // these two lines add the child to its new place
  index = findIndex(subTasks, "_id", dropItem._id);
  subArray = [ ...subArray.slice(0, index), convertedToObject[dragItem._id] , ...subArray.slice(index) ];

  //this line will refresh the order of the list by their indexes
  const arrayWithNewOrder = refreshOrder(subArray);
  const subTasksObject = convertToObject(arrayWithNewOrder, "_id");

  const outputObject = { ...convertedToObject, ...subTasksObject };
  const outputArray = converToArray(outputObject);

  return [outputArray, outputObject]
  
}

// #############################################################################
// this function collects all the child
// #############################################################################

export function exportAllChilds(object = {}, parent ) {
  if(parent !== null) return object[parent].subTask.map(item => object[item])
  else return exportRootParents(object)
}

// #############################################################################
// export root parent
// #############################################################################

export function exportRootParents(object){
  const outPutArray = [];
  const keys = Object.keys(object)

  for (const key of keys)
    if (object[key].parentTask === null)
      outPutArray.push(object[key])

  return outPutArray
}

// #############################################################################
// 
// #############################################################################

export function findIndex(array, key, searchKey){
  return array.findIndex(item =>( item[key] === searchKey ))
}

// #############################################################################
// refresh order
// #############################################################################

export function refreshOrder(array = []) {
  return array.map((item, index) => {
    return {...item, order: index + 1 }
  })
}

// #############################################################################
// convert to object
// #############################################################################

export function converToArray(object){
  const outPutArray = [];
  const keys = Object.keys(object)
  for (const key of keys) outPutArray.push(object[key])
  return outPutArray
}

