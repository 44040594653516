import React from 'react';
import { Progress, TaskInfo, DeleteAlert, EditTaskForm, TaskForm } from "./"
import { setUpGhostImage } from "../helpers"
import IconButton from "@material-ui/core/IconButton"

// #####[ MATERIAL ICONS ]######################################################
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DragHandleIcon from "@material-ui/icons/DragHandle";

// #############################################################################
// component
// #############################################################################

class TaskLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubs: false,
      showInfo: false,
      showAlert: false,
      deleted: false,
      showEditForm: false,
      showTaskForm: false,
    };
    this.taskRef = React.createRef();
    this.infoRef = React.createRef();
    this.coverRef = React.createRef();
  }

  // ###########################################################################
  // show of hide child
  // ###########################################################################

  toggleChild = (hasChild) => () => {
    if (hasChild) {
      this.setState({ showSubs: !this.state.showSubs });
      this.props.handleExpand(this.props.task._id, !this.state.showSubs);
    }
  }

  // ###########################################################################
  // show info of task
  // ###########################################################################

  toggleInfo = (showInfo = false) => {
    this.setState({ showInfo, showEditForm: false, showTaskForm: false });
    this.props.handleExpandForm(this.props.task._id, showInfo, 128);
  }
  // ###########################################################################
  // show delete alert
  // ###########################################################################
  toggleDeleteAlert = () => {
    this.setState({ showAlert: !this.state.showAlert });
  }

  // ###########################################################################
  // show add task form
  // ###########################################################################

  toggleTaskForm = (showTaskForm = false) => {
    this.setState({ showTaskForm, showInfo: false, showEditForm: false });
    this.props.handleExpandForm(this.props.task._id, showTaskForm, 182);
  }

  // ###########################################################################
  // handles delete of task
  // ###########################################################################

  handleDelete = () => {
    this.props.handleDelete({ id: this.props.task._id });
    this.setState({ deleted: true })
  }

  // ###########################################################################
  // handles edit of task
  // ###########################################################################

  toggleEditForm = (showEditForm = false) => {
    this.setState({ showEditForm, showTaskForm: false, showInfo: false });
    const additionalHeight = this.props.task.subTask.length > 0 ? 145 : 182;
    this.props.handleExpandForm(this.props.task._id, showEditForm, additionalHeight);
  }

  // ###########################################################################
  // drag start
  // ###########################################################################
  dragStart = reference => event => {
    setUpGhostImage(event, this.infoRef);
    this.props.setDragItem(this.props.task);
    this.infoRef.current.classList.add(this.props.classes.hideInside);
    setTimeout(() => {
      reference.current.style.opacity = 1;
    }, 0);
  }

  // ###########################################################################
  // drag Enter
  // ###########################################################################

  dragEnter = reference => event => {
    const { parentTask } = this.props.task
    if (parentTask === this.props.dragItem.parentTask) {
      this.infoRef.current.style.outline = "2px dashed #00000070";
      this.infoRef.current.style.backgroundColor = "#e6fbf4";
    }
    else {
      this.infoRef.current.style.outline = "2px dashed #ff000075";
      this.infoRef.current.style.backgroundColor = "#ffd1d1";
    }
  }

  // ###########################################################################
  // drag over
  // ###########################################################################

  dragOver = reference => event => {
    event.preventDefault();
  }

  // ###########################################################################
  // drag Enter
  // ###########################################################################

  dragLeave = reference => event => {
    const { task, dragItem } = this.props;
    if (task.parentTask === dragItem.parentTask) {
      this.infoRef.current.style.outline = "none";
      this.infoRef.current.style.backgroundColor = "#fff";

      if (dragItem._id === task._id)
        this.infoRef.current.style.backgroundColor = "#00000000";
    }
    else {
      this.infoRef.current.style.outline = "none";
      this.infoRef.current.style.backgroundColor = "#fff";

    }

  }

  // ###########################################################################
  // drag end
  // ###########################################################################

  dragEnd = reference => event => {
    reference.current.style.opacity = 1;
    this.infoRef.current.style.outline = "none";
    this.infoRef.current.style.backgroundColor = "#fff";
    this.infoRef.current.classList.remove(this.props.classes.hideInside);
  }

  // ###########################################################################
  // drag end
  // ###########################################################################

  drop = reference => event => {
    const { task, dragItem } = this.props;

    this.infoRef.current.style.outline = "none";
    this.infoRef.current.classList.remove(this.props.classes.hideInside);
    this.infoRef.current.style.backgroundColor = "#fff";

    if (task.parentTask === dragItem.parentTask)
      this.props.handleSort(this.props.task);
  }

  // ###########################################################################
  // this function will return the list of sub tasks
  // ###########################################################################

  getSubTasks = (tasks, task) => {
    return task.subTask.map(key => tasks[key]).sort((a, b) => a.order - b.order)
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const { showSubs, showInfo, showAlert, deleted, showEditForm, showTaskForm } = this.state
    const { classes, task, tasks, maxEdge, minEdge, width, users = [], rawData = [] } = this.props;
    const { indent = 0, handleEditTask, draggable = false, project = {} } = this.props

    const hasChild = task.subTask && task.subTask.length > 0;
    const hasParent = task.parentTask ? true : false;
    const subTasks = this.getSubTasks(tasks, task);

    if (!deleted)
      return (
        <React.Fragment>
          <div
            ref={this.coverRef}
            draggable={draggable}
            onDragStart={this.dragStart(this.taskRef)}
            onDragOver={this.dragOver(this.taskRef)}
            onDragEnter={this.dragEnter(this.taskRef)}
            onDragLeave={this.dragLeave(this.taskRef)}
            onDragEnd={this.dragEnd(this.taskRef)}
            onDrop={this.drop(this.taskRef)}
            style={{ width: width, zIndex: draggable ? 11 : -1 }}
            className={classes.dragHandler}
          />
          <div
            ref={this.taskRef}
            style={{ width: width }}
            className={classes.root}
          >
            <div
              ref={this.infoRef}
              style={{ paddingLeft: indent + 5, width: draggable ? 324 : 300 }}
              className={classes.wrapper}
            >
              <DeleteAlert handleDelete={this.handleDelete} toggleDeleteAlert={this.toggleDeleteAlert} showAlert={showAlert} />
              <div className={` ${classes.dragHolder} ${!draggable ? classes.dragIconHide : ''}`}>
                <DragHandleIcon classes={{ root: `${classes.dragIcon}` }} />
              </div>
              <IconButton
                onClick={this.toggleChild(hasChild)}
                className={`${classes.button} ${hasChild ? classes.orange : null} ${showSubs && hasChild ? classes.open : null}`}
              >
                <ChevronRightIcon />
              </IconButton>

              <div className={`${classes.taskName} ${hasParent ? null : classes.bold}`}>{task.name}</div>

              <IconButton onClick={this.toggleDeleteAlert} className={`${classes.cancelIcon}`}>
                <CancelOutlinedIcon classes={{ root: classes.actionIcons }} />
              </IconButton>

              <IconButton onClick={() => { this.toggleEditForm(!showEditForm) }} className={`${classes.editIcon} ${showEditForm ? classes.highlight : null}`}>
                <EditOutlinedIcon classes={{ root: classes.actionIcons }} />
              </IconButton>

              <IconButton onClick={() => { this.toggleTaskForm(!showTaskForm) }} className={`${classes.addIcon} ${showTaskForm ? classes.highlight : null}`}>
                <AddCircleOutlineIcon classes={{ root: classes.actionIcons }} />
              </IconButton>

              <IconButton onClick={() => { this.toggleInfo(true) }} className={`${classes.infoIcon} ${showInfo ? classes.highlight : null}`}>
                <InfoOutlinedIcon classes={{ root: classes.actionIcons }} />
              </IconButton>
            </div>

            <Progress
              width={width}
              handleEditTask={handleEditTask}
              task={task}
              tasks={tasks}
              minEdge={minEdge}
              maxEdge={maxEdge}
              handleListOfChanges={this.props.handleListOfChanges}
            />

          </div>

          {/* ################################################################ */}

          {!showInfo ? null : <TaskInfo toggleInfo={this.toggleInfo} showInfo={showInfo} task={task} />}

          {/* ################################################################ */}
          {!showEditForm ? null :
            <EditTaskForm
              handleEditTask={handleEditTask}
              toggleEditForm={this.toggleEditForm}
              showEditForm={showEditForm}
              task={task}
              tasks={rawData}
            />
          }

          {/* ################################################################ */}
          {!showTaskForm ? null :
            <TaskForm
              tasks={rawData}
              project={project}
              users={users}
              showTaskForm={showTaskForm}
              toggleTaskForm={this.toggleTaskForm}
              handleAddTask={this.props.handleAddTask}
              task={task}
            />
          }

          {/* ################################################################ */}

          {!showSubs ? null : subTasks.map(task =>
            <TaskLine
              key={task._id}

              task={task}
              tasks={tasks}

              users={users}
              project={project}
              rawData={rawData}

              draggable={this.props.draggable}
              dragItem={this.props.dragItem}
              width={width}
              indent={indent + 10}
              classes={classes}

              minEdge={minEdge}
              maxEdge={maxEdge}

              setDragItem={this.props.setDragItem}
              handleExpand={this.props.handleExpand}
              handleDelete={this.props.handleDelete}
              handleAddTask={this.props.handleAddTask}
              handleEditTask={handleEditTask}
              handleSort={this.props.handleSort}
              handleExpandForm={this.props.handleExpandForm}
              handleListOfChanges={this.props.handleListOfChanges}
            />
          )}

          {/* ################################################################ */}

        </React.Fragment>
      );
    else return null
  }
}

// #############################################################################
// export
// #############################################################################

export { TaskLine };

