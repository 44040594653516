import React from "react";
import { AttachmentModalItem } from './AttachmentModalItem'
// #####[ material ui ]##############################################################
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search"

// #####[ redux ]##############################################################
import { attachmentActions } from "../../actions";
import { attachmentUserActions } from "../../userActions/";
import { connect } from "react-redux";

// #####[ styles ]##############################################################
import { Colors } from "../../styles";

// #####[ components ]##############################################################
import { ReactInput } from '../reactInput/reactInput'
import DeleteModal from '../Modal/DeleteModal'

class AttachmentModalContent extends React.PureComponent {
    state = {
        value: 0,
        activitiy: null,
        activeTab: 'All',
        documents: [],
        searchInput: '',
        openDeleteModal: false,
        selectedOptionForDelete: {}
    };

    componentDidMount() {
        // this.props.toggleSearchInput(true);
        if (this.props.role === 'adminFlag')
            this.props.dispatch(attachmentActions.getAttachments({ projectId: this.props.projectId }));
        else
            this.props.dispatch(attachmentUserActions.getAttachments({ projectId: this.props.projectId }));
    }

    componentDidUpdate(prevProps) {
        if (this.props.documents && prevProps.documents !== this.props.documents) {
            this.setState(() => ({
                documents: this.props.documents
            }))
        }
    }

    handleDownload = (link, e) => {
        e.preventDefault();
        window.open(link);
    };

    handleChangeTab = (event, value) => {
        this.setState({ value });
    };

    componentWillUnmount() {
        this.setState({
            documents: {},
            activitiy: null
        });
    }

    handleFilterTabData = data => {

        return this.state.activeTab === 'All' ?
            data :
            data.filter(item => {
                return item.tags.find(tag => tag === this.state.activeTab) ? true : false
            })
    }

    handleFilterSearchInputData = data => {
        const searchInput = this.state.searchInput.toLowerCase()

        return data.filter(row => {
            const sender = (row.sender.firstName + ' ' + row.sender.lastName).toLowerCase();
            const tags = row.tags;
            const name = row.name.toLowerCase()

            return !sender.includes(searchInput) &&
                !name.includes(searchInput) &&
                !tags.some(tag => tag.toLowerCase().includes(searchInput)) ? false : true
        })
    }


    handleArrayToObject = (data) => {
        return data.map(dataElement => {
            return { value: dataElement, label: dataElement }
        })
    }

    handleEditAttachment = (name, attachmentId, tags) => {
        const projectId = this.props.projectId;
        if (this.props.role === 'adminFlag')
            this.props.dispatch(attachmentActions.editAttachment({ projectId, attachmentId, name, tags }));
        else
            this.props.dispatch(attachmentUserActions.editAttachment({ projectId, attachmentId, name, tags }));
    }

    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value })
    }

    // #######################################################################################
    // ####################              Dialog: Delete              ####################
    // #######################################################################################

    handleDeleteModal = selectedOptionForDelete => {
        selectedOptionForDelete ?
            this.setState({
                openDeleteModal: true,
                selectedOptionForDelete
            }) :
            this.setState({
                openDeleteModal: false,
            })
    }


    render() {
        const { classes, handlerAttachmentModal, projectName } = this.props;
        const { activeTab, documents, searchInput } = this.state;
        return (
            <div className={classes.modalContainer} tabIndex={1}>
                <Close
                    className={classes.closeIcon}
                    onClick={() => handlerAttachmentModal(false)}
                />
                <h3>Project Attachments</h3>
                <div>
                    <span>{projectName}</span>
                </div>
                <div className={classes.attachmentListContainer}>
                    <section className={classes.attachmentAndContractList}>
                        <header className={classes.header}>
                            <ul className={classes.headerList}>
                                <li
                                    onClick={() => this.setState({ activeTab: 'All' })}
                                    className={activeTab === 'All' ? classes.activeTab : classes.diactiveTab}>
                                    All
                                </li>
                                <li
                                    onClick={() => this.setState({ activeTab: 'Invoice' })}
                                    className={activeTab === 'Invoice' ? classes.activeTab : classes.diactiveTab}>
                                    Invoice
                                </li>
                                <li
                                    onClick={() => this.setState({ activeTab: 'Contract' })}
                                    className={activeTab === 'Contract' ? classes.activeTab : classes.diactiveTab}>
                                    Contract
                                </li>
                                <li
                                    onClick={() => this.setState({ activeTab: 'Change Order' })}
                                    className={activeTab === 'Change Order' ? classes.activeTab : classes.diactiveTab}>
                                    Change Order
                                </li>
                                <li
                                    onClick={() => this.setState({ activeTab: 'Misc Document' })}
                                    className={activeTab === 'Misc Document' ? classes.activeTab : classes.diactiveTab}>
                                    Misc Document
                                </li>
                            </ul>
                            <div className={classes.searchInputWrapper}>
                                <ReactInput
                                    type='text'
                                    value={searchInput}
                                    name='searchInput'
                                    onChange={this.handleChange}
                                    className={classes.searchInput}
                                    placeholder='Search'
                                />
                                <Search className={classes.searchIcon} />
                            </div>
                        </header>

                        <article className={classes.article}>
                            {
                                this.handleFilterSearchInputData(this.handleFilterTabData(documents))
                                    .map((document, index) => (
                                        <AttachmentModalItem
                                            key={index}
                                            handleDeleteModal={this.handleDeleteModal}
                                            handleEditAttachment={this.handleEditAttachment}
                                            data={document}
                                            tagsObject={this.handleArrayToObject(document.tags)}
                                            filesSizeComputer={(arg) => arg}
                                            role={this.props.role}
                                        />
                                    ))
                            }
                        </article>
                    </section>
                </div>

                <DeleteModal
                    open={this.state.openDeleteModal}
                    handleDeleteModal={this.handleDeleteModal}
                    selectedOptionForDelete={{ attachmentId: this.state.selectedOptionForDelete._id }}
                    adminAction={attachmentActions.deleteAttachment}
                    userAction={attachmentUserActions.deleteAttachment}
                    //optional
                    modalTitle={'Delete Attachment'}
                    optionNameForDelete={this.state.selectedOptionForDelete.name}
                // messageText={`This action will delete the entire project and the users !!!.You won't be able to restore, are you sure you want to delete?`}

                //confirm
                // isConfirmNeeded={true}
                // confirmWithName={'123'}
                />
            </div >
        );
    }
}

const styles = {
    modalContainer: {
        width: "800px",
        borderRadius: "10px",
        boxShadow: "0 0 10px #545454",
        padding: "30px 30px 15px 30px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#EFEFF9",
        "& > h3": {
            color: Colors.darkBlue,
            margin: "0",
            display: 'block'
        },
        "& >div": {
            marginBottom: '10px'
        },
        "& > div > span": {
            fontSize: "13px",
            padding: " 0 0 10px 0",
            margin: '0',
            color: Colors.grey,
            borderBottom: "1px solid #D1D1DB",
        }
    },
    closeIcon: {
        color: Colors.grey,
        position: "absolute",
        top: 10,
        right: 10,
        cursor: "pointer"
    },
    attachmentListContainer: {
        display: "flex",
        height: "100%"
    },
    attachmentAndContractList: {
        width: "50%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        flexGrow: 100,
        maxHeight: "600px"
    },
    header: {
        width: '100%',
        borderBottom: `2px solid ${Colors.lightGreen}`,
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    headerList: {
        listStyleType: 'none',
        padding: '10px 0',
        boxSizing: 'border-box',
        userSelect: 'none',
        margin: 0,
        fontWeight: '500',
        '& li': {
            display: 'inline',
            margin: '12px 0 ',
            cursor: 'pointer',
            padding: '10px 20px',
        },

    },

    activeTab: {
        color: 'white',
        backgroundColor: Colors.lightGreen,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
    },
    diactiveTab: {
        color: 'black',

    },
    searchInputWrapper: {
        position: 'relative'
    },
    searchIcon: {
        position: 'absolute',
        top: '6px',
        right: '10px',
        fontSize: '20px',
        color: Colors.grey
    },
    searchInput: {
        borderRadius: '30px',
        height: '30px!important',
        paddingRight: '33px',
        fontSize: '13px',
        color: Colors.grey
    },
    article: {
        height: 'calc(100vh - 300px)!important',
        minHeight: '150px !important',
        overflowY: 'overlay',
    }
};

const mapStateToProps = state => {
    return {
        projectId: state.projects.project._id,
        projectName: state.projects.project.name,
        documents: state.attachments,
        role: state.projects.project.role
    };
};

export default connect(mapStateToProps)(withStyles(styles)(AttachmentModalContent))

