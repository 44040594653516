// React //
import React from "react";
import { Link } from "react-router-dom";

// Redux //
import { connect } from "react-redux";
import { userActions } from "../../../actions";

// Material Ui //
import { withStyles } from "@material-ui/core";
import { Colors } from "../../../styles";

// Components //
import { EditIcon, DeleteIcon, BagIcon, FilledArrow } from "../../Icons";
import { ReactInput } from "../../";
import { SaveButton, CancelButton } from "../../Buttons";
import Select from "react-select";

// Reusable Functions //

// Styles //

class UsersModalTableItem extends React.PureComponent {
  state = {
    editMode: false,
    firstName: this.props.user.firstName,
    lastName: this.props.user.lastName,
    email: this.props.user.email,
    phoneNumber: this.props.user.phoneNumber,
    company: {
      label: this.props.user.company.name,
      value: this.props.user.company.name,
      id: this.props.user.company._id
    },
    title: this.props.user.title,
    category: this.props.user.category,
    showProjects: false,
    changes: {}
  };

  componentDidUpdate(prevProps) {
    prevProps.user !== this.props.user &&
      this.setState({
        ...this.props.user,
        company: {
          label: this.props.user.company.name,
          value: this.props.user.company.name,
          id: this.props.user.company._id
        }
      });

    !this.props.open &&
      this.setState({
        showProjects: false,
        editMode: false,
        ...this.props.user
      });
  }

  handleEditMode = () => {
    this.setState({
      editMode: true,
      showProjects: false
    });
  };

  handleSelectChange = e => {
    this.setState({
      company: e,
      changes: {
        company: e.id
      }
    });
  };

  handleChanges = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
      changes: {
        ...this.state.changes,
        [name]: value
      }
    });
  };

  handleCancelButton = () => {
    this.setState({
      editMode: false
    });
  };

  handleSaveButton = e => {
    const { dispatch, user } = this.props;
    const { changes } = this.state;
    e.preventDefault();
    Object.entries(changes).length !== 0 &&
      dispatch(userActions.editCompanyUser({ ...changes, id: user._id }));
    this.setState({
      editMode: false,
      changes: {},
      ...user
    });
  };

  handleOpenProjects = () => {
    this.setState(prevState => ({
      showProjects: !prevState.showProjects,
      editMode: false
    }));
  };

  render() {
    const { classes, user, handleDeleteModal, companies } = this.props;
    const {
      editMode,
      firstName,
      lastName,
      email,
      phoneNumber,
      company,
      title,
      showProjects
    } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <form onSubmit={this.handleSaveButton} className={classes.row}>
          <div className={classes.rowItem} style={{ flex: 2 }}>
            {editMode ? (
              <>
                <ReactInput
                  className={classes.reactInputNames}
                  placeholder="FirstName"
                  value={firstName}
                  name="firstName"
                  onChange={this.handleChanges}
                  required
                />
                <ReactInput
                  className={classes.reactInputNames}
                  placeholder="LastName"
                  value={lastName}
                  name="lastName"
                  onChange={this.handleChanges}
                  required
                />
              </>
            ) : (
              `${user.firstName} ${user.lastName}`
            )}
          </div>
          <div className={classes.rowItem} style={{ flex: 2 }}>
            {editMode ? (
              <ReactInput
                className={classes.reactInput}
                placeholder="Email"
                value={email}
                name="email"
                onChange={this.handleChanges}
                type="email"
                required
              />
            ) : (
              <span className={classes.text}>{user.email}</span>
            )}
          </div>
          <div className={classes.rowItem} style={{ flex: 2 }}>
            {editMode ? (
              <ReactInput
                className={classes.reactInput}
                placeholder="PhoneNumber"
                value={phoneNumber}
                name="phoneNumber"
                onChange={this.handleChanges}
                type="number"
                required
              />
            ) : (
              <span className={classes.text}>{user.phoneNumber}</span>
            )}
          </div>
          <div
            className={classes.rowItem}
            style={{ flex: 2, position: "relative" }}
          >
            {editMode ? (
              <>
                <Select
                  type="text"
                  name="company"
                  value={company}
                  required
                  onChange={this.handleSelectChange}
                  placeholder="Company"
                  options={companies.map(comp => ({
                    label: comp.name,
                    value: comp.name,
                    id: comp._id
                  }))}
                  className={classes.select}
                />
                <input
                  tabIndex={-1}
                  style={{
                    height: 0,
                    width: 0,
                    padding: 0,
                    margin: 0,
                    opacity: 0,
                    position: "absolute",
                    top: 30,
                    left: "50px"
                  }}
                  value={company}
                  required
                  onChange={this.handleSelectChange}
                  name="hiddenInput"
                />
              </>
            ) : (
              <span className={classes.text}>{user.company.name}</span>
            )}
          </div>
          <div className={classes.rowItem} style={{ flex: 2 }}>
            {editMode ? (
              <ReactInput
                className={classes.reactInput}
                placeholder="Title"
                value={title}
                name="title"
                onChange={this.handleChanges}
                required
              />
            ) : (
              <span className={classes.text}>{user.title}</span>
            )}
          </div>
          {/* <div className={classes.rowItem} style={{ flex: 2 }}>
            {editMode ? (
              <Select
                options={[]}
                name={"category"}
                onChange={this.handleSelectChange}
                className={classes.categorySelect}
                placeholder="Category"
                pageSize={3}
                value={category}
                isMulti
              />
            ) : (
              <span className={classes.text}>{user.category}</span>
            )}
          </div> */}
          <div
            className={classes.rowActions}
            style={editMode ? { flex: 2 } : { flex: 1 }}
          >
            {editMode ? (
              <>
                <SaveButton type="submit" />
                <CancelButton onClick={this.handleCancelButton} />
              </>
            ) : (
              <>
                {user.projectList[0] && (
                  <>
                    {showProjects ? (
                      <FilledArrow
                        tooltip="Hide Projects"
                        onClick={this.handleOpenProjects}
                      />
                    ) : (
                      <BagIcon
                        tooltip="Projects"
                        onClick={this.handleOpenProjects}
                      />
                    )}
                  </>
                )}
                <EditIcon onClick={this.handleEditMode} />
                <DeleteIcon onClick={() => handleDeleteModal(user)} />
              </>
            )}
          </div>
        </form>
        {showProjects && (
          <div className={classes.projectList}>
            <span className={classes.projectsText}>Projects:</span>
            {user.projectList.map((project, index) => (
              <>
                <span className={classes.projectDetail}>
                  <Link to={`/admin-dashboard/projects/${project.projectId}`}>
                    {" "}
                    {`${project.role.split("-")[1]} at ${
                      project.projectName
                    }`}{" "}
                  </Link>
                </span>
                {index !== user.projectList.length - 1 && <span>|</span>}
              </>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const Styles = {
  row: {
    display: "flex",
    backgroundColor: "white",
    color: Colors.grey,
    padding: "10px 10px",
    marginTop: "7px",
    justifyContent: "space-between"
  },
  rowItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "0 3px",
    wordBreak: "break-word",
    fontSize: "14px"
  },
  rowActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "0 3px"
  },
  reactInput: {
    width: "100%",
    fontSize: "14px",
    color: Colors.grey
  },
  reactInputNames: {
    width: "50%",
    margin: "0 2px",
    color: Colors.grey
  },
  text: {
    // padding: '4px 0'
  },
  projectList: {
    backgroundColor: "white",
    color: Colors.grey,
    padding: "0 10px 10px 10px",
    fontSize: "14px"
  },
  project: {
    margin: "7px"
  },
  projectsText: {
    color: Colors.darkBlue
  },
  select: {
    width: "100%"
  },
  projectDetail: {
    color: "#00000069",
    fontSize: "12px",
    padding: "2px 6px",
    fontWeight: 500,
    backgroundColor: "#00000005",
    borderRadius: 4,
    margin: "7px",
    "& > a": {
      textDecoration: "none",
      color: "#4D8ED4"
    },
    "&:hover": {
      backgroundColor: "#00000020"
    }
  },
  categorySelect: {
    width: "100%"
  }
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(
  withStyles(Styles)(UsersModalTableItem)
);
