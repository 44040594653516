// React //
import React, { useEffect } from "react";

// Redux //

// Material Ui //

// Components //
import { ProjectDocumentTabWrapper } from "../../ProjectDocumentTab";
// Reusable Functions //

// Styles //

export default () => {
  useEffect(() => {}, []);

  return <ProjectDocumentTabWrapper />;
};
