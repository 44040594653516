import { reportConstants } from "../constants";

export function reports(state = [], action) {
    switch (action.type) {
        case reportConstants.GET_REPORTS_SUCCESS:
            switch (action.reportType) {
                case 'project':
                    return { type: 'project', data: action.payload };

                case 'schedule':
                    return { type: 'schedule', data: action.payload.tasks };

                case 'cost':
                    return { type: 'cost', data: { costs: action.payload.costs, totals: action.payload.totals } };

                case 'issueTask':
                    return { type: 'issueTask', data: action.payload };

                case 'user':
                    return { type: 'user', data: action.payload };

                case 'program':
                    return { type: 'program', data: action.payload };

                default:
                    return action.payload;
            }
        case reportConstants.GET_REPORTS_FAILURE:
            return state;

        default:
            return state;
    }
}
