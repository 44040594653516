// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { clientActions } from "../../../actions";
import { API } from "../../../constants";

// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import Copyright from "@material-ui/icons/Copyright";

// Components //
import DeleteModal from "../../Modal/DeleteModal";
import ClientEditSlider from "./ClientEditSlider";
import UsersModal from "./UsersModal";
import { EditIcon, DeleteIcon } from "../../Icons";

// Reusable Functions //

// Styles //
import { Colors } from "../../../styles/colors";
class ClientList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.clientDetails = React.createRef();
    this.state = {
      selectedCompanyIndex: 0,
      selectedOptionForDelete: {},
      openDeleteModal: false,
      openSlider: false,
      openUsersModal: false,
      file: null
    };
  }

  componentDidMount() {
    if (this.props.companies && this.props.companies[0])
      this.setState({ selectedCompany: this.props.companies[0] });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.companies.length > 0 &&
      this.props.companies.length > 0 &&
      prevProps.companies.length === this.props.companies.length
    ) {
    } else if (this.props.companies && this.props.companies[0])
      this.setState({ selectedCompany: this.props.companies[0] });
  }

  handleSetCompany = selectedCompanyIndex => {
    this.setState({ selectedCompanyIndex });
  };

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
          openDeleteModal: true,
          selectedOptionForDelete
        })
      : this.setState({
          openDeleteModal: false,
          selectedCompanyIndex: 0
        });
  };

  // ###########################################################################
  //
  // ###########################################################################

  Transition = props => {
    return <Slide direction="up" {...props} />;
  };

  handleSlider = () => {
    this.setState(prevState => ({
      openSlider: !prevState.openSlider
    }));
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // ###########################################################################
  // Modal: Edit client
  // ###########################################################################

  handleEditClient = selectedClient => {
    const { dispatch } = this.props;
    dispatch(clientActions.editCompany(selectedClient));
  };

  handleUsersModal = bool => {
    this.setState({
      openUsersModal: bool
    });
  };

  handleFileChoosen = ({ target }) => {
    const { companies, dispatch } = this.props;
    const { selectedCompanyIndex } = this.state;
    target.files[0]
      ? this.setState({
          file: target.files[0]
        })
      : this.setState({
          file: ""
        });
    let document = new FormData();
    document.append("companyId", companies[selectedCompanyIndex]._id);
    document.append("image", target.files[0]);
    dispatch(clientActions.updateCompanyImage(document));
  };

  handleDeleteImage = () => {
    const { companies, dispatch } = this.props;
    const { selectedCompanyIndex } = this.state;
    dispatch(
      clientActions.deleteCompanyImage({
        companyId: companies[selectedCompanyIndex]._id
      })
    );
  };

  render() {
    const {
      classes,
      companies: tempCompanies = [],
      searchKeyWord
    } = this.props;
    const { selectedCompanyIndex, openSlider, openUsersModal } = this.state;
    const companies = tempCompanies.filter(company =>
      company.name.toLowerCase().includes(searchKeyWord.toLowerCase())
    );

    const selectedCompany = companies[selectedCompanyIndex];
    return (
      <>
        {companies[0] ? (
          <>
            <Grid container>
              {/* ############################################################ */}
              <Grid item xs={5}>
                <div className={classes.leftHeader}>Clients</div>
                <div className={classes.clientList}>
                  {companies.map((item, index) => (
                    <Grid className={classes.LeftItems} key={Math.random()}>
                      <Tooltip title={item.name} placement="top">
                        <div
                          className={
                            selectedCompany._id === item._id
                              ? classes.selectedCompany
                              : classes.companies
                          }
                          key={item._id}
                          onClick={() => this.handleSetCompany(index)}
                        >
                          <span className={classes.companyName}>
                            <div className={classes.logoHolder}>
                              {item.image ? (
                                <img
                                  src={`${API}/${item.image}`}
                                  alt="Logo"
                                  className={classes.logoImage}
                                />
                              ) : (
                                <Copyright className={classes.unknownLogo} />
                              )}
                            </div>
                            {item.name}
                          </span>
                        </div>
                      </Tooltip>
                    </Grid>
                  ))}
                </div>
              </Grid>

              {/* ############################################################ */}
              <Grid item className={classes.infoContainer} xs={7}>
                <Grid
                  container
                  className={classes.clientInfoContainer}
                  spacing={8}
                >
                  <Grid item xs={12}>
                    <Grid container spacing={16}>
                      <Grid item xs={2}>
                        <div className={classes.bigLogoHolder}>
                          <div className={classes.bigLogoContainer}>
                            {selectedCompany.image ? (
                              <img
                                src={`${API}/${selectedCompany.image}`}
                                alt="Logo"
                                className={classes.logoImage}
                              />
                            ) : (
                              <Copyright className={classes.unknownBigLogo} />
                            )}
                          </div>
                          <div className={classes.editIcon}>
                            <div
                              style={{
                                position: "relative"
                              }}
                            >
                              <input
                                type="file"
                                onChange={this.handleFileChoosen}
                                accept="image/*"
                                style={{
                                  position: "absolute",
                                  width: "32px",
                                  height: "32px",
                                  top: 0,
                                  left: 0,
                                  opacity: 0,
                                  zIndex: 10
                                }}
                              />
                              <EditIcon
                                style={{
                                  color: "white",
                                  fontSize: "12px!important"
                                }}
                              />
                            </div>
                          </div>
                          {selectedCompany.image && (
                            <div className={classes.deleteIcon}>
                              <div
                                style={{ position: "relative" }}
                                onClick={this.handleDeleteImage}
                              >
                                <DeleteIcon
                                  style={{
                                    color: "white",
                                    cursor: "default"
                                  }}
                                  title={""}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs>
                        <Grid container spacing={8}>
                          <Grid className={classes.infoGrid} item xs={6}>
                            <div className={classes.infoTitle}> Name</div>
                            <Tooltip
                              title={selectedCompany.name}
                              placement="top"
                            >
                              <div className={classes.textFiledData}>
                                {" "}
                                {selectedCompany.name}{" "}
                              </div>
                            </Tooltip>
                          </Grid>

                          <Grid className={classes.infoGrid} item xs={6}>
                            <div className={classes.infoTitle}>
                              Point Of Contact
                            </div>
                            <Tooltip
                              title={selectedCompany.pointOfContact}
                              placement="top"
                            >
                              <div className={classes.textFiledData}>
                                {" "}
                                {selectedCompany.pointOfContact}{" "}
                              </div>
                            </Tooltip>
                          </Grid>

                          <Grid className={classes.infoGrid} item xs={6}>
                            <div className={classes.infoTitle}>
                              {" "}
                              Primary Contact
                            </div>
                            <Tooltip
                              title={selectedCompany.primaryContact}
                              placement="top"
                            >
                              <div className={classes.textFiledData}>
                                {" "}
                                {selectedCompany.primaryContact}{" "}
                              </div>
                            </Tooltip>
                          </Grid>

                          <Grid className={classes.infoGrid} item xs={6}>
                            <div className={classes.infoTitle}>
                              Email Address
                            </div>
                            <Tooltip
                              title={selectedCompany.email}
                              placement="top"
                            >
                              <div className={classes.textFiledData}>
                                {" "}
                                {selectedCompany.email}{" "}
                              </div>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className={classes.infoGrid} item xs={12}>
                    <div className={classes.infoTitle}>Programs</div>
                    <div
                      className={classes.textFiledCategoriesData}
                      style={{ padding: 0 }}
                    >
                      <Grid container>
                        {selectedCompany.category &&
                        selectedCompany.category[0] ? (
                          selectedCompany.category.map((cat, index) => (
                            <Grid
                              item
                              xs
                              className={classes.categories}
                              style={
                                selectedCompany.category.length - 1 === index
                                  ? { marginRight: "0!important" }
                                  : {}
                              }
                              key={index}
                            >
                              {cat}
                            </Grid>
                          ))
                        ) : (
                          <Grid
                            item
                            xs
                            className={classes.categories}
                            style={{ height: "38px" }}
                          ></Grid>
                        )}
                      </Grid>
                    </div>
                  </Grid>

                  <Grid className={classes.infoGrid} item xs={6}>
                    <div className={classes.infoTitle}> Phone </div>
                    <div className={classes.textFiledData}>
                      {" "}
                      {selectedCompany.phone}{" "}
                    </div>
                  </Grid>

                  <Grid className={classes.infoGrid} item xs={6}>
                    <div className={classes.infoTitle}>Fax Or Mobile</div>
                    <div className={classes.textFiledData}>
                      {" "}
                      {selectedCompany.faxOrMobile}{" "}
                    </div>
                  </Grid>

                  <Grid className={classes.infoGrid} item xs={12}>
                    <div className={classes.infoTitle}>Address</div>
                    <div className={classes.textFiledDataWithHeight}>
                      {" "}
                      {selectedCompany.address}{" "}
                    </div>
                  </Grid>

                  <Grid
                    container
                    spacing={8}
                    className={classes.buttonContainer}
                  >
                    <Grid item xs={3}>
                      <Button
                        type="submit"
                        className={classes.userButton}
                        onClick={() => this.handleUsersModal(true)}
                      >
                        {"USERS"}
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        type="submit"
                        className={classes.editButton}
                        onClick={this.handleSlider}
                      >
                        {"EDIT CLIENT"}
                      </Button>
                    </Grid>

                    <Grid item xs={3}>
                      <Button
                        type="submit"
                        className={classes.deleteButton}
                        onClick={this.handleDeleteModal}
                      >
                        {"DELETE CLIENT"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* ############################################################## */}
            <UsersModal
              handleUsersModal={this.handleUsersModal}
              open={openUsersModal}
              clientId={selectedCompany._id}
            />

            {/* no company needd */}
            <DeleteModal
              open={this.state.openDeleteModal}
              handleDeleteModal={this.handleDeleteModal}
              selectedOptionForDelete={{ companyId: selectedCompany._id }}
              adminAction={clientActions.deleteCompany}
              userAction={clientActions.deleteCompany}
              //optional
              modalTitle={"Delete Company"}
            />
            {/* ############################################################ */}

            <div className={classes.addButtonOpenParent}>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={openSlider}
                classes={{
                  paper: classes.drawerPaper,
                  root: classes.drawerTransition
                }}
              >
                <div className={classes.drawerHeader}>Edit Client</div>
                <Divider />
                <List style={{ overflowY: "auto" }}>
                  <ClientEditSlider
                    handleCloseSlider={this.handleCloseSlider}
                    handleSlider={this.handleSlider}
                    handleEditClient={this.handleEditClient}
                    company={selectedCompany}
                    ref={this.clientDetails}
                  />
                </List>
              </Drawer>
            </div>
          </>
        ) : (
          <section className={classes.noData}> NO CLIENT </section>
        )}
      </>
    );
  }
}

const Styles = {
  clientList: {
    marginLeft: "25px",
    padding: "0 40px 0 13px",
    height: "423px",
    overflow: "overlay",
    direction: "rtl"
  },
  companies: {
    position: "relative",
    transition: "background-color 200ms",
    margin: "0px 0 10px 0",
    padding: "2px  13px 2px 4px",
    borderRadius: "4px",
    backgroundColor: "hsl(0, 0%, 100%)",
    color: "#000000b3",
    direction: "ltr",
    height: "40px",
    display: "flex",
    "&:hover": {
      backgroundColor: "#dadada",
      cursor: "pointer"
    }
  },
  selectedCompany: {
    position: "relative",
    margin: "0px 0 10px 0",
    padding: "2px  13px 2px 4px",
    borderRadius: "4px 0px 0px 4px",
    backgroundColor: "rgb(87, 178, 112)",
    borderColor: "hsl(0, 0%, 80%)",
    borderWidth: "1px",
    color: "white",
    direction: "ltr",
    height: "40px",
    display: "flex",
    "&::after": {
      transition: "background-color 200ms",
      position: "absolute",
      top: "0px",
      content: "''",
      right: "-40px",
      borderLeft: "20px solid rgb(87, 178, 112)",
      borderTop: "22px solid #00000000",
      borderBottom: "22px solid #00000000",
      borderRight: "20px solid #00000000"
    }
  },
  LeftItems: {
    margin: 0
  },
  textFiledData: {
    border: "1px solid #00000017",
    padding: "8px 10px",
    margin: "2px 0",
    borderRadius: "4px",
    backgroundColor: "hsl(0, 0%, 100%)",
    height: "20px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  textFiledCategoriesData: {
    padding: "10px 0 ",
    margin: "2px 0",
    borderRadius: "4px",
    backgroundColor: "transparent"
  },
  marginBottom: {
    marginBottom: "10px"
  },
  leftHeader: {
    color: "rgb(48,106,177)",
    margin: "0 20px 10px 35px",
    fontSize: "25px",
    fontWeight: "500"
  },
  deleteButton: {
    backgroundColor: "#f44336",
    width: "100%",
    fontSize: "14px",
    color: "#fff",
    borderRadius: "5px",
    padding: "10px",
    "&:hover": {
      backgroundColor: "#c62828"
    }
  },
  editButton: {
    backgroundColor: Colors.darkBlue,
    width: "100%",
    fontSize: "14px",
    color: "#fff",
    borderRadius: "5px",
    padding: "10px",
    "&:hover": {
      backgroundColor: "#e4a733"
    }
  },
  userButton: {
    backgroundColor: "#637684",
    width: "100%",
    fontSize: "14px",
    color: "#fff",
    borderRadius: "5px",
    padding: "10px",
    "&:hover": {
      backgroundColor: "#718696"
    }
  },
  borderTop: {
    borderTop: "1px solid #e1e1e1"
  },
  infoTitle: {
    fontSize: "14px",
    color: Colors.darkBlue,
    paddingLeft: "4px"
  },
  infoContainer: {
    padding: "10px 37px 20px 40px"
  },

  textFiledDataWithHeight: {
    border: "1px solid #00000017",
    minHeight: "50px",
    padding: "8px 10px",
    margin: "5px auto 0 auto",
    borderRadius: "4px",
    backgroundColor: "hsl(0, 0%, 100%)",
    wordWrap: "break-word"
  },
  infoGrid: {
    margin: "3px 0"
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "35px",
    color: "#00000036",
    fontWeight: "100!important",
    padding: "10px 50px 3px 0",
    borderRadius: "5px",
    marginTop: "0",
    marginBottom: "25px",
    height: "calc(100vh - 300px)"
  },
  buttonContainer: {
    justifyContent: "flex-end",
    padding: "0 4px",
    marginTop: "10px"
  },
  companyName: {
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center"
  },
  categories: {
    border: "1px solid #00000017",
    padding: "8px 10px",
    marginRight: "2px",
    marginBottom: "2px",
    borderRadius: "4px",
    backgroundColor: "hsl(0, 0%, 100%)"
  },
  drawerHeader: {
    color: "white",
    fontSize: "17px",
    padding: "10px",
    fontWeight: 400,
    backgroundColor: "#155794",
    textAlign: "center"
  },
  chooseFile: {
    backgroundColor: Colors.green,
    display: "flex!important",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    // fontSize: '10px',
    borderRadius: "5px",
    // fontWeight: '900',
    // flex: '1',
    // width: '100%',
    // height: '38px',
    boxSizing: "border-box",
    border: 0,
    cursor: "pointer",
    position: "relative",
    color: "white!important",
    "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "13px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: Colors.darkGreen
    },
    "& input": {
      opacity: 0,
      width: 0,
      height: 0,
      position: "absolute",
      top: 0,
      left: 0,
      cursor: "pointer"
    },
    "& > span": {
      width: "80%",
      height: "100%",
      zIndex: 100,
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    }
  },
  addAttachment: {
    width: "190px",
    height: "42px",
    fontSize: "14px",
    fontWeight: "500",
    color: "white",
    borderRadius: "5px",
    padding: "5px",
    cursor: "pointer!important",
    transition: "300ms",
    border: "none",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    margin: "2px 8px"
  },
  logoWrapper: {
    display: "flex",
    direction: "row"
  },
  fileName: {
    flex: 3,
    border: "1px solid #00000017",
    padding: "8px 10px",
    margin: "2px 0",
    borderRadius: "4px",
    backgroundColor: "hsl(0, 0%, 100%)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  deleteLogo: {
    width: "190px",
    height: "42px",
    fontSize: "14px",
    fontWeight: "500",
    color: "white",
    borderRadius: "5px",
    padding: "5px",
    cursor: "pointer!important",
    border: "none",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    margin: "2px 0 ",
    backgroundColor: "#f44336",
    "&:hover": {
      backgroundColor: "#c62828"
    },
    "&:disabled": {
      backgroundColor: "#6E8393"
    }
  },
  logoHolder: {
    width: "50px",
    height: "40px",
    borderRadius: "4px",
    overflow: "hidden",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logoImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
    height: "100%",
    width: "100%"
  },
  bigLogoHolder: {
    width: "100%",
    height: "131px",
    borderRadius: "4px",
    overflow: "hidden",
    border: "3px solid white",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  bigLogoContainer: {
    maxWidth: "96%",
    maxHeight: "96%",
    width: "96%",
    height: "96%",
    borderRadius: "4px",
    overflow: "hidden",
    border: "3px solid #EFF0F5",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  editIcon: {
    position: "absolute",
    bottom: 6,
    right: 6,
    backgroundColor: "#26578F",
    borderRadius: "100%",
    opacity: 0.3,
    "&:hover": {
      opacity: 1
    }
  },
  deleteIcon: {
    position: "absolute",
    bottom: 6,
    left: 6,
    backgroundColor: "#26578F",
    borderRadius: "100%",
    opacity: 0.3,
    "&:hover": {
      opacity: 1
    }
  },
  unknownLogo: {
    fontSize: "40px"
  },
  unknownBigLogo: {
    fontSize: "120px",
    color: Colors.darkenThanLightGrey
  }
};

const mapStateToProps = state => {
  return {
    companies: state.companies ? state.companies : []
  };
};

export default connect(mapStateToProps)(withStyles(Styles)(ClientList));
