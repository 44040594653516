import React, { PureComponent } from 'react';
import { convertToObject, isEqual, recoverExpandVariables } from "./helpers";
import { findMinAndMaxDate, addDays, subtractDays } from "./helpers";
import { detectScroll, getTimeGap, gradients, calculateProgress } from "./helpers";
import { calculateDaylineGradient, calculateHeightOfTasks } from "./helpers";
import { handleSavePNG, handleSaveJPG, handleSavePDF } from "./helpers";
import { Lines } from "./lines";

import { TimeLine } from './time-line';
import { Tasks, TaskForm } from "./tasks";
import { FileOptions } from "./file-options";

// #####[ MATERIAL UI ]#########################################################
import { withStyles } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"

// #####[ MATERIAL ICONS ]######################################################
import MyLocationIcon from "@material-ui/icons/MyLocation";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";

// #############################################################################
// component Gantt Chart
// #############################################################################

class GanttChart extends PureComponent {
  constructor(props) {
    super(props);
    const maxEdge = addDays(new Date(new Date().setHours(0, 0, 0, 0)), 60);
    const minEdge = subtractDays(new Date(new Date().setHours(0, 0, 0, 0)), 25);
    this.state = {
      tasks: [],
      maxDate: maxEdge,
      minDate: minEdge,
      maxEdge,
      minEdge,
      atLeft: false,
      atRight: false,
      convertedToObject: {},
      draggable: false,
      showTaskForm: false,
      showFileOption: false,
      projectProgress: 0,
      noData: true,
      lastExpand: new Date().getTime()
    };

    this.taskLineRef = React.createRef();
    this.forwardIcon = React.createRef();
    this.rewindIcon = React.createRef();
  };

  // ###########################################################################
  // add data to component states
  // ###########################################################################

  static getDerivedStateFromProps = (nextProps, prevState) => {

    const { tasks = [] } = nextProps;
    const noData = tasks.length === 0;
    let nextState = { noData };
    const [minDate, maxDate] = findMinAndMaxDate(tasks, ["plannedStartDate", "plannedFinishDate", "actualStartDate", "actualFinishDate"]);

    nextState = { ...nextState, minDate, maxDate }


    if (!isEqual(tasks, prevState.tasks)) {
      let convertedToObject = convertToObject(tasks, "_id");
      const projectProgress = calculateProgress(tasks)
      convertedToObject = recoverExpandVariables(prevState.convertedToObject, convertedToObject);
      nextState = { ...nextState, convertedToObject, tasks, projectProgress }
    }

    if (minDate.getTime() < prevState.minEdge.getTime()) {
      const minEdge = subtractDays(new Date(minDate.setHours(0, 0, 0, 0)), 15);
      nextState = { ...nextState, minEdge }
    }

    if (maxDate.getTime() > prevState.maxEdge.getTime()) {
      const maxEdge = addDays(new Date(maxDate.setHours(0, 0, 0, 0)), 15);
      nextState = { ...nextState, maxEdge }
    }

    return nextState
  }

  // ###########################################################################
  // toggle drag sort
  // ###########################################################################

  handledraggable = () => {
    this.setState({ draggable: !this.state.draggable })
  }

  // ###########################################################################
  // this function will 
  // ###########################################################################

  toggleTaskForm = () => {
    this.setState({ showTaskForm: !this.state.showTaskForm })
  }

  // ###########################################################################
  // this function will 
  // ###########################################################################

  toggleSaveFile = (dropDownPostion) => {
    this.setState({ showFileOption: !this.state.showFileOption, dropDownPostion })
  }

  // ###########################################################################
  // this function will 
  // ###########################################################################

  goToToday = (unit = 25) => {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const diffrence = getTimeGap(this.state.minEdge, today)
    this.taskLineRef.current.scrollLeft = (diffrence * unit) - (document.body.clientWidth / 2);
  }

  // ###########################################################################
  // handle delete task
  // ###########################################################################

  handleDelete = (data) => {
    const { project = {} } = this.props;
    const projectId = project._id;
    if (this.props.handleDelete) this.props.handleDelete({ ...data, projectId });
  }

  // ###########################################################################
  // handle add task
  // ###########################################################################

  handleAddTask = (data) => {
    const { project = {} } = this.props;
    const projectId = project._id;
    this.props.handleAddTask({ ...data, ...this.addHours(data, 8), projectId })
  }

  // ###########################################################################
  // handle edit task
  // ###########################################################################

  handleEditTask = (data) => {
    const { project = {} } = this.props;
    const projectId = project._id;
    this.props.handleEditTask({ ...data, ...this.addHours(data, 8), projectId });
  }
  
  // ###########################################################################
  // handle edit task
  // ###########################################################################

  handleListOfChanges = (list) => {
    const { project = {} } = this.props;
    const projectId = project._id;

    // this set state will update the state
    this.setState({convertedToObject: {...this.state.convertedToObject, ...list}})

    // this function will call an action to save the changes to database
    this.props.handleEditTasks({projectId, tasks: this.createAnArrayOfTask(list)})
  }

  // ###########################################################################
  // this function will change the object to an array
  // ###########################################################################

  createAnArrayOfTask = (object) => {
    const keys = Object.keys(object);
    
    return keys.map(taskId => {
      const { actualStartDate, actualFinishDate } = this.addHours(object[taskId], 8)
      return {
        _id: taskId,
        actualStartDate,
        actualFinishDate,
      }
    })
  }

  // ###########################################################################
  // this function will add hours all the dates
  // ###########################################################################

  addHours = (data = {}, hours = 0) => {
    return {
      actualStartDate: data.actualStartDate ? this.setHour(data.actualStartDate, hours) : undefined,
      actualFinishDate: data.actualFinishDate ? this.setHour(data.actualFinishDate, hours) : undefined,
      plannedStartDate: data.plannedStartDate ? this.setHour(data.plannedStartDate, hours) : undefined,
      plannedFinishDate: data.plannedFinishDate ? this.setHour(data.plannedFinishDate, hours) : undefined,
    };
  }

  // ###########################################################################
  // this function will add hours the selected date
  // ###########################################################################

  setHour = (date = new Date(), hours = 0) => {
    return new Date(new Date(date).setHours(hours,0,0,0))
  }

  // ###########################################################################
  // render
  // ###########################################################################

  changeTaskOrder = data => {
    const { project = {} } = this.props;
    const projectId = project._id;
    if (this.props.changeTaskOrder) this.props.changeTaskOrder({ ...data, projectId });
  }

  // ###########################################################################
  // calculate day line
  // ###########################################################################

  calcDayLine = (width, unit = 25) => {
    const { minEdge } = this.state;
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const offsetLeft = getTimeGap(minEdge, today) * unit;
    return calculateDaylineGradient(offsetLeft, width, unit);
  }

  // ###########################################################################
  // this function will toggle the expand flag in the tasks
  // ###########################################################################

  handleExpand = (taskId, value) => {
    let convertedToObject = {};
    const newTask = { ...this.state.convertedToObject[taskId], expand: value }

    if (!value && newTask.subTask.length > 0)
      convertedToObject = this.closeAllSubTasks(newTask, this.state.convertedToObject);


    convertedToObject = { ...this.state.convertedToObject, [taskId]: newTask }
    this.setState({ convertedToObject });
  }

  // ###########################################################################
  // this function will close all the subtasks when the parent is about to close
  // ###########################################################################

  closeAllSubTasks = (task, tasks) => {
    task.subTask.forEach(key => {
      tasks[key].expand = false;
      if (tasks[key].subTask.length > 0)
        this.closeAllSubTasks(tasks[key], tasks);
    })
    return tasks
  }

  // ###########################################################################
  // Save Snapshots
  // ###########################################################################

  handleSaveFile = (taskSize, fileFormat) => () => {
    switch (fileFormat) {
      case "PNG":
        handleSavePNG(taskSize, this.props.project.name);
        return
      case "JPG":
        handleSaveJPG(taskSize, this.props.project.name);
        return
      case "PDF":
        handleSavePDF(taskSize, this.props.project.name)
        return
      default:
        return
    }
  }

  // ###########################################################################
  // this function will change the expandForm variable in the tasks objects
  // ###########################################################################

  handleExpandForm = (taskId, isExpandForm, additionalHeight) => {
    const newTask = { ...this.state.convertedToObject[taskId], isExpandForm, additionalHeight }
    this.setState({ convertedToObject: { ...this.state.convertedToObject, [taskId]: newTask } });
  }

  // ###########################################################################
  // this function detects the scroll of the main board
  // ###########################################################################

  scrolled = event => {
    if (event.target.id !== this.taskLineRef.current.id) return;

    const { scrollRight, scrollLeft } = detectScroll(event.target);
    const forwardButton = document.getElementById("FORWARD_BUTTON");
    const rewindButton = document.getElementById("REWINED_BUTTON");

    if (scrollRight < 100) {
      const opacity = ((scrollRight % 100) / -100) + 1;
      forwardButton.style.opacity = opacity;
      forwardButton.style.display = "block";

    }
    else if (scrollLeft < 100) {
      const opacity = ((scrollLeft % 100) / -100) + 1;
      rewindButton.style.opacity = opacity;
      rewindButton.style.display = "block"

    } else {
      rewindButton.style.display = "none"
      forwardButton.style.display = "none"
    }
  }

  // ###########################################################################
  // this function will expand the time line from left
  // ###########################################################################

  expandMinEdge = () => {
    const minEdge = subtractDays(this.state.minEdge, 30);
    this.taskLineRef.current.scrollLeft = 30 * 25;
    this.setState({ minEdge });
  }

  // ###########################################################################
  // this function will expand from the right
  // ###########################################################################

  expandMaxEdge = () => {
    const maxEdge = addDays(this.state.maxEdge, 30);
    document.getElementById("FORWARD_BUTTON").style.display = "none"
    this.setState({ maxEdge });
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render() {

    const { classes, users = [], tasks = [], project = {} } = this.props;
    const { convertedToObject = {}, dropDownPostion = "bottom" } = this.state;
    const { maxEdge, minEdge, draggable, showTaskForm, showFileOption } = this.state;
    const width = getTimeGap(minEdge, maxEdge) * 25;
    const [tasksWithPosition, height] = calculateHeightOfTasks(minEdge, convertedToObject);

    return (
      <div className={classes.root}>

        {/* ################################################################## */}

        <IconButton onClick={this.expandMinEdge} id="REWINED_BUTTON" className={`${classes.rewindButton} ${classes.expandButtons}`} ref={this.rewindIcon}>
          <FastRewindIcon className={classes.forwardIcon} />
        </IconButton>

        <IconButton onClick={this.expandMaxEdge} id="FORWARD_BUTTON" className={`${classes.forwardButton} ${classes.expandButtons}`} ref={this.forwardIcon}>
          <FastForwardIcon className={classes.forwardIcon} />
        </IconButton>

        {/* ################################################################## */}

        <div className={classes.buttonHolderTop}>
          <IconButton onClick={this.handledraggable} className={classes.dragButton}>
            <DragIndicatorIcon classes={{ root: classes.icon }} />
          </IconButton>

          <IconButton onClick={this.toggleTaskForm} className={classes.addTaskButton}>
            <AddCircleOutlineIcon classes={{ root: classes.icon }} />
          </IconButton>

          <IconButton onClick={() => { this.toggleSaveFile("top") }} className={classes.photoButton}>
            <PhotoCameraOutlinedIcon classes={{ root: classes.icon }} />
          </IconButton>
        </div>

        {/* ################################################################## */}

        <div className={classes.buttonHolder}>

          <IconButton onClick={this.handledraggable} className={classes.dragButton}>
            <DragIndicatorIcon classes={{ root: classes.icon }} />
          </IconButton>

          <IconButton onClick={this.toggleTaskForm} className={classes.addTaskButton}>
            <AddCircleOutlineIcon classes={{ root: classes.icon }} />
          </IconButton>

          <IconButton onClick={() => { this.toggleSaveFile("bottom") }} className={classes.photoButton}>
            <PhotoCameraOutlinedIcon classes={{ root: classes.icon }} />
          </IconButton>

          <IconButton onClick={() => { this.goToToday(25) }} className={classes.todayButton}>
            <MyLocationIcon classes={{ root: classes.icon }} />
          </IconButton>

        </div>

        {/* ################################################################## */}

        <FileOptions
          handleToggle={this.toggleSaveFile}
          open={showFileOption}
          width={width}
          position={dropDownPostion}
          handleSaveFile={this.handleSaveFile}
        />

        {/* ################################################################## */}

        {!showTaskForm ? null :
          <TaskForm
            users={users}
            tasks={tasks}
            showTaskForm={showTaskForm}
            toggleTaskForm={this.toggleTaskForm}
            handleAddTask={this.handleAddTask}
            parentTask
            project={project}
          />
        }

        {/* ################################################################## */}

        <div
          id="mp-ganttchart-scroll"
          ref={this.taskLineRef}
          className={classes.container}
          onScroll={this.scrolled}
          style={{ backgroundImage: `${gradients.lines}, ${this.calcDayLine(width)}` }}
        >

          {/* ################################################################ */}

          <TimeLine width={width} maxEdge={maxEdge} minEdge={minEdge} />

          {/* ################################################################ */}

          <Lines horizontalUnit={13} verticalUnit={28} tasks={tasksWithPosition} height={height} width={width} />

          {/* ################################################################ */}
          {Object.keys(convertedToObject).length <= 0 ? null :
            <Tasks
              rawData={tasks}
              tasks={convertedToObject}
              users={users}
              project={project}

              width={width}
              maxEdge={maxEdge}
              minEdge={minEdge}
              draggable={draggable}

              handleExpand={this.handleExpand}
              changeTaskOrder={this.changeTaskOrder}
              handleDelete={this.handleDelete}
              handleEditTask={this.handleEditTask}
              handleAddTask={this.handleAddTask}
              handleExpandForm={this.handleExpandForm}
              handleListOfChanges={this.handleListOfChanges}
            />
          }
        </div>

        {/* ################################################################## */}
      </div>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root: {
    height: "100%",
    minHeight: "400px",
    border: "1px solid #00000020",
    background: "#f8fbfb",
    position: "relative",
    borderRadius: 5,
    overflow: "hidden",
    boxSizing: "border-box",
  },
  container: {
    minHeight: "calc(100%)",
    maxHeight: "calc(100%)",
    position: "relative",
    paddingBottom: 5,
    overflow: "auto",
    boxSizing: "border-box",
    backgroundAttachment: "local",
    backgroundColor: "#f8fbfb"
  },
  placholder: {
    width: 300,
    height: 400,
    zIndex: 1,
    position: "absolute",
    top: 1,
    left: 1,
    background: "#f8fbfb",
  },
  // ###########################################################################
  buttonHolder: {
    position: "absolute",
    right: 10,
    bottom: 12,
    zIndex: 12,
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonHolderTop: {
    position: "absolute",
    right: 10,
    top: 4,
    zIndex: 1500,
    display: "flex",
    justifyContent: "flex-end",
  },
  todayButton: {
    width: "fit-content",
    height: "fit-content",
    padding: 4,
    color: "#ff0066c7",
    margin: 2,
  },
  dragButton: {
    width: "fit-content",
    height: "fit-content",
    padding: 4,
    color: "#c700c7c7",
    margin: 2,
  },
  photoButton: {
    width: "fit-content",
    height: "fit-content",
    padding: 4,
    color: "#008ce4c7",
    margin: 2,
  },
  addTaskButton: {
    width: "fit-content",
    height: "fit-content",
    padding: 4,
    color: "#00d231c7",
    margin: 2,
  },
  icon: {
    fontSize: 18
  },

  // ###########################################################################
  topBar: {
    minHeight: 30,
    borderBottom: "1px solid #e1e1e1"
  },

  expandButtons: {
    transition: "padding 200ms",
    display: "none",
    position: "absolute",
    color: "#000000ad",
    top: "50%",
    zIndex: 1000,
    padding: 5,
    backgroundColor: "#e4a000",
    boxShadow: "rgba(0, 0, 0, 0.14) 0px 2px 5px 0px, rgba(0, 0, 0, 0.74) 0px 2px 2px -2px",
    "&:hover": {
      backgroundColor: "#e48000",
    }
  },
  rewindButton: {
    left: 10,
  },
  forwardButton: {
    right: 10,
  }

});

// #############################################################################
// connect styles to component
// #############################################################################

const GanttChartWithStyles = withStyles(styles)(GanttChart);

// #############################################################################
// export
// #############################################################################

export { GanttChartWithStyles as GanttChart };
