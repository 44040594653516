import React, { PureComponent } from 'react';
import { gradients, calculateWithAndPosition, addWorkDays } from "../helpers";
import { handleDrag, handlePercent, expand, listAllParnts, getTimeGap } from "../helpers";
import { getMaxDependers, getMinDependency, removeWeekends, moveDependencies } from "../helpers"

// #####[ MATERIAL UI ]#########################################################
import { withStyles } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"

// #####[ MATERIAL ICONS ]######################################################
import DragHandleIcon from "@material-ui/icons/DragHandle";

// #############################################################################
// component
// #############################################################################

class Progress extends PureComponent {
  constructor(props) {
    super(props);
    const [actualLeft, actualWidth] = calculateWithAndPosition(props.minEdge, props.task.actualStartDate, props.task.actualFinishDate, 25);
    this.state = {
      classes: [],
      task: {},
      actualLeft: actualLeft,
      actualWidth: actualWidth,
      plannedLeft: 0,
      plannedWidth: 0,
      complete: 0,
      hasTextRoom: actualWidth * (props.task.complete / 100) > 50,
      offset: 0,
      dragging: true,
      draggingPlanned: true,
      parentList: {},
      percentTextInside: true
    };
    this.progressRef = React.createRef();
    this.percentRef = React.createRef();
    this.percentNumberRef = React.createRef();
    this.plannedProgressRef = React.createRef();
  }

  hasSubTask = () => {
    return this.props.task.subTask && this.props.task.subTask.length > 0;
  }
  // ###########################################################################
  // find the min and max of dependencies
  // ###########################################################################

  getMinAndMaxDependencies = (IdPrefix = "PLANNED") => {
    const { tasks = {}, task = {}, minEdge } = this.props;
    const { maxActual, maxPlanned } = getMaxDependers(tasks, task, minEdge, IdPrefix);
    const { minActual, minPlanned } = getMinDependency(tasks, task, minEdge, IdPrefix);
    return ({ maxActual, maxPlanned, minActual, minPlanned });
  }

  // ###########################################################################
  // 
  // ###########################################################################

  handleDrag = event => {
    if (this.hasSubTask()) return;

    const { id } = event.target;

    if (id === this.progressRef.current.id || id === this.percentNumberRef.current.id) {
      const { task, tasks, minEdge } = this.props;
      const { maxActual, minActual } = this.getMinAndMaxDependencies();
      handleDrag(event, this.progressRef, task, tasks, maxActual, minActual, minEdge, this.handleEditTask);
    }
  }

  // ###########################################################################
  // 
  // ###########################################################################

  handleResize = option => event => {
    if (this.hasSubTask()) return;
    const IdPrefix = ""
    const { maxActual, minActual } = this.getMinAndMaxDependencies();
    const { task, tasks, minEdge } = this.props;
    expand(event, this.progressRef, task, tasks, maxActual, minActual, minEdge, option, this.handleEditTask, IdPrefix, 25);

  }

  // ###########################################################################
  // on change percent
  // ###########################################################################

  onPercentChange = event => {
    if (this.hasSubTask()) return;
    const { task, tasks } = this.props;
    const parentList = task.parentTask ? listAllParnts(task, tasks[task.parentTask], tasks) : null;
    handlePercent(event, this.percentRef, this.progressRef, this.percentNumberRef, parentList, this.handleEditTask, this.togglePercentText)
  }

  // ###########################################################################
  // handle edit
  // ###########################################################################

  togglePercentText = (hasTextRoom) => {
    if (hasTextRoom !== null && hasTextRoom !== this.state.hasTextRoom) this.setState({ hasTextRoom })
  }

  // ###########################################################################
  // handle edit progress
  // ###########################################################################

  handleEditTask = (data) => {
    const { task, tasks } = this.props;

    if(data.actualFinishDate && data.actualStartDate ){

      const [actualStartDate, actualFinishDate] = this.considerWorkDays(data)

      // this line will find all the tasks effected by this change in the 
      const effectedTasks = moveDependencies(task, tasks, {actualStartDate, actualFinishDate});

      if(Object.keys(effectedTasks).length > 0){
        this.props.handleListOfChanges({[task._id]: { ...task, actualStartDate, actualFinishDate }, ...effectedTasks})
      } else {
        this.props.handleEditTask({ id: task._id, actualStartDate, actualFinishDate, dependencies: task.dependencies })  
      }
    } else {
      this.props.handleEditTask({ id: task._id, ...data, dependencies: task.dependencies })  
    }
    
  }

  // ###########################################################################
  // this function will adjusts work days in the drag action
  // ###########################################################################

  considerWorkDays = (dates) => {
    const { actualStartDate, actualFinishDate } = this.props.task;

    const workDays = removeWeekends(actualStartDate, actualFinishDate)[1];
    const newWorkDays = removeWeekends(dates.actualStartDate, dates.actualFinishDate)[1];

    const startGap = getTimeGap(actualStartDate, dates.actualStartDate);
    const finishGap = getTimeGap(actualFinishDate, dates.actualFinishDate);
    
    if(!!startGap && !!finishGap && workDays !== newWorkDays){
      const finishDate = addWorkDays(dates.actualStartDate, workDays);
      return [dates.actualStartDate, finishDate]
    }

    return [dates.actualStartDate, dates.actualFinishDate]
  }

  // ###########################################################################
  // on drag the planned progress
  // ###########################################################################

  onPlannedDrag = event => {
    if (this.hasSubTask()) return;
    const IdPrefix = "PLANNED";
    const { maxPlanned, minPlanned } = this.getMinAndMaxDependencies();
    const { task, tasks, minEdge } = this.props;
    if (event.target.id === this.plannedProgressRef.current.id && maxPlanned)
      handleDrag(event, this.plannedProgressRef, task, tasks, maxPlanned, minPlanned, minEdge, this.handleEditTask, IdPrefix, 25)
  }

  // ###########################################################################
  // on resize the planned task start 
  // ###########################################################################

  onPlannedResize = option => event => {
    if (this.hasSubTask()) return;
    const IdPrefix = "PLANNED"
    const { maxPlanned, minPlanned } = this.getMinAndMaxDependencies();
    const { task, tasks, minEdge } = this.props;
    expand(event, this.plannedProgressRef, task, tasks, maxPlanned, minPlanned, minEdge, option, this.handleEditTask, IdPrefix, 25);
  }

  // ###########################################################################
  // render
  // ###########################################################################

  calculateDateDifference = (startDate, endDate) => {
    const [weekends, workDays] = removeWeekends(startDate, endDate);
    const totalDays = weekends + workDays;
    return `${totalDays} Days - ${workDays} BD - ${weekends} WE`
  }

  // ###########################################################################
  // render
  // ###########################################################################

  getPercentage = () => {
    const { task = {} } = this.props;
    return Math.floor(task.complete);
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const { classes, task = {}, minEdge } = this.props;
    const { hasTextRoom } = this.state;
    const [actualLeft, actualWidth] = calculateWithAndPosition(minEdge, task.actualStartDate, task.actualFinishDate, 25);
    const [plannedLeft, plannedWidth] = calculateWithAndPosition(minEdge, task.plannedStartDate, task.plannedFinishDate, 25);
    const hasSubTask = this.hasSubTask();

    return (
      <React.Fragment>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={`${task.name} ( ${this.calculateDateDifference(task.actualStartDate, task.actualFinishDate)} )`}
        >
          <main
            id={task._id}
            key={new Date(task.actualFinishDate).getTime()}
            onMouseDown={this.handleDrag}
            ref={this.progressRef}
            style={{ left: actualLeft, width: actualWidth }}
            className={`${classes.root} ${hasSubTask ? null : classes.mouseType}`}
          >
            {!hasTextRoom || hasSubTask ? 
              <div
                id={`P-N-${task._id}`} 
                key={task.complete + task.actualFinishDate} 
                ref={this.percentNumberRef}
                className={classes.percent}
              >
                {this.getPercentage()}%
              </div> 

              : null
            }

            <div
              id={`P-${task._id}`}
              ref={this.percentRef}
              style={{ width: task.complete + "%" }}
              className={`${classes.percentBar} ${hasSubTask ? classes.hasSubTask : null}`}
            >
              {hasTextRoom && !hasSubTask ? 
                <div
                  key={task.complete}
                  id={`P-N-${task._id}`}
                  ref={this.percentNumberRef}
                  className={classes.percent}
                >
                  {this.getPercentage()}%
                </div>

                 : null
               }

              {hasSubTask ? null :
                <div className={classes.percentHandler} onMouseDown={this.onPercentChange}>
                  <DragHandleIcon classes={{ root: classes.resizeIcon }} />
                </div>
              }
            </div>

            {hasSubTask ? null :
              <React.Fragment>
                <div
                  className={`${classes.handle} ${classes.leftHandle}`}
                  onMouseDown={this.handleResize("left")}
                >
                  <DragHandleIcon classes={{ root: classes.resizeIcon }} />
                </div>

                <div
                  className={`${classes.handle} ${classes.rightHandle}`}
                  onMouseDown={this.handleResize("right")}
                >
                  <DragHandleIcon classes={{ root: classes.resizeIcon }} />
                </div>

              </React.Fragment>
            }

          </main>
        </Tooltip>

        <Tooltip classes={{ tooltip: classes.tooltip }} title={`${task.name} ( ${this.calculateDateDifference(task.plannedStartDate, task.plannedFinishDate)} )`}>
          <main
            id={"PLANNED" + task._id}
            ref={this.plannedProgressRef}

            style={{ left: plannedLeft, width: plannedWidth }}
            className={`${classes.plannedProgress} ${hasSubTask ? null : classes.mouseType}`}
          >
            {true ? null :
              <React.Fragment>
                <div
                  className={`${classes.plannedHandle} ${classes.leftHandle}`}
                  onMouseDown={this.onPlannedResize("left")}
                >
                  <DragHandleIcon classes={{ root: classes.plannedResizeIcon }} />
                </div>

                <div
                  className={`${classes.plannedHandle} ${classes.rightHandle}`}
                  onMouseDown={this.onPlannedResize("right")}
                >
                  <DragHandleIcon classes={{ root: classes.plannedResizeIcon }} />
                </div>
              </React.Fragment>
            }
          </main>
        </Tooltip>
      </React.Fragment>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root: {
    bottom: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    height: 18,
    background: "#ccdbde",
    border: "1px solid #00000030",
    borderRadius: 2,
    boxSizing: "border-box",
    userSelect: "none",
    transition: "height 150ms, top 150ms, box-shadow 200ms, border 200ms",
    "&:hover > div": {
      opacity: 1,
    }
  },
  mouseType: {
    "&:hover": {
      cursor: "move"
    },
  },
  // ###########################################################################
  percent: {
    fontSize: 12,
    color: "#000000a3",
    position: "relative",
    fontWeight: 800,
    zIndex: 1,
    paddingRight: 18,
    textAlign: "center",
    width: "100%",
    overflow: "hidden"
  },
  percentBar: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    height: 16,
    minWidth: 18,
    background: "#00d88d",
    backgroundImage: gradients.stripes,
    transition: "height 150ms",
    "&:hover > div": {
      opacity: 1
    }
  },

  hasSubTask: {
    background: "#bbbbbb !important",
    backgroundImage: `${gradients.stripes} !important`,
  },

  // ###########################################################################

  plannedProgress: {
    bottom: 12,
    position: "absolute",
    height: 10,
    background: "orange",
    border: "1px solid #00000030",
    borderRadius: 2,
    boxSizing: "border-box",
    transition: "height 150ms, bottom 150ms, box-shadow 200ms, border 200ms",
    "&:hover":{
      cursor: "not-allowed"
    }
  },
  plannedHandle: {
    position: "absolute",
    top: -1,
    width: 16,
    height: 14,
    background: "orange",
    border: "1px solid #00000010",
    borderRadius: 2,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    opacity: 0,
    transition: "opacity 50ms",
    "&:hover": {
      background: "orange",
    },
    "&:hover > svg": {
      color: "#00000075",
      cursor: "col-resize"
    }
  },
  plannedResizeIcon: {
    position: "absolute",
    top: -3,
    transform: "rotate(90deg)",
    fontSize: 18,
    color: "#0000004a"
  },

  // ###########################################################################
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #00000010",
    fontSize: 11,
    color: "#000000a8",
    fontWeight: 600,
  },
  // ###########################################################################
  handle: {
    position: "absolute",
    top: -1,
    width: 18,
    height: 18,
    background: "#ccdbde",
    border: "1px solid #00000010",
    borderRadius: 2,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    opacity: 0,
    transition: "opacity 50ms",
    "&:hover": {
      background: "#00000010",
    },
    "&:hover > svg": {
      color: "#00000075",
      cursor: "col-resize"
    }
  },
  leftHandle: {
    left: -24,
  },
  rightHandle: {
    right: -24,
  },
  resizeIcon: {
    position: "absolute",
    transform: "rotate(90deg)",
    fontSize: 18,
    top: -1,
    color: "#00000070"
  },
  // ###########################################################################
  percentHandler: {
    position: "absolute",
    right: 0,
    width: 18,
    height: 16,
    zIndex: 2,
    transition: "opacity 50ms",
    background: "#00000020",
    justifyContent: "center",
    display: "flex",
    "&:hover": {
      background: "#00000040",
      cursor: "col-resize",
      opacity: "1 !important",
    },
  },
  outside: {
    right: -18
  }
  // ###########################################################################
});

const ProgressWithStyles = withStyles(styles)(Progress)

// #############################################################################
// export
// #############################################################################

export { ProgressWithStyles as Progress };