import React, { PureComponent } from "react";
import { history } from "../../helpers";
import { connect } from "react-redux";
import { ExitIcon } from "../../components";
import { userConstants } from "../../constants";

// ######[ MATERIAL UI ]########################################################
import { withStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// ######[ MATERIAL UI ICONS ]##################################################
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import Home from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsApplicationsOutlined from "@material-ui/icons/SettingsApplicationsOutlined";

// #############################################################################
// component
// #############################################################################

class SideBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: ""
    };
  }

  componentDidMount() {
    const array = this.props.pathname.split("/");

    this.setState({
      selected: array[2]
        ? array[2] === "projects"
          ? "/admin-dashboard"
          : "/" + array[1] + "/" + array[2] + "/"
        : "/admin-dashboard"
    });
  }

  // ###########################################################################
  //
  // ###########################################################################

  handleExit = () => {
    this.props.dispatch({ type: userConstants.LOGOUT });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleRoute = route => () => {
    this.setState({ selected: route });
    history.push(route);
  };

  // ###########################################################################
  //
  // ###########################################################################

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  // ###########################################################################
  //
  // ###########################################################################

  render() {
    const { classes } = this.props;
    const { open, selected } = this.state;
    return (
      <Drawer
        variant="permanent"
        className={`${classes.drawer} ${
          open ? classes.drawerOpen : classes.drawerClose
        }`}
        classes={{ paper: open ? classes.drawerOpen : classes.drawerClose }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton
            className={classes.drawerOpenButton}
            onClick={this.toggleDrawer}
          >
            {!open ? <MenuIcon /> : <CloseIcon />}
          </IconButton>
        </div>

        <List className={classes.list}>
          <ListItem
            button
            onClick={this.handleRoute("/admin-dashboard")}
            classes={{
              root: selected === "/admin-dashboard" ? classes.selected : null
            }}
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <Home />
            </ListItemIcon>
            <ListItemText
              classes={{ root: classes.textColor }}
              primary="Dashboard"
            />
          </ListItem>

          <ListItem
            button
            onClick={this.handleRoute("/admin-dashboard/users/")}
            classes={{
              root:
                selected === "/admin-dashboard/users/" ? classes.selected : null
            }}
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ root: classes.textColor }}
              primary="Users"
            />
          </ListItem>

          <ListItem
            button
            onClick={this.handleRoute("/admin-dashboard/clients/")}
            classes={{
              root:
                selected === "/admin-dashboard/clients/"
                  ? classes.selected
                  : null
            }}
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ root: classes.textColor }}
              primary="Clients"
            />
          </ListItem>

          <ListItem
            button
            onClick={this.handleRoute("/admin-dashboard/Profile/")}
            classes={{
              root:
                selected === "/admin-dashboard/Profile/"
                  ? classes.selected
                  : null
            }}
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <SettingsApplicationsOutlined />
            </ListItemIcon>
            <ListItemText
              classes={{ root: classes.textColor }}
              primary="Profile"
            />
          </ListItem>

          <ListItem
            classes={{ root: classes.exit }}
            button
            onClick={this.handleExit}
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ root: classes.textColor }}
              primary="Sign Out"
            />
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const drawerWidth = 240;
const styles = theme => ({
  toolbar: {
    padding: "7px 11px",
    boxSizing: "border-box",
    borderBottom: "1px solid #e1e1e1"
  },
  drawer: {
    width: drawerWidth,
    height: "100vh",
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 1200,
    "& > div": {
      overflowX: "hidden"
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: "width 200ms cubic-bezier(.27,.85,.5,1) "
  },
  drawerClose: {
    width: "56px",
    transition: "width 200ms cubic-bezier(.27,.85,.5,1) ",
    overflowX: "hidden"
  },
  drawerOpenButton: {
    padding: "5px"
  },
  textColor: {
    "& > span": {
      color: "#00000080"
    }
  },
  exit: {
    position: "sticky",
    top: "calc(100vh)"
  },
  selected: {
    "& > div": {
      color: "#00d47f"
    },
    "& > div > span": {
      color: "#00d47f"
    }
  },
  list: {
    height: "calc(100vh - 49px)",
    boxSizing: "border-box"
  },
  icon: {
    marginRight: 0
  }
});
// #############################################################################
// connect to the styles
// #############################################################################

const SideBarWithStyles = withStyles(styles)(SideBar);
const SideBarConnected = connect(null)(SideBarWithStyles);

// #############################################################################
// export
// #############################################################################

export { SideBarConnected as SideBar };
