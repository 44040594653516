import React from 'react'
import Close from '@material-ui/icons/Close'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import { withStyles } from "@material-ui/core";
import { API } from "../../constants";
import { Colors } from '../../styles/colors'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";

const handleDownload = (link, e) => {
    e.preventDefault();
    window.open(link);
};

const AttachmentElement = ({ classes, attachment, editMode, handleDeleteModal }) => (
    <div className={classes.container}>
        <div className={classes.attachmentWrapper}>
            <Tooltip title={attachment.name}>
                <span className={classes.name}>
                    {attachment.name}
                </span>
            </Tooltip>
            <span className={classes.action}>
                {
                    editMode ?
                        <IconButton
                            className={classes.IconButton}
                            onClick={() => handleDeleteModal(attachment)}
                        >
                            <Close
                                className={classes.closeIcon}
                            />
                        </IconButton> :
                        <a
                            href={attachment.link}
                            onClick={e => handleDownload(`${API}/${attachment.link}`, e)}
                            className={classes.anchor}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <ArrowDownward
                                className={classes.attachmentItemDownloadIcon}
                            />
                        </a>
                }
            </span>
        </div>
    </div>
)

const Styles = {
    container: {
        display: 'inline-block',
        margin: '5px',
        padding: 0,
        cursor: 'default',
    },
    attachmentWrapper: {
        backgroundColor: '#F6F6F6',
        border: `1px solid ${Colors.lightGrey}`,
        borderRadius: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 0 0 6px',
        height: '28px'
    },
    name: {
        color: Colors.grey,
        margin: '0 3px',
        width: '100px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        fontSize: '13px'
    },
    action: {
        cursor: 'pointer'
    },
    attachmentItemDownloadIcon: {
        color: Colors.lightGreen,
        fontSize: "24px"
    },
    anchor: {
        border: `2px solid ${Colors.lightGreen}`,
        borderRadius: '100%',
        marginRight: '-1px',
    },
    closeIcon: {
        fontSize: '16px',
        color: Colors.grey,
        width: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '1px'
    },
    IconButton: {
        padding: '6px'
    }

}

export default withStyles(Styles)(AttachmentElement)