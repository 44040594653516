import domtoimage from 'dom-to-image-chrome-fix';
let jsPDF = null;

// ###########################################################################
// filter the nodes
// ###########################################################################

export function filterNodes(node) {
  let allowed = false
  const allowedList = [
    {attribute: "tagName", value: "DIV"},
    {attribute: "tagName", value: undefined},
    {attribute: "tagName", value: "MAIN"},
    {attribute: "tagName", value: "SPAN"},
  ]
  allowedList.forEach(item => {
    allowed = node[item.attribute] === item.value || allowed;
  });
  return (allowed);
}

// ###########################################################################
// Save Snapshots
// ###########################################################################

export function handleSaveSVG(width, projectName) {
  var node = document.getElementById('mp-ganttchart-scroll');
  domtoimage.toSvg(node, { width, filter: filterNodes})
  .then((dataUrl) => {
    var link = document.createElement("a"); 
    document.body.appendChild(link);
    link.setAttribute("download",`${projectName} - Date: ${new Date()}.svg`);
    link.setAttribute("href", dataUrl);
    link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
  })
  .catch((error) => {
      console.error('oops, something went wrong!', error);
  });
}

// ###########################################################################
//
// ###########################################################################

export function handleSavePNG(taskSize, projectName) {
  var node = document.getElementById('mp-ganttchart-scroll');

  domtoimage.toPng(node,{width:taskSize, quality:1})
    .then((dataUrl) => {
      var link = document.createElement("a");
      link.setAttribute("href", dataUrl);
      link.setAttribute("download", `${projectName} - Date: ${new Date()}.png`);
      link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
    })
    .catch((error) => {
        console.error('oops, something went wrong!', error);
    });
}

// ###########################################################################
//
// ###########################################################################

export function handleSaveJPG(taskSize, projectName) {
  var node = document.getElementById('mp-ganttchart-scroll');

domtoimage.toJpeg(node,{width:taskSize, quality:1})
  .then((dataUrl) =>  {
    var link = document.createElement("a");
    link.setAttribute("href",dataUrl);
    link.setAttribute("download", `${projectName} - Date: ${new Date()}.jpg`);
    // link.click();
    link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
  })
  .catch((error) =>  {
      console.error('oops, something went wrong!', error);
  });
}

// ###########################################################################
//
// ###########################################################################

export function handleSavePDF(taskSize) {
  if (jsPDF){
    let doc = new jsPDF('l','mm','a3'); 
    var node = document.getElementById('mp-ganttchart-scroll');
    let ratio = 400/taskSize;
    let height = node.getBoundingClientRect().height * ratio;
  
    if (height > 297) {
      ratio = 297/node.getBoundingClientRect().height;
      taskSize *= ratio; 
    }
  
    domtoimage.toPng(node,{width:taskSize, quality:1})
    .then(function (dataUrl) {
      doc.addImage(dataUrl, 'PNG', 10, 10,400,height);
      doc.save();
    })
    .catch(function (error) {
        console.error('oops, something went wrong!', error);
    });
  } else {
    import('jspdf').then(_jsPDF => {
      jsPDF = _jsPDF;
      handleSavePDF(taskSize);
    });
  }
}