import React, { Fragment } from "react";

import { connect } from "react-redux";
import { ReactInput } from "../../";
import { userActions, clientActions } from "../../../actions";
import { UserInfo } from "./userInfo";
import Select from "react-select";
import { projectRoles } from "../../../constants";
import { UsersItem } from "./UsersItem";
import { BackButton } from "../../Buttons";

// #####[ MATERIAL UI ]#########################################################
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { Colors } from "../../../styles/colors";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

// #####[ MATERIAL UI ICONS ]###################################################
import SearchIcon from "@material-ui/icons/Search";

// #############################################################################
// styles
// #############################################################################

const styles = (theme) => ({
  // ///////////////////// search bar //////////////////////////
  searchBar: {
    borderBottom: "1px solid #e1e1e1",
    padding: 5,
    position: "fixed",
    zIndex: 1,
    backgroundColor: "#f4f5f9",
  },
  searchInput: {
    backgroundColor: "#ffffff00",
    color: "#00000099",
    paddingLeft: "40px",
    border: "none",
    zIndex: "0",
    "&:focus": {
      boxShadow: "none",
    },
  },
  searchIcon: {
    color: "#00000070",
    position: "absolute",
    marginLeft: "5px",
    zIndex: "0",
  },
  searchInputHolder: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  // ///////////////////////////////////////////////////////////
  paper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    overflowX: "hidden",
    position: "absolute",
    width: theme.spacing.unit * 70,
    background:
      "linear-gradient(to bottom, #3f51b5  0%,#3f51b5 60px,#3f51b5 60px,white 50px,white 100%)" /* W3C */,
    borderRadius: "5px",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 20px)",
      maxHeight: "calc(100vh - 20px)",
    },
  },
  users: {
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
  },
  textField: {
    width: "100%",
    "&>div": {
      borderRadius: "5px",
      backgroundColor: "#0000000a",
    },
  },
  customButton: {
    backgroundColor: "#155794",
    width: "100%",
    height: "48px",
    fontSize: "0.7rem",
    color: "#fff",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#3f51b5",
    },
  },
  desc: {
    margin: "20px 0",
    fontSize: "12px",
    color: "gray",
  },
  projects: {
    padding: "5px 2px 5px",
    margin: "2px",
    overflow: "hidden",
    backgroundColor: "#E0E0E0",
    borderRadius: "5px",
    fontSize: "0.8rem",
  },
  assignUserBox: {
    backgroundColor: "#eff0f5",
    borderRadius: "5px",
    border: "1px solid #bdbdbd",
    width: "100%",
    direction: "row",
    justify: "space-between",
    alignItems: "center",
    minWidth: "800px",
  },
  selectAddGrid: {
    width: "100%",
    direction: "row",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "3px",
  },
  textFieldItem: {
    width: "100%",
    height: "38px",
    color: "hsl(0,0%,50%)",
  },
  select: {
    backgroundColor: "red",
  },
  selectAddGridItem: {
    flex: 1,
    padding: "5px",
  },
  headerInputs: {
    padding: "5px",
    flex: 1,
  },
  headerButtonHolder: {
    padding: "5px 5px 5px 0px",
    width: "95px",
  },
  header: {
    padding: "80px 20px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 20px 10px",
    },
  },
  userInfo: {
    minHeight: "calc(100vh - 225px)",
    padding: "15px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 10px 10px",
    },
  },
  userDetails: {
    minHeight: "486px",
    borderRadius: "2px",
    padding: "15px 20px 20px 7px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px 10px 10px",
    },
  },
  projectList: {
    color: "#155794",
    fontSize: "14px",
  },
  tagHolder: {
    lineHeight: 1.5,
    width: "100%",
  },
  borderBottom: {
    borderBottom: "1px solid #bdbdbd",
    paddingBottom: "5px",
    fontSize: "14px",
    color: "#155794",
  },
  // withRightBorder: { borderRight: "1px solid #e1e1e1" },
  listHeader: {},
  outerContainer: {
    padding: "0px 20px",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  titleTopHeader: {
    width: "90px",
    padding: "10px 5px 10px 10px",
    color: "#155794",
    textAlign: "center",
    fontSize: "15px",
  },
  // ######[ styles for userInfo component ]########

  pageNumbersHolder: {
    padding: "5px 0px",
    display: "flex",
    flex: 1,
    flexWrap: "no-wrap",
    overflowX: "auto",
  },
  nextButton: {
    padding: "5px 0px",
  },
  pagination: {
    padding: "0px 20px",
  },
  borderTop: {
    borderTop: "1px solid #e1e1e1",
  },
  pageBtn: {
    fontSize: "14px",
    minWidth: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "15px",
    color: "#00000040",
    marginRight: "5px",
  },
  selectedPage: {
    color: "#155794",
    backgroundColor: "rgba(0, 0, 0, 0.03)",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 22px",
    color: "#000000a3",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    height: "48px !important",
    minHeight: "48px !important",
  },
  roleAssignSelect: {
    // color: "#758ABE"
  },
  moreButton: {
    color: "#00D47F",
    fontSize: "12px",
    padding: "3px",
    marginTop: "7px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-3px",
    cursor: "pointer",
    paddingRight: "3px",
    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
    "-khtml-user-select": "none",
    " -moz-user-select": "none",
    "-ms-user-select": "none",
  },
  arrowsContainer: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#FCFDFE",
    },
  },
  keyboardArrowUp: {
    fontSize: "17px",
    color: Colors.darkenThanLightGrey,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    borderBottom: "1px solid" + Colors.darkenThanLightGrey,
    width: "100%",
    padding: "2px 10px",
  },

  titleName: {
    color: Colors.darkBlue,
    textAlign: "left",
    fontWeight: "400",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0 3px",
  },

  keyboardArrowDown: {
    marginTop: "-8px",
    fontSize: "17px",
    color: Colors.darkenThanLightGrey,
  },
});

// #############################################################################
// component
// #############################################################################

class AdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.userDetails = React.createRef();
    this.state = {
      modalAddUser: false,
      modalEditUser: false,
      alertDeleteUser: false,
      openEditUser: false,
      firstName: "",
      lastName: "",
      company: "",
      title: "",
      email: "",
      password: "",
      phoneNumber: "",
      id: "",
      searchKeyWord: "",
      pageNumber: 0,
      pageCapacity: 15,
      selectedRole: "",
      modeButton: false,
      moreExpand: false,
      selectedUser: {},
      highlightedArrow: "",
      directionHighlightedArrow: null,
    };
  }
  componentDidMount = () => {
    this.props.dispatch(userActions.getAllUsersRoles());
    this.props.dispatch(clientActions.getAllCompanies());
  };

  componentDidUpdate() {
    const element = document.getElementById("tagHolder");
    if (element && !this.state.modeButton) {
      if (element.offsetHeight > 27) {
        this.setState({
          modeButton: true,
        });
      }
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSearch = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value.toString().toLowerCase(), pageNumber: 0 });
  };

  // ###########################################################################
  // Dialog: Delete User
  // ###########################################################################
  handleDeleteUsers = () => {
    const { dispatch } = this.props;
    const { id } = this.state;
    dispatch(userActions.deleteUser({ id }));
    this.handleCloseDeleteUser();
  };

  handleOpenDeleteUser = (id) => () => {
    this.setState({
      alertDeleteUser: true,
      id,
    });
  };

  handleCloseDeleteUser = () => {
    this.setState({ alertDeleteUser: false });
  };

  Transition = (props) => {
    return <Slide direction="up" {...props} />;
  };

  deleteDialog = () => {
    const { usersRoles = [] } = this.props;
    const { alertDeleteUser, id } = this.state;
    const users = usersRoles.filter((user) => id === user._id);
    return (
      <div>
        <Dialog
          open={alertDeleteUser}
          TransitionComponent={this.Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Alert!"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{`You won't be able to restore, are you sure you want to delete?`}</DialogContentText>
            <ul>
              {users.map((user) => (
                <li
                  key={user._id}
                >{`${user.firstName} ${user.lastName} (${user.email})`}</li>
              ))}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDeleteUser} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDeleteUsers} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  // ###########################################################################
  // Modal: Edit user
  // ###########################################################################

  handleSelectUser = (selectedUser) => () => {
    this.setState({
      selectedUser,
    });
    this.openDrawer();
  };

  handleEditUser = (selectedUser) => {
    this.props.dispatch(userActions.editUser(selectedUser));
  };

  handleLockUser = (data) => () => {
    this.props.dispatch(userActions.editUser(data));
  };

  // ###########################################################################
  // close drawer
  // ###########################################################################

  closeDrawer = () => {
    this.setState({ openEditUser: false });
  };

  // ###########################################################################
  // close drawer
  // ###########################################################################

  openDrawer = () => {
    this.setState({ openEditUser: true });
  };

  // ###########################################################################
  // Modal: Add user
  // ###########################################################################

  handleAddUser = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      company,
      title,
      selectedRole,
    } = this.state;
    dispatch(
      userActions.addUser({
        firstName,
        lastName,
        email,
        phoneNumber,
        companyId: company.id,
        title,
        companyRole: selectedRole.value,
      })
    );
    this.setState({
      modalAddUser: true,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      company: "",
      title: "",
      selectedRole: {},
    });
  };
  // ###########################################################################
  // Generate pagination Buttons
  // ###########################################################################
  generatePageButtons = (length, pageCapacity) => {
    const { pageNumber } = this.state;
    const { classes } = this.props;
    let numberOfPages = 0;
    const buttons = [];

    if (this.isInt(length / pageCapacity))
      numberOfPages = parseInt(length / pageCapacity);
    else numberOfPages = parseInt(length / pageCapacity) + 1;

    for (let i = 0; i < numberOfPages; i++)
      buttons.push(
        <IconButton
          key={i}
          className={
            i === pageNumber
              ? `${classes.selectedPage} ${classes.pageBtn}`
              : `${classes.pageBtn}`
          }
          onClick={this.handleChangePage(i)}
        >
          {i + 1}
        </IconButton>
      );

    return buttons;
  };

  isInt = (n) => {
    return n % 1 === 0;
  };

  // ###########################################################################
  // handles page change
  // ###########################################################################
  handleChangePage = (number) => () => {
    this.setState({ pageNumber: number });
  };
  handleNext = (length, pageCapacity) => () => {
    const NumberOfPages = Math.round((length + 2) / pageCapacity);
    if (this.state.pageNumber <= NumberOfPages - 2)
      this.setState({ pageNumber: this.state.pageNumber + 1 });
  };

  // ###########################################################################
  // handles filter
  // ###########################################################################
  filterUsers = (users) => {
    const keyWordLowerCase = this.state.searchKeyWord.toLowerCase();
    const filteredUsers = users.filter((user) => {
      const phoneResult = user.phoneNumber
        ? user.phoneNumber.toString().toLowerCase().includes(keyWordLowerCase)
        : false;
      const nameResult =
        user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
              .toLowerCase()
              .includes(keyWordLowerCase)
          : false;
      const emailResult = user.email
        ? user.email.toLowerCase().includes(keyWordLowerCase)
        : false;
      const title = user.title
        ? user.title.toLowerCase().includes(keyWordLowerCase)
        : false;
      const company = user.company
        ? user.company.name.toLowerCase().includes(keyWordLowerCase)
        : false;

      const companyRole = user.role
        ? user.role.toLowerCase().includes(keyWordLowerCase)
        : false;

      const projectsRole = user.projectList
        ? user.projectList.find((project) =>
            project.role
              .replace("-", " ")
              .toLowerCase()
              .includes(keyWordLowerCase)
          )
        : false;

      const isInProjectName = user.projectList
        ? user.projectList.find((project) =>
            project.projectName.toLowerCase().includes(keyWordLowerCase)
          )
        : false;

      return (
        emailResult ||
        nameResult ||
        phoneResult ||
        title ||
        company ||
        companyRole ||
        projectsRole ||
        isInProjectName
      );
    });
    return filteredUsers;
  };
  // ###########################################################################
  // Render
  // ###########################################################################
  handlePagination = (array) => {
    const { pageCapacity, pageNumber } = this.state;
    return array.slice(
      pageNumber * pageCapacity,
      pageNumber * pageCapacity + pageCapacity
    );
  };

  handleSelectChange = (option, event) => {
    this.setState({
      [event.name]: option,
    });
  };

  decorateRoles = () => {
    const roles = [];
    for (const role in projectRoles) {
      roles.push({ value: role, label: projectRoles[role] });
    }
    return roles;
  };

  handleMoreButton = () => {
    this.setState((prevState) => ({
      moreExpand: !prevState.moreExpand,
    }));
  };

  handleSort = (name) => {
    this.setState(
      (prevState) => ({
        highlightedArrow: name,
        isNameChanged: prevState.highlightedArrow !== name,
      }),
      () => {
        this.setState(
          (prevState) => ({
            directionHighlightedArrow: prevState.isNameChanged
              ? true
              : !prevState.directionHighlightedArrow,
          }),
          () =>
            this.handleSort1(
              this.state.highlightedArrow,
              this.state.directionHighlightedArrow
            )
        );
      }
    );
  };

  handleSort1 = (highlightedArrow, directionHighlightedArrow) => {
    this.setState({
      highlightedArrow,
      directionHighlightedArrow,
    });
  };

  sort = (users = []) => {
    const { highlightedArrow, directionHighlightedArrow } = this.state;

    if (directionHighlightedArrow === null) return users;
    if (highlightedArrow === "company")
      return directionHighlightedArrow === null
        ? users
        : users.sort((a, b) =>
            directionHighlightedArrow
              ? a.company.name.toLowerCase() > b.company.name.toLowerCase()
                ? 1
                : -1
              : a.company.name.toLowerCase() > b.company.name.toLowerCase()
              ? -1
              : 1
          );
    else if (highlightedArrow === "companyRole")
      return directionHighlightedArrow === null
        ? users
        : users.sort((a, b) =>
            directionHighlightedArrow
              ? a.role.toLowerCase() > b.role.toLowerCase()
                ? 1
                : -1
              : a.role.toLowerCase() > b.role.toLowerCase()
              ? -1
              : 1
          );
    else
      return directionHighlightedArrow === null
        ? users
        : users.sort((a, b) =>
            directionHighlightedArrow
              ? a[highlightedArrow].toLowerCase() >
                b[highlightedArrow].toLowerCase()
                ? 1
                : -1
              : a[highlightedArrow].toLowerCase() >
                b[highlightedArrow].toLowerCase()
              ? -1
              : 1
          );
  };

  sortArrows = (text) => (
    <>
      <KeyboardArrowUp
        className={this.props.classes.keyboardArrowUp}
        style={
          this.state.highlightedArrow === text &&
          this.state.directionHighlightedArrow
            ? { color: Colors.darkBlue }
            : {}
        }
      />
      <KeyboardArrowDown
        className={this.props.classes.keyboardArrowDown}
        style={
          this.state.highlightedArrow === text &&
          !this.state.directionHighlightedArrow
            ? { color: Colors.darkBlue }
            : {}
        }
      />
    </>
  );

  // ###########################################################################
  // Render
  // ###########################################################################

  render() {
    const { classes, usersRoles = [], companies } = this.props;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      company,
      title,
      searchKeyWord,
      openEditUser,
      selectedRole,
      selectedUser,
    } = this.state;
    const filteredUsers = this.filterUsers(usersRoles);
    const usersInPage = this.handlePagination(filteredUsers);
    // const sortedUsersInPage = this.handleSort(usersInPage);

    return (
      <Fragment>
        {/* //////////////////// search top bar //////////////////////////// */}
        <Grid container className={classes.searchBar}>
          <BackButton />
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            className={classes.searchInputHolder}
          >
            <SearchIcon className={classes.searchIcon} />
            <ReactInput
              name="searchKeyWord"
              value={searchKeyWord}
              onChange={this.handleSearch}
              placeholder="Filter Users"
              className={classes.searchInput}
              type="text"
            />
          </Grid>
        </Grid>
        <div className={classes.tableContainer}>
          {/* //////////////////// Add User Inline ///////////////////////// */}
          <div className={classes.header}>
            <form onSubmit={this.handleAddUser}>
              <Grid
                container
                className={classes.assignUserBox}
                justify={"space-evenly"}
                alignItems="center"
              >
                <div className={classes.headerInputs}>
                  <Grid
                    container
                    className={classes.selectAddGrid}
                    alignItems="center"
                    justify={"space-evenly"}
                  >
                    <div className={classes.titleTopHeader}>ADD USER</div>
                    <Grid item className={classes.selectAddGridItem}>
                      <ReactInput
                        name={"firstName"}
                        value={firstName}
                        onChange={this.handleChange}
                        required
                        placeholder="First Name"
                        autoComplete="off"
                        type="text"
                        className={classes.textFieldItem}
                      />
                    </Grid>
                    <Grid item className={classes.selectAddGridItem}>
                      <ReactInput
                        name="lastName"
                        value={lastName}
                        onChange={this.handleChange}
                        required
                        placeholder="Last Name"
                        autoComplete="off"
                        type="text"
                        className={classes.textFieldItem}
                      />
                    </Grid>
                    <Grid item className={classes.selectAddGridItem}>
                      <ReactInput
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        placeholder="Email"
                        autoComplete="off"
                        required
                        type="email"
                        className={classes.textFieldItem}
                      />
                    </Grid>
                    <Grid item className={classes.selectAddGridItem}>
                      <ReactInput
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={this.handleChange}
                        placeholder="Phone Number"
                        autoComplete="off"
                        required
                        type="number"
                        className={classes.textFieldItem}
                      />
                    </Grid>
                    <Grid
                      item
                      className={classes.selectAddGridItem}
                      style={{ position: "relative" }}
                    >
                      <Select
                        type="text"
                        name="company"
                        required
                        onChange={this.handleSelectChange}
                        placeholder="Company"
                        options={companies.map((comp) => ({
                          label: comp.name,
                          value: comp.name,
                          id: comp._id,
                        }))}
                        className={classes.roleAssignSelect}
                        value={company}
                        pageSize={3}
                      />
                      <input
                        tabIndex={-1}
                        style={{
                          height: 0,
                          width: 0,
                          padding: 0,
                          margin: 0,
                          opacity: 0,
                          position: "absolute",
                          top: 30,
                          left: "50px",
                        }}
                        value={company.label}
                        required
                        onChange={this.handleSelectChange}
                        name="hiddenInput"
                      />
                    </Grid>
                    <Grid
                      item
                      className={classes.selectAddGridItem}
                      style={{ position: "relative" }}
                    >
                      <div
                        className={classes.selectAddGridItem}
                        style={{ position: "relative" }}
                      >
                        <Select
                          options={this.decorateRoles()}
                          name={"selectedRole"}
                          onChange={this.handleSelectChange}
                          className={classes.roleAssignSelect}
                          required
                          placeholder="Select Role"
                          pageSize={3}
                          value={selectedRole}
                        />
                        <input
                          tabIndex={-1}
                          style={{
                            height: 0,
                            width: 0,
                            padding: 0,
                            margin: 0,
                            opacity: 0,
                            position: "absolute",
                            top: 30,
                            left: "50px",
                          }}
                          value={selectedRole}
                          required
                          onChange={this.handleSelectChange}
                          name="hiddenInput"
                        />
                      </div>
                    </Grid>

                    <Grid item className={classes.selectAddGridItem}>
                      <ReactInput
                        name="title"
                        value={title}
                        onChange={this.handleChange}
                        placeholder="Title"
                        autoComplete="off"
                        required
                        type="text"
                        className={classes.textFieldItem}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.headerButtonHolder}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Button type="submit" className={classes.customButton}>
                        {"ADD USER"}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </form>
          </div>
          {/* ////////////////////////////////////////////////////////////// */}

          {/* //////////////////////////// list header ///////////////////// */}
          <Grid container className={classes.outerContainer}>
            <Grid item className={classes.listHeader} xs={12}>
              <Grid container>
                <div className={classes.titleWrapper}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    className={classes.titleName}
                  >
                    <div
                      className={classes.title}
                      onClick={() => this.handleSort("lastName")}
                    >
                      Name
                    </div>
                    <div
                      className={classes.arrowsContainer}
                      onClick={() => this.handleSort("lastName")}
                    >
                      {this.sortArrows("lastName")}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    className={classes.titleName}
                  >
                    <div
                      className={classes.title}
                      onClick={() => this.handleSort("email")}
                    >
                      Email
                    </div>
                    <div
                      className={classes.arrowsContainer}
                      onClick={() => this.handleSort("email")}
                    >
                      {this.sortArrows("email")}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    className={classes.titleName}
                  >
                    <div
                      className={classes.title}
                      onClick={() => this.handleSort("phoneNumber")}
                    >
                      Phone Number
                    </div>
                    <div
                      className={classes.arrowsContainer}
                      onClick={() => this.handleSort("phoneNumber")}
                    >
                      {this.sortArrows("phoneNumber")}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    className={classes.titleName}
                  >
                    <div
                      className={classes.title}
                      onClick={() => this.handleSort("company")}
                    >
                      Company
                    </div>
                    <div
                      className={classes.arrowsContainer}
                      onClick={() => this.handleSort("company")}
                    >
                      {this.sortArrows("company")}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    className={classes.titleName}
                  >
                    <div
                      className={classes.title}
                      onClick={() => this.handleSort("companyRole")}
                    >
                      Role
                    </div>
                    <div
                      className={classes.arrowsContainer}
                      onClick={() => this.handleSort("companyRole")}
                    >
                      {this.sortArrows("companyRole")}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    className={classes.titleName}
                  >
                    <div
                      className={classes.title}
                      onClick={() => this.handleSort("title")}
                    >
                      Title
                    </div>
                    <div
                      className={classes.arrowsContainer}
                      onClick={() => this.handleSort("title")}
                    >
                      {this.sortArrows("title")}
                    </div>
                  </Grid>
                  {/* <div className={classes.titleName} style={{ flex: 2 }}>
                    <div className={classes.title} onClick={() => this.handleSort('category')}>Category</div>
                    <div className={classes.arrowsContainer} onClick={() => this.handleSort('category')}>
                        {this.sortArrows('category')}
                    </div>
                </div> */}
                  <div className={classes.titleName} style={{ flex: 1 }}></div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          {/* ////////////////////////////////////////////////////////////// */}

          {/* //////////////////// React Table ///////////////////////////// */}
          <Grid container>
            <Grid item xs={12} className={classes.userInfo}>
              {this.sort(usersInPage).map((user, index) => (
                <UsersItem
                  key={Math.random()}
                  user={user}
                  handleSelectUser={this.handleSelectUser}
                  handleOpenDeleteUser={this.handleOpenDeleteUser}
                  handleLockUser={this.handleLockUser}
                  projectList={user.projectList}
                />
              ))}
            </Grid>

            {/* ///////////////////// user details and edit /////////////////// */}

            {/* /////////////////////////Open button /////////////////////////// */}
            <div className={classes.addButtonOpenParent}>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={openEditUser}
                classes={{
                  paper: classes.drawerPaper,
                  root: classes.drawerTransition,
                }}
              >
                <div className={classes.drawerHeader}>Edit User</div>
                <Divider />
                <List style={{ overflowY: "auto" }}>
                  <UserInfo
                    closeDrawer={this.closeDrawer}
                    openDrawer={this.openDrawer}
                    handleEditUser={this.handleEditUser}
                    ref={this.userDetails}
                    companies={companies}
                    selectedUser={selectedUser}
                    openEditUser={openEditUser}
                  />
                </List>
              </Drawer>
            </div>

            {/* //////////////////// pagination component  //////////////////// */}

            <Grid container className={classes.pagination}>
              <Grid container className={classes.borderTop}>
                <div className={classes.nextButton}>
                  <IconButton
                    onClick={this.handleNext(
                      filteredUsers.length,
                      this.state.pageCapacity
                    )}
                    className={classes.pageBtn}
                  >
                    NEXT
                  </IconButton>
                </div>
                <div className={classes.pageNumbersHolder}>
                  {this.generatePageButtons(
                    filteredUsers.length,
                    this.state.pageCapacity
                  )}
                </div>
              </Grid>
            </Grid>

            {/* //////////////////// Delete User Dialog /////////////////////// */}

            {this.deleteDialog()}

            {/* /////////////////////////////////////////////////////////////// */}
          </Grid>
        </div>
      </Fragment>
    );
  }
}

// #############################################################################
// connect component to redux state and material UI styles
// #############################################################################

const mapStateToProps = (state) => {
  return {
    usersRoles: state.users.usersRoles
      ? state.users.usersRoles.map((user) =>
          !user.company ? { ...user, company: { name: "No Company" } } : user
        )
      : [],
    companies: state.companies ? state.companies : [],
  };
};

const AdminUsersConnect = connect(mapStateToProps)(AdminUsers);
const AdminUsersStyles = withStyles(styles)(AdminUsersConnect);

// #############################################################################
// exports
// #############################################################################

export { AdminUsersStyles as AdminUsers };
