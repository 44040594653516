import { activityConstants } from "../constants";

export function activities(state = [], action) {
  switch (action.type) {
    /////get/////

    case activityConstants.GET_ACTIVITIES_SUCCESS:

      return action.payload;

    case activityConstants.GET_ACTIVITIES_FAILURE:
      return state;

    default:
      return state;
  }
}
