
// #############################################################################
// this function gets start day and duration then return the finish date 
// considering the week end doesn't count
// #############################################################################

export const addWorkDays = (startDate = new Date(), duration = 1) => {
	

	let days = 0; // date counter
	let finishDate = new Date(startDate); // initial finish date 

	while(days < duration ){

		// this condition will add to work days the date isn't weekend
		if(finishDate.getDay() !== 0 && finishDate.getDay() !== 6){
			days++;
		}

		// this condition will prevent adding another day at the end of while loop
		if(days < duration) 
			finishDate = new Date (finishDate.setDate(finishDate.getDate() + 1));
	}

	// returns the finish date
	return finishDate
}