import { attachemntConstants } from "../constants";

export function attachments(state = [], action) {
  switch (action.type) {
    /////get/////
    case attachemntConstants.GET_ATTACHMENTS_SUCCESS:
      return action.payload;

    case attachemntConstants.GET_ATTACHMENTS_FAILURE:
      return state;

    /////add/////
    case attachemntConstants.ADD_ATTACHMENT_SUCCESS:
      return [...state, ...action.payload];
    // else return state
    case attachemntConstants.ADD_ATTACHMENT_FAILURE:
      return state;

    ////add folder////
    case attachemntConstants.ADD_FOLDER_SUCCESS:
      return [...state, action.payload];
    case attachemntConstants.ADD_FOLDER_FAILURE:
      return state;

    /////edit/////
    case attachemntConstants.EDIT_ATTACHMENT_SUCCESS:
      return state.map(attachment => {
        return attachment._id === action.payload._id
          ? action.payload
          : attachment;
      });

    case attachemntConstants.EDIT_ATTACHMENT_FAILURE:
      return state;

    /////edit/////
    case attachemntConstants.PROJECT_DOCUMENT_TAB_EDIT_SUCCESS:
      return action.payload;

    case attachemntConstants.PROJECT_DOCUMENT_TAB_EDIT_FAILURE:
      return state;

    /////delete/////
    case attachemntConstants.DELETE_ATTACHMENT_SUCCESS:
      let itemsToDelete = [action.payload.attachmentId];
      shouldDeleteItems(action.payload.attachmentId, itemsToDelete, state);
      return state.filter(item => !itemsToDelete.includes(item._id));

    case attachemntConstants.DELETE_ATTACHMENT_FAILURE:
      return state;

    //Add USER AND ASSIGN TO PROJECT
    case attachemntConstants.ADD_USER_AND_ASSIGN_SUCCESS:
      return {
        ...state,
        users: state.users ? [...state.users, action.payload] : [action.payload]
      };
    case attachemntConstants.ADD_USER_AND_ASSIGN_FAILURE:
      return state;

    case attachemntConstants.CHANGE_ATTACHMENT_ORDER_SUCCESS:
      return action.payload;

    case attachemntConstants.CHANGE_ATTACHMENT_ORDER_FAILURE:
      return state;

    case attachemntConstants.PROJECT_DOCUMENT_TAB_EDIT_KEEP_BOTH_SUCCESS:
      return action.payload.allNewDocuments.map(attachment =>
        attachment._id === action.payload.newDocument._id
          ? action.payload.newDocument
          : attachment
      );

    case attachemntConstants.PROJECT_DOCUMENT_TAB_EDIT_REPLACE_OLD_FILE_SUCCESS:
      let sentDocument = state.find(
        document => document._id === action.payload.sentDocument.attachmentId
      );
      const temp = state.find(
        attachment =>
          attachment.name === sentDocument.name &&
          (attachment.parentAttachment ===
            action.payload.sentDocument.parentAttachment ||
            (!attachment.parentAttachment &&
              action.payload.sentDocument.parentAttachment === "null" &&
              attachment._id !== sentDocument._id))
      );
      itemsToDelete = [temp._id];
      shouldDeleteItems(temp._id, itemsToDelete, state);
      return action.payload.allNewDocuments.filter(
        item => !itemsToDelete.includes(item._id)
      );

    case attachemntConstants.CLEARING_DOCUMENTS_DATA:
      return [];

    default:
      return state;
  }
}

let shouldDeleteItems = (parentId, itemsToDelete, state) => {
  for (var i = 0; i < state.length; i++) {
    if (state[i].parentAttachment === parentId) {
      itemsToDelete.push(state[i]._id);
      shouldDeleteItems(state[i]._id, itemsToDelete, state);
    } else {
      if (state[i].mainVersionId === parentId) {
        itemsToDelete.push(state[i]._id);
        shouldDeleteItems(state[i]._id, itemsToDelete, state);
      }
    }
  }
};
