import { filterConstants } from "../constants";

export const filterActions = {
  pageFilterEdit
};

//################################################################################
// get Activities
//################################################################################
function pageFilterEdit(data) {
  return {
    type: filterConstants.PAGE_FILTER_EDIT,
    payload: data
  };
}
