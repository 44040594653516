export const commentConstants = {
  GET_ALL_COMMENTS_SUCCESS: "GET_ALL_COMMENTS_SUCCESS",
  GET_ALL_COMMENTS_FAILURE: "GET_ALL_COMMENTS_FAILURE",

  ADD_COMMENT_SUCCESS: "ADD_COMMENT_SUCCESS",
  ADD_COMMENT_FAILURE: "ADD_COMMENT_FAILURE",

  EDIT_COMMENT_SUCCESS: "EDIT_COMMENT_SUCCESS",
  EDIT_COMMENT_FAILURE: "EDIT_COMMENT_FAILURE",

  DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",
  DELETE_COMMENT_FAILURE: "DELETE_COMMENT_FAILURE",

  ADD_ATTACHMENT_TO_COMMENT_SUCCESS: "ADD_ATTACHMENT_TO_COMMENT_SUCCESS",
  ADD_ATTACHMENT_TO_COMMENT_FAILURE: "ADD_ATTACHMENT_TO_COMMENT_FAILURE",

  GET_ISSUE_TASK_COMMENTS_SUCCESS: "GET_ISSUE_TASK_COMMENTS_SUCCESS",
  GET_ISSUE_TASK_COMMENTS_FAILURE: "GET_ISSUE_TASK_COMMENTS_FAILURE",

  ADD_ISSUE_TASK_COMMENTS_SUCCESS: "ADD_ISSUE_TASK_COMMENTS_SUCCESS",
  ADD_ISSUE_TASK_COMMENTS_FAILURE: "ADD_ISSUE_TASK_COMMENTS_FAILURE"
};
