export default function timeSince(date) {

  const seconds = Math.floor((new Date() - new Date(date)) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return "About " + interval + " years ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return "About " + interval + " months ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return "About " + interval + " days ago";
  }
  interval = Math.floor(seconds / 3600);

  if (interval >= 1) {
    return "About " + interval + " hours ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return "About " + interval + " minutes ago";
  }
  return Math.floor(seconds) < 1 ? '1 second ago' :
    Math.floor(seconds) + " seconds ago"
}