import React from "react";
import { UserList } from "../../";

// ######################### redux ###################################
import { connect } from "react-redux";
import { projectUserActions, userUserActions } from "../../../userActions";
import { filterActions } from "../../../actions";
// Material UI /////////////////////////////////////////////////////////////////
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// ######################### components ########################################
import AddAssignUserInline from "../../User-List/AddAssignUserInline";
import ThemeContext from "../../ContextApi/Context";

// #############################################################################
// component
// #############################################################################

class UserProjectUsers extends React.PureComponent {
  static contextType = ThemeContext;

  componentDidMount = () => {
    const projectId = this.context;

    const { dispatch } = this.props;
    dispatch(filterActions.pageFilterEdit(""));
    dispatch(projectUserActions.getProjectUsers({ projectId }));
    dispatch(userUserActions.getAllUsers());
  };

  handleUpdateRole = data => {
    this.props.dispatch(userUserActions.updateProjectRole(data));
  };

  render() {
    const { classes, role } = this.props;

    return (
      <div className={classes.projectInfo}>
        <Grid container>
          <Grid item xs={12}>
            {/* //////////////////// Assign User //////////////////////////////////// */}
            {role && <AddAssignUserInline />}

            {/* //////////////// list of all the user in project ///////////// */}
            <UserList handleEdit={this.handleUpdateRole} pageCapacity={8} />
          </Grid>
        </Grid>

        {/* ////////////////////////////////////////////////////////////// */}
      </div>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  projectInfo: {
    fontSize: "0.85rem"
  }
});

// #############################################################################
// connect redux and material styles to the component
// #############################################################################

const mapStateToProps = state => {
  return {
    role:
      state.projects.project && state.projects.project.role !== "Project-Client"
        ? true
        : false
  };
};

const UserProjectUsersStylesConnected = connect(mapStateToProps)(
  withStyles(styles)(UserProjectUsers)
);

// #############################################################################
// export
// #############################################################################

export { UserProjectUsersStylesConnected as UserProjectUsers };
