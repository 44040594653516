import React from "react";
import { connect } from "react-redux";
import { AdminDashboard, UserDashboard } from "../../pages";

// #############################################################################
// 
// #############################################################################

class AC extends React.Component {

  render() {
    const { user } = this.props;

    if (user) {
      switch (user.role) {
        case "admin":
          return <AdminDashboard />;

        case "user":
          return <UserDashboard />;
        default:
          break;
      }
    }
  }
}

// #############################################################################
// 
// #############################################################################

const mapStateToProps = state => {
  return {
    user: state.authentication.user
  };
};
const ACConnect = connect(mapStateToProps)(AC);

// #############################################################################
// 
// #############################################################################
export { ACConnect as AC };
