import { filterConstants } from "../constants";

export function filters(state = { pageFilter: "" }, action) {
  switch (action.type) {
    case filterConstants.PAGE_FILTER_EDIT:
      return { ...state, pageFilter: action.payload };

    default:
      return state;
  }
}
