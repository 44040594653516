// #############################################################################
// this function will calculate the stops on the PolyLine
// #############################################################################

export function calculateDots(dots, horizontalUnit = 13, verticalUnit = 28){
  const [startX, startY, endX, endY] = dots

  const x1 = startX;
  const y1 = startY + 12;

  const x2 = startX + (endX - startX);
  const y2 = y1;
  
  return `${parseInt(startX, 10) + 0.5},${parseInt(startY, 10) + 0.5} ${parseInt(x1, 10) + 0.5},${parseInt(y1, 10) + 0.5} ${parseInt(x2, 10) + 0.5},${parseInt(y2, 10) + 0.5} ${parseInt(endX, 10) + 0.5},${parseInt(endY, 10) + 0.5}`
}