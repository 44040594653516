import React, { PureComponent } from 'react';
import { withStyles } from "@material-ui/core"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from "@material-ui/icons/Close";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { CButton } from "../";


// #############################################################################
// component
// #############################################################################

class CommitModalContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      commit: ""
    };
  }

  // ###########################################################################
  // handle change
  // ###########################################################################

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  }

  // ###########################################################################
  // handle commit
  // ###########################################################################

  handleCommit = (event) => {
    event.preventDefault()
    const { commit } = this.state;
    const { _id } = this.props.data;
    const { projectId, role } = this.props;

    this.props.handleClose(true);
    this.props.handleCommit({ commit, projectId, role,  issueTaskId: _id, done: true });
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const { commit } = this.state;
    const { classes, handleClose, data } = this.props;

    return (
      <ClickAwayListener onClickAway={() => {handleClose(false)}}>
        <form tabIndex={1} onSubmit={this.handleCommit} className={classes.root}>

          <IconButton type="button" onClick={() => {handleClose(false)}} className={classes.iconButton}>
            <CloseIcon/>
          </IconButton>

          <h3 className={classes.header}>Completion Task</h3>
          <h6 className={classes.description}>{data.description}</h6>
          <h5 className={classes.issueName}>{data.name}</h5>

          <textarea
            required
            name="commit"
            value={commit}
            onChange={this.handleChange}
            placeholder="write your note here"
            className={classes.input}>
          </textarea>

          <div className={classes.buttonHolder}>
            <CButton type="submit" className={classes.button}>Done</CButton>
          </div>

        </form>
      </ClickAwayListener>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    position:"relative",
    width: "45%",
    minWidth: 500,
    minHeight: 100,
    padding: "15px 25px",
    borderRadius: 10,
    background: "#eff0f5",
    boxShadow: "0px 0px 7px 1px #00000050",
  },
  iconButton:{
    position: "absolute",
    padding: 1,
    right: 10,
    top: 10,
  },
  header:{
    fontWeight: 400,
    color: "#155794",
    marginBottom: 5,
  },
  description:{
    margin: 0,
    fontWeight: 500,
    color: "#00000057",
    marginBottom: 15,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  issueName:{
    fontWeight: 400,
    color: "#000000cf",
    borderBottom: "1px solid #4CAF50",
    paddingBottom: 4,
    margin: "10px 0px",
  },
  input: {
    width: "100%",
    minHeight: 150,
  },
  buttonHolder:{
    display: "flex",
    justifyContent: "flex-end",
  },
  button:{
    width: 100,
    height: 40,
    marginTop: 5
  }
});

// #############################################################################
// connect material styles to component
// #############################################################################
const CommitModalContentWithStyles = withStyles(styles)(CommitModalContent)
export  { CommitModalContentWithStyles as CommitModalContent };

