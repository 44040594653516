// React //
import React from "react";

// Redux //

// Material Ui //
import { withStyles } from "@material-ui/core";

// Components //
import { FolderAndPlus } from '../Icons'
import { SaveButton, CancelButton } from '../Buttons/'
import { ReactInput } from '../reactInput/reactInput'
import TimeYearMonthDay from '../reusebleFunctions/TimeYearMonthDay'

// Reusable Functions //

// Styles //
import { Colors } from '../../styles/colors'

class AddNewFolder extends React.PureComponent {
    state = {
        isChangeHappend: false,
        isAddNewFolder: false,
        name: '',
        attachmentNote: ''
    };

    handleOpenCloseFolder = () => {
        !this.state.editMode && this.setState(prevState => ({
            isMaximizeForVersions: false,
            isFolderOpend: !prevState.isFolderOpend
        }))
    }

    handleCancel = () => {
        const { documents } = this.props
        this.setState({
            editMode: false,
            name: documents.name,
            attachmentNote: documents.attachmentNote,
            isChangeHappend: false
        })
    }

    handleChange = ({ target }) => {
        this.setState({
            [target.name]: target.value, isChangeHappend: true
        })
    }

    handleAddNewFolder = () => {
        const { name, attachmentNote, isChangeHappend } = this.state
        isChangeHappend && this.props.handleAddNewFolder({
            name,
            attachmentNote
        })
    }

    render() {
        const {
            classes = {},
            dynamicMargin,
            dynamicRowColor,
            handleToggleAddNewFolder
        } = this.props;
        const {
            name,
            attachmentNote,
        } = this.state
        return (
            <div className={classes.row} style={{ filter: `brightness(${dynamicRowColor}%)` }}>
                <div className={classes.nameTd}
                    onClick={this.handleOpenCloseFolder}
                >
                    <div style={{ marginLeft: `${dynamicMargin}px` }}>
                        <FolderAndPlus
                            isFolderOpend={false}
                            hasChild={false}
                        />
                        <ReactInput className={classes.input} name='name' onChange={this.handleChange} value={name} ref='nameInput' />
                    </div>
                </div>
                <div className={classes.noteTd} onClick={this.handleOpenCloseFolder}>
                    <textarea rows={1} className={classes.textarea} name='attachmentNote' onChange={this.handleChange} value={attachmentNote} />
                </div>
                <div className={classes.dateTd}
                    onClick={this.handleOpenCloseFolder}
                >
                    {TimeYearMonthDay()}
                </div>
                <div className={classes.actionsTd}>
                    <SaveButton onClick={this.handleAddNewFolder} />
                    <CancelButton onClick={handleToggleAddNewFolder} />
                </div>
            </div>
        );
    }
}

const Styles = {
    row: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        margin: '10px 0 0 10px',
        '& > div': {
            padding: '6px 10px',
            backgroundColor: 'white',
            borderRadius: '3px',
            border: `1px solid #e3e3e3`,
            fontSize: '14px',
            margin: '0 3px',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box'
        },
    },
    nameTd: {
        width: '30%',
        justifyContent: 'flex-start',
        alignItems: 'inherit!important',
        backgroundColor: 'transparent!important',
        padding: '0!important',
        border: 'none!important',
        marginRight: '0!important',
        '& > div': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: 'white',
            border: `1px solid #e3e3e3`,
            width: '100%',
            padding: '6px 10px',
            borderRadius: '3px',
            borderRight: 'none!important',
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0'

        },
        '& > div > span:nth-child(2)': {
            marginLeft: '5px',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            WebkitHyphens: 'auto',
            MsHyphens: 'auto',
            hyphens: 'auto',
        }
    },
    noteTd: {
        flexGrow: '100',
        color: Colors.grey,
        marginLeft: '0!important',
        borderLeft: 'none!important',
        borderTopLeftRadius: '0!important',
        borderBottomLeftRadius: '0!important',
    },
    dateTd: {
        width: '90px',
        justifyContent: 'center'
    },
    actionsTd: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '170px'
    },
    input: {
        width: '100%'
    },
    textarea: {
        width: '100%',
        height: 'auto'
    }
};

export default withStyles(Styles)(AddNewFolder)
