import React, { PureComponent } from 'react';
import { withStyles } from "@material-ui/core"
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined"

// #############################################################################
// component
// #############################################################################

class DeleteAlert  extends PureComponent {
  
  render() {
  const { classes, showAlert = false } = this.props;
    return (
      <div className={`${classes.root} ${showAlert ? null : classes.hide }`}>
        <button onClick={this.props.handleDelete} className={`${classes.button} ${classes.deleteButton}`}>
          DELETE
          <DeleteForeverOutlinedIcon classes={{root: classes.icon}}/>
        </button>
        <button onClick={this.props.toggleDeleteAlert} className={`${classes.button} ${classes.canceleButton}`}>CANCEL</button>
      </div>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    position: "absolute",
    top: 0,
    left: 0,
    width:"100%",
    height:"100%",
    background: "#434b50de",
    zIndex: 11,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    opacity: 1,
    transition:"opacity 450ms"
  },
  hide:{
    zIndex: 0,
    opacity: 0,
    display: "none"
  },
  // ###########################################################################
  button:{
    padding:"5px 15px",
    borderRadius: 4,
    color: "red",
    background: "none",
    border: "1px solid red",
    fontSize: 13,
    margin: 5,
    fontWeight: 600,
    position: "relative",
    alignItems: 'center',
  },
  deleteButton:{
    color: "#ffbb37",
    border: "1px solid #ffbb37",
    paddingLeft: "30px",
    "&:hover": {
      background: "#ffbb3726"
    },
    "&:active": {
      background: '#ffbb3778',
    },
    "&:focus":{
      boxShadow: "0 0 0px 4px #ffbb3745"
    },
  },
  canceleButton:{
    color: "#37ff64",
    border: "1px solid #37ff64",
    "&:hover": {
      background: "#37ff6433"
    },
    "&:active": {
      background: '#37ff6459',
    },
    "&:focus":{
      boxShadow: "0 0 0px 4px #37ff645c"
    }
  },
  // ###########################################################################
  icon: {
    position: "absolute",
    top: 0,
    left: 0
  }
});
const DeleteAlertWithStyles = withStyles(styles)(DeleteAlert)

// #############################################################################
// export
// #############################################################################

export  { DeleteAlertWithStyles as DeleteAlert };

