import { userConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "../actions";
import { history } from "../helpers";

export const userUserActions = {
  getAllUsers,
  clearAssignedUsersLocal,
  deleteProjectRole,
  setProjectRole,
  updateProjectRole,
  verification,
  resetPassword,
  getProfileGeneral,
  editProfileGeneral,
  getMyRole
};
//################################################################################
// GET All Users
//################################################################################

function getAllUsers(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getAllUsers", data, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.users));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(users) {
    return { type: userConstants.GET_ALL_USERS_SUCCESS, payload: users };
  }
  // function failure(error) {
  //   return { type: userConstants.GET_ALL_USERS_FAILURE, payload: error };
  // }
}

//################################################################################
// Update Project Role
//################################################################################

function updateProjectRole(data, page) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editRole", data, "user").then(
      response => {
        dispatch(progressBarActions.stop());

        if (response.success === true) {
          if (response.userProject) dispatch(success(response.userProject));
          dispatch(snackBarActions.snackBarSuccess("Successful update!!"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(userProject) {
    return {
      type: userConstants.UPDATE_PROJECT_ROLE_SUCCESS,
      payload: userProject
    };
  }
  // function failure(error) {
  //   return { type: userConstants.UPDATE_PROJECT_ROLE_FAILURE, payload: error };
  // }
}
//################################################################################
// CLEAR_ASSIGNED_USERS_LOCAL
//################################################################################
function clearAssignedUsersLocal() {
  return { type: userConstants.CLEAR_ASSIGNED_USERS_LOCAL };
}

//################################################################################
// DELETE Project Role // Unassign User From Project
//################################################################################

function deleteProjectRole(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    // setTimeout(() => {
    generalServices.service("deleteProjectRole", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.userProject));
          dispatch(snackBarActions.snackBarSuccess("Unassign successfully!!"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    // }, 5000);
  };
  function success(userProject) {
    return {
      type: userConstants.DELETE_PROJECT_ROLE_SUCCESS,
      payload: userProject
    };
  }
  // function failure(error) {
  //   return { type: userConstants.DELETE_PROJECT_ROLE_FAILURE, payload: error };
  // }
}

//################################################################################
// SET Project Role
//################################################################################

function setProjectRole(data, page) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("setProjectRole", data, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.user, response.user_project));
          dispatch(snackBarActions.snackBarSuccess("Success!!"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(user, userProject) {
    const newUser = {
      _id: userProject._id,
      project: userProject.project,
      role: userProject.role,
      user: user
    };
    return { type: userConstants.SET_PROJECT_ROLE_SUCCESS, payload: newUser };
  }
  // function failure(error) {
  //   return { type: userConstants.SET_PROJECT_ROLE_FAILURE, payload: error };
  // }
}

//################################################################################
// verification for passwordChanging
//################################################################################

function verification(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("verification", data, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success) {
          dispatch(success());
          dispatch(snackBarActions.snackBarSuccess(response.msg));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(user) {
    window.location.href = "/";
    return { type: "" };
  }
  // function failure(error) {
  //   return { type: "" };
  // }
}

//################################################################################
// ressetPassword for passwordChanging
//################################################################################

function resetPassword(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("resetPassword", data, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success) {
          dispatch(success());
          dispatch(snackBarActions.snackBarSuccess(response.msg));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(user) {
    window.location.href = "/";
    return { type: "" };
  }
  // function failure(error) {
  //   return { type: "" };
  // }
}

//################################################################################
// GET User
//################################################################################

function getProfileGeneral() {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("profile", undefined, "general").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.user));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(user) {
    return { type: userConstants.GET_PROFILE_SUCCESS, payload: user };
  }
  // function failure(error) {
  //   return { type: userConstants.GET_PROFILE_FAILURE, payload: error };
  // }
}

//################################################################################
// EDIT User
//################################################################################

function editProfileGeneral(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    // setTimeout(() => {
    generalServices.service("editProfile", data, "general").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.user));
          dispatch(snackBarActions.snackBarSuccess("Successful"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    // }, 5000);
  };
  function success(data) {
    return { type: userConstants.EDIT_PROFILE_SUCCESS, payload: data };
  }
  // function failure(error) {
  //   return { type: userConstants.EDIT_PROFILE_FAILURE, payload: error };
  // }
}

//################################################################################
// Get my Role
//################################################################################

function getMyRole(projectId) {
  return dispatch => {
    dispatch(progressBarActions.start());
    dispatch(request());
    // setTimeout(() => {
    generalServices.service("myRole", projectId, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.userProject));
        } else {
          history.push("/user-dashboard");
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    // }, 5000);
  };
  function request() {
    return { type: userConstants.GET_MY_ROLE_REQUEST };
  }
  function success(userProject) {
    return { type: userConstants.GET_MY_ROLE_SUCCESS, payload: userProject };
  }
  // function failure(error) {
  //   return { type: userConstants.GET_MY_ROLE_FAILURE, payload: error };
  // }
}
