export const costConstants = {
  ADD_COST_SUCCESS: "ADD_COST_SUCCESS",
  ADD_COST_FAILURE: "ADD_COST_FAILURE",

  GET_PROJECT_COSTS_REQUEST: "GET_PROJECT_COSTS_REQUEST",
  GET_PROJECT_COSTS_SUCCESS: "GET_PROJECT_COSTS_SUCCESS",
  GET_PROJECT_COSTS_FAILURE: "GET_PROJECT_COSTS_FAILURE",

  EDIT_COST_SUCCESS: "EDIT_COST_SUCCESS",
  EDIT_COST_FAILURE: "EDIT_COST_FAILURE",

  APPROVE_COST_SUCCESS: "APPROVE_COST_SUCCESS",
  APPROVE_COST_FAILURE: "APPROVE_COST_FAILURE",

  DELETE_COST_SUCCESS: "DELETE_COST_SUCCESS",
  DELETE_COST_FAILURE: "DELETE_COST_FAILURE",

  CHANGE_PROJECT_COSTS_ORDER_SUCCESS: "CHANGE_PROJECT_COSTS_ORDER_SUCCESS",
  CHANGE_PROJECT_COSTS_ORDER_FAILURE: "CHANGE_PROJECT_COSTS_ORDER_FAILURE",

  ADD_ATTACHMENT_TO_COST_SUCCESS: "ADD_ATTACHMENT_TO_COST_SUCCESS",
  ADD_ATTACHMENT_TO_COST_FAILURE: "ADD_ATTACHMENT_TO_COST_FAILURE",

  GET_COST_TOTALS_SUCCESS: "GET_COST_TOTALS_SUCCESS",
  GET_COST_TOTALS_FAILURE: "GET_COST_TOTALS_FAILURE",

  CLEARING_COSTS_DATA: "CLEARING_COSTS_DATA"
};
