import React from "react";
import { ReactInput } from "../reactInput/reactInput";
import ThemeContext from "../ContextApi/Context";

// #####[ material ui ]#########################################################
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AttachFileIcon from "@material-ui/icons/AttachFile";

// #####[ redux ]###############################################################
import { connect } from "react-redux";
import { noteActions, filterActions } from "../../actions";
import { noteUserActions } from "../../userActions";

// #####[ colors ]##############################################################
import { Colors } from "../../styles";

// #############################################################################
// handle select file
// #############################################################################

class AddNoteInline extends React.PureComponent {
  static contextType = ThemeContext;

  state = {
    uploadPercentage: 0,
    file: "",
    text: "",
    noteActions: noteUserActions
  };

  componentDidMount() {
    this.setState({
      noteActions:
        this.props.role === "adminFlag" ? noteActions : noteUserActions
    });
  }

  componentDidUpdate(prevProps) {
    prevProps.role !== this.props.role &&
      this.setState({
        noteActions:
          this.props.role === "adminFlag" ? noteActions : noteUserActions
      });
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  // ###########################################################################
  // handle select file
  // ###########################################################################

  handleFileChoosen = ({ target }) => {
    target.files[0]
      ? this.setState({
        file: target.files[0]
      })
      : this.setState({
        file: ""
      });
  };

  // ###########################################################################
  // handle the percentage of the upload
  // ###########################################################################

  handleUploadPercentage = uploadedSize => {
    const uploadPercentage = (uploadedSize / this.state.file.size) * 100;
    this.setState({ uploadPercentage });

    if (uploadPercentage >= 100)
      this.setState({
        file: null,
        uploadPercentage: 0
      });
  };

  // ###########################################################################
  // handle submit
  // ###########################################################################

  handlefileSubmit = e => {
    const { file, text, noteActions } = this.state;
    const { dispatch } = this.props;
    const projectId = this.context;
    e.preventDefault();

    let document = new FormData();
    document.append("projectId", projectId);
    document.append("text", text);
    file && document.append("document", file);

    dispatch(
      noteActions.addNote(
        document
        // this.handleUploadPercentage
      )
    );
    this.setState({
      text: "",
      file: ""
    });

    dispatch(filterActions.pageFilterEdit(''))
  };

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const { uploadPercentage, text, file } = this.state;
    const { classes } = this.props;

    return (
      <Grid container className={classes.container}>
        <form
          onSubmit={this.handlefileSubmit}
          className={classes.AttachmentUploadFile}
        >
          <div
            className={classes.progressBar}
            style={{ width: `${uploadPercentage}%` }}
          ></div>
          <Grid container>
            <div className={classes.selectAddGrid}>
              {/* <textarea                                 className={classes.input}>
                                
                            </textarea> */}
              <ReactInput
                className={classes.input}
                placeholder="Write Your Journal"
                onChange={this.handleChange}
                value={text}
                name="text"
                required
                autoComplete="off"
              />
            </div>

            <div className={classes.uploadButtonContainer}>
              <label
                className={`${classes.chooseFile} ${classes.addAttachment}`}
              >
                {file && <AttachFileIcon className={classes.attachmentIcon} />}
                <span>ADD ATTACHMENT</span>
                <input type="file" onChange={this.handleFileChoosen} />
              </label>
              <button
                type="submit"
                className={`${classes.addNote}
                                            ${
                  !text
                    ? classes.disabledButton
                    : classes.uploadButton
                  }`}
                value="Upload"
                disabled={!text}
              >
                ADD JOURNAL
              </button>
            </div>
          </Grid>
        </form>
      </Grid>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = {
  container: {
    margin: "5px 0"
  },
  AttachmentUploadFile: {
    border: "1px solid #bdbdbd",
    padding: "5px",
    flex: 10,
    position: "relative",
    borderRadius: "3px"
  },
  progressBar: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "3px",
    boxSizing: "border-box",
    backgroundColor: "#068aea",
    zIndex: 0,
    transition: "width 100ms linear"
  },
  selectAddGrid: {
    flexGrow: 100,
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px",
    "& >div": {
      zIndex: 10
    }
  },

  "& input": {
    opacity: 0,
    position: "absolute",
    margin: "0",
    padding: "0",
    cursor: "pointer",
    width: "90px"
    // height: "42px",
  },
  uploadButtonContainer: {
    // width: "250px",
    marginLeft: "5px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  addNote: {
    width: "100px",
    height: "100%",
    color: "white",
    borderRadius: "5px",
    padding: "5px",
    margin: "0 4px",
    cursor: "pointer!important",
    transition: "300ms",
    border: "none",
    "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "13px",
    fontWeight: "400"
  },
  addAttachment: {
    width: "155px",
    height: "100%",
    fontSize: "14px",
    fontWeight: "500",
    color: "white",
    borderRadius: "5px",
    padding: "5px",
    margin: "0 4px",
    cursor: "pointer!important",
    transition: "300ms",
    border: "none"
  },
  disabledButton: {
    backgroundColor: Colors.darkGrey,
    width: "100px",
    color: "white",
    borderRadius: "5px",
    cursor: "default!important"
  },
  chooseFile: {
    backgroundColor: Colors.green,
    display: "flex!important",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    // fontSize: '10px',
    borderRadius: "5px",
    // fontWeight: '900',
    // flex: '1',
    // width: '100%',
    // height: '38px',
    boxSizing: "border-box",
    border: 0,
    cursor: "pointer",
    position: "relative",
    color: "white!important",
    "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "13px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: Colors.darkGreen
    },
    "& input": {
      opacity: 0,
      width: 0,
      height: 0,
      position: "absolute",
      top: 0,
      left: 0,
      cursor: "pointer"
    },
    "& > span": {
      width: "80%",
      height: "100%",
      zIndex: 100,
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    }
  },
  input: {
    flexGrow: 100,
    margin: "0 5px"
  },
  uploadButton: {
    backgroundColor: Colors.darkBlue,
    "&:hover": {
      backgroundColor: "#3F51B5"
    }
  },
  attachmentIcon: {
    color: "white",
    fontSize: "22px",
    transform: "rotate(45deg)"
  }
};

// #############################################################################
// connect store and styles to component
// #############################################################################

const mapStateToProps = state => {
  return {
    role: state.projects.project ? state.projects.project.role : undefined
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AddNoteInline));
