import React from "react";
import { ReactInput } from "../";
import Select from "react-select";
import chroma from "chroma-js";
import IconButton from "@material-ui/core/IconButton";

// #####[ MATERIAL ICONS ]######################################################
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Tooltip from "@material-ui/core/Tooltip";
// #####[ Button ]######################################################
import AddAttachmentsButtonWithDropdown from "../Buttons/AddAttachment/AddAttachmentsButtonWithDropdown";

// #####[ reusebleFunction ]##############################################################
import addingCommaToCost from "../reusebleFunctions/addingCommaToCost";

// #############################################################################
// select options
// #############################################################################

const costStatus = [
  { label: "needs review", value: "needs review", color: "#8ba59d" },
  { label: "Approved", value: "approved", color: "#0ad871" },
  { label: "denied", value: "denied", color: "#ec6a16" }
];

// #############################################################################
//
// #############################################################################

export class ListItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.subCosts = [];
    this.state = {
      openSub: false,
      editRow: false,
      disabledDoEditCost: false,
      openAddSubCost: false,
      dragChild: false,
      hasError: false,
      // list items
      name: "",
      note: "",
      budget: "",
      actual: "",
      revised: "",
      purchaseId: "",
      isInputChanged: false,
      openPurchaseIDModal: false,
      useTooltip: false,
      addAttachment: null,
      tags: null
    };
  }

  componentDidMount() {
    const { name, note, budget, actual, revised, purchaseId } = this.props.data;
    this.isEllipsisActive(this.refs.purchaseId);
    this.setState({
      name,
      note,
      budget,
      actual,
      revised,
      purchaseId
    });
  }

  componentDidUpdate(prevProps) {
    const { name, note, budget, actual, revised, purchaseId } = this.props.data;
    if (prevProps.data !== this.props.data) {
      this.isEllipsisActive(this.refs.purchaseId);
      this.setState({
        name,
        note,
        budget,
        actual,
        revised,
        purchaseId
      });
    }
  }

  isEllipsisActive = e => {
    this.setState({
      useTooltip: e.offsetWidth < e.scrollWidth
    });
  };

  // ###########################################################################
  //
  // ###########################################################################

  static getDerivedStateFromProps(props, state) {
    const { allCosts, data } = props;
    const { allCostsProps } = state;

    if (allCostsProps !== allCosts) {
      const allSubCosts = allCosts.filter(cost => {
        if (!cost.parentCost) {
          return false;
        } else {
          return cost.parentCost._id === data._id;
        }
      });

      return {
        allCostsProps: allCosts,
        allCosts,
        allSubCosts
      };
    } else return null;
  }

  // ###########################################################################
  //
  // ###########################################################################

  getSubCosts = (allCosts = [], costId) => {
    return allCosts.filter(cost => {
      if (!cost.parentCost) {
        return false;
      } else {
        return cost.parentCost._id === costId;
      }
    });
  };

  // ###########################################################################
  //
  // ###########################################################################

  toggleSubHandler = () => {
    if (this.state.openSub) {
      this.setState(() => ({
        openSub: false
      }));
    } else {
      this.setState(() => ({ openSub: true }));
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  findMyRoute = (cost, allCosts) => {
    if (cost.parentCost == null) {
      return "";
    } else {
      const parent = allCosts.find(c => c._id === cost.parentCost._id);
      return this.findMyRoute(parent, allCosts) + parent.name + " / ";
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleOpenAdd = () => {
    this.setState({
      openAddSubCost: true,
      name: "",
      note: "",
      budget: "",
      actual: "",
      revised: "",
      purchaseId: "",
      approvalStatus: { value: "needs review", label: "needs review" }
    });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleAdd = () => {
    const { handleAdd, data } = this.props;
    handleAdd({ value: data._id, label: data.name });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleDelete = () => {
    const { handleDeleteModal, data } = this.props;
    handleDeleteModal(data);
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleSelectChange = (option, event) => {
    this.setState(prevState => ({
      [event.name]: option,
      purchaseId: event.value !== "approved" ? "" : prevState.purchaseId,
      isInputChanged: true
    }));
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleApprove = (option, event) => {
    const {
      handleApprove,
      data,
      handleSetSelectedItemForPurchaseId,
      handleOpenPurchaseModal
    } = this.props;
    if (option.value.toLowerCase() === "approved") {
      handleSetSelectedItemForPurchaseId({
        id: data._id,
        approvalStatus: option.value
      });
      handleOpenPurchaseModal(true);
    } else {
      if (!this.state.editRow)
        handleApprove({ id: data._id, approvalStatus: option.value });
      this.setState({ [event.name]: option });
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleSendApprovedAndPurchaseID = () => {
    const { handleApprove, data } = this.props;
    if (!this.state.editRow) handleApprove(data._id, "approved");
    this.setState({ approvalStatus: { label: "Approved", value: "Approved" } });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleEditDoubleClick = () => {
    const { data } = this.props;
    const {
      approvalStatus,
      name = "",
      note = "",
      budget,
      actual,
      revised,
      purchaseId = ""
    } = data;
    if (!this.state.editRow)
      if (data.subCost.length > 0) {
        this.setState({
          editRow: true,
          disabledDoEditCost: true,
          approvalStatus: {
            label: this.upperCaseFirstLetter(approvalStatus),
            value: approvalStatus,
            color: this.definedSelectColor(approvalStatus)
          },
          name,
          note,
          budget,
          actual,
          revised,
          purchaseId
        });
      } else {
        this.setState({
          editRow: true,
          disabledDoEditCost: false,
          approvalStatus: {
            label: this.upperCaseFirstLetter(approvalStatus),
            value: approvalStatus,
            color: this.definedSelectColor(approvalStatus)
          },
          name,
          note,
          budget,
          actual,
          revised,
          purchaseId
        });
      }
  };

  // ###########################################################################
  // handle add Attachment
  // ###########################################################################

  handleBrowseFile = (addAttachment, tags) => {
    this.setState({
      addAttachment,
      tags
    });
  };
  handleCancelFile = () => {
    this.setState({
      addAttachment: null,
      tags: null
    });
  };

  // ###########################################################################
  // handle save changes
  // ###########################################################################

  handleSaveChange = e => {
    e.preventDefault();
    const {
      name,
      note,
      budget = 0,
      actual = 0,
      revised = 0,
      isInputChanged,
      addAttachment,
      purchaseId,
      tags
    } = this.state;
    const { data } = this.props;

    const tempBudget = budget !== "" ? budget : "0";
    const tempActual = actual !== "" ? actual : "0";
    const tempRevised = revised !== "" ? revised : "0";

    isInputChanged &&
      this.props.handleEditCostInline({
        name,
        note,
        budget: tempBudget,
        actual: tempActual,
        revised: tempRevised,
        purchaseId,
        costId: data._id
      });

    addAttachment &&
      this.props.handleAddAttachment(data._id, addAttachment, tags);

    this.setState({
      editRow: false,
      disabledDoEditCost: false,
      isInputChanged: false,
      addAttachment: null,
      tags: null
    });
  };

  // ###########################################################################
  //
  // ###########################################################################
  //
  handleChange = e => {
    const { name, value } = e.target;

    // if (type !== 'number')
    //   this.setState({ [name]: value })
    // else if (value.toString()[0] == 0 && value.toString()[1] != '.')

    this.setState({
      [name]: value,
      isInputChanged: true
    });
  };

  // ###########################################################################
  // handle add in line
  // ###########################################################################

  handleAddInlineCost = e => {
    e.preventDefault();
    const {
      name,
      note,
      budget,
      revised,
      actual,
      approvalStatus,
      purchaseId,
      addAttachment,
      tags
    } = this.state;
    const { data } = this.props;
    this.props.handleAddInlineCost({
      name,
      note,
      budget,
      revised,
      actual,
      addAttachment,
      tags,
      approvalStatus: approvalStatus.value,
      parentId: data._id,
      purchaseId: purchaseId === "" ? undefined : purchaseId
    });
    this.setState({ openAddSubCost: false });
  };

  handleCancelChange = () => {
    const { name, note, budget, revised, actual, purchaseId } = this.props;
    this.setState({
      editRow: false,
      name,
      note,
      budget,
      revised,
      actual,
      purchaseId: purchaseId === "" ? undefined : purchaseId
    });
  };

  // ###########################################################################
  // Drag And Drop
  // ###########################################################################
  onDragChild = () => {
    this.setState({ dragChild: true });
  };

  onDragStart = (e, index) => {
    const { dragChild } = this.state;
    const { allSubCosts } = this.state;
    this.draggedItem = allSubCosts[index];
    if (!dragChild) {
      e.dataTransfer.setData("text/html", e.target.id);
      e.target.style.opacity = "0.4";
    }
    this.props.onDragChild();
  };

  // ###########################################################################
  //
  // ###########################################################################

  onDragOver = (e, index) => {
    const { dragChild } = this.state;
    const { allSubCosts } = this.state;
    const draggedOverItem = allSubCosts[index];
    e.preventDefault();
    let onDragOverItems = allSubCosts.filter(item => item !== this.draggedItem);
    if (this.draggedItem === draggedOverItem) {
      return;
    }
    if (!dragChild && draggedOverItem !== undefined) {
      // add the dragged item after the dragged over item
      onDragOverItems.splice(index, 0, this.draggedItem);
      this.setState({ allSubCosts: onDragOverItems });
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  onDragEnd = (e, index) => {
    e.preventDefault();
    e.target.style.opacity = "1";
    this.draggedIdx = null;
    const { dispatchChangeCostOrder } = this.props;
    const { allSubCosts } = this.state;
    const draggedEndItem = allSubCosts[index];
    if (draggedEndItem !== undefined) {
      const costId = draggedEndItem._id;
      const projectId = draggedEndItem.project._id;
      e.target.style.border = "";
      dispatchChangeCostOrder(projectId, costId, index + 1);
      this.setState({ dragChild: false });
    }
  };

  // ###########################################################################
  // select styles
  // ###########################################################################

  selectStyles = () => ({
    placeholder: styles => ({ ...styles, ...this.dot() }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...this.dot(data.color),
      color: data.color
    }),
    input: styles => ({ ...styles, ...this.dot() }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        textAlign: "left",
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? color.alpha(0.5).css()
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default"
      };
    }
  });

  // ###########################################################################
  // the dot inside the select box
  // ###########################################################################

  dot = (color = "#ccc") => ({
    alignItems: "center",
    display: "flex"

    // ':before': {
    //   backgroundColor: color,
    //   borderRadius: 10,
    //   content: '" "',
    //   display: 'block',
    //   marginRight: 8,
    //   height: 10,
    //   width: 10,
    // },
  });

  // ###########################################################################
  // defined select color
  // ###########################################################################

  definedSelectColor = selectValue => {
    switch (selectValue) {
      case "needs review":
        return "#8ba59d";
      case "approved":
        return "#0ad871";
      case "denied":
        return "#ec6a16";
      default:
        return "#8ba59d";
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  upperCaseFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // ###########################################################################
  // Modal
  // ###########################################################################

  handleInfoIcon = clickedRowDataId => {
    this.props.handlerInfoModal(true);
    this.props.setClickedRowDataToState(clickedRowDataId);
  };

  // ###########################################################################
  // Render
  // ###########################################################################

  render() {
    const {
      userRole,
      data,
      approvable,
      subCount,
      openPurchaseIDModal,
      handleSetSelectedItemForPurchaseId
    } = this.props;
    const {
      allCosts,
      allSubCosts,
      openSub,
      approvalStatus,
      name,
      purchaseId
    } = this.state;
    const {
      note,
      budget,
      actual,
      revised,
      disabledDoEditCost,
      openAddSubCost,
      useTooltip
    } = this.state;
    let allSubCostsFiltered = allSubCosts.filter(cost => cost !== undefined);

    return (
      <React.Fragment>
        <form onSubmit={e => this.handleSaveChange(e)}>
          <div
            onDoubleClick={userRole ? this.handleEditDoubleClick : () => {}}
            style={
              subCount ? { margin: `-2px 0px 3px 0px` } : { margin: `2px 0px` }
            }
            className={"mplist-item"}
          >
            <div className="item-cost-table-name-item">
              <div
                style={
                  subCount !== undefined
                    ? { marginLeft: subCount * 10 + "px" }
                    : { marginLeft: "0px" }
                }
                className="item-cost-table-name-item-inside"
              >
                <div
                  className={
                    "item " +
                    (data.subCost.length > 0
                      ? openSub
                        ? "open"
                        : "close"
                      : "bullet") +
                    (data.subCost.length > 0 ? " clickable" : "")
                  }
                  onClick={
                    data.subCost.length > 0 ? this.toggleSubHandler : null
                  }
                />
                {this.state.editRow && userRole ? (
                  <div className="input-holder-name">
                    <ReactInput
                      type="text"
                      value={name}
                      name="name"
                      onChange={this.handleChange}
                      className="item-edit-cost-inLine"
                      required
                    />
                  </div>
                ) : (
                  <div
                    className="item-cost-table-name"
                    style={
                      data.parentCost === null
                        ? { fontWeight: "bold", color: "#575757" }
                        : {}
                    }
                  >{`${data.name} ${" "}`}</div>
                )}
                {this.state.editRow && userRole ? (
                  <div className="input-holder-note">
                    <ReactInput
                      type="text"
                      value={note}
                      name="note"
                      onChange={this.handleChange}
                      className="item-edit-cost-inLine"
                      placeholder="Note"
                    />
                  </div>
                ) : (
                  <div className="item-cost-table-item">{data.note}</div>
                )}
              </div>
            </div>
            <div className="item-cost-table-data-purchaseId">
              {this.state.editRow ? (
                <div className="input-holder-note">
                  <ReactInput
                    type="text"
                    value={purchaseId}
                    name="purchaseId"
                    onChange={this.handleChange}
                    className="item-edit-cost-inLine"
                    placeholder="purchase ID"
                  />
                </div>
              ) : (
                <Tooltip
                  title={data.purchaseId ? data.purchaseId : ""}
                  placement="top"
                  disableHoverListener={!useTooltip}
                >
                  <div
                    className="price-tag-purchaseId"
                    ref="purchaseId"
                    style={
                      data.parentCost === null
                        ? { fontWeight: "bold", color: "#575757" }
                        : {}
                    }
                  >
                    {data.purchaseId ? data.purchaseId : null}
                  </div>
                </Tooltip>
              )}
            </div>

            <div className="item-cost-table-data">
              <div
                className="price-tag"
                style={
                  data.parentCost === null
                    ? { fontWeight: "bold", color: "#575757" }
                    : {}
                }
              >
                {addingCommaToCost(data.budget)}
              </div>
            </div>

            <div className="item-cost-table-data">
              <div className={`item-value`}>
                {this.state.editRow && !data.subCost[0] && userRole ? (
                  <div className="input-holder-note">
                    <ReactInput
                      disabled={disabledDoEditCost}
                      type="number"
                      value={revised}
                      name="revised"
                      onChange={this.handleChange}
                      className="item-edit-cost-inLine"
                      placeholder="Revised"
                    />
                  </div>
                ) : (
                  <div
                    className={`price-tag ${
                      data.revised < 0
                        ? "red"
                        : data.revised === 0
                        ? ""
                        : "green"
                    }`}
                    style={
                      data.parentCost === null
                        ? { fontWeight: "bold", color: "#575757" }
                        : {}
                    }
                  >
                    {addingCommaToCost(data.revised)}
                  </div>
                )}
              </div>
            </div>

            <div className="item-cost-table-data">
              {this.state.editRow && !data.subCost[0] && userRole ? (
                <div className="input-holder">
                  <ReactInput
                    disabled={disabledDoEditCost}
                    type="number"
                    value={actual}
                    name="actual"
                    onChange={this.handleChange}
                    className="item-edit-cost-inLine"
                    placeholder={"actual"}
                  />
                </div>
              ) : (
                <div
                  className="price-tag"
                  style={
                    data.parentCost === null
                      ? { fontWeight: "bold", color: "#575757" }
                      : {}
                  }
                >
                  {addingCommaToCost(data.actual)}
                </div>
              )}
            </div>
            <div className=" item-edit-add-delet-button">
              {this.state.editRow ? (
                <></>
              ) : (data.subCost && data.subCost.length > 0) || !approvable ? (
                <React.Fragment>
                  <div
                    style={{
                      backgroundColor: this.definedSelectColor(
                        data.approvalStatus
                      )
                    }}
                    className="approval-status-dot"
                  />

                  <div
                    style={{
                      color: this.definedSelectColor(data.approvalStatus),
                      width: "100px",
                      height: "34px",
                      display: "flex",
                      alignItems: "center",
                      ...this.dot(this.definedSelectColor(data.approvalStatus))
                    }}
                  >
                    {this.upperCaseFirstLetter(data.approvalStatus)}
                  </div>
                </React.Fragment>
              ) : (
                <Select
                  options={costStatus}
                  name={"approvalStatus"}
                  styles={this.selectStyles()}
                  onChange={this.handleApprove}
                  className="item-status-select-inline-edit"
                  required
                  placeholder="Cost Status"
                  defaultValue="null"
                  singleValue
                  value={{
                    value: data.approvalStatus,
                    label: this.upperCaseFirstLetter(data.approvalStatus),
                    color: this.definedSelectColor(data.approvalStatus)
                  }}
                  maxMenuHeight={170}
                  style={{ width: "20px!important" }}
                />
              )}
              {this.state.editRow && userRole ? (
                <div className={"save-edit-buttons-main-div"}>
                  {/*ADD*/}
                  <AddAttachmentsButtonWithDropdown
                    handleBrowseFile={this.handleBrowseFile}
                    handleCancelFile={this.handleCancelFile}
                  />
                  {/*SAVE*/}
                  <button
                    type="submit"
                    className="item-edit-cost-save-button-inLine"
                  >
                    SAVE
                  </button>
                  {/*CANCEL*/}
                  <button
                    className="item-edit-cost-cancel-button-inLine"
                    onClick={this.handleCancelChange}
                  >
                    CANCEL
                  </button>
                </div>
              ) : (
                <div className="edit-delete-buttons-holder">
                  {userRole && (
                    <>
                      {data.hasAttachment ? (
                        <Tooltip title="Attachment">
                          <IconButton
                            onClick={() => this.handleInfoIcon(data._id)}
                            className="cost-attachment-Icon"
                          >
                            <AttachFileIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <AttachFileIcon className="cost-attachment-Icon-disable" />
                      )}
                      <Tooltip title="Edit Cost">
                        <IconButton
                          onClick={this.handleEditDoubleClick}
                          className="cost-list-edit"
                          component="span"
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Cost">
                        <IconButton
                          onClick={this.handleDelete}
                          component="span"
                          className="cost-list-delete"
                        >
                          <CancelOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Add New Sub Cost">
                    <IconButton
                      onClick={this.handleOpenAdd}
                      component="span"
                      className="cost-list-add"
                    >
                      <AddCircleOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="show info">
                    <IconButton
                      onClick={() => this.handleInfoIcon(data._id)}
                      component="span"
                      className="cost-list-info"
                    >
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* ################################################################ */}
        {/* this form below will add new sub cost the the selected cost */}
        {openAddSubCost && (
          <form onSubmit={this.handleAddInlineCost}>
            <div
              style={
                subCount > 0
                  ? { margin: `3px 5px 9px ${subCount * 10 + 5}px` }
                  : {}
              }
              className={"mplist-edit-inline-item"}
            >
              <div className={"mplist-edit-inline-item-sub-div"}>
                <div className="input-holder">
                  <ReactInput
                    type="text"
                    value={name}
                    name="name"
                    onChange={this.handleChange}
                    className="item-edit-cost-inLine"
                    placeholder="Cost Name"
                    required
                  />
                </div>
                {data.role && <span className="item-role">{data.role}</span>}
                <div className="input-holder">
                  <ReactInput
                    type="note"
                    value={note}
                    name="note"
                    onChange={this.handleChange}
                    className="item-edit-cost-inLine"
                    placeholder="Item Note"
                  />
                </div>
                <div className="input-holder">
                  <ReactInput
                    disabled={disabledDoEditCost}
                    type="number"
                    value={budget}
                    name="budget"
                    onChange={this.handleChange}
                    className="item-edit-cost-inLine"
                    placeholder="Budget"
                    required
                  />
                </div>
                <div className="input-holder">
                  <ReactInput
                    // disabled={disabledDoEditCost}
                    type="number"
                    value={revised}
                    name="revised"
                    onChange={this.handleChange}
                    className="item-edit-cost-inLine"
                    placeholder="Revised"
                    disabled
                  />
                </div>
                <div className="input-holder">
                  <ReactInput
                    disabled={disabledDoEditCost}
                    type="number"
                    value={actual}
                    name="actual"
                    onChange={this.handleChange}
                    className="item-edit-cost-inLine"
                    placeholder="Actual"
                  />
                </div>
                {userRole && (
                  <>
                    <div className="item-status-select-wrapper">
                      <Select
                        options={costStatus}
                        name={"approvalStatus"}
                        styles={this.selectStyles()}
                        onChange={this.handleSelectChange}
                        className="item-status-select-in-line-add"
                        required
                        placeholder="Cost Status"
                        defaultValue="null"
                        singleValue
                        value={approvalStatus}
                        maxMenuHeight={170}
                      />
                    </div>
                    <div className="item-status-select-wrapper">
                      <ReactInput
                        name="purchaseId"
                        value={purchaseId}
                        onChange={this.handleChange}
                        placeholder="Purchase ID"
                        variant="outlined"
                        autoComplete="off"
                        // type="number"
                        className="purchase-id-inputs"
                        disabled={
                          !approvalStatus || approvalStatus.value !== "approved"
                        }
                        type="text"
                      />
                    </div>
                  </>
                )}

                <div className={"edit-buttons-main-div"}>
                  {/*ADD*/}
                  <AddAttachmentsButtonWithDropdown
                    handleBrowseFile={this.handleBrowseFile}
                    handleCancelFile={this.handleCancelFile}
                  />
                  {/*SAVE*/}
                  <button
                    type="submit"
                    className="item-edit-cost-save-button-inLine"
                  >
                    SAVE
                  </button>
                  {/*CANCEL*/}
                  <button
                    className="item-edit-cost-cancel-button-inLine"
                    onClick={() => {
                      this.setState({ openAddSubCost: false });
                    }}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
        {openSub && data.subCost && (
          <React.Fragment>
            {allSubCostsFiltered.map((cost, index) => {
              return (
                <div
                  className="drag"
                  draggable={userRole}
                  onDragStart={e => this.onDragStart(e, index)}
                  onDragOver={e => this.onDragOver(e, index)}
                  onDragEnd={e => this.onDragEnd(e, index)}
                  key={index}
                  id={cost._id}
                >
                  <ListItem
                    subCount={subCount !== undefined ? subCount + 1 : 1}
                    id={cost._id}
                    data={cost}
                    allCosts={allCosts}
                    handleAdd={this.props.handleAdd}
                    handleEditCostInline={this.props.handleEditCostInline}
                    handleAddInlineCost={this.props.handleAddInlineCost}
                    handleApprove={this.props.handleApprove}
                    handleDeleteModal={this.props.handleDeleteModal}
                    handlerInfoModal={this.props.handlerInfoModal}
                    handleAddAttachment={this.props.handleAddAttachment}
                    setClickedRowDataToState={
                      this.props.setClickedRowDataToState
                    }
                    approvable={userRole}
                    userRole={userRole}
                    onDragChild={this.onDragChild}
                    dispatchChangeCostOrder={this.props.dispatchChangeCostOrder}
                    handlePurchaseIDModal={this.props.handlePurchaseIDModal}
                    handleOpenPurchaseModal={this.props.handleOpenPurchaseModal}
                    handleSetSelectedItemForPurchaseId={
                      handleSetSelectedItemForPurchaseId
                    }
                    openPurchaseIDModal={openPurchaseIDModal}
                  />
                </div>
              );
            })}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
