export const attachemntConstants = {
  GET_ATTACHMENTS_SUCCESS: "GET_ATTACHMENTS_SUCCESS",
  GET_ATTACHMENTS_FAILURE: "GET_ATTACHMENTS_FAILURE",

  ADD_ATTACHMENT_SUCCESS: "ADD_ATTACHMENT_SUCCESS",
  ADD_ATTACHMENT_FAILURE: "ADD_ATTACHMENT_FAILURE",

  ADD_FOLDER_SUCCESS: "ADD_FOLDER_SUCCESS",
  ADD_FOLDER_FAILURE: "ADD_FOLDER_FAILURE",

  EDIT_ATTACHMENT_SUCCESS: "EDIT_ATTACHMENT_SUCCESS",
  EDIT_ATTACHMENT_FAILURE: "EDIT_ATTACHMENT_FAILURE",

  PROJECT_DOCUMENT_TAB_EDIT_SUCCESS: "PROJECT_DOCUMENT_TAB_EDIT_SUCCESS",
  PROJECT_DOCUMENT_TAB_EDIT_FAILURE: "PROJECT_DOCUMENT_TAB_EDIT_FAILURE",

  DELETE_ATTACHMENT_SUCCESS: "DELETE_ATTACHMENT_SUCCESS",
  DELETE_ATTACHMENT_FAILURE: "DELETE_ATTACHMENT_FAILURE",

  CHANGE_ATTACHMENT_ORDER_SUCCESS: "CHANGE_ATTACHMENT_ORDER_SUCCESS",
  CHANGE_ATTACHMENT_ORDER_FAILURE: "CHANGE_ATTACHMENT_ORDER_FAILURE",

  PROJECT_DOCUMENT_TAB_EDIT_KEEP_BOTH_SUCCESS:
    "PROJECT_DOCUMENT_TAB_EDIT_KEEP_BOTH_SUCCESS",

  PROJECT_DOCUMENT_TAB_EDIT_REPLACE_OLD_FILE_SUCCESS:
    "PROJECT_DOCUMENT_TAB_EDIT_REPLACE_OLD_FILE_SUCCESS",

  CLEARING_DOCUMENTS_DATA: "CLEARING_DOCUMENTS_DATA"
};
