import React from "react";
import { withStyles } from "@material-ui/core";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Colors } from "../../styles/colors";

const styles = {
  iconButton: {
    padding: "3px !important",
    color: Colors.lightGreen
  },
  AddCircleOutlined: {
    color: "#06BFBA"
  }
};

export const AddCircleOutlinedIcon = React.memo(
  withStyles(styles)(({ classes, onClick, tooltip = "Add Subproject" }) => (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={onClick}
        className={classes.iconButton}
        component="span"
      >
        <AddCircleOutline className={classes.AddCircleOutlined} />
      </IconButton>
    </Tooltip>
  ))
);
