import React from 'react'
import { withStyles } from '@material-ui/core'
import AddBox from '@material-ui/icons/AddBox'
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../styles/colors'

const styles = {
    iconButton: {
        padding: '3px !important',
        color: Colors.lightGreen,
    }
}

export const PlusFilledIcon = React.memo(withStyles(styles)(({ classes, onClick, tooltip = 'Open', color = 'gray' }) => (
    <Tooltip title={tooltip}>
        <IconButton onClick={onClick} className={classes.iconButton} component="span">
            <AddBox style={{ color }} />
        </IconButton>
    </Tooltip>
))
)
