import { history } from "../helpers";

export function handleRouting(user) {
  switch (user.role) {
    case "admin":
      history.push("/admin-dashboard");
      break;
    case "user":
      history.push("/user-dashboard");
      break;
    default:
      history.push("/");
      break;
  }
}
