import React from "react";

// #####[ material ui ]##############################################################
import { withStyles } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Person from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
// #####[ redux ]##############################################################
import { connect } from "react-redux";
import { API } from "../../constants";
import { attachmentActions } from "../../actions";
import { attachmentUserActions } from "../../userActions";
// #####[ reusable functions ]##############################################################
import pastTimeSoFar from "../reusebleFunctions/pastTimeSoFar";

// #####[ styles ]##############################################################
import { Colors } from "../../styles";

// #####[ components ]##############################################################
import { DeleteIcon, EditIcon } from "../Icons";
import DeleteModal from "../Modal/DeleteModal";
import { ReactInput } from "../";
import { SaveButton, CancelButton } from "../Buttons";
class AttachmentModalTab extends React.PureComponent {
  state = {
    selectedOptionForDelete: {},
    openDeleteModal: false,
    editMode: false,
    attachmentNote: ""
  };

  componentDidMount() {
    const { role, projectId, clickedRowDataId, dispatch } = this.props;
    role === "adminFlag"
      ? dispatch(
          attachmentActions.getAttachments({
            projectId,
            issueTaskId: clickedRowDataId
          })
        )
      : dispatch(
          attachmentUserActions.getAttachments({
            projectId: projectId,
            issueTaskId: clickedRowDataId
          })
        );
  }

  // componentDidUpdate(prevProps) {
  // }

  handleDownload = (link, e) => {
    e.preventDefault();
    window.open(link);
  };

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
          openDeleteModal: true,
          selectedOptionForDelete
        })
      : this.setState({
          openDeleteModal: false
        });
  };

  handleEditMode = attachmentNote => {
    this.setState({
      editMode: true,
      attachmentNote
    });
  };

  handleCancel = () => {
    this.setState({
      attachmentNote: "",
      editMode: false
    });
  };

  handleSubmitEdits = attachmentId => {
    const { projectId, role, dispatch } = this.props;
    const { attachmentNote } = this.state;
    role === "adminFlag"
      ? dispatch(
          attachmentActions.editAttachment({
            projectId,
            attachmentId,
            attachmentNote
          })
        )
      : dispatch(
          attachmentUserActions.editAttachment({
            projectId,
            attachmentId,
            attachmentNote
          })
        );
    this.setState({
      editMode: false
    });
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const { classes, attachments, role } = this.props;
    const { editMode, attachmentNote } = this.state;
    return (
      <>
        <article className={classes.subContainerAttachmentList}>
          {attachments
            ? attachments.map((attachment, index) => (
                <div className={classes.attachmentItem} key={index}>
                  <div className={classes.attachmentItemDataWrapper}>
                    <div
                      className={
                        classes.attachmentItemDataWrapperUserAndAttachment
                      }
                    >
                      <Person className={classes.attachmentItemUserIcon} />
                      <span>
                        <span>
                          {attachment.sender.firstName}{" "}
                          {attachment.sender.lastName}
                        </span>
                        Added {attachment.name}
                      </span>
                    </div>
                    {editMode ? (
                      <div className={classes.attachmentNoteWrapper}>
                        <ReactInput
                          name="attachmentNote"
                          value={attachmentNote}
                          onChange={this.handleChange}
                          placeholder="Attachment Note"
                          className={classes.input}
                          rows={1}
                        />
                      </div>
                    ) : (
                      <div className={classes.attachmentNote}>
                        {attachment.attachmentNote}
                      </div>
                    )}
                  </div>
                  {editMode ? (
                    <>
                      <SaveButton
                        onClick={() => this.handleSubmitEdits(attachment._id)}
                      />
                      <CancelButton onClick={this.handleCancel} />
                    </>
                  ) : (
                    <div>
                      {role !== "Project-Client" && (
                        <>
                          <EditIcon
                            onClick={() =>
                              this.handleEditMode(attachment.attachmentNote)
                            }
                          />
                          <DeleteIcon
                            onClick={() => this.handleDeleteModal(attachment)}
                          />
                        </>
                      )}
                      <div className={classes.uploadTime}>
                        <span>{pastTimeSoFar(attachment.createDate)}</span>
                      </div>
                      <IconButton
                        className={classes.iconButton}
                        onClick={e =>
                          this.handleDownload(`${API}/${attachment.link}`, e)
                        }
                      >
                        <a href={attachment.link} className={classes.link}>
                          <ArrowDownward
                            className={classes.attachmentItemDownloadIcon}
                          />
                        </a>
                      </IconButton>
                    </div>
                  )}
                </div>
              ))
            : null}
        </article>
        <DeleteModal
          open={this.state.openDeleteModal}
          handleDeleteModal={this.handleDeleteModal}
          selectedOptionForDelete={{
            attachmentId: this.state.selectedOptionForDelete._id
          }}
          adminAction={attachmentActions.deleteAttachment}
          userAction={attachmentUserActions.deleteAttachment}
          //optional
          modalTitle={"Delete Attachment"}
        />
      </>
    );
  }
}

const styles = {
  subContainerAttachmentList: {
    overflowY: "overlay",
    flexGrow: 100,
    maxHeight: "600px",
    padding: "0 10px"
  },
  attachmentItem: {
    backgroundColor: "white",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "row",
    padding: "10px 13px",
    marginBottom: "7px",
    alignItems: "center",

    "& > div:nth-child(2)": {
      display: "flex",
      alignItems: "center"
    }
  },
  attachmentItemDataWrapper: {
    flexGrow: 100
  },

  attachmentItemDataWrapperUserAndAttachment: {
    display: "flex",
    alignItems: "flex-start",
    margin: "2px 0",
    "& span": {
      fontSize: "12px"
    },
    "& span:nth-child(2)": {
      color: Colors.grey,
      display: "block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      hyphens: "auto"
    },
    "& span:nth-child(2) > span": {
      color: Colors.darkGreen,
      marginRight: "4px",
      fontWeight: "bold"
    }
  },
  attachmentItemUserIcon: {
    color: Colors.grey,
    fontSize: "16px",
    height: "100%",
    marginRight: "4px"
  },

  attachmentItemDataWrapperTags: {
    margin: "2px 0",
    fontSize: "11px",
    color: Colors.darkBlue,
    fontWeight: "bold",
    display: "flex"
  },

  tagsIcon: {
    fontSize: "14px",
    height: "100%",
    marginRight: "5px",
    color: Colors.darkBlue
  },

  uploadTime: {
    minWidth: "90px",
    "& span": {
      display: "flex",
      fontWeight: "normal",
      alignItems: "center",
      margin: "0 7px",
      fontSize: "10px",
      color: Colors.grey
    }
  },

  attachmentItemDownloadIcon: {
    color: Colors.lightGreen,
    fontSize: "24px"
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  iconButton: {
    padding: "3px!important"
  },
  input: {
    fontSize: "13px",
    marginLeft: "20px",
    height: "25px",
    width: "400px"
  },
  attachmentNote: {
    margin: " 0 20px",
    fontSize: "13px",
    color: "darkgrey",
    lineHeight: 1
  }
};

const mapStateToProps = state => {
  return {
    projectId: state.projects.project._id,
    role: state.projects.project.role,
    attachments: state.attachments
  };
};

const tempTemp = connect(mapStateToProps)(
  withStyles(styles)(AttachmentModalTab)
);

export { tempTemp as AttachmentModalTab };
