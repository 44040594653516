import React, { PureComponent } from 'react';
import { withStyles } from "@material-ui/core"
import { Select, ReactInput } from "../"
import { Button } from "../";
import { addWorkDays, addDays, removeWeekends } from "../helpers";
import DatePicker from "../../DatePicker/DatePicker";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


// #############################################################################
// component
// #############################################################################

class EditTaskForm extends PureComponent {
  constructor(props) {
    let dependencies = [];
    super(props);
    const priority = this.decoratedPriorities().filter(item => props.task.priority === item.value)[0];
    const status = this.decoratedStatuses().filter(item => props.task.status === item.value)[0];

    if(props.task.dependencies) dependencies = this.generateListOfLabels(props.task.dependencies, props.tasks);
    this.state = {
      id: props.task._id,
      priority,
      status,
      name: props.task.name,
      dependencies,
      complete: props.task.complete,
      completed: props.task.completed,
      description: props.task.description,
      duration: removeWeekends(props.task.actualStartDate, props.task.actualFinishDate)[1],
      actualStartDate: new Date(props.task.actualStartDate),
      actualFinishDate: new Date(props.task.actualFinishDate),
      plannedStartDate: new Date(props.task.plannedStartDate),
      plannedFinishDate: new Date(props.task.plannedFinishDate),
    };
  }

  // ###########################################################################
  // handle changes in the input
  // ###########################################################################

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value});
  }

  // ###########################################################################
  // this function will handle the start date and finish date considering duration
  // ###########################################################################

  handleActualStartDate = (date) => {
    const { duration = 1 } = this.state;
    this.setState({
      actualStartDate: date,
      actualFinishDate: addWorkDays(date, duration),
    }); 
  }

  // ###########################################################################
  // handle check box
  // ###########################################################################

  handleCheckBox = name => event => {
    this.setState({ [name]: event.target.checked });
    !event.target.checked && this.setState({complete: 99})
  }

  // ###########################################################################
  // this function will handle the change in the dependencies 
  // ###########################################################################

  handleChangeDependencies = event => {
    const { tasks = []} = this.props;
    this.setState({ [event.target.name]: event.target.value});
    const selectedItemWithLag = event.target.value.find(item => item.lag !== undefined);
    const task = selectedItemWithLag ? tasks.find(item => item._id === selectedItemWithLag.value) : undefined;
    if(task) this.handleChangePlannedStartDate(addDays(new Date(task.actualFinishDate), selectedItemWithLag.lag + 1))
  }

  
  handleChangePlannedStartDate = startDate => {
    const finishDate = addWorkDays(startDate, this.state.duration);

    this.setState({
      plannedStartDate: startDate,
      actualStartDate: startDate,
      plannedFinishDate: finishDate,
      actualFinishDate: finishDate,
    });
  }

  // ###########################################################################
  // handle changes in the input
  // ###########################################################################

  handleChangeStartDate = date => {
    this.setState({
      actualStartDate: date,
      actualFinishDate: addWorkDays(date, this.state.duration),
    });
  }

  // ###########################################################################
  // handle changes in the input
  // ###########################################################################

  calculateDuration = (startDate , finishDate) => {
    const diffTime = Math.abs(new Date(finishDate) - new Date(startDate));
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  }

  // ###########################################################################
  // handle changes in the input
  // ###########################################################################

  handleChangeDuration = event => {
    const rawDuration = parseInt(event.target.value, 10)
    const duration = !rawDuration ? 0 : rawDuration > 10000 ? 10000 : rawDuration ;
    const today = new Date(new Date().setHours(0,0,0,0));
    const { plannedStartDate, actualStartDate } = this.state;

    const finishDate = plannedStartDate ?
      addWorkDays(plannedStartDate, duration) :
      addWorkDays(today ,duration);

    this.setState({
      duration: duration === 0 ? "" : duration,
      actualStartDate: actualStartDate || today,
      actualFinishDate:  finishDate,
    });
  }

  // ###########################################################################
  // generate list of labels
  // ###########################################################################

  generateListOfLabels = (array = []) => {
    return array.map(item => ({label: item.name , value: item._id}));
  }

  // ###########################################################################
  // handle changes in the select inputs
  // ###########################################################################

  handleSelectChange = (option, event) => {
    this.setState({ [event.name]: option });
  };

  // ###########################################################################
  // Decorate Priority
  // ###########################################################################

  decoratedPriorities = () => ([
    { value: "A", label: "LEVEL-1" },
    { value: "B", label: "LEVEL-2" },
    { value: "C", label: "LEVEL-3" },
    { value: "D", label: "LEVEL-4" },
    { value: "E", label: "LEVEL-5" }
  ]);

  // ###########################################################################
  // list of all the statuses for the status select in task form
  // ###########################################################################

  decoratedStatuses = () => ([
    {value:'G',label:'GREEN'},
    {value:'O',label:'ORANGE'},
    {value:'Y',label:'YELLOW'},
    {value:'R',label:'REG'}
  ]);

  // ###########################################################################
  // this will handle the edit
  // ###########################################################################

  handleSaveEdit = even => {
    even.preventDefault()
    const {id, name, description} = this.state;
    const data = {
     actualStartDate: this.state.actualStartDate,
     actualFinishDate: this.state.actualFinishDate,
     priority: this.state.priority.value,
     status:  this.state.status.value,
     completed: this.state.completed,
     complete: this.state.complete,
     dependencies: this.exportDependenciesValue(this.state.dependencies),
     description,
     name,
    }
    
    this.props.handleEditTask({id, ...this.filterUnedited(data)})
    this.props.toggleEditForm();
  }

  // ###########################################################################
  // this function will filter the edited values from unedited values
  // ###########################################################################

  filterUnedited = (data) => {
    const keys = Object.keys(data);
    const { task } = this.props;
    let object = {};
    keys.forEach(key => { 
      if(data[key] !== task[key]){

        object[key] = data[key];
        if(key ===  "actualStartDate")
          object.actualFinishDate = data.actualFinishDate;
        if(key ===  "plannedStartDate")
          object.plannedFinishDate = data.plannedFinishDate;
        if(key ===  "actualFinishDate")
          object.actualStartDate = data.actualStartDate;
        if(key ===  "plannedFinishDate")
          object.plannedStartDate = data.plannedStartDate;
      }
    });
    return object;
  }

  // ###########################################################################
  // generate list of dependencies for select component
  // ###########################################################################

  decorateDependencies = (inputArray = []) => {
    const { _id } = this.props.task;
    const parents = this.findParent(this.props.task.parentTask, this.props.tasks);
    const array = [];
    inputArray.forEach(item => { if(item._id !== _id && !this.isAncestor(item._id, parents)) array.push({value: item._id, label: item.name}); })
    return array;
  }

  // ###########################################################################
  // find all the parent
  // ###########################################################################


  findParent = (parent, tasks, parents = []) => {
    tasks.forEach(task => {
      if(task._id === parent){
        parents.push(task._id)
        if(task.parentTask) this.findParent(task.parentTask, tasks, parents);
      }
    });
    return parents;
  }

  // ###########################################################################
  // is it one of Ancestors
  // ###########################################################################

  isAncestor = (taskId, parents = []) => {
    let isAncestor = false;
    parents.forEach(parent => { isAncestor = parent === taskId || isAncestor });
    return isAncestor;
  }

  // ###########################################################################
  // this function will create a array of tasks ids
  // ###########################################################################

  exportDependenciesValue = (array = {}) => {
    return array.map(item =>  { return item.value });
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render() {

    const { name, description, dependencies, duration } = this.state;
    const { actualStartDate, actualFinishDate } = this.state;
    const { classes, showEditForm = false , tasks = {} } = this.props;
    const hasChild = this.props.task.subTask.length > 0;

    return (
      <form id="TASK-INLINE-FORM" onSubmit={this.handleSaveEdit} className={`${classes.root} ${!showEditForm ? classes.hide : null}`}>
        <div className={classes.row}>
          <div className={`${classes.inputHolder} ${!showEditForm ? classes.hideInputs : null}`}>
            <ReactInput
              required
              type="text"
              onChange={this.handleChange}
              className={classes.input}
              name="name"
              value={name}
            />
          </div>

          <div className={`${classes.inputHolder} ${classes.checkboxHolder} ${!showEditForm ? classes.hideInputs : null}`}>
            <FormControlLabel
              classes={{ root: classes.checkoutLabel }}
              control={
                <Checkbox
                  checked={this.state.completed}
                  classes={{ root: classes.checkBox }}
                  onChange={this.handleCheckBox('completed')}
                  value="completed"
                  color="secondary"
                />
              }
              label="COMPLETED"
            />
          </div>
          <div type="" className={`${classes.buttonHolder} ${!showEditForm ? classes.hideInputs : null}`}>
            <Button type="submit" onClick={ this.handleSaveEdit} className={classes.button} variant="success">SAVE</Button>
          </div>
        </div>
        { hasChild ? null :
          <div className={classes.row}>
            <div className={`${classes.inputHolder} ${!showEditForm ? classes.hideInputs : null}`}>

              <DatePicker
                required
                onChange={this.handleActualStartDate}
                name="actualStartDate"
                selected={actualStartDate}
                placeholderText="ACTUAL START DATE"
                className={classes.datePicker}
                autoComplete="off"
              />
            </div>

            <div className={`${classes.inputHolder} ${classes.durationHolder}`}>
              <ReactInput
                required
                type="number"
                onChange={this.handleChangeDuration}
                className={classes.input}
                min="0"
                max="10000"
                name="duration"
                placeholder="DURATION"
                value={duration}
              />
            </div>

            <div className={`${classes.inputHolder} ${!showEditForm ? classes.hideInputs : null}`}>

              <DatePicker
                required
                onChange={(date) => this.setState({actualFinishDate: date})}
                name="actualFinishDate"
                selected={actualFinishDate}
                placeholder="ACTUAL FINISH DATE"
                className={classes.datePicker}
                autoComplete="off"
              />
            </div>
            <div className={`${classes.buttonHolder} ${!showEditForm ? classes.hideInputs : null}`}>
              <Button type="button" onClick={() => {this.props.toggleEditForm(false)}} className={classes.button} variant="secondary">CANCEL</Button>
            </div> 
          </div>
        }
        <div className={classes.row}>
          <div className={`${classes.inputHolder} ${!showEditForm ? classes.hideInputs : null}`}>
            <Select
              isMulti
              withLag={!hasChild}
              required
              name="dependencies"
              value={dependencies}
              onChange={this.handleChangeDependencies}
              placeholder="SELECT TASK AS DEPENDENCIES"
              options={this.decorateDependencies(tasks)}
            />
          </div>

          { !hasChild ? null :
            <div className={`${classes.buttonHolder} ${!showEditForm ? classes.hideInputs : null}`}>
              <Button type="button" onClick={() => {this.props.toggleEditForm(false)}} className={classes.button} variant="secondary">CANCEL</Button>
            </div>
          }

        </div>
        <div className={classes.row}>
          <div className={`${classes.inputHolder} ${!showEditForm ? classes.hideInputs : null}`}>
            <textarea
              required
              type="text"
              onChange={this.handleChange}
              className={classes.textarea}
              name="description"
              placeholder="DESCRIPTION"
              value={description}
            />
          </div>
        </div>
      </form>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    width: "calc(100% - 12px)",
    padding: "10px 0px",
    position: "sticky",
    margin: "3px 6px",
    background: "#f8fbfb",
    border:"1px solid #00000020",
    zIndex: 15,
    left: 6,
    boxSizing: "border-box",
    transition: "opacity 50ms, padding 100ms, margin-top 300ms, margin-bottom 300ms",
    transitionTimingFunction:  "cubic-bezier(.26,.94,.62,.96)",
    boxShadow:"0 2px 17px 8px #00000024",
    opacity: 1,
    borderRadius:4,
  },
  hide:{
    margin: 0,
    height: 0,
    border: 0,
    padding: 0,
    opacity: 0,
    "& > div": {
      display: "none"  
    }
  },
  row:{
    display: "flex",
    width: "calc(100% - 20px)",
    padding: "0px 10px",

  },
  checkoutLabel: {
    marginLeft: "0px !important"
  },
  checkBox: {
    padding: "5px"
  },
  checkboxHolder:{
    flex: "0 !important"

  },
  // ###########################################################################
  inputHolder:{
    height: "fit-content",
    padding: " 2px 2px",
    flex: 1,
  },
  durationHolder:{
    height: "fit-content",
    padding: " 2px 2px",
    flex: 0.5,
  },
  textAreaHolder:{
    height: 34,
    padding: " 2px 2px",
    flex: 2.5,
  },
  hideInputs:{
    display: "none"
  },
  // ###########################################################################
  input:{
    height: 34,
    fontSize: 12,
    fontWeight: 500,
    color: "#000919b5",
    width: "100%"
  },
  textarea:{
    minHeight: 34,
    height: 34,
    fontSize: 12,
    fontWeight: 500,
    color: "#000919b5",
    width: "100%",
    resize: "none !important",
  },
  buttonHolder:{
    height: "fit-content",
    padding: " 2px 2px",
  },
  // ###########################################################################
  button:{
    width: 100
  },

  // ###########################################################################
  // date picker
  datePicker: {
    borderRadius: 4,
    boxSizing: "border-box",
    border: "1px solid #C7C7C7",
    flex: 1,
    width: "100%",
    height: 34,
    padding: "2px 10px",
    fontSize: 12,
    color: "#000919b5",
    fontWeight: 500,
  }
});

// #############################################################################
// connect material styles and redux state to component
// #############################################################################
const EditTaskFormWithStyles = withStyles(styles)(EditTaskForm)


// #############################################################################
// export
// #############################################################################

export  { EditTaskFormWithStyles as EditTaskForm };

