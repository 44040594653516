import React from "react";
import { withStyles } from "@material-ui/core";
//############################################################################
// styles
//############################################################################
const styles = theme => ({
  Button: {
    "&.success":{
      background: "#58b370",
    },
    "&:hover.success":{
      background: "#4da263",
    },
    "&:active.success":{
      background: "#4cab65",
    },
     "&:focus.success":{
      background: "#58b370",
      boxShadow: "0 0 0px 3px #29ef8e80"
    },

    "&.danger":{
      background: "#ff8323"
    },
    "&:hover.danger":{
      background: "#e8751b",
    },
    "&:active.danger":{
      background: "#dc6b13",
    },
     "&:focus.danger":{
      boxShadow: "0 0 0px 3px #dc6b134d"
    },

    "&.warning":{
      background: "#ffce04",
      color:"#00000091"
    },
    "&:hover.warning":{
      background: "#ffc200",
    },
    "&:active.warning":{
      background: "#ffb600",
    },
     "&:focus.warning":{
      boxShadow: "0 0 0px 3px #ffc20059"
    },

    "&.info":{
      background: "#00a2e6",
    },
    "&:hover.info":{
      background: "#0097d6",
    },
    "&:active.info":{
      background: "#0089c3",
    },
     "&:focus.info":{
      boxShadow: "0 0 0px 3px #00acf56b"
    },

    "&.secondary":{
      background: "#b1b1b1",
    },
    "&:hover.secondary":{
      background: "#a5a5a5",
    },
    "&:active.secondary":{
      background: "#949494",
    },
     "&:focus.secondary":{
      boxShadow: "0 0 0px 3px #35353536"
    },

    position: "relative",
    transition: "background-color 100ms, box-shadow 150ms ease-in-out",
    fontWeight: "600",
    color: "#ffffff",
    fontSize: "12px",
    height: "34px",
    border: "none",
    borderRadius: "5px",
    padding:"0px 20px"
  },
});

//############################################################################
// component
//############################################################################
function Button(props) {
  const {
    children,
    classes,
    className,
    type,
    onClick,
    disabled,
    htmlFor,
    name,
    variant = "success"
  } = props;
  return (
    <button
      name={name}
      htmlFor={htmlFor}
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`${classes.Button} ${variant} ${className}`}
      
    >
      {children}
    </button>
  );
}

//############################################################################
// connect to styles and redux
//############################################################################
const ButtonWithStyle = withStyles(styles)(Button);

//############################################################################
// export
//############################################################################
export { ButtonWithStyle as Button };
