import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core";

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    borderRadius:"50%",
    height:"15px",
    width:"15px",
    zIndex:"2000",
    position:"absolute",
    margin:"3px",
    transition:"box-shadow 200ms cubic-bezier(.18,1.11,.7,1.47)",
    "&:hover": {
        boxShadow:"0px 0px 0px 4px #00000017"
      }
  },
  poper:{
    fontSize:"12px"
  }
})
// #############################################################################
// Component
// #############################################################################

function RoleTag(props) {
  const { classes, className = "", role = "" } = props;
  const [ labelName, color ] = role === "Project-Admin" ? ["Project Admin","#00BCD4"] : role === "Project-Manager" ? ["Project Manager","#a771e0"]  : ["Project Client","#28cc72"];
  return (
    <Tooltip classes={{tooltip: classes.poper}} title={labelName}>
      <label style={{backgroundColor: color}} className={`${classes.root} ${className}`}/>
    </Tooltip>
  );
}

// #############################################################################
// Component
// #############################################################################
const RoleTagWithStyles = withStyles(styles)(RoleTag)

export { RoleTagWithStyles  as RoleTag };
