import React, { PureComponent, Fragment } from 'react';
// ######[ MATERIAL UI ]########################################################
import { withStyles } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';

// #####[ MATERIAL ICON ]#######################################################
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

// #############################################################################
// component
// #############################################################################
class ProjectBean extends PureComponent {

  // ###########################################################################
  // find expanded child
  // ###########################################################################

  findExpanded = () => {
    const { parent, projects } = this.props;
    let isExpanded = false;
    let selectedParent = {};
    parent.subProject.forEach(id => {
      if(projects[id].expand){
        isExpanded = true;
        selectedParent = projects[id];
      }
    })
    return [isExpanded, selectedParent]
  }


  // ###########################################################################
  //  generate beens
  // ###########################################################################

  generateBeens = () => {
    const { classes, currentProject = "", goToLink } = this.props;
    const { highlight, parent, projects } = this.props;

    const keys = Object.keys(projects);
    const array = [];

    keys.forEach(key => {

      const item = projects[key];
      const isCurrentProject = currentProject === item._id;
      const isHighlighted = highlight && highlight._id === item._id ;

      // console.log({isCurrentProject, currentProject, name: item.name})

      if(item.parentProject === parent._id) {
        array.push(
          <div
            key={item._id}
            className={`${classes.projectButton} ${isHighlighted ? classes.highlight: ""} ${ item.expand ? classes.expanded : ""} ${isCurrentProject ? classes.currentProject : ""}`}
          >
            { item.subProject.length <= 0 ? 
              <div onClick={()=> {goToLink(item)}}>
                <div className={classes.buttonPlaceholder}/>
              </div>
              :
              <IconButton className={classes.expandButton} onClick={this.props.handleExpand(item, parent, !item.expand, "onClick")}>
                <ExpandMoreIcon classes={{root: `${classes.icon} ${item.expand ? classes.iconWhite : classes.iconDark} `}}/>
              </IconButton>
            }
            <div 
              className={`${classes.text} ${item.expand ? isCurrentProject ? classes.currentProjectText : classes.expandedText : ""}`}
              onClick={()=> {goToLink(item)}}
            >
              {item.name}
            </div>
          </div>
        )
      }  
    })
    return array;
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const { classes, currentProject = "", adminRole, highlight, goToLink } = this.props;
    const { parent, projects } = this.props;
    const [ isExpanded, selectedParent ] = this.findExpanded();

    return (
      <Fragment>

        <div className={classes.root}>
          { parent.subProject === undefined && parent.subProject.length === 0 ? null :  this.generateBeens(projects)}
        </div>

        {!isExpanded ? null : 
          <ProjectBean
            goToLink={goToLink}
            highlight={highlight}
            adminRole={adminRole}
            currentProject={currentProject}
            classes={classes}
            parent={selectedParent}
            projects={projects}
            handleExpand={this.props.handleExpand}
          />
        }
      </Fragment>
    );
  }
}

// #############################################################################
// styles
// #############################################################################
const styles = theme => ({
  root:{
    display: "flex",
    padding: "12px 3px",
  },
  button:{
    padding: 5,
    width: 34,
    height: 34,
    background: "#00000012",
    margin: "0px 5px"
  },
  projectButton:{
    boxSizing: "border-box",
    minWidth: 160,
    maxWidth: 160,
    height: 30,
    padding:0,
    margin:"0px 5px",
    fontSize: "12px",
    color: "#487599",
    display:"flex",
    alignItems:"center",
    borderTop: "2px solid #487599",
    borderBottom: "2px solid #00000000",
    backgroundColor: "white",
    boxShadow: "0px 0px 3px 0px #0000001c",
    "&:hover":{
      cursor: "pointer",
    }
  },
  highlight: {
    boxShadow: "0 0 0px 3px #ff8c0082",
  },
  expanded:{
    backgroundColor:"#4875999c !important",
    "& > div":{
      color: "white !important",
    }
  },
  currentProject:{
    backgroundColor:"#487599 !important",
    "& > div": {
      color: "white !important"
    }
  },
  expandedText:{
    color: "white !important",
  },
  currentProjectText:{
    color: "white",
  },
  text: {
    padding: "7px 4px",
    fontWeight: 600,
    color:"#70818e",
    textDecoration:"none",
    fontSize: 12,
    whiteSpace:"nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    height: "100%",
    boxSizing: "border-box"
  },
  buttonPlaceholder:{
    width: 24,
    minWidth: 24,
    height: 24,
    padding: 2,
  },
  expandButton:{
    margin: "0px 8px",
    padding: 0,
    width: 20,
    height: 20,
    "& > span":{
      width: 20,
      height: 20,
    }
  },
  icon:{
    transition:"transform 200ms"
  },
  iconDark:{
    
  },
  iconWhite:{
    transform: "rotate(180deg)",
    color: "white",
  },
  newExpand:{
    display: "flex",
    marginTop:"10px"
  }
})


// #############################################################################
// connect styles to component
// #############################################################################

const ProjectBeanWithStyles = withStyles(styles)(ProjectBean)

// #############################################################################
// export
// #############################################################################

export { ProjectBeanWithStyles as ProjectBean };
