// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { commentActions } from "../../actions";
import { commentUserActions } from "../../userActions";

// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// Components //
import { CancelButton, SaveButton } from "../Buttons";

// Reusable Functions //

// Styles //

class AddNewComment extends React.PureComponent {
  state = {
    commentText: ""
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isAddNewComment !== this.props.isAddNewComment)
      this.setState({ isAddNewComment: this.props.isAddNewComment });
  }

  handleCommentCancel = () => {
    this.setState({
      commentText: ""
    });
    this.props.handleCancelAddNewComment();
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
      isChangeHappend: true
    });
  };

  handleCommentSubmit = e => {
    // e.preventDefault()
    const {
      dispatch,
      projectId,
      role,
      parentComment: parentIssueTaskComment,
      clickedRowDataId: issueTaskId
    } = this.props;
    const { commentText: text } = this.state;

    dispatch(
      role === "adminFlag"
        ? commentActions.addIssueTaskComment({
            projectId,
            text,
            issueTaskId,
            parentIssueTaskComment
          })
        : commentUserActions.addIssueTaskComment({
            projectId,
            text,
            issueTaskId,
            parentIssueTaskComment
          })
    );
    this.setState({
      commentText: ""
    });
    this.props.handleCancelAddNewComment &&
      this.props.handleCancelAddNewComment();
  };

  render() {
    const { classes, root } = this.props;
    const { commentText } = this.state;
    return (
      <Grid
        container
        className={classes.addNewCommentWrapper}
        style={
          root ? { padding: "10px 15px 0 15px" } : { borderTop: "1px solid #f0f0f0", paddingLeft: '15px' }
        }
      >
        <Grid item xs className={classes.textareaWrapper}>
          <textarea
            className={classes.addNewCommentTextarea}
            placeholder="Write your comment"
            value={commentText}
            name="commentText"
            onChange={this.handleChange}
          />
        </Grid>
        <Grid className={classes.actionsWrapper}>
          <SaveButton
            disabled={!commentText}
            onClick={this.handleCommentSubmit}
          />
          {!root && <CancelButton onClick={this.handleCommentCancel} />}
        </Grid>
      </Grid>
    );
  }
}

const Styles = {
  addNewCommentWrapper: {
    padding: "10px 5px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
    // borderTop: "1px solid" + Colors.lightGrey
  },
  addNewCommentTextarea: {
    borderRadius: "30px",
    width: "100%",
    padding: "9px 10px"
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "7px"
  },
  textareaWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

const mapStateToProps = state => ({
  projectId: state.projects.project._id,
  role: state.projects.project.role
});

export default connect(mapStateToProps)(withStyles(Styles)(AddNewComment));
