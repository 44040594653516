import React, { PureComponent } from 'react';
import { withStyles } from "@material-ui/core"
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import { removeWeekends } from "../helpers";


// #############################################################################
// component
// #############################################################################

class TaskInfo extends PureComponent {

  handleDateView = input => {
    const date = new Date(input);
    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`

  }

  calculateDateDifference = (startDate, endDate) => {
    const [weekends, workDays] = removeWeekends(startDate, endDate);
    const totalDays = weekends + workDays;
    return `${totalDays} Days - ${workDays} BD - ${weekends} WE`
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
  const { classes, task, showInfo = false } = this.props;
    return (
      <ClickAwayListener onClickAway={() => { this.props.toggleInfo(false) }}>
      <div id="TASK-INLINE-FORM" className={`${classes.root} ${showInfo ? classes.show: classes.hide}`}>

        <div className={classes.info}>
          <div className={classes.title}>OWNER:</div>
          <div className={classes.value}>{task.owner.firstName} {task.owner.lastName}</div>
        </div>

        <div className={classes.info}>
          <div className={classes.title}>PRIORITY:</div>
          <div className={classes.value}>{task.priority}</div>
        </div>

        <div className={classes.info}>
          <div className={classes.title}>STATUS:</div>
          <div className={classes.value}>{task.status}</div>
        </div>

        <div className={classes.info}>
          <div className={classes.title}>COMPLETE:</div>
          <div className={classes.value}>{parseInt(task.complete,10)}%</div>
        </div>

        <div className={classes.info}>
          <div className={classes.title}>PLANNED START DATE:</div>
          <div className={classes.value}>{this.handleDateView(task.plannedStartDate)}</div>
        </div>

        <div className={classes.info}>
          <div className={classes.title}>PLANNED FINISH DATE:</div>
          <div className={classes.value}>{this.handleDateView(task.plannedFinishDate)}</div>
        </div>

        <div className={classes.info}>
          <div className={classes.title}>ACTUAL START DATE:</div>
          <div className={classes.value}>{this.handleDateView(task.actualStartDate)}</div>
        </div>

        <div className={classes.info}>
          <div className={classes.title}>ACTUAL FINISH DATE:</div>
          <div className={classes.value}>{this.handleDateView(task.actualFinishDate)}</div>
        </div>

        <div className={classes.info}>
          <div className={classes.title}>DURATION:</div>
          <div className={classes.value}>{this.calculateDateDifference(task.actualStartDate, task.actualFinishDate)}</div>
        </div>

        <div className={classes.descriptionInfo}>
          <div className={classes.title}>DESCRIPTION:</div>
          <Tooltip classes={{tooltip: classes.tooltip}} title={task.description} aria-label="add">
            <div className={classes.description}>{task.description}</div>
          </Tooltip>
        </div>
        <div className={classes.dependency}>
          <div className={classes.title}>DEPENDENCIES:</div>
          <div className={classes.dependencies}>
          {task.dependencies.map(item => {
            return (
              <div key={item._id} className={classes.chip}>{item.name}</div>
            )
          })}
          </div>
        </div>

      </div>
      </ClickAwayListener>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    display: "flex",
    flexWrap: "wrap",
    overflow : "hidden",
    width: "calc(100% - 12px)",
    padding: "10px 0px",
    position: "sticky",
    background: "#fdfdfd",
    border:"1px solid #00000030",
    zIndex: 10,
    boxShadow:"0 2px 17px 8px #00000024",
    margin:"3px 6px",
    left: 6,
    boxSizing: "border-box",
    transition: "opacity 500ms, padding 100ms, margin 100ms",
    transitionTimingFunction:  "cubic-bezier(.26,.94,.62,.96)",
    opacity: 1,
    borderRadius: 4
  },
  show:{
    opacity: 1,
  },
  hide:{
    margin: 0,
    height: 0,
    border: 0,
    padding: 0,
    opacity: 0,
  },
  // ###########################################################################
  info:{
    padding: "5px 15px",
    display: "flex",
    width: "25%",
    boxSizing: "border-box",
  },
  title:{
    color: "#00091994",
    fontSize: 12,
    fontWeight: 500,
    paddingRight: 5,
  },
  value:{
    color: "#0087dcc2",
    fontSize: 12,
    fontWeight: 700,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  // ###########################################################################
  descriptionInfo: {
    padding: "5px 15px",
    display: "flex",
    width: "75%",
    boxSizing: "border-box",
  },
  description: {
    color: "#964100ba",
    fontSize: 12,
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  // ###########################################################################
  dependency:{
    padding: "5px 15px",
    display: "flex",
    width: "100%",
    boxSizing: "border-box",
    alignItems: "center"
  },
  dependencies:{
    display: "flex",
    flex: 1
  },
  chip:{
    fontSize: 11,
    fontWeight: 600,
    padding: "2px 5px",
    background: "#00000014",
    color: "#ca4400",
    margin: "0 2px",
    borderRadius: 3
  },
  tooltip:{
    backgroundColor: "white",
    border:"1px solid #00000010",
    fontSize: 12,
    color: "#964100ba",
  }
});
const TaskInfoWithStyles = withStyles(styles)(TaskInfo)

// #############################################################################
// export
// #############################################################################

export  { TaskInfoWithStyles as TaskInfo };

