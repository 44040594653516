import React from "react";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
//############################################################################
// styles
//############################################################################
const styles = theme => {
  return {
    Button: {
      "&.primary":{
        backgroundColor: "#155794"
      },
      "&.secondary":{
        background: "linear-gradient(90deg, #EF5350  0%, #FFB74D 50%, #EF5350  100%)",
        backgroundSize: "200% 100%",
      },
      "&.success":{
        background: "linear-gradient(90deg, #4caf50 0%, #8bc34a 50%, #4caf50 100%)",
        backgroundSize: "200% 100%",
      },
      "&.danger":{
        background: "linear-gradient(90deg, #d32f2f 0%, #f44336 50%, #d32f2f 100%)",
        backgroundSize: "200% 100%",
      },
      "&.warning":{
        background: "linear-gradient(90deg, #ffc107 0%, #ffeb3b 50%, #ffc107 100%)",
        backgroundSize: "200% 100%",
      },
      "&.info":{
        background: "linear-gradient(90deg, #673ab7 0%, #ba68c8 50%, #673ab7 100%)",
        backgroundSize: "200% 100%",
      },
      "&.light":{
        background: "linear-gradient(90deg, #b2ebf2 0%, #00bcd4 50%, #b2ebf2 100%)",
        backgroundSize: "200% 100%",
      },
      "&.dark":{
        background: "linear-gradient(90deg, #3f51b5 0%, #673ab7 50%, #3f51b5 100%)",
        backgroundSize: "200% 100%",
      },
      position: "relative",
      marginTop: '24px',
      backgroundPositionX: "0%",
      transition: "background-color 300ms ease-in-out",
      fontWeight: "900",
      color: "#ffffff",
      width: "100%",
      fontSize: "11px",
      height: "50px",
      border: "none",
      borderRadius: "5px",
      "&:focus": {
        outline: "none",
        // backgroundPositionX: "100%"
        backgroundColor: "#3f3f80"
      },
      "&:hover": {
        // backgroundPositionX: "100%"
        backgroundColor: "#4c4b94"
      }
    },
    cover: {
      width: "100%",
      height: "100%",
      backgroundColor: "#ffffff00",
      borderRadius: "25px",
      position: "absolute"
    }
  };
};

//############################################################################
// component
//############################################################################
function CButton(props) {
  const {
    children,
    classes,
    className,
    type,
    onClick,
    disabled,
    htmlFor,
    name,
    variant = "primary"
  } = props;
  return (
    <Button
      classes={{ root: className }}
      name={name}
      htmlFor={htmlFor}
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`${classes.Button} ${variant}`}
      
    >
      <div name={name} htmlFor={htmlFor} className={classes.cover} />
      {children}
    </Button>
  );
}

//############################################################################
// connect to styles and redux
//############################################################################
const CButtonWithStyle = withStyles(styles)(CButton);

//############################################################################
// export
//############################################################################
export { CButtonWithStyle as CButton };
