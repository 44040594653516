
// #############################################################################
// this function will find all the parents of selected task
// #############################################################################

export function listAllParnts(childTask, task, tasks) {
  const parent = {};
  let sumOfOtherChildsComplete = 0

  // this function will sum all the child complete except the selected child task
  task.subTask.forEach(item => { 
    sumOfOtherChildsComplete += item !== childTask._id ? parseInt(document.getElementById(`P-${item}`).style.width.split("%")[0]) : 0;
  });

  parent.sumOfOtherChildsComplete = sumOfOtherChildsComplete;
  parent.name = task.name;
  parent._id = task._id;
  parent.childNumber = task.subTask.length;
  parent.percentNode = document.getElementById(`P-${task._id}`);
  parent.percentNumberNode = document.getElementById(`P-N-${task._id}`);
  parent.parent = task.parentTask ? listAllParnts(task, tasks[task.parentTask], tasks) : null;
  return parent;
}
