import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";

// #############################################################################
// component
// #############################################################################

class TaskRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // ###########################################################################
  // return the date in the new format
  // ###########################################################################

  shortDate = (dateString = new Date()) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() >= 10 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1);
    const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();

    return `${month}/${day}/${year}`;
  };

  // ###########################################################################
  // calculate the delay
  // ###########################################################################

  calcualteDelay = (task = {}) => {
    const { actualFinishDate, completed } = task;
    const today = new Date();
    const diffTime = today.getTime() - new Date(actualFinishDate).getTime();
    const diffTimeInDays =
      diffTime >= 0 && !completed
        ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + " Days"
        : 0;
    return diffTimeInDays;
  };

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const { classes, task = {} } = this.props;
    const delay = this.calcualteDelay(task);
    return (
      <div className={classes.root}>
        <div className={classes.column}>{task.name}</div>
        <div style={{ flex: 2 }} className={classes.column}>
          {this.shortDate(task.actualStartDate)}
        </div>
        <div style={{ flex: 2 }} className={classes.column}>
          {this.shortDate(task.actualFinishDate)}
        </div>
        <div className={classes.column}>
          <div className={classes.progressHolder}>
            {!task.completed ? (
              <div className={classes.percentNumber}>
                {Math.floor(task.complete) + "%"}
              </div>
            ) : null}
            <div
              style={{ width: task.complete + "%" }}
              className={classes.progress}
            >
              {task.completed ? "Completed" : ""}
            </div>
          </div>
        </div>
        <div className={classes.delay}>{delay === 0 ? "" : delay}</div>
      </div>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root: {
    display: "flex",
    padding: "8px 0px",
    alignItems: "center",
    borderBottom: "1px solid #cbcbdb"
  },
  column: {
    flex: 3,
    color: "#80808A",
    fontSize: 12
  },
  progress: {
    backgroundImage:
      "repeating-linear-gradient(-45deg, #ffffff00, #ffffff00 5px, #00000010 5px, #00000010 10px)",
    height: 20,
    background: "#00d88d",
    color: "#fff",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: 13,
    fontWeight: 700
  },
  progressHolder: {
    border: "1px solid #cbcbdb",
    borderRadius: 3,
    position: "relative"
  },
  delay: {
    flex: 1.5,
    color: "red",
    fontSize: 13,
    textAlign: "center"
  },
  percentNumber: {
    position: "absolute",
    left: "calc(50% - 15px)",
    top: 2,
    fontSize: 13,
    fontWeight: 700,
    color: "#66666d"
  }
});

// #############################################################################
// connect material styles and redux state to component
// #############################################################################
const TaskRowWithStyles = withStyles(styles)(TaskRow);

// #############################################################################
// export
// #############################################################################

export { TaskRowWithStyles as TaskRow };
