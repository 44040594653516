import { projectConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "../actions";
import { history } from "../helpers";

export const projectUserActions = {
  getMyProjects,
  getProject,
  addProject,
  getProjectUsers,
  editProject,
  deleteProject,
  getAllArchitects,
  changeProjectOrder,
  getActivities
};

//################################################################################
// GET All Projects
//################################################################################

function getMyProjects() {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("myProjects", undefined, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.projects));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(projects) {
    return {
      type: projectConstants.GET_ALL_PROJECTS_SUCCESS,
      payload: projects
    };
  }
  // function failure(error) {
  //   return { type: projectConstants.GET_ALL_PROJECTS_FAILURE, payload: error };
  // }
}

//################################################################################
// Get Project
//################################################################################

function getProject(data) {
  return dispatch => {
    // dispatch(request());
    dispatch(progressBarActions.start());
    // setTimeout(() => {
    generalServices.service("getProject", data, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.project));
        } else {
          // dispatch(snackBarActions.snackBarError(response.msg));
          history.push("/admin-dashboard");
          dispatch(
            snackBarActions.snackBarError("This Project Does Not Exist")
          );
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    // }, 5000);
  };
  // function request() {
  //   return { type: projectConstants.GET_PROJECT_REQUEST };
  // }
  function success(project) {
    return { type: projectConstants.GET_PROJECT_SUCCESS, payload: project };
  }
  // function failure(error) {
  //   return { type: projectConstants.GET_PROJECT_FAILURE, payload: error };
  // }
}

//################################################################################
// Add Project
//################################################################################

function addProject(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("createProject", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.project));
          dispatch(
            snackBarActions.snackBarSuccess("Project Successfully Added")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(project) {
    return { type: projectConstants.ADD_PROJECTS_SUCCESS, payload: project };
  }
  // function failure(error) {
  //   return { type: projectConstants.ADD_PROJECTS_FAILURE, payload: error };
  // }
}

//################################################################################
// Get Project Users
//################################################################################

function getProjectUsers(data) {
  return dispatch => {
    dispatch(request());
    dispatch(progressBarActions.start());
    generalServices.service("getProjectUsers", data, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.projectUsers));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function request() {
    return { type: projectConstants.GET_PROJECT_USERS_REQUEST };
  }
  function success(users) {
    return { type: projectConstants.GET_PROJECT_USERS_SUCCESS, payload: users };
  }
  // function failure(error) {
  //   return { type: projectConstants.GET_PROJECT_USERS_FAILURE, payload: error };
  // }
}

//################################################################################
// Edit Project
//################################################################################

function editProject(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editProject", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(data));
          dispatch(
            snackBarActions.snackBarSuccess("Saved changes successfully")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(project) {
    return { type: projectConstants.EDIT_PROJECTS_SUCCESS, payload: project };
  }
}
//################################################################################
// Delete Project
//################################################################################

function deleteProject(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteProject", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(
            snackBarActions.snackBarSuccess("Project Deleted Successfully")
          );
          history.push("/");
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
}

//################################################################################
// GET All Architects
//################################################################################

function getAllArchitects() {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getAllArchitects", undefined, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.architects));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(Architects) {
    return {
      type: projectConstants.GET_ALL_PROJECT_ARCHITECT_SUCCESS,
      payload: Architects
    };
  }
  // function failure(error) {
  //   return {
  //     type: projectConstants.GET_ALL_PROJECT_ARCHITECT_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Change Project Order
//################################################################################

function changeProjectOrder(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("changeProjectOrder", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.project));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(project) {
    return { type: projectConstants.CHANGE_PROJECT_ORDER_FAILURE };
  }
  // function failure(error) {
  //   return { type: projectConstants.CHANGE_PROJECT_ORDER_FAILURE };
  // }
}

//################################################################################
// get Activities
//################################################################################
function getActivities(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getActivities", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.activities));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(activities) {
    return {
      type: projectConstants.GET_ACTIVITIES_SUCCESS,
      payload: activities
    };
  }
  // function failure(error) {
  //   return {
  //     type: projectConstants.GET_ACTIVITIES_FAILURE,
  //     payload: error
  //   };
  // }
}
