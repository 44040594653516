import { projectConstants, userConstants } from "../constants";

export function projects(state = {}, action) {
  switch (action.type) {
    //
    case projectConstants.GET_ALL_PROJECTS_SUCCESS:
      return { ...state, projects: action.payload };
    case projectConstants.GET_ALL_PROJECTS_FAILURE:
      return { ...state, msg: action.payload };

    //
    case projectConstants.ADD_PROJECTS_SUCCESS: {
      if (action.payload.status === "OPEN") {
        const newProject = openAllParent(state.projects, action.payload);
        return {
          ...state,
          projects: state.projects
            ? [action.payload, ...newProject]
            : [action.payload]
        };
      } else {
        return {
          ...state,
          projects: state.projects
            ? [action.payload, ...state.projects]
            : [action.payload]
        };
      }
    }
    case projectConstants.ADD_PROJECTS_FAILURE:
      return { ...state, msg: action.payload };

    case projectConstants.EDIT_PROJECTS_SUCCESS: {
      return {
        ...state,
        project: action.payload
          ? { ...state.project, ...action.payload }
          : state.project
      };
    }

    case projectConstants.EDIT_AND_ORDER_SUCCESS: {
      return {
        ...state,
        projects: action.payload
      };
    }

    case projectConstants.EDIT_AND_ORDER_FAILURE: {
      return state;
    }

    case userConstants.UPDATE_PROJECT_ROLE_SUCCESS: {
      const index = findIndex(state.users, "_id", action.payload._id);
      return {
        ...state,
        users: [
          ...state.users.slice(0, index),
          { ...state.users[index], role: action.payload.role },
          ...state.users.slice(index + 1)
        ]
      };
    }
    case userConstants.DELETE_PROJECT_ROLE_SUCCESS: {
      const index = findIndex(state.users, "_id", action.payload._id);
      return {
        ...state,
        users: [...state.users.slice(0, index), ...state.users.slice(index + 1)]
      };
    }

    case projectConstants.COPY_PROJECT_SUCCESS: {
      return {
        ...state,
        projects: [
          action.payload,
          ...state.projects.map(project =>
            project._id === action.payload.parentProject
              ? project.subProject
                ? {
                    ...project,
                    subProject: [action.payload._id, ...project.subProject]
                  }
                : {
                    ...project,
                    subProject: [action.payload._id]
                  }
              : project
          )
        ]
      };
    }

    case projectConstants.COPY_PROJECT_FAILURE: {
      return state;
    }

    //
    case projectConstants.GET_ALL_PROJECT_ARCHITECT_SUCCESS:
      return { ...state, architects: action.payload };

    case projectConstants.GET_ALL_PROJECT_ARCHITECT_FAILURE:
      return { ...state, msg: action.payload };
    //

    case projectConstants.GET_PROJECT_REQUEST:
      return { ...state, project: undefined };
    case projectConstants.GET_PROJECT_SUCCESS:
      return { ...state, project: action.payload };
    case projectConstants.GET_PROJECT_FAILURE:
      return { ...state, project: {}, msg: action.payload };

    //
    case projectConstants.GET_PROJECT_USERS_REQUEST:
      return { ...state, users: undefined };
    case projectConstants.GET_PROJECT_USERS_SUCCESS:
      return { ...state, users: action.payload };
    case projectConstants.GET_PROJECT_USERS_FAILURE:
      return { ...state, msg: action.payload };

    case userConstants.SET_PROJECT_ROLE_SUCCESS: {
      const { users = [] } = state;
      return { ...state, users: [...users, action.payload] };
    }

    //GET Root Projects
    case projectConstants.GET_ROOT_PROJECTS_SUCCESS:
      return { ...state, rootProjects: action.payload };
    case projectConstants.GET_ROOT_PROJECTS_FAILURE:
      return { ...state, msg: action.payload };

    case projectConstants.CHANGE_PROJECT_ORDER_FAILURE:
      return state;

    //GET Sub Projects
    case projectConstants.GET_SUB_PROJECTS_REQUEST:
      return {
        ...state,
        subProjects: { ...state.subProjects, [action.payload[0]]: undefined }
      };
    case projectConstants.GET_SUB_PROJECTS_SUCCESS:
      return {
        ...state,
        subProjects: {
          ...state.subProjects,
          [action.payload[0]]: action.payload[1]
        }
      };
    case projectConstants.GET_SUB_PROJECTS_FAILURE:
      return { ...state, msg: action.payload };

    //UPDATE PARENT LOCALLY
    case projectConstants.UPDATE_PARENT_LOCALLY: {
      let index;
      if (state.projects && action.payload)
        index = findIndex(state.projects, "_id", action.payload._id);
      return {
        ...state,
        projects: [
          ...state.projects.slice(0, index),
          action.payload,
          ...state.projects.slice(index + 1)
        ]
      };
    }
    case projectConstants.DELETE_PROJECTS_SUCCESS:
      return { ...state, project: undefined };
    case projectConstants.DELETE_PROJECTS_FAILURE:
      return { ...state, msg: action.payload };

    //Add USER AND ASSIGN TO PROJECT
    case projectConstants.ADD_USER_AND_ASSIGN_SUCCESS:
      return {
        ...state,
        users: state.users ? [...state.users, action.payload] : [action.payload]
      };
    case userConstants.ADD_USER_AND_ASSIGN_FAILURE:
      return { ...state, msg: action.payload };

    case projectConstants.CLEARING_PROJECTS_DATA:
      return {};

    case projectConstants.ADD_ADDRESS_TO_PROJECT_SUCCESS:
      const isExist = state.project.addresses.find(
        address => address === action.payload
      );
      return {
        ...state,
        project: {
          ...state.project,
          addresses: isExist
            ? [...state.project.addresses]
            : [...state.project.addresses, action.payload]
        }
      };

    case projectConstants.ADD_ADDRESS_TO_PROJECT_FAILURE:
      return state;

    default:
      return state;
  }
}

// #############################################################################
//  find the index of searched item in the array
// #############################################################################

function findIndex(array, key, searchKey) {
  return array.findIndex(item => item[key] === searchKey);
}

const openAllParent = (projectList, child) => {
  const parent = projectList.find(
    project => project._id === child.parentProject
  );

  if (parent) {
    const newProjectList = projectList.map(project =>
      project._id === child.parentProject
        ? { ...project, status: "OPEN" }
        : project
    );
    return openAllParent(newProjectList, parent);
  } else {
    return projectList;
  }
};
