// React //
import React from "react";

// Redux //

// Material Ui //

// Components //
import NoteMonthContainer from "./NoteMonthContainer";
// Reusable Functions //

// Styles //

const Header = ({ notes }) => {
  const filterByYearMonth = notes => {
    let tempObj = [];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    if (!notes.length) return [];
    for (var i = 0; i < notes.length; i++) {
      const date = new Date(notes[i].createDate);

      const yearMonth = `${monthNames[date.getMonth()]} ${date.getFullYear()}`;

      if (!tempObj[yearMonth]) tempObj[yearMonth] = [];
      tempObj = {
        ...tempObj,
        [yearMonth]: [...tempObj[yearMonth], notes[i]]
      };
      if (notes.length - 1 === i) return tempObj;
    }
  };

  const monthNotes = filterByYearMonth(notes);
  const object = Object.keys(monthNotes);

  return (
    <>
      {object[0] ? (
        object.map((key, index) => (
          <NoteMonthContainer
            key={index}
            notes={monthNotes[key]}
            date={key}
            index={index}
          />
        ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "35px",
            color: "#00000036",
            fontWeight: "100",
            padding: "10px 50px 3px 0",
            borderRadius: "5px",
            marginTop: "0",
            // flexGrow: 100,
            marginBottom: "25px",
            height: "230px"
          }}
        >
          No Journal
        </div>
      )}
    </>
  );
};

export default Header;
