import React from 'react'
import { withStyles } from '@material-ui/core'
import IndeterminateCheckBox from '@material-ui/icons/IndeterminateCheckBox'
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../styles/colors'

const styles = {
    iconButton: {
        padding: '3px !important',
        color: Colors.lightGreen,
    }
}

export const MinusFilledIcon = React.memo(withStyles(styles)(({ classes, onClick, tooltip = 'Open', color = 'gray' }) => (
    <Tooltip title={tooltip}>
        <IconButton onClick={onClick} className={classes.iconButton} component="span">
            <IndeterminateCheckBox style={{ color }} />
        </IconButton>
    </Tooltip>
))
)
