import { costConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "./";

export const costActions = {
  addCost,
  getProjectCosts,
  editCost,
  approveCost,
  deleteCost,
  changeCostOrder,
  getCostTotals
};

//##############################################################################
// Add Cost
//##############################################################################

function addCost(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("createCost", data).then(
      response => {
        if (response.success === true) {
          dispatch(success(response.costs));
          dispatch(getCostTotals(response));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    //////////////////////////
  };
  function success(cost) {
    return { type: costConstants.ADD_COST_SUCCESS, payload: cost };
  }
  // function failure(error) {
  //   return { type: costConstants.ADD_COST_FAILURE, payload: error };
  // }
}

//##############################################################################
// Get All Cost
//##############################################################################

function getProjectCosts(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getProjectCost", data).then(
      response => {
        if (response.success === true) {
          dispatch(success(response.costs));
          dispatch(getCostTotals(response));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(costs) {
    return { type: costConstants.GET_PROJECT_COSTS_SUCCESS, payload: costs };
  }
  // function failure(error) {
  //   return { type: costConstants.GET_PROJECT_COSTS_FAILURE, payload: error };
  // }
}

//##############################################################################
// Edit Cost
//##############################################################################

function editCost(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editCost", data).then(
      response => {
        if (response.success === true) {
          dispatch(success(response.costs));
          dispatch(getCostTotals(response));
          // if (data.approvalStatus) {
          //   dispatch(
          //     approveCost({ id: data.id, approvalStatus: data.approvalStatus })
          //   );
          // }
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(cost) {
    return { type: costConstants.EDIT_COST_SUCCESS, payload: cost };
  }
  // function failure(error) {
  //   return { type: costConstants.EDIT_COST_FAILURE, payload: error };
  // }
}

//##############################################################################
// Edit Cost
//##############################################################################

function approveCost(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("approvalStatus", data).then(
      response => {
        if (response.success === true) {
          dispatch(success(response.costs));
          dispatch(getCostTotals(response));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(cost) {
    return { type: costConstants.APPROVE_COST_SUCCESS, payload: cost };
  }
  // function failure(error) {
  //   return { type: costConstants.APPROVE_COST_FAILURE, payload: error };
  // }
}

//################################################################################
// Delete Cost
//##############################################################################

function deleteCost(data) {
  return dispatch => {
    dispatch(progressBarActions.start());

    ////Cost////////////////////
    generalServices.service("deleteCost", data).then(
      response => {
        if (response.success === true) {
          dispatch(success(response.costs));
          dispatch(getCostTotals(response));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    //////////////////////////
  };
  function success(cost) {
    return { type: costConstants.DELETE_COST_SUCCESS, payload: cost };
  }
  // function failure(error) {
  //   return { type: costConstants.DELETE_COST_FAILURE, payload: error };
  // }
}

//##############################################################################
// Change Cost Order
//##############################################################################
function changeCostOrder(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("changeCostOrder", data).then(
      response => {
        if (response.success === true) {
          dispatch(success(response.project));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(cost) {
    return {
      type: costConstants.CHANGE_PROJECT_COSTS_ORDER_SUCCESS,
      payload: cost
    };
  }
  // function failure(error) {
  //   return {
  //     type: costConstants.CHANGE_PROJECT_COSTS_ORDER_FAILURE,
  //     payload: error
  //   };
  // }
}

//##############################################################################
// get cost totals
//##############################################################################

function getCostTotals(data) {
  const { actualTotal, budgetTotal, revisedTotal } = data;
  return {
    type: costConstants.GET_COST_TOTALS_SUCCESS,
    payload: { actualTotal, budgetTotal, revisedTotal }
  };
}
