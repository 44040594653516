// React //
import React from "react";
import Select from "react-select";

// Redux //
import { connect } from "react-redux";
import { issueActions } from "../../actions";
import { issueUserActions } from "../../userActions";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Flag from '@material-ui/icons/Flag'
// Components //
import { ReactInput } from "../";
import PDatePicker from "components/DatePicker/DatePicker";
import ThemeContext from "../ContextApi/Context";
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
// Reusable Functions //

// Styles //

class AddIssueInline extends React.PureComponent {
  static contextType = ThemeContext;
  state = {
    name: "",
    description: "",
    assignedTo: null,
    dueDate: undefined,
    important: false
  };

  // ###########################################################################
  // handle change in the inputs
  // ###########################################################################

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // ###########################################################################
  // handle select
  // ###########################################################################

  handleSelectChange = (option, event) => {
    this.setState({ [event.name]: option });
  };

  // ###########################################################################
  // Add Issue
  // ###########################################################################

  handleAddIssue = e => {
    e.preventDefault();
    const projectId = this.context;
    const assignedTo = this.state.assignedTo.value;
    this.props.role
      ? this.props.dispatch(
        issueActions.addIssue({ ...this.state, projectId, assignedTo })
      )
      : this.props.dispatch(
        issueUserActions.addIssue({ ...this.state, projectId, assignedTo })
      );
    this.clearState();
  };

  // ###########################################################################
  // clear state
  // ###########################################################################

  clearState = () => {
    this.setState({
      name: "",
      description: "",
      dueDate: undefined,
      projectId: "",
      assignedTo: "",
      important: false
    });
  };

  // ###########################################################################
  // create list of users for the select box
  // ###########################################################################

  decorateUsers = users => {
    return users.map(item => ({
      value: item.user._id,
      label: `${item.user.firstName} ${item.user.lastName}`
    }));
  };

  handleCheckBox = ({ target: { checked } }) => {
    this.setState({
      important: checked
    })
  }

  render() {
    const { classes, users } = this.props;
    const { name, description, assignedTo, dueDate, important } = this.state;

    return (
      <>
        <form onSubmit={this.handleAddIssue} className={classes.listHeader}>
          <div className={classes.spacer} />

          {/* //////////////////// Add Issue InLine //////////////////////// */}
          <Grid
            container
            className={classes.assignUserBox}
            justify={"space-evenly"}
            alignItems="center"
          >
            <Grid
              container
              className={classes.selectAddGrid}
              alignItems="center"
              justify={"space-evenly"}
            >
              <div className={classes.selectAddGridItem}>
                <ReactInput
                  name={"name"}
                  value={name}
                  onChange={this.handleChange}
                  required
                  placeholder="Title"
                  variant="outlined"
                  autoComplete="off"
                  type="text"
                  className={classes.inputs}
                />
              </div>
              <div className={classes.selectAddGridItem}>
                <ReactInput
                  name={"description"}
                  value={description}
                  onChange={this.handleChange}
                  required
                  placeholder="Description"
                  variant="outlined"
                  autoComplete="off"
                  type="text"
                  className={classes.inputs}
                />
              </div>
              <div
                className={classes.selectAddGridItem}
                style={{ position: "relative" }}
              >
                <Select
                  options={this.decorateUsers(users)}
                  name={"assignedTo"}
                  onChange={this.handleSelectChange}
                  required
                  placeholder="Assigned To"
                  maxMenuHeight={120}
                  value={assignedTo}
                  styles={{
                    menuList: styles => {
                      return {
                        ...styles,
                        width: "100%"
                      };
                    }
                  }}
                />
                <input
                  style={{
                    height: 0,
                    width: 0,
                    padding: 0,
                    margin: 0,
                    opacity: 0,
                    position: "absolute",
                    top: 30,
                    left: "100px"
                  }}
                  value={assignedTo ? assignedTo : ""}
                  required
                  onChange={this.handleChange}
                  name="assignedTo"
                />
              </div>
              <Grid
                className={classes.selectAddGridItem}
                style={{ position: "relative" }}
              >
                <PDatePicker
                  onChange={date => {
                    this.setState({ dueDate: date });
                  }}
                  name="dueDate"
                  selected={dueDate}
                  placeholderText="Due Date"
                  className={classes.datePicker}
                  autoComplete="off"
                />
                <input
                  style={{
                    height: 0,
                    width: 0,
                    padding: 0,
                    margin: 0,
                    opacity: 0,
                    position: "absolute",
                    top: 30,
                    left: "100px"
                  }}
                  name="dueDate"
                  value={dueDate ? "1" : ""}
                  required
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid>
                <FormControlLabel className={classes.flagFormControl} control={<Checkbox checked={important} onChange={this.handleCheckBox} className={classes.addFlagCheckbox} />}
                  label={
                    <span className={classes.addFlagWrapper}>
                      <Flag className={classes.flagIcon} />
                      <span className={classes.addFlagText}>Add Flag</span>
                    </span>
                  }
                />
              </Grid>
            </Grid>
            <div className={classes.addButtonHolder}>
              <Button className={classes.customButton} type="submit">
                {"ADD ISSUE"}
              </Button>
            </div>
          </Grid>
        </form>
      </>
    );
  }
}

const Styles = {
  customButton: {
    backgroundColor: "#155794",
    width: "100px",
    height: "48px",
    fontSize: "12px",
    color: "#fff",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#3f51b5"
    }
  },
  addButton: {
    backgroundColor: "#1aaa55",
    width: "100%",
    height: "36px",
    fontSize: "12px",
    color: "#fff",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#168f48"
    }
  },
  listHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px"
  },
  assignUserBox: {
    backgroundColor: "#eff0f5",
    borderRadius: "5px",
    border: "1px solid #bdbdbd",
    width: "100%",
    direction: "row",
    justify: "space-between",
    alignItems: "center"
  },
  selectAddGrid: {
    flex: 1,
    direction: "row",
    backgroundColor: "#ffffff",
    margin: "5px",
    borderRadius: "3px"
  },
  inputs: {
    width: "100%"
  },
  addButtonHolder: {
    width: "fit-content",
    margin: "5px 5px 5px 0px"
  },
  selectAddGridItem: {
    flex: 1,
    padding: "5px",
    "& > div > div": {
      display: "flex"
    }
  },
  font: {
    fontSize: "15px"
  },
  datePicker: {
    fontSize: "15px",
    height: "32px",
    borderRadius: "4px",
    border: "1px solid #C7C7C7",
    padding: "2px 8px",
    flex: 1
  },
  flagIcon: {
    color: 'white',
    padding: '2px',
    backgroundColor: 'red',
    borderRadius: '100%',
    fontSize: '13px',
    marginRight: '4px'
  },
  addFlagText: {
    color: 'red'
  },
  addFlagWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addFlagCheckbox: {
    padding: '5px!important',
  },
  flagFormControl:{
    marginLeft: '7px'
  }
};

const mapStateToProps = state => ({
  users: state.projects.users ? state.projects.users : []
});

export default connect(mapStateToProps)(withStyles(Styles)(AddIssueInline));
