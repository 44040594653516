import React, { Fragment } from "react";

//#####[ MATERIAL COMPONENTS ]##################################################
import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

//#####[ MATERIAL ICONS ]#######################################################
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

// #####[ colors ]##############################################################
import { Colors } from "../../styles";

// #############################################################################
// Component
// #############################################################################

class SOW extends React.PureComponent {
  state = {
    sow: this.props.project.sow ? this.props.project.sow : "",
    tempSow: this.props.project.sow ? this.props.project.sow : "",
    editMode: false,
    showEditButton: false,
    isCardExpanded: false,
    isCardExpandingArrowShows: false,
    elementsHeight: 0
  };

  componentDidMount() {
    if (this.refs.tbody)
      this.setState({
        isCardExpandingArrowShows:
          this.refs.pre.offsetHeight > 280 ? true : false
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.project)
      if (this.props.project.sow !== prevProps.project.sow)
        this.setState({ sow: this.props.project.sow });

    if (this.refs.pre)
      this.setState({
        isCardExpandingArrowShows:
          this.refs.pre.offsetHeight > 280 ? true : false
      });
  }

  // ###########################################################################
  // Handle Change
  // ###########################################################################

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // ###########################################################################
  // Show Edit Button
  // ###########################################################################

  ToggleEditButton = flag => () => {
    this.setState({ showEditButton: flag });
  };

  // ###########################################################################
  // Show Edit Button
  // ###########################################################################

  ToggleEditMode = flag => () => {
    this.setState({ editMode: flag, tempSow: this.state.sow }, () => {
      if (this.refs.textarea) this.refs.textarea.focus();
    });
  };

  // ###########################################################################
  // save edit
  // ###########################################################################

  saveEdit = () => {
    const sow = this.state.tempSow;
    const { project } = this.props;
    this.props.editProject({
      projectId: project._id,
      editProject: true,
      sow,
     });
    this.setState({ editMode: false, sow: this.state.tempSow });
  };

  handleToggleExpandCard = () => {
    this.setState(prevState => ({
      isCardExpanded: !prevState.isCardExpanded
    }));
  };

  // ###########################################################################
  // render
  // ###########################################################################

  render = () => {
    const { classes, editable } = this.props;
    const { showEditButton, editMode, sow } = this.state;
    const { tempSow, isCardExpanded, isCardExpandingArrowShows } = this.state

    return (
      <div
        className={classes.root}
        onDoubleClick={this.ToggleEditMode(true)}
        onMouseOver={this.ToggleEditButton(true)}
        onMouseOut={this.ToggleEditButton(false)}
      >
        {/* ################################################################ */}

        <div className={classes.titleWrapper}>
          <span className={classes.title}>Journals</span>
        </div>

        {/* ################################################################ */}

        <section className={classes.section}>
          {/* ################################################################ */}

          {editMode && editable ? (
            <Fragment>
              <div className={classes.textareaWrapper}>
                <textarea
                  onChange={this.handleChange}
                  className={classes.textarea}
                  name="tempSow"
                  value={tempSow}
                  placeholder="Type Here..."
                  ref="textarea"
                />
              </div>
              <div className={classes.buttonHolder}>
                <IconButton
                  onClick={this.ToggleEditMode(false)}
                  className={`${classes.buttons} ${classes.cancelButton}`}
                >
                  CANCEL
                </IconButton>
                <IconButton
                  onClick={this.saveEdit}
                  className={`${classes.buttons} ${classes.saveButton}`}
                >
                  SAVE
                </IconButton>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Fragment>
                {sow ? (
                  <div
                    className={classes.textContainerWrapper}
                    style={
                      isCardExpanded
                        ? { height: this.refs.pre.offsetHeight + 30 + "px" }
                        : null
                    }
                  >
                    <pre ref="pre" className={classes.textContainer}>
                      {sow}
                    </pre>
                  </div>
                ) : (
                  <section className={classes.noData}>No Data</section>
                )}

                <div className={classes.editButtonWrapper}>
                  <IconButton
                    onClick={this.ToggleEditMode(true)}
                    className={`${classes.editButton} ${
                      showEditButton && editable && !editMode ? classes.show : classes.hide
                    }`}
                  >
                    <EditIcon classes={{ root: classes.icon }} />
                  </IconButton>
                </div>
              </Fragment>
            </Fragment>
          )}
        </section>
        {/* ################################################################ */}
        {!editMode && (
          <section
            className={classes.expandCardArrowSection}
            onClick={this.handleToggleExpandCard}
            style={
              isCardExpandingArrowShows
                ? { visibility: "inherit" }
                : { visibility: "hidden" }
            }
          >
            <KeyboardArrowDown
              className={`${classes.expandCardArrowSectionIcon} ${
                isCardExpanded ? classes.expandCardArrowSectionUpIcon : null
              }`}
            />
          </section>
        )}
      </div>
    );
  };
}

// #############################################################################
// Styles
// #############################################################################

const styles = theme => ({
  root: {
    position: "relative",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  titleWrapper: { position: "relative" },
  title: {
    backgroundColor: "white",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    color: Colors.grey,
    fontWeight: "500",
    fontSize: "17px",
    padding: "5px 15px 10px"
  },

  section: {
    backgroundColor: "white",
    padding: "10px 15px 3px",
    borderRadius: "5px 5px 0px 0px",
    marginTop: "0",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    transition: "300ms"
  },

  buttonHolder: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px 0"
  },

  buttons: {
    fontSize: "12px",
    borderRadius: "4px",
    padding: "4px 10px",
    fontWeight: "500"
  },

  cancelButton: {
    color: "#ed6a34"
  },

  saveButton: {
    color: "#00ca54"
  },

  editButtonWrapper: {
    height: "33px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
    position: "relative"
  },

  editButton: {
    padding: 4,
    color: Colors.lightGreen,
    position:"absolute",
    top: 4,
    right: -9 
  },
  icon: {
    fontSize: "20px"
  },

  show: {
    display: "block"
  },

  hide: {
    display: "none"
  },

  textContainerWrapper: {
    flexGrow: 100,
    height: "257px",
    overflow: "hidden",
    transition: "300ms",
    cursor: "pointer",
    padding:"1px",
    boxSizing:"border-box"
  },

  textContainer: {
    fontSize: "14px",
    boxSizing: "border-box",
    color: "#000000ba",
    lineHeight: "1.4rem",
    border: "1px solid #00000000",
    padding: "8px 10px",
    whiteSpace: "pre-wrap",
    fontFamily: "inherit",
    margin: "20px 0 0",
    flexGrow: 100,
    cursor: "pointer"
  },

  textareaWrapper: {
    flexGrow: 100,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: 255,
    padding: 1
  },
  textarea: {
    boxSizing: "border-box",
    fontSize: "14px",
    width: "100%",
    color: "#000000ba",
    lineHeight: "1.4rem",
    marginTop: "20px",
    flexGrow: 100
    // minHeight: "228px"
  },
  expandCardArrowSection: {
    backgroundColor: "#E0E1E6",
    // height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px"
  },
  expandCardArrowSectionIcon: {
    color: Colors.darkBlue,
    transition: "200ms"
  },
  expandCardArrowSectionUpIcon: {
    transform: "rotate(180deg)"
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    color: "#00000036",
    fontWeight: "100!important",
    backgroundColor: "white",
    padding: "10px 15px 15px",
    borderRadius: "5px",
    marginTop: "20px",
    // flexGrow: 100,
    // marginBottom: "25px",
    height: "215px"
  }
});

// #############################################################################
// connect Material UI Styles to the component
// #############################################################################

const SOWWithStyles = withStyles(styles)(SOW);

// #############################################################################
// export
// #############################################################################

export { SOWWithStyles as SOW };
