import { progressBarConstants } from "../constants";

export const progressBarActions = {
  start,
  stop,
  issueRequestStart,
  issueRequestEnd,
  projectRequestStart,
  projectRequestEnd,
  clientRequestStart,
  clientRequestEnd,
  usersRequestStart,
  usersRequestEnd
};

function start() {
  return { type: progressBarConstants.PROGRESS_START };
}

function stop() {
  return { type: progressBarConstants.PROGRESS_STOP };
}

function issueRequestStart() {
  return {
    type: progressBarConstants.ISSUE_REQUEST_START,
  };
}

function issueRequestEnd() {
  return {
    type: progressBarConstants.ISSUE_REQUEST_END,
  };
}

function projectRequestStart() {
  return {
    type: progressBarConstants.USERS_REQUEST_START,
  };
}

function projectRequestEnd() {
  return {
    type: progressBarConstants.USERS_REQUEST_END,
  };
}

function clientRequestStart() {
  return {
    type: progressBarConstants.CLIENT_REQUEST_START,
  };
}

function clientRequestEnd() {
  return {
    type: progressBarConstants.CLIENT_REQUEST_END,
  };
}

function usersRequestStart() {
  return {
    type: progressBarConstants.PROJECT_REQUEST_START,
  };
}

function usersRequestEnd() {
  return {
    type: progressBarConstants.PROJECT_REQUEST_END,
  };
}
