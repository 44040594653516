import axios from "axios";
import { API } from "../constants";

//################################################################################
// all the exports exports
//################################################################################

export const userService = {
  login,
  logout,
  // addInvitation,
  // getAllInvitations,
  // verify,
  getAllUsers,
  setProjectRole,
  getMyRole,
  getMyProjects,
  getUser,
  getUserProjects,
  addUser,
  updateProjectRole,
  getAllUsersRoles,
  deleteProjectRole,
  deleteUser,
  editUser,
  whoAmI,
  getCompanyUsers
};

//################################################################################
// log in request
//################################################################################

function login(data) {
  return axios({
    url: `${API}/api/login`,
    method: "post",
    headers: { "content-type": "application/json" },
    data
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .then(data => {
      if (data.user && data.token) {
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));
      }
      return data;
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// logout request
//################################################################################

function logout() {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/admin/logOut`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    }
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("refreshToken");
        return;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// Add Invitation request
//################################################################################

// function addInvitation(data) {
//   const token = JSON.parse(localStorage.getItem("token"));
//   return axios({
//     url: `${API}/api/admin/createUser`,
//     method: "POST",
//     headers: { Authorization: 'JWT ' + token,  "content-type": "application/json" },
//     data
//   })
//     .then(response => {
//       if (response.status !== 200) {
//         return Promise.reject(response);
//       } else {
//         return response.data;
//       }
//     })
//     .catch(function(err) {
//       return Promise.reject(err);
//     });
// }

//################################################################################
// Get All Invitations
//################################################################################

// function getAllInvitations() {
//   const token = JSON.parse(localStorage.getItem("token"));
//   return axios({
//     url: `${API}/api/admin/getAllInactiveUsers`,
//     method: "POST",
//     headers: { Authorization: 'JWT ' + token,  "content-type": "application/json" }
//   }).then(response => {
//     if (response.status !== 200) {
//       return Promise.reject(response);
//     } else {
//       return response.data;
//     }
//   })
//   .catch(function(err) {
//     return Promise.reject(err);
//   });
// }

//################################################################################
// Verify User
//################################################################################

// function verify(id,password) {
//   return axios({
//     url: `${API}/api/checkVerificationCode`,
//     method: "POST",
//     headers: { "content-type": "application/json" },
//     data:{
//       password:password,
//       verificationCode: id
//     }
//   }).then(response => {
//     if (response.status !== 200) {
//       return Promise.reject(response);
//     } else {
//       return response.data;
//     }
//   })
//   .catch(function(err) {
//     return Promise.reject(err);
//   });
// }

//################################################################################
// GET All Users
//################################################################################

async function getAllUsers() {
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    const response = await axios({
      url: `${API}/api/admin/getAllUsers`,
      method: "POST",
      headers: {
        Authorization: "JWT " + token,
        "content-type": "application/json"
      }
    });
    if (response.status !== 200) {
      return response;
    } else {
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

//################################################################################
// SET Project role
//################################################################################

function setProjectRole(data) {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/admin/setProjectRole`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    },
    data
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// Get my Role
//################################################################################

function getMyRole(projectId) {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/user/myRole`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    },
    data: {
      projectId
    }
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// Get my Projects
//################################################################################

function getMyProjects() {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/user/myProjects`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    }
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        // console.log("response.data", response.data);
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// GET User
//################################################################################

function getUser(userId) {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/admin/getUser`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    },
    data: {
      id: userId
    }
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// GET User Projects
//################################################################################

function getUserProjects(userId) {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/admin/getUserProjects`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    },
    data: {
      userId
    }
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// Add User
//################################################################################

async function addUser(data) {
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    const response = await axios({
      url: `${API}/api/admin/createUser`,
      method: "POST",
      headers: {
        Authorization: "JWT " + token,
        "content-type": "application/json"
      },
      data
    });
    if (response.status !== 200) {
      return response;
    } else {
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

//################################################################################
// Update Project role
//################################################################################

function updateProjectRole(data) {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/admin/editRole`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    },
    data
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// GET All Users
//################################################################################

function getAllUsersRoles() {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/admin/getAllUsersWithRoleProjects`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    }
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// DELETE Project role
//################################################################################

function deleteProjectRole(data) {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/admin/deleteProjectRole`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    },
    data
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// DELETE user
//################################################################################

function deleteUser(data) {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/admin/deleteUser`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    },
    data
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// EDIT user
//################################################################################

function editUser(data) {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/admin/editUser`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    },
    data
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// WHO AM I
//################################################################################

function whoAmI(data) {
  const token = JSON.parse(localStorage.getItem("token"));
  return axios({
    url: `${API}/api/user/whoAmI`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    }
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
}

//################################################################################
// DELETE COMPANY IMAGE
//################################################################################

async function getCompanyUsers(data) {
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    const response = await axios({
      url: `${API}/api/admin/getCompanyUsers`,
      method: "POST",
      headers: {
        Authorization: "JWT " + token,
        "content-type": "application/json"
      },
      data
    });
    if (response.status !== 200) {
      return response;
    } else {
      return response.data;
    }
  } catch (err) {
    return err;
  }
}
