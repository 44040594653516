import React from "react";
import { connect } from "react-redux";
import { GanttChart } from "../../gantt-chart/gantt-chart";
import { projectUserActions, taskUserActions } from "../../../userActions";
import { filterActions } from "../../../actions";
import { TaskTotal } from "../../tasks-total";
import ThemeContext from "../../ContextApi/Context";

// #############################################################################
// component
// #############################################################################

class UserProjectSchedule extends React.PureComponent {
  static contextType = ThemeContext;

  // ###########################################################################
  // get all the project
  // ###########################################################################

  componentDidMount = () => {
    const { dispatch } = this.props;
    const projectId = this.context;
    dispatch(projectUserActions.getProject({ projectId }));
    dispatch(taskUserActions.getProjectTasks({ projectId }));
    dispatch(projectUserActions.getProjectUsers({ projectId }));
    dispatch(filterActions.pageFilterEdit(""));
  };

  // ###########################################################################
  // handle edit task
  // ###########################################################################

  handleEditTask = data => {
    this.props.dispatch(taskUserActions.editTask(data));
  };

  // ###########################################################################
  // handle edit task
  // ###########################################################################
  handleDelete = data => {
    this.props.dispatch(taskUserActions.deleteTask(data));
  };

  // ###########################################################################
  // handle edit task
  // ###########################################################################

  handleAddTask = data => {
    this.props.dispatch(taskUserActions.addTask(data));
  };

  // ###########################################################################
  // handle edit task
  // ###########################################################################

  changeTaskOrder = data => {
    this.props.dispatch(taskUserActions.changeOrder(data));
  };

  // ###########################################################################
  // Render
  // ###########################################################################

  render() {
    const { tasks = [], projectUsers = [], project = {} } = this.props;
    return (
      <React.Fragment>
        <TaskTotal tasks={tasks} />
        <GanttChart
          changeTaskOrder={this.changeTaskOrder}
          users={projectUsers}
          handleAddTask={this.handleAddTask}
          handleDelete={this.handleDelete}
          handleEditTask={this.handleEditTask}
          tasks={tasks}
          project={project}
        />
      </React.Fragment>
    );
  }
}

// #############################################################################
// connect redux state and material styles to the component
// #############################################################################

const mapStateToProps = state => {
  return {
    tasks: state.tasks.tasks,
    projectUsers: state.projects.users,
    project: state.projects.project
  };
};

const UserProjectScheduleConnected = connect(mapStateToProps)(
  UserProjectSchedule
);

// #############################################################################
// export
// #############################################################################

export { UserProjectScheduleConnected as UserProjectSchedule };
