import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { PrivateRoute, FirstRoute } from "./Private.routes";
import {
  LoginPage,
  AdminDashboard,
  UserDashboard,
  ChangePassword,
  EnterEmailResetPassword
} from "../pages";
import { history } from "../helpers";
import { ProgressBar } from "../components";

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Switch>
        <PrivateRoute path="/admin-dashboard" component={AdminDashboard} />
        <PrivateRoute path="/user-dashboard" component={UserDashboard} />
        <Route path="/Login" component={LoginPage} />
        <Route path="/verify" component={ChangePassword} />
        <Route path="/reset-password" component={EnterEmailResetPassword} />
        <FirstRoute path="/" component={LoginPage} />
      </Switch>
      <ProgressBar progressing={true} />
    </div>
  </Router>
);

export default AppRouter;
