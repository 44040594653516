export const gradients = {
  stripes: "repeating-linear-gradient(-45deg, #ffffff00, #ffffff00 5px, #00000010 5px, #00000010 10px)",
  lines: "repeating-linear-gradient(90deg, #ffffff00, #ffffff00 24px, #00000010 24px, #00000010 25px)"
}
export const images = {
  dragPlaceholder: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAAsSAAALEgHS3X78AAAAGElEQVQYlWP8//8/Az7AhFd2eChgYGAAABhjAw05avLfAAAAAElFTkSuQmCC"
}

export function calculateDaylineGradient(offsetLeft, width, unit = 25){
  const onePixel = 1;
  return `linear-gradient(90deg, #ffffff00, #ffffff00 ${offsetLeft - onePixel}px, #ff005c36 ${offsetLeft - onePixel}px, #ff005c36 ${offsetLeft + unit}px, #ffffff00 ${offsetLeft}px)`;
}