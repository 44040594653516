// React //
import React from "react";
import Select from "react-select";

// Redux //
import { connect } from "react-redux";
import { projectActions, userActions, clientActions } from "../../actions";
import { userUserActions, clientUserActions } from "../../userActions";
import { projectRoles } from "../../constants";

// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// Components //
import { ReactInput } from "../";
import ThemeContext from "../ContextApi/Context";

// Reusable Functions //

// Styles //

class AddAssignUserInline extends React.PureComponent {
  static contextType = ThemeContext;

  state = {
    selectedUser: "",
    selectedRole: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    company: null,
    title: "",
    actionType: { value: true, label: "ASSIGN USER" },
    roleFlag: "",
    category: []
  };

  componentDidMount() {
    if (this.props.actionType) {
      this.setState({
        actionType: "flag"
      });
    }
    this.props.AdminOrUser === "admin"
      ? this.props.dispatch(clientActions.getAllCompanies())
      : this.props.dispatch(clientUserActions.getAllCompanies());
  }

  componentDidUpdate(prevProps) {
    if (this.props.roleFlag !== this.state.roleFlag)
      this.setState({
        projectActions: projectActions,
        userActions:
          this.props.roleFlag === "adminFlag" ? userActions : userUserActions
      });

    if (this.props.companies !== prevProps.companies) {
      this.setState({
        company: {
          value: this.props.companies[0] ? this.props.companies[0].name : null,
          label: this.props.companies[0] ? this.props.companies[0].name : null,
          id: this.props.companies[0] ? this.props.companies[0]._id : null
        }
      });
    }
  }

  // ###########################################################################
  //
  // ###########################################################################

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleSelectChange = (option, event) => {
    event.name === "actionType"
      ? this.setState({
          selectedUser: "",
          selectedRole: "",
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          company: null,
          title: "",
          role: "",
          [event.name]: option,
          category: []
        })
      : this.setState({
          [event.name]: option
        });
  };

  // ###########################################################################
  //
  // ###########################################################################

  decorateAllUsers = (allUsers = []) => {
    return allUsers.map(user => {
      return { value: user._id, label: `${user.firstName} ${user.lastName}` };
    });
  };

  // ###########################################################################
  //
  // ###########################################################################

  decorateRoles = () => {
    const roles = [];
    for (const role in projectRoles) {
      roles.push({ value: role, label: projectRoles[role] });
    }
    return roles;
  };

  // ###########################################################################
  // Modal: Add user
  // ###########################################################################

  handleAddUser = e => {
    e.preventDefault();
    const projectId = this.context;

    const { dispatch, actionType, clientId } = this.props;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      company,
      title,
      selectedRole
    } = this.state;
    if (actionType) {
      dispatch(
        userActions.AddCompanyUser({
          projectId,
          firstName,
          lastName,
          email,
          phoneNumber,
          companyId: company.id,
          title,
          companyRole: selectedRole.value
          // category: category.map(cat => cat.label),
          // clientId
        })
      );
    } else {
      dispatch(
        this.state.projectActions.addUserAndAssign({
          projectId,
          firstName,
          lastName,
          email,
          phoneNumber,
          company: company.id,
          title,
          role: selectedRole.value,
          // category: category.map(cat => cat.label),
          clientId
        })
      );
    }

    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      company: null,
      title: "",
      selectedRole: ""
      // category: []
    });
  };

  // ###########################################################################
  // Assign user
  // ###########################################################################

  handleAssign = () => {
    const projectId = this.context;
    const { dispatch } = this.props;
    const { selectedUser, selectedRole } = this.state;
    const data = {
      projectId,
      userId: selectedUser.value,
      role: selectedRole.value
    };
    dispatch(this.state.userActions.setProjectRole(data));
    this.setState({
      selectedUser: "",
      selectedRole: ""
    });
  };

  render() {
    const { classes, allUsers, roleFlag, companies } = this.props;
    const {
      selectedUser,
      selectedRole,
      firstName,
      lastName,
      email,
      phoneNumber,
      company,
      title,
      actionType
    } = this.state;
    return (
      <>
        <Grid
          container
          className={classes.assignUserBox}
          justify={"space-evenly"}
        >
          <Grid
            container
            className={classes.selectAddGrid}
            justify={"space-evenly"}
          >
            {roleFlag === "adminFlag" && actionType !== "flag" && (
              <div className={classes.actionsSelect}>
                <Select
                  options={[
                    { value: true, label: "ASSIGN USER" },
                    { value: false, label: "ADD USER" }
                  ]}
                  name={"actionType"}
                  onChange={this.handleSelectChange}
                  className={classes.selectAdd}
                  required
                  placeholder="Select Action"
                  maxMenuHeight={120}
                  value={this.state.actionType}
                />
              </div>
            )}
            <div className={classes.inputsHolder}>
              {this.state.actionType.value && (
                <div className={classes.form}>
                  <div className={classes.selectAddGridItem}>
                    <Select
                      options={this.decorateAllUsers(allUsers)}
                      name={"selectedUser"}
                      onChange={this.handleSelectChange}
                      className={classes.selectAdd}
                      required
                      placeholder="Select User"
                      maxMenuHeight={120}
                      value={selectedUser}
                      isClearable={true}
                    />
                  </div>
                  <div className={classes.selectAddGridItem}>
                    <Select
                      options={this.decorateRoles()}
                      name={"selectedRole"}
                      onChange={this.handleSelectChange}
                      className={classes.selectAdd}
                      required
                      placeholder="Select Role"
                      pageSize={3}
                      value={selectedRole}
                      isClearable={true}
                    />
                  </div>
                  <div className={classes.assignButtons}>
                    {selectedUser && selectedRole ? (
                      <div>
                        <Button
                          className={classes.assignButton}
                          onClick={this.handleAssign}
                        >
                          {"assign"}
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button disabled className={classes.disabledButton}>
                          {"assign"}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* //////////////////// add new users to this project ///////////////////// */}
              {!this.state.actionType.value && (
                <form onSubmit={this.handleAddUser} className={classes.form}>
                  <div className={classes.inputsContainer}>
                    <div style={{ display: "flex" }}>
                      <div className={classes.selectAddGridItem}>
                        <ReactInput
                          required
                          name="firstName"
                          value={firstName}
                          onChange={this.handleChange}
                          placeholder="First Name"
                          className={classes.formInputs}
                          type="text"
                          autoComplete="off"
                        />
                      </div>
                      <div className={classes.selectAddGridItem}>
                        <ReactInput
                          required
                          name="lastName"
                          value={lastName}
                          onChange={this.handleChange}
                          placeholder="Last Name"
                          className={classes.formInputs}
                          type="text"
                          autoComplete="off"
                        />
                      </div>
                      <div className={classes.selectAddGridItem}>
                        <ReactInput
                          required
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                          placeholder="E-mail"
                          className={classes.formInputs}
                          type="email"
                          autoComplete="off"
                        />
                      </div>
                      <div className={classes.selectAddGridItem}>
                        <ReactInput
                          required
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={this.handleChange}
                          placeholder="Phone Number"
                          className={`${classes.formInputs} ${classes.phoneNumber}`}
                          type="number"
                          autoComplete="off"
                        />
                      </div>
                      <div
                        className={classes.selectAddGridItem}
                        style={{ position: "relative" }}
                      >
                        <Select
                          type="text"
                          name="company"
                          required
                          onChange={this.handleSelectChange}
                          placeholder="Company"
                          options={companies.map(comp => ({
                            label: comp.name,
                            value: comp.name,
                            id: comp._id
                          }))}
                          className={classes.roleAssignSelect}
                          pageSize={3}
                          value={company}
                        />
                        <input
                          tabIndex={-1}
                          style={{
                            height: 0,
                            width: 0,
                            padding: 0,
                            margin: 0,
                            opacity: 0,
                            position: "absolute",
                            top: 30,
                            left: "50px"
                          }}
                          value={company ? company : ""}
                          required
                          onChange={this.handleSelectChange}
                          name="hiddenInput"
                        />
                      </div>
                      <div className={classes.selectAddGridItem}>
                        <ReactInput
                          required
                          name="title"
                          value={title}
                          onChange={this.handleChange}
                          placeholder="Title"
                          className={classes.formInputs}
                          type="text"
                          autoComplete="off"
                        />
                      </div>
                      <div
                        className={classes.selectAddGridItem}
                        style={{ position: "relative" }}
                      >
                        <Select
                          options={this.decorateRoles()}
                          name={"selectedRole"}
                          onChange={this.handleSelectChange}
                          className={classes.roleAssignSelect}
                          required
                          placeholder="Select Role"
                          pageSize={3}
                          value={selectedRole}
                        />
                        <input
                          tabIndex={-1}
                          style={{
                            height: 0,
                            width: 0,
                            padding: 0,
                            margin: 0,
                            opacity: 0,
                            position: "absolute",
                            top: 30,
                            left: "50px"
                          }}
                          value={selectedRole}
                          required
                          onChange={this.handleSelectChange}
                          name="hiddenInput"
                        />
                      </div>
                    </div>

                    {/* {actionType === 'flag' &&
                                            <div className={classes.selectAddGridCategoryItem}>
                                                <Select
                                                    options={categoryOptions}
                                                    name={"category"}
                                                    onChange={this.handleSelectChange}
                                                    className={classes.categorySelect}
                                                    required
                                                    placeholder="Category"
                                                    pageSize={3}
                                                    value={category}
                                                    isMulti
                                                />
                                            </div>
                                        } */}
                  </div>
                  <div>
                    {/* //////added because react select dosent have required */}

                    <div className={classes.assignButtons}>
                      <Button
                        className={classes.customButton}
                        type="submit"
                        // style={actionType === 'flag' ? { height: '85px' } : { height: '38px' }}
                        style={{ height: "38px" }}
                      >
                        {"Add User"}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

const Styles = {
  customButton: {
    backgroundColor: "#155794",
    width: "100px",
    fontSize: "0.7rem",
    color: "#fff",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#3f51b5"
    }
  },
  assignButtons: {
    padding: "5px"
  },
  assignButton: {
    backgroundColor: "#58b370",
    width: "100px",
    height: "38px",
    fontSize: "0.7rem",
    color: "#fff",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#4a965e"
    }
  },
  disabledButton: {
    backgroundColor: "#9e9e9e",
    width: "100px",
    height: "38px",
    fontSize: "0.7rem",
    color: "#616161",
    borderRadius: "5px"
  },
  select: {
    margin: "10px 0"
  },

  // ////////////////// assign and add forms ////////////////////

  assignUserBox: {
    backgroundColor: "#eff0f5",
    borderRadius: "5px",
    border: "1px solid #bdbdbd",
    width: "100%",
    padding: "5px",
    direction: "row",
    justify: "space-between"
  },

  selectAddGrid: {
    direction: "row",
    justify: "space-between",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "3px"
  },
  selectAddGridItem: {
    flex: 1,
    padding: "5px"
  },
  selectAddGridCategoryItem: {
    flex: 100,
    padding: "5px",
    position: "relative"
  },
  inputsHolder: {
    flex: 1,
    display: "flex"
  },
  inputsContainer: {
    flex: 1
  },
  formInputs: {
    width: "100%"
  },
  actionsSelect: {
    minWidth: "160px",
    padding: "5px"
  },
  roleAssignSelect: {
    // width: "100%",
    fontSize: "15px"
  },
  phoneNumber: {
    "&::-webkit-inner-spin-button": {
      "- webkit - appearance": "none",
      display: "none"
    }
  },
  form: {
    display: "flex",
    width: "100%"
  },
  categorySelect: {
    width: "100%"
  },
  selectAdd: {
    width: "100%"
  }
};

const mapStateToProps = state => ({
  allUsers: state.users.users,
  roleFlag: state.projects.project ? state.projects.project.role : undefined,
  companies: state.companies ? state.companies : [],
  AdminOrUser: state.authentication.user.role
});

export default connect(mapStateToProps)(
  withStyles(Styles)(AddAssignUserInline)
);
