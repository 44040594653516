import React from "react";
import { withStyles } from "@material-ui/core";
import Logo from "../../components/img/PM.jpg";
import Typography from "@material-ui/core/Typography";
import { ReactInput } from "../../components";
import Button from "@material-ui/core/Button";
import { userUserActions } from "../../userActions";
import { snackBarActions } from "../../actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CustomSnackbar } from "../../components";
import { Link } from "react-router-dom";

class EnterEmailResetPassword extends React.PureComponent {
  state = {
    password: "",
    reenterPassword: ""
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email } = this.state;
    const { dispatch } = this.props;

    dispatch(
      userUserActions.resetPassword({
        email
      })
    );
  };

  handleChange = ({ target: { value, name } }) => {
    this.setState({
      [name]: value
    });
  };

  handleCloseSnackBar = () => {
    this.props.dispatch(snackBarActions.snackBarClose());
  };

  render() {
    const { classes, snackBar } = this.props;
    const { email } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <img src={Logo} alt="PM Design" className={classes.image} />
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className={classes.cardContainer}>
            <div className={classes.card}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.changePassword}
              >
                Reset Password
              </Typography>
              <div className={classes.inputContainer}>
                <div className={classes.label}>Enter Your Email Address</div>
                <ReactInput
                  className={classes.input}
                  type="email"
                  value={email}
                  name="email"
                  onChange={this.handleChange}
                  autocomplete="off"
                  required
                />
              </div>
              <div className={classes.loginLink}>
                <Link to={"/login"} className={classes.link}>
                  Go To Login Page
                </Link>
              </div>
              <div className={classes.submitButtonContainer}>
                <Button className={classes.submitButton} type="submit">
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </form>

        {snackBar && (
          <CustomSnackbar
            autoHideDuration={4000}
            {...snackBar}
            onClose={this.handleCloseSnackBar}
          />
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  logoContainer: {},
  cardContainer: {},
  card: {
    width: "350px",
    boxShadow: "0 0 3px #a8a8a8",
    borderRadius: "4px",
    padding: "15px"
  },
  image: {
    width: "170px"
  },
  changePassword: {
    borderBottom: "1px solid #EDEDED",
    paddingBottom: "10px",
    color: "#4d4d4d"
  },
  inputContainer: {
    marginTop: "20px"
  },
  input: {
    width: "100%",
    marginTop: "10px"
  },
  submitButton: {
    width: "100%",
    backgroundColor: "#1F7991",
    transition: "300ms",
    color: "white",
    "&:hover": {
      backgroundColor: "#2da2c2"
    }
  },
  loginLink: {
    marginTop: "10px",
    textAlign: "center"
  },
  submitButtonContainer: {
    marginTop: "20px"
  },
  link: {
    color: "#2DA2C2",
    textDecoration: "none"
  }
};

const mapToStateProps = state => ({
  snackBar: state.snackBar.snackBar
});

const EnterEmailResetPasswordStyles = withRouter(
  withStyles(styles)(EnterEmailResetPassword)
);
const EnterEmailResetPasswordConnect = connect(mapToStateProps)(
  EnterEmailResetPasswordStyles
);
export { EnterEmailResetPasswordConnect as EnterEmailResetPassword };
