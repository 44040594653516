import React from "react";
import { connect } from "react-redux";
import { AdminUsers, AdminProjects } from "../../components";
import { AdminProject, Clients, Profile } from "../../components";
import { Router, Switch, Route, withRouter } from "react-router-dom";
import { history } from "../../helpers";
import { CustomSnackbar } from "../../components";
import { snackBarActions, userActions } from "../../actions";
import { CircularWaiting } from "../../components";

import { SideBar } from "./side-bar";
// ######[ MATERIAL UI ]########################################################
import { withStyles } from "@material-ui/core";

// #############################################################################
//
// #############################################################################

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  main: {
    backgroundColor: "#eff0f5",
    height: "100vh",
    width: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
});

// #############################################################################
//
// #############################################################################

class AdminDashboard extends React.Component {
  // ###########################################################################
  //
  // ###########################################################################

  handleCloseSnackBar = () => {
    this.props.dispatch(snackBarActions.snackBarClose());
  };

  // ###########################################################################
  //
  // ###########################################################################

  componentDidMount = () => {
    const { user, dispatch } = this.props;
    if (!user) {
      dispatch(userActions.whoAmI());
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  componentDidUpdate = () => {
    this.checkURL(this.props.user, this.props.match);
  };

  // ###########################################################################
  //
  // ###########################################################################

  checkURL = (user = {}, match = {}) => {
    if (user.role && user.role !== "admin") history.push("/user-dashboard");
  };

  // ###########################################################################
  //
  // ###########################################################################

  render() {
    const { classes, snackBar, user = {} } = this.props;

    if (user && user.role === "admin") {
      return (
        <div className={classes.root}>
          <SideBar pathname={history.location.pathname} />

          <Router history={history}>
            <main className={classes.main}>
              <Switch>
                <Route path={"/admin-dashboard/users"} component={AdminUsers} />
                <Route
                  path={"/admin-dashboard/projects/:projectId"}
                  component={AdminProject}
                />
                <Route path={"/admin-dashboard/clients"} component={Clients} />
                <Route path={"/admin-dashboard/Profile"} component={Profile} />
                <Route path={"/admin-dashboard"} component={AdminProjects} />
              </Switch>
            </main>
          </Router>

          {snackBar && (
            <CustomSnackbar
              autoHideDuration={4000}
              {...snackBar}
              onClose={this.handleCloseSnackBar}
            />
          )}
        </div>
      );
    } else {
      return <CircularWaiting />;
    }
  }
}

// #############################################################################
//
// #############################################################################

const mapStateToProps = (state) => {
  return {
    snackBar: state.snackBar.snackBar,
    user: state.authentication.user,
  };
};
const AdminDashboardWithRouter = withRouter(AdminDashboard);
const AdminDashboardConnect = connect(mapStateToProps)(
  AdminDashboardWithRouter
);
const AdminDashboardStyles = withStyles(styles)(AdminDashboardConnect);

// #############################################################################
//
// #############################################################################

export { AdminDashboardStyles as AdminDashboard };
