import React from 'react';
import { TaskLine } from "./";
import { updateOrder, isEqual } from "../helpers";

// #####[ MATERIAL UI ]#########################################################
import { withStyles } from "@material-ui/core"

// #############################################################################
// component
// #############################################################################

class Tasks extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      originalTasks: this.props.tasks,
      originalTasksNested: this.props.tasksNested,
      tasks: this.props.tasks,
      rawData: this.props.rawData,
      tasksNested: this.props.tasksNested,
      dragItem: {},
    }
  }

  // ###########################################################################
  // 
  // ###########################################################################

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if(!isEqual(nextProps.tasksNested, prevState.originalTasksNested)){
      return {
        originalTasks: nextProps.tasks,
        originalTasksNested: nextProps.tasksNested,
        tasks: nextProps.tasks,
        rawData: nextProps.rawData,
        tasksNested: nextProps.tasksNested,
      }
    }
    else return null
  }

  // ###########################################################################
  // set the dragged item
  // ###########################################################################

  setDragItem = (data) => {
    this.setState({dragItem: data});
  }

  // ###########################################################################
  // handle sort
  // ###########################################################################

  handleSort = dropItem => {
    const { dragItem } = this.state;
    const [rawData, tasks] = updateOrder(this.state.rawData, dragItem, dropItem);

    this.setState({ rawData, tasks })

    const data = {order: dropItem.order,  projectId: dropItem.project, taskId: dragItem._id };
    this.props.changeTaskOrder(data);
  }

  getKeys = (object, parent = null) => {
    return Object.keys(object).filter(key => object[key].parentTask === null).sort((a,b) => object[a].order - object[b].order);
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render = () => {
    const { tasks = {} } = this.state;

    const { maxEdge, minEdge, width, users = [], project, rawData = [] } = this.props;
    const { handleEditTask, handleDelete, handleAddTask  } = this.props;
    const { classes = [], draggable = false, handleExpand} = this.props;
    const keys = this.getKeys(tasks, null);

    return (
      <React.Fragment>
        {keys.map(key =>
          <TaskLine

            key={key}
            tasks={tasks}
            task={tasks[key]}
            project={project}
            users={users}
            rawData={rawData}

            draggable={draggable}
            dragItem={this.state.dragItem}

            classes={classes}
            width={width}
            minEdge={minEdge}
            maxEdge={maxEdge}

            handleSort={this.handleSort}
            setDragItem={this.setDragItem}
            handleExpand={handleExpand}
            handleAddTask={handleAddTask}
            handleEditTask={handleEditTask} 
            handleDelete={handleDelete}
            handleExpandForm={this.props.handleExpandForm}
            handleListOfChanges={this.props.handleListOfChanges}
          />
        )}
      </React.Fragment>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  // ###########################################################################
  // classes for the TaskLine components
  root:{
    display: "flex",
    position: "relative",
    transition:"padding 100ms, background-color 100ms",
  },

  rootWithNoTransition:{
    display: "flex",
    position: "relative",
    transition:"background-color 100ms",
    "&:hover":{
      backgroundColor: "#00385d0f",
    },
    "&:hover > main:nth-child(3)":{
      height: 14,
      bottom: 2,
    },
  },

  wrapper:{
    left:6,
    position: 'sticky',
    backgroundColor: "#fff",
    overflow: "hidden",
    display: "flex",
    minHeight: 50,
    transition:"width 70ms",
    width: 300,
    padding: 5,
    margin:"3px 6px",
    alignItems: "center",
    boxSizing:"border-box",
    boxShadow:"0 2px 5px 0px #00000024, 0 2px 2px -2px #000000bd",
    zIndex: 10,
    borderRadius: 4,
    opacity: 0.95,
  },
  // ###########################################################################
  dragHandler:{
    position: "absolute",
    left: 0,
    height: 56,
  },

  dragIcon:{
    color: "#00284c6b",
    width: 24,
    height: 24,
  },
  dragHolder:{
    transition: "width 100ms",
    overflow: "hidden",
    width: "1.5em",
    height: 24,
  },
  dragIconHide:{
    width: "0em",
    overflow: "hidden",
  },
  // ###########################################################################
  taskName:{
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "-khtml-user-select": "none",
    "-webkit-user-select": "none",
    "-webkit-touch-callout": "none",
    pointerEvents: "none",
    fontSize: 12,
    flex: 1,
    padding: "0px 5px",
    fontWeight: 500,
    color: "#00091994",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  bold:{
    fontWeight: 600,
    color: "#000919b5",
  },
  // ###########################################################################
  button:{
    padding: 0,
    color: "#00000020"
  },
  orange: {
    color:"#d68400",
    transition: "all 150ms"
  },
  open:{
    color:"#00d060",
    transform: "rotate(90deg)"
  },
  // ###########################################################################
  actionIcons:{
    fontSize: 18
  },
  editIcon:{
    padding: 2,
    color: "#39fb66",
    margin: 1,

  },
  addIcon:{
    padding: 2,
    color: "#009dff",
    margin: 1,
  },
  cancelIcon:{
    padding: 2,
    color: "red",
    margin: 1,
  },
  infoIcon:{
    padding: 2,
    color: "#ffb500",
    margin: 1,
  },
  // ###########################################################################
  hideInside:{
    backgroundColor: "#00000000",
    boxShadow: "none",
    "& > * ": {
      opacity: 0
    }
  },
  // ###########################################################################
  highlight: {
    backgroundColor: "#00000014",
  }
});

// #############################################################################
// connect material styles and REDUX state to component
// #############################################################################

const TasksWithStyles = withStyles(styles)(Tasks)

// #############################################################################
// export
// #############################################################################

export  { TasksWithStyles as  Tasks };