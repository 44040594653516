import React from 'react';
import { withStyles } from "@material-ui/core";
import { projectStatus } from "../constants";
import { connect } from 'react-redux'
import { projectActions } from "../actions";
import { projectUserActions } from "../userActions";

// #############################################################################
// component
// #############################################################################

class GeneralInfo extends React.PureComponent {

  state = {
    project: this.props.project ? this.props.project : [],
    status: { label: '', value: '' }
  }

  componentDidMount() {
    this.setState({
      status: { label: this.props.project.status, value: this.props.project.status }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props)
      this.setState({
        status: { label: this.props.project.status, value: this.props.project.status }
      })
  }

  projectStatuses = () => {
    return projectStatus.map(item => ({ label: item, value: item }))
  }

  handleSelectChange = (value, option) => {
    this.setState({ [option.name]: value },
      () => {
        if (this.props.role === 'adminFlag')
          this.props.dispatch(projectActions.editProject({ projectId: this.props.projectId, status: this.state.status.value }))
        else
          this.props.dispatch(projectUserActions.editProject({ projectId: this.props.projectId, status: this.state.status.value }))
      }
    );
  };

  handleProjectStatusColor = (status) => {
    const { classes } = this.props;
    switch (status) {
      case "NOT STARTED":
        return <div className={classes.status} style={{ color: "#d0d0d0"}}>{status}</div>
      case "CANCELED":
        return <div className={classes.status} style={{ color: "#e64e00"}}>{status}</div>
      case "FINISHED":
        return <div className={classes.status} style={{ color: "#00de67"}}>{status}</div>
      case "OPEN":
        return <div className={classes.status} style={{ color: "#0cbbd0"}}>{status}</div>
      case "ON HOLD":
        return <div className={classes.status} style={{ color: "orange"}}>{status}</div>
      default:
        return;
    }
  }

  render() {
    const { project = {}, classes } = this.props;
    const { status } = this.state
    return (
      <div className={classes.root}>
        {/*######################################################################*/}
        <div className={`${classes.container}`}>
          <label className={classes.label}>Project Name:</label>
          <span className={classes.value}>{project.name}</span>
        </div>
        {/*######################################################################*/}
        <div className={classes.container} >
          <label className={classes.label}>City:</label>
          <span className={classes.value}>{project.city}</span>
        </div>
        {/*######################################################################*/}
        <div className={classes.container} >
          <label className={classes.label}>State:</label>
          <span className={classes.value}>{project.state}</span>
        </div>
        {/*######################################################################*/}
        <div className={classes.container} >
          <label className={classes.label}>Region:</label>
          <span className={classes.value}>{project.region}</span>
        </div>
        {/*######################################################################*/}
        <div className={classes.container} >
          <label className={classes.label}>Site Number:</label>
          <span className={classes.value}>{project.siteNumber}</span>
        </div>
        {/*######################################################################*/}
        <div className={`${classes.container} ${classes.projectStatus}`}>
          <label className={classes.label}>Project Status:</label>
          <span className={classes.value}>{this.handleProjectStatusColor(status.value)}</span>
        </div>
        {/*######################################################################*/}
      </div>
    )
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root: {
    display: "flex",
    padding: "6px 15px",
    color: "white",
    borderTop: "1px solid #e1e1e1",
    backgroundColor: "#f4f5f9",
  },
  status:{
    padding: 0,
  },
  container: {
    display: "flex",
    alignItems: "center",
    paddingRight: 15
  },
  value: {
    color: "#000000ab",
    fontSize: 14,
    fontWeight: 600,
  },
  label: {
    color: "#0000008c",
    fontSize: "12px",
    fontWeight: 500,
    padding: "2px 5px",
  },
  projectNameInput: {
    flex: 1,
    backgroundColor: "#00000000 !important",
    fontSize: "16px",
    fontWeight: "700",
    color: "#0000009e"
  },
  reactSelect: {
    width: '150px',
    zIndex: 1000,
    marginLeft: '20px',
    color: '#8A92CF',
    cursor: 'pointer!important',
    fontSize: '13px'
  },
  option: {
    padding: ' 10px!important'
  },
  projectStatus: {
    flex: 2
  }
})

// #############################################################################
// connect to material styles
// #############################################################################

const mapStateToProps = state => {
  return {
    status: state.project ? state.project.status ? state.project.status : '' : '',
    projectId: state.projects.project ? state.projects.project._id : '',
    role: state.projects.project ? state.projects.project.role: undefined,
    project: state.projects.project ? state.projects.project : {}
  }
}

// #############################################################################
// connect to material styles
// #############################################################################
const GeneralInfoWithStyles = withStyles(styles)(GeneralInfo)
const connectedToRedux = connect(mapStateToProps)(GeneralInfoWithStyles)

// #############################################################################
// export
// #############################################################################
// 

export { connectedToRedux as GeneralInfo }


