import { costConstants } from "../constants";

export function costs(state = {}, action) {
  switch (action.type) {
    case costConstants.ADD_COST_SUCCESS:
      return { ...state, costs: action.payload };

    case costConstants.ADD_COST_FAILURE:
      return { ...state, msg: action.payload };

    case costConstants.GET_PROJECT_COSTS_SUCCESS:
      return { ...state, costs: action.payload };

    case costConstants.GET_PROJECT_COSTS_FAILURE:
      return { ...state, msg: action.payload };

    case costConstants.EDIT_COST_SUCCESS:
      return { ...state, costs: action.payload };

    case costConstants.EDIT_COST_FAILURE:
      return { ...state, msg: action.payload };

    case costConstants.APPROVE_COST_SUCCESS:
      return { ...state, costs: action.payload };

    case costConstants.APPROVE_COST_FAILURE:
      return { ...state, msg: action.payload };

    case costConstants.DELETE_COST_SUCCESS:
      return { ...state, costs: action.payload };

    case costConstants.DELETE_COST_FAILURE:
      return { ...state, msg: action.payload };

    case costConstants.ADD_ATTACHMENT_TO_COST_SUCCESS: {
      const index = findIndex(state.costs, "_id", action.payload);
      return {
        ...state,
        costs: [
          ...state.costs.slice(0, index),
          { ...state.costs[index], hasAttachment: true },
          ...state.costs.slice(index + 1)
        ]
      };
    }

    case costConstants.GET_COST_TOTALS_SUCCESS:
      return { ...state, costTotals: action.payload };

    case costConstants.CLEARING_COSTS_DATA:
      return {};

    default:
      return state;
  }
}
// #############################################################################
//  find the index of searched item in the array
// #############################################################################

function findIndex(array, key, searchKey) {
  return array.findIndex(item => item[key] === searchKey);
}
