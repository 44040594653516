import React, { PureComponent } from 'react';
import { ReactInput } from "../"

// #####[ MATERIAL UI ]#########################################################
import { withStyles } from "@material-ui/core"
import IconButton from '@material-ui/core/IconButton';

// #####[ MATERIAL ICON ]#######################################################
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from "@material-ui/icons/Close"

// #############################################################################
// component
// #############################################################################

class Search extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: ""
    };
  }

  // ###########################################################################
  // handle change in inputs
  // ###########################################################################

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  // ###########################################################################
  // this function will find all the sub-projects of the selected project
  // ###########################################################################

  findAllSubs = (selectedProject = {}, projects = {}, outPutArray = [], number = 0) => {
    if(selectedProject.subProject.length > 0){
      selectedProject.subProject.forEach(key => { 
        if(projects[key].subProject.length > 0) outPutArray = [key, ...this.findAllSubs(projects[key], projects, outPutArray, number)]
        else outPutArray = [...outPutArray, key];
      })
      return outPutArray
    }
    return [];
  }

  // ###########################################################################
  // this function will search on the projects based on the search query in state
  // ###########################################################################

  handleSearch = (projects, searchQuery = "", selectedProject = {}) => {
    const keyWordLowerCase = searchQuery.toLowerCase();
    const subProjects = this.findAllSubs(selectedProject, projects);
    return subProjects.filter(key => projects[key].name.toLowerCase().includes(keyWordLowerCase))
  }

  // ###########################################################################
  // handle select
  // ###########################################################################

  handleSelect = (projectId) => () => {
    this.clearSearch();
    if(this.props.handleSearch) this.props.handleSearch(projectId)
  }

  // ###########################################################################
  // clear search 
  // ###########################################################################

  clearSearch = () =>{
    this.setState({searchQuery: ""})
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const { classes, projects = {}, selectedProject } = this.props;
    const { searchQuery } = this.state
    const searchResult = searchQuery !== "" ? this.handleSearch(projects, searchQuery, selectedProject) : [];

    return (
      <div className={classes.root}>
        <div onClick={this.props.clearFucos} className={classes.inputAndButtonHolder}>

          <ReactInput autoComplete="off" value={searchQuery} name="searchQuery" onChange={this.handleChange} className={classes.input} placeholder="Search Project" />

          <IconButton aria-label="search" className={classes.searchButton}>
            <SearchIcon fontSize="small" />
          </IconButton>

          {searchResult.length === 0 ? null :
            <div className={classes.listHolder}>

              <div className={classes.titleHolder}>
                <span className={classes.title}>Search Result</span>
                <IconButton onClick={this.clearSearch} className={classes.closeIcon}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>

              <ul className={classes.list}>
                { searchResult.map( id => ( <li key={id} onClick={this.handleSelect(id)} className={classes.listItem}>{projects[id].name}</li> ) ) }
              </ul>

            </div>
          }
        </div>
      </div>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({

  root:{
    display: "flex",
    justifyContent: "flex-end",
    position: "relative"
  },
  inputAndButtonHolder:{
    display: "flex",
    alignItems: "center",
    position: "absolute",
    width: 400,
    bottom: -55,
    right: 7,
    zIndex: 1000,

  },
  searchButton:{
    position: "absolute",
    padding: 3,
    width: 36,
    height: 36,
    right: 0,
    borderRadius: "0 3px 3px 0",
    background: "#477599",
    color: "white",
    "&:hover": {
      background: "#155794",
    }
  },
  input:{
    width: 400,
  },
  listHolder:{
    position: "absolute",
    top: 48,
    background: "white",
    border: "1px solid #e1e1e1",
    borderRadius: 3,
  },
  list: {
    width: 400,
    boxSizing: "border-box",
    padding: "9px 0px",
    margin: "0",
    listStyle: "none",
    maxHeight: 160,
    overflow: "auto",
  },
  listItem:{
    color: "#545454",
    fontSize: 14,
    padding: "5px 10px",
    "&:hover": {
      background: "#00000010",
      cursor: "pointer"
    }
  },
  titleHolder:{
    background: "#477599",
    borderRadius: "3px 3px 0 0",
    padding: "8px 10px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    color: "white",
    marginBottom: 1
  },
  closeIcon:{
    position: "absolute",
    color: "white",
    padding: 4,
    right: 7,
  }
});

// #############################################################################
// connect material styles and redux state to component
// #############################################################################

const SearchWithStyles = withStyles(styles)(Search)
export  { SearchWithStyles as Search };

