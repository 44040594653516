import React from 'react';
import { monthNames } from "../helpers"
import { weekDaysName } from "../helpers"
import Tooltip from "@material-ui/core/Tooltip"

// #############################################################################
// component
// #############################################################################

export function Month(props){

  const { classes, data = [], unit = 25 } = props;
  const monthName = monthNames[data[0].getMonth()];
  const year = data[0].getFullYear();
  const arrayLength = data.length;
  const width = arrayLength * unit;

  return (
    <div className={classes.monthRoot} style={{width}}>
      <div className={classes.monthContainer}>
        <div className={classes.monthLabel}>
          <span>{monthName} </span>
          <span className={classes.yearLabel}>{year}</span>
        </div>
      </div>
        <div className={classes.days}>
          { data.map((item, index) => (
              day({classes: classes, key: index, data: item, lastDayOfMonth: arrayLength === (index + 1) })
          ))}
        </div>
    </div>
  );
}

// #############################################################################
// day function
// #############################################################################

function  day(props){
  const { classes, data, lastDayOfMonth, key } = props;
  const date = data.getDate();
  const weekDay = data.getDay();
  const day = weekDaysName[weekDay]
  const isWeekend = weekDay  === 0 || weekDay  === 6;
  const today = data.toJSON() === new Date(new Date().setHours(0,0,0,0)).toJSON();

  return (
    <Tooltip key={key} classes={{tooltip: classes.tooltip}} title={`${day.name}${today ? " (today)" : ""}`}>
      <div className={`${classes.dayRoot} ${ weekDay === 0 ? classes.borderDark: null}`}>
        <div className={`${classes.weekDay} ${ isWeekend ? classes.redText: null}`}>{day.symbol}</div>
        <div className={`${classes.date} ${ isWeekend ? classes.redText: null}`}>{date}</div>

        {lastDayOfMonth ? <div className={classes.monthDivider} /> : null}
        {today ? <div className={classes.stripe}/> : null}
      </div>
    </Tooltip>
  );
}
