import React from "react";
import { connect } from "react-redux";

// #####[ Material UI ]#########################################################
import { withStyles } from "@material-ui/core";

import { issueUserActions, projectUserActions } from "../../../userActions";
import { filterActions } from "../../../actions";

import { IssueList } from "../../IssueList/IssueList";

import AddIssueInline from "../../IssueList/AddIssueInline";
import ThemeContext from "../../ContextApi/Context";

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  issues: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }
});

// #############################################################################
// component
// #############################################################################

class UserProjectIssues extends React.PureComponent {
  static contextType = ThemeContext;

  componentDidMount = () => {
    const projectId = this.context;
    const { dispatch } = this.props;
    dispatch(issueUserActions.getProjectIssues({ projectId }));
    dispatch(projectUserActions.getProjectUsers({ projectId }));
    dispatch(filterActions.pageFilterEdit(""));
  };

  render() {
    const { classes, role } = this.props;
    return (
      <div className={classes.issues}>
        {role && <AddIssueInline />}
        <IssueList pageCapacity={7} />
      </div>
    );
  }
}

// #############################################################################
// connect REDUX state and styles to the component
// #############################################################################

const mapStateToProps = state => ({
  role:
    state.projects.project && state.projects.project.role !== "Project-Client"
      ? true
      : false
});

const UserProjectIssuesConnected = connect(mapStateToProps)(UserProjectIssues);
const UserProjectIssuesStyles = withStyles(styles)(UserProjectIssuesConnected);

// #############################################################################
// export
// #############################################################################

export { UserProjectIssuesStyles as UserProjectIssues };
