import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from "@material-ui/core";
import { Colors } from '../../styles/colors'

const styles = {
    saveButton: {
        width: '70px',
        backgroundColor: Colors.green,
        color: "white",
        height: "38px",
        margin: "0px 2px",
        fontSize: "11px",
        fontWeight: "900",
        display: 'inline-block',
        "&:hover": {
            backgroundColor: Colors.darkGreen,
        },
        "&:active": {
            backgroundColor: Colors.darkGreen
        },
        '&:disabled': {
            backgroundColor: Colors.grey,
            color: 'white'
        }
    },
}

export const SaveButton = React.memo(withStyles(styles)(({ classes, onClick, disabled, type }) => (
    < Button className={classes.saveButton} onClick={onClick} disabled={disabled} type={type}>SAVE</Button >
)))