import { getTimeGap } from "./"

// #############################################################################
// calculates the width and position of progress based on time 
// #############################################################################

export function calculateWithAndPosition(leftEdge ,startTime, finishTime, unit = 25){
  const startPosition = new Date ( new Date(startTime).setHours(0,0,0,0));
  const finishPosition = new Date ( new Date(finishTime).setHours(0,0,0,0));
  const position = (getTimeGap(leftEdge, startPosition) * unit);
  const width = (getTimeGap(startPosition, finishPosition) * unit) + unit - 1;
  return [position, width];
}

// #############################################################################
// 
// #############################################################################