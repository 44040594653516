import React from 'react'
import { withStyles } from '@material-ui/core'
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../styles/colors'

const styles = {
    iconButton: {
        padding: '3px !important',
        color: Colors.lightGreen,
    },
    fileCopy: {
        color: '#88B0D3'
    }
}

export const FileCopyIcon = React.memo(withStyles(styles)(({ classes, onClick }) => (
    <Tooltip title="Copy">
        <IconButton onClick={onClick} className={classes.iconButton} component="span">
            <FileCopyOutlined className={classes.fileCopy} />
        </IconButton>
    </Tooltip>
))
)
