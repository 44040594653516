import {
  attachemntConstants,
  costConstants,
  noteConstants
} from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "./";

export const attachmentActions = {
  getAttachments,
  addAttachment,
  addFolder,
  editAttachment,
  deleteAttachment,
  changeAttachmentOrder
};

//################################################################################
// GET Attachments
//################################################################################

function getAttachments(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getAttachments", data).then(
      response => {
        if (response.success === true) {
          dispatch(success(response.attachments));
        } else {
          dispatch(failure("Try Again!!!"));
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(attachments) {
    return {
      type: attachemntConstants.GET_ATTACHMENTS_SUCCESS,
      payload: attachments
    };
  }
  function failure(error) {
    return {
      type: attachemntConstants.GET_ATTACHMENTS_FAILURE,
      payload: error
    };
  }
}

//################################################################################
// Add Attachments
//################################################################################

function addAttachment(attachment, handleUploadPercentage) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("addAttachment", attachment).then(
      response => {
        if (response.success === true) {
          if (attachment.get("costId") !== null) {
            dispatch(updateCostList(attachment.get("costId")));
            dispatch(snackBarActions.snackBarSuccess("Successful"));
          } else if (attachment.get("noteId")) {
            dispatch(
              addToNoteSuccess(response.attachments, attachment.get("noteId"))
            );
            dispatch(snackBarActions.snackBarSuccess("Successful"));
          } else {
            //for changing message in adding versions and files
            const attachments = response.attachments;
            let verstionFlag = false;
            let fileFlag = false;

            for (var i = 0; i < attachments.length; i++) {
              if (attachments[i].mainVersionId) verstionFlag = true;
              else fileFlag = true;
              if (verstionFlag && fileFlag) {
                dispatch(
                  snackBarActions.snackBarSuccess(
                    "Files And Versions Added Successfully"
                  )
                );
                break;
              }
            }
            if (fileFlag && !verstionFlag) {
              dispatch(
                snackBarActions.snackBarSuccess("Files Added Successfully")
              );
            } else if (verstionFlag && !fileFlag) {
              dispatch(
                snackBarActions.snackBarSuccess("Versions Added Successfully")
              );
            }
            dispatch(success(attachments));
          }
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(file) {
    return { type: attachemntConstants.ADD_ATTACHMENT_SUCCESS, payload: file };
  }
  function updateCostList(costId) {
    return {
      type: costConstants.ADD_ATTACHMENT_TO_COST_SUCCESS,
      payload: costId
    };
  }
  function addToNoteSuccess(data, noteId) {
    return {
      type: noteConstants.ADD_ATTACHMENT_TO_NOTE_SUCCESS,
      payload: data,
      noteId
    };
  }
  // function failure(error) {
  //   return { type: attachemntConstants.ADD_ATTACHMENT_FAILURE, payload: error };
  // }
}

//################################################################################
// Upload Folder
//################################################################################

function addFolder(attachment, handleUploadPercentage) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("addFolder", attachment).then(
      response => {
        if (response.success === true) {
          dispatch(snackBarActions.snackBarSuccess("Successful"));
          dispatch(success(response.attachment));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(attachment) {
    return {
      type: attachemntConstants.ADD_FOLDER_SUCCESS,
      payload: attachment
    };
  }
  // function failure(error) {
  //   return { type: attachemntConstants.ADD_FOLDER_FAILURE, payload: error };
  // }
}

//################################################################################
// edit Attachments
//################################################################################

function editAttachment(attachment, allNewDocuments) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editAttachment", attachment).then(
      response => {
        if (response.success === true) {
          dispatch(
            snackBarActions.snackBarSuccess("Attachment edited successfully")
          );
          if (attachment.projectDocument)
            if (attachment.deleteExistAttachment === true) {
              dispatch(
                projectDocumentTabEditReplaceOldFileSuccess(
                  attachment,
                  response.attachment,
                  allNewDocuments
                )
              );
            } else if (attachment.deleteExistAttachment === false)
              dispatch(
                projectDocumentTabEditKeepBothSuccess(
                  attachment,
                  response.attachment,
                  allNewDocuments
                )
              );
            else dispatch(projectDocumentTabEditSuccess(allNewDocuments));
          else dispatch(success(response.attachment));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(file) {
    return { type: attachemntConstants.EDIT_ATTACHMENT_SUCCESS, payload: file };
  }
  // function updateCostList(costId) {
  //   return { type: costConstants.EDIT_ATTACHMENT_TO_COST_SUCCESS, payload: costId }
  // }
  // function failure(error) {
  //   return {
  //     type: attachemntConstants.EDIT_ATTACHMENT_FAILURE,
  //     payload: error
  //   };
  // }
  function projectDocumentTabEditSuccess(array) {
    return {
      type: attachemntConstants.PROJECT_DOCUMENT_TAB_EDIT_SUCCESS,
      payload: array
    };
  }
  function projectDocumentTabEditKeepBothSuccess(
    sentDocument,
    newDocument,
    allNewDocuments
  ) {
    return {
      type: attachemntConstants.PROJECT_DOCUMENT_TAB_EDIT_KEEP_BOTH_SUCCESS,
      payload: { sentDocument, newDocument, allNewDocuments }
    };
  }
  function projectDocumentTabEditReplaceOldFileSuccess(
    sentDocument,
    newDocument,
    allNewDocuments
  ) {
    return {
      type:
        attachemntConstants.PROJECT_DOCUMENT_TAB_EDIT_REPLACE_OLD_FILE_SUCCESS,
      payload: { sentDocument, newDocument, allNewDocuments }
    };
  }
}

//################################################################################
// Delete Attachment
//################################################################################

function deleteAttachment(attachment) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteAttachment", attachment).then(
      response => {
        if (response.success === true) {
          dispatch(
            snackBarActions.snackBarSuccess("The file Successfully removed!")
          );
          if (response.noteId) {
            dispatch(
              addToNoteSuccess({
                attachmentId: attachment.attachmentId,
                noteId: response.noteId
              })
            );
          }
          dispatch(success(attachment));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(attachment) {
    return {
      type: attachemntConstants.DELETE_ATTACHMENT_SUCCESS,
      payload: attachment
    };
  }
  function addToNoteSuccess(data) {
    return {
      type: noteConstants.DELETE_ATTACHMENT_TO_NOTE_SUCCESS,
      payload: data
    };
  }
  // function failure(error) {
  //   return {
  //     type: attachemntConstants.DELETE_ATTACHMENT_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Change Attachment Order
//################################################################################

function changeAttachmentOrder(attachment, allNewDocuments) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("changeAttachmentOrder", attachment).then(
      response => {
        if (response.success === true) {
          dispatch(
            snackBarActions.snackBarSuccess("Attachment edited successfully!")
          );
          dispatch(success(attachment));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(attachment) {
    return {
      type: attachemntConstants.CHANGE_ATTACHMENT_ORDER_SUCCESS,
      payload: allNewDocuments
    };
  }
  // function failure(error) {
  //   return {
  //     type: attachemntConstants.CHANGE_ATTACHMENT_ORDER_FAILURE,
  //     payload: error
  //   };
  // }
}
