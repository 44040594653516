import axios from "axios";
import { API } from "../constants";
import { history } from "../helpers";

//################################################################################
// all the exports exports
//################################################################################

export const generalServices = {
  service
};

//################################################################################
// Get Activity
//################################################################################

function service(url, data, role = "admin", repeatFlag = true) {
  const token = JSON.parse(
    localStorage.getItem("token") !== "undefined"
      ? localStorage.getItem("token")
      : "{}"
  );
  return axios({
    url: `${API}/api/${role}/${url}`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    },
    data
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        return response.data;
      }
    })
    .catch(err => {
      const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
      if (!refreshToken) {
        history.push("/login");
        return Promise.reject({
          message: "You Need To Login Again"
        });
      }
      if (!err.response) {
        return Promise.reject({
          message: "Failed To Connect To Server"
        });
      }
      if (err.response.status === 401) {
        // refreshToken exist / token not exist
        if (repeatFlag) {
          return loginWithToken(refreshToken)
            .then(() => {
              return service(url, data, role, false);
            })
            .catch(({ msg }) => {
              history.push("/login");
              return Promise.reject({
                message: "You Need To Login Again"
              });
            });
        } else {
          return Promise.reject({
            message: "You Need To Login Again"
          });
        }
      } else if (err.response.status === 500) {
        return Promise.reject({
          message: "Failed To Connect To Server"
        });
      } else if (err.response.status === 404) {
        return Promise.reject({
          message: "Something Wrong Happened (404)"
        });
      } else if (err.response.status === 403) {
        return Promise.reject({
          message: "Access Forbidden (403)"
        });
      } else if (err.response.status === 400) {
        return Promise.reject({
          message: "Something Wrong Happened (400)"
        });
      } else {
        // refreshToken && token not exist
        history.push("/login");
        return Promise.reject({
          message: "You Need To Login Again"
        });
      }
    });
}

//################################################################################
// Get Activity
//################################################################################

function loginWithToken(token) {
  return axios({
    url: `${API}/api/loginWithToken`,
    method: "POST",
    headers: {
      Authorization: "JWT " + token,
      "content-type": "application/json"
    }
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      } else {
        localStorage.setItem("token", JSON.stringify(response.data.token));
        return;
      }
    })
    .catch(err => {
      return Promise.reject({
        message: "You Need To Login Again"
      });
    });
}
