import React from "react";
import { withStyles } from "@material-ui/core";
import EditOutlined from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Colors } from "../../styles/colors";

const styles = {
  iconButton: {
    padding: "3px !important",
    color: Colors.lightGreen,
    fontSize: "12px"
  }
};

export const EditIcon = React.memo(
  withStyles(styles)(({ classes, onClick, style = {} }) => (
    <Tooltip title="Edit">
      <IconButton
        onClick={onClick}
        className={classes.iconButton}
        component="span"
        style={style}
      >
        <EditOutlined className={classes.editIcon} style={style} />
      </IconButton>
    </Tooltip>
  ))
);
