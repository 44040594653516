import { dateInRow } from "./"

// #############################################################################
// this function will calculate the time gap between to date
// #############################################################################

export function getTimeGap(start = new Date(), finish = new Date()){
  let gap = 0;
  if(dateInRow(start) < dateInRow(finish)){
	  for(let date = new Date(start); dateInRow(date) < dateInRow(finish); date = new Date (date.setDate(date.getDate() + 1)))
	    ++gap
  } else if((dateInRow(start) > dateInRow(finish))) {
  	for(let date = new Date(start); dateInRow(date) > dateInRow(finish); date = new Date (date.setDate(date.getDate() - 1)))
	    --gap
  }
  return gap;
}
