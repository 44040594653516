import React from "react";

// #####[ material ui ]##############################################################
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
// #####[ redux ]##############################################################
import { connect } from "react-redux";
// #####[ reusable functions ]##############################################################
import pastTimeSoFar from "../reusebleFunctions/pastTimeSoFar";
// #####[ styles ]##############################################################
import { Colors } from "../../styles";

// #####[ components ]##############################################################

class CommentModalTabItemReplay extends React.PureComponent {
  state = {
    editMode: false,
    text: "",
    isAddNewComment: false
  };

  handleReplayClick = () => {
    this.setState(prevState => ({
      isAddNewComment: !prevState.isAddNewComment
    }));
    this.props.handleIsReplyActive && this.props.handleIsReplyActive();
  };

  handleCancelAddNewComment = () => {
    this.setState({
      isAddNewComment: false
    });
    this.props.handleIsReplyActive();
  };

  render() {
    const { classes, comment } = this.props;
    return (
      <>
        <Grid container className={classes.commentItemWrapper}>
          <Grid container>
            <Grid className={classes.photoWrapper}>
              <div style={{ width: "70px" }} />
            </Grid>

            <Grid item xs className={classes.commentTextAndAttachmentWrapper}>
              <p className={classes.commentText}>
                <span
                  className={classes.name}
                >{`${comment.owner.firstName} ${comment.owner.lastName}:`}</span>
                {comment.text}
              </p>
              {/* )} */}
            </Grid>
            <Grid item xs={12} className={classes.time}>
              {pastTimeSoFar(comment.createDate)}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const styles = {
  commentItemWrapper: {
    backgroundColor: "white",
    padding: "5px",
    borderTop: "1px solid #f0f0f0"
  },
  photoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "6px",
    width: "75px"
  },
  image: {
    width: "70px"
  },
  commentTextAndAttachmentWrapper: {
    // backgroundColor: 'blue',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingRight: "10px",
    fontsize: "13px"
  },
  name: {
    color: Colors.darkBlue,
    fontWeight: "500",
    marginRight: "7px",
    fontSize: "14px"
  },
  commentText: {
    margin: "2px 5px",
    fontSize: "14px",
    color: Colors.grey
  },
  attachmentWrapper: {
    margin: "10px 0",
    overflow: "hidden"
  },
  actionsAndTimeWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  iconWrapper: {
    padding: "3px!important"
  },
  infoIconWrapper: {
    color: Colors.yellow
  },
  time: {
    color: Colors.grey,
    fontSize: "11px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingRight: "10px",
    marginTop: "7px"
  },
  textarea: {
    width: "99%",
    border: `1px solid ${Colors.lightGrey}`,
    color: Colors.grey,
    fontSize: "14px",
    marginLeft: "5px",
    height: "auto"
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start"
  },
  actionEditWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "13px"
  },
  cancelButton: {
    fontWeight: 900,
    fontSize: "10px",
    height: "38px",
    width: "70px",
    // box-sizing: border-box;
    borderRadius: "5px",
    backgroundColor: "#AFADAD",
    color: "#ffffff",
    display: "inline-block",
    border: "none",
    cursor: "pointer",
    margin: "2px"
  },
  commentButton: {
    color: Colors.grey,
    borderRadius: "15px",
    border: `1px solid ${Colors.darkenThanLightGrey}`,
    fontSize: "11px",
    padding: "3px 8px!important"
  },
  actionAndReplayWrapper: {},
  replayWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: " 7px"
  },
  replayButton: {
    fontSize: "12px",
    color: Colors.darkBlue,
    padding: 0
  }
};

const mapStateToProps = state => {
  return {
    comments: state.comments,
    role: state.projects.project ? state.projects.project.role : undefined,
    projectId: state.projects.project ? state.projects.project._id : undefined
  };
};

const tempTemp = connect(mapStateToProps)(
  withStyles(styles)(CommentModalTabItemReplay)
);

export { tempTemp as CommentModalTabItemReplay };
