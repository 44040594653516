// #############################################################################
// this function will calculate the stops on the PolyLine
// #############################################################################

export function generateLine(dots, horizontalUnit = 13, verticalUnit = 28, plus = 0.5){
  const [startX, startY, endX, endY] = dots
  const x1 = ((endX - startX) / 2 ) <= 1 ? (startX + horizontalUnit) : (startX + endX) / 2;
  const y1 = startY;

  const x2 = x1;
  const y2 = endY < startY ? endY - verticalUnit * (endY > startY ? 1 : -1): startY + verticalUnit * (endY > startY ? 1 : -1);

  const x3 = ((endX - startX) / 2 ) <= 1 ? (endX - horizontalUnit) : (startX + endX) / 2;
  const y3 = y2;

  const x4 = x3;
  const y4 = endY;
  
  return `${parseInt(startX, 10) + plus},${parseInt(startY, 10) + plus} ${parseInt(x1, 10) + plus},${parseInt(y1, 10) + plus} ${parseInt(x2, 10) + plus},${parseInt(y2, 10) + plus} ${parseInt(x3, 10) + plus},${parseInt(y3, 10) + plus} ${parseInt(x4, 10) + plus},${parseInt(y4, 10) + plus} ${parseInt(endX, 10) + plus},${parseInt(endY, 10) + plus}`
}