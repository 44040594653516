import { noteConstants } from "../constants";

export function notes(state = [], action) {
  switch (action.type) {
    case noteConstants.GET_ALL_NOTES_SUCCESS:
      return action.payload;

    case noteConstants.ADD_NOTE_SUCCESS:
      return [action.payload, ...state];

    case noteConstants.EDIT_NOTE_SUCCESS: {
      return state.map(item =>
        item._id !== action.payload._id ? item : action.payload
      );
    }

    case noteConstants.DELETE_NOTE_SUCCESS:
      return state.filter(item => item._id !== action.payload.noteId);

    case noteConstants.ADD_ATTACHMENT_TO_NOTE_SUCCESS:
      return state.map(note => {
        return note._id !== action.noteId
          ? note
          : { ...note, attachments: [...note.attachments, ...action.payload] };
      });

    case noteConstants.DELETE_ATTACHMENT_TO_NOTE_SUCCESS:
      return state.map(note => {
        return note._id !== action.payload.noteId
          ? note
          : {
              ...note,
              attachments: [
                ...note.attachments.filter(attachment => {
                  return attachment._id !== action.payload.attachmentId;
                })
              ]
            };
      });

    case noteConstants.CHANGEING_NUMBER_OF_COMMENTS:
      let noteId;
      let addOrDelete;
      if (action.payload.noteId) {
        noteId = action.payload.noteId;
        addOrDelete = action.payload.addOrDelete;
      } else {
        noteId = action.payload.get("noteId");
        addOrDelete = action.payload.get("addOrDelete");
      }
      return state.map(note => {
        if (note._id === noteId) {
          if (addOrDelete === "add") {
            return { ...note, numberOfComments: note.numberOfComments + 1 };
          } else {
            return {
              ...note,
              numberOfComments: action.payload.numberOfComments
            };
          }
        } else {
          return note;
        }
      });

      case noteConstants.CLEARING_NOTES_DATA:
        return [];

    default:
      return state;
  }
}
