import React from "react";
import "./localList.css";
import { ListItem } from "./ListItem";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItemMaterial from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import EjectIcon from "@material-ui/icons/Eject";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ErrorIcon from "@material-ui/icons/Error";
import { withRouter, Link } from "react-router-dom";
import { projectActions, clientActions } from "../../actions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IssueItem } from "../IssueList/IssueItem";
const drawerWidth = 440;

// #############################################################################
// component
// #############################################################################

class LocalList extends React.Component {
  state = {
    filter: "",
    pageNumber: 0,
    pageCapacity: 8,
    data: [],
    dataProps: [],
    dragChild: false,
    openIssueList: false,
    issues: [],
    selectedProject: {},
    allprojects: [],
    onItem: {},
    projectsStatusForFiltering: [],
    open: false,
    "on hold": false,
    finished: false,
    canceled: false,
    "not started": false,
  };

  // ###########################################################################
  // this react method will copy some of the props to state
  // ###########################################################################

  componentDidMount() {
    const { data: projects = [], companies = [] } = this.props;
    this.setState({
      allprojects: [...companies, ...projects],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.allprojects !== prevState.allprojects)
      if (this.props !== prevProps) {
        const { dataProps } = this.state;
        const keyWordLowerCase = this.props.filter
          ? this.props.filter.toLowerCase()
          : "";

        const data = this.props.data.filter((item) => {
          const splitedRole = item.role
            ? `${item.role.split("-")[0]} ${item.role.split("-")[1]}`
            : "";
          const name = item.name
            ? item.name.toLowerCase().includes(keyWordLowerCase)
            : true;

          // if the user role is ADMIN there is no need to check the role of user in the project because by default the role is Manager
          const rawRole = this.props.adminRole
            ? false
            : item.role
            ? item.role.toLowerCase().includes(keyWordLowerCase)
            : true;

          const role = splitedRole.toLowerCase().includes(keyWordLowerCase);
          return name || rawRole || role;
        });

        if (dataProps !== data && data !== undefined) {
          const rootData = data.filter(
            (project) => project.parentProject === null
          );
          this.setState({
            dataProps: data,
            data: rootData.length > 0 ? rootData : data,
          });
        }
      }

    if (
      prevProps.data !== this.props.data ||
      prevProps.companies !== this.props.companies
    ) {
      this.setState({
        allprojects: [...this.props.companies, ...this.props.data],
      });
    }
  }

  componentWillUnmount() {
    this.props.dispatch(projectActions.clearingReduxData());
  }

  // shouldComponentUpdate(nextState, nextProps) {
  //   if (nextState !== this.state) return true;

  //   if (nextProps !== this.props) return true;

  //   for (var i = 0; i < nextState.allprojects; i++)
  //     for (var j = 0; j < this.state.allprojects; j++)
  //       if (nextState.allproject[i]._id === this.state.allprojects[j]._id)
  //         if (nextState.allproject[i].order !== this.state.allprojects[j].order)
  //           return true;

  //   return false;
  // }

  // ###########################################################################
  //
  // ###########################################################################

  changePage = (page) => {
    this.setState({ page });
  };

  // ###########################################################################
  //
  // ###########################################################################

  genaratePagination = (len, curPage, size) => {
    const pages = [];
    const lastPage = Math.ceil(len / size) - 1;
    if (len < 1) {
      return "";
    }
    pages.push(
      <div key={-1} className="first-page" onClick={() => this.changePage(0)}>
        {`[1] First`}
      </div>
    );
    for (let index = curPage - 1; index <= curPage + 1; index++) {
      if (index >= 0 && index <= lastPage) {
        if (index === curPage) {
          pages.push(
            <div
              key={index}
              className="midde-page current-page"
              onClick={() => this.changePage(index)}
            >
              {index + 1}
            </div>
          );
        } else {
          pages.push(
            <div
              key={index}
              className="midde-page"
              onClick={() => this.changePage(index)}
            >
              {index + 1}
            </div>
          );
        }
      }
    }
    pages.push(
      <div
        key={lastPage + 1}
        className="last-page"
        onClick={() => this.changePage(lastPage)}
      >
        {`Last [${lastPage + 1}]`}
      </div>
    );

    return pages;
  };

  // ###########################################################################
  //
  // ###########################################################################

  generatePageData = (data = []) => {
    const start = this.state.pageSize * this.state.page;
    const end = this.state.pageSize * (this.state.page + 1);
    return data.slice(start, end);
  };

  // ###########################################################################
  // generate page button
  // ###########################################################################
  generatePageButtons = (length, pageCapacity) => {
    const { pageNumber } = this.state;
    const { classes } = this.props;
    let numberOfPages = 0;
    const buttons = [];
    if (this.isInt(length / pageCapacity))
      numberOfPages = parseInt(length / pageCapacity);
    else numberOfPages = parseInt(length / pageCapacity, 10) + 1;

    for (let i = 0; i < numberOfPages; i++)
      buttons.push(
        <IconButton
          key={i}
          className={
            i === pageNumber
              ? `${classes.selectedPage} ${classes.pageBtn}`
              : `${classes.pageBtn}`
          }
          onClick={this.handleChangePage(i)}
        >
          {i + 1}
        </IconButton>
      );

    return buttons;
  };

  // ###########################################################################
  // this function will return
  // ###########################################################################
  isInt = (n) => {
    return n % 1 === 0;
  };

  // ###########################################################################
  // handle page changes
  // ###########################################################################
  handleChangePage = (number) => () => {
    this.setState({ pageNumber: number });
  };

  // ###########################################################################
  // handle next
  // ###########################################################################

  handleNext = (length, pageCapacity) => () => {
    const NumberOfPages = Math.round((length + 2) / pageCapacity);
    if (this.state.pageNumber <= NumberOfPages - 2)
      this.setState({ pageNumber: this.state.pageNumber + 1 });
  };

  // ###########################################################################
  // handle pagination
  // ###########################################################################

  handlePagination = (array) => {
    const { pageCapacity, pageNumber } = this.state;
    return array.slice(
      pageNumber * pageCapacity,
      pageNumber * pageCapacity + pageCapacity
    );
  };

  // ###########################################################################
  //
  // ###########################################################################

  displaySelectedIssues = (ProjectsWithIssues, e) => {
    e.stopPropagation();
    this.props.handleListShift(true);
    this.setState({ openIssueList: true, ProjectsWithIssues });
  };

  // ###########################################################################
  //
  // ###########################################################################

  closeIssueList = () => {
    this.setState({ openIssueList: false });
    this.props.handleListShift(false);
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleCopyProject = (projectId) => {
    this.props.dispatch(projectActions.copyProjectPattern({ projectId }));
  };
  // ###########################################################################
  //
  // ###########################################################################

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.checked,
    });

    target.checked
      ? this.setState((prevState) => ({
          projectsStatusForFiltering: [
            ...prevState.projectsStatusForFiltering,
            target.name,
          ],
        }))
      : this.setState((prevState) => ({
          projectsStatusForFiltering: prevState.projectsStatusForFiltering.filter(
            (item) => item !== target.name
          ),
        }));
  };

  thisProjectIssues = (issues, searchKey) => {
    return issues.filter((issue) => issue.project === searchKey);
  };

  onDragOver = ({ currentTarget }) => {
    if (
      this.state.onItem.id !== currentTarget.id ||
      this.state.onItem.getAttribute("index") !==
        currentTarget.getAttribute("index")
    ) {
      this.setState((prevState) => ({
        onItem: currentTarget,
        onItemIndex: Number(currentTarget.getAttribute("index")),
        previousItem: prevState.onItem,
        previousItemIndex: prevState.onItem.id
          ? Number(prevState.onItem.getAttribute("index"))
          : null,
      }));
    }
  };

  handleOnDragStart = (draggedItem) => {
    this.setState({
      draggedItem,
      // draggedItemIndex: this.state.allprojects.findIndex(
      //   project => project._id === draggedItem.id
      // ),
      tempAllProject: this.state.allprojects,
    });
  };

  hanldeOnDragOver = (onItem) => {
    if (
      this.state.onItem.id !== onItem.id ||
      this.state.onItem.getAttribute("index") !== onItem.getAttribute("index")
    ) {
      this.setState((prevState) => ({
        onItem,
        onItemIndex: Number(onItem.getAttribute("index")),
        previousItem: prevState.onItem,
        previousItemIndex: prevState.onItem.id
          ? Number(prevState.onItem.getAttribute("index"))
          : null,
      }));
    }
  };

  handleOnDragEnd = async () => {
    const { previousItem, allprojects, draggedItem, onItem } = this.state;
    // for preventing actions trigger drag on its own
    if (onItem && previousItem && onItem.id !== draggedItem.id) {
      let newParentId;
      if (
        onItem.getAttribute("id") === "middle" ||
        onItem.getAttribute("id") === "middleTop"
      )
        newParentId = onItem.getAttribute("parentproject");
      else newParentId = onItem.getAttribute("id");
      const draggedItemIndex = Number(draggedItem.getAttribute("index"));
      const draggedItemType = draggedItem.getAttribute("type");
      const onItemType = onItem.getAttribute("type");
      const draggedItemId = draggedItem.id;
      const oldParentId = draggedItem.getAttribute("parentproject");
      const oldClientId = draggedItem.getAttribute("client");
      const newClientId = onItem.getAttribute("client");
      let onItemIndex = Number(onItem.getAttribute("index"));

      // preventing to drop on bad position
      if (
        onItem.getAttribute("id") === "middle" ||
        onItem.getAttribute("id") === "middleTop"
      ) {
        if (draggedItemType === "Project")
          if (onItemType === "Client" || onItemType === "Program") {
            return;
          }

        if (draggedItemType === "Program")
          if (onItemType === "Client" || onItemType === "Project") {
            return;
          }

        if (draggedItemType === "Client")
          if (onItemType === "Program" || onItemType === "Project") {
            return;
          }
      }

      //prevent to drop program on project
      if (onItemType === "Project" && draggedItemType === "Program") return;
      // companies drag and drop ////////////////////////////////////////////////////////
      if (draggedItemType === "Client") {
        if (!oldParentId && !newParentId) {
          let tempAllCompanies = [...this.props.companies];
          // if (onItemIndex === this.props.companies.length) onItemIndex--;

          tempAllCompanies.splice(
            onItemIndex,
            0,
            this.props.companies.find((comp) => comp._id === draggedItem.id)
          );
          let order;
          if (draggedItemIndex > onItemIndex) {
            tempAllCompanies.splice(draggedItemIndex + 1, 1);
            order = onItemIndex + 1;
          } else {
            tempAllCompanies.splice(draggedItemIndex, 1);
            order = onItemIndex;
          }
          this.props.dispatch(
            clientActions.changeCompanyOrder(
              {
                companyId: draggedItemId,
                order,
              },
              tempAllCompanies
            )
          );
          return;
        } else {
          return;
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      ///// prevent user to move parent into its child

      const ownedProjectCounter = (allprojects, parentId, array = []) => {
        allprojects.forEach((pro) => {
          pro.parentProject === parentId &&
            array.push(pro) &&
            ownedProjectCounter(allprojects, pro._id, array);
        });
        return array;
      };

      if (
        ownedProjectCounter(allprojects, draggedItemId).some(
          (project) => project._id === newParentId
        )
      ) {
        return;
      }

      /// duplication preventing
      if (onItem.parentProject === draggedItemId) return;
      let tempAllProject = [];
      for (var j = 0; j < allprojects.length; j++) {
        tempAllProject.push({ ...allprojects[j] });
      }
      if (
        tempAllProject.find((project) => project._id === onItem.id) &&
        tempAllProject.find((project) => project._id === onItem.id).isFolder ===
          false
      )
        return;

      let count = -1;
      let onItemInArrayIndex;
      if (onItem.id !== "middle" && onItem.id !== "middleTop") onItemIndex = 0;
      for (var i = 0; i < tempAllProject.length; i++) {
        if (
          tempAllProject[i].client === newClientId &&
          (draggedItemType === "Program" ? tempAllProject[i].isProgram : true)
        )
          if (
            tempAllProject[i].parentProject === newParentId ||
            (!tempAllProject[i].parentProject && !newParentId)
          ) {
            count = count + 1;
          }
        onItemInArrayIndex = i;
        if (count === onItemIndex) {
          break;
        }
      }
      let childsNumber = tempAllProject.filter(
        (project) =>
          project.parentProject === newParentId ||
          (!project.parentProject && !newParentId)
      ).length;

      onItemInArrayIndex =
        onItemIndex === childsNumber
          ? onItemInArrayIndex + 1
          : onItemInArrayIndex;

      const draggedItemInArrayIndex = tempAllProject.findIndex(
        (project) => project._id === draggedItemId
      );
      const isParentClient = this.props.companies.some(
        (comp) => comp._id === newParentId
      );
      if (isParentClient) {
        tempAllProject[draggedItemInArrayIndex].parentProject = null;
        tempAllProject[draggedItemInArrayIndex].client = newParentId;
      } else {
        tempAllProject[draggedItemInArrayIndex].parentProject = newParentId;
        tempAllProject[draggedItemInArrayIndex].client = newClientId;
      }

      // prevent to drop project on client
      if (!tempAllProject[draggedItemInArrayIndex].isProgram && isParentClient)
        return;

      //for adding to top of the list
      if (
        onItem.getAttribute("id") !== "middle" &&
        onItem.getAttribute("id") !== "middleTop"
      )
        onItemInArrayIndex = 0;

      tempAllProject.splice(
        onItemInArrayIndex,
        0,
        isParentClient
          ? {
              ...tempAllProject[draggedItemInArrayIndex],
              client: newParentId,
              parentProject: null,
            }
          : tempAllProject[draggedItemInArrayIndex]
      );
      if (draggedItemInArrayIndex < onItemInArrayIndex)
        tempAllProject.splice(draggedItemInArrayIndex, 1);
      else tempAllProject.splice(draggedItemInArrayIndex + 1, 1);

      let order;
      if (oldParentId === newParentId || (!oldParentId && !newParentId))
        if (draggedItemIndex > onItemIndex) order = onItemIndex + 1;
        else order = onItemIndex;
      else {
        order = onItemIndex + 1;
      }
      if (onItem.id !== "middle" && onItem.id !== "middletop") order = 1;

      //////////////////////////////////////////////// for openning all parant status /////////////////////////////////////////
      //////////////////////////////////////////////// for openning all parant status /////////////////////////////////////////

      tempAllProject = tempAllProject.filter((project) => !project.email);
      const childProject = tempAllProject.find(
        (project) => project._id === draggedItemId
      );
      if (childProject.status === "OPEN") {
        const openAllParent = (projectList, child) => {
          const parent = projectList.find(
            (project) => project._id === child.parentProject
          );

          if (parent) {
            const newProjectList = projectList.map((project) =>
              project._id === child.parentProject
                ? { ...project, status: "OPEN" }
                : project
            );
            return openAllParent(newProjectList, parent);
          } else {
            return projectList;
          }
        };

        if (childProject.client) {
          tempAllProject = openAllParent(tempAllProject, childProject);
        }
      }

      //////////////////////////////////////////////// checking is same name is exist or not /////////////////////////////////////////
      //////////////////////////////////////////////// checking is same name is exist or not /////////////////////////////////////////

      const isProjectWithSameNameExist = tempAllProject.filter(
        (project) =>
          project._id !== childProject._id &&
          (project.name.charAt(project.name.length - 1) === ")"
            ? project.name.slice(0, project.name.length - 4)
            : project.name) &&
          project.parentProject === childProject.parentProject &&
          project.client === childProject.client
      );

      let biggestNumber = Math.max(
        ...isProjectWithSameNameExist
          .filter(
            (project) => project.name.charAt(project.name.length - 1) === ")"
          )
          .map((project) =>
            Number(project.name.charAt(project.name.length - 2))
          )
      );

      if (biggestNumber === 0 || biggestNumber === -Infinity)
        biggestNumber = 1;

      if (isProjectWithSameNameExist[0]) {
        tempAllProject = tempAllProject.map((project) =>
          project._id === childProject._id
            ? childProject.name.charAt(project.name.length - 1) === ")"
              ? {
                  ...project,
                  name: `${project.name.slice(
                    0,
                    project.name.length - 4
                  )} (${biggestNumber + 1})`,
                }
              : {
                  ...project,
                  name: `${project.name} (${biggestNumber + 1})`,
                }
            : project
        );
      }

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if (newParentId === oldParentId && newClientId === oldClientId) {
        this.props.dispatch(
          projectActions.changeProjectOrder(
            {
              projectId: draggedItemId,
              order,
            },
            tempAllProject
          )
        );
      } else {
        this.props.dispatch(
          projectActions.editProject(
            {
              parentProject: isParentClient ? null : newParentId,
              client: isParentClient ? newParentId : newClientId,
              order: order,
              projectId: draggedItemId,
              name: tempAllProject.find(
                (project) => project._id === childProject._id
              ).name,
            },
            tempAllProject
          )
        );
      }
    }
  };

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const {
      classes,
      adminRole,
      openAddProject,
      handleAddSubProject,
      filter,
      companies,
      issues,
      userId,
    } = this.props;
    const {
      openIssueList,
      ProjectsWithIssues,
      allprojects,
      previousItem,
      pageCapacity,
      finished,
      open,
      canceled,
      projectsStatusForFiltering,
    } = this.state;
    const pendingIssues = issues.filter((issue) => issue.status === "pending");
    const filteredPendingIssues = pendingIssues.filter((issue) =>
      filter === ""
        ? true
        : issue.name.toLowerCase().includes(filter.toLowerCase()) ||
          issue.project.name.toLowerCase().includes(filter.toLowerCase()) ||
          issue.description.toLowerCase().includes(filter.toLowerCase())
    );
    const mustShowing = allprojects
      .filter((project) => {
        return projectsStatusForFiltering[0]
          ? projectsStatusForFiltering.includes(
              //for checking if project of client
              project.status ? project.status.toLowerCase() : undefined
            )
          : true;
      })
      .filter((project) =>
        filter === ""
          ? projectsStatusForFiltering[0]
            ? true
            : project.email
          : project.name.toLowerCase().includes(filter.toLowerCase())
      );
    return (
      <React.Fragment>
        {/* //////////////////// list of all the projects  /////////////////// */}
        <div className={classes.issueList}>
          {pendingIssues.length > 0 && (
            <div className={classes.listHolder}>
              <div className={classes.myProjectTitle}>My New Tasks</div>
              <div className={classes.headerRoot}>
                <div className={classes.headerItem} style={{ flex: 3 }}>
                  Project Name
                </div>
                <div className={classes.headerItem} style={{ flex: 3 }}>
                  Task
                </div>
                <div className={classes.headerItem} style={{ flex: 4.5 }}>
                  Description
                </div>
                <div className={classes.headerItem} style={{ flex: 2 }}>
                  Due Date
                </div>
                <div className={classes.headerItem} style={{ flex: 3 }}>
                  Delay Time
                </div>
                <div className={classes.headerItem} style={{ flex: 2 }}>
                  Priority
                </div>
                <div
                  style={{
                    width: "230px",
                    textAlign: "center",
                    paddingRight: "10px",
                  }}
                  className={classes.headerItem}
                >
                  Actions
                </div>
              </div>
              <div className={classes.issueContainer}>
                {filteredPendingIssues[0] ? (
                  filteredPendingIssues.map((item, idx) => (
                    <IssueItem
                      projectUsers={[]}
                      key={item._id}
                      data={{ ...item, status: "pending" }}
                      handleEdit={this.handleEdit}
                      handleDeleteModal={this.handleDeleteModal}
                      setClickedRowDataToState={this.setClickedRowDataToState}
                      handlerInfoModal={this.handlerInfoModal}
                      adminRole={adminRole}
                      projectId={""}
                      dispatch={this.props.dispatch}
                      handleChangeTabs={this.handleChangeTabs}
                      isMine={item.assignedTo._id === userId}
                      workSpace
                    />
                  ))
                ) : (
                  <div className={classes.notFound}>No Issue Task</div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mpllist-main">
          <div className={classes.myProjectTitle}>My Projects</div>
          <Grid className={classes.checkboxsFilters}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={open}
                  onChange={this.handleChange}
                  color="primary"
                  name="open"
                  className={classes.checkbox}
                />
              }
              label="Open"
              className={classes.checkboxLabel}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state["on hold"]}
                  onChange={this.handleChange}
                  color="primary"
                  name="on hold"
                  className={classes.checkbox}
                />
              }
              label="On Hold"
              className={classes.checkboxLabel}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={finished}
                  onChange={this.handleChange}
                  color="primary"
                  name="finished"
                  className={classes.checkbox}
                />
              }
              label="Finished"
              className={classes.checkboxLabel}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={canceled}
                  onChange={this.handleChange}
                  color="primary"
                  name="canceled"
                  className={classes.checkbox}
                />
              }
              label="Canceled"
              className={classes.checkboxLabel}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state["not started"]}
                  onChange={this.handleChange}
                  color="primary"
                  name="not started"
                  className={classes.checkbox}
                />
              }
              label="Not Started"
              className={classes.checkboxLabel}
            />
          </Grid>

          {/* <div className="mpllist-header">
            {allprojects
              ? allprojects.filter(
                  project => !project.email && !project.isProgram
                ).length
              : ""}
            {allprojects
              ? allprojects.length > 1
                ? " Projects"
                : " Project"
              : ""}
          </div> */}
          <div className="mpllist-container">
            <div
            // id="root"
            // draggable="false"
            // onDragOver={this.onDragOver}
            // index={-1000}
            // parentproject={"null"}
            />
            {mustShowing[0] ? (
              this.handlePagination(mustShowing).map((project, idx) => (
                <ListItem
                  openIssueList={openIssueList}
                  openAddProject={openAddProject}
                  displaySelectedIssues={this.displaySelectedIssues}
                  link={this.props.link}
                  key={project._id}
                  id={project._id}
                  data={project}
                  status={project.status}
                  index={idx}
                  allprojects={this.state.allprojects}
                  onDragChild={this.onDragChild}
                  adminRole={adminRole}
                  handleOnDragStart={this.handleOnDragStart}
                  hanldeOnDragOver={this.hanldeOnDragOver}
                  handleOnDragEnd={this.handleOnDragEnd}
                  dispatchChangeProjectOrder={
                    this.props.dispatchChangeProjectOrder
                  }
                  previousItem={previousItem}
                  handleAddSubProject={handleAddSubProject}
                  handleCopyProject={this.handleCopyProject}
                  filter={this.props.filter}
                  projectsStatusForFiltering={projectsStatusForFiltering}
                  dynamicMargin={
                    filter || projectsStatusForFiltering[0] ? 0 : -20
                  }
                />
              ))
            ) : (
              <div className={classes.notFound}>
                No Client, Program or Project
              </div>
            )}
          </div>
        </div>
        {/* //////////////////// pagination component  //////////////////// */}
        {mustShowing.length > pageCapacity && (
          <Grid container className={classes.pagination}>
            <Grid container className={classes.borderTop}>
              <div className={classes.nextButton}>
                <IconButton
                  onClick={this.handleNext(
                    allprojects.length,
                    this.state.pageCapacity
                  )}
                  className={classes.pageBtn}
                >
                  NEXT
                </IconButton>
              </div>
              <div className={classes.pageNumbersHolder}>
                {this.generatePageButtons(
                  companies.filter((project) => !project.parentProject).length,
                  this.state.pageCapacity
                )}
              </div>
            </Grid>
          </Grid>
        )}
        {/*###########################  Drawer  #############################*/}
        <div className={classes.addButtonOpenParent}>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            // variant="temporary"
            anchor="right"
            open={openIssueList}
            classes={{
              paper: classes.drawerPaper,
              root: classes.drawerTransition,
            }}
          >
            <div
              style={{ height: "48px", minHeight: "48px" }}
              className={classes.drawerHeader}
            >
              <div className={classes.drawerHeaderText}>
                LIST OF ALL THE ISSUES
              </div>
              <IconButton
                aria-label="Close"
                className={classes.addButtonOpen}
                onClick={this.closeIssueList}
              >
                <EjectIcon />
              </IconButton>
            </div>
            <Divider />

            {/*##############  list of selected project issues  #############*/}
            <div className={classes.issueListContainer}>
              {ProjectsWithIssues && ProjectsWithIssues.length > 0
                ? ProjectsWithIssues.map((project) => {
                    if (project.issueTask.length > 0)
                      return (
                        <React.Fragment key={project._id}>
                          <div className={classes.listHeader}>
                            {project.name}{" "}
                            {project.issueTask.length > 1 ? "ISSUES" : "ISSUE"}
                          </div>
                          <List style={{ padding: "0px 20px 5px" }}>
                            {project.issueTask.map((issue) => (
                              <Link
                                className={classes.issueLink}
                                to={`/${
                                  adminRole
                                    ? "admin-dashboard"
                                    : "user-dashboard"
                                }/projects/${project._id}/project-issues`}
                                key={issue._id}
                              >
                                <ListItemMaterial className={classes.ListItem}>
                                  <ListItemIcon
                                    classes={{ root: classes.listIcon }}
                                  >
                                    <ErrorIcon
                                      classes={{ root: classes.errorIcon }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    classes={{ root: classes.listItemText }}
                                    primary={issue.name}
                                  />
                                </ListItemMaterial>
                              </Link>
                            ))}
                          </List>
                        </React.Fragment>
                      );
                    return null;
                  })
                : null}
            </div>
            {/*##############################################################*/}
          </Drawer>
        </div>
        {/*##################################################################*/}
      </React.Fragment>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = (theme) => ({
  // /////////////////// pagination component ////////////////////
  pageNumbersHolder: {
    padding: "5px 0px",
    display: "flex",
    flex: 1,
    flexWrap: "no-wrap",
    overflowX: "auto",
  },
  nextButton: {
    padding: "5px 0px",
  },
  borderTop: {
    borderTop: "1px solid #e1e1e1",
  },
  pageBtn: {
    fontSize: "14px",
    minWidth: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "15px",
    color: "#00000040",
    marginRight: "5px",
  },
  selectedPage: {
    color: "#155794",
    backgroundColor: "rgba(0, 0, 0, 0.03)",
  },
  pagination: {
    padding: "0px !important",
  },
  listHeader: {
    padding: "10px 20px 0",
    fontSize: "12px",
    color: "#de0000bd",
  },
  ListItem: {
    backgroundColor: "#e1e1e12b",
    padding: "10px 10px",
    margin: "4px 0px",
    borderRadius: "5px",
    border: "1px solid #00000005",
    transition: "border 100ms, background-color 100ms",
    "&:hover": {
      border: "1px solid #de69002b",
      backgroundColor: "#dea4a424",
    },
  },
  listItemText: {
    "& > span": {
      color: "#00000094",
      fontSize: "0.8rem",
    },
  },
  issueLink: {
    textDecoration: "none",
  },
  ///////////////////// Add-Project Drawer ///////////////////////////////////
  root: {
    display: "flex",
  },
  drawerTransition: {
    transition: "transform 3000ms cubic-bezier(.16,.95,.32,1)",
  },
  issueListContainer: {
    overflowY: "auto",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  addButtonOpen: {
    transform: "rotate(90deg)",
    color: "#e64e00",
    padding: "8px",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    position: "relative",
    width: drawerWidth,
    flexShrink: 0,
    "&>div": {
      overflowY: "unset",
      borderLeft: "1px solid #ff0000",
      zIndex: 1201,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    minHeight: "48px !important",
    padding: "0 10px 0 20px",
    color: "#000000a3",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  drawerHeaderText: {
    flex: 1,
    color: "#e64e00",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  header: {
    flex: 1,
    minHeight: "48px !important",
    backgroundColor: "#f4f5f9",
    borderBottom: "1px #c3c1c2 solid",
    padding: "18px 30px",
    boxSizing: "border-box",
  },
  headerFilter: {
    width: "70%",
    height: "28px",
    padding: "0 20px",
    backgroundColor: "#f4f5f9",
    border: 0,
    borderLeft: "1px solid #c3c1c2 ",
  },

  lable: {
    color: "#00000057",
    position: "absolute",
    fontSize: "12px",
    top: "-4px",
    margin: "0px 3px",
  },
  inputHolder: {
    position: "relative",
    padding: "12px 4px 10px",
  },
  buttonHolder: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 4px",
  },
  button: {
    margin: "0px !important",
    maxWidth: "195px",
  },

  addProjectForm: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  listIcon: {
    marginRight: "0px",
    color: "#ffa500b5",
  },
  myProjectTitle: {
    fontSize: "22px",
    color: "#606061",
    marginBottom: "10px",
  },
  checkboxsFilters: {
    borderBottom: "1px solid #E2E3E8",
  },
  headerItem: {
    boxSizing: "border-box",
    color: "#155794",
    padding: "7px 5px",
    margin: "0px 3px",
    fontSize: "15px",
  },
  headerRoot: {
    boxSizing: "border-box",
    padding: "5px 0px",
    display: "flex",
    width: "100%",
    borderBottom: "1px solid #D6D8DA",
    marginBottom: "15px",
  },
  issueContainer: {
    maxHeight: "162px",
    overflowY: "overlay",
  },
  notFound: {
    fontSize: "35px",
    color: "rgba(0, 0, 0, 0.21)",
    fontWeight: 100,
    textAlign: "center",
    paddingRight: "3%",
  },
});

// #############################################################################
// connect component to the style
// #############################################################################

const LocalListWithRouter = withRouter(LocalList);
const LocalListWithStyles = withStyles(styles)(LocalListWithRouter);

// #############################################################################
// Export
// #############################################################################
export { LocalListWithStyles as LocalList };
