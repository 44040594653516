// #############################################################################
// this function will setup the ghost image of the dragged element
// #############################################################################


export function setUpGhostImage (event, reference){
  const {top, left} = reference.current.getBoundingClientRect()
  const positionX = event.clientX - left;
  const positionY = event.clientY - top;

  let clone = reference.current.cloneNode(true);
  clone.style.position = "absolute";
  clone.style.top = "-10000px";
  clone.style.left = "-10000px";

  document.body.appendChild(clone);
  event.dataTransfer.setDragImage(clone, positionX, positionY);
  event.dataTransfer.setData("text", event.target.id);
}
