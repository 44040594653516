export const issueConstants = {
  ADD_ISSUE_SUCCESS: "ADD_ISSUE_SUCCESS",
  ADD_ISSUE_FAILURE: "ADD_ISSUE_FAILURE",

  GET_USER_ISSUES_REQUEST: "GET_USER_ISSUES_REQUEST",
  GET_USER_ISSUES_SUCCESS: "GET_USER_ISSUES_SUCCESS",
  GET_USER_ISSUES_FAILURE: "GET_USER_ISSUES_FAILURE",

  EDIT_ISSUE_SUCCESS: "EDIT_ISSUE_SUCCESS",
  EDIT_ISSUE_FAILURE: "EDIT_ISSUE_FAILURE",

  APPROVE_ISSUE_SUCCESS: "APPROVE_ISSUE_SUCCESS",
  APPROVE_ISSUE_FAILURE: "APPROVE_ISSUE_FAILURE",

  DELETE_ISSUE_SUCCESS: "DELETE_ISSUE_SUCCESS",
  DELETE_ISSUE_FAILURE: "DELETE_ISSUE_FAILURE",

  CHANGE_PROJECT_ISSUES_ORDER_SUCCESS: "CHANGE_PROJECT_ISSUES_ORDER_SUCCESS",
  CHANGE_PROJECT_ISSUES_ORDER_FAILURE: "CHANGE_PROJECT_ISSUES_ORDER_FAILURE",

  CLEARING_ISSUES_DATA: "CLEARING_ISSUES_DATA"
};
