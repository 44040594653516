// this component use handleBrowseFile function via props to save a file into the parent state

import React from 'react'
import { withStyles } from "@material-ui/core"
import { NewFile } from '../../Icons/NewFile'

const AddAttachmentIconAddFile = ({ handleAddNewFiles, classes }) => {

    return (
        <div className={classes.wapper}>
            <label className={classes.chooseFile}>
                <NewFile />
                <input type='file' onChange={handleAddNewFiles} multiple />
            </label>
        </div>
    )
}

const styles = {
    wrapper: {
        position: 'relative'
    },
    chooseFile: {
        display: 'flex!important',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        fontSize: '10px',
        borderRadius: '5px',
        fontWeight: '500',
        flex: '1',
        width: '100%',
        height: '38px',
        boxsizing: 'border-box',
        border: 0,
        cursor: 'pointer',
        position: 'relative',
        boxSizing: 'border-box',
        color: 'white!important',
        // margin: '2px 0',
        '& input': {
            opacity: 0,
            width: 0,
            height: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer',
        },
        '& > span': {
            zIndex: 100,
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '& svg': {
            cursor: 'pointer',
        }
    },
}

export default React.memo(withStyles(styles)(AddAttachmentIconAddFile))