import React, { PureComponent } from 'react';
import { withStyles } from "@material-ui/core"
import DatePicker from "../../DatePicker/DatePicker";
import { Select, ReactInput } from "../"
import { addWorkDays, addDays } from "../helpers";
import { Button } from "../";


// #############################################################################
// component
// #############################################################################

class TaskForm extends PureComponent {
  constructor(props) {
    super(props);
    const { parentTask, task, project = {} } = props

    this.state = {
      parentId: !parentTask ? task._id : "null",
      dependencies: [],
      projectId: project._id,
      priority: { value: "A", label: "LEVEL-1" },
      status: {value:'G',label:'Green'},
      name: "",
      description: "",
      duration: 1,
      actualStartDate: undefined,
      actualFinishDate: undefined,
      plannedStartDate: undefined,
      plannedFinishDate: undefined,
      completed: false,
      userId: {}
    };
  }

  // ###########################################################################
  // clear state to default
  // ###########################################################################

  clearState = () => {
    const { parentTask, task, project = {} } = this.props;
    this.setState({
      parentId: !parentTask ? task._id : "null",
      dependencies: [],
      projectId: project._id,
      priority: { value: "A", label: "LEVEL-1" },
      status: {value:'G',label:'Green'},
      name: "",
      description: "",
      duration: 1,
      actualStartDate:"",
      actualFinishDate:"",
      plannedStartDate:"",
      plannedFinishDate:"",
      completed: false,
      userId: {}
    });
  }

  // ###########################################################################
  // handle changes in the input
  // ###########################################################################

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value});
  }

  // ###########################################################################
  // this function will handle the change in the dependencies 
  // ###########################################################################

  handleChangeDependencies = event => {
    const { tasks = []} = this.props;
    this.setState({ [event.target.name]: event.target.value});
    const selectedItemWithLag = event.target.value.find(item => item.lag !== undefined);
    const task = selectedItemWithLag ? tasks.find(item => item._id === selectedItemWithLag.value) : undefined;
    if(task) this.handleChangePlannedStartDate(addDays(new Date(task.actualFinishDate), selectedItemWithLag.lag + 1))
  }

  // ###########################################################################
  // handle changes in the input
  // ###########################################################################

  handleChangePlannedStartDate = startDate => {
    const finishDate = addWorkDays(startDate, this.state.duration);

    this.setState({
      plannedStartDate: startDate,
      actualStartDate: startDate,
      plannedFinishDate: finishDate,
      actualFinishDate: finishDate,
    });
  }

  // ###########################################################################
  // handle the planned finish date changes
  // ###########################################################################

  handleChangePlannedFinishDate = date => {
    const { plannedStartDate = new Date() } = this.state;

    const oneDayInMilliseconds = 1000 * 60 * 60 * 24; 
    const duration = this.calculateDuration(plannedStartDate, date);

    const finishDate = plannedStartDate && (date - plannedStartDate) > oneDayInMilliseconds ?
      date : addDays(plannedStartDate, 1);

    this.setState({ plannedFinishDate: finishDate, actualFinishDate: finishDate, duration: Math.max(duration, 1) });
  }

  // ###########################################################################
  // handle changes in the input
  // ###########################################################################

  calculateDuration = (startDate , finishDate) => {
    const diffTime = Math.abs(new Date(finishDate) - new Date(startDate));
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  }

  // ###########################################################################
  // handle changes in the input
  // ###########################################################################

  handleChangeDuration = event => {

    const rawDuration = parseInt(event.target.value, 10)
    const duration = !rawDuration ? 0 : rawDuration > 10000 ? 10000 : rawDuration ;
    const today = new Date(new Date().setHours(0,0,0,0));
    const { plannedStartDate, actualStartDate } = this.state;
    

    const finishDate = plannedStartDate ?
      addWorkDays(plannedStartDate, duration) :
      addWorkDays(today ,duration);

    this.setState({
      duration: duration === 0 ? "" : duration,
      plannedStartDate: plannedStartDate || today,
      actualStartDate: actualStartDate || today,
      plannedFinishDate: finishDate,
      actualFinishDate:  finishDate,
    });
  }

  // ###########################################################################
  // handle changes in the select inputs
  // ###########################################################################

  handleSelectChange = (option, event) => {
    this.setState({ [event.name]: option });
  };

  // ###########################################################################
  // Decorate Priority
  // ###########################################################################

  decoratedPriorities = () => ([
    { value: "A", label: "LEVEL-1" },
    { value: "B", label: "LEVEL-2" },
    { value: "C", label: "LEVEL-3" },
    { value: "D", label: "LEVEL-4" },
    { value: "E", label: "LEVEL-5" }
  ]);

  // ###########################################################################
  // list of all the statuses for the status select in task form
  // ###########################################################################

  decoratedStatuses = () => ([
    {value:'G',label:'Green'},
    {value:'O',label:'Orange'},
    {value:'Y',label:'Yellow'},
    {value:'R',label:'Red'}
  ]);

  // ###########################################################################
  // decorates list of users
  // ###########################################################################

  decoratedUsers = (array = []) => {
    return array.map(item => ({
      value: item.user._id, 
      label: `${item.user.firstName} ${item.user.lastName}`
    }));
  }

  // ###########################################################################
  // this will handle the edit
  // ###########################################################################

  handleAddTask = event => {
    event.preventDefault();

    const data = {
      userId: this.state.userId.value,
      status: this.state.status.value,
      priority: this.state.priority.value,
      projectId: this.props.project._id,
      dependencies: this.exportDependenciesValue(this.state.dependencies),
      actualStartDate: this.state.actualStartDate,
      actualFinishDate: this.state.actualFinishDate,
      plannedStartDate: this.state.plannedStartDate,
      plannedFinishDate: this.state.plannedFinishDate,
    };

    this.props.handleAddTask({...this.state, ...data})
    this.props.toggleTaskForm();
    this.clearState();

  }

  // ###########################################################################
  // decorates the list of tasks for the select boxes
  // ###########################################################################

  decoratedDependencies = (inputArray = []) => {

    if(!this.props.parentTask && this.props.task) {
      const { _id } = this.props.task;
      const parents = this.findParent(this.props.task.parentTask, this.props.tasks);
      const array = [];
      inputArray.forEach(item => { if(item._id !== _id && !this.isAncestor(item._id, parents)) array.push({value: item._id, label: item.name}); })
      return array;
    }
    else {
      return inputArray.map(item => ({label: item.name, value: item._id}));
    }
  }

  // ###########################################################################
  // find all the parent
  // ###########################################################################


  findParent = (parent, tasks, parents = []) => {
    tasks.forEach(task => {
      if(task._id === parent){
        parents.push(task._id)
        if(task.parentTask) this.findParent(task.parentTask, tasks, parents);
      }
    });
    return parents;
  }

  // ###########################################################################
  // is it one of Ancestors
  // ###########################################################################

  isAncestor = (taskId, parents = []) => {
    let isAncestor = false;
    parents.forEach(parent => { isAncestor = parent === taskId || isAncestor });
    return isAncestor;
  }

  // ###########################################################################
  // this function will create a array of tasks ids
  // ###########################################################################

  exportDependenciesValue = (array) => {
    return array.map(item => (item.value))
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const { name, description, userId, dependencies, duration} = this.state;
    const { actualStartDate, actualFinishDate, plannedStartDate, plannedFinishDate } = this.state;
    const { tasks = [], classes, showTaskForm = false, users, parentTask = false } = this.props;
    return (
      <form
        id="TASK-INLINE-FORM"
        onSubmit={this.handleAddTask}
        className={`${classes.root} ${!showTaskForm ? parentTask ? classes.hideOnTop : classes.hide : null} ${ parentTask ? classes.noMargin: null}`}
      >
        <div className={`${classes.row} ${!showTaskForm ? classes.hideInputs : null}`}>
          <div className={`${classes.inputHolder}`}>
            <ReactInput
              required
              type="text"
              onChange={this.handleChange}
              className={classes.input}
              name="name"
              placeholder="TASK NAME"
              value={name}
            />
          </div>

          <div className={`${classes.inputHolder}`}>
            <Select
              type="text"
              name="userId"
              value={userId}
              required
              onChange={this.handleChange}
              placeholder="OWNER"
              options={this.decoratedUsers(users)}
            />
          </div>

          <div className={`${classes.buttonHolder}`}>
            <Button type="submit" className={classes.button} variant="success">SAVE</Button>
          </div>
        
        </div>
        <div className={`${classes.row} ${!showTaskForm ? classes.hideInputs : null}`}>
          <div className={classes.inputHolder}>

            <DatePicker
              required
              onChange={this.handleChangePlannedStartDate}
              name="plannedStartDate"
              selected={plannedStartDate}
              placeholderText="PLANNED START DATE"
              className={classes.datePicker}
              autoComplete="off"
            />

          </div>
          <div className={`${classes.inputHolder} ${classes.durationHolder}`}>
            <ReactInput
              required
              type="number"
              onChange={this.handleChangeDuration}
              className={classes.input}
              name="duration"
              min="0"
              max="10000"
              placeholder="DURATION"
              value={duration}
            />
          </div>
          <div className={`${classes.inputHolder}`}>

            <DatePicker
              required
              onChange={this.handleChangePlannedFinishDate}
              name="plannedFinishDate"
              selected={plannedFinishDate}
              placeholderText="PLANNED FINISH DATE"
              className={classes.datePicker}
              autoComplete="off"
            />

          </div>
          <div className={`${classes.inputHolder}`}>

            <DatePicker
              required
              onChange={(date) => this.setState({actualStartDate: date})}
              name="actualStartDate"
              selected={actualStartDate}
              placeholderText="ACTUAL START DATE"
              className={classes.datePicker}
              autoComplete="off"
            />

          </div>
          <div className={`${classes.inputHolder}`}>

            <DatePicker
              required
              onChange={(date) => this.setState({actualFinishDate: date})}
              name="actualFinishDate"
              selected={actualFinishDate}
              placeholderText="ACTUAL FINISH DATE"
              className={classes.datePicker}
              autoComplete="off"
            />
          </div>

          <div  className={`${classes.buttonHolder}`}>
            <Button type="button" onClick={() => { this.props.toggleTaskForm(false) }} className={classes.button} variant="secondary">CANCEL</Button>
          </div>

        </div>

        <div className={`${classes.row} ${!showTaskForm ? classes.hideInputs : null}`}>
          <div className={`${classes.inputHolder}`}>
            <Select
              className={classes.muliSelect}
              isMulti
              defaultLag={0}
              withLag
              type="text"
              name="dependencies"
              value={dependencies}
              onChange={this.handleChangeDependencies}
              placeholder="SELECT TASK AS DEPENDENCIES"
              options={this.decoratedDependencies(tasks)}
            />
          </div>
        </div>

        <div className={`${classes.row} ${!showTaskForm ? classes.hideInputs : null}`}>
          <div className={`${classes.textAreaHolder}`}>
            <textarea
              type="text"
              onChange={this.handleChange}
              className={classes.textarea}
              name="description"
              placeholder="DESCRIPTION"
              value={description}
            />
          </div>
        </div>
      </form>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    display: "flex",
    flexWrap: "wrap",
    width: "clac(100% - 12px)",
    margin:"3px 6px",
    padding: "10px 0px",
    position: "sticky",
    background: "#f8fbfb",
    border: "1px solid #00000020",
    zIndex: 1500,
    boxShadow:"0 2px 17px 8px #00000024",
    left: 6,
    boxSizing: "border-box",
    opacity: 1,
    height: "fit-content",
    borderRadius: 4,
  },
  title:{
    padding: "0px 15px 5px 15px",
    color: "#0000009c",
    fontSize: "14px",
    fontWeight: "500",
    width: '100%'
  },
  noMargin:{
    margin: 0,
    position: "absolute",
    left: 0,
    top: 0,
    border: "0px solid #00000020",
    boxShadow: "0 2px 17px 8px #00000024",
    borderRadius: 0
  },
  hide:{
    height: 0,
    border: 0,
    padding: 0,
    opacity: 0,
    margin: 0,
  },
  hideOnTop:{
    opacity: 0,
    padding: 0,
    margin: 0,
    top: -100
  },
  row:{
    padding:"0px 10px",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  // ###########################################################################
  inputHolder:{
    height: "fit-content",
    padding: " 2px 2px",
    flex: 1,
  },
  durationHolder:{
    height: "fit-content",
    padding: " 2px 2px",
    flex: 0.5,
  },
  textAreaHolder:{
    padding: " 2px 2px 0px 2px",
    flex: 1,
  },
  hideInputs:{
    display: "none"
  },
  // ###########################################################################
  input:{
    height: 34,
    fontSize: 12,
    fontWeight: 500,
    color: "#000919b5",
    width: "100%"
  },
  textarea:{
    minHeight: 34,
    height: 34,
    fontSize: 12,
    fontWeight: 500,
    color: "#000919b5",
    width: "100%",
    resize: "none",
  },
  buttonHolder:{
    height: "fit-content",
    padding: " 2px 2px",
  },
  // ###########################################################################
  button:{
    width: 100
  },
  muliSelect:{
    width: "100%"
  },
  // ###########################################################################
  // date picker
  datePicker: {
    borderRadius: 4,
    boxSizing: "border-box",
    border: "1px solid #C7C7C7",
    flex: 1,
    width: "100%",
    height: 34,
    padding: "2px 10px",
    fontSize: 12,
    color: "#000919b5",
    fontWeight: 500,
  }
});

// #############################################################################
// connect material styles and redux state to component
// #############################################################################
const TaskFormWithStyles = withStyles(styles)(TaskForm)


// #############################################################################
// export
// #############################################################################

export  { TaskFormWithStyles as TaskForm };
