// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { attachmentActions, filterActions } from "../../actions";
import { attachmentUserActions } from "../../userActions";
import ThemeContext from "../ContextApi/Context";

// Material Ui //

// Components //
import { InlineActionsButtons, DocumentList } from "./";
// Reusable Functions //

// Styles //

class ProjectDocumentTabWrapper extends React.PureComponent {
  static contextType = ThemeContext;
  state = {
    isAddNewFolder: false,
    pushToFirstPage: false
  };

  componentDidMount() {
    const { dispatch, role } = this.props;
    const projectId = this.context;
    role === "admin"
      ? dispatch(
        attachmentActions.getAttachments({
          projectId,
          projectDocument: true
        })
      )
      : dispatch(
        attachmentUserActions.getAttachments({
          projectId,
          projectDocument: true
        })
      );
    dispatch(filterActions.pageFilterEdit(''))
  }

  handleToggleAddNewFolder = () => {
    this.setState(prevState => ({
      isAddNewFolder: !prevState.isAddNewFolder
    }));
  };

  render() {
    const { clientRole } = this.props;
    const { isAddNewFolder } = this.state;
    return (
      <>
        {clientRole !== "Project-Client" && (
          <InlineActionsButtons
            handleToggleAddNewFolder={this.handleToggleAddNewFolder}
            handlePushToFirstPage={
              this.refs.documentList ? this.refs.documentList : () => { }
            }
          />
        )}
        <DocumentList
          handleToggleAddNewFolder={this.handleToggleAddNewFolder}
          isAddNewFolder={isAddNewFolder}
          ref="documentList"
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  clientRole: state.projects.project ? state.projects.project.role : undefined,
  role: state.authentication.user.role
});

export default connect(mapStateToProps)(ProjectDocumentTabWrapper);
