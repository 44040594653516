import { constructionConstants, projectConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "../actions";

export const constructionUserActions = {
  getAllForms,
  addPMDGAddendumForm,
  addPMDGDailyForm,
  addPMDGRFIForm,
  addPMDGShopDrawingsSubmittalLogForm,
  editForm,
  deleteForm,
  getForm
};

//################################################################################
// GET ALL FORMS
//################################################################################
function getAllForms(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getAllForms", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.forms));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(data) {
    return {
      type: constructionConstants.GET_ALL_FORMS_SUCCESS,
      payload: data
    };
  }
  // function failure(error) {
  //   return {
  //     type: constructionConstants.GET_ALL_FORMS_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// add PMDGAddendumForm
//################################################################################
function addPMDGAddendumForm(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("addPMDGAddendumForm", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.form));
          dispatch(addAddressToProject(data.address));
          dispatch(snackBarActions.snackBarSuccess("Successful"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(activities) {
    return {
      type: constructionConstants.ADD_FORM_SUCCESS,
      payload: activities
    };
  }
  function addAddressToProject(address) {
    return {
      type: projectConstants.ADD_ADDRESS_TO_PROJECT_SUCCESS,
      payload: address
    };
  }
  // function failure(error) {
  //   return {
  //     type: constructionConstants.ADD_FORM_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// add PMDGAddendumForm
//################################################################################
function addPMDGDailyForm(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("addPMDGDailyForm", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.form));
          dispatch(addAddressToProject(data.address));
          dispatch(snackBarActions.snackBarSuccess("Successful"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(activities) {
    return {
      type: constructionConstants.ADD_FORM_SUCCESS,
      payload: activities
    };
  }
  function addAddressToProject(address) {
    return {
      type: projectConstants.ADD_ADDRESS_TO_PROJECT_SUCCESS,
      payload: address
    };
  }
  // function failure(error) {
  //   return {
  //     type: constructionConstants.ADD_FORM_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// add PMDGRFIForm
//################################################################################
function addPMDGRFIForm(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("addPMDGRFIForm", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.form));
          dispatch(addAddressToProject(data.address));
          dispatch(snackBarActions.snackBarSuccess("Successful"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(activities) {
    return {
      type: constructionConstants.ADD_FORM_SUCCESS,
      payload: activities
    };
  }
  function addAddressToProject(address) {
    return {
      type: projectConstants.ADD_ADDRESS_TO_PROJECT_SUCCESS,
      payload: address
    };
  }
  // function failure(error) {
  //   return {
  //     type: constructionConstants.ADD_FORM_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// add PMDGRFIForm
//################################################################################
function addPMDGShopDrawingsSubmittalLogForm(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices
      .service("addPMDGShopDrawingsSubmittalLogForm", data, "user")
      .then(
        response => {
          if (response.success === true) {
            dispatch(success(response.form));
            dispatch(addAddressToProject(data.address));
            dispatch(snackBarActions.snackBarSuccess("Successful"));
          } else {
            dispatch(snackBarActions.snackBarError(response.msg));
          }
          dispatch(progressBarActions.stop());
        },
        error => {
          dispatch(progressBarActions.stop());
          dispatch(snackBarActions.snackBarError(error.message));
        }
      );
  };
  function success(activities) {
    return {
      type: constructionConstants.ADD_FORM_SUCCESS,
      payload: activities
    };
  }
  function addAddressToProject(address) {
    return {
      type: projectConstants.ADD_ADDRESS_TO_PROJECT_SUCCESS,
      payload: address
    };
  }
  // function failure(error) {
  //   return {
  //     type: constructionConstants.ADD_FORM_FAILURE,
  //     payload: error
  //   };
  // }
}
//################################################################################
// edit PMDGAddendumForm
//################################################################################
function editForm(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editForm", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.form));
          dispatch(addAddressToProject(data.get('address')));
          dispatch(snackBarActions.snackBarSuccess("Successful"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(activities) {
    return {
      type: constructionConstants.EDIT_FORM_SUCCESS,
      payload: activities
    };
  }
  function addAddressToProject(address) {
    return {
      type: projectConstants.ADD_ADDRESS_TO_PROJECT_SUCCESS,
      payload: address
    };
  }
  // function failure(error) {
  //   return {
  //     type: constructionConstants.EDIT_FORM_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// edit PMDGAddendumForm
//################################################################################
function deleteForm(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteForm", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(data.formId));
          dispatch(snackBarActions.snackBarSuccess("Successful"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(data) {
    return {
      type: constructionConstants.DELETE_FORM_SUCCESS,
      payload: data
    };
  }
  // function failure(error) {
  //   return {
  //     type: constructionConstants.DELETE_FORM_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// edit PMDGAddendumForm
//################################################################################
function getForm(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getForm", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.form));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(data) {
    return {
      type: constructionConstants.GET_FORM_SUCCESS,
      payload: data
    };
  }
  // function failure(error) {
  //   return {
  //     type: constructionConstants.DELETE_FORM_FAILURE,
  //     payload: error
  //   };
  // }
}
