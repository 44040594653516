
// #############################################################################
// this function will count the weekends and work days and also return the time line
// #############################################################################

export function removeWeekends(startDate = new Date(),  finishDate = new Date()){

	const start = new Date(startDate);
	const finish = new Date(finishDate);

	let weekends = 0;  // weekend counter
	let workDays = 0;  // work days counter
	let timeline = []; // time line for days between the two dates

	// this loop runs till date is less than finish date
	for(let date = new Date(start); dateInRow(date) <= dateInRow(finish); date = new Date (date.setDate(date.getDate() + 1))){

		// adds the date to time line
		timeline.push(new Date(date));
		// this condition will add to weekend counter and workdays
		if(date.getDay() === 0 || date.getDay() === 6)
			++weekends;
		else
			++workDays;
	}
	// return the result in array
	return [weekends ,workDays, timeline];
}

// #############################################################################
// this function will return the date in number form
// #############################################################################

export function dateInRow(date){
	const time = new Date(date)
	return time.getFullYear() * 10000 + time.getMonth() * 100 + time.getDate()
}