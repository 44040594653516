import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from "@material-ui/core";
import { Colors } from '../../styles/colors'

const styles = {
    cancelButton: {
        width: '70px',
        backgroundColor: Colors.lightGrey,
        color: "white",
        height: "38px",
        margin: "0px 2px",
        fontSize: "11px",
        fontWeight: "900",
        display: 'inline-block',
        "&:hover": {
            backgroundColor: Colors.grey
        },
        "&:active": {
            backgroundColor: Colors.grey
        }
    },
}

export const CancelButton = React.memo(withStyles(styles)(({ classes, onClick }) => (
    <Button className={classes.cancelButton} onClick={onClick}>CANCEL</Button>
)))