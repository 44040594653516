import { getTimeGap, maxDate, minDate } from "./"

// #############################################################################
// find the dependencies and 
// #############################################################################

export const moveDependencies = (task, tasks, newDates) => {
	const { actualFinishDate, actualStartDate } = newDates;

	const timeGapStart = getTimeGap(new Date(task.actualStartDate), actualStartDate);
	const timeGapFinish = getTimeGap(new Date(task.actualFinishDate), actualFinishDate);

	// find all the dependencies
	return findDependTasks(tasks, task, timeGapStart, timeGapFinish);
	
};

// #############################################################################
// update children
// #############################################################################

const findDependTasks = (tasks, task, startGap, finishGap, output = {}) => {

	findSequence(tasks, task, finishGap, output)
	const pTask = task.parentTask || null;

	if(!!pTask ){

		const [minEdge, maxEdge] = calculateParentEdges(tasks, tasks[pTask], task, startGap, finishGap);
		const temp = deepCopy(tasks[pTask]);

		const newStartGap = getTimeGap(new Date(temp.actualStartDate), minEdge);
		const newFinishGap = getTimeGap(new Date(temp.actualFinishDate), maxEdge);

		temp.actualStartDate = minEdge.toISOString();
		temp.actualFinishDate = maxEdge.toISOString();

		output[temp._id] = temp;
		
		findDependTasks(tasks, tasks[pTask], newStartGap, newFinishGap, output)
	}

	return output
}

// #############################################################################
// 
// #############################################################################

const calculateParentEdges = (tasks, parentTask, task, startGap, finishGap) => {
	const siblings = {}; 

	parentTask.subTask.forEach(child => {
		siblings[child] = deepCopy(tasks[child])
	})

	const taskCopy = deepCopy(task)

	const start = new Date(taskCopy.actualStartDate);
	const finish = new Date(taskCopy.actualFinishDate);

	taskCopy.actualStartDate = new Date(start.setDate(start.getDate() + startGap)).toISOString();
	taskCopy.actualFinishDate = new Date(finish.setDate(finish.getDate() + finishGap)).toISOString();

	const withChanges = {...siblings, [taskCopy._id]: taskCopy, ...findSequence(siblings, task, finishGap)}
	return findMinAndMaxEdge(withChanges)
}

// #############################################################################
// 
// #############################################################################

const findMinAndMaxEdge = (subTasks) => {

	let minEdge = maxDate();
	let maxEdge = minDate();
	const keys = Object.keys(subTasks)
	
	keys.forEach(taskId => {
		minEdge = Math.min(minEdge, new Date(subTasks[taskId].actualStartDate).getTime())
		maxEdge = Math.max(maxEdge, new Date(subTasks[taskId].actualFinishDate).getTime())
	})

	return [new Date(minEdge),  new Date(maxEdge)];

}

// #############################################################################
// update children
// #############################################################################

const updateChildren = (tasks, task, gap = 0, output = {}) => {
	task.subTask.forEach(subTaskId => {

		const temp = deepCopy(tasks[subTaskId])

		const start = new Date(temp.actualStartDate);
		const finish = new Date(temp.actualFinishDate);

		temp.actualStartDate = new Date(start.setDate(start.getDate() + gap)).toISOString();
		temp.actualFinishDate = new Date(finish.setDate(finish.getDate() + gap)).toISOString();

		output[subTaskId] = temp;

		if(!!tasks[subTaskId].subTask.length)
			updateChildren(tasks, tasks[subTaskId], gap, output);
	});
	return output
}

// #############################################################################
// update children
// #############################################################################

const findSequence = (tasks, task, gap, output = {}) => {
	Object.keys(tasks).forEach(taskId => {
		tasks[taskId].dependencies.forEach(dependency => {
			if(dependency._id === task._id){

				const temp = deepCopy(tasks[taskId])

				const start = new Date(temp.actualStartDate);
				const finish = new Date(temp.actualFinishDate);

				temp.actualStartDate = new Date(start.setDate(start.getDate() + gap)).toISOString();
				temp.actualFinishDate = new Date(finish.setDate(finish.getDate() + gap)).toISOString();

				output[taskId] = temp;

				if(!!tasks[taskId].subTask.length){
					updateChildren(tasks, tasks[taskId], gap, output)
				}

				findSequence(tasks, tasks[taskId], gap, output)
			}
		});
	});
	return output
}

// #############################################################################
// deep copy of objects and arrays
// #############################################################################

 const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

