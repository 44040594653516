import { issueConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "../actions";

export const issueUserActions = {
  addIssue,
  getUserIssues,
  getProjectIssues,
  editIssue,
  approveIssue,
  deleteIssue,
  changeIssueOrder
};

//################################################################################
// Add Issue
//################################################################################

function addIssue(data) {
  return dispatch => {
    dispatch(progressBarActions.start());

    ////Issue////////////////////
    generalServices.service("createIssueTask", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(snackBarActions.snackBarSuccess("SUCCESS"));
          if (response.issueTask) dispatch(success(response.issueTask));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    //////////////////////////
  };
  function success(issueTask) {
    return { type: issueConstants.ADD_ISSUE_SUCCESS, payload: issueTask };
  }
  // function failure(error) {
  //   return { type: issueConstants.ADD_ISSUE_FAILURE, payload: error };
  // }
}

//################################################################################
// Get All Issue
//################################################################################

function getUserIssues(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getUserIssueTasks", data, "general").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.issueTasks));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(issueTasks) {
    return {
      type: issueConstants.GET_USER_ISSUES_SUCCESS,
      payload: issueTasks
    };
  }
  // function failure(error) {
  //   return { type: issueConstants.GET_USER_ISSUES_FAILURE, payload: error };
  // }
}

//################################################################################
// Get All Issue
//################################################################################

function getProjectIssues(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getProjectIssueTasks", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.issueTasks));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(issueTasks) {
    return {
      type: issueConstants.GET_USER_ISSUES_SUCCESS,
      payload: issueTasks
    };
  }
  // function failure(error) {
  //   return { type: issueConstants.GET_USER_ISSUES_FAILURE, payload: error };
  // }
}
//################################################################################
// Edit Issue
//################################################################################

function editIssue(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editIssueTask", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(snackBarActions.snackBarSuccess("SUCCESS"));
          if (response.issueTask) dispatch(success(response.issueTask._doc));
          if (data.approvalStatus)
            dispatch(
              approveIssue({ id: data.id, approvalStatus: data.approvalStatus })
            );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(issueTask) {
    return { type: issueConstants.EDIT_ISSUE_SUCCESS, payload: issueTask };
  }
  // function failure(error) {
  //   return { type: issueConstants.EDIT_ISSUE_FAILURE, payload: error };
  // }
}

//################################################################################
// Edit Issue
//################################################################################

function approveIssue(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("approveIssue", data, "user").then(
      response => {
        if (response.success === true) {
          // dispatch(success(response.issues));
          dispatch(success(response.issueTask));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(issueTask) {
    return { type: issueConstants.APPROVE_ISSUE_SUCCESS, payload: issueTask };
  }
  // function failure(error) {
  //   return { type: issueConstants.APPROVE_ISSUE_FAILURE, payload: error };
  // }
}

//################################################################################
// Delete Issue
//################################################################################

function deleteIssue(data) {
  return dispatch => {
    dispatch(progressBarActions.start());

    ////Issue////////////////////
    generalServices.service("deleteIssueTask", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(snackBarActions.snackBarSuccess("SUCCESS"));
          // if(response.issues) dispatch(success(response.issues));
          // if (response.issueTasks)
           dispatch(success(data.issueTaskId));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    //////////////////////////
  };
  function success(issues) {
    return { type: issueConstants.DELETE_ISSUE_SUCCESS, payload: issues };
  }
  // function failure(error) {
  //   return { type: issueConstants.DELETE_ISSUE_FAILURE, payload: error };
  // }
}

//################################################################################
// Change Issue Order
//################################################################################
function changeIssueOrder(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("changeIssueTaskOrder", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.project));
          // dispatch(getAllProjects());
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(issue) {
    return {
      type: issueConstants.CHANGE_PROJECT_ISSUES_ORDER_SUCCESS,
      payload: issue
    };
  }
  // function failure(error) {
  //   return {
  //     type: issueConstants.CHANGE_PROJECT_ISSUES_ORDER_FAILURE,
  //     payload: error
  //   };
  // }
}
