// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// Components //
import { Header } from "./";
import { DownloadIcon } from "../Icons";
import { savePDF } from "@progress/kendo-react-pdf";

// Reusable Functions //
import TimeMonthDayYear from "../reusebleFunctions/TimeMonthDayYear";

// Styles //
import { Colors } from "../../styles/colors";

import {
  projectActions,
  clientActions,
  constructionActions
} from "../../actions";
import {
  projectUserActions,
  constructionUserActions,
  clientUserActions
} from "../../userActions";

class PMDGRFIPdf extends React.PureComponent {
  componentDidMount() {
    const { dispatch, match, role } = this.props;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];
    const formId = url[url.length - 1];
    const formName =
      url[url.length - 2] === "getendums"
        ? "PMDG Addendum Form"
        : url[url.length - 2] === "getDailyLog"
          ? "PMDG Daily Log Form"
          : url[url.length - 2] === "getRFI"
            ? "PMDG RFI Form"
            : url[url.length - 2] === "getShopDrawingsSubmittal"
              ? "PMDG Shop Drawings Submittal Log Form"
              : "Log Form";

    if (role === "admin") {
      dispatch(projectActions.getProjectUsers({ projectId }));
      dispatch(clientActions.getAllCompanies());
      dispatch(constructionActions.getForm({ formId, formName }));
    } else {
      dispatch(projectUserActions.getProjectUsers({ projectId }));
      dispatch(clientUserActions.getAllCompanies());
      dispatch(
        constructionUserActions.getForm({ formId, formName, projectId })
      );
    }
  }


  bodyRef = React.createRef();

  handleDownload = formName => () => {
    savePDF(this.bodyRef.current, {
      paperSize: "auto",
      fileName: `${formName}.pdf`,
      margin: 20
    });
  };

  render() {
    const { classes, formData, creator, project } = this.props;
    function isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    }
    return (
      <div className={classes.root}>
        <div ref={this.bodyRef}>

          <Header />
          {formData && project && !isEmpty(formData) && !isEmpty(project) && (
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.title}>RFI’s</div>
              </Grid>
              <Grid item xs={12}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>PM DESIGN GROUP, INC</th>
                      <th>PMDF Project NO</th>
                      <th>Project Name</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{formData.address}</td>
                      <td>{formData.project.PMdgJobCode}</td>
                      <td>{formData.project.name}</td>
                      <td>{TimeMonthDayYear(formData.createDate)}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={6}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{project.address}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={6}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{creator.phone}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={6}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{creator.email}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Subject</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{formData.subject}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={9}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Question/Conflict</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{formData.question}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={3}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Date Required</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {formData.dateRequierd
                          ? TimeMonthDayYear(formData.dateRequierd)
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={9}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Answer/Resolution</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{formData.answer}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={3}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Date Answered</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {formData.dateAnswered
                          ? TimeMonthDayYear(formData.dateAnswered)
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          )}
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.downloadButton}
            onClick={this.handleDownload("RFI’s")}
          >
            Download Form
          </Button>
        </div>
        <div className={classes.attachmentsContainer}>
          {formData
            ? formData.attachments
              ? formData.attachments.map((file, index) => (
                <span className={classes.fileRepresent} key={index}>
                  <span className={classes.downloadIconAttachment}>
                    <DownloadIcon
                      link={file.link}
                    />
                  </span>
                  <span className={classes.fileName}>{file.name}</span>
                </span>
              ))
              : null
            : null}
        </div>
      </div>
    );
  }
}

const Styles = {
  title: {
    backgroundColor: Colors.darkBlue,
    color: "white",
    fontSize: "23px",
    padding: "10px",
    margin: "15px 7px 0 7px",
    fontWeight: "500"
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "7px",
    fontSize: "14px",
    "& thead tr th": {
      backgroundColor: "#A7B2BE",
      width: "20%",
      fontWeight: "500",
      padding: "10px",
      height: "18px"
    },
    "& tbody tr td": {
      textAlign: "center",
      padding: "10px",
      backgroundColor: "white",
      border: "1px solid #C2CBD1",
      height: "18px"
    }
  },
  assignedTo: {
    marginRight: "10px"
  },
  downloadButton: {
    backgroundColor: "#58B370",
    color: "white",
    width: "150px",
    marginTop: "20px",
    marginLeft: "7px",
    "&:hover": {
      backgroundColor: "#439c5a"
    }
  },
  fileRepresent: {
    borderRadius: "5px",
    border: "1px solid #D6D6D8",
    padding: "30px",
    margin: "5px",
    position: "relative",
    display: "inline-flex"
  },
  attachmentsContainer: {
    marginTop: "15px"
  },
  downloadIconAttachment: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
const mapStateToProps = state => ({
  creator: state.authentication.user,
  role: state.authentication.user.role,
  project: state.projects.project,
  companies: state.companies,
  formData: state.construction
});
export default connect(mapStateToProps)(withStyles(Styles)(PMDGRFIPdf));
