import { updateParent, convertPositionToDates, clearNode } from "./"
import { changeLines } from "./"

// #############################################################################
// expand from right
// #############################################################################

export function expand(event, refrence, task = {}, tasks = {}, maxDependency, minDepender, minEdge, option, handleEditTask, IdPrefix = "", unit = 25) {
  const mouseStartPosition = event.clientX;
  const originalWidth = refrence.current.offsetWidth;
  const originalLeft = refrence.current.offsetLeft;
  const timeLineHighlighter = document.getElementById("TIME-LINE-HIGHLIGHTER");

  const onMouseMove = (event) => {
    if(option === "right")
      expandFromRight(event, mouseStartPosition, originalWidth, originalLeft, refrence, task, tasks, maxDependency, minDepender, timeLineHighlighter, IdPrefix)
    else if(option === "left")
      expandFromLeft(event, mouseStartPosition, originalWidth, originalLeft, refrence, task, tasks, maxDependency, minDepender, timeLineHighlighter, IdPrefix)
  }

  
  const removeEventListeners = event => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', removeEventListeners);
    clearNode(timeLineHighlighter)
    const [startDate, finishDate] = expandStop(event, refrence, task, tasks, minEdge, option, maxDependency, minDepender, timeLineHighlighter, IdPrefix, unit)
    if(IdPrefix === "PLANNED")
      handleEditTask({plannedStartDate: startDate, plannedFinishDate: finishDate}); 
    else
      handleEditTask({actualStartDate: startDate, actualFinishDate: finishDate})
  }

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', removeEventListeners);
}

// #############################################################################
// expand from left
// #############################################################################

export function expandFromLeft(event, mouseStartPosition, originalWidth, originalLeft, refrence, task, tasks, maxDependency, minDepender, timeLineHighlighter, IdPrefix){
  const parent = tasks[task.parentTask];
  const isActualProgress = task._id === refrence.current.id;

  const left = originalLeft + event.clientX - mouseStartPosition

  refrence.current.style.left = left + "px";
  refrence.current.style.width = originalWidth + originalLeft - left + "px";

  timeLineHighlighter.style.left = left + "px";
  timeLineHighlighter.style.width = originalWidth + originalLeft - left + "px";


  if(isActualProgress) changeLines(task, refrence);
  if(parent) updateParent(event, parent, tasks, IdPrefix)

}

// #############################################################################
// expand from right
// #############################################################################

export function expandFromRight(event, mouseStartPosition, originalWidth, originalLeft, refrence, task, tasks, maxDependency, minDepender, timeLineHighlighter, IdPrefix){
  const parent = tasks[task.parentTask];
  const isActualProgress = task._id === refrence.current.id;

  const width = originalWidth + event.clientX - mouseStartPosition;

  refrence.current.style.width = width + "px";
  timeLineHighlighter.style.left = refrence.current.offsetLeft + "px";
  timeLineHighlighter.style.width = width + "px";

  if(isActualProgress) changeLines(task, refrence);

  if(parent) updateParent(event, parent, tasks, IdPrefix)

}

// #############################################################################
// expand from right
// #############################################################################

export function expandStop(event, refrence, task, tasks, minEdge, option, maxDependency, minDepender, timeLineHighlighter, IdPrefix = "", unit = 25) {
  const parent = tasks[task.parentTask];
  const onePixel = 1;
  const width = refrence.current.offsetWidth - (refrence.current.offsetWidth % unit);
  const left = refrence.current.offsetLeft;
  const isActualProgress = task._id === refrence.current.id;
  
  if(option === "right"){
    refrence.current.style.width = `${width - onePixel}px`;

    timeLineHighlighter.style.left = refrence.current.offsetLeft + "px";
    timeLineHighlighter.style.width = `${width - onePixel}px`;
  }

  if(option === "left"){
    refrence.current.style.left = `${left - (left % unit )}px`
    refrence.current.style.width = `${refrence.current.offsetWidth + (left % unit )}px`

    timeLineHighlighter.style.left = `${left - (left % unit )}px`
    timeLineHighlighter.style.width = `${refrence.current.offsetWidth + (left % unit )}px`
  }

  if(isActualProgress) changeLines(task, refrence);
  
  if( event.clientX !== 0 && parent) updateParent(event, parent, tasks, IdPrefix);

  return convertPositionToDates(left, refrence.current.offsetWidth + onePixel , task, minEdge, unit);
}
