// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import {
  projectActions,
  constructionActions,
  clientActions
} from "../../actions";
import {
  projectUserActions,
  constructionUserActions,
  clientUserActions
} from "../../userActions";

// Material Ui //
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
// Components //
import Creatable from "react-select/lib/Creatable";
import Select from "react-select";
import { ReactInput } from "../";
import { DownloadIcon } from "../Icons";

// Reusable Functions //
import TimeMonthDayYear from "../reusebleFunctions/TimeMonthDayYear";

// Styles //
import { Colors } from "../../styles/colors";
import { history } from "../../helpers";

class PMDGShopDrawingsSubmittalLogForm extends React.PureComponent {
  state = {
    values: {},
    address: "",
    assignedTo: "",
    division: "",
    description: "",
    comments: "",
    files: [],
    deletedFormAttachments: []
  };

  componentDidMount() {
    const { dispatch, match, role } = this.props;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];
    const formId = url[url.length - 1];
    const formName =
      url[url.length - 2] === "addendum"
        ? "PMDG Addendum Form"
        : url[url.length - 2] === "addDailyLog"
        ? "PMDG Daily Log Form"
        : url[url.length - 2] === "addRFI"
        ? "PMDG RFI Form"
        : url[url.length - 2] === "addShopDrawingsSubmittal"
        ? "PMDG Shop Drawings Submittal Log Form"
        : "";
    if (role === "admin") {
      dispatch(projectActions.getProjectUsers({ projectId }));
      dispatch(clientActions.getAllCompanies());
      url[url.length - 1] !== "add" &&
        dispatch(constructionActions.getForm({ formId, formName }));
    } else {
      dispatch(projectUserActions.getProjectUsers({ projectId }));
      dispatch(clientUserActions.getAllCompanies());
      url[url.length - 1] !== "add" &&
        dispatch(
          constructionUserActions.getForm({ formId, formName, projectId })
        );
    }
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  componentDidUpdate(prevProps) {
    const { addresses, formData } = this.props;
    if (prevProps.addresses !== addresses) {
      this.setState({
        addresses: addresses
      });
    }
    if (prevProps.formData !== formData) {
      prevProps.formData &&
        !this.isEmpty(formData) &&
        this.setState({
          address: { value: formData.address, label: formData.address },
          assignedTo: formData.assignedTo.map(data => ({
            value: data.firstName + " " + data.lastName,
            label: data.firstName + " " + data.lastName,
            _id: data._id
          })),
          division: formData.division,
          description: formData.description,
          comments: formData.comments,
          files: formData.attachments
        });
    }
  }

  handleGoToForm = values => {
    this.setState({
      values
    });
  };
  handleCategoryChange = address => {
    this.setState({
      address
    });
  };
  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });
  };

  handleAddNewRow = () => {
    this.setState(prevState => ({
      fields: [...prevState.fields, { title: "", description: "" }]
    }));
  };

  handleSave = e => {
    e.preventDefault();
    const {
      handleSave,
      addresses,
      dispatch,
      formData,
      role,
      match
    } = this.props;
    const {
      assignedTo,
      address,
      division,
      description,
      comments,
      files,
      deletedFormAttachments
    } = this.state;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];

    const document = new FormData();
    document.append("projectId", projectId);
    document.append(
      "assignedTo",
      assignedTo.map(person => person._id)
    );
    document.append("address", address.value);
    const isExist = addresses.find(item => item === address.value);
    const finalAddress = isExist ? addresses : [address.value, ...addresses];
    document.append("addresses", JSON.stringify(finalAddress));
    deletedFormAttachments[0] &&
      document.append(
        "deletedFormAttachments",
        JSON.stringify(deletedFormAttachments)
      );
    document.append("division", division);
    document.append("description", description);
    document.append("comments", comments);

    if (url[url.length - 1] !== "add") {
      document.append("formName", formData.name);
      document.append("formId", formData._id);
      for (var i = 0; i < files.length; i++) {
        if (!files[i]._id) document.append("documents", files[i]);
      }
      role === "admin"
        ? dispatch(constructionActions.editForm(document))
        : dispatch(constructionUserActions.editForm(document));
      handleSave();
      history.goBack();
    } else {
      for (i = 0; i < files.length; i++) {
        if (!files[i]._id) document.append("documents", files[i]);
      }
      role === "admin"
        ? dispatch(
            constructionActions.addPMDGShopDrawingsSubmittalLogForm(document)
          )
        : dispatch(
            constructionUserActions.addPMDGShopDrawingsSubmittalLogForm(
              document
            )
          );
      handleSave();
      history.goBack();
    }
  };

  handleSelectChange = e => {
    this.setState({
      assignedTo: e
    });
  };

  customStyles = {
    control: provided => ({
      ...provided,
      borderRadius: "5px",
      border: "1px solid #CCCCCC",
      marginBottom: "-1px"
      // "&:active": {
      //   borderRadius: "5px"
      // },
      // "&:hover": {
      //   borderRadius: "5px"
      // }
    })
    // container: provided => ({
    //   display: "none"
    // })
  };

  handleInputFile = ({ target: { files } }) => {
    this.setState(prevState => ({
      files: [...prevState.files, ...[...files].map(file => file)]
    }));
  };

  handleRemoveAttachment = index => () => {
    this.setState(prevState => ({
      files: prevState.files.filter((file, index2) => index !== index2),
      deletedFormAttachments: prevState.files[index]._id
        ? [...prevState.deletedFormAttachments, prevState.files[index]._id]
        : prevState.deletedFormAttachments
    }));
  };

  render() {
    const { classes, users, addresses = [], project = {} } = this.props;
    const {
      address,
      assignedTo,
      division,
      description,
      comments,
      files
    } = this.state;
    return (
      <div className={classes.container}>
        <form onSubmit={this.handleSave}>
          <div className={classes.topItemsContainer}>
            <div className={classes.title}>Shop Drawings Submittal</div>
            <div className={classes.address}>
              <span style={{ position: "relative" }}>
                <Creatable
                  onChange={this.handleCategoryChange}
                  value={address}
                  placeholder="Click To Select Office Address"
                  className={classes.addressCreate}
                  options={addresses.map(address => ({
                    label: address,
                    value: address
                  }))}
                />
                <input
                  value={address}
                  style={{
                    position: "absolute",
                    top: 30,
                    right: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    opacity: 0,
                    marginRight: "auto",
                    marginLeft: "auto"
                  }}
                  required
                />
              </span>
            </div>
          </div>
          <div className={classes.formContainer}>
            <div className={classes.topHeaderContainer}>
              <div className={classes.topHeaderTitle}>
                Shop Drawings Submittal
              </div>
              <div className={classes.centerFullHeight}>
                <div
                  className={classes.topHeaderData}
                  style={{ border: "none" }}
                >
                  <strong>Project #:</strong> &nbsp;{" "}
                  {` ${project && project.name}`}
                </div>
                <div className={classes.topHeaderData}>
                  <strong>SDS:</strong> &nbsp; {` ${"#SDS"}`}
                </div>
                <div className={classes.topHeaderData}>
                  <strong>Submital NO:</strong> &nbsp; {` ${"#Submital NO"}`}
                </div>
                <div className={classes.topHeaderData}>
                  <strong>Contractor:</strong> &nbsp;{" "}
                  {`Contractor: ${
                    project.generalContractor
                      ? project.generalContractor.firstName
                      : ""
                  } 
            ${
              project.generalContractor
                ? project.generalContractor.firstName
                : ""
            }`}
                </div>
                <div className={classes.topHeaderData}>
                  <strong>Client:</strong> &nbsp; {` ${"#Client"}`}
                </div>
                <div className={classes.topHeaderBlueData}>
                  <strong>Date:</strong> &nbsp;{" "}
                  {` ${TimeMonthDayYear(new Date())}`}
                </div>
              </div>
            </div>
            <div className={classes.otherContentContainer}>
              <div className={classes.forContainer}>
                <span style={{ position: "relative" }}>
                  <Select
                    // options={this.projectStatuses()}
                    name={"assignedTo"}
                    value={assignedTo}
                    onChange={this.handleSelectChange}
                    required
                    placeholder="To"
                    maxMenuHeight={250}
                    isMulti
                    options={users.map(user => ({
                      label: `${user.user.firstName} ${user.user.lastName}`,
                      value: `${user.user.firstName} ${user.user.lastName}`,
                      _id: user.user._id
                    }))}
                    styles={this.customStyles}
                  />
                  <input
                    value={assignedTo}
                    style={{
                      position: "absolute",
                      top: 30,
                      right: 0,
                      left: 0,
                      width: 0,
                      height: 0,
                      opacity: 0,
                      marginRight: "auto",
                      marginLeft: "auto"
                    }}
                    required
                  />
                </span>
                <span>
                  <ReactInput
                    name="division"
                    value={division}
                    onChange={this.handleChange}
                    required
                    placeholder={"Division"}
                    autoComplete="off"
                    type="text"
                    className={classes.divisionStyle}
                  />
                </span>
              </div>

              <div className={classes.inputsContainer}>
                <textarea
                  name="description"
                  value={description}
                  onChange={this.handleChange}
                  required
                  placeholder={"Description"}
                  autoComplete="off"
                  type="text"
                  className={classes.descriptionInput}
                />
                <textarea
                  name="comments"
                  value={comments}
                  onChange={this.handleChange}
                  required
                  placeholder={`Comments`}
                  autoComplete="off"
                  type="text"
                  className={classes.commnetsInput}
                  rows="9"
                />
              </div>
              <Grid container className={classes.attachmentButtonWrapper}>
                <Grid item xs={2}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={this.handleInputFile}
                    name="files"
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      // raised
                      component="span"
                      className={`${classes.attachmentButton} ${classes.button}`}
                    >
                      <Add className={classes.addIcon} />
                      ATTACHMENT
                    </Button>
                  </label>
                </Grid>
                <Grid
                  item
                  xs={10}
                  container
                  justify="flex-end"
                  alignItems="flex-start"
                >
                  {[...files].map((file, index) => (
                    <span className={classes.fileRepresent} key={index}>
                      <Close
                        className={classes.closeIconAttachment}
                        onClick={this.handleRemoveAttachment(index)}
                      />
                      <span className={classes.downloadIconAttachment}>
                        <DownloadIcon link={file.link} />
                      </span>
                      <span className={classes.fileName}>{file.name}</span>
                    </span>
                  ))}
                </Grid>
              </Grid>
              <div className={classes.saveButtonContainer}>
                <Button type="submit" className={classes.saveButton}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const Styles = {
  container: {
    padding: "10px 15px"
  },
  topItemsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  title: {
    fontSize: "20px",
    color: Colors.darkBlue
  },
  pmDesign: {
    color: Colors.darkBlue,
    textAlign: "right",
    marginRight: "40px",
    fontSize: "13px"
  },
  address: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "15px"
  },
  addressCreate: {
    width: "300px",
    fontSize: "12px"
  },
  solidDataContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "13px"
  },
  solidData: {
    width: "50%",
    textAlign: "left",
    marginTop: "5px"
  },
  forContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    "& span": {
      width: "50%",
      margin: "0 5px"
    }
  },
  infoContainer: {
    backgroundColor: "#D7D7D7",
    borderRadius: "5px",
    padding: "20px 10px",
    marginTop: "20px",
    fontSize: "13px"
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px"
  },
  typeInput: {
    width: "100%",
    marginRight: "5px",
    borderRadius: 0,
    border: "none",
    borderTop: "1px solid #EEEEEE"
  },
  divisionStyle: {
    width: "100%",
    marginRight: "5px",
    borderRadius: "5px",
    border: "1px solid #CCCCCC",
    borderTop: "2px solid #EEEEEE"
    // "&:focus": {
    //   boxShadow: "none!important",
    //   borderColor: "transparent!important"
    //   // borderRadius: "5px"
    // }
  },
  descriptionInput: {
    flex: 10,
    borderRadius: "5px 5px 0 0 ",
    border: "1px solid #CCCCCC",
    marginBottom: "-1px",
    padding: "10px",
    height: "40px",
    "&:hover": {
      borderColor: "#B3B3B3"
    }
  },
  commnetsInput: {
    flex: 10,
    borderRadius: " 0 0 5px 5px",
    border: "1px solid #CCCCCC",
    padding: "10px",
    height: "300px",
    "&:hover": {
      borderColor: "#B3B3B3"
    }
  },
  addNewRowContainer: {
    marginTop: "20px"
  },
  addNewRowButton: {
    backgroundColor: "#A6A6A6",
    borderRadius: "5px",
    position: "relative",
    width: "270px",
    "&:hover": {
      backgroundColor: Colors.darkBlue
    }
  },
  addNewlineSpan: {
    width: "220px",
    color: "white",
    fontSize: "13px",
    marginTop: "2px"
  },
  addNewLineIcon: {
    position: "absolute",
    color: "white",
    right: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "13px"
  },
  addIcon: {
    fontSize: "21px"
  },
  saveButtonContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  saveButton: {
    width: "130px",
    backgroundColor: Colors.green,
    color: "white",
    fontSize: "13px",
    padding: "6px 40px!important",
    borderRadius: "5px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: Colors.darkGreen
    }
  },
  cancelButton: {
    width: "130px",
    backgroundColor: Colors.grey,
    color: "white",
    fontSize: "13px",
    padding: "6px 40px!important",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: Colors.darkGrey
    }
  },
  formContainer: {
    backgroundColor: "#fdfdfd",
    marginTop: "15px",
    borderRadius: "4px"
  },
  centerFullHeight: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%"
  },
  otherContentContainer: {
    padding: "20px"
  },
  topHeaderContainer: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px #a6a6a6"
  },
  topHeaderTitle: {
    height: "calc(100% - 25px)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "20px",
    padding: "0 10px",
    color: "green"
  },
  topHeaderData: {
    height: "calc(100% - 25px)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "13px",
    padding: "0 10px",
    borderLeft: "solid 1px #a6a6a6"
  },
  topHeaderBlueData: {
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "13px",
    padding: "0 10px",
    backgroundColor: "#83a9c7",
    color: "white"
  },
  attachmentButton: {
    backgroundColor: Colors.darkBlue,
    color: "white",
    "&:hover": {
      backgroundColor: "#1661A8"
    }
  },
  attachmentButtonWrapper: {
    marginTop: "20px"
  },
  button: {
    paddingRight: "20px",
    paddingLeft: "20px",
    borderRadius: "5px"
  },
  input: {
    position: "absolute",
    opacity: 0,
    width: 0
  },
  fileRepresent: {
    borderRadius: "5px",
    border: "1px solid #D6D6D8",
    padding: "30px",
    margin: "5px",
    position: "relative",
    display: "inline-flex"
  },
  closeIconAttachment: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: Colors.grey,
    fontSize: "16px",
    cursor: "pointer"
  },
  downloadIconAttachment: {
    marginRight: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
const mapStateToProps = state => ({
  addresses: state.projects.project
    ? state.projects.project.addresses !== null
      ? state.projects.project.addresses
      : []
    : [],
  users: state.projects.users ? state.projects.users : [],
  role: state.authentication.user.role,
  project: state.projects.project,
  companies: state.companies,
  formData: state.construction
});
export default connect(mapStateToProps)(
  withStyles(Styles)(PMDGShopDrawingsSubmittalLogForm)
);
