import { snackBarConstants } from "../constants";

export function snackBar(state = {}, action) {
  switch (action.type) {
    case snackBarConstants.SUCCESS:
      return {
        type: "alert-success",
        message: action.message   
      };
    case snackBarConstants.ERROR:
      return {
        type: "alert-danger",
        message: action.message
      };
    case snackBarConstants.CLEAR:
      return {};
    case snackBarConstants.SNACKBAR_SUCCESS:
      return {
        ...state,
        snackBar: {
          ...action.payload,
          variant: "success",
          open: true
        }
      };
    case snackBarConstants.SNACKBAR_ERROR:
      return {
        ...state,
        snackBar: {
          ...action.payload,
          variant: "error",
          open: true
        }
      };
    case snackBarConstants.SNACKBAR_WARNING:
      return {
        ...state,
        snackBar: {
          ...action.payload,
          variant: "warning",
          open: true
        }
      };
    case snackBarConstants.SNACKBAR_INFO:
      return {
        ...state,
        snackBar: {
          ...action.payload,
          variant: "info",
          open: true
        }
      };
    case snackBarConstants.SNACKBAR_CLOSE:
      return {
        ...state,
        snackBar: { ...state.snackBar, open: false }
      };
    case snackBarConstants.SNACKBAR_CLEAR:
      return {
        ...state,
        snackBar: {}
      };
    default:
      return state;
  }
}
