import { dateInRow } from "./";

// #############################################################################
// this function will generate list of and object of the the giving time period
// #############################################################################

export function generateTimeLine( start = new Date() , finish = new Date()){
  const timeLineArray = [];

  for(let date = new Date(start); dateInRow(date) < dateInRow(finish); date = new Date (date.setDate(date.getDate() + 1)))
    timeLineArray.push(new Date(date));
  
  return timeLineArray ;
}

// #############################################################################
// this function will divide the time line array to a array of months
// #############################################################################

export function divideByMonth(timeArray){

  if(timeArray.length === 0) return [];
  
  const monthArray = [[]];
  let correntMonth = timeArray[0].getMonth()
  let index = 0

  timeArray.forEach(item => {
    if(item.getMonth() === correntMonth)
      monthArray[index].push(item);
    else {
      correntMonth = (correntMonth + 1) % 12
      monthArray[++index] = []
      monthArray[index].push(item);
    } 
  })
  return monthArray;
}

// #############################################################################
// name of the months
// #############################################################################

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

// #############################################################################
// name of the week days
// #############################################################################

export const weekDaysName = [
  {name: 'Sunday',    symbol: 'S'},
  {name: 'Monday',    symbol: 'M'},
  {name: 'Tuesday',   symbol: 'T'},
  {name: 'Wednesday', symbol: 'W'},
  {name: 'Thursday',  symbol: 'T'},
  {name: 'Friday',    symbol: 'F'},
  {name: 'Saturday',  symbol: 'S'},
];

