import { minDate, maxDate, calculateWithAndPosition } from "./"

// #############################################################################
// find min and max in parents
// #############################################################################

export function getParentsMaxes(taskObjects = {} , task = {}, minEdge, IdPrefix = "", unit = 25, maxActualDate = {date: minDate()}, maxPlannedDate = {date: minDate()}) {
  const parent = taskObjects[task.parentTask]
  let outMaxPlanned = {};
  let outMaxActual = {};

  if(parent){
    const { maxActual, maxPlanned } = getParentMaxDependers(taskObjects, taskObjects[task.parentTask], minEdge, IdPrefix, unit);


    if(new Date(maxActual.date).getTime() >= maxActualDate.date.getTime())
      outMaxActual = {...maxActual, dependencyNode: document.getElementById(parent._id)};
    else
      outMaxActual = maxActualDate;

    if(new Date(maxPlanned.date).getTime() >= maxPlannedDate.date.getTime())
      outMaxPlanned = { ...maxPlanned, dependencyNode: document.getElementById(IdPrefix + parent._id) };
    else 
      outMaxPlanned = maxPlannedDate;

    if(parent.parentTask)
      return getParentsMaxes(taskObjects, parent, minEdge, IdPrefix, unit, outMaxActual, outMaxPlanned);
    else
      return { maxActual: outMaxActual, maxPlanned: outMaxPlanned }
  }
  else return {maxActual: defaultMax(), maxPlanned: defaultMax()}
}


// #############################################################################
// find min and max in parents
// #############################################################################

export function getParentsMins(taskObjects = {} , task = {}, minEdge, IdPrefix = "", unit = 25, minActualDate = {date: maxDate()}, minPlannedDate = {date: maxDate()}) {
  const parent = taskObjects[task.parentTask]
  let outMinPlanned = {}
  let outMinActual = {}

  if(parent){
    const { minActual, minPlanned } = getParentMinDependency(taskObjects, taskObjects[task.parentTask], minEdge, IdPrefix, unit);


    if(new Date(minActual.date).getTime() < minActualDate.date.getTime())
      outMinActual = {...minActual, dependencyNode: document.getElementById(parent._id)};
    else{
      outMinActual = {...defaultMin(), minActualDate};
    }


    if(new Date(minPlanned.date).getTime() < minPlannedDate.date.getTime())
      outMinPlanned = { ...minPlanned, dependencyNode: document.getElementById(IdPrefix + parent._id) };
    else
      outMinPlanned = {...defaultMin() ,minPlannedDate};


    if(parent.parentTask)
      return getParentsMins(taskObjects, parent, minEdge, IdPrefix, unit, outMinActual, outMinPlanned);
    else
      return { minActual: outMinActual, minPlanned: outMinPlanned }


  }
  else return { minActual: defaultMin(), minPlanned: defaultMin() }
}

// #############################################################################
// find min and max in parents
// #############################################################################

function getParentMaxDependers( taskObjects = {}, task = {} , minEdge, IdPrefix = "", unit = 25){
  const { dependencies = [] } = task;
  let maxActual = 0;
  let maxPlanned = 0;
    
  let maxActualTask = {};
  let maxPlannedTask = {};

  if (dependencies.length > 0 ) {
    dependencies.forEach(task => {

      const maxActualTemp = Math.max(new Date(taskObjects[task._id].actualFinishDate).getTime(), maxActual);
      maxActualTask = maxActualTemp !== maxActual ? taskObjects[task._id] : maxActualTask;

      const maxPlannedTemp = Math.max(new Date(taskObjects[task._id].plannedFinishDate).getTime(), maxPlanned);
      maxPlannedTask = maxPlannedTemp !== maxPlanned ? taskObjects[task._id] : maxPlannedTask;

      maxActual = maxActualTemp;
      maxPlanned = maxPlannedTemp;

    });
  }
  

  if (dependencies.length > 0) {
    const [actualPosition, actualWidth] = calculateWithAndPosition(minEdge, maxActualTask.actualStartDate, maxActualTask.actualFinishDate, unit)
    const [plannedPosition, plannedWidth] = calculateWithAndPosition(minEdge, maxPlannedTask.plannedStartDate, maxPlannedTask.plannedFinishDate, unit)
    return {
      maxActual: {
        task: maxActualTask,
        date: new Date(maxActual),
        start: actualPosition,
        width: actualWidth,
        end: actualPosition + actualWidth,
        node: document.getElementById(maxActualTask._id),
      },
      maxPlanned: {
        task: maxPlannedTask,
        date: new Date(maxPlanned), 
        start: plannedPosition,
        width: plannedWidth,
        end: plannedPosition + plannedWidth,
        node: document.getElementById(IdPrefix + maxPlannedTask._id),
      }
    }
  }
  else{
    return {
      maxActual:{
        task: null,
        date: minDate(),
        start: 0,
        width: 0,
        end: 0,
      }, 
      maxPlanned:{
        task: null,
        date: minDate(),
        start: 0,
        width: 0,
        end: 0,
      }
    }
  }
}


// #############################################################################
// find min of dependencies
// #############################################################################

function getParentMinDependency(taskObjects =  {}, task = {}, minEdge, IdPrefix = "", unit = 25){
  let minActual = Number.MAX_VALUE;
  let minPlanned = Number.MAX_VALUE;
  
  let minActualTask = {};
  let minPlannedTask = {};


  const keys = Object.keys(taskObjects)
  

  for(const key of keys){
    let itsMyDependency = false;
    if(taskObjects[key].dependencies) {
      taskObjects[key].dependencies.forEach(item => {
        itsMyDependency = itsMyDependency || (item._id === task._id);
      })
    }
    if(itsMyDependency){

      const minActualTemp = Math.min(new Date(taskObjects[key].actualStartDate).getTime(), minActual);
      minActualTask = minActualTemp !== minActual ? taskObjects[key] : minActualTask;

      const minPlannedTemp = Math.min(new Date(taskObjects[key].plannedFinishDate).getTime(), minPlanned);
      minPlannedTask = minPlannedTemp !== minPlanned ? taskObjects[key] : minPlannedTask;

      minActual = minActualTemp;
      minPlanned = minPlannedTemp;
    }
  }
  if( minActual !== Number.MAX_VALUE){
    const [actualPosition, actualWidth] = calculateWithAndPosition(minEdge, minActualTask.actualStartDate, minActualTask.actualFinishDate, unit)
    const [plannedPosition, plannedWidth] = calculateWithAndPosition(minEdge, minPlannedTask.plannedStartDate, minPlannedTask.plannedFinishDate, unit)
    return {
      minActual: {
        task: minActualTask,
        date: new Date(minActual),
        start: actualPosition,
        width: actualWidth,
        end: actualPosition + actualWidth,
        node: document.getElementById(minActualTask._id),
      },
      minPlanned: {
        task: minPlannedTask,
        date: new Date(minPlanned), 
        start: plannedPosition,
        width: plannedWidth,
        end: plannedPosition + plannedWidth,
        node: document.getElementById(IdPrefix + minPlannedTask._id),
      }
    }
  }
  else {
    return {
      minActual: {
        task: null,
        date: maxDate(),
        start: Number.MAX_VALUE,
        width: 0,
        end: 0,
      }, 
      minPlanned: {
        task: null,
        date: maxDate(),
        start: Number.MAX_VALUE,
        width: 0,
        end: 0,
      }
    }
  }
}

// #############################################################################
// return a default object of the max depended
// #############################################################################

function defaultMax(){
  return {
    task: null,
    dependencyNode: null,
    node: null,
    date: minDate(),
    start: 0,
    width: 0,
    end: 0,
  }
}

// #############################################################################
// return a default object of the min dependencies
// #############################################################################

function defaultMin(){
  return {
    task: null,
    dependencyNode: null,
    node: null,
    date: maxDate(),
    start: Number.MAX_VALUE,
    width: 0,
    end: Number.MAX_VALUE,
  }
}
