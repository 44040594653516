import React from 'react';
import { divideByMonth, gradients, generateTimeLine } from "../helpers";
import { Month } from "./"
import { withStyles } from "@material-ui/core"

// #############################################################################
// component
// #############################################################################

class TimeLine extends React.PureComponent {

  render = () =>{
    const { classes, rowCount, minEdge, maxEdge, width } = this.props;
    const timeLineArray = generateTimeLine(minEdge, maxEdge);
    const months = divideByMonth(timeLineArray);

    return (
      <div style={{width}} className={classes.root}>
        {months.map((item, index) => (
          <Month classes={classes} rowCount={rowCount} key={index} data={item} />
        ))}
        <div className={classes.duration} id="TIME-LINE-HIGHLIGHTER"/>
      </div>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    display: "flex",
    marginBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 1000,
    boxShadow: "0 2px 5px 0px #00000024, 0 2px 2px -2px #00000045"
  },
  duration:{
    position: "absolute",
    height: 38,
    top: 57,
    background: "#00a7ff3b",
  },

  // ###[ STYLES FOR MONTH COMPONENT ]##########################################
  monthRoot:{
    position:"relative",
    paddingTop: 35,
    backgroundColor:"#f8fbfb",
    userSelect: "none"
  },
  days:{
    display: "flex",
    borderTop: "2px solid #155794"
  },
  monthContainer: {
    position:"relative",
    paddingLeft: 2,
    paddingRight: 15,
    fontSize: 12,
    fontWeight: 600,
    color: "#155794",
    paddingBottom: 5
  },
  monthLabel: {
    position: "sticky",
    left: 10,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 100,
  },
  lines:{
    position: "absolute",
    width:"100%",
    minHeight: 300,
    top: 50,
    backgroundImage: gradients.lines,
  },
  yearLabel:{
    color: "#ce6c00"
  },

  // ###[ STYLES FOR DAY COMPONENT ]##########################################

  dayRoot:{
    width: 25,
    borderRight: "1px solid #00000010",
    textAlign:"center",
    position: "relative",
    boxSizing: "border-box",
    "&:hover":{
      cursor: "pointer" 
    }
  },
  weekDay:{
    fontSize: 9,
    right: 2,
    color: "#00000050",
    padding: " 3px 2px",
    width: "fit-content",
    position: "absolute"
  },
  date:{
    fontSize: 11,
    paddingTop: 15,
    paddingBottom: 10
  },
  redText:{
    color: "red"
  },
  borderDark:{
    borderRight: "1px solid #00000085",
  },
  today:{
    backgroundColor: "#ff005c36",
  },
  firstDayOfMonth:{
    borderRight: "1px solid #00000085 !important",
  },
  stripe: {
    position: "absolute",
    backgroundColor: "#ff005c36",
    width:"calc(100% + 2px)",
    height: "100%",
    top: 0,
    left: -1
  },
  monthDivider:{
    position: "absolute",
    height: "100%",
    borderRight:"2px solid #155794",
    top: 0,
    right: -1,
    zIndex: 1
  },
  tooltip:{
    backgroundColor: "white",
    border:"1px solid #00000010",
    fontSize: 12,
    color: "red",
  }
})


// #############################################################################
// connect style to component
// #############################################################################

const TimeLineWithStyles = withStyles(styles)(TimeLine)

// #############################################################################
// export
// #############################################################################

export  { TimeLineWithStyles as TimeLine };

