// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { commentActions } from "../../actions";
import { commentUserActions } from "../../userActions";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";

// Components //
import ModalCommentItem from "../Comments/ModalCommentItem";
import DeleteModal from "../Modal/DeleteModal";
// Reusable Functions //

// Styles //
import { Colors } from "../../styles";

class NoteModalContent extends React.PureComponent {
  state = {
    textarea: "",
    seeMoreComment: false,
    openDeleteModal: false,
    selectedOptionForDelete: {}
  };

  componentDidMount() {
    const { dispatch, projectId, noteId, role } = this.props;
    dispatch(
      role === "adminFlag"
        ? commentActions.getComments({ projectId, noteId })
        : commentUserActions.getComments({ projectId, noteId })
    );
  }

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    });
  };

  handleEdit = data => {
    const { dispatch, projectId, noteId, role } = this.props;
    dispatch(
      role === "adminFlag"
        ? commentActions.editComment({ projectId, noteId, ...data })
        : commentUserActions.editComment({ projectId, noteId, ...data })
    );
  };

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
          openDeleteModal: true,
          selectedOptionForDelete
        })
      : this.setState({
          openDeleteModal: false
        });
  };

  handleDelete = commentId => {
    this.setState({
      selectedOptionForDelete: { _id: commentId },
      openDeleteModal: true
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { dispatch, projectId, noteId, role } = this.props;
    const { textarea } = this.state;
    dispatch(
      role === "adminFlag"
        ? commentActions.addComment({ projectId, noteId, text: textarea })
        : commentUserActions.addComment({ projectId, noteId, text: textarea })
    );
    this.setState({
      textarea: ""
    });
  };

  render() {
    const { classes, handlerInfoModal, comments, role, userId } = this.props;
    const { textarea } = this.state;

    return (
      <div className={classes.modalContainer} tabIndex="-1">
        <header>
          <Close
            className={classes.closeIcon}
            onClick={() => handlerInfoModal(false)}
          />
          <h3>Note Title</h3>

          <p>{null} </p>
        </header>
        <section className={classes.section}>
          <form
            onSubmit={this.handleSubmit}
            className={classes.addNewCommentForm}
          >
            <textarea
              value={textarea}
              name="textarea"
              onChange={this.handleChange}
              placeholder="Write Your Comment"
              className={classes.textarea}
              rows="3"
            />
            <Button
              type="submit"
              className={classes.submitButton}
              disabled={!textarea}
            >
              Submit
            </Button>
          </form>
          {comments[0] ? (
            <div className={classes.commentItemsWrapper}>
              {comments.map((comment, index) => (
                <ModalCommentItem
                  key={index}
                  comment={comment}
                  handleEdit={this.handleEdit}
                  handleDelete={this.handleDelete}
                  role={role}
                  userId={userId}
                />
              ))}
            </div>
          ) : (
            <section className={classes.noData}>No Comment</section>
          )}
        </section>

        <DeleteModal
          open={this.state.openDeleteModal}
          handleDeleteModal={this.handleDeleteModal}
          selectedOptionForDelete={{
            commentId: this.state.selectedOptionForDelete._id
          }}
          adminAction={commentActions.deleteComment}
          userAction={commentUserActions.deleteComment}
          //optional
          modalTitle={"Delete Journal"}
        />
      </div>
    );
  }
}

const Styles = {
  modalContainer: {
    width: "700px",
    // minHeight: "350px",
    borderRadius: "10px",
    boxShadow: "0 0 10px #545454",
    padding: "30px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EFEFF9",
    "& h3": {
      color: Colors.darkGreen,
      margin: "0"
    },
    "& > header > p": {
      fontSize: "13px",
      padding: " 0 0 10px 0",
      margin: 0,
      color: Colors.grey,
      borderBottom: "1px solid #D1D1DB"
    }
  },
  closeIcon: {
    color: Colors.grey,
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer"
  },
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "13px"
  },
  textarea: {
    width: "100%",
    height: "auto"
  },
  submitButton: {
    backgroundColor: Colors.green,
    color: "white",
    width: "100%",
    marginTop: "7px",
    "&:hover": {
      backgroundColor: Colors.green
    },
    "&:disabled": {
      backgroundColor: Colors.grey,
      cursor: "default"
    }
  },
  commentItemsWrapper: {
    width: "100%",
    overflowY: "overlay",
    margin: "10px",
    minHeight: "200px",
    maxHeight: "400px"
  },
  seeMoreCommentsButton: {
    backgroundColor: Colors.darkBlue,
    color: "white",
    width: "100%",
    margin: 0,
    "&:hover": {
      backgroundColor: Colors.darkBlue
    }
  },
  addNewCommentForm: {
    width: "100%"
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "35px",
    color: "#00000036",
    fontWeight: "100!important",
    height: "200px",
    margin: "10px"
  }
};

const mapStateToProps = state => ({
  projectId: state.projects.project._id,
  comments: state.comments,
  role: state.projects.project.role,
  userId: state.authentication.user._id
});

export default connect(mapStateToProps)(withStyles(Styles)(NoteModalContent));
