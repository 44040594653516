// React //
import React from "react";

// Redux //

// Material Ui //
import { withStyles } from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import Tooltip from '@material-ui/core/Tooltip';
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";

// Components //
import pastTimeSoFar from '../reusebleFunctions/pastTimeSoFar'

// Reusable Functions //

// Styles //
import { Colors } from '../../styles/colors'

class ModalItem extends React.PureComponent {
  state = {
    editMode: false,
    editText: ''
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  handleEdit = () => {
    !this.state.editMode &&
      this.setState({
        editMode: true,
        editText: this.props.comment.text
      })
  }

  handleCancelChange = () => {
    this.setState({ editMode: false, editText: '' });
  };

  handleSubmitChange = () => {
    const { editText } = this.state
    const { handleEdit, comment } = this.props
    handleEdit({ text: editText, commentId: comment._id })
    this.setState({
      editMode: false
    })
  }

  handleDelete = () => {
    const { handleDelete, comment } = this.props
    handleDelete(comment._id)
  }

  render() {
    const { classes, comment, role, userId } = this.props;
    const { editMode, editText } = this.state

    return (
      <div className={classes.modalCommentItemWrapper} onDoubleClick={this.handleEdit}>
        <div className={classes.nameTimeAndActionWrapper}>
          <div className={classes.nameAndTimeWrapper}>
            <Person className={classes.personIcon} />
            <span className={classes.name}>{comment.owner.firstName} {comment.owner.lastName}</span>
            <span className={classes.time}>
              ({comment.edited ?
                `Edited ${pastTimeSoFar(comment.lastUpdate)}` :
                `Created ${pastTimeSoFar(comment.createDate)}`})
              </span>
          </div>

          <div className={classes.actionWrapper}>
            {editMode ?
              <>
                <button type='submit' className={classes.submitButton} onClick={this.handleSubmitChange} disabled={!editText}>
                  SAVE
                </button>
                {/*CANCEL*/}
                <button className={classes.cancelButton} onClick={this.handleCancelChange}>
                  CANCEL
                </button>
              </> :
              <>
                {userId === comment.owner._id &&
                  <Tooltip title="Edit">
                    <IconButton className={classes.iconWrapper} onClick={this.handleEdit} component="span">
                      <EditOutlinedIcon className={classes.editIconWrapper} />
                    </IconButton>
                  </Tooltip>
                }
                {(userId === comment.owner._id || role === 'adminFlag') &&
                  <Tooltip title="Delete">
                    <IconButton className={classes.iconWrapper} onClick={this.handleDelete} component="span">
                      <CancelOutlinedIcon className={classes.cancelIconWrapper} />
                    </IconButton>
                  </Tooltip>
                }
              </>
            }
          </div>

        </div>
        {editMode ?
          <textarea
            rows={2}
            className={classes.textarea}
            value={editText}
            onChange={this.handleChange}
            name='editText'
          /> :
          <p className={classes.comment}>{comment.text}</p>
        }
      </div>
    );
  }
}

const Styles = {
  modalCommentItemWrapper: {
    padding: '10px 15px',
    backgroundColor: 'white',
    margin: '8px 0'
  },
  nameTimeAndActionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '42px'
  },
  nameAndTimeWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  personIcon: {
    color: 'black',
    marginRight: '3px',
    fontSize: '16px'
  },
  name: {
    color: Colors.darkBlue,
    marginRight: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  time: {
    color: Colors.grey,
    fontSize: '12px',
  },
  iconWrapper: {
    padding: '3px!important'
  },
  editIconWrapper: {
    color: Colors.lightGreen
  },
  cancelIconWrapper: {
    color: Colors.orange
  },
  comment: {
    margin: '0 0 7px 19px',
    color: Colors.grey,
    fontSize: '14px'
  },
  textarea: {
    width: '100%',
    marginTop: '15px',
    height: 'auto'
  },
  submitButton: {
    fontWeight: 900,
    fontSize: '10px',
    height: '38px',
    width: '70px',
    // box-sizing: border-box;
    borderRadius: '5px',
    backgroundColor: '#4e9e63',
    color: '#ffffff',
    display: 'inline-block',
    border: 'none',
    cursor: 'pointer',
    margin: '2px',
    '&:disabled': {
      backgroundColor: Colors.grey,
      cursor: 'default'
    }
  },
  cancelButton: {
    fontWeight: 900,
    fontSize: '10px',
    height: '38px',
    width: '70px',
    // box-sizing: border-box;
    borderRadius: '5px',
    backgroundColor: '#AFADAD',
    color: '#ffffff',
    display: 'inline-block',
    border: 'none',
    cursor: 'pointer',
    margin: '2px'
  }
};

export default withStyles(Styles)(ModalItem);
