// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import {
  constructionActions,
  projectActions,
  clientActions
} from "../../actions";
import {
  constructionUserActions,
  projectUserActions,
  clientUserActions
} from "../../userActions";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
// import SwapVert from "@material-ui/icons/SwapVert";
// Components //
import Creatable from "react-select/lib/Creatable";
import { ReactInput } from "../";
import { DownloadIcon } from "../Icons";

// Reusable Functions //
import TimeMonthDayYear from "../reusebleFunctions/TimeMonthDayYear";

//icons
import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";

// Styles //
import { Colors } from "../../styles/colors";
import { history } from "../../helpers";

class PMDGDailyLogForm extends React.PureComponent {
  state = {
    weather: "",
    temp: "",
    workPerformedToday: "",
    comments: "",
    address: "",
    files: [],
    deletedFormAttachments: []
  };

  componentDidMount() {
    const { dispatch, match, role } = this.props;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];
    const formId = url[url.length - 1];
    const formName =
      url[url.length - 2] === "addendum"
        ? "PMDG Addendum Form"
        : url[url.length - 2] === "addDailyLog"
        ? "PMDG Daily Log Form"
        : url[url.length - 2] === "addRFI"
        ? "PMDG RFI Form"
        : url[url.length - 2] === "addShopDrawingsSubmittal"
        ? "PMDG Shop Drawings Submittal Log Form"
        : "";
    if (role === "admin") {
      dispatch(projectActions.getProjectUsers({ projectId }));
      dispatch(clientActions.getAllCompanies());
      url[url.length - 1] !== "add" &&
        dispatch(constructionActions.getForm({ formId, formName }));
    } else {
      dispatch(projectUserActions.getProjectUsers({ projectId }));
      dispatch(clientUserActions.getAllCompanies());
      url[url.length - 1] !== "add" &&
        dispatch(
          constructionUserActions.getForm({ formId, formName, projectId })
        );
    }
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  componentDidUpdate(prevProps) {
    const { addresses, formData } = this.props;
    if (prevProps.addresses !== addresses) {
      this.setState({
        addresses: addresses
      });
    }
    if (prevProps.formData !== formData) {
      prevProps.formData &&
        !this.isEmpty(formData) &&
        this.setState({
          address: { value: formData.address, label: formData.address },
          weather: formData.weather,
          temp: formData.temp,
          workPerformedToday: formData.workPerformedToday,
          comments: formData.comments,
          files: formData.attachments
        });
    }
  }

  handleCategoryChange = address => {
    this.setState({
      address
    });
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });
  };

  handleSave = e => {
    e.preventDefault();
    const {
      handleSave,
      addresses,
      dispatch,
      match,
      formData,
      role
    } = this.props;
    const {
      address,
      weather,
      temp,
      workPerformedToday,
      comments,
      files,
      deletedFormAttachments
    } = this.state;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];

    /////////////////////////////////////////////////////
    const document = new FormData();

    document.append("weather", weather);
    document.append("temp", temp);
    document.append("workPerformedToday", workPerformedToday);
    document.append("comments", comments);
    document.append("projectId", projectId);
    document.append("address", address.value);
    const isExist = addresses.find(item => item === address.value);
    const finalAddress = isExist ? addresses : [address.value, ...addresses];

    document.append("addresses", JSON.stringify(finalAddress));

    deletedFormAttachments[0] &&
      document.append(
        "deletedFormAttachments",
        JSON.stringify(deletedFormAttachments)
      );

    //////////////////////////////////////////////////

    if (url[url.length - 1] !== "add") {
      document.append("formId", formData._id);
      document.append("formName", formData.name);

      for (var i = 0; i < files.length; i++) {
        if (!files[i]._id) document.append("documents", files[i]);
      }
      role === "admin"
        ? dispatch(constructionActions.editForm(document))
        : dispatch(constructionUserActions.editForm(document));
      handleSave();
      history.goBack();
    } else {
      for (i = 0; i < files.length; i++) {
        if (!files[i]._id) document.append("documents", files[i]);
      }
      role === "admin"
        ? dispatch(constructionActions.addPMDGDailyForm(document))
        : dispatch(constructionUserActions.addPMDGDailyForm(document));
      handleSave();
      history.goBack();
    }
  };

  handleInputFile = ({ target: { files } }) => {
    this.setState(prevState => ({
      files: [...prevState.files, ...[...files].map(file => file)]
    }));
  };

  handleRemoveAttachment = index => () => {
    this.setState(prevState => ({
      files: prevState.files.filter((file, index2) => index !== index2),
      deletedFormAttachments: prevState.files[index]._id
        ? [...prevState.deletedFormAttachments, prevState.files[index]._id]
        : prevState.deletedFormAttachments
    }));
  };

  render() {
    const { classes, addresses = [], companies, project, creator } = this.props;
    const {
      address,
      weather,
      temp,
      workPerformedToday,
      comments,
      files
    } = this.state;
    const company =
      companies &&
      project &&
      companies.find(com => com._id === project.client._id);
    return (
      <div className={classes.container}>
        <form onSubmit={this.handleSave}>
          <div className={classes.topItemsContainer}>
            <div className={classes.title}>Daily Logs</div>
            <div className={classes.address}>
              <span style={{ position: "relative" }}>
                <Creatable
                  onChange={this.handleCategoryChange}
                  value={address}
                  placeholder="Click To Select Office Address"
                  className={classes.addressCreate}
                  options={addresses.map(address => ({
                    label: address,
                    value: address
                  }))}
                />
                <input
                  value={address}
                  style={{
                    position: "absolute",
                    top: 30,
                    right: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    opacity: 0,
                    marginRight: "auto",
                    marginLeft: "auto"
                  }}
                  required
                />
              </span>
            </div>
          </div>
          <div className={classes.formContainer}>
            <div className={classes.topHeaderContainer}>
              <div className={classes.topHeaderTitle}>Daily Logs</div>
              <div className={classes.centerFullHeight}>
                <div
                  className={classes.topHeaderData}
                  style={{ border: "none" }}
                >
                  <strong>Project Name:</strong> &nbsp;{" "}
                  {` ${project && project.name}`}
                </div>
                <div className={classes.topHeaderData}>
                  <strong>Report No:</strong> &nbsp; {` ${"123 323 145"}`}
                </div>
                <div className={classes.topHeaderData}>
                  <strong>Client:</strong> &nbsp;{" "}
                  {` ${company ? company.name : null}`}
                </div>
                <div className={classes.topHeaderBlueData}>
                  <strong>Date:</strong> &nbsp;{" "}
                  {` ${TimeMonthDayYear(new Date())}`}
                </div>
              </div>
            </div>
            <div className={classes.otherContentContainer}>
              <div className={classes.firstRowContainer}>
                <span
                  className={classes.inputsSubContainer}
                  style={{ marginRight: "5px" }}
                >
                  {/* <div className={classes.seprateTitle}>Weather</div> */}
                  <ReactInput
                    name="weather"
                    value={weather}
                    onChange={this.handleChange}
                    required
                    autoComplete="off"
                    type="text"
                    className={classes.typeInput}
                    placeholder="Weather"
                  />
                  {/* <SwapVert className={classes.icon} /> */}
                </span>
                <span
                  className={classes.inputsSubContainer}
                  style={{ marginLeft: "5px" }}
                >
                  {/* <div className={classes.seprateTitle}>Temp</div> */}
                  <ReactInput
                    name="temp"
                    value={temp}
                    onChange={this.handleChange}
                    required
                    autoComplete="off"
                    type="text"
                    className={classes.typeInput}
                    placeholder="Temp"
                  />
                </span>
                <span
                  className={classes.infoWithLogo}
                  style={{ marginLeft: "5px" }}
                >
                  <div className={classes.solidDataContainer}>
                    <PersonIcon />
                    {creator}
                  </div>
                </span>
                <span
                  className={classes.infoWithLogo}
                  style={{ marginLeft: "5px" }}
                >
                  <div className={classes.solidDataContainer}>
                    <WorkIcon />
                    Daily Logs
                  </div>
                </span>
              </div>
              <div className={classes.inputsContainer}>
                {/* <div className={classes.seprateTitle}>Work Performed Today</div> */}
                <textarea
                  name="workPerformedToday"
                  value={workPerformedToday}
                  onChange={this.handleChange}
                  required
                  placeholder={"Work Performed Today"}
                  autoComplete="off"
                  type="text"
                  className={classes.textarea}
                />
              </div>
              <div className={classes.inputsContainer}>
                {/* <div className={classes.seprateTitle}>Comments</div> */}
                <textarea
                  name="comments"
                  value={comments}
                  onChange={this.handleChange}
                  required
                  placeholder={"Comments"}
                  autoComplete="off"
                  type="text"
                  className={classes.textarea}
                />
              </div>
              <Grid container className={classes.attachmentButtonWrapper}>
                <Grid item xs={2}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={this.handleInputFile}
                    name="files"
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      // raised
                      component="span"
                      className={`${classes.attachmentButton} ${classes.button}`}
                    >
                      <Add className={classes.addIcon} />
                      ATTACHMENT
                    </Button>
                  </label>
                </Grid>
                <Grid
                  item
                  xs={10}
                  container
                  justify="flex-end"
                  alignItems="flex-start"
                >
                  {[...files].map((file, index) => (
                    <span className={classes.fileRepresent} key={index}>
                      <Close
                        className={classes.closeIconAttachment}
                        onClick={this.handleRemoveAttachment(index)}
                      />
                        <span className={classes.downloadIconAttachment}>
                          <DownloadIcon link={file.link} />
                        </span>
                        <span className={classes.fileName}>{file.name}</span>
                    </span>
                  ))}
                </Grid>
              </Grid>

              <div>
                <div className={classes.saveButtonContainer}>
                  <Button type="submit" className={classes.saveButton}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const Styles = {
  container: {
    padding: "10px 15px"
  },
  topItemsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  title: {
    fontSize: "20px",
    color: Colors.darkBlue
  },
  pmDesign: {
    color: Colors.darkBlue,
    textAlign: "right",
    marginRight: "40px",
    fontSize: "13px"
  },
  address: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "15px",
    position: "relative"
  },
  addressCreate: {
    width: "300px",
    fontSize: "12px"
  },
  solidDataContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "13px",
    padding: "0 10px"
  },
  solidData: {
    width: "50%",
    textAlign: "left",
    marginTop: "5px"
  },
  inputsContainer: {
    marginTop: "20px"
  },
  inputsSubContainer: {
    position: "relative",
    flexGrow: "400"
  },
  infoWithLogo: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: Colors.darkBlue
  },
  typeInput: {
    width: "100%",
    marginRight: "5px",
    borderColor: "#EAEBED",
    fontSize: "14px"
  },
  saveButtonContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  saveButton: {
    width: "130px",
    backgroundColor: Colors.green,
    color: "white",
    fontSize: "13px",
    padding: "6px 40px!important",
    borderRadius: "5px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: Colors.darkGreen
    }
  },
  cancelButton: {
    width: "130px",
    backgroundColor: Colors.grey,
    color: "white",
    fontSize: "13px",
    padding: "6px 40px!important",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: Colors.darkGrey
    }
  },
  formContainer: {
    backgroundColor: "#fdfdfd",
    marginTop: "15px",
    borderRadius: "4px"
  },
  centerFullHeight: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%"
  },
  otherContentContainer: {
    padding: "20px"
  },
  topHeaderContainer: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px #a6a6a6"
  },
  topHeaderTitle: {
    height: "calc(100% - 25px)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "20px",
    padding: "0 10px",
    color: "green"
  },
  topHeaderData: {
    height: "calc(100% - 25px)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "13px",
    padding: "0 10px",
    borderLeft: "solid 1px #a6a6a6"
  },
  topHeaderBlueData: {
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "13px",
    padding: "0 10px",
    backgroundColor: "#83a9c7",
    color: "white"
  },
  icon: {
    position: "absolute",
    right: 7,
    top: 7,
    color: Colors.darkenThanLightGrey
  },
  textarea: {
    height: "130px",
    width: "100%",
    borderColor: "#EAEBED",
    fontSize: "14px"
  },
  seprateTitle: {
    marginBottom: "10px",
    color: Colors.darkBlue,
    marginLeft: "5px"
  },
  firstRowContainer: {
    display: "flex",
    marginTop: "20px"
  },
  attachmentButton: {
    backgroundColor: Colors.darkBlue,
    color: "white",
    "&:hover": {
      backgroundColor: "#1661A8"
    }
  },
  attachmentButtonWrapper: {
    marginTop: "20px"
  },
  button: {
    paddingRight: "20px",
    paddingLeft: "20px",
    borderRadius: "5px"
  },
  input: {
    position: "absolute",
    opacity: 0,
    width: 0
  },
  fileRepresent: {
    borderRadius: "5px",
    border: "1px solid #D6D6D8",
    padding: "30px",
    margin: "5px",
    position: "relative",
    display: "inline-flex"
  },
  closeIconAttachment: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: Colors.grey,
    fontSize: "16px",
    cursor: "pointer"
  },
  downloadIconAttachment: {
    marginRight: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
const mapStateToProps = state => ({
  addresses: state.projects.project
    ? state.projects.project.addresses !== null
      ? state.projects.project.addresses
      : []
    : [],
  users: state.projects.users ? state.projects.users : [],
  creator: `${state.authentication.user.firstName} ${state.authentication.user.lastName}`,
  role: state.authentication.user.role,
  project: state.projects.project,
  companies: state.companies,
  formData: state.construction
});
export default connect(mapStateToProps)(withStyles(Styles)(PMDGDailyLogForm));
