export const progressBarConstants = {
  PROGRESS_START: "PROGRESS_START",
  PROGRESS_STOP: "PROGRESS_STOP",
  ISSUE_REQUEST_START: "ISSUE_REQUEST_START",
  ISSUE_REQUEST_END: "ISSUE_REQUEST_END",
  PROJECT_REQUEST_START: "PROJECT_REQUEST_START",
  PROJECT_REQUEST_END: "PROJECT_REQUEST_END",
  CLIENT_REQUEST_START: "CLIENT_REQUEST_START",
  CLIENT_REQUEST_END: "CLIENT_REQUEST_END",
  USERS_REQUEST_START: "USERS_REQUEST_START",
  USERS_REQUEST_END: "USERS_REQUEST_END",
};
