import React  from 'react';
import { withStyles } from "@material-ui/core";
import { isItOnTheBoard, isItOnTheRoad } from "./helpers";
import { calculateDots } from "./calculates-dots";

// #############################################################################
// component
// #############################################################################

function Lines(props){
  const { classes, projects = {}, roadToParent = [], width = 0 } = props;

  let lineArray = [];
  const keys = Object.keys(projects);
  const filteredKeys = keys.filter(key => projects[key].subProject.length > 0)

  filteredKeys.forEach(key => {
    projects[key].subProject.forEach(item => {
      if(isItOnTheBoard(key, projects) && isItOnTheBoard(item, projects))
        lineArray.push(<Line classes={classes} roadToParent={roadToParent} id={`line`} key={ key + item} start={key} end={item} projects={projects}/>)
    })
  });

  return ( <svg width={`${width}px`} className={classes.root}>{lineArray}</svg> );
}



// #############################################################################
// this component will render the line of the dependencies
// #############################################################################

function Line(props){
  const { classes = {}, start = "", end = "", projects = {}, roadToParent = []} = props;


  const isRoad = isItOnTheRoad(start, end, roadToParent);
  const isItRootProject = projects[start].parentProject === null;
  const padding = 12;
  const heightOfButton = 30;
  const widthOfButton = 170;

  let startX = isItRootProject ? widthOfButton / 2 : (projects[start].order - 1 ) * widthOfButton + (widthOfButton / 2);
  let startY = (projects[start].level) * (heightOfButton + (padding * 2)) + (heightOfButton + padding);
  let endX = (projects[end].order - 1 ) * widthOfButton + (widthOfButton / 2);
  let endY = (projects[end].level) * (heightOfButton + (padding * 2)) + padding;


  const dots = [startX, startY, endX, endY];
  return ( <polyline style={{strokeWidth: isRoad ? 3 : 1 }} className={classes.line} id={`start:${start},end:${end}`} points={calculateDots(dots)} />);
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    position:"absolute",
    minWidth: "100%",
    height:"100%",
    top: 0,
    left: 0,
  },
  line:{
    fill: "none",
    stroke: "#487599",
    strokeWidth: 1
  }
});

// #############################################################################
// connect material styles to component
// #############################################################################
const LinesWithStyles = withStyles(styles)(Lines);
export  { LinesWithStyles as Lines };
