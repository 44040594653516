export const snackBarConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',
    SNACKBAR_SUCCESS : 'SNACKBAR_SUCCESS',
    SNACKBAR_WARNING : 'SNACKBAR_WARNING',
    SNACKBAR_ERROR : 'SNACKBAR_ERROR',
    SNACKBAR_CLEAR : 'SNACKBAR_CLEAR',
    SNACKBAR_CLOSE : 'SNACKBAR_CLOSE',
    SNACKBAR_INFO : 'SNACKBAR_INFO',
};
