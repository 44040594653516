import { reportConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "../actions";

export const reportUserActions = {
  report
};

//################################################################################
// get Activities
//################################################################################
function report(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("report", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(payload) {
    return {
      type: reportConstants.GET_REPORTS_SUCCESS,
      payload,
      reportType: data.type
    };
  }
  // function failure(error) {
  //   return {
  //     type: reportConstants.GET_REPORTS_FAILURE,
  //     payload: error,
  //     reportType: data.type
  //   };
  // }
}
