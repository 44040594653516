// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { userActions, clientActions } from "../../../actions";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";

// Components //
import AddAssignUserInline from "../../User-List/AddAssignUserInline";
import UsersModalTableTitles from "./UsersModalTableTitles";
import UsersModalTableItem from "./UsersModalTableItem";
import DeleteModal from "../../Modal/DeleteModal";
// Reusable Functions //

// Styles //
import { Colors } from "../../../styles";

class UsersModal extends React.PureComponent {
  state = {
    selectedOptionForDelete: {},
    openDeleteModal: false,
    highlightedArrow: null,
    directionHighlightedArrow: null
  };

  componentDidMount() {
    this.props.dispatch(
      userActions.getCompanyUsers({ companyId: this.props.clientId })
    );
  }

  componentDidUpdate(prevState) {
    prevState.open === false &&
      this.props.open === true &&
      this.props.dispatch(clientActions.getAllCompanies()) &&
      this.props.dispatch(
        userActions.getCompanyUsers({ companyId: this.props.clientId })
      );
  }

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
          openDeleteModal: true,
          selectedOptionForDelete
        })
      : this.setState({
          openDeleteModal: false
        });
  };

  handleSort = (highlightedArrow, directionHighlightedArrow) => {
    this.setState({
      highlightedArrow,
      directionHighlightedArrow
    });
  };

  sort = () => {
    const { users = [] } = this.props;
    const { highlightedArrow, directionHighlightedArrow } = this.state;

    if (directionHighlightedArrow === null) return users;
    return directionHighlightedArrow === null
      ? users
      : users.sort((a, b) =>
          directionHighlightedArrow
            ? a[highlightedArrow] > b[highlightedArrow]
              ? 1
              : -1
            : a[highlightedArrow] > b[highlightedArrow]
            ? -1
            : 1
        );
  };

  render() {
    const { classes, open, handleUsersModal, clientId, companies } = this.props;
    return (
      <>
        <div>
          <Dialog
            open={open}
            keepMounted
            onClose={() => handleUsersModal(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.wrapper}
            maxWidth={"lg"}
          >
            <Grid className={classes.modal}>
              <Grid container className={classes.modalTitle}>
                <Close
                  className={classes.closeIcon}
                  onClick={() => handleUsersModal(false)}
                />
                <h3 className={classes.modalHeader}>Users</h3>

                <p className={classes.modalHeaderDescription}> </p>
              </Grid>
              <Grid container className={classes.modalBody}>
                <Grid container className={classes.addUserInlineWrapper}>
                  <AddAssignUserInline actionType clientId={clientId} />
                  <UsersModalTableTitles handleSort={this.handleSort} />
                  <div className={classes.tableRowsWrapper}>
                    {this.sort()
                      .filter(user => user.company._id === clientId)
                      .map((user, index) => (
                        <UsersModalTableItem
                          key={index}
                          open={open}
                          user={user}
                          handleDeleteModal={this.handleDeleteModal}
                          companies={companies}
                        />
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
        </div>

        <DeleteModal
          open={this.state.openDeleteModal}
          handleDeleteModal={this.handleDeleteModal}
          selectedOptionForDelete={{
            id: this.state.selectedOptionForDelete._id
          }}
          adminAction={userActions.deleteCompanyUser}
          userAction={userActions.deleteCompanyUser}
          //optional
          modalTitle={"Delete User"}
        />
      </>
    );
  }
}

const Styles = {
  wrapper: {
    // position: 'relative'
  },
  modal: {
    maxWidth: "1300px",
    minHeight: "500px",
    borderRadius: "10px",
    boxShadow: "0 0 10px #545454",
    padding: "30px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EFEFF9"
  },
  modalTitle: {
    position: "relative",
    display: "flex",
    flexDirection: "column"
  },
  closeIcon: {
    color: Colors.grey,
    position: "absolute",
    top: -17,
    right: -18,
    cursor: "pointer"
  },
  modalHeader: {
    color: Colors.darkBlue,
    margin: "0",
    fontWeight: "500"
  },
  modalHeaderDescription: {
    fontSize: "13px",
    padding: " 0 0 10px 3px",
    margin: 0,
    color: Colors.grey
  },
  addUserInlineWrapper: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column"
  },
  tableRowsWrapper: {
    height: "300px",
    maxHeight: "300px",
    overflowY: "overlay"
  }
};

const mapStateToProps = state => ({
  users: state.users ? state.users.companyUsers : [],
  companies: state.companies ? state.companies : []
});

export default connect(mapStateToProps)(withStyles(Styles)(UsersModal));
