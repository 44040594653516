import React from "react";
import { Link } from "react-router-dom";

// #####[ MATERIAL UI ]#########################################################
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

// #####[ MATERIAL UI ICONS ]###################################################
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'

// #############################################################################
// component
// #############################################################################

class UsersItem extends React.Component {
    state = {
        moreButton: false,
        moreExpand: false
    };

    componentDidMount() {
        const element = this.refs.tagHolder;
        if (element) {
            if (element.offsetHeight > 35) {
                this.setState({
                    moreButton: true,
                    height: element.offsetHeight
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.projectList !== prevProps.projectList) {
            const element = this.refs.tagHolder;
            if (element)
                if (element.offsetHeight > 35)
                    this.setState({
                        moreButton: true,
                        height: element.offsetHeight
                    });
                else
                    this.setState({
                        moreButton: false,
                        height: 35
                    });
        }
    }

    handleMoreButton = () => {
        this.setState(prevState => ({
            moreExpand: !prevState.moreExpand
        }));
    };
    // ###########################################################################
    // Render
    // ###########################################################################

    render() {
        const { classes, user, handleSelectUser, handleOpenDeleteUser, handleLockUser } = this.props;
        const { moreButton, moreExpand, height } = this.state
        return (
            <>
                <Grid container className={classes.userInfoCard}>
                    <Grid item xs={12} container className={classes.cardHeader}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={2}
                            className={classes.headerInfo}
                        >
                            <span className={classes.titles}>{`Name: `}</span>
                            <span>{`${user.firstName} ${user.lastName}`}</span>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={2}
                            className={classes.headerInfo}
                        >
                            <span className={classes.titles}>{`Email: `}</span>
                            <span>{user.email}</span>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={2}
                            className={classes.headerInfo}
                        >
                            <span className={classes.titles}>{`Phone: `}</span>
                            <span>{user.phoneNumber}</span>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={2}
                            className={classes.headerInfo}
                        >
                            <span className={classes.titles}>{`Company: `}</span>
                            <span>
                                {user.company ? user.company.name : ""}
                            </span>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={2}
                            className={classes.headerInfo}
                        >
                            <span
                                className={classes.titles}
                            >{`Company Role: `}</span>
                            <span>{user.role ? user.role : ""}</span>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={1}
                            className={classes.headerInfo}
                        >
                            <span className={classes.titles}>{`Title: `}</span>
                            <span>{user.title}</span>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={1}
                            className={classes.actions}
                        >
                            <div className={classes.iconHolder}>
                                <Tooltip title="Edit User">
                                    <IconButton
                                        onClick={handleSelectUser(user)}
                                        aria-label="Edit"
                                        className={classes.editIcon}
                                    >
                                        <EditOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                {user.role === 'admin' ? null : user.lock ?
                                    <Tooltip title="Unlock User">
                                        <IconButton
                                            onClick={handleLockUser({ id: user._id, lock: !user.lock })}
                                            aria-label="Delete"
                                            className={classes.lockUser}
                                        >
                                            <Lock fontSize="small" />

                                        </IconButton>
                                    </Tooltip> :
                                    <Tooltip title="Lock User">
                                        <IconButton
                                            onClick={handleLockUser({ id: user._id, lock: !user.lock })}
                                            aria-label="Delete"
                                            className={classes.unlockUser}
                                        >
                                            <LockOpen fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip title="Delete User">
                                    <IconButton
                                        onClick={handleOpenDeleteUser(user._id)}
                                        aria-label="Delete"
                                        className={classes.deleteIcon}
                                    >
                                        <CancelOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        {user.projectList[0] &&
                            <Grid container justify='center'>
                                <div
                                    className={classes.tagHolder}
                                    ref="tagHolder"
                                    style={
                                        moreButton
                                            ? moreExpand
                                                ? { height: height + 'px' }
                                                : { height: "27px" }
                                            : { height: height + 'px' }
                                    }
                                >
                                    <span className={classes.projectList}>{`Project${user.projectList[1] ? 's' : ''}`}:</span>
                                    {user.projectList.map((project, index) => {
                                        return (
                                            <span
                                                key={index}
                                                className={
                                                    index !== user.projectList.length - 1
                                                        ? `${classes.projectDetail} ${classes.withRightBorder}`
                                                        : `${classes.projectDetail}`
                                                }
                                            >
                                                <Link
                                                    to={`/admin-dashboard/projects/${project.projectId}`}
                                                >
                                                    {" "}
                                                    {`${project.role.split("-")[1]} at ${
                                                        project.projectName
                                                        }`}{" "}
                                                </Link>
                                            </span>
                                        );
                                    })}
                                </div>
                                {moreButton ? (
                                    <div className={classes.moreButtonContainer}>
                                        <Button
                                            onClick={this.handleMoreButton}
                                            className={classes.moreButton}
                                        >
                                            {moreExpand ? "Less" : "More"}
                                        </Button>
                                    </div>
                                ) : null}
                            </Grid>
                        }
                    </Grid>

                </Grid>
            </>
        )
    }
}


// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
    titles: {
        display: "none",
        color: "#155794",
        fontSize: "14px",
        paddingRight: "5px",
        [theme.breakpoints.down("sm")]: {
            display: "Block"
        }
    },
    userInfoCard: {
        position: "relative",
        marginBottom: "5px",
        backgroundColor: "#fff",
        padding: "8px 0 0",
        borderRadius: "2px"
    },
    headerInfo: {
        display: "flex",
        color: "#000000b3",
        fontSize: "14px"
    },
    cardHeader: {
        padding: '0 15px 8px'
    },
    projectList: {
        color: "#155794",
        fontSize: "14px"
    },
    projectDetail: {
        color: "#00000069",
        fontSize: "12px",
        padding: "2px 6px",
        fontWeight: 500,
        backgroundColor: "#00000005",
        borderRadius: 4,
        margin: 2,
        display: "inline-block",
        "& > a": {
            textDecoration: "none",
            color: "#4D8ED4"
        },
        "&:hover": {
            backgroundColor: "#00000020"
        }
    },
    tagHolder: {
        // lineHeight: 1.5,
        width: "100%",
        marginBottom: '8px',
        padding: '8px 15px 0',
        transition: '300ms!important',
        overflow: "hidden"
    },
    iconHolder: {
        position: "absolute",
        top: "5px",
        right: "5px"
    },
    deleteIcon: {
        padding: "3px",
        color: "#ff4b00cc"
    },
    editIcon: {
        padding: "3px",
        color: "#30b542e0"
    },
    moreButton: {
        color: "#00D47F",
        fontSize: '12px',
        padding: '3px',
        width: '100%',
        borderRadius: '0',
        '& span': {
            width: '100%'
        }
    },
    moreButtonContainer: {
        display: "flex",
        justifyContent: "center",
        width: '100%'
    },
    lockUser: {
        padding: "3px",
    },
    unlockUser: {
        opacity: 0.2,
        padding: "3px",
    }
});

const UsersItemStyles = withStyles(styles)(UsersItem);

// #############################################################################
// exports
// #############################################################################

export { UsersItemStyles as UsersItem };
