export const noteConstants = {
  GET_ALL_NOTES_SUCCESS: "GET_ALL_NOTES_SUCCESS",
  GET_ALL_NOTES_FAILURE: "GET_ALL_NOTES_FAILURE",

  ADD_NOTE_SUCCESS: "ADD_NOTE_SUCCESS",
  ADD_NOTE_FAILURE: "ADD_NOTE_FAILURE",

  EDIT_NOTE_SUCCESS: "EDIT_NOTE_SUCCESS",
  EDIT_NOTE_FAILURE: "EDIT_NOTE_FAILURE",

  DELETE_NOTE_SUCCESS: "DELETE_NOTE_SUCCESS",
  DELETE_NOTE_FAILURE: "DELETE_NOTE_FAILURE",

  ADD_ATTACHMENT_TO_NOTE_SUCCESS: "ADD_ATTACHMENT_TO_NOTE_SUCCESS",
  ADD_ATTACHMENT_TO_NOTE_FAILURE: "ADD_ATTACHMENT_TO_NOTE_FAILURE",

  DELETE_ATTACHMENT_TO_NOTE_SUCCESS: "DELETE_ATTACHMENT_TO_NOTE_SUCCESS",
  DELETE_ATTACHMENT_TO_NOTE_FAILURE: "DELETE_ATTACHMENT_TO_NOTE_FAILURE",

  CHANGEING_NUMBER_OF_COMMENTS: "CHANGEING_NUMBER_OF_COMMENTS",

  CLEARING_NOTES_DATA: "CLEARING_NOTES_DATA",
};
