import React from 'react';
import { withStyles } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// #############################################################################
// component
// #############################################################################

function NavigationButtons(props) {
  const { classes, handleNavigation } = props;
  const backgroundColor = "#487599";

  return (
    <div className={classes.root}>
      <div className={classes.buttonHolder}>

        <IconButton style={{backgroundColor}} id="NavigationButtonArrowUp" onClick={() => {handleNavigation("ArrowUp")}} className={`${classes.buttonTop} ${classes.button}`}>
          <ExpandMoreIcon/>
        </IconButton>

        <IconButton style={{backgroundColor}} id="NavigationButtonArrowRight" onClick={() => {handleNavigation("ArrowRight")}} className={`${classes.buttonRight} ${classes.button}`}>
          <ExpandMoreIcon/>
        </IconButton>

        <IconButton style={{backgroundColor}} id="NavigationButtonArrowDown" onClick={() => {handleNavigation("ArrowDown")}} className={`${classes.buttonBottom} ${classes.button}`}>
          <ExpandMoreIcon/>
        </IconButton>

        <IconButton style={{backgroundColor}} id="NavigationButtonArrowLeft" onClick={() => {handleNavigation("ArrowLeft")}} className={`${classes.buttonLeft} ${classes.button}`}>
          <ExpandMoreIcon/>
        </IconButton>

      </div>
    </div>
  );
}
// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    position: "relative",
    zIndex: 1000,
  },
  buttonHolder:{
    position: "absolute",
    transform: "rotate(45deg)",
    width: 43,
    height:43,
    right: 15,
    top: 67,
  },
  button:{
    position: "absolute",
    color: "white",
    padding: 0,
    "&:hover": {
      backgroundColor: "#3c6382"
    },
    "& > span > svg":{
      fontSize: 20,
    },
  },
  buttonTop:{
    left: 0,
    top:0,
    "& > span":{
      transform:" rotate(135deg)"
    }
  },
  buttonRight:{
    right: 0,
    top:0,
    "& > span":{
      transform:" rotate(-135deg)"
    }
  },
   buttonBottom:{
    bottom: 0,
    right: 0,
    "& > span":{
      transform:"rotate(-45deg)"
    }
  },
   buttonLeft:{
    bottom: 0,
    left: 0,
    "& > span":{
      transform:" rotate(45deg)"
    }
  }
});

// #############################################################################
// connect material styles to component
// #############################################################################
const NavigationButtonsWithStyles = withStyles(styles)(NavigationButtons)
export  { NavigationButtonsWithStyles as NavigationButtons };

