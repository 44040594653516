import React from "react";
import { connect } from "react-redux";
import { history } from "../../helpers";
import {userConstants} from '../../constants'

//Material UI
import { withStyles } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DashboardIcon from "@material-ui/icons/Dashboard";

const styles = theme => ({
  list: {
    width: 250
  },
  drawerProfile: {
    height: "60px",
    backgroundColor: "#2F2E82",
    display: "flex",
    flexDirection: "row",
    padding: "20px"
  },
  profilePhoto: {
    width: "64px",
    height: "64px",
    borderRadius: "32px",
    backgroundColor: "#ef5350"
  },
  profileInfo: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    overflow: "hidden"
  }
});

class UserSidebar extends React.PureComponent {
  handleDashboard = () => {
    history.push("/user-Dashboard");
  };

  handleSignOut = () => {
    // this.props.dispatch(userActions.logout);
    this.props.dispatch({ type: userConstants.LOGOUT });
  };

  sideList = classes => (
    <div className={classes.list}>
      <div className={classes.drawerProfile}>
        <div className={classes.profilePhoto} />
        <div className={classes.profileInfo}>
          {this.props.user ? (
            <div>{this.props.user.lastName}</div>
          ) : (
            <div>{JSON.parse(localStorage.getItem("user")).lastName}</div>
          )}
          {this.props.user ? (
            <div>{this.props.user.firstName}</div>
          ) : (
            <div>{JSON.parse(localStorage.getItem("user")).firstName}</div>
          )}
        </div>
      </div>
      <List>
        <div className={classes.listSpacer} />
        <ListItem button key={"Dashboard"} onClick={this.handleDashboard}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <Divider />
        <ListItem button key={"Signout"} onClick={this.handleSignOut}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={"Signout"} />
        </ListItem>
      </List>
    </div>
  );

  render() {
    const { classes } = this.props;
    return (
      <SwipeableDrawer
        open={this.props.drawer}
        onClose={this.props.toggleDrawer(false)}
        onOpen={this.props.toggleDrawer(true)}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={this.props.toggleDrawer(false)}
          onKeyDown={this.props.toggleDrawer(false)}
        >
          {this.sideList(classes)}
        </div>
      </SwipeableDrawer>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.authentication.user
  };
};

const UserSidebarConnect = connect(mapStateToProps)(UserSidebar);
const UserSidebarStyles = withStyles(styles)(UserSidebarConnect);

export { UserSidebarStyles as UserSidebar };
