import React from "react";
import { connect } from "react-redux";
import { snackBarActions, userActions } from "../../actions";
import { PAC } from "../../components";
import { UserProjects } from "../../components/UserDashboard/Projects/UserProjects";
import { Router, Switch, Route, withRouter } from "react-router-dom";
import { history } from "../../helpers";
import { CustomSnackbar, Profile } from "../../components";
import { SideBar } from "./side-bar";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core";

// #############################################################################
// Styles
// #############################################################################

const styles = theme => ({
  root: {
    display: "flex"
  },
  main: {
    backgroundColor: "#eff0f5",
    height: "100vh",
    flex: 1,
    width: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column"
  },
  waiting: {
    position: "fixed",
    top: "50%",
    left: "50%"
  }
});

// #############################################################################
// Component
// #############################################################################

class UserDashboard extends React.Component {
  // ###########################################################################
  //
  // ###########################################################################

  handleCloseSnackBar = () => {
    this.props.dispatch(snackBarActions.snackBarClose());
  };

  // ###########################################################################
  //
  // ###########################################################################

  componentDidMount = () => {
    const { user } = this.props;
    if (!user) {
      this.props.dispatch(userActions.whoAmI());
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  componentDidUpdate = () => {
    this.checkURL(this.props.user, this.props.match);
  };

  // ###########################################################################
  //
  // ###########################################################################

  checkURL = (user = {}, match = {}) => {
    if (user.role && user.role !== "user") history.push("/admin-dashboard");
  };

  // ###########################################################################
  //
  // ###########################################################################

  render() {
    const { classes, snackBar, user = {} } = this.props;
    if (user && user.role === "user") {
      return (
        <div className={classes.root}>
          <SideBar />
          <main className={classes.main}>
            <Router history={history}>
              <Switch>
                <Route
                  path={"/user-dashboard/projects/:projectId"}
                  component={PAC}
                />
                <Route path={"/user-dashboard/Profile"} component={Profile} />
                <Route path={"/user-dashboard/"} component={UserProjects} />
              </Switch>
            </Router>
          </main>

          {snackBar && (
            <CustomSnackbar
              autoHideDuration={4000}
              {...snackBar}
              onClose={this.handleCloseSnackBar}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className={classes.waiting}>
          <CircularProgress thickness={1} />
        </div>
      );
    }
  }
}

// #############################################################################
// Map State To Props
// #############################################################################

const mapStateToProps = state => {
  return {
    project: state.projects.project,
    user: state.authentication.user,
    snackBar: state.snackBar.snackBar
  };
};

const UserDashboardWithRouter = withRouter(UserDashboard);
const UserDashboardConnect = connect(mapStateToProps)(UserDashboardWithRouter);
const UserDashboardStyles = withStyles(styles)(UserDashboardConnect);

// #############################################################################
// export
// #############################################################################

export { UserDashboardStyles as UserDashboard };
