import React from "react";

// #####[ Material UI ]#########################################################
import { withStyles } from "@material-ui/core";

import { issueActions, projectActions, filterActions } from "../../../actions";

import { IssueList } from "../../";
import AddIssueInline from "../../IssueList/AddIssueInline";
import ThemeContext from "../../ContextApi/Context";
import { connect } from "react-redux";
// #############################################################################
// component
// #############################################################################

class AdminProjectIssues extends React.PureComponent {
  // ###########################################################################
  // clear search input and gets all the issues from server
  // ###########################################################################
  static contextType = ThemeContext;

  componentDidMount = () => {
    const { dispatch } = this.props;
    const projectId = this.context;
    dispatch(issueActions.getProjectIssues({ projectId }));
    dispatch(projectActions.getProjectUsers({ projectId }));
    dispatch(filterActions.pageFilterEdit(""));
  };

  // ###########################################################################
  // Add Issue
  // ###########################################################################

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.issues}>
        <AddIssueInline />
        <IssueList pageCapacity={7} />
      </div>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  issues: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }
});

const mapToStateProps = () => ({});

const AdminIssuesStyles = withStyles(styles)(AdminProjectIssues);
const AdminIssuesConnected = connect(mapToStateProps)(AdminIssuesStyles);

// #############################################################################
// export
// #############################################################################

export { AdminIssuesConnected as AdminProjectIssues };
