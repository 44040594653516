import React from 'react'
import { withStyles } from '@material-ui/core'
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../styles/colors'

const styles = {
    iconButton: {
        padding: '3px !important',
        color: Colors.lightGreen,
    },
    attachmentIcon: {
        color: Colors.lightGrey,
        fontSize: "22px",
        transform: "rotate(45deg)"
    },
    minimizeIcon: {
        transition: "300ms",
        transform: "rotate(180deg)",
        fontSize: '22px'
    },
}

export const ArrowAttachment = React.memo(withStyles(styles)(({ classes, onClick, isMaximizeForAttachments }) => (
    <>
        {isMaximizeForAttachments ? (
            <Tooltip title={"Minimize"}>
                <IconButton
                    className={classes.iconButton}
                    onClick={onClick}
                >
                    <KeyboardArrowDown
                        className={classes.minimizeIcon}
                    />
                </IconButton>
            </Tooltip>
        ) : (
                <Tooltip title={"Attachments"}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={onClick}
                    >
                        <AttachFileIcon
                            className={classes.attachmentIcon}
                        />
                    </IconButton>
                </Tooltip>
            )}
    </>
))
)
