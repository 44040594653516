import { addDays, generateLine } from "./"

// #############################################################################
// handle drag 
// #############################################################################

export function handleDrag(event, reference, task = {} , tasks = {}, maxDependency, minDepender, minEdge, handleEditTask, IdPrefix = "", unit = 25){
  // the start of the mouse position on the drag
  const mouseStartPostition = event.pageX

  // the original left of the draging element 
  const originalLeft = reference.current.offsetLeft;

  // the highlight of the time line which shows the position of the draging element
  // on the task board
  const timeLineHighlighter = document.getElementById("TIME-LINE-HIGHLIGHTER");

  const onMouseMove = event => {
    moveAt(event, mouseStartPostition, originalLeft , reference, task , tasks, maxDependency, minDepender, timeLineHighlighter, IdPrefix);
  }

  const removeEventListeners = event => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', removeEventListeners);
    const [startDate, finishDate] = moveEnd(event, reference, task , tasks, minEdge, maxDependency, minDepender, timeLineHighlighter, IdPrefix, unit);
    clearNode(timeLineHighlighter)
    if(IdPrefix === "PLANNED")
      handleEditTask({plannedStartDate: startDate, plannedFinishDate: finishDate}); 
    else
      handleEditTask({actualStartDate: startDate, actualFinishDate: finishDate})
  }

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', removeEventListeners);
}

// #############################################################################
// handle movement of the element
// #############################################################################

export function moveAt(event, mouseStartPostition, originalLeft, reference, task = {} , tasks = {}, maxDependency, minDepender, timeLineHighlighter, IdPrefix = "") {
    const isActualProgress = task._id === reference.current.id;

    const parent = tasks[task.parentTask];

    const left = originalLeft + event.pageX - mouseStartPostition;

    reference.current.style.left = left + "px";
    timeLineHighlighter.style.left = left + "px";
    timeLineHighlighter.style.width = reference.current.offsetWidth + "px";

    if(isActualProgress)
      changeLines(task, reference);

    if(parent)
      updateParent(event, parent, tasks, IdPrefix)
    
  }

// #############################################################################
// handle drag end
// #############################################################################

export function moveEnd(event, reference, task, tasks, minEdge, maxDependency, minDepender , timeLineHighlighter, IdPrefix, unit){
  // all the tasks time line has one pixel less to fit between the days vertical lines
  const onePixel = 1;
  const parent = tasks[task.parentTask];
  const isActualProgress = task._id === reference.current.id;

  const { offsetWidth, offsetLeft } = reference.current;
  
  let left = (offsetLeft + 1) - ((offsetLeft + 1) % unit);  

  // this line will justify the position of dragged element between days lines
  reference.current.style.left = left + "px";

  timeLineHighlighter.style.left = left + "px";
  timeLineHighlighter.style.width = reference.current.offsetWidth + "px";

  if(parent) updateParent(event, parent, tasks, IdPrefix);

  if(isActualProgress) changeLines(task, reference);

  return convertPositionToDates(left, offsetWidth + onePixel, task, minEdge, unit);

}

// #############################################################################
// this function will handle the parents and update theme
// #############################################################################

export function updateParent(event, task = {} , tasks = {}, IdPrefix = ""){

  const isActualProgress = IdPrefix === "";

  // this line will find the min and max of child of the task
  const [min, max] = findMinAndMaxEdge(task.subTask, IdPrefix);

  // this line will get the parent node object from HTML tree
  const node = document.getElementById(IdPrefix + task._id);

  // these two lines will update the parent tasks of the drag task
  node.style.left = `${min}px`;
  node.style.width = `${max - min}px`;

  if(isActualProgress) changeLines(task, {current: node});

  // if there is a parent do the same 
  if(task.parentTask) updateParent(event, tasks[task.parentTask], tasks, IdPrefix);
}

// #############################################################################
// this function will converts the dragged node to start and finish date
// #############################################################################

export function convertPositionToDates(left, width, task, minEdge, unit = 25){
  const minDate = addDays(new Date(minEdge), left / unit);
  const maxDate = addDays(new Date(minEdge), (left + width - unit) / unit);

  return [minDate, maxDate];
}

// #############################################################################
// this function will find the min and max edges between child nodes
// #############################################################################

export function findMinAndMaxEdge(childArray, IdPrefix = ""){
  let max = 0;
  let min = Number.MAX_VALUE;

  childArray.forEach(id => {
    const element = document.getElementById(IdPrefix + id);
    min = Math.min(element.offsetLeft, min);
    max = Math.max(element.offsetLeft + element.offsetWidth, max)
  });
  return [min, max];
}

// #############################################################################
// this function will find the end and start lines of target task
// #############################################################################

export function changeLines(task = {}, reference = {}){

  const endLines = document.querySelectorAll(`[id $= "end:${task._id}"]`);
  const startLines = document.querySelectorAll(`[id ^= "start:${task._id}"]`);
  
  const left = reference.current.offsetLeft;
  const width = reference.current.offsetWidth;

  startLines.forEach(polylineNode => {
    const points = exportPoints(polylineNode);
    const lastIndex = points.length - 1;

    const startX = left + width;
    const startY = points[0].y;
    
    const endX = points[lastIndex].x;
    const endY = points[lastIndex].y;

    const outputPoints = generateLine([startX, startY, endX, endY]);

    polylineNode.setAttribute("points", outputPoints)
    
  });

  endLines.forEach(polylineNode => {
    const points = exportPoints(polylineNode)
    const lastIndex = points.length - 1;

    const startX = points[0].x;
    const startY = points[0].y;

    const endX = left;
    const endY = points[lastIndex].y;

    const outputPoints = generateLine([startX, startY, endX, endY]);
    polylineNode.setAttribute("points", outputPoints)
    
  })
}

// #############################################################################
// this function will export the polyLines dots and return the all the dots
// #############################################################################

export function exportPoints(node){
  const pointsArray =  node.attributes.points.nodeValue.split(" ");
  return pointsArray.map(point => {
    const coordinate = point.split(",");
    return {x: parseFloat(coordinate[0]), y: parseFloat(coordinate[1])}
  })
}

// #############################################################################
// clear node
// #############################################################################

export function clearNode(node = {}){
  if(node.style && node.style.left){
    setTimeout(() => {
      node.style.width = 0;
    }, 200)
  }
}






