// React //
import React from "react";

// Redux //
import { connect } from "react-redux";

// Material Ui //
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";


// Reusable Functions //

// Styles //
import { Colors } from '../../styles/colors'
class AlertModalExistFile extends React.Component {

    state = {
        open: false,
        confirmText: ''
    };

    componentDidMount() {
        this.setState({ open: this.props.open })
    }
    shouldComponentUpdate(nextProps, nextState) {
        return nextState.confirmText !== this.state.confirmText || !nextProps.open === this.state.open

    }

    componentDidUpdate(pervProps) {
        if (this.props.open === false)
            this.setState({ confirmText: '' })

        if (this.props.open !== pervProps.open)
            this.setState({ open: this.props.open })
    }

    handleChange = ({ target }) => {
        this.setState({
            [target.name]: target.value
        })
    }
    handleAlertDocument = (deleteExistAttachment) => {
        const {
            dispatch,
            selectedOptionForAlert,
            adminAction,
            userAction,
            role,
            projectId,
            handleAlertModal,
            confirmWithName,
        } = this.props;

        if (!confirmWithName || confirmWithName === this.state.confirmText) {
            if (role === 'adminFlag')
                dispatch(adminAction({
                    attachmentId: selectedOptionForAlert.attachmentId,
                    parentAttachment: selectedOptionForAlert.parentAttachment,
                    order: selectedOptionForAlert.order,
                    projectDocument: true,
                    projectId,
                    ...deleteExistAttachment
                },
                    selectedOptionForAlert.tempAllDocument));
            else
                dispatch(userAction({
                    attachmentId: selectedOptionForAlert.attachmentId,
                    parentAttachment: selectedOptionForAlert.parentAttachment,
                    order: selectedOptionForAlert.order,
                    projectDocument: true,
                    projectId,
                    ...deleteExistAttachment
                },
                    selectedOptionForAlert.tempAllDocument));

            handleAlertModal();
        }
    };

    render() {
        const { open } = this.state;
        const {
            optionNameForAlert,
            messageText,
            classes,
            modalTitle,
            handleAlertModal,
        } = this.props

        return (
            <div>
                <Dialog
                    open={open}
                    keepMounted
                    onClose={() => handleAlertModal()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.wrapper}
                >
                    <Grid container>
                        <Grid item xs={12} className={classes.modalHeader}>
                            <WarningRoundedIcon className={classes.warn} />
                            <h3 className={classes.headerText}>
                                {modalTitle ? modalTitle : "Alert!"}
                            </h3>
                            <IconButton
                                onClick={() => handleAlertModal()}
                                aria-label="Delete"
                                className={classes.cancelButton}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} className={classes.DialogContent}>
                            <DialogContent style={{ padding: 0 }} >
                                <DialogContentText className={classes.messageText}>
                                    {messageText ? messageText :
                                        "You won't be able to restore, are you sure you want to delete?"
                                    }
                                </DialogContentText>
                                {optionNameForAlert &&
                                    <ul className={classes.optionNameForAlert}>
                                        <li>{optionNameForAlert}</li>
                                    </ul>
                                }
                            </DialogContent>
                        </Grid>
                        <Grid item xs={12} >
                            <DialogActions className={classes.DialogActions}>
                                <Button type='button' onClick={() => handleAlertModal()} color="primary">
                                    Cancel
                                </Button>
                                <Button type='submit' color='secondary' onClick={() => this.handleAlertDocument({ deleteExistAttachment: true })}>
                                    Replace
                                </Button>
                                <Button type='submit' className={classes.keepBothButton} onClick={() => this.handleAlertDocument({ deleteExistAttachment: false })}>
                                    Keep Both
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>


                </Dialog>
            </div >);
    }
}

const Styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column'
    },

    confirmText: {
        width: '100%'
    },

    DialogContent: {
        padding: '25px 25px 10px 25px'
    },
    confirm: {
        padding: '25px 25px 0 25px'
    },
    DialogActions: {
        padding: '10px 25px 10px 25px'
    },
    modalHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: Colors.green,
        height: "50px",
        padding: "0px 50px"
    },
    headerText: {
        margin: "3px 0px 0px",
        color: "white"
    },
    warn: {
        color: "white",
        position: "absolute",
        left: "10px"
    },
    cancelButton: {
        position: "absolute",
        right: "10px",
        padding: "7px",
        color: 'white'
    },
    messageText: {
        color: Colors.green
    },
    commandText: {
        margin: '7px 0'
    },
    confirmTextfaultText: {
        margin: 0,
        fontSize: '14px',
        color: 'red'
    },
    optionNameForAlert: {
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        WebkitHyphens: 'auto',
        MsHyphens: 'auto',
        hyphens: 'auto',
        paddingRight: '30px'
    },
    keepBothButton: {
        color: Colors.green,
        '&:hover': {
            backgroundColor: '#dcf2e2'
        }
    }
};

const mapStateToProps = state => ({
    role: state.projects.project ? state.projects.project.role : undefined,
    projectId: state.projects.project ? state.projects.project._id : undefined
});

export default connect(mapStateToProps)(withStyles(Styles)(AlertModalExistFile));

                // #######################################################################################
                // ####################              Dialog: Alert component            ####################
                // #######################################################################################

// {/* <AlertModal
// open={this.state.openAlertModal}
// handleAlertModal={this.handleAlertModal}
//   selectedOptionForAlert={{ issueTaskId: this.state.selectedOptionForAlert._id }}
// adminAction={attachemntActions.alertAttachment}
// userAction={attachemntUserActions.alertAttachment}
// //optional
// modalTitle={'Alert Attachment'}
// optionNameForAlert={this.state.selectedOptionForAlert.name}
// messageText={`This action will alert the entire project and the users !!!.You won't be able to restore, are you sure you want to alert?`}
// submitButtonText='Unassign'


//confirm
// confirmInputPlaceHolder ={}
// isConfirmNeeded={true}
// confirmWithName={'123'}
// /> */}

// #######################################################################################
// ####################              Dialog: Alert handle            ####################
// #######################################################################################

// handleAlertModal = selectedOptionForAlert => {
//     selectedOptionForAlert ?
//         this.setState({
//             openAlertModal: true,
//             selectedOptionForAlert
//         }) :
//         this.setState({
//             openAlertModal: false,
//         })
// }
