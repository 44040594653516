export const clientConstants = {
  GET_ALL_COMPANIES_SUCCESS: "GET_ALL_COMPANIES_SUCCESS",
  GET_ALL_COMPANIES_FAILURE: "GET_ALL_COMPANIES_FAILURE",

  ADD_COMPANY_SUCCESS: "ADD_COMPANY_SUCCESS",
  ADD_COMPANY_FAILURE: "ADD_COMPANY_FAILURE",

  EDIT_COMPANY_SUCCESS: "EDIT_COMPANY_SUCCESS",
  EDIT_COMPANY_FAILURE: "EDIT_COMPANY_FAILURE",

  DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",
  DELETE_COMPANY_FAILURE: "DELETE_COMPANY_FAILURE",

  DELETE_COMPANY_IMAGE_SUCCESS: "DELETE_COMPANY_IMAGE_SUCCESS",
  DELETE_COMPANY_IMAGE_FAILURE: "DELETE_COMPANY_IMAGE_FAILURE",

  UPDATE_COMPANY_IMAGE_SUCCESS: "UPDATE_COMPANY_IMAGE_SUCCESS",
  UPDATE_COMPANY_IMAGE_FAILURE: "UPDATE_COMPANY_IMAGE_FAILURE",

  CHANGE_CLIENT_ORDER_SUCCESS: "CHANGE_CLIENT_ORDER_SUCCESS",
  CHANGE_CLIENT_ORDER_FAILURE: "CHANGE_CLIENT_ORDER_FAILURE",
};
