// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { attachmentActions } from '../../actions'
import { attachmentUserActions } from '../../userActions'

// Material Ui //
import { withStyles } from "@material-ui/core";

// Components //
import {
  EditIcon, DownloadIcon, DeleteIcon, DateRangeIcon, UpwardArrow, FolderAndPlus,
  FileIcon, NewFolder
} from '../Icons'
import { SaveButton, CancelButton } from '../Buttons/'
import AddAttachmentIconAddFile from '../Buttons/AddAttachment/AddAttachmentIconAddFile'
import { ReactInput } from '../reactInput/reactInput'
import { AddNewFolder } from './'

// Reusable Functions //
import TimeYearMonthDay from '../reusebleFunctions/TimeYearMonthDay'
// Styles //
import { Colors } from '../../styles/colors'

class DocumentListItem extends React.PureComponent {
  state = {
    editMode: false,
    isMaximizeForVersions: false,
    isFolderOpend: false,
    name: this.props.documents.name,
    attachmentNote: this.props.documents.attachmentNote,
    isChangeHappend: false,
    isAddNewFolder: false,
    attachment: '',
    color: 'white'
  };

  componentDidMount() {
    this.setState({
      name: this.props.documents.name,
      attachmentNote: this.props.documents.attachmentNote,
    })
  }

  componentDidUpdate(prevProps) {
    this.props.documents !== prevProps.documents &&
      this.setState({
        name: this.props.documents.name,
        attachmentNote: this.props.documents.attachmentNote,
      })
  }

  handleMinimizeMaximizeForVersions = (e) => {
    this.setState(prevState => ({
      isMaximizeForVersions: !prevState.isMaximizeForVersions,
      isFolderOpend: false
    }))
  }

  handleOpenClose = () => {
    !this.state.editMode && this.setState(prevState => ({
      isMaximizeForVersions: !prevState.isMaximizeForVersions,
      isFolderOpend: !prevState.isFolderOpend
    }))
  }

  handleSaveEdits = () => {
    const { attachmentNote, isChangeHappend, name } = this.state
    const { dispatch, role, projectId, documents } = this.props

    if (isChangeHappend) {
      if (role === 'adminFlag')
        dispatch(attachmentActions.editAttachment({
          projectId,
          attachmentId: documents._id,
          projectDocument: false,
          attachmentNote,
          name
        }));
      else
        dispatch(attachmentUserActions.editAttachment({
          projectId,
          attachmentId: documents._id,
          projectDocument: false,
          attachmentNote,
          name
        }))
    }
    this.setState({
      editMode: false
    })
  }

  handleEdit = () => {
    this.setState({
      editMode: true
    })
  }

  handleCancel = () => {
    const { documents } = this.props
    this.setState({
      editMode: false,
      name: documents.name,
      attachmentNote: documents.attachmentNote,
      isChangeHappend: false,
      isAddNewFolder: false
    })
  }

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value, isChangeHappend: true
    })
  }

  handleSubmitFiles = (files) => {
    this.setState({
      files,
      isChangeHappend: true
    })
  }

  handleAddNewFiles = ({ target }) => {
    const { dispatch, role, projectId, documents } = this.props
    let document = new FormData();
    document.append("projectId", projectId);
    const parentAttachment = documents.isFolder ? documents._id : documents.parentAttachment
    document.append('parentAttachment', parentAttachment ? parentAttachment : 'null')
    document.append('projectDocument', true)
    for (var i = 0; i < target.files.length; i++)
      document.append("document", target.files[i], documents.isFolder ? target.files[i].name : documents.name);
    !documents.isFolder && document.append('addVersion', true)
    if (role === 'adminFlag')
      dispatch(attachmentActions.addAttachment(document));
    else
      dispatch(attachmentUserActions.addAttachment(document))

    this.setState({
      isFolderOpend: true
    })
    target.value = null
  }

  handleAddNewFolder = ({ name, attachmentNote }) => {
    const { dispatch, projectId, documents, role } = this.props
    role === 'adminFlag' ?
      dispatch(attachmentActions.addFolder({
        projectId,
        name,
        attachmentNote,
        parentAttachment: documents._id,
        projectDocument: true
      })) :
      dispatch(attachmentUserActions.addFolder({
        projectId,
        name,
        attachmentNote,
        parentAttachment: documents._id,
        projectDocument: true
      }))

    this.setState({
      isAddNewFolder: false,
      isFolderOpend: true
    })

  }

  handleAddNewFolderMode = () => {
    this.setState((prevState) => ({
      isAddNewFolder: !prevState.isAddNewFolder
    })
    )
  }

  handleToggleAddNewFolder = () => {
    this.setState((prevState) => ({
      isAddNewFolder: !prevState.isAddNewFolder
    })
    )
  }

  handleChangeColor = (bool) => {
    this.setState({
      color: bool ? 'fff4c9' : 'white'
    })
  }


  // ###########################################################################
  //
  // ###########################################################################

  onDragStart = ({ currentTarget, dataTransfer, target }) => {
    const { handleOnDragStart } = this.props;
    handleOnDragStart(currentTarget);
    dataTransfer.setData("text/html", currentTarget.id);
    currentTarget.style.opacity = "0.4";
  };

  // ###########################################################################
  //
  // ###########################################################################

  onDragOver = ({ currentTarget }) => {
    if (
      this.props.previousItem &&
      this.props.previousItem.id !== currentTarget.id
    ) {
      this.props.hanldeOnDragOver(currentTarget);
      // this.setState({ currentTarget: {} });

      if (currentTarget.id === "middle") {
        this.refs.line.style.display = "inline";
        currentTarget.style.height = "25px";
      } else if (currentTarget.id === "middleTop") {
        this.refs.lineTop.style.display = "inline";
        currentTarget.style.height = "25px";
      } else {
        this.refs.nameTd.style.backgroundColor = '#F5E9BE'
        this.refs.noteTd.style.backgroundColor = '#F5E9BE'
        this.refs.dateTd.style.backgroundColor = '#F5E9BE'
        this.refs.actionTd.style.backgroundColor = '#F5E9BE'
      }
    } else {
      this.props.hanldeOnDragOver(currentTarget);
    }
  };

  onDragLeave = ({ currentTarget }) => {
    if (
      this.props.previousItem &&
      this.props.previousItem.id !== currentTarget.id
    ) {
      if (currentTarget.id === "middle") {
        this.refs.line.style.display = "none";
        currentTarget.style.height = "10px";
      } else if (currentTarget.id === "middleTop") {
        this.refs.lineTop.style.display = "none";
        currentTarget.style.height = "10px";
      } else {
        this.refs.nameTd.style.backgroundColor = 'white'
        this.refs.noteTd.style.backgroundColor = 'white'
        this.refs.dateTd.style.backgroundColor = 'white'
        this.refs.actionTd.style.backgroundColor = 'white'
      }
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  onDragEnd = e => {
    e.preventDefault();
    const { handleOnDragEnd } = this.props;
    handleOnDragEnd(e.currentTarget.id)
    e.target.style.opacity = "1";
  };


  render() {
    const {
      classes = {},
      documents = [],
      dynamicMargin,
      dynamicRowColor,
      handleDeleteModal,
      dispatch,
      projectId,
      role,
      allDocuments,
      index,
      previousItem
    } = this.props;
    const {
      isMaximizeForVersions,
      isFolderOpend,
      editMode,
      name,
      attachmentNote,
      isAddNewFolder,
      color
    } = this.state

    let versions = allDocuments.filter(item => item.mainVersionId === documents._id)
    let childs = allDocuments.filter(document => document.parentAttachment === documents._id && !document.mainVersionId)

    return (
      <>

        {index === 0 && !documents.mainVersionId && (
          <div
            style={{
              width: "100%",
              height: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "150ms",
              zIndex: 10,
              paddingLeft: 13 + Number(dynamicMargin) + 'px',
              paddingRight: '3px',
              boxSizing: 'border-box',
            }}
            id={"middleTop"}
            onDragStart={!documents.mainVersionId ? this.onDragStart : () => { }}
            onDragOver={!documents.mainVersionId ? this.onDragOver : () => { }}
            onDragEnd={!documents.mainVersionId ? this.onDragEnd : () => { }}
            onDragLeave={!documents.mainVersionId ? this.onDragLeave : () => { }}
            parentattachment={documents.parentAttachment}
            draggable={false}
            index={0}
          // order={order}
          >
            <div
              style={{
                height: "80%",
                width: "100%",
                backgroundColor: "#FFCA00",
                display: "none",
                pointerEvents: "none"
              }}
              ref="lineTop"
            ></div>
          </div>
        )}

        <div
          className={classes.rowWrapper}
          style={documents.mainVersionId ? { margin: '0', border: 'none' } : {}}
          onDragStart={!documents.mainVersionId ? this.onDragStart : () => { }}
          onDragOver={!documents.mainVersionId ? this.onDragOver : () => { }}
          onDragEnd={!documents.mainVersionId ? this.onDragEnd : () => { }}
          onDragLeave={!documents.mainVersionId ? this.onDragLeave : () => { }}
          draggable={!documents.mainVersionId && role !== 'Project-Client'}
          id={documents._id}
          parentattachment={documents.parentAttachment}
          index={index}
          ref="project"
        >
          <div className={classes.row} style={{ filter: `brightness(${dynamicRowColor}%)` }}>
            <div className={classes.nameTd}
            // onClick={this.handleOpenClose}
            >
              <div style={{ marginLeft: `${dynamicMargin}px`, backgroundColor: color }} ref='nameTd'>
                {documents.isFolder ?
                  <FolderAndPlus
                    isFolderOpend={isFolderOpend}
                    hasChild={childs[0]}
                    onClick={this.handleOpenClose}
                  /> :
                  <FileIcon color={documents.mainVersionId ? Colors.darkenThanLightGrey : Colors.grey} />
                }

                {documents.isFolder && editMode ?
                  <ReactInput className={classes.input} name='name' onChange={this.handleChange} value={name} /> :
                  <span style={documents.mainVersionId ? { color: Colors.darkenThanLightGrey } : {}}>{name} </span>
                }
              </div>
            </div>
            <div className={classes.noteAttachmentTd}
              // onClick={this.handleOpenClose}
              style={{ backgroundColor: color }}
              ref='noteTd'
            >
              {!editMode ? attachmentNote :
                <textarea rows={1} className={classes.textarea} name='attachmentNote' onChange={this.handleChange} value={attachmentNote} />
              }
            </div>
            <div className={classes.dateTd} style={documents.mainVersionId ? { color: Colors.darkenThanLightGrey } : {}}
              // onClick={this.handleOpenClose}
              ref='dateTd'
            >{TimeYearMonthDay(documents.createDate)}
            </div>
            <div className={classes.actionsTd}
              style={role === 'Project-Client' ?
                { width: '90px' } : editMode ? { width: '220px' } : {}}
              ref='actionTd'
            >
              {!editMode ?
                <>

                  {!versions[0] && !documents.isFolder && <span style={{ width: '30px', height: '38px' }}></span>}
                  {documents.isFolder && <span style={{ width: '30px', height: '38px' }}></span>}

                  {versions[0] ? isMaximizeForVersions ?
                    <UpwardArrow onClick={this.handleMinimizeMaximizeForVersions} /> :
                    <DateRangeIcon onClick={this.handleMinimizeMaximizeForVersions} title='Versions' /> :
                    null
                  }

                  {role === 'Project-Client' ? null : documents.isFolder ?
                    <>
                      <AddAttachmentIconAddFile handleAddNewFiles={this.handleAddNewFiles} />
                      <NewFolder onClick={this.handleAddNewFolderMode} />
                    </> :
                    <>
                      {documents.mainVersionId ?
                        <span style={{ width: '30px', height: '38px' }}></span>
                        :
                        <AddAttachmentIconAddFile handleAddNewFiles={this.handleAddNewFiles} />
                      }
                    </>
                  }

                  {!documents.isFolder && <DownloadIcon onClick={this.handleDownlaod} link={documents.link} />}
                  {role !== 'Project-Client' &&
                    <>
                      <EditIcon onClick={this.handleEdit} />
                      <DeleteIcon onClick={() => handleDeleteModal(documents)} />
                    </>
                  }
                </> :
                <>
                  <SaveButton onClick={this.handleSaveEdits} disabled={!name} />
                  <CancelButton onClick={this.handleCancel} />
                </>
              }
            </div>
          </div>

          {/* for mapping versions of the file */}
          {isMaximizeForVersions && versions.map((child, index) => (
            <DocumentListItem
              key={index}
              index={index}
              documents={child}
              children={childs} //passing children to force pureComponent rerender
              allDocuments={allDocuments}
              handleDeleteModal={handleDeleteModal}
              handleEdit={this.handleEdit}
              classes={classes}
              dynamicMargin={`${Number(dynamicMargin)}`}
              dynamicRowColor={`${Number(dynamicRowColor) - 3}`}
              dispatch={dispatch}
              projectId={projectId}
              name={documents.name}
              attachmentNote={documents.attachmentNote}
              role={role}
              onDragStart={!documents.mainVersionId ? this.onDragStart : () => { }}
              onDragOver={!documents.mainVersionId ? this.onDragOver : () => { }}
              onDragEnd={!documents.mainVersionId ? this.onDragEnd : () => { }}
              onDragLeave={!documents.mainVersionId ? this.onDragLeave : () => { }}
              draggable={role !== 'Project-Client'}
            />
          ))}
        </div>

        {
          isAddNewFolder &&
          <AddNewFolder
            handleAddNewFolder={this.handleAddNewFolder}
            dynamicMargin={`${Number(dynamicMargin) + 15}`}
            dynamicRowColor={`${Number(dynamicRowColor) - 3}`}
            handleToggleAddNewFolder={this.handleToggleAddNewFolder}
          />
        }

        {((childs[0] && !isFolderOpend) || !childs[0]) && !documents.mainVersionId && (
          <div
            style={{
              width: "100%",
              height: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "150ms",
              zIndex: 10,
              paddingLeft: 13 + Number(dynamicMargin) + 'px',
              paddingRight: '3px',
              boxSizing: 'border-box',
            }}
            id={"middle"}
            onDragStart={this.onDragStart}
            onDragOver={this.onDragOver}
            onDragEnd={this.onDragEnd}
            onDragLeave={this.onDragLeave}
            parentattachment={documents.parentAttachment}
            draggable={false}
            index={index + 1}
          // order={order}
          >
            <div
              style={{
                height: "80%",
                width: "100%",
                backgroundColor: "#FFCA00",
                display: "none",
                pointerEvents: "none"
              }}
              ref="line"
            ></div>
          </div>
        )}

        {/* for mapping childs of the folder */}
        {
          isFolderOpend && childs.map(
            (child, index) =>
              <DocumentListItem
                key={index}
                index={index}
                documents={child}
                children={childs}
                allDocuments={allDocuments}
                handleDeleteModal={handleDeleteModal}
                handleEdit={this.handleEdit}
                classes={classes}
                dynamicMargin={`${Number(dynamicMargin) + 15}`}
                dynamicRowColor={`${Number(dynamicRowColor) - 3}`}
                dispatch={dispatch}
                projectId={projectId}
                name={documents.name}
                attachmentNote={documents.attachmentNote}
                role={role}
                onDragStart={this.onDragStart}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                handleOnDragStart={this.props.handleOnDragStart}
                hanldeOnDragOver={this.props.hanldeOnDragOver}
                handleOnDragEnd={this.props.handleOnDragEnd}
                previousItem={previousItem}
                id={documents._id}
              />)
        }
      </>
    );
  }
}

const Styles = {
  rowWrapper: {
    borderRadius: '3px',
    // margin: '5px 0',

  },
  row: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '10px',
    boxSizing: 'border-box',
    transition: '150ms',
    '& > div': {
      padding: '6px 10px',
      backgroundColor: 'white',
      border: `1px solid #e3e3e3`,
      fontSize: '14px',
      margin: '0 3px',
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
  },
  nameTd: {
    width: '30%',
    justifyContent: 'flex-start',
    alignItems: 'inherit!important',
    backgroundColor: 'transparent!important',
    padding: '0!important',
    border: 'none!important',
    marginRight: '0!important',
    '& > div': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: 'white',
      border: `1px solid #e3e3e3`,
      borderRight: 'none',
      width: '100%',
      padding: '6px 10px',
      borderRadius: '3px',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0'
    },
    '& > div > span:nth-child(2)': {
      marginLeft: '5px',
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      WebkitHyphens: 'auto',
      MsHyphens: 'auto',
      hyphens: 'auto',
    }
  },
  noteAttachmentTd: {
    flex: 7,
    color: Colors.grey,
    marginLeft: '0!important',
    borderLeft: 'none!important',
    borderTopLeftRadius: '0!important',
    borderBottomLeftRadius: '0!important',
  },
  dateTd: {
    width: '90px',
    justifyContent: 'center'
  },
  actionsTd: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '170px',
  },
  input: {
    width: '100%'
  },
  textarea: {
    width: '100%',
    height: 'auto'
  }
};

const mapStateToProps = state => ({
  role: state.projects.project ? state.projects.project.role : undefined,
  projectId: state.projects.project ? state.projects.project._id : undefined
});

export default connect(mapStateToProps)(withStyles(Styles)(DocumentListItem));
