import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
//############################################################################
// styles
//############################################################################
const styles = theme => ({
  selectField: {
    position: "relative",
    zIndex: "3",
    display: "flex",
    marginTop: "24px",
    flexDirection: "row-reverse",
    alignItems: "center",
    height: "45px",
    borderRadius: "22px",
    backgroundColor: "#ffffff",
    border: "2px solid #ffffff"
  },
  fileSelectField: {
    marginTop: "20px",
    position: "relative",
    zIndex: "3",
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    height: "45px",
    borderRadius: "22px",
    backgroundColor: "#ffffff",
    border: "2px solid #ffffff"
  },
  directionRTL: {
    direction: "ltr"
  },
  input: {
    position: "relative",
    zIndex: "2",
    borderRadius: "0 22px 22px 0",
    fontSize: "14px",
    border: "none",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    color: theme.palette.text.secondary,
    transition: theme.colorTransition,
    padding: "0 5px",
    backgroundColor: "#00000000",
    "&:focus + div": {
      padding: "4px 10px 0 10px"
    },
    "&:focus": {
      outline: "none"
    },
    "&::placeholder": {
      color: "#c6c6c6"
    },
    "&[type=date]::-webkit-inner-spin-button": {
      '-webkit-appearance': 'none',
      display: 'none',
  } 
  },
  fileInput: {
    position: "relative",
    borderRadius: "0 22px 22px 0",
    opacity: "0",
    zIndex: "2",
    fontSize: "14px",
    border: "none",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    color: theme.palette.text.primary,
    transition: theme.colorTransition,
    padding: "5px",
    backgroundColor: "#ffffff00",
    "&:focus + div": {
      padding: "4px 10px 0 10px"
    },
    "&:focus": {
      outline: "none"
    },
    "&::placeholder": {
      color: "#c6c6c6"
    },
    "& + *": {
      opacity: "1"
    }
  },
  icon: {
    zIndex: "2",
    padding: "4px 15px 0 15px",
    float: "left",
    color: theme.palette.text.primary,
    transition: "padding 150ms, " + theme.colorTransition,
    fontSize: "16px"
  },
  label: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    zIndex: "1",
    height: "43px",
    border: "1px",
    borderRadius: "22px",
    color: theme.palette.text.secondary,
    display: "flex",
    fontSize: "13px",
    justifyContent: "start",
    alignItems: "center",
    marginLeft: "50px",
    paddingBottom: "3px"
  },
  FileIcon: {
    color: theme.palette.text.primary,
    transition: "padding 150ms",
    padding: "4px 15px 0 15px",
    zIndex: "2",
    float: "left",
    fontSize: "16px"
  },
  inputTitleRoot: {
    position: "absolute",
    zIndex: "-1",
    left: "17px",
    color: theme.palette.text.secondary,
    transition: "top 300ms",
    margin: "0px",
    fontSize: "14px",
    fontWeight: "500",
    transitionTimingFunction: "cubic-bezier(0.98,-0.6,0,1.6)"
  },
  inputTitle: {
    top: "-22px"
  },
  inputTitleHide: {
    top: "12px"
  },
  background: {
    borderRadius: "22px",
    width: "100%",
    height: "100%",
    backgroundColor: '#fff',
    transition: theme.colorTransition,
    position: "absolute",
    zIndex: "1"
  }
});
//############################################################################
// component
//############################################################################
class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      value: ""
    };
    if (props.value) {
      this.state.focus = true;
    }
  }
  handleChange = e => {
    const { value } = e.target;
    const { onChange } = this.props;
    this.setState({ value: value });

    if (onChange) onChange(e);
    else return;
  };
  handleFocus = () => {
    this.setState({ focus: true });
  };
  handleBlur = () => {
    this.setState({ focus: false });
  };
  render() {
    const {
      classes,
      value,
      defaultValue,
      placeholder,
      className,
      name
    } = this.props;
    const {
      required,
      max,
      min,
      autoComplete,
      disabled,
      htmlFor,
      type,
      pattern
    } = this.props;
    if (type === "file") {
      return (
        <div
          className={`${classes.fileSelectField} ${
            classes.directionRTL
          } ${className}`}
        >
          <div className={classes.background} />
          <label
            className={
              this.state.value
                ? `${classes.inputTitle} ${classes.inputTitleRoot}`
                : `${classes.inputTitleHide} ${classes.inputTitleRoot}`
            }
          >
            {placeholder}
          </label>
          <input
            onClick={this.handleFocus}
            onBlur={this.handleBlur}
            htmlFor={htmlFor}
            value={value}
            disabled={disabled}
            autoComplete={autoComplete}
            required={required}
            type={type}
            name={name}
            max={max}
            min={min}
            onChange={this.handleChange}
            className={classes.fileInput}
          />
          <label className={`${classes.label}`}>
            <div>{this.state.value ? this.state.value : placeholder}</div>
          </label>
          <div className={`${classes.FileIcon}`}>
            {React.cloneElement(this.props.children, {
              style: { fontSize: "16px" },
              color: classes.icon.color
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`${classes.selectField} ${
            classes.directionRTL
          } ${className}`}
        >
          <div className={classes.background} />
          <label
            className={
              this.state.focus ||
              (value && value.length > 0) ||
              (defaultValue && defaultValue.length > 0) ||
              this.state.value.length > 0
                ? `${classes.inputTitle} ${classes.inputTitleRoot}`
                : `${classes.inputTitleHide} ${classes.inputTitleRoot}`
            }
          >
            {placeholder}
          </label>
          <input
            pattern={pattern}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            htmlFor={htmlFor}
            disabled={disabled}
            autoComplete={autoComplete}
            required={required}
            type={type}
            name={name}
            max={max}
            min={min}
            value={value}
            defaultValue={defaultValue}
            onChange={this.handleChange}
            className={classes.input}
            placeholder={this.state.focus ? "" : placeholder}
          />
          <div className={`${classes.icon}`}>
            {React.cloneElement(this.props.children, {
              style: { fontSize: "16px" },
              color: classes.icon.color
            })}
          </div>
        </div>
      );
    }
  }
}
//############################################################################
// export
//############################################################################
const TextFieldWithStyle = withStyles(styles)(TextField);
export { TextFieldWithStyle as TextField };
