import React from 'react'
import Button from '@material-ui/core/Button'

export default ({ handleAddSubProject, type }) => {
    return (
        <div style={{  height: '36px', display: 'flex' }}>
            {type !== 'Project' &&
                <Button
                    style={{
                        backgroundColor: '#83C27B', flex: 1, display: 'flex', justifyContent: 'center',
                        alignItems: 'center', color: 'white', borderRadius: 0 ,width: '100px'
                    }}
                    onClick={() => handleAddSubProject('Program')}>
                    Program
            </Button>
            }
            {type !== 'Client' &&
                <Button
                    style={{
                        backgroundColor: '#0BBBB8', flex: 1, display: 'flex', justifyContent: 'center',
                        alignItems: 'center', color: 'white', borderRadius: 0,width: '100px'
                    }}
                    onClick={() => handleAddSubProject('Project')}>
                    Project
                </Button>
            }
        </div >
    )
}