import React from 'react'
import { withStyles } from '@material-ui/core'
import Folder from "@material-ui/icons/Folder";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../styles/colors'

const styles = {
    iconButton: {
        padding: '3px !important',
        color: Colors.lightGreen,
        position: 'relative'
    },
    folderIcon: {
        fontSize: '31px'
    },
    addRemoveIcon: {
        position: 'absolute',
        borderRadius: '100%',
        backgroundColor: Colors.lightGreen,
        border: '1px solid white',
        color: 'white',
        fontSize: '13px',
        top: '18px',
        right: '5px'
    }
}

export const FolderAndPlus = React.memo(withStyles(styles)(({ classes, onClick, hasChild, isFolderOpend }) => (
    <Tooltip title={hasChild ? isFolderOpend ? "Close" : "Open" : 'Empty'}>
        <IconButton onClick={onClick} className={classes.iconButton} component="span">
            <Folder className={classes.folderIcon} />
            {hasChild ? isFolderOpend ? <Remove className={classes.addRemoveIcon} /> : <Add className={classes.addRemoveIcon} /> : null}
        </IconButton>
    </Tooltip>
))
)
