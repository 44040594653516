import React from "react";

// #####[ material ui ]##############################################################
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// #####[ redux ]##############################################################
import { connect } from "react-redux";
// #####[ reusable functions ]##############################################################
import pastTimeSoFar from "../reusebleFunctions/pastTimeSoFar";
// #####[ styles ]##############################################################
import { Colors } from "../../styles";

// #####[ components ]##############################################################
import AvaterImage from "../img/noteTabAvatar.png";
// import { EditIcon, DeleteIcon } from '../Icons'
import AddNewComment from "./AddNewComment";
import { CommentModalTabItemReplay } from "./CommentModalTabItemReplay";

class CommentModalTabItem extends React.PureComponent {
  state = {
    editMode: false,
    text: "",
    isAddNewComment: false
  };

  handleReplayClick = () => {
    this.setState(prevState => ({
      isAddNewComment: !prevState.isAddNewComment
    }));
    this.props.handleIsReplyActive && this.props.handleIsReplyActive();
  };

  handleCancelAddNewComment = () => {
    this.setState({
      isAddNewComment: false
    });
    this.props.handleIsReplyActive();
  };

  render() {
    const {
      classes,
      comment,
      clickedRowDataId,
      allComments
    } = this.props;
    const { editMode, text, isAddNewComment } = this.state;
    return (
      <>
        <Grid container className={classes.commentItemWrapper}>
          <Grid container style={{ paddingBottom: "5px" }}>
            <Grid className={classes.photoWrapper}>
              <img
                src={AvaterImage}
                alt="profileImage"
                className={classes.image}
              />
            </Grid>

            <Grid item xs className={classes.commentTextAndAttachmentWrapper}>
              {editMode ? (
                <textarea
                  rows={2}
                  className={classes.textarea}
                  value={text}
                  onChange={this.handleChange}
                  name="text"
                />
              ) : (
                <>
                  <p className={classes.name}>
                    {`${comment.owner.firstName} ${comment.owner.lastName}`}
                  </p>
                  <p className={classes.commentText}>{comment.text}</p>
                </>
              )}
            </Grid>
            <Grid item xs={2} className={classes.actionsAndTimeWrapper}>
              {editMode ? (
                <>
                  {/* <Grid item xs className={classes.actionEditWrapper}>
                  <SaveButton onClick={this.handleSaveNewComment} />
                  <CancelButton onClick={this.handleCancelChange} />
                </Grid> */}
                </>
              ) : (
                <Grid container className={classes.actionAndReplayWrapper}>
                  {/* <Grid xs={12} className={classes.actionWrapper}>
                      {role !== "Project-Client" && (
                        <>
                          <EditIcon onClick={this.handleEdit} />
                          <DeleteIcon onClick={this.handleDelete} />
                        </>
                      )}
                    </Grid> */}
                  <Grid item xs={12} className={classes.replayWrapper}>
                    <Button
                      onClick={this.handleReplayClick}
                      className={classes.replayButton}
                    >
                      Reply
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} className={classes.time}>
              {pastTimeSoFar(comment.createDate)}
            </Grid>
          </Grid>

          {/* {
            allComments.filter(com => com.parentComment === comment._id).map((comment => (
              <CommentModalTabItemReplay comment={comment} />
            )))
          } */}

          {isAddNewComment && (
            <AddNewComment
              handleCancelAddNewComment={this.handleCancelAddNewComment}
              isAddNewComment={isAddNewComment}
              clickedRowDataId={clickedRowDataId}
              parentComment={comment._id}
            />
          )}

          {!isAddNewComment &&
            allComments
              .filter(com => com.parentIssueTaskComment === comment._id)
              .map((com, index) => <CommentModalTabItemReplay comment={com} key={index} />)}

          {/* comments part */}
          {/* {isMaximizeForComments && comments[0] && (
          <Grid container>
            {comments
              .filter(
                comment => comment.note === note._id && !comment.parentComment
              )
              .map((comment, index) => (
                <CommentItem
                  key={index}
                  noteId={note._id}
                  comment={comment}
                  allComments={comments}
                  handleAddCommentButton={this.handleAddCommentButton}
                  dynamicMargin={30}
                  role={role}
                  projectId={projectId}
                />
              ))}
          </Grid>
        )} */}
        </Grid>
      </>
    );
  }
}

const styles = {
  commentItemWrapper: {
    backgroundColor: "white",
    color: Colors.grey
  },
  photoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "6px",
    width: "75px"
  },
  image: {
    width: "60px"
  },
  commentTextAndAttachmentWrapper: {
    // backgroundColor: 'blue',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingRight: "10px",
    marginTop: "5px",
    fontSize: "15px"
  },
  name: {
    color: Colors.darkBlue,
    fontWeight: "500",
    margin: "7px 5px 2PX",
    fontSize: "14px"
  },
  commentText: {
    margin: "2PX 5px 10px",
    fontSize: "13px"
  },
  attachmentWrapper: {
    margin: "10px 0",
    overflow: "hidden"
  },
  actionsAndTimeWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    padding: '10px'
  },
  iconWrapper: {
    padding: "3px!important"
  },
  infoIconWrapper: {
    color: Colors.yellow
  },
  time: {
    color: '#9595a1',
    fontSize: "11px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingRight: "10px",
    marginTop: "7px"
  },
  textarea: {
    width: "99%",
    border: `1px solid ${Colors.lightGrey}`,
    color: Colors.grey,
    fontSize: "14px",
    marginLeft: "5px",
    height: "auto"
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start"
  },
  actionEditWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "13px"
  },
  cancelButton: {
    fontWeight: 900,
    fontSize: "10px",
    height: "38px",
    width: "70px",
    // box-sizing: border-box;
    borderRadius: "5px",
    backgroundColor: "#AFADAD",
    color: "#ffffff",
    display: "inline-block",
    border: "none",
    cursor: "pointer",
    margin: "2px"
  },
  commentButton: {
    color: Colors.grey,
    borderRadius: "15px",
    border: `1px solid ${Colors.darkenThanLightGrey}`,
    fontSize: "11px",
    padding: "3px 8px!important"
  },
  actionAndReplayWrapper: {},
  replayWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: " 7px"
  },
  replayButton: {
    fontSize: "12px",
    color: Colors.darkBlue,
    padding: 0
  }
};

const mapStateToProps = state => {
  return {
    comments: state.comments,
    role: state.projects.project ? state.projects.project.role : undefined,
    projectId: state.projects.project ? state.projects.project._id : undefined
  };
};

const tempTemp = connect(mapStateToProps)(
  withStyles(styles)(CommentModalTabItem)
);

export { tempTemp as CommentModalTabItem };
