import React from "react";
import { withStyles } from "@material-ui/core";

//#############################################################################
// React Input Component
//#############################################################################
class ReactInputDropdown extends React.PureComponent {
  //###########################################################################
  // this function will handle the change in the input
  handleChange = event => {
    if (this.props.onChange) this.props.onChange(event);
  };

  //###########################################################################
  render() {
    const { name, type, min, max, accept, required, disabled, value, htmlFor, placeholder } = this.props;
    const { className } = this.props;
    return (
        <input

          style={{paddingRight: '35px'}}
          onClick={this.props.handleOnClick}
          className={`react-input-style ${className} `}
          onFocus={this.props.handleFocus}
          onBlur={this.props.handleBlur}
          onChange={this.handleChange}
          htmlFor={htmlFor}
          value={value}
          disabled={disabled}
          autoComplete={'off'}
          required={required}
          type={type}
          name={name}
          max={max}
          min={min}
          accept={accept}
          placeholder={placeholder}
          list="architects"
          onKeyDown={ this.props.onKeyDown }
        />
    );
  }
}

//#############################################################################
// Styles
//#############################################################################

var styles = {
  //.react-input-style
      reactInputStyleClass: {
              backgroundColor: "hsl(0, 0%, 100%)",
              boxSizing: "border-box",
              borderColor: "hsl(0, 0%, 80%)",
              borderRadius: "4px",
              borderStyle: "solid",
              borderWidth: "1px",
              height: "38px",
              fontSize: "15px",
              padding: "0px 10px",
              transition: "border-color 200ms, box-shadow 200ms"
      },
  //.react-input-style:hover
      reactInputStyleHoverClass: {
              borderColor: "hsl(0, 0%, 70%)"
      },
  //.react-input-style:focus
      reactInputStyleFocusClass: {
              borderColor: "hsl(214, 100%, 57%)",
              boxShadow: "0px 0px 0px 1px hsl(214, 100%, 57%)"
      },
      input:{
        // paddingRight: '20px!important'
      }
  }

//#############################################################################
// export
//#############################################################################

const ReactInputDropdownWithStyles = withStyles(styles)(ReactInputDropdown);

export { ReactInputDropdownWithStyles as ReactInputDropdown }
