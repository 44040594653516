import React from "react";
import { Link } from "react-router-dom";
// #####[ material ui ]##############################################################
import { withStyles } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

// #####[ colors ]##############################################################
import { Colors } from "../../styles";

// #####[ reusebleFunction ]##############################################################
import delayTime from "../reusebleFunctions/delayTime";
import addingCommaToCost from "../reusebleFunctions/addingCommaToCost";
import ThemeContext from "../ContextApi/Context";

class Card extends React.PureComponent {
  static contextType = ThemeContext;
  state = {
    isCardExpanded: false,
    isCardExpandingArrowShows: false,
    elementsHeight: 0
  };

  componentDidMount() {
    if (this.refs.tbody)
      this.setState({
        isCardExpandingArrowShows:
          this.refs.tbody.offsetHeight > 270 ? true : false
      });
  }

  componentDidUpdate() {
    if (this.refs.tbody)
      this.setState({
        isCardExpandingArrowShows:
          this.refs.tbody.offsetHeight > 270 ? true : false
      });
  }

  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  dataSepration = (data = []) => {
    if (data[0])
      switch (this.props.hasTitle) {
        case "Issue information":
          return data
            .filter(val => val.issue === true && val.status === "open")
            .map(val => {
              const date = new Date(val.dueDate);
              return {
                "Issue Name": val.name,
                "Due Date": `${date.getDay()} ${
                  this.monthNames[date.getMonth()]
                } ${date.getFullYear()}`,
                "Delay Time": delayTime(date),
                Owner: val.assignedTo.firstName + " " + val.assignedTo.lastName
              };
            });
        case "Project Costs":
          return data
            .filter(val => val.parentCost === null)
            .map(val => {
              return {
                "Cost Name": val.name,
                Budget: val.budget,
                Revised: val.revised,
                Actual: val.actual,
                Status: val.approvalStatus
              };
            });
        case "Contract list":
          return [];
        default:
          return [];
      }
    else return [];
  };

  handleToggleExpandCard = () => {
    this.setState(prevState => ({
      isCardExpanded: !prevState.isCardExpanded
    }));
  };

  handleCalculateItems = e => {
    this.setState(prevState => ({
      elmentsHeight: prevState.elementsHeight + e.offsetHeight
    }));
  };

  render() {
    const { isCardExpanded, isCardExpandingArrowShows } = this.state;
    const { classes, hasTitle, data = [], role } = this.props;
    const projectId = this.context;
    return (
      <React.Fragment>
        <div className={classes.card}>
          <div
            className={classes.titleWrapper}
            style={{ visibility: hasTitle ? "none" : "hidden" }}
          >
            <Link
              to={`/${
                role === "adminFlag" ? "admin" : "user"
              }-dashboard/projects/${projectId}/project-costs`}
              className={classes.title}
            >
              {hasTitle}
            </Link>
          </div>
          {!this.dataSepration(data)[0] ? (
            <section className={classes.noData}>No Data</section>
          ) : (
            <React.Fragment>
              <section
                className={classes.section}
                style={
                  isCardExpanded
                    ? { height: this.refs.tbody.offsetHeight + 30 + "px" }
                    : null
                }
              >
                <table className={classes.table}>
                  <thead>
                    <tr className={classes.header}>
                      {Object.keys(this.dataSepration(data)[0]).map(
                        (cell, index) => (
                          <th
                            key={index}
                            className={`${classes.headerCell} 
                            ${index === 0 ? classes.nameColumn : null}
                            ${
                              index ===
                                Object.keys(this.dataSepration(data)[0])
                                  .length -
                                  1 && cell === "Status"
                                ? classes.statusColumn
                                : null
                            }
                          `}
                          >
                            <div>{cell}</div>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody ref="tbody">
                    {this.dataSepration(data).map((rowData, index) => (
                      <tr key={index} className={classes.row}>
                        {Object.values(rowData).map((cell, index) => (
                          <td
                            key={index}
                            className={`
                            ${classes.bodyCell} 
                            ${index === 0 ? classes.nameColumn : null} 
                          `}
                          >
                            <div
                              className={
                                cell === "approved"
                                  ? classes.approved
                                  : cell === "denied"
                                  ? classes.denied
                                  : cell === "needs review"
                                  ? classes.needReview
                                  : classes.normal
                              }
                            >
                              <span />
                              {/* for add $ to cost values */}
                              {this.props.hasTitle === "Project Costs" &&
                              (index === 1 || index === 2 || index === 3)
                                ? `${addingCommaToCost(cell)}`
                                : cell}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>

              <section
                className={classes.expandCardArrowSection}
                onClick={this.handleToggleExpandCard}
                style={
                  isCardExpandingArrowShows
                    ? { visibility: "inherit" }
                    : { visibility: "hidden" }
                }
              >
                <KeyboardArrowDown
                  className={`${classes.expandCardArrowSectionIcon} ${
                    isCardExpanded ? classes.expandCardArrowSectionUpIcon : null
                  }`}
                />
              </section>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  card: {
    fontSize: "13px",
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  titleWrapper: {},
  title: {
    backgroundColor: "white",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    color: Colors.grey,
    padding: "5px 15px 10px",
    fontWeight: "500",
    fontSize: "17px",
    textDecoration: "none!important",
    "&:hover": {
      color: "#aaaab5"
    }
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    color: "#00000036",
    fontWeight: "100!important",
    backgroundColor: "white",
    padding: "10px 15px 3px",
    borderRadius: "5px",
    marginTop: "0",
    // flexGrow: 100,
    marginBottom: "25px",
    height: "290px"
  },
  section: {
    backgroundColor: "white",
    padding: "10px 15px 3px",
    borderRadius: "5px 5px 0px 0px",
    marginTop: "0",
    height: "290px",
    overflow: "hidden",
    transition: "300ms"
  },
  table: {
    borderCollapse: "collapse",
    display: "flex",
    flexDirection: "column",
    "& tbody tr:not(:last-child)": {
      borderBottom: `1px solid ${Colors.lightGrey}`
    }
  },
  header: {
    borderBottom: `2px solid ${Colors.darkBlue}`,
    display: "flex",
    flexDirection: "row",
    flex: 1
    // marginBottom: "10px"
  },
  headerCell: {
    padding: "3px 0",
    textAlign: "center",
    color: Colors.darkBlue,
    fontWeight: "500",
    flex: 2
  },
  headerCellName: {
    flex: "2!important"
  },
  row: {
    display: "flex",
    flexDirection: "row"
    // cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: Colors.lightBlue
    // }
  },
  bodyCell: {
    flex: 2,
    "& div": {
      // height: "27px",
      fontWeight: "400",
      padding: "7px 3px",
      margin: "2px 2px",
      textAlign: "center",
      "& span": {
        display: "none",
        width: "7px",
        height: "7px",
        margin: "5px 10px 0 0",
        borderRadius: "100em"
      }
    }
  },
  nameColumn: {
    flex: "3!important",
    "& div": {
      textAlign: "left!important"
    }
  },
  statusColumn: {
    "& div": {
      textAlign: "left!important"
    }
  },
  approved: {
    color: Colors.lightGreen,
    fontWeight: "500",
    textAlign: "left!important",
    "& span": {
      display: "inline-block!important",
      backgroundColor: Colors.lightGreen
    }
  },
  denied: {
    color: "red",
    fontWeight: "500",
    textAlign: "left!important",
    "& span": {
      display: "inline-block!important",
      backgroundColor: "red"
    }
  },
  needReview: {
    color: Colors.camouflageGreen,
    fontWeight: "500",
    textAlign: "left!important",
    "& span": {
      display: "inline-block!important",
      backgroundColor: Colors.camouflageGreen
    }
  },
  normal: {
    color: Colors.grey,
    // border: `1px solid #bebec6`,
    borderRadius: "5px",
    flex: "10!important",
    textAlign: "center"
  },
  expandCardArrowSection: {
    backgroundColor: "#E0E1E6",
    // height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px"
  },
  expandCardArrowSectionIcon: {
    color: Colors.darkBlue,
    transition: "200ms"
  },
  expandCardArrowSectionUpIcon: {
    transform: "rotate(180deg)"
  }
};

const CardWithStyles = withStyles(styles)(Card);
export { CardWithStyles as Card };
