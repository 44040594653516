import React from 'react';
import {Button} from "../button/button"
import { withStyles } from "@material-ui/core"
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


// #############################################################################
// component
// #############################################################################

function FileOptions(props){
  const { classes, handleToggle, open = false, width = 0, handleSaveFile, position="bottom" } = props;
  if(open)
    return (
      <ClickAwayListener onClickAway={() => {handleToggle("bottom")}}>
        <div style={ position === "bottom" ? {bottom: 45} : {top: 45} } className={classes.root}>
          <Button className={classes.buttons} variant="info" onClick={handleSaveFile(width,"JPG")}>SAVE AS JPG</Button>
          <Button className={classes.buttons} variant="success" onClick={handleSaveFile(width,"PDF")}>SAVE AS PDF</Button>
          <Button className={classes.buttons} variant="danger" onClick={handleSaveFile(width,"PNG")}>SAVE AS PNG</Button>
        </div>
      </ClickAwayListener>
    );
  else return null
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    position: "absolute",
    zIndex: 1200,
    right: 10,
    background: "white",
    width: 150,
    padding: 3,
    borderRadius: 4,
    boxShadow: "0 2px 5px 0px #00000024, 0 2px 2px -2px #000000bd"
  },
  buttons:{
    width: "calc(100% - 6px)",
    margin: 3,
  }
});

// #############################################################################
// connect material styles and redux state to component
// #############################################################################
const FileOptionsWithStyles = withStyles(styles)(FileOptions)

// #############################################################################
// export
// #############################################################################

export  { FileOptionsWithStyles as FileOptions };