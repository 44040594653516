import React from "react";
import { history } from "../../helpers";
import CalendarIcon from "./img/calendar.svg";
import { Link } from "react-router-dom";
import { API } from "../../constants";
// #####[ MATERIAL COMPONENTS ]#################################################
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Copyright from "@material-ui/icons/Copyright";

// #####[ MATERIAL ICON ]#######################################################
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import Info from "@material-ui/icons/Info";
// #############################################################################
// component
// #############################################################################
import {
  FileCopyIcon,
  AddCircleOutlinedIcon,
  AddCircleFilledIcon,
  PlusFilledIcon,
  MinusFilledIcon
} from "../Icons";
import ProjectAndProgramModal from "./ProjectAndProgramModal.jsx";

// #####[ Styles ]#######################################################
import { Colors } from "../../styles/colors";

class ListItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.subProjects = [];
    this.state = {
      openSub: false,
      dragChild: false,
      isOnDrag: false,
      currentTarget: {},
      prevTarget: null,
      useTooltip: false,
      isProjectAndProgramModalShowed: false,
      openTooltip: false
    };
  }

  // ###########################################################################
  //
  // ###########################################################################

  componentDidMount() {
    this.setState({
      allprojects: this.props.allProjects
    });
    this.isEllipsisActive(this.refs.projectName);
  }

  componentDidUpdate(prevProps) {
    this.props.allProjects !== prevProps.allProjects &&
      this.setState({
        allprojects: this.props.allProjects
      });
  }

  // ###########################################################################
  //
  // ###########################################################################

  isEllipsisActive = e => {
    this.setState({
      useTooltip: e.offsetWidth < e.scrollWidth
    });
  };

  // static getDerivedStateFromProps(props, state) {
  //   const { allprojects, data } = props;
  //   const { allProjectsProps } = state;

  //   if (allProjectsProps !== allprojects) {
  //     const allSubProjects = allprojects.filter(project => {
  //       if (!project.parentProject) {
  //         return false;
  //       } else {
  //         return project.parentProject === data._id;
  //       }
  //     });

  //     return {
  //       allProjectsProps: allprojects,
  //       allProjects: allprojects,
  //       allSubProjects
  //     };
  //   } else return null;
  // }

  // ###########################################################################
  //
  // ###########################################################################

  getSubProjects = (allprojects = [], projectId) => {
    return allprojects.filter(project => project.parentProject === projectId);
  };

  // ###########################################################################
  //
  // ###########################################################################

  toggleSubHandler = () => {
    this.setState(prevState => ({ openSub: !prevState.openSub }));
  };

  // ###########################################################################
  //
  // ###########################################################################

  findMyRoute = (project, allprojects) => {
    if (project || project.parentProject === null) {
      return "";
    }
    const parent = allprojects.find(p => p._id === project.parentProject);
    return this.findMyRoute(parent, allprojects) + parent.name + " / ";
  };

  // ###########################################################################
  //
  // ###########################################################################

  dateFromatter = dateString => {
    const date = new Date(dateString);
    return (
      date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()
    );
  };

  // ###########################################################################
  //
  // ###########################################################################

  onDragChild = () => {
    this.setState({
      dragChild: true,
      isOnDrag: true
    });
  };

  // ###########################################################################
  //
  // ###########################################################################

  DoLinkHandler = e => {
    if (this.state.isOnDrag) {
      e.preventDefault();
    } else {
      history.push(this.props.link + this.props.data._id);
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleProjectStatusColor = status => {
    switch (status) {
      case "NOT STARTED":
        return (
          <div className="item-status-code" style={{ color: "gray" }}>
            {status}
          </div>
        );
      case "CANCELED":
        return (
          <div className="item-status-code" style={{ color: "#e64e00" }}>
            {status}
          </div>
        );
      case "FINISHED":
        return (
          <div className="item-status-code" style={{ color: "#00de67" }}>
            {status}
          </div>
        );
      case "OPEN":
        return (
          <div className="item-status-code" style={{ color: "#0cbbd0" }}>
            {status}
          </div>
        );
      case "ON HOLD":
        return (
          <div className="item-status-code" style={{ color: "orange" }}>
            {status}
          </div>
        );
      default:
        return;
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  onDragStart = ({ currentTarget, dataTransfer, target }) => {
    const { handleOnDragStart } = this.props;
    handleOnDragStart(currentTarget);
    dataTransfer.setData("text/html", currentTarget.id);
    this.draggedItem = currentTarget;
    currentTarget.style.opacity = "0.4";
  };

  // ###########################################################################
  //
  // ###########################################################################

  onDragOver = ({ currentTarget }) => {
    if (
      this.props.previousItem &&
      this.props.previousItem.id !== currentTarget.id
    ) {
      this.props.hanldeOnDragOver(currentTarget);
      // this.setState({ currentTarget: {} });

      if (currentTarget.id === "middle") {
        this.refs.line.style.display = "inline";
        currentTarget.style.height = "25px";
      } else if (currentTarget.id === "middleTop") {
        this.refs.lineTop.style.display = "inline";
        currentTarget.style.height = "25px";
      } else {
        currentTarget.style.backgroundColor = "#fff4c9";
      }
    } else {
      this.props.hanldeOnDragOver(currentTarget);
    }
  };

  onDragLeave = ({ currentTarget }) => {
    if (
      this.props.previousItem &&
      this.props.previousItem.id !== currentTarget.id
    ) {
      if (currentTarget.id === "middle") {
        this.refs.line.style.display = "none";
        currentTarget.style.height = "10px";
      } else if (currentTarget.id === "middleTop") {
        this.refs.lineTop.style.display = "none";
        currentTarget.style.height = "10px";
      } else {
        currentTarget.style.backgroundColor = "white";
      }
    }
  };

  // ###########################################################################
  //
  // ###########################################################################

  onDragEnd = e => {
    e.preventDefault();
    this.draggedItem.style.opacity = "1";

    ///for closeing tooltip if its open //////////////
    this.setState({
      openTooltip: false
    });

    const { handleOnDragEnd } = this.props;
    handleOnDragEnd(e.currentTarget.id);
    e.target.style.opacity = "1";
  };

  handleOpenProjectAndProgramModal = () => {
    this.setState({ isProjectAndProgramModalShowed: true });
  };

  handleAddSubProject = type => {
    this.props.handleAddSubProject({ parentProject: this.props.data, type });
    this.setState({ isProjectAndProgramModalShowed: false });
  };

  handleClickAway = () => {
    this.setState({ isProjectAndProgramModalShowed: false });
  };

  ownedProjectCounter = (allprojects, parentId, array = []) => {
    allprojects.forEach(pro => {
      pro.parentProject === parentId &&
        array.push(pro) &&
        this.ownedProjectCounter(allprojects, pro._id, array);
    });
    return array;
  };

  handleTooltipMouseOver = bool => () => {
    this.setState({
      openTooltip: bool
    });
  };

  // ###########################################################################
  // Render
  // ###########################################################################

  render() {
    const {
      classes,
      data,
      allprojects,
      adminRole,
      openIssueList,
      openAddProject,
      id,
      index,
      forcingtorerender,
      previousItem,
      handleCopyProject,
      projectsStatusForFiltering,
      filter,
      dynamicMargin
    } = this.props;
    const {
      openSub,
      useTooltip,
      isProjectAndProgramModalShowed,
      openTooltip
    } = this.state;
    const ownedProjects = data.email
      ? allprojects.filter(project => project.client === data._id)
      : this.ownedProjectCounter(allprojects, data._id);

    const subProjects = data.email
      ? ownedProjects
          .filter(project => {
            return !ownedProjects.some(
              pro => project.parentProject === pro._id
            );
          })
          // .sort(a => (a.isProgram ? -1 : 1))
      : ownedProjects.filter(project => {
          return project.parentProject === data._id;
        });

    let numberofLeafs = [];
    let ownedIssueTasks = !data.email && data.issueTask[0] ? [data] : [];
    ownedProjects.forEach(project => {
      !project.email &&
        !project.isProgram &&
        !ownedProjects.some(proj => proj.parentProject === project._id) &&
        numberofLeafs.push(project);

      !data.email && project.issueTask[0] && ownedIssueTasks.push(project);
    });

    let numberofIssueTasks = data.email
      ? undefined
      : ownedIssueTasks.reduce((repo, item) => repo + item.issueTask.length, 0);
    const type = data.email ? "Client" : data.isProgram ? "Program" : "Project";

    return (
      <>
        {index === 0 && (
          <div
            style={{
              width: "100%",
              height: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "149ms",
              zIndex: 10
            }}
            id={"middleTop"}
            onDragStart={this.onDragStart}
            onDragOver={this.onDragOver}
            onDragEnd={this.onDragEnd}
            onDragLeave={this.onDragLeave}
            parentproject={data.parentProject}
            draggable={false}
            index={0}
            type={type}
            client={data.client}
          >
            <div
              style={{
                height: "80%",
                width: "100%",
                backgroundColor: "#FFCA00",
                display: "none",
                pointerEvents: "none"
              }}
              ref="lineTop"
            ></div>
          </div>
        )}
        <div
          className={
            "mpllist-item" +
            (subProjects && subProjects.length > 0 ? " clickable" : "")
          }
          // draggable={adminRole}
          onDragStart={this.onDragStart}
          onDragOver={this.onDragOver}
          onDragEnd={this.onDragEnd}
          onDragLeave={this.onDragLeave}
          id={id}
          parentproject={data.parentProject}
          draggable={adminRole && !projectsStatusForFiltering[0] && !filter}
          index={index}
          forcingtorerender={forcingtorerender}
          ref="project"
          type={type}
          style={
            data.email
              ? {
                  borderTop: "2px solid #44C494",
                  borderLeft: "2px solid #44C494",
                  borderRadius: 0
                }
              : !data.parentProject
              ? { marginLeft: 0 }
              : { marginLeft: `${dynamicMargin}px` }
          }
          client={data.client}
        >
          {/*########################   red item  ############################*/}

          {/* {!!numberOfIssues && <Grid className="item-red-label" id={id}></Grid>} */}
          {data.isProgram === false && (
            <Grid className={classes.projectItem} id={id}></Grid>
          )}

          {data.isProgram === true && (
            <Grid className={classes.programItem} id={id}></Grid>
          )}
          <div className={classes.logoHolder}>
            {!data.email ? null : data.image ? (
              <img
                className={classes.logoImage}
                src={`${API}/${data.image}`}
                alt="Client-Logo"
              />
            ) : (
              <Copyright className={classes.unknownLogo} />
            )}
          </div>

          {/*########################   arrow icon   ################################*/}

          <div
            className={classes.arrowIconWrapper}
            onClick={this.toggleSubHandler}
          >
            {subProjects[0] ? (
              openSub ? (
                <MinusFilledIcon
                  color={
                    data.email
                      ? "#44C494"
                      : data.isProgram
                      ? "#7ED6E3"
                      : "#014F96"
                  }
                />
              ) : (
                <PlusFilledIcon
                  color={
                    data.email
                      ? "#44C494"
                      : data.isProgram
                      ? "#7ED6E3"
                      : "#014F96"
                  }
                />
              )
            ) : null}
          </div>
          {/*###################### project name  #############################*/}

          <Tooltip
            title={data.name}
            placement="bottom-start"
            disableHoverListener={!useTooltip}
            onMouseEnter={this.handleTooltipMouseOver(true)}
            onMouseLeave={this.handleTooltipMouseOver(false)}
            open={openTooltip}
          >
            {data.client ? (
              <Link
                className={classes.projectNameWrapper}
                // onClick={data.email ? () => {} : this.handlePushToProjectDetails}
                to={`/${
                  adminRole ? "admin-dashboard" : "user-dashboard"
                }/projects/${data._id}`}
                ref="projectName"
              >
                <span
                  className="item-name"
                  id={id}
                  parentproject={data.parentProject}
                >
                  {data.name}
                </span>
              </Link>
            ) : (
              <div
                className={classes.projectNameWrapper}
                // onClick={data.email ? () => {} : this.handlePushToProjectDetails}
                ref="projectName"
              >
                <span
                  className="item-name"
                  id={id}
                  parentproject={data.parentProject}
                >
                  {data.name}
                </span>
              </div>
            )}
          </Tooltip>

          {/*######################## issue number  ###########################*/}
          {numberofIssueTasks > 0 && (
            <div className="item-issues-button-holder">
              <IconButton
                onClick={e =>
                  this.props.displaySelectedIssues(ownedIssueTasks, e)
                }
                className="item-issues"
              >
                <WarningRoundedIcon className="item-issues-icon" />
                <span className="item-issues-text">{`${numberofIssueTasks} Issues and Tasks`}</span>
              </IconButton>
            </div>
          )}

          {/*########################  total number of a project  ##########################*/}

          <div className={classes.totalNumberOfTheProjectsWrapper}>
            <Info className={classes.infoIcon} />
            {`Total Number Of Projects: ${numberofLeafs.length} `}
          </div>
          {/*#########################  project status  #########################*/}

          <div style={{ width: "84px" }}>
            {this.handleProjectStatusColor(data.status)}
          </div>

          {/*##########################  find my route  #######################*/}

          <div className="mpllist-item-route">
            {this.findMyRoute(data, allprojects)}
          </div>

          {/*#########################  role  ###########################*/}
          <Grid className="item-role">
            {data.role && !adminRole && data.role}
          </Grid>

          {/*#########################  date holder  ###########################*/}

          <div className="mpllist-item-date-hoder">
            <img
              className="calendar-icon"
              src={CalendarIcon}
              type="image/svg+xml"
              alt="item-icon"
            />
            <div className="item-date">
              {data.startDate && this.dateFromatter(data.startDate)}
            </div>
          </div>
          {/*#########################  copy and parent icons  ###########################*/}
          {adminRole && (
            <>
              <div className={classes.actionsWrapper}>
                {data.client ? (
                  <FileCopyIcon
                    className={classes.fileCopyIcon}
                    onClick={() => handleCopyProject(data._id)}
                  />
                ) : (
                  <div style={{ width: "30px" }} />
                )}
                {isProjectAndProgramModalShowed ? (
                  <AddCircleFilledIcon
                    className={classes.AddCircleOutlinedIcon}
                    onClick={this.handleOpenProjectAndProgramModal}
                  />
                ) : (
                  <AddCircleOutlinedIcon
                    className={classes.AddCircleOutlinedIcon}
                    onClick={this.handleOpenProjectAndProgramModal}
                  />
                )}

                {isProjectAndProgramModalShowed && (
                  <ClickAwayListener onClickAway={this.handleClickAway}>
                    <div className={classes.projectAndProgramModal}>
                      <ProjectAndProgramModal
                        handleAddSubProject={this.handleAddSubProject}
                        type={
                          data.email
                            ? "Client"
                            : data.isProgram
                            ? "Program"
                            : "Project"
                        }
                      />
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </>
          )}
        </div>

        {((subProjects[0] && !openSub) || !subProjects[0]) && (
          <div
            style={{
              width: "100%",
              height: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "150ms",
              zIndex: 10
            }}
            id={"middle"}
            onDragStart={this.onDragStart}
            onDragOver={this.onDragOver}
            onDragEnd={this.onDragEnd}
            onDragLeave={this.onDragLeave}
            parentproject={data.parentProject}
            draggable={false}
            index={index + 1}
            type={type}
            client={data.client}
          >
            <div
              style={{
                height: "80%",
                width: "100%",
                backgroundColor: "#FFCA00",
                display: "none",
                pointerEvents: "none"
              }}
              ref="line"
            ></div>
          </div>
        )}
        {openSub && subProjects[0] && (
          <div className="mpllist-sub-data">
            {subProjects.map((project, index) => (
              <ListItem
                classes={classes}
                openAddProject={openAddProject}
                openIssueList={openIssueList}
                displaySelectedIssues={this.props.displaySelectedIssues}
                key={project._id}
                id={project._id}
                data={project}
                parentproject={project.parentProject}
                index={index}
                allprojects={allprojects}
                link={this.props.link}
                onDragChild={this.onDragChild}
                adminRole={adminRole}
                dispatchChangeProjectOrder={
                  this.props.dispatchChangeProjectOrder
                }
                onDragStart={this.onDragStart}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                handleOnDragStart={this.props.handleOnDragStart}
                hanldeOnDragOver={this.props.hanldeOnDragOver}
                handleOnDragEnd={this.props.handleOnDragEnd}
                previousItem={previousItem}
                handleAddSubProject={this.props.handleAddSubProject}
                handleCopyProject={handleCopyProject}
                filter={this.props.filter}
                projectsStatusForFiltering={projectsStatusForFiltering}
                dynamicMargin={dynamicMargin + 20}
                handleOpenProjectAndProgramModal={
                  this.handleOpenProjectAndProgramModal
                }
              />
            ))}
          </div>
        )}
      </>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  arrowIconWrapper: {
    width: "45px",
    marginLeft: "10px"
  },
  iconButton: {
    padding: "2px"
  },
  expandeIcon: {
    margin: "0px 10px"
  },
  expandMoreIcon: {
    fontSize: "28px",
    transform: "rotate(-90deg)",
    transition: "transform 170ms",
    color: "#00d47f"
  },
  expandMoreIconDisabled: {
    transform: "rotate(-90deg)",
    color: "#e1e1e1"
  },
  expandOpen: {
    transform: "rotate(0deg)"
  },
  subContainer: {
    transition: "height 150ms",
    overflowY: "hidden"
  },
  dottedLineWrapper: {
    height: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "250ms"
  },
  dottedLine: {
    width: "98%",
    height: "0px",
    backgroundColor: "grey",
    borderColor: "gray",
    borderTop: "1px dotted",
    visibility: "hidden"
  },
  actionsWrapper: {
    display: "flex",
    padding: "0"
  },
  totalNumberOfTheProjectsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: Colors.blue,
    marginRight: "20px",
    marginLeft: "20px",
    fontSize: "12px",
    fontWeight: "400",
    width: "200px",
    minWidth: "200px"
  },
  infoIcon: {
    marginRight: "8px",
    marginTop: "2px",
    color: Colors.blue,
    fontSize: "20px"
  },
  projectNameWrapper: {
    height: "53px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 100,
    cursor: "pointer",
    textDecoration: "none!important",
    "text-overflow": "ellipsis",
    overflow: "hidden",
    "white-space": "nowrap"
  },
  projectItem: {
    backgroundColor: Colors.darkBlue,
    width: "4px",
    borderRadius: "2px 0px 0px 2px",
    position: "absolute",
    left: 0,
    top: 0,
    height: "54px"
  },
  programItem: {
    backgroundColor: "#76D6DF",
    borderRadius: "0px 40px 40px 0px ",
    position: "absolute",
    top: "2px",
    left: "-13px",
    width: "22px",
    height: "50px",
    "&::after": {
      width: "13px",
      height: "50px",
      backgroundColor: "#EFF0F5",
      position: "absolute",
      content: "''"
    }
  },
  logoHolder: {
    width: "50px",
    height: "40px",
    borderRadius: "4px",
    overflow: "hidden",
    marginLeft: "5px",
    minWidth: "30px"
  },
  logoImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
    height: "100%",
    width: "100%"
  },
  projectAndProgramModal: {
    position: "absolute",
    top: "-30px",
    zIndex: 10,
    right: "5px"
  },
  unknownLogo: {
    fontSize: "42px",
    color: "#80808A",
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
    height: "100%",
    width: "100%"
  }
});
const ListItemWithStyles = withStyles(styles)(ListItem);

// #############################################################################
// export
// #############################################################################

export { ListItemWithStyles as ListItem };
