import React from "react";
import { generateLine } from "../helpers"

// #############################################################################
// this component will gather all the lines together
// #############################################################################

export function Lines(props){
  const { tasks = {}, horizontalUnit = 13, verticalUnit = 28 , width = 0, height = 0 } = props;

  let lineArray = [];
  const keys = Object.keys(tasks);
  const filteredKeys = keys.filter(key => tasks[key].dependencies.length > 0)
  
  filteredKeys.forEach(key => {
    tasks[key].dependencies.forEach(item => {
      if(isItOnTheBoard(key, tasks) && isItOnTheBoard(item._id, tasks))
        lineArray.push(<Line id={`line`} key={ key + item._id} start={item._id} end={key} tasks={tasks} verticalUnit={verticalUnit} horizontalUnit={horizontalUnit} />)
    })
  });

  return (
    <svg style={{position: "absolute", zIndex: 0}} height={height + "px"} width={width + "px"}>

      <marker id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="6" markerHeight="6" orient="auto-start-reverse">
        <path d="M 0 0 L 10 5 L 0 10 z" fill="#1598c7" />
      </marker>

      <marker id="dot" viewBox="0 0 10 10" refX="1" refY="4" markerWidth="4" markerHeight="4">
        <circle cx="4" cy="4" r="0" fill="#1598c7" />
      </marker>

      {lineArray}
    </svg>
  );
}


// #############################################################################
// this component will render the line of the dependencies
// #############################################################################

function Line(props){
  const { start = "", end = "", tasks = {}, horizontalUnit = 13, verticalUnit = 28 } = props;

  const startX = parseInt(tasks[start].right, 10);
  const startY = parseInt(tasks[start].height + 22, 10);
  const endX = parseInt(tasks[end].left, 10);
  const endY = parseInt(tasks[end].height + 22, 10);
  const dots = [startX, startY, endX, endY];

  return ( <polyline id={`start:${start},end:${end}`} className="line" points={generateLine(dots, horizontalUnit, verticalUnit)} markerStart="url(#dot)" markerEnd="url(#arrow)" style={{ fill: "none", stroke: "#1598c7", strokeWidth: 1 }}  />);
}

// #############################################################################
// this function checks if the tasks are showing on the board
// #############################################################################

function isItOnTheBoard(taskId, tasks) {
  return tasks[taskId].parentTask === null || (tasks[taskId].parentTask !== null && tasks[tasks[taskId].parentTask].expand)
}
