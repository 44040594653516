import React from "react";

//////////////// material UI//////////////////////////
import { withStyles } from "@material-ui/core";

///////////////////////// components /////////////////////////////
import { List } from "../../CostList/List";
import { AttachmentUploadFile } from "../../Attachments/AttachmentUploadFile";
import AddCostInline from "../../CostList/AddCostInline";
import TotalCost from "../../CostList/TotalCost";
import ThemeContext from "../../ContextApi/Context";
import { filterActions } from "../../../actions";
import { connect } from "react-redux";

class AdminProjectCosts extends React.PureComponent {
  static contextType = ThemeContext;

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(filterActions.pageFilterEdit(""));
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.costs}>
        {/* //////////////////// Add Costs Inline /////////////////////////// */}
        <AddCostInline />

        {/* ////////////////////// Attachment ////////////////////// */}
        <AttachmentUploadFile />

        {/* //////////////////////// total cost ////////////////////// */}
        <TotalCost />

        {/* //////////////////////// cost List ////////////////////// */}

        <List />
      </div>
    );
  }
}

const styles = theme => ({
  costs: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }
});

const mapToStateProps = () => ({});

const AdminCostsStyles = connect(mapToStateProps)(
  withStyles(styles)(AdminProjectCosts)
);

export { AdminCostsStyles as AdminProjectCosts };
