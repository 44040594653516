export const userConstants = {
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  LOGOUT: "LOGOUT",

  // GET_ALL_INVITATIONS_SUCCESS: "GET_ALL_INVITATIONS_SUCCESS",
  // GET_ALL_INVITATIONS_FAILURE: "GET_ALL_INVITATIONS_FAILURE",

  // ADD_INVITATIONS_SUCCESS: "ADD_INVITATIONS_SUCCESS",
  // ADD_INVITATIONS_FAILURE: "ADD_INVITATIONS_FAILURE",

  // VERIFY_USER_SUCCESS:"VERIFY_USER_SUCCESS",
  // VERIFY_USER_FAILURE:"VERIFY_USER_FAILURE",

  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",

  SET_PROJECT_ROLE_SUCCESS: "SET_PROJECT_ROLE_SUCCESS",
  SET_PROJECT_ROLE_FAILURE: "SET_PROJECT_ROLE_FAILURE",

  CLEAR_ASSIGNED_USERS_LOCAL: "CLEAR_ASSIGNED_USERS_LOCAL",

  GET_MY_ROLE_REQUEST: "GET_MY_ROLE_REQUEST",
  GET_MY_ROLE_SUCCESS: "GET_MY_ROLE_SUCCESS",
  GET_MY_ROLE_FAILURE: "GET_MY_ROLE_FAILURE",

  GET_MY_PROJECTS_SUCCESS: "GET_MY_PROJECTS_SUCCESS",
  GET_MY_PROJECTS_FAILURE: "GET_MY_PROJECTS_FAILURE",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  GET_USER_PROJECTS_SUCCESS: "GET_USER_PROJECTS_SUCCESS",
  GET_USER_PROJECTS_FAILURE: "GET_USER_PROJECTS_FAILURE",

  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",

  UPDATE_PROJECT_ROLE_SUCCESS: "UPDATE_PROJECT_ROLE_SUCCESS",
  UPDATE_PROJECT_ROLE_FAILURE: "UPDATE_PROJECT_ROLE_FAILURE",

  GET_ALL_USERS_ROLES_SUCCESS: "GET_ALL_USERS_ROLES_SUCCESS",
  GET_ALL_USERS_ROLES_FAILURE: "GET_ALL_USERS_ROLES_FAILURE",

  DELETE_PROJECT_ROLE_SUCCESS: "DELETE_PROJECT_ROLE_SUCCESS",
  DELETE_PROJECT_ROLE_FAILURE: "DELETE_PROJECT_ROLE_FAILURE",

  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE",

  GET_COMPANY_USERS_SUCCESS: "GET_COMPANY_USERS_SUCCESS",
  GET_COMPANY_USERS_FAILURE: "GET_COMPANY_USERS_FAILURE",

  ADD_COMPANY_USER_SUCCESS: "ADD_COMPANY_USER_SUCCESS",
  ADD_COMPANY_USER_FAILURE: "ADD_COMPANY_USER_FAILURE",

  EDIT_COMPANY_USERS_SUCCESS: "EDIT_COMPANY_USERS_SUCCESS",
  EDIT_COMPANY_USERS_FAILURE: "EDIT_COMPANY_USERS_FAILURE",

  DELETE_COMPANY_USERS_SUCCESS: "DELETE_COMPANY_USERS_SUCCESS",
  DELETE_COMPANY_USERS_FAILURE: "DELETE_COMPANY_USERS_FAILURE",

  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",

  EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_FAILURE: "EDIT_PROFILE_FAILURE"
};
