// React //
import React from "react";

// Redux //
import { connect } from "react-redux";

// Material Ui //
import { withStyles } from "@material-ui/core";

// Components //

// Reusable Functions //
import addingCommaToCost from "../reusebleFunctions/addingCommaToCost";
// Styles //

class TotalCost extends React.PureComponent {
  state = {};

  render() {
    const { classes, totals } = this.props;
    return (
      <div className={classes.totalCostsHolder}>
        <div>
          <label>Total Budget Cost:</label>
          <span>{addingCommaToCost(totals.budgetTotal)}</span>
        </div>
        <div className={classes.middle}>
          <label>Total Revised Cost:</label>
          <span>{addingCommaToCost(totals.revisedTotal)}</span>
        </div>
        <div>
          <label>Total Actual Cost:</label>
          <span>{addingCommaToCost(totals.actualTotal)}</span>
        </div>
      </div>
    );
  }
}

const Styles = {
  totalCostsHolder: {
    display: "flex",
    margin: "10px 0px",
    "& > div": {
      textAlign: "center",
      flex: 1,
      border: "1px solid #004d8682",
      padding: "15px 4px",
      backgroundColor: "#ffffffe0",
      borderRadius: "5px",
      color: "#fffffc",
      fontSize: "15px",
      fontWeight: 500
    },
    "& > div > span": {
      padding: "0px 4px",
      color: "#000000ad"
    },
    "& > div > label": {
      color: "#0000006b"
    }
  },
  middle: {
    margin: "0 6px"
  }
};

const mapStateToProps = state => ({
  totals: state.costs.costTotals ? state.costs.costTotals : {}
});

export default connect(mapStateToProps)(withStyles(Styles)(TotalCost));
