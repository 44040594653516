import React, { PureComponent } from "react";
import { TaskRow } from "./";
import { withStyles } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import ThemeContext from "../ContextApi/Context";
import { Link } from "react-router-dom";
// #############################################################################
// component
// #############################################################################

class GanttChartWidget extends PureComponent {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      isCardExpanded: false,
      expandArrow: false
    };
  }

  // ###########################################################################
  // componentDidMount
  // ###########################################################################

  componentDidMount = () => {
    if (this.refs.container)
      this.setState({ expandArrow: this.isItOverflowed() });
  };

  // ###########################################################################
  // componentDidUpdate
  // ###########################################################################

  componentDidUpdate = () => {
    if (this.refs.container)
      this.setState({ expandArrow: this.isItOverflowed() });
  };

  // ###########################################################################
  // is it overflowed
  // ###########################################################################

  handleToggleExpandCard = () => {
    this.setState(prevState => ({
      isCardExpanded: !prevState.isCardExpanded
    }));
  };

  // ###########################################################################
  // is it overflowed
  // ###########################################################################

  isItOverflowed = () => {
    return this.refs.container.offsetHeight > 270;
  };

  // ###########################################################################
  // render
  // ###########################################################################

  render() {
    const { isCardExpanded, expandArrow } = this.state;
    const { classes, tasks = [], role } = this.props;
    const parentTasks = tasks.filter(task => task.parentTask === null);
    const projectId = this.context;

    return (
      <div className={classes.root}>
        <Link
          to={`/${
            role === "adminFlag" ? "admin" : "user"
          }-dashboard/projects/${projectId}/project-schedule`}
          className={classes.title}
        >
          Project Schedule
        </Link>
        <div
          className={classes.container}
          style={
            isCardExpanded
              ? { height: this.refs.container.offsetHeight + 30 + "px" }
              : null
          }
        >
          {parentTasks[0] ? (
            <>
              <div className={classes.tableHeader}>
                <div className={classes.headerColumn}>Task Name</div>
                <div style={{ flex: 2 }} className={classes.headerColumn}>
                  Start Date
                </div>
                <div style={{ flex: 2 }} className={classes.headerColumn}>
                  Finish Date
                </div>
                <div className={classes.headerColumn}>Time Line</div>
                <div
                  style={{ flex: 1.5, textAlign: "center" }}
                  className={classes.headerColumn}
                >
                  Delay
                </div>
              </div>
              <div ref="container">
                {parentTasks.map(task => (
                  <TaskRow key={task._id} task={task} />
                ))}
              </div>
            </>
          ) : (
            <section className={classes.noData}>No Data</section>
          )}
        </div>
        {!expandArrow ? null : (
          <div
            onClick={this.handleToggleExpandCard}
            className={classes.expandButton}
          >
            <KeyboardArrowDown
              className={`${classes.icon} ${
                isCardExpanded ? classes.rotate : null
              }`}
            />
          </div>
        )}
      </div>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root: {
    position: "relative"
  },
  container: {
    overflow: "hidden",
    height: "290px",
    padding: "10px 15px 3px",
    background: "white",
    borderRadius: "5px 5px 0px 0px",
    marginTop: 20,
    transition: "height 300ms"
  },
  title: {
    backgroundColor: "white",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    color: "#80808A",
    padding: "5px 15px 10px",
    fontWeight: "500",
    fontSize: 17,
    width: "fit-content",
    position: "absolute",
    top: -24,
    left: "0",
    textDecoration: "none!important",
    "&:hover": {
      color: "#aaaab5"
    }
  },
  tableHeader: {
    display: "flex",
    borderBottom: "2px solid #155794"
  },
  headerColumn: {
    flex: 3,
    color: "#155794",
    padding: "3px 5px",
    fontWeight: 500,
    fontSize: 13
  },
  expandButton: {
    backgroundColor: "#E0E1E6",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px"
  },
  icon: {
    color: "#155794",
    transition: "transform 200ms"
  },
  rotate: {
    transform: "rotate(180deg)"
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    color: "#00000036",
    fontWeight: "100!important",
    backgroundColor: "white",
    padding: "10px 15px 3px",
    borderRadius: "5px",
    marginTop: "0",
    // flexGrow: 100,
    marginBottom: "25px",
    height: "290px"
  }
});

// #############################################################################
// connect material styles and redux state to component
// #############################################################################
const GanttChartWidgetWithStyles = withStyles(styles)(GanttChartWidget);

// #############################################################################
// export
// #############################################################################

export { GanttChartWidgetWithStyles as GanttChartWidget };
