import React from "react";

// #####[ material ui ]##############################################################
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import  Person from "@material-ui/icons/Person";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Bookmarks from "@material-ui/icons/Bookmarks";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// #####[ redux ]##############################################################
import { activityActions, attachmentActions} from "../../actions";
import { activityUserActions , attachmentUserActions } from "../../userActions/";
import { connect } from "react-redux";
import { API } from "../../constants";

// #####[ reusable functions ]##############################################################
import pastTimeSoFar from "../reusebleFunctions/pastTimeSoFar";

// #####[ styles ]##############################################################
import { Colors } from "../../styles";

class AdminProjectCostsModalContent extends React.PureComponent {
  state = { value: 0, activitiy: null };

  componentDidMount() {
    if (this.props.role === "adminFlag") {
      this.props.dispatch(
        attachmentActions.getAttachments({
          projectId: this.props.projectId,
          costId: this.props.clickedRowDataId
        })
      );
      this.props.dispatch(
        activityActions.getActivities({
          costId: this.props.clickedRowDataId
        })
      );
    }
    else {
      this.props.dispatch(
        attachmentUserActions.getAttachments({
          projectId: this.props.projectId,
          costId: this.props.clickedRowDataId
        })
      );
      this.props.dispatch(
        activityUserActions.getActivities({
          costId: this.props.clickedRowDataId
        })
      );
    }



  }

  componentDidUpdate(prevProps) {
    const activities = this.props.activities;
    const documents = this.props.documents;

    if (documents && prevProps.documents !== documents)
      this.setState({
        documents,
        contracts: documents.filter(document =>
          document.tags.includes("Contract")
        ),
        attachments: documents.filter(
          document => !document.tags.includes("Contract")
        )
      });

    if (activities && prevProps.activities !== activities)
      this.setState({ activities });
  }

  handleDownload = (link, e) => {
    e.preventDefault();
    window.open(link);
  };

  handleChangeTab = (event, value) => {
    this.setState({ value });
  };

  componentWillUnmount() {
    this.setState({
      documents: {},
      activitiy: null
    });
  }

  render() {
    const { classes, handlerInfoModal, costs } = this.props;
    const { value, activities, contracts, attachments } = this.state;
    const cost = costs.filter(
      cost => cost._id === this.props.clickedRowDataId
    )[0];
    const costName = cost.name;
    return (
      <div className={classes.modalContainer} tabIndex={1}>
        <Close
          className={classes.closeIcon}
          onClick={() => handlerInfoModal(false)}
        />
        <h3>{costName}</h3>

        <p>{cost.itemNotes ? cost.itemNotes : null} </p>

        <div className={classes.activityAndAttachmentListContainer}>
          {/* // NOTE Activity */}
          <section className={classes.activity}>
            <header className={classes.subContainerActivityHeader}>
              <h4>ACTIVITY</h4>
            </header>
            <article className={classes.subContainerActivityList}>
              {activities &&
                activities.map((activity, index) => (
                  <div className={classes.activityItem} key={index}>
                    <Person className={classes.attachmentItemUserIcon} />
                    <span>
                      <span>
                        {activity.user.firstName + " " + activity.user.lastName}
                      </span>
                      <span>{activity.action}</span>
                    </span>
                    <span>{pastTimeSoFar(activity.createDate)}</span>
                  </div>
                ))}
            </article>
          </section>
          {/* NOTE Attachment and Contract Tabs */}
          <section className={classes.attachmentAndContractList}>
            <header className={classes.subContainerAttachmentAndContractHeader}>
              <Tabs
                value={this.state.value}
                onChange={this.handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                className={classes.tabs}
                classes={{ indicator: classes.indicator }}
              >
                <Tab
                  classes={{ selected: classes.selected }}
                  label="Attachments"
                />
                <Tab
                  classes={{ selected: classes.selected }}
                  label="Contracts"
                />
              </Tabs>
            </header>
            {value === 0 ? (
              // NOTE attachment tab content
              <article className={classes.subContainerAttachmentList}>
                {attachments
                  ? attachments.map((attachment, index) => (
                    <div className={classes.attachmentItem} key={index}>
                      <div className={classes.attachmentItemDataWrapper}>
                        <div
                          className={
                            classes.attachmentItemDataWrapperUserAndAttachment
                          }
                        >
                          <Person
                            className={classes.attachmentItemUserIcon}
                          />
                          <span>
                            <span>
                              {attachment.sender.firstName}{" "}
                              {attachment.sender.lastName}
                            </span>
                            Added {attachment.name}
                          </span>
                        </div>
                        {attachment.tags
                          ? attachment.tags[0] && (
                            <div
                              className={
                                classes.attachmentItemDataWrapperTags
                              }
                            >
                              <Bookmarks className={classes.tagsIcon} />
                              <div>
                                {attachment.tags.map((tag, index) => (
                                  <span key={"$" + index}>
                                    {tag}
                                    {attachment.tags.length - 1 !== index &&
                                      ", "}
                                  </span>
                                ))}
                              </div>
                            </div>
                          )
                          : ""}
                        <div className={classes.uploadTime}>
                          <span>{pastTimeSoFar(attachment.createDate)}</span>
                        </div>
                      </div>
                      <div className={classes.uploadTime}>
                        <a
                          href={attachment.link}
                          onClick={e =>
                            this.handleDownload(
                              `${API}/${attachment.link}`,
                              e
                            )
                          }
                        >
                          <ArrowDownward
                            className={classes.attachmentItemDownloadIcon}
                          />
                        </a>
                      </div>
                    </div>
                  ))
                  : null}
              </article>
            ) : (
                <article className={classes.subContainerAttachmentList}>
                  {contracts
                    ? contracts.map((contract, index) => (
                      <div className={classes.attachmentItem} key={index}>
                        <div className={classes.attachmentItemDataWrapper}>
                          <div
                            className={
                              classes.attachmentItemDataWrapperUserAndAttachment
                            }
                          >
                            <Person
                              className={classes.attachmentItemUserIcon}
                            />
                            <span>
                              <span>
                                {contract.sender.firstName}{" "}
                                {contract.sender.lastName}
                              </span>
                              Added {contract.name}
                            </span>
                          </div>
                          {contract.tags
                            ? contract.tags[0] && (
                              <div
                                className={
                                  classes.attachmentItemDataWrapperTags
                                }
                              >
                                <Bookmarks className={classes.tagsIcon} />
                                <div>
                                  {contract.tags.map((tag, index) => (
                                    <span key={"$" + index}>
                                      {tag}
                                      {contract.tags.length - 1 !== index &&
                                        ", "}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            )
                            : ""}
                          <div className={classes.uploadTime}>
                            <span>{pastTimeSoFar(contract.createDate)}</span>
                          </div>
                        </div>
                        <div className={classes.uploadTime}>
                          <a
                            href={contract.link}
                            onClick={e =>
                              this.handleDownload(
                                `${API}/${contract.link}`,
                                e
                              )
                            }
                          >
                            <ArrowDownward
                              className={classes.attachmentItemDownloadIcon}
                            />
                          </a>
                        </div>
                      </div>
                    ))
                    : null}
                </article>
              )}
          </section>
        </div>
      </div>
    );
  }
}

const styles = {
  modalContainer: {
    width: "700px",
    minHeight: "350px",
    borderRadius: "10px",
    boxShadow: "0 0 10px #545454",
    padding: "30px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EFEFF9",
    "& > h3": {
      color: Colors.darkGreen,
      margin: "0"
    },
    "& > p": {
      fontSize: "13px",
      padding: " 0 0 10px 0",
      margin: 0,
      color: Colors.grey,
      borderBottom: "1px solid #D1D1DB"
    }
  },
  closeIcon: {
    color: Colors.grey,
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer"
  },
  activityAndAttachmentListContainer: {
    display: "flex",
    height: "100%"
  },
  activity: {
    width: "50%",
    margin: 0,
    paddingRight: "30px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 100,
    maxHeight: "600px"
  },
  subContainerActivityHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px 0 5px",
    marginBottom: "10px",
    borderBottom: `2px solid ${Colors.lightGreen}`,
    color: "black",

    "& > h4": {
      margin: 0,
      display: "flex",
      alignItems: "flex-end",
      padding: "5px 6px"
    }
  },
  subContainerActivityList: {
    overflowY: "overlay",
    paddingRight: "10px",
    flexGrow: 100
  },
  activityItem: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "row",
    padding: "15px 10px",
    marginBottom: " 6px ",
    borderRadius: "5px",
    "& span": {
      fontSize: "12px",
      color: Colors.grey
    },
    "& span > span:first-child": {
      color: Colors.darkGreen,
      marginRight: "4px",
      fontWeight: "bold",
      width: "20px"
    },
    "& > span:nth-child(2)": {
      paddingRight: "15px",
      width: "175px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      hyphens: "auto"
    },
    "& > span:nth-child(3)": {
      fontSize: "10px",
      width: "90px",
      textAlign: "center"
    }
  },

  attachmentAndContractList: {
    width: "50%",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    flexGrow: 100,
    maxHeight: "600px"
  },
  subContainerAttachmentAndContractHeader: {
    paddingLeft: "30px"
  },

  subContainerAttachmentList: {
    overflowY: "overlay",
    paddingRight: "11px",
    paddingLeft: "30px",
    borderLeft: "1px solid #D1D1DB",
    flexGrow: 100
  },
  attachmentItem: {
    backgroundColor: "white",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "row",
    padding: "10px 13px",
    marginBottom: "7px",

    "& > div:nth-child(2)": {
      width: "10%",
      display: "flex",
      alignItems: "center"
    }
  },
  attachmentItemDataWrapper: {
    width: "90%"
  },
  attachmentItemDataWrapperUserAndAttachment: {
    display: "flex",
    alignItems: "flex-start",
    margin: "2px 0",
    "& span": {
      fontSize: "12px"
    },
    "& span:nth-child(2)": {
      color: Colors.grey,
      display: "block",
      width: "200px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      hyphens: "auto"
    },
    "& span:nth-child(2) > span": {
      color: Colors.darkGreen,
      marginRight: "4px",
      fontWeight: "bold"
    }
  },
  attachmentItemUserIcon: {
    color: Colors.grey,
    fontSize: "16px",
    height: "100%",
    marginRight: "4px"
  },

  attachmentItemDataWrapperTags: {
    margin: "2px 0",
    fontSize: "11px",
    color: Colors.darkBlue,
    fontWeight: "bold",
    display: "flex"
  },

  tagsIcon: {
    fontSize: "14px",
    height: "100%",
    marginRight: "5px",
    color: Colors.darkBlue
  },

  uploadTime: {
    marginTop: "7px",
    "& span": {
      display: "flex",
      fontWeight: "normal",
      alignItems: "center",
      marginLeft: "20px",
      fontSize: "10px",
      color: Colors.grey
    }
  },

  attachmentItemDownloadIcon: {
    color: Colors.lightGreen,
    fontSize: "24px"
  },
  selectBox: {
    backgroundColor: "#F7F6FC",
    border: "1px solid #E1E0E5",
    borderRadius: "3px",
    width: "120px",
    height: "30px",
    color: Colors.grey,
    boxShadow: "inset 0 0 1px #E1E0E5"
  },
  tabs: {
    marginBottom: "10px",
    "& span": { fontWeight: "bold!important", fontSize: "14px!important" }
    // borderBottom: "1px solid #D1D1DB "
  },
  indicator: {
    backgroundColor: Colors.lightGreen + "!important",
    height: "2px"
  },
  selected: {
    color: `black!important`
  },
  nothingToShow: {
    height: "290px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none!important",
    fontSize: "30px!important",
    fontWeight: "100!important",
    color: "#00000036!important"
  }
};

const mapStateToProps = state => {
  return {
    projectId: state.projects.project._id,
    documents: state.attachments,
    costs: state.costs.costs,
    activities: state.activities,
    role: state.projects.project.role
  };
};

const AdminProjectCostsModalContentConnected = connect(mapStateToProps)(
  AdminProjectCostsModalContent
);
const AdminProjectCostsModalContentConnectedAndStyled = withStyles(styles)(
  AdminProjectCostsModalContentConnected
);

export {
  AdminProjectCostsModalContentConnectedAndStyled as AdminProjectCostsModalContent
};
