import React from "react";
import { connect } from "react-redux";
import { projectUserActions, costUserActions } from "../../../userActions";
import {
  issueUserActions,
  userUserActions,
  taskUserActions,
  noteUserActions
} from "../../../userActions";
import { ProjectInfo, GanttChartWidget } from "../../";
import { filterActions } from "../../../actions";
import { userProjectForm } from "../../";
import { Card } from "../../dashboardCards/Card";
import { ProjectTreeChart } from "../../";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import ThemeContext from "../../ContextApi/Context";
import { IssueWidget } from "../../dashboardCards/IssueWidget";
import { NoteWidget } from "../../dashboardCards/NoteWidget";

// #############################################################################
// component
// #############################################################################

class UserProjectDashboard extends React.PureComponent {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      openAddProject: false,
      selectedProject: null
    };
  }

  // #########################################################################
  // Component Did Mount
  // #########################################################################

  componentDidMount = () => {
    const { dispatch } = this.props;
    const projectId = this.context;
    dispatch(costUserActions.getProjectCosts({ projectId }));
    dispatch(projectUserActions.getProjectUsers({ projectId }));
    dispatch(issueUserActions.getProjectIssues({ projectId }));
    dispatch(taskUserActions.getProjectTasks({ projectId }));
    dispatch(projectUserActions.getAllArchitects());
    dispatch(projectUserActions.getProject({ projectId }));
    dispatch(userUserActions.getAllUsers());
    dispatch(filterActions.pageFilterEdit(""));
    dispatch(noteUserActions.getProjectNotes({ projectId }));

    this.setState({
      selectedProject: projectId
    });
  };

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;
    const projectId = this.context;

    if (this.state.selectedProject !== projectId) {
      dispatch(costUserActions.getProjectCosts({ projectId }));
      dispatch(projectUserActions.getProjectUsers({ projectId }));
      dispatch(issueUserActions.getProjectIssues({ projectId }));
      dispatch(taskUserActions.getProjectTasks({ projectId }));
      dispatch(projectUserActions.getAllArchitects());
      dispatch(projectUserActions.getProject({ projectId }));
      dispatch(userUserActions.getAllUsers());
      dispatch(noteUserActions.getProjectNotes({ projectId }));
      this.setState({
        selectedProject: projectId
      });
    }
  }

  // #########################################################################
  // handle select changes
  // #########################################################################

  handleAddProject = data => {
    const parent = this.props.project._id;
    this.props.dispatch(
      projectUserActions.addProject({ ...data, projectId: parent, parent })
    );
    this.setState(prevState => ({ openAddProject: !prevState.openAddProject }));
  };

  // #########################################################################
  // handle edit project
  // #########################################################################

  editProject = data => {
    this.props.dispatch(projectUserActions.editProject(data));
  };

  // #########################################################################
  // handle Toggle add Project from
  // #########################################################################

  handleToggleAddProject = () => {
    this.setState(prevState => ({ openAddProject: !prevState.openAddProject }));
  };

  // #########################################################################
  // Render
  // #########################################################################

  render() {
    const {
      project,
      architects = [],
      classes,
      role,
      isClient,
      costs = [],
      projects = [],
      tasks = [],
      companies
    } = this.props;
    const { selectedProject } = this.state;
    return (
      <React.Fragment>
        <ProjectInfo
          architects={architects}
          deletable
          editProject={this.editProject}
        />
        <div className={classes.chart}>
          <ProjectTreeChart selectedProject={project._id} projects={projects} />
        </div>
        <div className={classes.cardsContainerWrapper}>
          <Grid className={classes.cardsContainer} container spacing={16}>
            <Grid item xs={6} className={classes.cardsSubContainer}>
              <IssueWidget />
            </Grid>
            <Grid item xs={6} className={classes.cardsSubContainer}>
              <NoteWidget />
            </Grid>
          </Grid>
          <Grid className={classes.cardsContainer} container spacing={16}>
            <Grid item xs={6} className={classes.cardsSubContainer}>
              <GanttChartWidget tasks={tasks} project={project} role={role} />
            </Grid>
            <Grid item xs={6} className={classes.cardsSubContainer}>
              <Card hasTitle={"Project Costs"} data={costs} role={role} />
            </Grid>
          </Grid>
        </div>
        {isClient && companies[0] && (
          <userProjectForm
            withShadow
            fullForm={false}
            openAddProject={this.state.openAddProject}
            handleToggleAddProject={this.handleToggleAddProject}
            users={this.props.users}
            companies={this.props.companies}
            architects={this.props.architects}
            allProjects={this.props.projects}
            selectedProject={selectedProject}
            handleAddProject={this.handleAddProject}
            handleRemoveAddSubProjectFlag={() => {}}
          />
        )}
      </React.Fragment>
    );
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  chart: {
    padding: "20px 0px"
  },
  cardsContainer: {
    marginTop: "20px"
  }
});

// #############################################################################
// connect the redux states and material styles to the component
// #############################################################################

const mapStateToProps = state => {
  return {
    projects: state.projects.projects,
    architects: state.projects.architects,
    project: state.projects.project,
    issues: state.issues,
    costs: state.costs.costs,
    isClient:
      state.projects.project && state.projects.project.role !== "Project-Client"
        ? true
        : false,
    role: state.projects.project ? state.projects.project.role : undefined,
    users: state.users.users ? state.users.users : [],
    companies: state.companies ? state.companies : [],
    tasks: state.tasks.tasks
  };
};
const UserProjectDashboardConnected = connect(mapStateToProps)(
  UserProjectDashboard
);
const UserProjectDashboardWithStyles = withStyles(styles)(
  UserProjectDashboardConnected
);

// #############################################################################
// export
// #############################################################################
export { UserProjectDashboardWithStyles as UserProjectDashboard };
