import { clientConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "../actions";

export const clientUserActions = {
  getAllCompanies,
  addCompany,
  editCompany,
  deleteCompany
};

//################################################################################
// GET All Companies
//################################################################################

function getAllCompanies() {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getAllCompanies", undefined, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.companies));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(companies) {
    return {
      type: clientConstants.GET_ALL_COMPANIES_SUCCESS,
      payload: companies
    };
  }
  // function failure(error) {
  //   return { type: clientConstants.GET_ALL_COMPANIES_FAILURE, payload: error };
  // }
}

//################################################################################
// Add Company
//################################################################################

function addCompany(data, getprojectAfterClientAdded) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("createCompany", data, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.company));
          dispatch(
            snackBarActions.snackBarSuccess("Company added successfully!!")
          );
        } else {
          dispatch(failure(response.msg));
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(company) {
    return { type: clientConstants.ADD_COMPANY_SUCCESS, payload: company };
  }
  function failure(error) {
    return { type: clientConstants.ADD_COMPANY_FAILURE, payload: error };
  }
}

//################################################################################
// Edit Company
//################################################################################

function editCompany(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editCompany", data, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.company));
          dispatch(
            snackBarActions.snackBarSuccess("Company edited successfully!!")
          );
        } else {
          dispatch(failure(response.msg));
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(company) {
    return { type: clientConstants.EDIT_COMPANY_SUCCESS, payload: company };
  }
  function failure(error) {
    return { type: clientConstants.EDIT_COMPANY_FAILURE, payload: error };
  }
}

//################################################################################
// Delete Company
//################################################################################

function deleteCompany(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteCompany", data, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.company));
          dispatch(
            snackBarActions.snackBarSuccess("Company deleted successfully!!")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };

  function success(company) {
    return { type: clientConstants.DELETE_COMPANY_SUCCESS, payload: company };
  }
  // function failure(error) {
  //   return { type: clientConstants.DELETE_COMPANY_FAILURE, payload: error };
  // }
}
