import React from 'react'
import { withStyles } from '@material-ui/core'
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Chat from "@material-ui/icons/Chat";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../styles/colors'

const styles = {
    iconButton: {
        padding: '3px !important',
        color: Colors.lightGreen,
    },
    commentIconWrapper: {
        padding: "3px!important"
    },
    minimizeIcon: {
        transition: "300ms",
        transform: "rotate(180deg)",
        fontSize: '22px'
    },
    commentIcon: {
        fontSize: "22px",
        color: Colors.blue
    },
}

export const ArrowComment = React.memo(withStyles(styles)(({ classes, onClick, isMaximizeForComments }) => (
    <>
        {isMaximizeForComments ? (
            <Tooltip title={"Minimize"}>
                <IconButton
                    className={classes.commentIconWrapper}
                    onClick={onClick}
                >
                    <KeyboardArrowDown
                        className={classes.minimizeIcon}
                    />
                </IconButton>
            </Tooltip>
        ) : (
                <Tooltip title={"Comments"}>
                    <IconButton
                        className={classes.commentIconWrapper}
                        onClick={onClick}
                    >
                        <Chat className={classes.commentIcon} />
                    </IconButton>
                </Tooltip>
            )}
    </>
))
)
