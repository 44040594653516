import React from "react";
import "./inputStyle.css";

//#############################################################################
// React Input Component
//#############################################################################
class ReactInput extends React.PureComponent {
  //###########################################################################
  // this function will handle the change in the input
  handleChange = event => {
    if (this.props.onChange) this.props.onChange(event);
  };

  //###########################################################################
  render() {
    const { name, type, min, max, accept, required, autoComplete, disabled, value, htmlFor, placeholder } = this.props;
    const { className, style } = this.props;

    return (
      <input
        style={style}
        onClick={this.handleFocus}
        className={`react-input-style ${className}`}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        htmlFor={htmlFor}
        value={value}
        disabled={disabled}
        autoComplete={autoComplete}
        required={required}
        type={type}
        name={name}
        max={max}
        min={min}
        accept={accept}
        placeholder={placeholder}
      />
    );
  }
}

//#############################################################################
// export
//#############################################################################

export { ReactInput };
