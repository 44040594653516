import React from "react";
import "./List.css";

////////////////////// components ////////////////////////////
import DeleteModal from "../Modal/DeleteModal";
import { ListItem } from "./ListItem";
import ThemeContext from "../ContextApi/Context";

// MATERIAL UI ///////////////////////////////////////////
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

////////////////////modal and its content ////////////////////
import HOCModal from "../Modal/Modal";
import { AdminProjectCostsModalContent } from "./AdminProjectCostsModalContent";
import PurchaseIdModalContent from "./PurchaseIDModalContent";
// Actions
import { attachmentActions } from "../../actions";
import { attachmentUserActions } from "../../userActions/";
import { costActions, filterActions } from "../../actions";
import { costUserActions } from "../../userActions";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

// #############################################################################
// component
// #############################################################################

class List extends React.PureComponent {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageCapacity: 10,
      data: [],
      dataProps: [],
      dragChild: false,
      infoModalIsShowed: false,
      clickedRowDataId: null,
      openDeleteModal: false,
      selectedOptionForDelete: {},
      role: undefined,
      openPurchaseIDModal: false
    };
  }

  componentDidMount() {
    const { dispatch, role } = this.props;
    const projectId = this.context;
    dispatch(filterActions.pageFilterEdit(""));

    if (role === "adminFlag") {
      dispatch(costActions.getProjectCosts({ projectId }));
      dispatch(costActions.getCostTotals({ projectId }));
    } else {
      dispatch(costUserActions.getProjectCosts({ projectId }));
      dispatch(costUserActions.getCostTotals({ projectId }));
    }
  }

  componentDidUpdate() {
    if (this.props.data && this.props.data !== this.state.dataProps)
      this.setState({
        dataProps: this.props.data,
        data: this.props.data.filter(Cost => Cost.parentCost === null)
      });
  }

  // ###########################################################################
  // generate page data
  // ###########################################################################

  generatePageData = (data = []) => {
    const start = this.state.pageSize * this.state.page;
    const end = this.state.pageSize * (this.state.page + 1);
    return data.slice(start, end);
  };

  // ###########################################################################
  // Drag And Drop
  // ###########################################################################
  onDragChild = () => {
    this.setState({ dragChild: true });
  };

  onDragStart = (e, index) => {
    const { data } = this.state;
    this.draggedItem = data[index];
    e.dataTransfer.setData("text/html", e.target.id);
    e.target.style.opacity = "0.4";
  };

  onDragOver = (e, index) => {
    const { dragChild } = this.state;
    e.preventDefault();
    const { data } = this.state;
    const draggedOverItem = data[index];
    let onDragOverItems = data.filter(item => item !== this.draggedItem);
    if (this.draggedItem === draggedOverItem) {
      return;
    }
    if (!dragChild) {
      // add the dragged item after the dragged over item
      onDragOverItems.splice(index, 0, this.draggedItem);
      this.setState({ data: onDragOverItems });
    }
  };

  onDragEnd = (e, index) => {
    e.preventDefault();
    e.target.style.opacity = "1";
    this.draggedIdx = null;
    const { data } = this.state;
    const draggedEndItem = data[index];
    const projectId = draggedEndItem.project._id;
    const costId = draggedEndItem._id;
    this.dispatchChangeCostOrder(projectId, costId, index + 1);
    this.setState({ dragChild: false });
  };

  // ###########################################################################
  //
  // ###########################################################################

  generatePageButtons = (length, pageCapacity) => {
    const { pageNumber } = this.state;
    const { classes } = this.props;
    let numberOfPages = 0;
    const buttons = [];

    if (this.isInt(length / pageCapacity))
      numberOfPages = parseInt(length / pageCapacity);
    else numberOfPages = parseInt(length / pageCapacity) + 1;

    for (let i = 0; i < numberOfPages; i++)
      buttons.push(
        <IconButton
          key={i}
          className={
            i === pageNumber
              ? `${classes.selectedPage} ${classes.pageBtn}`
              : `${classes.pageBtn}`
          }
          onClick={this.handleChangePage(i)}
        >
          {i + 1}
        </IconButton>
      );

    return buttons;
  };
  isInt = n => {
    return n % 1 === 0;
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleChangePage = number => () => {
    this.setState({ pageNumber: number });
  };

  handleNext = (length, pageCapacity) => () => {
    const NumberOfPages = Math.round((length + 2) / pageCapacity);
    if (this.state.pageNumber <= NumberOfPages - 2)
      this.setState({ pageNumber: this.state.pageNumber + 1 });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handlePagination = array => {
    const { pageCapacity, pageNumber } = this.state;
    return array.slice(
      pageNumber * pageCapacity,
      pageNumber * pageCapacity + pageCapacity
    );
  };

  // ###########################################################################
  //
  // ###########################################################################

  filterArray = (array = [], root = false) => {
    const { pageFilter } = this.props;
    const keyWordLowerCase = pageFilter.toLowerCase();
    let filteredArray = [];

    filteredArray = array.filter(item => {
      const approvalStatus = item.approvalStatus
        ? item.approvalStatus.toLowerCase().includes(keyWordLowerCase)
        : false;
      const note = item.note
        ? item.note.toLowerCase().includes(keyWordLowerCase)
        : false;
      const name = item.name
        ? item.name.toLowerCase().includes(keyWordLowerCase)
        : false;
      return name || approvalStatus || note;
    });
    if (filteredArray.length === 0 && root === false)
      filteredArray = this.filterArray(this.state.dataProps, true);
    return filteredArray;
  };

  // ###########################################################################
  //
  // ###########################################################################

  handlerInfoModal = bool => {
    this.setState({
      infoModalIsShowed: !!bool
    });
  };

  // ###########################################################################
  //
  // ###########################################################################

  setClickedRowDataToState = clickedRowDataId => {
    this.setState({
      clickedRowDataId
    });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleAddAttachment = (costId, file, tags) => {
    const { dispatch, role } = this.props;
    const projectId = this.context;
    let document = new FormData();
    document.append("projectId", projectId);
    document.append("costId", costId);
    document.append("document", file);
    document.append("tags", tags);

    role === "adminFlag"
      ? dispatch(attachmentActions.addAttachment(document))
      : dispatch(attachmentUserActions.addAttachment(document));
  };

  // #######################################################################################
  // ####################              Dialog: Delete              ####################
  // #######################################################################################

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
          openDeleteModal: true,
          selectedOptionForDelete
        })
      : this.setState({
          openDeleteModal: false
        });
  };

  handleAdd = parent => {
    this.setState({
      modalAddCost: true,
      name: "",
      note: "",
      budget: "",
      actual: "",
      parentId: parent,
      projectId: "",
      approvalStatus: ""
    });
  };

  //////////////////////////// Edit Cost Inline By Double Click /////////////////////////////
  handleEditCostInline = editedData => {
    const projectId = this.context;
    const { dispatch, role } = this.props;
    const {
      name,
      note,
      budget,
      actual,
      revised,
      purchaseId,
      tags,
      costId: id
    } = editedData;
    const data = {
      name,
      note,
      budget,
      actual,
      revised,
      purchaseId,
      tags,
      id,
      projectId
    };

    role === "adminFlag"
      ? dispatch(costActions.editCost(data))
      : dispatch(costUserActions.editCost(data));
  };

  handleAddInlineCost = ({
    name,
    note,
    budget,
    revised,
    actual,
    approvalStatus,
    parentId,
    purchaseId,
    addAttachment: attachment,
    tags
  }) => {
    const projectId = this.context;
    const { dispatch, role } = this.props;
    const document = new FormData();
    document.append("name", name);
    document.append("budget", budget);
    revised && document.append("revised", revised);
    document.append("actual", actual);
    document.append("parentId", parentId);
    document.append("projectId", projectId);
    document.append("note", note);
    document.append("approvalStatus", approvalStatus);
    purchaseId && document.append("purchaseId", purchaseId);
    document.append("tags", tags ? tags : []);
    attachment && document.append("document", attachment);

    role === "adminFlag"
      ? dispatch(costActions.addCost(document))
      : dispatch(costUserActions.addCost(document));
  };

  // #######################################################################################
  // #######################             COst Order                  #######################
  // #######################################################################################
  dispatchChangeCostOrder = (projectId, costId, order) => {
    const { dispatch, role } = this.props;
    role === "adminFlag"
      ? dispatch(
          costActions.changeCostOrder({
            projectId,
            costId,
            order
          })
        )
      : dispatch(
          costUserActions.changeCostOrder({
            projectId,
            costId,
            order
          })
        );
  };

  handleOpenPurchaseModal = bool => {
    this.setState({
      openPurchaseIDModal: !!bool
    });
  };

  handleSetSelectedItemForPurchaseId = selectedItemForPurchaseId => {
    this.setState({
      selectedItemForPurchaseId: { ...selectedItemForPurchaseId }
    });
  };

  handleSetPurchaseId = purchaseId => {
    this.handleOpenPurchaseModal(false);
    this.handleApprove({ purchaseId, ...this.state.selectedItemForPurchaseId });
  };

  handleApprove = ({ purchaseId = undefined, id, approvalStatus }) => {
    const projectId = this.context;
    const { dispatch, role } = this.props;
    role === "adminFlag"
      ? dispatch(
          costActions.approveCost({
            projectId,
            purchaseId,
            id,
            approvalStatus
          })
        )
      : dispatch(
          costUserActions.approveCost({
            projectId,
            purchaseId,
            id,
            approvalStatus
          })
        );
  };

  // ###########################################################################
  // Render
  // ###########################################################################

  render() {
    const { classes, role } = this.props;

    const { data, infoModalIsShowed, openPurchaseIDModal } = this.state;

    const filteredData = this.filterArray(data);
    const dataInPage = this.handlePagination(filteredData);

    const userRole = role !== "Project-Client" ? true : false;
    return (
      <div className={classes.list}>
        {/* ################################################################## */}

        <div className="mplist-main">
          <div className="mpclist-header">
            <div style={{ display: "flex", flex: 2, margin: "0px 3px" }}>
              <div style={{ flex: 2, paddingLeft: "15px" }}>Cost Name</div>
              <div style={{ flex: 3, paddingLeft: "15px" }}>Note</div>
            </div>
            <div
              style={{ textAlign: "center", width: "142px", margin: "0px 3px" }}
            >
              Purchase ID
            </div>
            <div
              style={{ textAlign: "center", width: "112px", margin: "0px 3px" }}
            >
              Budget
            </div>
            <div
              style={{ textAlign: "center", width: "112px", margin: "0px 3px" }}
            >
              Revised
            </div>
            <div
              style={{ textAlign: "center", width: "112px", margin: "0px 3px" }}
            >
              Actual
            </div>
            <div style={{ flex: 1.3, margin: "0px 3px 0 15px" }}>Actions</div>
          </div>

          {/* ################################################################## */}
          {dataInPage[0] ? (
            <div className="mplist-container">
              {/* {pageData.map((Cost, idx) => { */}
              {dataInPage.map((Cost, index) => {
                return (
                  <div
                    className="drag"
                    // draggable={true}
                    draggable={userRole}
                    onDragStart={e => this.onDragStart(e, index)}
                    onDragOver={e => this.onDragOver(e, index, false)}
                    onDragEnd={e => this.onDragEnd(e, index)}
                    key={Cost._id}
                    id={Cost._id}
                  >
                    <ListItem
                      id={Cost._id}
                      data={Cost}
                      allCosts={this.props.data}
                      handleAdd={this.handleAdd}
                      handleEditCostInline={this.handleEditCostInline}
                      handleAddInlineCost={this.handleAddInlineCost}
                      handleApprove={this.handleApprove}
                      handleDeleteModal={this.handleDeleteModal}
                      handlerInfoModal={this.handlerInfoModal}
                      handleAddAttachment={this.handleAddAttachment}
                      setClickedRowDataToState={this.setClickedRowDataToState}
                      approvable={userRole}
                      onDragChild={this.onDragChild}
                      // if User role is Client this props set "false" for Admin and manager set "true"
                      userRole={userRole}
                      dispatchChangeCostOrder={this.dispatchChangeCostOrder}
                      handleOpenPurchaseModal={this.handleOpenPurchaseModal}
                      handleSetSelectedItemForPurchaseId={
                        this.handleSetSelectedItemForPurchaseId
                      }
                      openPurchaseIDModal={openPurchaseIDModal}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <section className={classes.noData}>No Cost</section>
          )}
        </div>

        {/* ################################################################## */}
        {dataInPage[0] && (
          <>
            <Grid container className={classes.pagination}>
              <Grid container className={classes.borderTop}>
                <div className={classes.nextButton}>
                  <IconButton
                    onClick={this.handleNext(
                      filteredData.length,
                      this.state.pageCapacity
                    )}
                    className={classes.pageBtn}
                  >
                    NEXT
                  </IconButton>
                </div>
                <div className={classes.pageNumbersHolder}>
                  {this.generatePageButtons(
                    filteredData.length,
                    this.state.pageCapacity
                  )}
                </div>
              </Grid>
            </Grid>

            {/* //////////////////// Delete User Dialog ///////////////////////// */}
            <DeleteModal
              open={this.state.openDeleteModal}
              handleDeleteModal={this.handleDeleteModal}
              selectedOptionForDelete={{
                id: this.state.selectedOptionForDelete._id
              }}
              adminAction={costActions.deleteCost}
              userAction={costUserActions.deleteCost}
              //optional
              modalTitle={"Delete Cost"}
              // optionNameForDelete={this.state.selectedOptionForDelete.name}
            />

            <HOCModal
              isShowed={infoModalIsShowed}
              handlerInfoModal={this.handlerInfoModal}
            >
              <AdminProjectCostsModalContent
                handlerInfoModal={this.handlerInfoModal}
                clickedRowDataId={this.state.clickedRowDataId}
              />
            </HOCModal>

            <PurchaseIdModalContent
              handleOpenPurchaseModal={this.handleOpenPurchaseModal}
              clickedRowDataId={this.state.clickedRowDataId}
              handleSetPurchaseId={this.handleSetPurchaseId}
              open={openPurchaseIDModal}
            />
          </>
        )}
        {/* ################################################################## */}
      </div>
    );
  }
}

const styles = theme => ({
  list: {
    marginTop: "10px"
  },
  // ////////////////////////// pagination ////////////////////
  pageNumbersHolder: {
    padding: "5px 0px",
    display: "flex",
    flex: 1,
    flexWrap: "no-wrap",
    overflowX: "auto"
  },
  nextButton: {
    padding: "5px 0px"
  },
  pagination: {
    padding: "0px"
  },
  borderTop: {
    borderTop: "1px solid #e1e1e1"
  },
  pageBtn: {
    fontSize: "14px",
    minWidth: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "15px",
    color: "#00000040",
    marginRight: "5px"
  },
  selectedPage: {
    color: "#155794",
    backgroundColor: "rgba(0, 0, 0, 0.03)"
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "35px",
    color: "#00000036",
    fontWeight: "100!important",
    padding: "10px 50px 3px 0",
    borderRadius: "5px",
    marginTop: "0",
    // flexGrow: 100,
    marginBottom: "25px",
    height: "230px"
  }
});

const mapStateToProps = state => {
  return {
    data: state.costs.costs ? state.costs.costs : [],
    costTotals: state.costs.costTotals,
    project: state.projects.project,
    role: state.projects.project ? state.projects.project.role : undefined,
    pageFilter: state.filters.pageFilter
  };
};

const CostListWithStyle = withRouter(
  connect(mapStateToProps)(withStyles(styles)(List))
);

export { CostListWithStyle as List };
