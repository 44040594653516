// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
// Material Ui //
import { withStyles } from "@material-ui/core";

// Components //
import {
  FileIcon,
  UpwardArrow,
  DateRangeIcon,
  DownloadIcon,
  EditIcon,
  DeleteIcon,
  AddCircleOutlinedIcon
} from "../Icons";
// Reusable Functions //
import TimeMonthDayYear from "../reusebleFunctions/TimeMonthDayYear";
// Styles //
import { Colors } from "../../styles/colors";
import { Link } from "react-router-dom";

class ConstructionDocumentTabItem extends React.PureComponent {
  state = {
    isExpand: false
  };

  componentDidUpdate() {
    !this.props.data[0] &&
      this.setState({
        isExpand: false
      });
  }

  handleExpand = () => {
    this.setState(prevState => ({
      isExpand: !prevState.isExpand
    }));
  };

  render() {
    const {
      classes,
      role,
      position,
      handleGoToForm,
      handleGoToEmptyForm,
      data = {},
      name,
      tempName,
      handleDeleteModal,
      handleGoToReport,
      userId
    } = this.props;
    const { isExpand } = this.state;

    // ###########################################################################
    // create routes for the selected project
    // ###########################################################################
    const routes =
      position && position === "admin"
        ? {
            addendum: `/admin-dashboard/projects/${this.props.projectId}/construction-project/addendum`,
            addLog: `/admin-dashboard/projects/${this.props.projectId}/construction-project/addDailyLog`,
            addRFI: `/admin-dashboard/projects/${this.props.projectId}/construction-project/addRFI`,
            addSubmittal: `/admin-dashboard/projects/${this.props.projectId}/construction-project/addShopDrawingsSubmittal`,
            getendum: `/admin-dashboard/projects/${this.props.projectId}/construction-project/getendums`,
            getLog: `/admin-dashboard/projects/${this.props.projectId}/construction-project/getDailyLog`,
            getRFI: `/admin-dashboard/projects/${this.props.projectId}/construction-project/getRFI`,
            getSubmittal: `/admin-dashboard/projects/${this.props.projectId}/construction-project/getShopDrawingsSubmittal`
          }
        : {
            addendum: `/user-dashboard/projects/${this.props.projectId}/construction-project/addendum`,
            addLog: `/user-dashboard/projects/${this.props.projectId}/construction-project/addDailyLog`,
            addRFI: `/user-dashboard/projects/${this.props.projectId}/construction-project/addRFI`,
            addSubmittal: `/user-dashboard/projects/${this.props.projectId}/construction-project/addShopDrawingsSubmittal`,
            getendum: `/user-dashboard/projects/${this.props.projectId}/construction-project/getendums`,
            getLog: `/user-dashboard/projects/${this.props.projectId}/construction-project/getDailyLog`,
            getRFI: `/user-dashboard/projects/${this.props.projectId}/construction-project/getRFI`,
            getSubmittal: `/user-dashboard/projects/${this.props.projectId}/construction-project/getShopDrawingsSubmittal`
          };
    return (
      <div className={classes.root}>
        {isExpand ? (
          <div>
            <div className={classes.header}>
              <div className={classes.firstHeaderItem} style={{ flex: 3 }}>
                <div className={classes.firstHeaderSubItemExpand}>
                  Title of form
                </div>
              </div>
              <div className={classes.headerItem} style={{ flex: 3 }}>
                Note
              </div>
              <div className={classes.headerItem} style={{ flex: 2 }}>
                Date
              </div>
              <div className={classes.headerItem} style={{ flex: 2 }}>
                Assigned to
              </div>
              <div className={classes.headerActionItem}>
                <UpwardArrow onClick={this.handleExpand} />
                <Link
                  to={
                    name === "PMDG Addendums"
                      ? routes.addendum + "/add"
                      : name === "PMDG Daily Log"
                      ? routes.addLog + "/add"
                      : name === "PMDG RFI’s"
                      ? routes.addRFI + "/add"
                      : routes.addSubmittal + "/add"
                  }
                >
                  <AddCircleOutlinedIcon
                    onClick={() => handleGoToEmptyForm(name)}
                    tooltip={
                      name === "PMDG Addendums"
                        ? "Add Addendum"
                        : name === "PMDG Daily Log"
                        ? "Add Log"
                        : name === "PMDG RFI’s"
                        ? "Add RFI"
                        : "Add Submittal"
                    }
                    // link={documents.link}
                  />
                </Link>
                <span style={{ width: "30px", height: "38px" }}></span>
                <span style={{ width: "30px", height: "38px" }}></span>
              </div>
            </div>
            {data.map((item, index) => (
              <div className={classes.row} key={index}>
                <div className={classes.firstBodyItem} style={{ flex: 3 }}>
                  <div className={classes.firstBodySubItem}>
                    <FileIcon className={classes.fileIcon} withoutTooltip />
                    {item.formNumber}
                  </div>
                </div>
                <div className={classes.bodyItem} style={{ flex: 3 }}>
                  {item.note}
                </div>
                <div className={classes.bodyItem} style={{ flex: 2 }}>
                  {TimeMonthDayYear(item.createDate)}
                </div>
                <div className={classes.bodyItem} style={{ flex: 2 }}>
                  {item.assignedTo &&
                    item.assignedTo.map((person, index) => (
                      <span className={classes.assignedTo} key={index}>
                        {person.firstName} {person.lastName}
                        {index !== item.assignedTo.length - 1 && ","}
                      </span>
                    ))}
                </div>
                <div className={classes.bodyActionItem}>
                  {isExpand && (
                    <Link
                      to={
                        name === "PMDG Addendums"
                          ? routes.getendum + "/" + data[index]._id
                          : name === "PMDG Daily Log"
                          ? routes.getLog + "/" + data[index]._id
                          : name === "PMDG RFI’s"
                          ? routes.getRFI + "/" + data[index]._id
                          : routes.getSubmittal + "/" + data[index]._id
                      }
                    >
                      <DownloadIcon
                        onClick={() =>
                          handleGoToReport(
                            data[index],
                            name === "PMDG Addendums"
                              ? "PMDG Addendum Pdf"
                              : name === "PMDG Daily Log"
                              ? "PMDG Daily Log Pdf"
                              : name === "PMDG RFI’s"
                              ? "PMDG RFI Pdf"
                              : "PMDG Shop Drawings Submittal Log Pdf"
                          )
                        }
                      />
                    </Link>
                  )}
                  {((role === "Project-Client" &&
                    item.creator._id === userId) ||
                    role !== "Project-Client") &&
                    isExpand && (
                      <>
                        <Link
                          to={
                            name === "PMDG Addendums"
                              ? routes.addendum + "/" + data[index]._id
                              : name === "PMDG Daily Log"
                              ? routes.addLog + "/" + data[index]._id
                              : name === "PMDG RFI’s"
                              ? routes.addRFI + "/" + data[index]._id
                              : routes.addSubmittal + "/" + data[index]._id
                          }
                        >
                          <EditIcon
                            onClick={() => handleGoToForm(data[index], name)}
                          />
                        </Link>
                        <DeleteIcon
                          onClick={() => handleDeleteModal(data[index])}
                        />
                      </>
                    )}
                  {!(
                    role === "Project-Client" && item.creator._id === userId
                  ) && <span style={{ width: "30px", height: "38px" }}></span>}
                  {!(
                    role === "Project-Client" && item.creator._id === userId
                  ) && <span style={{ width: "30px", height: "38px" }}></span>}
                  {isExpand && (
                    <span style={{ width: "30px", height: "38px" }}></span>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          //////////////////////////// not expand
          <div>
            <div className={classes.header}>
              {/* it has condition */}
              {data[0] && <div className={classes.strip}>1</div>}
              <div className={classes.firstHeaderItem} style={{ flex: 10 }}>
                <div className={classes.firstHeaderSubItemNotExpand}>
                  {tempName}
                </div>
              </div>
              <div className={classes.headerActionItem}>
                {data[0] && (
                  <DateRangeIcon onClick={this.handleExpand} title="Versions" />
                )}
                <Link
                  to={
                    name === "PMDG Addendums"
                      ? routes.addendum + "/add"
                      : name === "PMDG Daily Log"
                      ? routes.addLog + "/add"
                      : name === "PMDG RFI’s"
                      ? routes.addRFI + "/add"
                      : routes.addSubmittal + "/add"
                  }
                >
                  <AddCircleOutlinedIcon
                    onClick={() => handleGoToEmptyForm(name)}
                    tooltip={
                      name === "PMDG Addendums"
                        ? "Add Addendum"
                        : name === "PMDG Daily Log"
                        ? "Add Log"
                        : name === "PMDG RFI’s"
                        ? "Add RFI"
                        : "Add Submittal"
                    }
                  />
                </Link>
                {!data[0] && (
                  <span style={{ width: "30px", height: "38px" }}></span>
                )}
                <span style={{ width: "30px", height: "38px" }}></span>
                <span style={{ width: "30px", height: "38px" }}></span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const Styles = {
  root: {
    borderRadius: "4px",
    marginTop: "5px",
    padding: "10px 0px"
  },
  header: {
    boxSizing: "border-box",
    display: "flex",
    width: "100%"
  },
  row: {
    boxSizing: "border-box",
    display: "flex",
    width: "100%"
  },
  headerItem: {
    boxSizing: "border-box",
    fontSize: "15px",
    backgroundColor: "white",
    padding: "10px 7px"
  },
  firstHeaderItem: {
    boxSizing: "border-box",
    fontSize: "15px",
    backgroundColor: "white"
  },
  firstHeaderSubItemNotExpand: {
    padding: "10px 7px 10px 7px",
    marginLeft: "20px"
  },
  firstHeaderSubItemExpand: {
    padding: "10px 7px 10px 7px",
    marginLeft: "25px"
  },
  headerActionItem: {
    boxSizing: "border-box",
    fontSize: "15px",
    backgroundColor: "white",
    padding: "0 7px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "5px",
    width: "150px",
    minWidth: "150px"
  },
  bodyItem: {
    borderTop: "1px solid #EBEBEB",
    boxSizing: "border-box",
    fontSize: "14px",
    backgroundColor: "white",
    padding: "10px 7px",
    color: Colors.grey
  },
  firstBodyItem: {
    boxSizing: "border-box",
    fontSize: "15px",
    backgroundColor: "white",
    color: Colors.grey
  },
  firstBodySubItem: {
    borderTop: "1px solid #EBEBEB",
    padding: "3px 7px 3px 7px",
    marginLeft: "20px",
    display: "flex",
    alignItems: "center"
  },
  bodyActionItem: {
    borderTop: "1px solid #EBEBEB",
    boxSizing: "border-box",
    backgroundColor: "white",
    padding: "0 7px",
    color: Colors.grey,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "5px",
    width: "150px",
    minWidth: "150px"
  },
  fileIcon: {
    marginRight: "2px",
    fontSize: "23px"
  },
  strip: {
    backgroundColor: "#D5D5D5",
    width: "5px",
    color: "transparent",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px"
  },
  assignedTo: {
    marginRight: "10px",
    display: "inline-block"
  }
};
const mapStateToProps = state => ({
  project: state.project,
  role: state.projects.project ? state.projects.project.role : undefined,
  position: state.authentication.user.role,
  userId: state.authentication.user._id
});
export default connect(mapStateToProps)(
  withStyles(Styles)(ConstructionDocumentTabItem)
);
