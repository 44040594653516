import React from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ListItem } from "./List-Item";
import IconButton from "@material-ui/core/IconButton";
import DeleteModal from "../Modal/DeleteModal";

import { userActions } from "../../actions";
import { userUserActions } from "../../userActions";
import { connect } from "react-redux";

//##############################################################################
// styles
//##############################################################################

const styles = theme => ({
  // ////////////////////////// table ////////////////////////////////////////
  tableContainer: {
    width: "100%",
    marginBottom: "15px"
  },
  tableHeader: {
    margin: "10px 0",
    borderBottom: "1px solid #e1e1e1"
  },
  tableHeaderItems: {
    padding: "8px 3px",
    color: "#155794",
    fontSize: "14px"
  },
  actionHolder: {
    display: "flex",
    justifyContent: "center"
  },
  // ////////////////////////// pagination ///////////////////////////////////
  pageNumbersHolder: {
    padding: "5px 0px",
    display: "flex",
    flex: 1,
    flexWrap: "no-wrap",
    overflowX: "auto"
  },
  nextButton: {
    padding: "5px 0px"
  },
  pagination: {
    padding: "0px"
  },
  borderTop: {
    borderTop: "1px solid #e1e1e1"
  },
  pageBtn: {
    fontSize: "14px",
    minWidth: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "15px",
    color: "#00000040",
    marginRight: "5px"
  },
  selectedPage: {
    color: "#155794",
    backgroundColor: "rgba(0, 0, 0, 0.03)"
  }
});

//##############################################################################
// component
//##############################################################################

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageCapacity: props.pageCapacity ? props.pageCapacity : 8,
      pageNumber: 0,
      DeleteModal: false,
      selectedUserId: "",
      selectedUserEmail: "",
      selectedUserFullName: "",
      openDeleteModal: false,
      selectedOptionForDelete: {}
    };
  }

  // #####[ handles filter ]####################################################

  filterUsers = (array = []) => {
    const { pageFilter } = this.props;
    const keyWordLowerCase = pageFilter.toLowerCase();
    const filteredArray = array.filter(item => {
      const roleSplitted = item.role
        ? `${item.role.split("-")[0]} ${item.role.split("-")[1]}}`
        : "";
      const phoneResult = item.user.phoneNumber
        ? item.user.phoneNumber.toString().includes(keyWordLowerCase)
        : false;
      const nameResult =
        item.user.firstName && item.user.lastName
          ? `${item.user.firstName} ${item.user.lastName}`
              .toLowerCase()
              .includes(keyWordLowerCase)
          : false;
      const emailResult = item.user.email
        ? item.user.email.toLowerCase().includes(keyWordLowerCase)
        : false;
      const role = roleSplitted.toLowerCase().includes(keyWordLowerCase);
      const roleRawData = item.role
        ? item.role.toLowerCase().includes(keyWordLowerCase)
        : false;
      const title = item.user.title
        ? item.user.title.toLowerCase().includes(keyWordLowerCase)
        : false;
      const company = item.user.company
        ? item.user.company.name.toLowerCase().includes(keyWordLowerCase)
        : false;
      return (
        emailResult ||
        nameResult ||
        phoneResult ||
        role ||
        roleRawData ||
        company ||
        title
      );
    });
    return filteredArray;
  };

  // ######[ Generate pagination Buttons ]####################################
  generatePageButtons = (length = 0, pageCapacity) => {
    const { pageNumber } = this.state;
    const { classes } = this.props;
    let numberOfPages = 0;
    const buttons = [];

    if (this.isInt(length / pageCapacity))
      numberOfPages = parseInt(length / pageCapacity);
    else numberOfPages = parseInt(length / pageCapacity) + 1;

    for (let i = 0; i < numberOfPages; i++)
      buttons.push(
        <IconButton
          key={i}
          className={
            i === pageNumber
              ? `${classes.selectedPage} ${classes.pageBtn}`
              : `${classes.pageBtn}`
          }
          onClick={this.handleChangePage(i)}
        >
          {i + 1}
        </IconButton>
      );

    return buttons;
  };

  isInt = n => {
    return n % 1 === 0;
  };

  // #####[ handles page change ]#############################################
  handleChangePage = number => () => {
    this.setState({ pageNumber: number });
  };

  handleNext = (length, pageCapacity) => () => {
    const NumberOfPages = Math.round((length + 2) / pageCapacity);
    if (this.state.pageNumber <= NumberOfPages - 2)
      this.setState({ pageNumber: this.state.pageNumber + 1 });
  };

  // #####[ handles pagination ]##############################################
  handlePagination = array => {
    const { pageCapacity, pageNumber } = this.state;
    return array.slice(
      pageNumber * pageCapacity,
      pageNumber * pageCapacity + pageCapacity
    );
  };

  // #######################################################################################
  // ####################              Dialog: Delete              ####################
  // #######################################################################################

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
          openDeleteModal: true,
          selectedOptionForDelete
        })
      : this.setState({
          openDeleteModal: false
        });
  };

  // #####[  handle edit user ]###############################################

  handleEditUser = data => {
    this.props.handleEdit(data);
  };

  // #####[  Render ]##########################################################

  render() {
    const { classes, data = [], role } = this.props;
    const filteredUsers = this.filterUsers(data);
    const usersInPage = this.handlePagination(filteredUsers);
    const minHeight = role ? "calc(100vh - 310px)" : "calc(100vh - 250px)";
    return (
      <Grid container>
        <Grid item xs={12}>
          {/* //////////////////// Table //////////////////////////////////*/}

          <Grid container className={classes.tableHeader}>
            <div style={{ flex: 3 }} className={classes.tableHeaderItems}>
              Full Name
            </div>
            <div style={{ flex: 4 }} className={classes.tableHeaderItems}>
              E-mail
            </div>
            <div style={{ flex: 2 }} className={classes.tableHeaderItems}>
              Phone Number
            </div>
            <div style={{ flex: 2 }} className={classes.tableHeaderItems}>
              Company
            </div>
            <div style={{ flex: 2 }} className={classes.tableHeaderItems}>
              Title
            </div>
            <div style={{ flex: 3 }} className={classes.tableHeaderItems}>
              Role
            </div>
            {role ? (
              <div
                style={{ width: "75px" }}
                className={`${classes.tableHeaderItems} ${classes.actionHolder}`}
              >
                Action
              </div>
            ) : (
              ""
            )}
          </Grid>
          <div style={{ minHeight }} className={classes.tableContainer}>
            {usersInPage.map(project => (
              <ListItem
                editable={role}
                removable={role}
                key={project._id}
                handleDeleteModal={this.handleDeleteModal}
                handleEditUser={this.handleEditUser}
                project={project}
              />
            ))}
          </div>

          {/* //////////////////// pagination buttons //////////////////// */}

          <Grid container className={classes.pagination}>
            <Grid container className={classes.borderTop}>
              <div className={classes.nextButton}>
                <IconButton
                  onClick={this.handleNext(
                    filteredUsers.length,
                    this.state.pageCapacity
                  )}
                  className={classes.pageBtn}
                >
                  NEXT
                </IconButton>
              </div>
              <div className={classes.pageNumbersHolder}>
                {this.generatePageButtons(
                  filteredUsers.length,
                  this.state.pageCapacity
                )}
              </div>
            </Grid>
          </Grid>

          {/* ////////////////////////////////////////////////////////////// */}

          {/* //////////////////// Delete User Dialog ///////////////////////// */}
          <DeleteModal
            open={this.state.openDeleteModal}
            handleDeleteModal={this.handleDeleteModal}
            selectedOptionForDelete={{
              userId: this.state.selectedOptionForDelete._id
            }}
            adminAction={userActions.deleteProjectRole}
            userAction={userUserActions.deleteProjectRole}
            //optional
            modalTitle={"Unassign User"}
            messageText={`Are you sure you 
              want to unassign 
              ${this.state.selectedOptionForDelete.firstName} 
              ${this.state.selectedOptionForDelete.lastName} 
              from this project?`}
            submitButtonText="Unassign"
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.projects.users,
    role:
      state.projects.project && state.projects.project.role !== "Project-Client"
        ? true
        : false,
    pageFilter: state.filters.pageFilter
  };
};

//##############################################################################
// connect material classes to the component
//##############################################################################

const UserListStylesConnected = connect(mapStateToProps)(
  withStyles(styles)(UserList)
);

//##############################################################################
// export
//##############################################################################

export { UserListStylesConnected as UserList };
