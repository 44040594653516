import React from "react";
import { withStyles } from "@material-ui/core";
import { CancelOutlined } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Colors } from "../../styles/colors";

const styles = {
  iconButton: {
    padding: "3px !important",
    color: Colors.orange
  }
};

export const DeleteIcon = React.memo(
  withStyles(styles)(({ classes, onClick, style = {}, title = "Delete" }) => (
    <Tooltip title={title}>
      <IconButton
        onClick={onClick}
        className={classes.iconButton}
        component="span"
        style={style}
      >
        <CancelOutlined style={style} />
      </IconButton>
    </Tooltip>
  ))
);
