// React //
import React from "react";

// Redux //
import { commentActions } from "../../actions";
import { commentUserActions } from "../../userActions";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// Components //
import AttachmentElement from "../Attachments/AttachmentElement";
import AvaterImage from "../img/noteTabAvatar.png";
import CommentItem from "../Comments/CommentItem";
import AddAttachmentsButton from "../Buttons/AddAttachment/AddAttachmentsButton";
import AddNewComment from "../Comments/AddNewComment";
import { CancelButton, SaveButton } from "../Buttons";
import { EditIcon, DeleteIcon, ArrowComment, ArrowAttachment } from "../Icons";
import ThemeContext from "../ContextApi/Context";

// Reusable Functions //
import TimeMonthDayFullTime from "../reusebleFunctions/TimeMonthDayFullTime";
// Styles //
import { Colors } from "../../styles/colors";

import { API } from "../../constants";

class NoteItem extends React.PureComponent {
  static contextType = ThemeContext;
  state = {
    editMode: false,
    text: this.props.note.text,
    note: this.props.note,
    isMaximizeForAttachments: false,
    isMaximizeForComments: false,
    isAddNewComment: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.note !== this.props.note)
      this.setState(() => ({
        note: this.props.note
      }));

    if (this.state.note.numberOfComments === 0) {
      this.setState({
        isMaximizeForComments: false
      });
    }

    if (this.state.note.attachments.length === 0) {
      this.setState({
        isMaximizeForAttachments: false
      });
    }
  }

  handleAttachmentsMaximizer = () => {
    this.setState(prevState => ({
      isMaximizeForAttachments: !prevState.isMaximizeForAttachments,
      isMaximizeForComments: false,
      editMode: false,
      text: "",
      isChangeHappend: false
    }));
  };

  handleCommentsMaximizer = () => {
    const { dispatch, role } = this.props;
    const {
      note: { _id: noteId }
    } = this.state;
    const projectId = this.context;

    this.setState(prevState => ({
      isMaximizeForAttachments: false,
      isMaximizeForComments: !prevState.isMaximizeForComments,
      editMode: false,
      text: "",
      isChangeHappend: false
    }));
    role === "adminFlag"
      ? dispatch(commentActions.getComments({ noteId }))
      : dispatch(commentUserActions.getComments({ projectId, noteId }));
  };

  handleEdit = () => {
    this.setState(prevState => ({
      editMode: true,
      text: prevState.note.text,
      isMaximizeForComments: false,
      isAddNewComment: false
    }));
  };

  handleDelete = () => {
    this.props.handleDeleteModal(this.props.note);
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
      isChangeHappend: true
    });
  };

  handleBrowseFile = attachment => {
    this.setState({
      attachment
    });
  };

  handleSubmit = () => {
    const { attachment, isChangeHappend, note, text } = this.state;
    const { handleEditNote, handleAddAttachment } = this.props;
    const projectId = this.context;

    isChangeHappend && handleEditNote({ noteId: note._id, text, projectId });

    if (attachment) {
      let document = new FormData();
      document.append("projectId", projectId);
      document.append("noteId", note._id);
      document.append("document", attachment);
      handleAddAttachment(document);
    }

    this.setState({
      text: "",
      attachment: "",
      isChangeHappend: false,
      editMode: false
    });
  };

  handleCancelChange = () => {
    this.setState({
      editMode: false,
      text: "",
      attachment: ""
    });
  };

  //   handleDoubleClick = () => {
  //     !this.state.editMode &&
  //       this.props.role !== "Project-Client" &&
  //       this.setState(prevState => ({
  //         editMode: "true",
  //         text: prevState.note.text
  //       }));
  //   };

  handleAddCommentButton = () => {
    this.setState(prevState => ({
      isAddNewComment: !prevState.isAddNewComment
    }));
  };

  handleOpenCommentsInAddingNewComment = () => {
    this.setState({
      isMaximizeForComments: true
    });
  };

  // // for comment modal
  // handlerInfoModal = () => {
  //     this.props.handlerInfoModal(true, this.props.note._id)
  // }

  render() {
    const { classes, role, comments, widget } = this.props;
    const {
      editMode,
      text,
      note,
      isMaximizeForAttachments,
      isMaximizeForComments,
      isAddNewComment
    } = this.state;
    const addedDate = TimeMonthDayFullTime(note.createDate);
    return (
      <>
        <Grid
          container
          className={classes.noteItemWrapper}
          style={!widget ? { paddingBottom: "10px" } : {}}
        >
          <Grid container>
            <Grid
              item
              xs={1}
              className={classes.logoHolder}
              style={
                widget
                  ? {
                      width: "65px",
                      height: "65px",
                      maxWidth: "65px",
                      minWidth: "65px"
                    }
                  : {}
              }
            >
              <img
                src={
                  note.owner.avatar
                    ? `${API}/${note.owner.avatar}`
                    : AvaterImage
                }
                alt="profileImage"
                className={classes.logoImage}
              />
            </Grid>

            <Grid item xs className={classes.noteTextAndAttachmentWrapper}>
              {editMode ? (
                <textarea
                  rows={2}
                  className={classes.textarea}
                  value={text}
                  onChange={this.handleChange}
                  name="text"
                />
              ) : (
                <>
                  <p className={classes.name}>
                    {note.owner.firstName} {note.owner.lastName}
                  </p>
                  <p className={classes.noteText}>{note.text}</p>
                </>
              )}
              <div
                className={classes.attachmentWrapper}
                style={
                  isMaximizeForAttachments ? { height: "auto" } : { height: 0 }
                }
              >
                {note.attachments.map((attachment, index) => (
                  <AttachmentElement
                    key={index}
                    attachment={attachment}
                    editMode={editMode}
                    className={classes.AttachmentElement}
                    handleDeleteModal={this.props.handleDeleteModal}
                  />
                ))}
              </div>
            </Grid>
            {widget && (
              <Grid
                item
                xs={3}
                className={classes.widgetTime}
                container
                alignItems="flex-start"
                justify="flex-end"
              >
                {addedDate}
              </Grid>
            )}
            <Grid
              item
              xs={widget ? 1 : 2}
              className={classes.actionsAndTimeWrapper}
            >
              {editMode ? (
                <Grid item xs className={classes.actionEditWrapper}>
                  <AddAttachmentsButton
                    handleBrowseFile={this.handleBrowseFile}
                  />
                  <SaveButton onClick={this.handleSubmit} />
                  <CancelButton onClick={this.handleCancelChange} />
                </Grid>
              ) : (
                <>
                  <Grid item className={classes.actionWrapper}>
                    {/* {note.numberOfComments > 0 && (
                        <span className={classes.numberOfComments}>
                          {note.numberOfComments} Comment
                        {note.numberOfComments > 1 && "s"}
                        </span>
                      )} */}
                    {note.numberOfComments > 0 && (
                      <ArrowComment
                        onClick={this.handleCommentsMaximizer}
                        isMaximizeForComments={isMaximizeForComments}
                      />
                    )}
                    {/* {note.attachments[0] && ( */}
                    {note.attachments[0] && !widget && (
                      <ArrowAttachment
                        onClick={this.handleAttachmentsMaximizer}
                        isMaximizeForAttachments={isMaximizeForAttachments}
                      />
                    )}
                    {!widget && role !== "Project-Client" && (
                      <>
                        <EditIcon onClick={this.handleEdit} />
                        <DeleteIcon onClick={this.handleDelete} />
                      </>
                    )}
                    {/* this is the comment modal */}
                    {/* <Tooltip title="Info">
                                    <IconButton className={classes.iconWrapper} onClick={this.handlerInfoModal} component="span">
                                        <InfoOutlinedIcon className={classes.infoIconWrapper} />
                                    </IconButton>
                                </Tooltip> */}
                  </Grid>
                  {!widget &&
                    !isMaximizeForComments &&
                    !isMaximizeForAttachments && (
                      <Grid item className={classes.commentButtonWrapper}>
                        <Button
                          className={classes.commentButton}
                          onClick={this.handleAddCommentButton}
                        >
                          Add Comment
                        </Button>
                      </Grid>
                    )}
                  {!widget && (
                    <Grid item className={classes.time}>
                      {addedDate}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {isAddNewComment && (
            <AddNewComment
              handleAddCommentButton={this.handleAddCommentButton}
              isAddNewComment={isAddNewComment}
              noteId={note._id}
              handleOpenCommentsInAddingNewComment={
                this.handleOpenCommentsInAddingNewComment
              }
            />
          )}

          {/* comments part */}
          {isMaximizeForComments && comments[0] && (
            <Grid container>
              {comments
                .filter(
                  comment => comment.note === note._id && !comment.parentComment
                )
                .map((comment, index) => (
                  <CommentItem
                    key={index}
                    noteId={note._id}
                    comment={comment}
                    allComments={comments}
                    handleAddCommentButton={this.handleAddCommentButton}
                    dynamicMargin={30}
                    role={role}
                    widget={widget}
                  />
                ))}
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}
const Styles = {
  noteItemWrapper: {
    backgroundColor: "white",
    margin: "5px 0"
    // paddingBottom: "10px"
  },
  logoHolder: {
    maxWidth: "90px",
    width: "90px",
    height: "90px",
    minWidth: "90px",
    borderRadius: "100%",
    overflow: "visible",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginTop: "10px",
    marginLeft: "10px"
  },
  logoImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
    height: "100%",
    width: "100%",
    borderRadius: "100%"
  },
  image: {
    width: "70px"
  },
  noteTextAndAttachmentWrapper: {
    // backgroundColor: 'blue',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingRight: "10px",
    marginTop: "17px"
  },
  name: {
    color: Colors.darkBlue,
    fontWeight: "500",
    margin: "7px 5px 2PX"
  },
  noteText: {
    margin: "2PX 5px 10px",
    fontSize: "14px"
  },
  attachmentWrapper: {
    margin: "10px 0",
    overflow: "hidden"
  },
  actionsAndTimeWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingRight: "15px"
  },
  iconWrapper: {
    padding: "3px!important"
  },

  // maximizeIcon: {
  //     transition: '300ms',
  //     transform: 'rotate(180deg)'
  // },
  attachmentIcon: {
    color: Colors.lightGrey,
    fontSize: "22px",
    transform: "rotate(45deg)"
  },
  editIconWrapper: {
    color: Colors.lightGreen
  },
  cancelIconWrapper: {
    color: Colors.orange
  },
  infoIconWrapper: {
    color: Colors.yellow
  },
  time: {
    color: Colors.grey,
    fontSize: "13px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  widgetTime: {
    color: Colors.grey,
    fontSize: "12px",
    marginTop: "20px"
  },
  textarea: {
    width: "99%",
    border: `1px solid ${Colors.lightGrey}`,
    color: Colors.grey,
    fontSize: "14px",
    marginLeft: "5px",
    height: "auto"
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "17px"
  },
  actionEditWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "17px"
  },
  submitButton: {
    fontWeight: 900,
    fontSize: "10px",
    height: "38px",
    width: "70px",
    // box-sizing: border-box;
    borderRadius: "5px",
    backgroundColor: "#4e9e63",
    color: "#ffffff",
    display: "inline-block",
    border: "none",
    cursor: "pointer",
    margin: "2px",
    "&:disabled": {
      backgroundColor: Colors.grey,
      cursor: "default"
    }
  },
  cancelButton: {
    fontWeight: 900,
    fontSize: "10px",
    height: "38px",
    width: "70px",
    // box-sizing: border-box;
    borderRadius: "5px",
    backgroundColor: "#AFADAD",
    color: "#ffffff",
    display: "inline-block",
    border: "none",
    cursor: "pointer",
    margin: "2px"
  },
  commentButtonWrapper: {
    padding: "5px 0"
  },
  commentButton: {
    color: Colors.grey,
    borderRadius: "15px",
    border: `1px solid ${Colors.darkenThanLightGrey}`,
    fontSize: "11px",
    padding: "3px 8px!important"
  },
  numberOfComments: {
    color: Colors.darkBlue,
    fontSize: "13px",
    paddingBottom: "5px",
    marginRight: "5px"
  }
};

export default withStyles(Styles)(NoteItem);
