import React from "react";
import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import { projectRoles } from "../../constants";
import Tooltip from '@material-ui/core/Tooltip';

// #####[ icons ]##############################################################
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

// ############################################################################
// styles
// ############################################################################

const styles = theme => ({
  root: {
    display: "flex"
  },
  tableItem: {
    padding: "3px"
  },
  tableItemInside: {
    border: "1px solid #00000017",
    backgroundColor: "#fff",
    borderRadius: "2px",
    minHeight: "42px",
    height: "100%",
    boxSizing: "border-box",
    fontSize: "13px",
    padding: "12px 10px 12px 18px",
    color: "#000000b5",
    [theme.breakpoints.down("md")]: {
      padding: "12px 5px"
    }
  },
  iconButton: {
    padding: "4px"
  },
  editIcon: {
    color: "#30b542e0"
  },
  deleteIcon: {
    color: "#ff4b00cc"
  },
  tableInsideActions: {
    border: "1px solid #00000017",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    backgroundColor: "#fff",
    borderRadius: "2px",
    padding: "2px 5px",
    minHeight: "42px",
    height: "100%"
  },
  inputs: {
    width: "100%",
    height: "42px",
    fontSize: "13px",
    color: "#000000b5"
  },
  saveButton: {
    backgroundColor: "#58b370",
    width: "75px",
    height: "42px",
    fontSize: "0.7rem",
    color: "#fff",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#4a965e"
    }
  },
  cancelButton: {
    backgroundColor: "#9e9e9e",
    width: "75px",
    height: "42px",
    fontSize: "0.7rem",
    color: "#fff",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#9e9e9e"
    }
  },
  selectAdd: {
    height: "42px",
    width: "100%",
    "& > div": {
      minHeight: "42px",
    }
  },
  select: {
    backgroundColor: 'red',
    color: "red"
  }
});

// ############################################################################
// ############################################################################
// component
// ############################################################################

class ListItem extends React.PureComponent {
  constructor(props) {
    super(props);
    const { role } = this.props.project;
    this.state = {
      firstName: this.props.project.user.firstName,
      lastName: this.props.project.user.lastName,
      email: this.props.project.user.email,
      company: this.props.project.user.company ? this.props.project.user.company.name : '',
      title: this.props.project.user.title,
      isAdmin: this.props.project.user.role === 'admin',
      phoneNumber: this.props.project.user.phoneNumber,
      role: { value: this.props.project.role, label: `${role.split("-")[0]} ${role.split("-")[1]}` },
      editMode: false,
    };
  }
  // ######[ handle edit mode ]################################################
  handleEditMode = () => {
    const { role } = this.props.project;
    this.setState({ editMode: !this.state.editMode });
    this.setState({ role: { value: role, label: `${role.split("-")[0]} ${role.split("-")[1]}` } })
  };
  // ######[ handle change inputs ]############################################
  handleSelectChange = (option, event) => {
    this.setState({
      [event.name]: option
    });
  };

  // ######[ handle edit user ]################################################
  handleEditUser = () => {
    const data = {
      projectId: this.props.project.project,
      userId: this.props.project.user._id,
      role: this.state.role.value
    }
    this.props.handleEditUser(data)
    this.setState({ editMode: false })
  };
  // ######[ generate the list of roles ]######################################
  decorateRoles = () => {
    const roles = [];
    for (const role in projectRoles) {
      roles.push({ value: role, label: projectRoles[role] });
    }
    return roles;
  };
  // ######[ render ]##########################################################
  render() {
    const { classes, handleDeleteModal } = this.props;
    const { firstName, lastName, email, phoneNumber, editMode, company, title, role, isAdmin } = this.state;
    return (
      <div className={classes.root}>
        <div style={{ flex: 3 }} className={classes.tableItem}>
          <div className={classes.tableItemInside}>{`${firstName} ${lastName}`}</div>
        </div>
        <div style={{ flex: 4 }} className={classes.tableItem}>
          <div className={classes.tableItemInside}>{email}</div>
        </div>
        <div style={{ flex: 2 }} className={classes.tableItem}>
          <div className={classes.tableItemInside}>{phoneNumber}</div>
        </div>
        <div style={{ flex: 2 }} className={classes.tableItem}>
          <div className={classes.tableItemInside}>{company}</div>
        </div>
        <div style={{ flex: 2 }} className={classes.tableItem}>
          <div className={classes.tableItemInside}>{title}</div>
        </div>
        <div style={{ flex: 3, width: "0px" }} className={classes.tableItem}>
          {editMode ? (
            <div className={classes.root}>
              <Select
                options={this.decorateRoles()}
                name={"role"}
                onChange={this.handleSelectChange}
                className={classes.selectAdd}
                style={{ control: { height: "42px" } }}
                required
                placeholder="Select Role"
                maxMenuHeight={120}
                value={role}
              />
              <div style={{ width: "75px", marginLeft: "6px" }}>
                <Button className={classes.cancelButton} onClick={this.handleEditMode}>
                  {"Cancel"}
                </Button>
              </div>
            </div>
          ) :
            (<div className={classes.tableItemInside}>{role.label}</div>)
          }
        </div>
        {/* ################################################################### */}
        {/* all the action buttons such as edit, delete, save and cancel        */}
        {editMode ? (
          <div style={{ width: "75px" }} className={classes.tableItem}>
            <Button className={classes.saveButton} onClick={this.handleEditUser}>
              {"save"}
            </Button>
          </div>
        ) : (
            this.props.editable || this.props.removable ? (
              <div style={{ width: "75px" }} className={classes.tableItem}>
                <div className={classes.tableInsideActions}>
                  {this.props.editable ? (
                    <Tooltip title="Edit User">
                      <IconButton
                        onClick={this.handleEditMode}
                        className={`${classes.iconButton} ${classes.editIcon}`}
                        aria-label="Delete"
                      >
                        <EditOutlinedIcon className={classes.editIcon} />
                      </IconButton>
                    </Tooltip>
                  ) : ""}
                  {this.props.removable && !isAdmin ? (
                    <Tooltip title="Delete User">
                      <IconButton
                        onClick={() => handleDeleteModal(this.props.project.user)}
                        className={`${classes.iconButton} ${classes.deleteIcon}`}
                        aria-label="edit"
                      >
                        <CancelOutlinedIcon className={classes.deleteIcon} />
                      </IconButton>
                    </Tooltip>
                  ) : <div style={{ width: '32px' }} />}
                </div>
              </div>
            ) : "")}
      </div>
    );
  }
}

// ############################################################################
// map styles to the component
// ############################################################################
const UsersDataWithStyles = withStyles(styles)(ListItem);

// ############################################################################
// export
// ############################################################################
export { UsersDataWithStyles as ListItem };


