export default function delayTime(dueDate) {
  let delay = Math.abs(dueDate - new Date());
  if (dueDate - new Date() < 0) {
    const month = parseInt(delay / 2592000000, 10);
    delay = delay - month * 2592000000;
    const week = parseInt(delay / 604800000, 10);
    delay = delay - week * 604800000;
    const day = parseInt(delay / 86400000, 10);
    delay = delay - day * 86400000;

    const monthString =
      month > 0 ? (month > 1 ? `${month} Months` : `${month} Month`) : ``;
    const weekString =
      week > 0 ? (week > 1 ? `${week} Weeks` : `${week} Week`) : ``;
    const dayString = day > 0 ? (day > 1 ? `${day} Days` : `${day} Day`) : ``;
    const output = `${monthString}${
      month > 0 ? (week > 0 ? (day > 0 ? `, ` : ` and `) : ``) : ``
    } ${weekString} ${
      (week > 0 || month > 0) && day > 0 ? `and ` : ``
    } ${dayString}`.trim();

    return output === `` ? "today" : output;
  } else return `No Delay`;
}
