import React from "react";
import { connect } from "react-redux";
import {
  projectActions,
  costActions,
  issueActions,
  taskActions,
  userActions,
  clientActions,
  filterActions,
  noteActions,
} from "../../../actions";
import { ProjectInfo, GanttChartWidget, ProjectTreeChart } from "../../";
import ThemeContext from "../../ContextApi/Context";

import { NoteWidget } from "../../dashboardCards/NoteWidget";
import { IssueWidget } from "../../dashboardCards/IssueWidget";
import { CircularWaiting } from "../../";
// #####[ MATERIAL UI ]#########################################################
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
// #####[ components ]##########################################################
import { Card } from "../../dashboardCards/Card";
// #############################################################################
// Component
// #############################################################################

const styles = (theme) => ({
  cardsContainerWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  cardsContainer: {
    marginTop: "20px",
  },
  cardsSubContainer: {
    marginTop: "10px",
  },
  container: {
    width: "50%",
  },
  chart: {
    padding: "20px 0px",
  },
  absoluteLoaderContainer: {
    position: "absolute",
    height: "calc(100vh - 130px)",
    width: "98%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    paddingRight: "35px",
    paddingBottom: "50px",
    zIndex: "10",
  },
});

// #############################################################################
// component
// #############################################################################

class AdminProjectDashboard extends React.PureComponent {
  state = { selectedProject: null };
  static contextType = ThemeContext;
  // #########################################################################
  // component did mount
  // #########################################################################

  componentDidMount() {
    const projectId = this.context;
    const { dispatch } = this.props;
    dispatch(projectActions.getProject({ projectId }));
    dispatch(costActions.getProjectCosts({ projectId }));
    dispatch(projectActions.getProjectUsers({ projectId }));
    dispatch(issueActions.getProjectIssues({ projectId }));
    dispatch(taskActions.getProjectTasks({ projectId }));
    dispatch(projectActions.getAllProjects());
    dispatch(userActions.getAllUsers());
    dispatch(clientActions.getAllCompanies());
    dispatch(noteActions.getProjectNotes({ projectId }));
    this.setState({
      selectedProject: projectId,
    });
    dispatch(filterActions.pageFilterEdit(""));
  }

  // #########################################################################
  // handle edit project
  // #########################################################################

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;
    const projectId = this.context;
    if (this.state.selectedProject !== projectId) {
      dispatch(projectActions.getProject({ projectId }));
      dispatch(projectActions.getAllArchitects());
      dispatch(projectActions.getAllProjects());
      dispatch(costActions.getProjectCosts({ projectId }));
      dispatch(projectActions.getProjectUsers({ projectId }));
      dispatch(taskActions.getProjectTasks({ projectId }));
      dispatch(issueActions.getProjectIssues({ projectId }));
      dispatch(noteActions.getProjectNotes({ projectId }));

      this.setState({
        selectedProject: projectId,
      });
    }
  }

  // #########################################################################
  // handle edit project
  // #########################################################################

  editProject = (data) => {
    this.props.dispatch(projectActions.editProject(data));
  };

  // #########################################################################
  // render component
  // #########################################################################
  render() {
    const {
      project = {},
      costs = [],
      classes = {},
      allProjects = [],
      tasks = [],
      projectId,
      role,
      issueRequest,
      clientRequest,
      projectRequest,
    } = this.props;

    return (
      <React.Fragment>
        {!(issueRequest || clientRequest || projectRequest) ? (
          <>
            <ProjectInfo deletable editProject={this.editProject} />
            <div className={classes.chart}>
              <ProjectTreeChart
                adminRole
                selectedProject={projectId}
                projects={allProjects}
              />
            </div>

            <div className={classes.cardsContainerWrapper}>
              <Grid className={classes.cardsContainer} container spacing={16}>
                <Grid item xs={6} className={classes.cardsSubContainer}>
                  <IssueWidget />
                </Grid>
                <Grid item xs={6} className={classes.cardsSubContainer}>
                  <NoteWidget />
                </Grid>
              </Grid>
              <Grid className={classes.cardsContainer} container spacing={16}>
                <Grid item xs={6} className={classes.cardsSubContainer}>
                  <GanttChartWidget
                    tasks={tasks}
                    project={project}
                    role={role}
                  />
                </Grid>
                <Grid item xs={6} className={classes.cardsSubContainer}>
                  <Card hasTitle={"Project Costs"} data={costs} role={role} />
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <CircularWaiting />
        )}
      </React.Fragment>
    );
  }
}

// #############################################################################
// map REDUX state to props
// #############################################################################

const mapStateToProps = (state) => {
  return {
    allProjects: state.projects.projects,
    project: state.projects.project,
    issues: state.issues,
    costs: state.costs.costs,
    projectUsers: state.projects.users,
    tasks: state.tasks.tasks,
    role: state.projects.project ? state.projects.project.role : undefined,
    issueRequest: state.progressBar.issueRequest,
    clientRequest: state.progressBar.clientRequest,
    projectRequest: state.progressBar.projectRequest,
  };
};
const connectedAdminProjectDashboard = connect(mapStateToProps)(
  AdminProjectDashboard
);
const AdminProjectDashboardWithStyles = withStyles(styles)(
  connectedAdminProjectDashboard
);

// #############################################################################
// Dialog: Delete Cost
// #############################################################################

export { AdminProjectDashboardWithStyles as AdminProjectDashboard };
