// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import {
  projectActions,
  constructionActions,
  clientActions
} from "../../actions";
import {
  projectUserActions,
  constructionUserActions,
  clientUserActions
} from "../../userActions";

// Material Ui //
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
// Components //
import Creatable from "react-select/lib/Creatable";
import Select from "react-select";
import { ReactInput } from "../";
import PDatePicker from "components/DatePicker/DatePicker";
import { DownloadIcon } from "../Icons";

// Reusable Functions //
import TimeMonthDayYear from "../reusebleFunctions/TimeMonthDayYear";

//icons
import WarningIcon from "@material-ui/icons/Warning";

// Styles //
import { Colors } from "../../styles/colors";
import { history } from "../../helpers";
class PMDGRFIForm extends React.PureComponent {
  state = {
    values: {},
    formName: "",
    categories: "",
    fields: [{ title: "", description: "" }],
    address: "",
    addendumNO: "addendumNO",
    assignedTo: "",
    assignedToAttn: "",
    assignedToEmail: "",
    cc: "",
    ccAttn: "",
    ccEmail: "",
    subject: "",
    question: "",
    answer: "",
    dateRequired: "",
    dateAnswered: "",
    files: [],
    deletedFormAttachments: []
  };

  componentDidMount() {
    const { dispatch, match, role } = this.props;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];
    const formId = url[url.length - 1];
    const formName =
      url[url.length - 2] === "addendum"
        ? "PMDG Addendum Form"
        : url[url.length - 2] === "addDailyLog"
        ? "PMDG Daily Log Form"
        : url[url.length - 2] === "addRFI"
        ? "PMDG RFI Form"
        : url[url.length - 2] === "addShopDrawingsSubmittal"
        ? "PMDG Shop Drawings Submittal Log Form"
        : "";
    if (role === "admin") {
      dispatch(projectActions.getProjectUsers({ projectId }));
      dispatch(clientActions.getAllCompanies());
      url[url.length - 1] !== "add" &&
        dispatch(constructionActions.getForm({ formId, formName }));
    } else {
      dispatch(projectUserActions.getProjectUsers({ projectId }));
      dispatch(clientUserActions.getAllCompanies());
      url[url.length - 1] !== "add" &&
        dispatch(
          constructionUserActions.getForm({ formId, formName, projectId })
        );
    }
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  componentDidUpdate(prevProps) {
    const { addresses, formData, match } = this.props;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];
    if (prevProps.addresses !== addresses) {
      this.setState({
        addresses: addresses
      });
    }
    if (prevProps.formData !== formData) {
      prevProps.formData &&
        !this.isEmpty(formData) &&
        prevProps.formData &&
        !this.isEmpty(formData) &&
        this.setState({
          address: { value: formData.address, label: formData.address },
          assignedTo: formData.assignedTo.map(data => ({
            value: data.firstName + " " + data.lastName,
            label: data.firstName + " " + data.lastName,
            _id: data._id
          })),
          projectId,
          assignedToAttn: formData.assignedToAttn,
          assignedToEmail: formData.assignedTo.map(data => ({
            value: data.email,
            label: data.email,
            _id: data._id
          })),
          cc: formData.cc
            .filter(data => data !== null)
            .map(data => ({
              value: data.firstName + " " + data.lastName,
              label: data.firstName + " " + data.lastName,
              _id: data._id
            })),
          ccAttn: formData.ccAttn,
          ccEmail: formData.cc
            .filter(data => data !== null)
            .map(data => ({
              value: data.email,
              label: data.email,
              _id: data._id
            })),
          subject: formData.subject,
          question: formData.question,
          answer: formData.answer,
          addresses: Array.from(
            new Set([formData.address.value, ...addresses])
          ),
          dateRequired: formData.dateRequired,
          dateAnswered: formData.dateAnswered ? formData.dateAnswered : "",
          files: formData.attachments
        });
    }
  }

  handleGoToForm = values => {
    this.setState({
      values
    });
  };
  handleGoToEmptyForm = formName => {
    this.setState({
      formName
    });
  };
  handleCategoryChange = address => {
    this.setState({
      address
    });
  };
  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });
  };

  handleAddNewRow = () => {
    this.setState(prevState => ({
      fields: [...prevState.fields, { title: "", description: "" }]
    }));
  };

  handleSave = e => {
    e.preventDefault();
    const {
      handleSave,
      addresses,
      dispatch,
      formData,
      role,
      match
    } = this.props;
    const {
      assignedTo,
      address,
      assignedToAttn,
      cc,
      ccAttn,
      subject,
      question,
      answer,
      dateRequired,
      files,
      deletedFormAttachments
    } = this.state;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];

    const document = new FormData();
    document.append("projectId", projectId);
    document.append("address", address.value);
    document.append(
      "assignedTo",
      assignedTo.map(person => person._id)
    );
    document.append("assignedToAttn", assignedToAttn);
    document.append(
      "cc",
      cc.map(person => person._id)
    );
    document.append("ccAttn", ccAttn);
    document.append("subject", subject);
    document.append("question", question);
    document.append("answer", answer);
    const isExist = addresses.find(item => item === address.value);
    const finalAddress = isExist ? addresses : [address.value, ...addresses];

    document.append("addresses", JSON.stringify(finalAddress));

    deletedFormAttachments[0] &&
      document.append(
        "deletedFormAttachments",
        JSON.stringify(deletedFormAttachments)
      );

    if (url[url.length - 1] !== "add") {
      document.append("formId", formData._id);
      document.append("formName", formData.name);
      document.append(
        "dateAnswered",
        JSON.stringify(answer) !== JSON.stringify(formData.answer)
          ? new Date()
          : undefined
      );
      for (var i = 0; i < files.length; i++) {
        if (!files[i]._id) document.append("documents", files[i]);
      }
      role === "admin"
        ? dispatch(constructionActions.editForm(document))
        : dispatch(constructionUserActions.editForm(document));
      handleSave();
      history.goBack();
    } else {
      document.append("dateRequired", dateRequired);
      for (i = 0; i < files.length; i++) {
        if (!files[i]._id) document.append("documents", files[i]);
      }
      role === "admin"
        ? dispatch(constructionActions.addPMDGRFIForm(document))
        : dispatch(constructionUserActions.addPMDGRFIForm(document));
      handleSave();
      history.goBack();
    }
  };

  handleSelectChange = name => values => {
    name === "assignedTo" &&
      this.setState({
        [name]: values,
        assignedToEmail: values.map(value => ({
          ...value,
          value: value.email,
          label: value.email
        }))
      });

    name === "assignedToEmail" &&
      this.setState({
        [name]: values,
        assignedTo: values.map(value => ({
          ...value,
          value: value.firstName + " " + value.lastName,
          label: value.firstName + " " + value.lastName
        }))
      });

    name === "cc" &&
      this.setState({
        [name]: values,
        ccEmail: values.map(value => ({
          ...value,
          value: value.email,
          label: value.email
        }))
      });

    name === "ccEmail" &&
      this.setState({
        [name]: values,
        cc: values.map(value => ({
          ...value,
          value: value.firstName + " " + value.lastName,
          label: value.firstName + " " + value.lastName
        }))
      });
  };

  handleInputFile = ({ target: { files } }) => {
    this.setState(prevState => ({
      files: [...prevState.files, ...[...files].map(file => file)]
    }));
  };

  handleRemoveAttachment = index => () => {
    this.setState(prevState => ({
      files: prevState.files.filter((file, index2) => index !== index2),
      deletedFormAttachments: prevState.files[index]._id
        ? [...prevState.deletedFormAttachments, prevState.files[index]._id]
        : prevState.deletedFormAttachments
    }));
  };

  render() {
    const {
      classes,
      users,
      addresses = [],
      project,
      creator = {},
      companies
    } = this.props;
    const {
      address,
      assignedTo,
      assignedToAttn,
      assignedToEmail,
      cc,
      ccAttn,
      ccEmail,
      subject,
      question,
      answer,
      dateRequired,
      dateAnswered,
      files
    } = this.state;
    const company =
      companies &&
      project &&
      companies.find(com => com._id === project.client._id);
    return (
      <form onSubmit={this.handleSave} className={classes.container}>
        <div className={classes.topItemsContainer}>
          <div className={classes.title}>Request For Information</div>
          <div className={classes.address}>
            <span style={{ position: "relative" }}>
              <Creatable
                onChange={this.handleCategoryChange}
                value={address}
                placeholder="Click To Select Office Address"
                className={classes.addressCreate}
                options={addresses.map(address => ({
                  label: address,
                  value: address
                }))}
              />
              <input
                value={address}
                style={{
                  position: "absolute",
                  top: 30,
                  right: 0,
                  left: 0,
                  width: 0,
                  height: 0,
                  opacity: 0,
                  marginRight: "auto",
                  marginLeft: "auto"
                }}
                required
              />
            </span>
          </div>
        </div>

        <div className={classes.formContainer}>
          <div className={classes.topHeaderContainer}>
            <div className={classes.topHeaderTitle}>RFI's</div>
            <div className={classes.centerFullHeight}>
              <div className={classes.topHeaderData} style={{ border: "none" }}>
                <strong>Project #:</strong> &nbsp;{" "}
                {/* {` ${project && project.PMdgJobCode ? project.PMdgJobCode : ''}`} */}
              </div>
              <div className={classes.topHeaderData}>
                <strong>RFI #:</strong> &nbsp; {` ${"123 323 145"}`}
              </div>
              <div className={classes.topHeaderData}>
                <strong>From:</strong> &nbsp; {` ${"RFI`s"}`}
              </div>
              <div className={classes.topHeaderData}>
                <strong>Phone:</strong> &nbsp;{" "}
                {` ${creator.phone ? creator.phone : ""}`}
              </div>
              <div className={classes.topHeaderData}>
                <strong>Client:</strong> &nbsp;{" "}
                {` ${company ? company.name : null}`}
              </div>
              <div className={classes.topHeaderData}>
                <strong>Email:</strong> &nbsp;{" "}
                {` ${creator.email ? creator.email : ""}`}
              </div>
              <div className={classes.topHeaderBlueData}>
                <strong>Date:</strong> &nbsp;{" "}
                {`${TimeMonthDayYear(new Date())}`}
              </div>
            </div>
          </div>
          <div className={classes.otherContentContainer}>
            <div className={classes.forContainer}>
              <div className={classes.column} style={{ marginRight: "5px" }}>
                <div style={{ position: "relative" }}>
                  <Select
                    name={"assignedTo"}
                    value={assignedTo}
                    onChange={this.handleSelectChange("assignedTo")}
                    required
                    placeholder="To"
                    maxMenuHeight={250}
                    isMulti
                    options={users.map(user => ({
                      label: `${user.user.firstName} ${user.user.lastName}`,
                      value: `${user.user.firstName} ${user.user.lastName}`,
                      ...user.user
                    }))}
                    className={classes.reactSelect}
                  />
                  <input
                    value={assignedTo}
                    style={{
                      position: "absolute",
                      top: 40,
                      right: 0,
                      left: 0,
                      width: 0,
                      height: 0,
                      opacity: 0,
                      marginRight: "auto",
                      marginLeft: "auto"
                    }}
                    required
                  />
                </div>
                <ReactInput
                  name="assignedToAttn"
                  value={assignedToAttn}
                  onChange={this.handleChange}
                  required
                  placeholder={"Attn"}
                  autoComplete="off"
                  type="text"
                  className={classes.typeInput}
                />
                <Select
                  name={"assignedToEmail"}
                  value={assignedToEmail}
                  onChange={this.handleSelectChange("assignedToEmail")}
                  required
                  placeholder="Email"
                  maxMenuHeight={250}
                  isMulti
                  options={users.map(user => ({
                    label: `${user.user.email}`,
                    value: `${user.user.email}`,
                    ...user.user
                  }))}
                  className={classes.reactSelect}
                />
              </div>
              <div className={classes.column} style={{ marginLeft: "5px" }}>
                <div style={{ position: "relative" }}>
                  <Select
                    name={"cc"}
                    value={cc}
                    onChange={this.handleSelectChange("cc")}
                    required
                    placeholder="CC"
                    maxMenuHeight={250}
                    isMulti
                    options={users.map(user => ({
                      label: `${user.user.firstName} ${user.user.lastName}`,
                      value: `${user.user.firstName} ${user.user.lastName}`,
                      ...user.user
                    }))}
                    className={classes.reactSelect}
                  />
                  <input
                    value={cc}
                    style={{
                      position: "absolute",
                      top: 40,
                      right: 0,
                      left: 0,
                      width: 0,
                      height: 0,
                      opacity: 0,
                      marginRight: "auto",
                      marginLeft: "auto"
                    }}
                    required
                  />
                </div>
                <ReactInput
                  name="ccAttn"
                  value={ccAttn}
                  onChange={this.handleChange}
                  required
                  placeholder={"Attn"}
                  autoComplete="off"
                  type="text"
                  className={classes.typeInput}
                />
                <Select
                  name={"ccEmail"}
                  value={ccEmail}
                  onChange={this.handleSelectChange("ccEmail")}
                  required
                  placeholder="Email"
                  maxMenuHeight={250}
                  isMulti
                  options={users.map(user => ({
                    label: `${user.user.email}`,
                    value: `${user.user.email}`,
                    ...user.user
                  }))}
                  className={classes.reactSelect}
                />
              </div>
            </div>
            <div className={classes.textareaContainer}>
              <textarea
                name="subject"
                value={subject}
                onChange={this.handleChange}
                required
                placeholder={"Subject"}
                autoComplete="off"
                type="text"
                className={classes.textarea}
              />
            </div>
          </div>
        </div>

        <div className={classes.infoContainer}>
          <WarningIcon style={{ margin: "5px" }} />
          Please respond no later than date indicated above. if you have any
          question please contact address. thank you
        </div>

        <div className={classes.formContainer}>
          <div className={classes.topHeaderContainer}>
            <div className={classes.topHeaderTitleBlue}>Question/Conflict</div>
            <div className={classes.centerFullHeight}>
              <div className={classes.topHeaderBlueData}>
                <strong>Date Required:</strong> &nbsp;{" "}
                <PDatePicker
                  onChange={date => {
                    this.setState({ dateRequired: date });
                  }}
                  name="dueDate"
                  selected={dateRequired}
                  placeholderText="(MO/DY/YE)"
                  className={classes.datePicker}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          <div className={classes.otherContentContainer}>
            <div className={classes.questionContainer}>
              <div className={classes.questionBody}>
                <textarea
                  name="question"
                  value={question}
                  onChange={this.handleChange}
                  required
                  placeholder={"Write Your Question"}
                  autoComplete="off"
                  type="text"
                  className={classes.textarea}
                  style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                />
              </div>
            </div>
            <div className={classes.questionContainer}>
              <div className={classes.questionTitleContainer}>
                <span className={classes.questiontitle}>Answer/Resolution</span>
                <span className={classes.DateRequiredContainer}>
                  <span>Date Answerd:</span>
                  <span>{dateAnswered}</span>
                </span>
              </div>
              <div className={classes.questionBody}>
                <textarea
                  name="answer"
                  value={answer}
                  onChange={this.handleChange}
                  placeholder={"Answer"}
                  autoComplete="off"
                  type="text"
                  className={classes.textarea}
                  style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                />
              </div>
            </div>
          </div>
        </div>

        <Grid container className={classes.attachmentButtonWrapper}>
          <Grid item xs={2} style={{ position: "relative" }}>
            <input
              accept="image/*"
              className={classes.input}
              id="raised-button-file"
              multiple
              type="file"
              onChange={this.handleInputFile}
              name="files"
            />
            <label htmlFor="raised-button-file">
              <Button
                // raised
                component="span"
                className={`${classes.attachmentButton} ${classes.button}`}
              >
                <Add className={classes.addIcon} />
                ATTACHMENT
              </Button>
            </label>
          </Grid>

          <Grid
            item
            xs={10}
            container
            justify="flex-end"
            alignItems="flex-start"
          >
            {[...files].map((file, index) => (
              <span className={classes.fileRepresent} key={index}>
                <Close
                  className={classes.closeIconAttachment}
                  onClick={this.handleRemoveAttachment(index)}
                />
                <span className={classes.downloadIconAttachment}>
                  <DownloadIcon link={file.link} />
                </span>
                <span className={classes.fileName}>{file.name}</span>
              </span>
            ))}
          </Grid>
        </Grid>

        <div className={classes.saveButtonContainer}>
          <Button className={classes.saveButton} type="submit">
            Save
          </Button>
        </div>
      </form>
    );
  }
}

const Styles = {
  container: {
    padding: "10px 15px"
  },
  topItemsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  title: {
    fontSize: "20px",
    color: Colors.darkBlue
  },
  pmDesign: {
    color: Colors.darkBlue,
    textAlign: "right",
    marginRight: "40px",
    fontSize: "13px"
  },
  address: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "15px"
  },
  addressCreate: {
    width: "300px",
    fontSize: "12px"
  },
  solidDataContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "13px"
  },
  solidData: {
    width: "50%",
    textAlign: "left",
    marginTop: "5px"
  },
  forContainer: {
    marginTop: "20px",
    display: "flex"
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffeb3b14",
    border: `1px solid #969696`,
    color: "#969696",
    borderRadius: "5px",
    padding: "5px",
    marginTop: "20px",
    fontSize: "13px"
  },
  inputsContainer: {
    display: "flex",
    marginTop: "20px"
  },
  typeInput: {
    width: "100%",
    marginRight: "5px",
    padding: "10px",
    marginTop: "20px"
  },
  descriptionInput: {
    flex: 10,
    marginLeft: "5px"
  },
  addNewRowContainer: {
    marginTop: "20px"
  },
  addNewRowButton: {
    backgroundColor: "#A6A6A6",
    borderRadius: "5px",
    position: "relative",
    width: "270px",
    "&:hover": {
      backgroundColor: Colors.darkBlue
    }
  },
  addNewlineSpan: {
    width: "220px",
    color: "white",
    fontSize: "13px",
    marginTop: "2px"
  },
  addNewLineIcon: {
    position: "absolute",
    color: "white",
    right: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "13px"
  },
  addIcon: {
    fontSize: "21px"
  },
  saveButtonContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  saveButton: {
    width: "130px",
    backgroundColor: Colors.green,
    color: "white",
    fontSize: "13px",
    padding: "6px 40px!important",
    borderRadius: "5px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: Colors.darkGreen
    }
  },
  cancelButton: {
    width: "130px",
    backgroundColor: Colors.grey,
    color: "white",
    fontSize: "13px",
    padding: "6px 40px!important",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: Colors.darkGrey
    }
  },
  formContainer: {
    backgroundColor: "#fdfdfd",
    marginTop: "15px",
    borderRadius: "4px"
  },
  centerFullHeight: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%"
  },
  otherContentContainer: {
    padding: "20px"
  },
  topHeaderContainer: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px #a6a6a6"
  },
  topHeaderTitle: {
    height: "calc(100% - 25px)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "20px",
    padding: "0 10px",
    color: "green"
  },
  topHeaderTitleBlue: {
    height: "calc(100% - 25px)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "20px",
    padding: "0 10px",
    color: Colors.darkBlue
  },
  topHeaderData: {
    height: "calc(100% - 25px)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "13px",
    padding: "0 10px",
    borderLeft: "solid 1px #a6a6a6"
  },
  topHeaderBlueData: {
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "13px",
    padding: "0 10px",
    backgroundColor: "#83a9c7",
    color: "white"
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  textarea: {
    height: "130px",
    width: "100%"
  },
  textareaContainer: {
    marginTop: "20px"
  },
  questionTitleContainer: {
    backgroundColor: "#D7D7D7",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    padding: "10px",
    paddingRight: "130px",
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px"
  },
  DateRequiredContainer: {
    display: "flex",
    "& span:nth-child(2)": {
      color: "white",
      marginLeft: "10px",
      width: "185px"
    }
  },
  questionContainer: {
    marginTop: "20px"
  },
  reactSelect: {
    marginTop: "20px"
  },
  datePicker: {
    backgroundColor: "transparent",
    fontSize: "14px",
    padding: "2px 8px",
    border: "none!important",
    color: "white",
    width: "185px"
  },
  attachmentButton: {
    backgroundColor: Colors.darkBlue,
    color: "white",
    "&:hover": {
      backgroundColor: "#1661A8"
    }
  },
  attachmentButtonWrapper: {
    marginTop: "20px"
  },
  button: {
    paddingRight: "20px",
    paddingLeft: "20px",
    borderRadius: "5px"
  },
  input: {
    position: "absolute",
    opacity: 0,
    width: 0
  },
  fileRepresent: {
    borderRadius: "5px",
    border: "1px solid #D6D6D8",
    padding: "30px",
    margin: "5px",
    position: "relative",
    display: "inline-flex"
  },
  closeIconAttachment: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: Colors.grey,
    fontSize: "16px",
    cursor: "pointer"
  },
  downloadIconAttachment: {
    marginRight: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
const mapStateToProps = state => ({
  addresses: state.projects.project
    ? state.projects.project.addresses !== null
      ? state.projects.project.addresses
      : []
    : [],
  users: state.projects.users ? state.projects.users : [],
  creator: state.authentication.user,
  role: state.authentication.user.role,
  project: state.projects.project,
  companies: state.companies,
  formData: state.construction
});
export default connect(mapStateToProps)(withStyles(Styles)(PMDGRFIForm));
