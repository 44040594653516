// React //
import React, { useEffect } from "react";

// Redux //
import { withRouter } from "react-router-dom";
// Material Ui //

// Components //
import { ConstructionDocumentTabList } from "../../ConstructionDocumentTab";
// Reusable Functions //

// Styles //

export default withRouter(({ toggleSearchInput, searchQuery, clearSearch }) => {
  useEffect(() => {
    toggleSearchInput(true);
  }, []);

  return (
    <ConstructionDocumentTabList
      searchQuery={searchQuery}
      clearSearch={clearSearch}
    />
  );
});
