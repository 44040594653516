import React from "react";

import { IssueItem } from "../IssueList/IssueItem";
import { connect } from "react-redux";
import { issueActions } from "../../actions";
import { issueUserActions } from "../../userActions";
import { Link } from "react-router-dom";
// #####[ MATERIAL COMPONENTS ]#################################################
import { withStyles } from "@material-ui/core";
import { AddCircleOutlinedIcon } from "../Icons";
////////////////////modal and its content ////////////////////
import HOCModal from "../Modal/Modal";
import { IssueTaskModalContent } from "../IssueList/IssueTaskModalContent";

import DeleteModal from "../Modal/DeleteModal";
import ThemeContext from "../ContextApi/Context";
import { Colors } from "../../styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Modal from "../Modal/Modal";
import Close from "@material-ui/icons/Close";
import AddIssueInline from "../IssueList/AddIssueInline";
class IssueWidget extends React.PureComponent {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.state = {
      pageCapacity: props.pageCapacity ? props.pageCapacity : 7,
      pageNumber: 0,
      alertDeleteIssue: false,
      selectedIssue: {},
      dataProps: [],
      data: [],
      infoModalIsShowed: false,
      selectedOptionForDelete: {},
      openDeleteModal: false,
      value: 0,
      showIssueModal: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { data } = props;
    // return { data }
    const { dataProps } = state;
    if (dataProps !== data) {
      // const rootData = data.filter(issue => issue.parentIssue === null);
      return {
        dataProps: data,
        data
        // data: rootData,
      };
    } else return null;
  }

  // #######################################################################################
  // ####################              Dialog: Delete              ####################
  // #######################################################################################

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
          openDeleteModal: true,
          selectedOptionForDelete
        })
      : this.setState({
          openDeleteModal: false
        });
  };

  // ###########################################################################
  // handle Delete
  // ###########################################################################

  handleChangePage = number => () => {
    this.setState({ pageNumber: number });
  };

  widgetFilterArray = (array = []) => {
    const { pageFilter } = this.props;
    const keyWordLowerCase = pageFilter.toLowerCase();
    const filteredArray = array.filter(item => {
      const name = item.name
        ? item.name.toLowerCase().includes(keyWordLowerCase)
        : false;
      const description = item.description
        ? item.description.toLowerCase().includes(keyWordLowerCase)
        : false;
      const status = (item.status === "open" || item.status === "done"
        ? "Accepted"
        : item.status === "pending"
        ? "Not Accepted"
        : "Denied"
      )
        .toLowerCase()
        .includes(keyWordLowerCase);
      const date = new Date(item.dueDate);

      const dueDateMonth = (date.getMonth() + 1)
        .toString()
        .includes(keyWordLowerCase);
      const dueDateYear = date
        .getFullYear()
        .toString()
        .includes(keyWordLowerCase);
      const dueDateDay = date
        .getDate()
        .toString()
        .includes(keyWordLowerCase);

      const fullDate = (
        (date.getMonth() + 1).toString() +
        "-" +
        date.getDate().toString() +
        "-" +
        date.getFullYear().toString()
      ).includes(keyWordLowerCase);

      const assignedTo = (
        item.assignedTo.firstName +
        " " +
        item.assignedTo.lastName
      )
        .toLowerCase()
        .includes(keyWordLowerCase);
      return (
        name ||
        description ||
        status ||
        dueDateMonth ||
        dueDateYear ||
        dueDateDay ||
        fullDate ||
        assignedTo
      );
    });
    return filteredArray;
  };

  handlerInfoModal = bool => {
    this.setState({
      infoModalIsShowed: !!bool
    });
  };

  setClickedRowDataToState = clickedRowDataId => {
    this.setState({
      clickedRowDataId
    });
  };

  handleEdit = issue => {
    const { dispatch, role } = this.props;
    const projectId = this.context;
    role === "adminFlag"
      ? dispatch(issueActions.editIssue({ ...issue, projectId }))
      : dispatch(issueUserActions.editIssue({ ...issue, projectId }));
  };

  // ###########################################################################
  // Order
  // ###########################################################################

  handleChangeTabs = value => {
    this.setState({
      value
    });
  };

  handleOpenModal = bool => {
    this.setState({
      showIssueModal: !!bool
    });
  };

  header = ({ classes }) => (
    <div className={classes.header}>
      <div className={classes.headerItem} style={{ flex: 3 }}>
        Task
      </div>
      <div className={classes.headerItem} style={{ flex: 4.5 }}>
        Description
      </div>
      <div className={classes.headerItem} style={{ flex: 3 }}>
        Assigned To
      </div>
      <div className={classes.headerItem} style={{ flex: 2 }}>
        Due Date
      </div>
      <div className={classes.headerItem} style={{ flex: 3 }}>
        Delay Time
      </div>
      <div
        className={classes.headerItem}
        style={{
          width: "100px"
        }}
      >
        Status
      </div>
      <div
        style={{
          width: "70px",
          textAlign: "center",
          paddingRight: "10px"
        }}
        className={classes.headerItem}
      >
        Actions
      </div>
    </div>
  );

  // ###########################################################################
  // render
  // ###########################################################################

  render = () => {
    const {
      classes,
      pageFilter = "",
      projectUsers,
      role,
      dispatch,
      userId,
      projectId
    } = this.props;

    const { data = [], infoModalIsShowed, value, showIssueModal } = this.state;
    const filteredData = this.widgetFilterArray(
      [
        ...data.filter(item => item.important),
        ...data
          .filter(item => !item.important)
          .sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1))
      ],
      pageFilter
    );
    return (
      <React.Fragment>
        <div className={classes.card}>
          <div className={classes.titleWrapper}>
            <Link
              to={`/${
                role === "adminFlag" ? "admin" : "user"
              }-dashboard/projects/${projectId}/project-issues`}
              className={classes.title}
            >
              Issue information
            </Link>
          </div>
          {!filteredData[0] ? (
            <>
              <section className={classes.noData}>No Issue-Task</section>
              <div className={classes.addIcon}>
                <Link
                  to={`/${
                    role === "adminFlag" ? "admin" : "user"
                  }-dashboard/projects/${projectId}/project-issues`}
                  className={classes.title}
                >
                  <AddCircleOutlinedIcon tooltip="Add New Issue-Task" />
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className={classes.section}>
                {/* ########################## pendding tasks  ######################## */}

                {this.header({ classes })}

                <div className={classes.listHolder}>
                  {filteredData.map((item, index) => (
                    <div
                      className={classes.issueItemWrapper}
                      style={
                        index === filteredData.length - 1
                          ? { borderBottom: "none" }
                          : {}
                      }
                    >
                      <IssueItem
                        projectUsers={projectUsers}
                        key={item._id}
                        data={item}
                        handleEdit={this.handleEdit}
                        handleDeleteModal={this.handleDeleteModal}
                        setClickedRowDataToState={this.setClickedRowDataToState}
                        handlerInfoModal={this.handlerInfoModal}
                        role={role}
                        dispatch={dispatch}
                        handleChangeTabs={this.handleChangeTabs}
                        isMine={item.assignedTo._id === userId}
                        widget
                      />
                    </div>
                  ))}
                </div>

                {/* //////////////////// Delete User Dialog ///////////////////////// */}

                <DeleteModal
                  open={this.state.openDeleteModal}
                  handleDeleteModal={this.handleDeleteModal}
                  selectedOptionForDelete={{
                    issueTaskId: this.state.selectedOptionForDelete._id
                  }}
                  adminAction={issueActions.deleteIssue}
                  userAction={issueUserActions.deleteIssue}
                  //optional
                  modalTitle={"Delete Issue"}
                  optionNameForDelete={this.state.selectedOptionForDelete.name}
                />

                {/* ############################################################ */}

                <HOCModal
                  isShowed={infoModalIsShowed}
                  handlerInfoModal={this.handlerInfoModal}
                >
                  <IssueTaskModalContent
                    handlerInfoModal={this.handlerInfoModal}
                    clickedRowDataId={this.state.clickedRowDataId}
                    value={value}
                  />
                </HOCModal>

                <Modal
                  isShowed={showIssueModal}
                  handlerInfoModal={this.handleOpenModal}
                >
                  <Card className={classes.modalCard}>
                    <Grid container justify="space-between">
                      <Typography variant="h5" className={classes.modalTitle}>
                        Add New Issue-Task
                      </Typography>
                      <Close
                        className={classes.closeIcon}
                        onClick={() => this.handleOpenModal(false)}
                      />
                    </Grid>

                    <AddIssueInline handleOpenModal={this.handleOpenModal} />
                  </Card>
                </Modal>

                {/* ############################################################ */}
              </div>
              <div className={classes.addIcon}>
                <Link
                  to={`/${
                    role === "adminFlag" ? "admin" : "user"
                  }-dashboard/projects/${projectId}/project-issues`}
                  className={classes.title}
                >
                  <AddCircleOutlinedIcon tooltip="Add New Issue-Task" />
                </Link>
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    );
  };
}

const mapStateToProps = state => ({
  projectUsers: state.projects.users ? state.projects.users : [],
  data: state.issues ? state.issues : [],
  role: state.projects.project ? state.projects.project.role : undefined,
  userId: state.authentication.user._id,
  projectId: state.projects.project ? state.projects.project._id : undefined,
  pageFilter: state.filters.pageFilter
});

const styles = theme => ({
  card: {
    fontSize: "13px",
    display: "flex",
    flexDirection: "column"
  },
  section: {
    backgroundColor: "white",
    padding: "10px 15px 5px",
    borderRadius: "5px 5px 0px 0px",
    marginTop: "0",
    height: "100%",
    overflow: "hidden",
    transition: "300ms"
  },
  header: {
    borderBottom: `2px solid ${Colors.darkBlue}`,
    display: "flex",
    flexDirection: "row",
    flex: 1
    // marginBottom: "10px"
  },
  title: {
    backgroundColor: "white",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    color: Colors.grey,
    padding: "5px 15px 10px",
    fontWeight: "500",
    fontSize: "17px",
    textDecoration: "none!important",
    "&:hover": {
      color: "#aaaab5"
    }
  },
  root: {
    boxSizing: "border-box",
    padding: "10px 0px",
    display: "flex",
    width: "100%"
  },
  headerItem: {
    boxSizing: "border-box",
    color: "#155794",
    padding: "3px 5px 3px 10px",
    margin: "0px 3px",
    fontSize: "13px",
    fontWeight: "500"
  },
  listHolder: {
    height: "282px",
    overflowY: "overlay",
    paddingRight: "10px",
    marginTop: "5px"
  },

  ////////////////////////////// pagination ////////////////////////////////////

  pagination: {
    padding: "0px"
  },
  borderTop: {
    borderTop: "1px solid #e1e1e1"
  },
  issuesLabel: {
    padding: "5px 4px",
    fontSize: "20px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    "& >div": {
      height: "2px",
      flexGrow: 100,
      backgroundColor: "gray",
      marginLeft: "15px",
      marginTop: "5px"
    }
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "35px",
    color: "#00000036",
    fontWeight: "100!important",
    padding: "10px 50px 3px 0",
    borderRadius: "5px",
    marginTop: "0",
    height: "332px",
    backgroundColor: "white"
  },
  addIcon: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "white"
  },
  modalCard: {
    maxWidth: "85%",
    width: "85%",
    minWidth: "85%",
    padding: "15px 20px"
  },
  modalTitle: {
    padding: "10px",
    color: "#606061"
  },
  closeIcon: {
    color: "#bdbdbd",
    cursor: "pointer"
  },
  issueItemWrapper: {
    borderBottom: "1px solid #CBCBDB"
  }
});

// #############################################################################
// styles
// #############################################################################

const IssueWidgetWithStyles = withStyles(styles)(IssueWidget);
const IssueWidgetConnected = connect(mapStateToProps)(IssueWidgetWithStyles);
export { IssueWidgetConnected as IssueWidget };
