import React from "react";
import { withStyles } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Colors } from "../../styles/colors";
import { API } from "../../constants";

const styles = {
  iconButton: {
    padding: "4px !important",
    color: Colors.lightGreen
  },
  attachmentItemDownloadIcon: {
    borderRadius: "100%",
    border: `2px solid ${Colors.lightGreen}`,
    fontSize: "17px"
  }
};

const handleDownload = (link, e) => {
  e.preventDefault();
  window.open(link);
};

export const DownloadIcon = React.memo(
  withStyles(styles)(({ classes, onClick, link = "" }) => (
    <>
      {link ? (
        <a
          href={link}
          onClick={e => handleDownload(`${API}/${link}`, e)}
          className={classes.anchor}
        >
          <Tooltip title="Download">
            <IconButton
              onClick={onClick}
              className={classes.iconButton}
              component="span"
            >
              <ArrowDownward className={classes.attachmentItemDownloadIcon} />
            </IconButton>
          </Tooltip>
        </a>
      ) : (
        <Tooltip title="Download">
          <IconButton
            onClick={onClick}
            className={classes.iconButton}
            component="span"
          >
            <ArrowDownward className={classes.attachmentItemDownloadIcon} />
          </IconButton>
        </Tooltip>
      )}
    </>
  ))
);
