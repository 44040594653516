import React from "react";

// #####[ material ui ]##############################################################
import { withStyles } from "@material-ui/core";

// #####[ redux ]##############################################################
import { connect } from "react-redux";
import { commentActions } from "../../actions";
import { commentUserActions } from "../../userActions";
// #####[ reusable functions ]##############################################################

// #####[ styles ]##############################################################
import { Colors } from "../../styles";

// #####[ components ]##############################################################

// import { EditIcon, DeleteIcon } from '../Icons'
import AddNewComment from "./AddNewComment";
import { CommentModalTabItem } from "./CommentModalTabItem";

class CommentModalTab extends React.PureComponent {
  state = {
    editMode: false,
    text: "",
    isAddNewComment: false,
    isReplay: false
  };

  componentDidMount() {
    const { dispatch, role, clickedRowDataId, projectId } = this.props;
    role === "adminFlag"
      ? dispatch(
          commentActions.getIssueTaskComments({ issueTaskId: clickedRowDataId })
        )
      : dispatch(
          commentUserActions.getIssueTaskComments({
            projectId,
            issueTaskId: clickedRowDataId
          })
        );
  }

  handleIsReplyActive = () => {
    this.setState(prevState => ({
      isReplay: !prevState.isReplay
    }));
  };

  render() {
    const { classes, comments, clickedRowDataId } = this.props;
    const { isAddNewComment } = this.state;
    return (
      <>
        <div className={classes.commentsContainer}>
          {comments
            .filter(comment => !comment.parentIssueTaskComment)
            .map((comment, index) => (
              <div className={classes.commentItemWrapper} key={index}>
                <CommentModalTabItem
                  allComments={comments}
                  comment={comment}
                  clickedRowDataId={clickedRowDataId}
                  handleIsReplyActive={this.handleIsReplyActive}
                  isReplay={false}
                />
              </div>
            ))}
        </div>
        <AddNewComment
          handleCancelAddNewComment={this.handleCancelAddNewComment}
          isAddNewComment={isAddNewComment}
          clickedRowDataId={clickedRowDataId}
          root={true}
        />
      </>
    );
  }
}

const styles = {
  commentsContainer: {
    maxHeight: "300px",
    overflowY: "overlay",
    padding: "0 20px 0 10px"
  },
  commentItemWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "4px",
    marginBottom: "7px",
    paddingBottom: "7px"
  },
  photoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "6px",
    width: "75px"
  },
  image: {
    width: "70px"
  },
  commentTextAndAttachmentWrapper: {
    // backgroundColor: 'blue',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingRight: "10px",
    marginTop: "10px"
  },
  name: {
    color: Colors.darkBlue,
    fontWeight: "500",
    margin: "7px 5px 2PX"
  },
  commentText: {
    margin: "2PX 5px 10px",
    fontSize: "14px"
  },
  attachmentWrapper: {
    margin: "10px 0",
    overflow: "hidden"
  },
  actionsAndTimeWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingRight: "15px"
  },
  iconWrapper: {
    padding: "3px!important"
  },
  infoIconWrapper: {
    color: Colors.yellow
  },
  time: {
    color: Colors.grey,
    fontSize: "13px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingRight: "10px"
  },
  textarea: {
    width: "99%",
    border: `1px solid ${Colors.lightGrey}`,
    color: Colors.grey,
    fontSize: "14px",
    marginLeft: "5px",
    height: "auto"
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start"
  },
  actionEditWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "13px"
  },
  cancelButton: {
    fontWeight: 900,
    fontSize: "10px",
    height: "38px",
    width: "70px",
    // box-sizing: border-box;
    borderRadius: "5px",
    backgroundColor: "#AFADAD",
    color: "#ffffff",
    display: "inline-block",
    border: "none",
    cursor: "pointer",
    margin: "2px"
  },
  commentButton: {
    color: Colors.grey,
    borderRadius: "15px",
    border: `1px solid ${Colors.darkenThanLightGrey}`,
    fontSize: "11px",
    padding: "3px 8px!important"
  },
  actionAndReplayWrapper: {
    marginTop: "17px"
  },
  replayWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: " 7px"
  },
  replayButton: {
    fontSize: "12px",
    color: Colors.darkBlue,
    padding: 0
  }
};

const mapStateToProps = state => {
  return {
    comments: state.comments,
    role: state.projects.project ? state.projects.project.role : undefined,
    projectId: state.projects.project ? state.projects.project._id : undefined
  };
};

const tempTemp = connect(mapStateToProps)(withStyles(styles)(CommentModalTab));

export { tempTemp as CommentModalTab };
