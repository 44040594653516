export const Colors = {
  lightGrey: "#cbcbdb",
  darkenThanLightGrey: '#BCBCBC',
  grey: "#80808A",
  darkGrey: '#9E9E9E',
  lightGreen: "#0AD871",
  green: "#58B370",
  darkGreen: "#4E9E63",
  camouflageGreen: "#8BA59D",
  darkBlue: "#155794",
  blue: "#4BBAFF",
  lightBlue: '#DEEBFF',
  orange: "#FF6F33",
  darkOrange: '#EC6A16',
  red: 'red',
  yellow: '#FFC000'
};
