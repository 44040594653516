import React from 'react'
import { withStyles } from '@material-ui/core'
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../styles/colors'

const styles = {
    iconButton: {
        padding: '3px !important',
        color: Colors.lightGreen,
    },
    icon: {
        color: Colors.darkBlue,
    }
}

export const FilledArrow = React.memo(withStyles(styles)(({ classes, onClick, tooltip, angle }) => (
    <Tooltip title={tooltip}>
        <IconButton onClick={onClick} className={classes.iconButton} component="span">
            <PlayCircleFilled className={classes.icon} style={{ transform: 'rotate(-90deg)' }} />
        </IconButton>
    </Tooltip>
))
)
