import React from "react";
import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { API } from "../../constants";
import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';

// #####[ functions ]##############################################################
import pastTimeSoFar from '../reusebleFunctions/pastTimeSoFar'

// #####[ icons ]##############################################################

import CancelOutlined from '@material-ui/icons/CancelOutlined'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
// import EditOutlined from '@material-ui/icons/EditOutlined'
import Bookmark from '@material-ui/icons/Bookmark'
import Person from '@material-ui/icons/Person'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import Clear from '@material-ui/icons/Clear'

// #####[ components ]##############################################################
import { ReactInput } from '../reactInput/reactInput'

// #####[ colors ]##############################################################
import { Colors } from '../../styles'

// #####[ reusable functions ]##############################################################

class AttachmentModalItem extends React.PureComponent {
    state = {
        editMode: false,
        editableName: this.props.data.name,
        disableSaveButton: false,
        defaultTags: this.props.tagsObject,
        tags: this.props.tagsObject
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data)
            this.setState({
                editableName: this.props.data.name,
                defaultTags: this.props.tagsObject
            })
    }

    handleDownload = (link) => {
        window.open(link)
    }

    // handleEditMode = () => {
    //     this.setState({
    //         editMode: true
    //     })
    // }

    handlerEditableName = (e) => {
        this.setState({
            editableName: e.target.value,
            disableSaveButton: e.target.value === '' ? true : false,
            isChangeHappend: true
        })
    }

    handleCheckbox = ({ target }) => {
        this.setState(prevState => ({
            tags: target.checked ?
                [...prevState.tags, { label: target.name, value: target.name }] :
                prevState.tags.filter(tag => tag.value !== target.name),
            isChangeHappend: true
        }))
    }

    handleSaveChange = () => {
        const checkedTagsfunction = () => {
            return Object.values(this.state.tags).map(tag => tag.value)
        }
        this.state.isChangeHappend &&
            this.props.handleEditAttachment(this.state.editableName,
                this.props.data._id, checkedTagsfunction())

        this.setState({
            editMode: false,
            editableName: this.props.data.name,
            isChangeHappend: false

        })
    }

    handleCancelChange = () => {
        this.setState({
            editMode: false,
            editableName: this.props.data.name,
            disableSaveButton: false
        })
    }


    render() {
        const { data, classes, handleDeleteModal } = this.props;
        const { editMode, editableName, defaultTags, tags } = this.state
        return (
            <>
                <Grid container className={classes.root} >

                    <div className={classes.documentSenderAndInfo}>
                        <>
                            <div className={classes.personAndSenderWrapper}>
                                <Person className={classes.Person} />
                                <div className={classes.fileSender}>
                                    {`${data.sender.firstName} ${data.sender.lastName}`}
                                </div>
                            </div>
                            <div className={classes.fileDescription}>
                                {!editMode ?
                                    <p>{data.name}</p> :
                                    <ReactInput
                                        className={classes.editAttachmentNameInput}
                                        type='text'
                                        value={editableName}
                                        onChange={(e) => this.handlerEditableName(e)}
                                    />
                                }
                            </div>
                        </>
                    </div>

                    <div className={classes.IconTimeAndTagsHolder}>
                        <div className={classes.IconAndTimeHolder}>
                            {/* icons */}
                            {this.props.role !== 'Project-Client' &&
                                <div>
                                    {!this.state.editMode ?
                                        <>
                                            {/* <Tooltip title="Edit Attachment">
                                                <IconButton
                                                    onClick={this.handleEditMode}
                                                    className={classes.iconButton}
                                                    component="button">
                                                    <EditOutlinedIcon
                                                        className={classes.editIcon}
                                                    />
                                                </IconButton>
                                            </Tooltip> */}

                                            <Tooltip title="Delete Attachment">
                                                <IconButton
                                                    className={classes.iconButton}
                                                    onClick={() => handleDeleteModal(data)}
                                                >
                                                    <CancelOutlined
                                                        className={classes.deleteIcon} />
                                                </IconButton>
                                            </Tooltip>

                                        </>
                                        :
                                        <>
                                            <Tooltip title="Cancel">
                                                <IconButton
                                                    onClick={this.handleCancelChange}
                                                    className={classes.iconButton}
                                                    component="button">
                                                    <Clear
                                                        className={classes.cancelIcon}
                                                    />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Done">
                                                <IconButton
                                                    onClick={this.handleSaveChange}
                                                    className={classes.iconButton}
                                                    component="button">
                                                    <CheckCircleOutline
                                                        className={classes.doneIcon}
                                                    />
                                                </IconButton>
                                            </Tooltip>

                                        </>
                                    }
                                </div>
                            }
                            {/*CreateDate*/}
                            <div className={classes.pastTimeSoFar}>
                                {pastTimeSoFar(data.createDate)}
                            </div>

                        </div>
                        {/* tags */}
                        <div className={classes.TagsHolder}>
                            {!editMode ?
                                defaultTags.map((tag, index) =>
                                    <span key={index} className={classes.tagsSpan}>
                                        <Bookmark className={classes.Bookmark} />
                                        <span>{tag.value}&nbsp;</span>
                                    </span>
                                ) :
                                <span className={classes.tagsSpanEditMode}>
                                    {
                                        [
                                            { label: 'Invoice', value: 'Invoice' },
                                            { label: 'Contract', value: 'Contract' },
                                            { label: 'Change Order', value: 'Change Order' },
                                            { label: 'Misc Document', value: 'Misc Document' }
                                        ].map((tag, index) =>
                                            <label key={index}>
                                                <input
                                                    type='checkbox'
                                                    checked={tags.find(tagg => tagg.value === tag.value)}
                                                    onChange={this.handleCheckbox}
                                                    name={tag.value}
                                                    style={{ position: 'relative', top: '2px', left: 'auto' }} />
                                                <span>{tag.value}</span>
                                            </label>
                                        )
                                    }
                                </span>

                            }
                        </div>

                    </div>
                    {/* download icon */}
                    <div className={classes.downloadIconWrapper}>
                        <Tooltip title="Download File">
                            <IconButton type="submit"
                                onClick={() => this.handleDownload(`${API}/${data.link}`)}
                                target="blank"
                                className={classes.iconButton}>
                                <ArrowDownward
                                    className={classes.downloadIcon}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Grid>
            </>
        )

    }
};

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
    root: {
        width: "100%",
        fontSize: '13px',
        borderRadius: '30px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px 0',
        marginBottom: '7px'
    },
    Bookmark: {
        color: Colors.lightGrey,
        fontSize: '18px',
        marginRight: '2px',
        marginTop: '2px'
    },
    fileSender: {
        flex: 1,
        marginLeft: '10px'
    },
    iconButton: {
        padding: '3px'
    },
    deleteIcon: { color: "#ff4b00cc" },
    downloadIconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '100%',
        margin: '5px 15px'

    },
    downloadIcon: {
        color: Colors.green,
        fontSize: '30px',
    },
    editIcon: {
        color: "#30b542e0"
    },
    doneIcon: {
        color: Colors.blue
    },
    cancelIcon: {
        color: Colors.red
    },

    editAttachmentNameInput: {
        width: '100%',
        fontSize: '13px',
        height: '25px!important'
    },
    Person: {
        fontSize: '19px',
        color: Colors.grey
    },
    documentSenderAndInfo: {
        flex: '3',
        padding: '0 10px 0 20px',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        WebkitHyphens: 'auto',
        MsHyphens: 'auto',
        hyphens: 'auto',
    },
    personAndSenderWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    fileDescription: {
        paddingLeft: '30px',
        color: Colors.grey,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        '& p': {
            margin: 0,
        }
    },
    IconTimeAndTagsHolder: {
    },
    IconAndTimeHolder: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    TagsHolder: {
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    tagsSpan: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '10px',
        '& span': {
            color: Colors.grey
        }
    },
    tagsSpanEditMode: {
        fontSize: '10px',
        color: Colors.grey,
        '& label': {
            margin: '2px 0'
        }
    },
    pastTimeSoFar: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingLeft: '10px',
        width: '140px',
        color: Colors.grey
    }

})



// #############################################################################
// connect styles to the component
// #############################################################################

const AttachmentItemWithStyles = withStyles(styles)(AttachmentModalItem);

// #############################################################################
// export
// #############################################################################

export { AttachmentItemWithStyles as AttachmentModalItem }