import React from 'react'
import { withStyles } from '@material-ui/core'
import NoteAdd from "@material-ui/icons/NoteAdd";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../styles/colors'

const styles = {
    iconButton: {
        padding: '3px !important',
        color: Colors.darkenThanLightGrey,
    },
}

export const NewFile = React.memo(withStyles(styles)(({ classes, onClick }) => (
    <Tooltip title="Add Files">
        <IconButton onClick={onClick} className={classes.iconButton} component="span">
            <NoteAdd className={classes.editIcon} />
        </IconButton>
    </Tooltip>
))
)
