import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

//############################################################################
// styles
//############################################################################

const styles = theme => ({
  root:{
    position: 'fixed',
    top: 0,
    zIndex: 1301,
  },
  container: {
    width: "100%"
  },
  progressPlaceholder: {
    height: "5px",
    width: "100%"
  },
  barColorPrimary: {
    backgroundColor: "#55C5FF",
    width: "100%"
  },
  colorPrimary: {
    backgroundColor: "#E7F7FF",
    width: "100%"
  }
});

//############################################################################
// component
//############################################################################

function ProgressBar(props) {
  const { classes, status, color, barColor } = props;
  const selectedColor = color || classes.colorPrimary;
  const selectedBarColor = barColor || classes.barColorPrimary;
  if (status)
    return (
      <LinearProgress classes={{ colorPrimary: selectedColor, barColorPrimary: selectedBarColor,  root: classes.root }}/>);
        else return <div className={`${classes.progressPlaceholder} ${classes.root}`}/>;
        
}
//############################################################################
// connects styles to component
//############################################################################
const mapStateToProps = state => {
  return {
    status: state.progressBar.status
  };
};

const ProgressBaConnected = connect(mapStateToProps)(ProgressBar);
const ProgressBarWithStyle = withStyles(styles)(ProgressBaConnected);

//############################################################################
// export
//############################################################################
export { ProgressBarWithStyle as ProgressBar };
