// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { clientActions } from "../../../actions";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
// Components //
import { ReactInput } from "../../";
import Creatable from "react-select/lib/Creatable";
// Reusable Functions //

// Styles //
import { Colors } from "../../../styles";

class AddClientInline extends React.PureComponent {
  state = {
    openEditClient: false,
    alertDeleteClient: false,
    modalAddClient: false,
    name: "",
    pointOfContact: "",
    primaryContact: "",
    email: "",
    address: "",
    faxOrMobile: "",
    phone: "",
    searchKeyWord: "",
    selected: {},
    file: null,
    categories: []
  };

  handleAddClient = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    const {
      name,
      pointOfContact,
      primaryContact,
      email,
      address,
      phone,
      faxOrMobile,
      file,
      categories
    } = this.state;

    let document = new FormData();
    document.append("name", name);
    document.append("pointOfContact", pointOfContact);
    document.append("primaryContact", primaryContact);
    document.append("email", email);
    document.append("address", address);
    document.append("phone", phone);
    document.append("faxOrMobile", faxOrMobile);

    // for (var i = 0; i < categories.length; i++) {
    document.append(
      "category",
      JSON.stringify(categories.map(cat => cat.value))
    );
    // }

    file && document.append("image", file);

    if (this.props.handleOpenModal) {
      //second parameter in bellow function is for getting allproject after client added
      dispatch(clientActions.addCompany(document, true));
      this.props.handleOpenModal(false);
    } else {
      dispatch(clientActions.addCompany(document));
    }

    this.setState({
      name: "",
      pointOfContact: "",
      primaryContact: "",
      email: "",
      address: "",
      phone: "",
      faxOrMobile: "",
      file: "",
      categories: []
    });
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileChoosen = ({ target }) => {
    target.files[0]
      ? this.setState({
          file: target.files[0]
        })
      : this.setState({
          file: ""
        });
  };

  handleDeleteChip = chipName => {
    this.setState(prevState => ({
      chips: prevState.filter(chip => chip === chipName)
    }));
  };

  handleCategoryChange = categories => {
    this.setState({
      categories
    });
  };

  render() {
    const { classes } = this.props;
    const {
      name,
      pointOfContact,
      primaryContact,
      email,
      address,
      phone,
      faxOrMobile,
      file,
      categories
    } = this.state;

    return (
      <>
        <div className={classes.header}>
          <form
            onSubmit={this.handleAddClient}
            onKeyPress={event => {
              if (event.which === 13 /* Enter */) {
                event.preventDefault();
              }
            }}
          >
            <Grid container spacing={8} className={classes.headerInputs}>
              <Grid item xs={11}>
                <Grid
                  container
                  spacing={8}
                  className={classes.inputsWrapper}
                  style={{ padding: 0 }}
                >
                  <Grid item xs>
                    <Grid
                      container
                      className={classes.selectAddGrid}
                      alignItems="center"
                      spacing={8}
                    >
                      <Grid item xs={2} md={1}>
                        <div className={classes.titleTopHeader}>ADD CLIENT</div>
                      </Grid>
                      <Grid item xs={11}>
                        <Grid
                          container
                          spacing={8}
                          className={classes.inputHolder}
                        >
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs className={classes.inputContainer}>
                                <ReactInput
                                  name={"name"}
                                  value={name}
                                  onChange={this.handleChange}
                                  required
                                  placeholder="Name"
                                  autoComplete="off"
                                  type="text"
                                  className={classes.textFieldItem}
                                />
                              </Grid>
                              <Grid item xs className={classes.inputContainer}>
                                <ReactInput
                                  name="pointOfContact"
                                  value={pointOfContact}
                                  onChange={this.handleChange}
                                  required
                                  placeholder="Point Of Contact"
                                  autoComplete="off"
                                  type="text"
                                  className={classes.textFieldItem}
                                />
                              </Grid>
                              <Grid item xs className={classes.inputContainer}>
                                <ReactInput
                                  name="primaryContact"
                                  value={primaryContact}
                                  onChange={this.handleChange}
                                  placeholder="Primary Contact"
                                  autoComplete="off"
                                  required
                                  type="text"
                                  className={classes.textFieldItem}
                                />
                              </Grid>
                              <Grid item xs className={classes.inputContainer}>
                                <ReactInput
                                  name="email"
                                  value={email}
                                  onChange={this.handleChange}
                                  placeholder="Email Address"
                                  autoComplete="off"
                                  required
                                  type="email"
                                  className={classes.textFieldItem}
                                />
                              </Grid>
                              <Grid item xs className={classes.inputContainer}>
                                <ReactInput
                                  name="faxOrMobile"
                                  value={faxOrMobile}
                                  onChange={this.handleChange}
                                  placeholder="fax Or Mobile"
                                  autoComplete="off"
                                  required
                                  type="number"
                                  className={classes.textFieldItem}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={classes.inputContainer}
                              >
                                <ReactInput
                                  name="phone"
                                  value={phone}
                                  onChange={this.handleChange}
                                  placeholder="Phone"
                                  autoComplete="off"
                                  required
                                  type="number"
                                  className={classes.textFieldItem}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs className={classes.inputContainer}>
                                <Creatable
                                  isMulti
                                  onChange={this.handleCategoryChange}
                                  options={categories}
                                  value={categories}
                                  placeholder="Programs (Press Enter To Add)"
                                  components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                    Menu: () => null
                                  }}
                                />
                              </Grid>
                              <Grid item xs className={classes.inputContainer}>
                                <ReactInput
                                  name="address"
                                  value={address}
                                  onChange={this.handleChange}
                                  placeholder="Address"
                                  autoComplete="off"
                                  required
                                  type="text"
                                  className={classes.textFieldItem}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={1}>
                    <label
                      className={`${classes.chooseFile} ${classes.addAttachment}`}
                    >
                      {file && (
                        <AttachFileIcon className={classes.attachmentIcon} />
                      )}
                      <span>ADD LOGO</span>
                      <input
                        type="file"
                        onChange={this.handleFileChoosen}
                        accept="image/*"
                      />
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} className={classes.buttonWrapper}>
                <Button type="submit" className={classes.customButton}>
                  {"ADD CLIENT"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </>
    );
  }
}

const Styles = theme => ({
  header: {
    padding: "20px 20px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 20px 10px"
    }
  },
  headerInputs: {
    padding: "5px",
    borderRadius: "5px",
    border: "1px solid #bdbdbd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  selectAddGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    padding: "4px"
  },
  titleTopHeader: {
    padding: "10px 5px 10px 10px",
    color: "#155794",
    textAlign: "center",
    fontSize: "15px"
  },
  textFieldItem: {
    width: "100%",
    height: "38px"
  },
  customButton: {
    backgroundColor: "#155794",
    width: "100%",
    padding: "10px",
    fontSize: "0.7rem",
    color: "#fff",
    borderRadius: "5px",
    height: "91px",
    "&:hover": {
      backgroundColor: "#3f51b5"
    }
  },
  inputContainer: {
    margin: "0 3px"
  },
  chooseFile: {
    backgroundColor: Colors.green,
    display: "flex!important",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    // fontSize: '10px',
    borderRadius: "5px",
    // fontWeight: '900',
    // flex: '1',
    // width: '100%',
    // height: '38px',
    boxSizing: "border-box",
    border: 0,
    cursor: "pointer",
    position: "relative",
    color: "white!important",
    "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "13px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: Colors.darkGreen
    },
    "& input": {
      opacity: 0,
      width: 0,
      height: 0,
      position: "absolute",
      top: 0,
      left: 0,
      cursor: "pointer"
    },
    "& > span": {
      width: "80%",
      height: "100%",
      zIndex: 100,
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    }
  },
  attachmentIcon: {
    color: "white",
    fontSize: "19px",
    transform: "rotate(45deg)"
  },
  addAttachment: {
    // width: "100px",
    height: "91px",
    fontSize: "12px",
    fontWeight: "500",
    color: "white",
    borderRadius: "5px",
    padding: "5px",
    cursor: "pointer!important",
    transition: "300ms",
    border: "none",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  inputsWrapper: {
    padding: "5px",
    borderRadius: "5px",
    border: "1px solid #bdbdbd"
  },
  inputHolder: {
    display: "flex",
    flexGrow: 100
  },
  "hide-options": {
    display: "none"
  },
  "Select-arrow-zone": {
    display: "none"
  },
  "Select-menu-outer": {
    display: "none"
  }
});

let mapStateToProps;

export default connect(mapStateToProps)(withStyles(Styles)(AddClientInline));
