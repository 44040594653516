import { calculateWithAndPosition, getParentsMaxes, getParentsMins } from "./"


// #############################################################################
// find Min and Max date
// #############################################################################

export function findMinAndMaxDate( array = [], keys = []){
  let max = 0;
  let min = Number.MAX_VALUE;
  array.forEach(data => {
    keys.forEach(item => {
      const itemDate = new Date(data[item]).getTime();
      max = Math.max(itemDate, max);
      min = Math.min(itemDate, min);
    })
  })
  return [ new Date(min), new Date(max) ];
}

// #############################################################################
// this function will add date to the input date 
// #############################################################################

export function addDays(inputDate, days){
  const date = new Date(inputDate);
  return new Date(date.setDate(date.getDate() + days));
}

// #############################################################################
// this function will subtract date from the input date
// #############################################################################

export function subtractDays(inputDate, days){
  return new Date(inputDate.setDate(inputDate.getDate() - days));
}

// #############################################################################
// find max of dependers
// #############################################################################

export function getMaxDependers( taskObjects = {}, task = {} , minEdge, IdPrefix = "", unit = 25){
  const parentsMaxDependers = getParentsMaxes(taskObjects, task, minEdge, IdPrefix, unit);
  const { dependencies = [] } = task;

  let maxActual = 0;
  let maxPlanned = 0;
    
  let maxActualTask = {};
  let maxPlannedTask = {};

  if (dependencies.length > 0 ) {
    dependencies.forEach(task => {

      const maxActualTemp = Math.max(new Date(taskObjects[task._id].actualFinishDate).getTime(), maxActual);
      maxActualTask = maxActualTemp !== maxActual ? taskObjects[task._id] : maxActualTask;

      const maxPlannedTemp = Math.max(new Date(taskObjects[task._id].plannedFinishDate).getTime(), maxPlanned);
      maxPlannedTask = maxPlannedTemp !== maxPlanned ? taskObjects[task._id] : maxPlannedTask;

      maxActual = maxActualTemp;
      maxPlanned = maxPlannedTemp;

    });
  }
  

  if (dependencies.length > 0) {
    const [actualPosition, actualWidth] = calculateWithAndPosition(minEdge, maxActualTask.actualStartDate, maxActualTask.actualFinishDate, unit)
    const [plannedPosition, plannedWidth] = calculateWithAndPosition(minEdge, maxPlannedTask.plannedStartDate, maxPlannedTask.plannedFinishDate, unit)
    return {
      maxActual: {
        task: maxActualTask,
        date: new Date(maxActual),
        start: actualPosition,
        width: actualWidth,
        end: actualPosition + actualWidth,
        node: document.getElementById(maxActualTask._id),
        parentsMaxDependency: parentsMaxDependers.maxActual,
      },
      maxPlanned: {
        task: maxPlannedTask,
        date: new Date(maxPlanned), 
        start: plannedPosition,
        width: plannedWidth,
        end: plannedPosition + plannedWidth,
        node: document.getElementById(IdPrefix + maxPlannedTask._id),
        parentsMaxDependency: parentsMaxDependers.maxPlanned,
      }
    }
  }
  else{
    return {
      maxActual:{
        task: null,
        date: null,
        start: 0,
        width: 0,
        end: 0,
        parentsMaxDependency: parentsMaxDependers.maxActual,
      }, 
      maxPlanned:{
        task: null,
        date: null,
        start: 0,
        width: 0,
        end: 0,
        parentsMaxDependency: parentsMaxDependers.maxPlanned,
      }
    }
  }
}

// #############################################################################
// find min of dependencies
// #############################################################################

export function getMinDependency(taskObjects =  {}, task = {}, minEdge, IdPrefix = "", unit = 25){
  const parentsMinDependencies = getParentsMins(taskObjects, task, minEdge, IdPrefix, unit);
  let minActual = Number.MAX_VALUE;
  let minPlanned = Number.MAX_VALUE;
  
  let minActualTask = {};
  let minPlannedTask = {};

  
  const keys = Object.keys(taskObjects)

  for(const key of keys){
    let itsMyDependency = false;
    if(taskObjects[key].dependencies) {
      taskObjects[key].dependencies.forEach(item => {
        itsMyDependency = itsMyDependency || (item._id === task._id);
      })
    }
    if(itsMyDependency){

      const minActualTemp = Math.min(new Date(taskObjects[key].actualStartDate).getTime(), minActual);
      minActualTask = minActualTemp !== minActual ? taskObjects[key] : minActualTask;

      const minPlannedTemp = Math.min(new Date(taskObjects[key].plannedFinishDate).getTime(), minPlanned);
      minPlannedTask = minPlannedTemp !== minPlanned ? taskObjects[key] : minPlannedTask;

      minActual = minActualTemp;
      minPlanned = minPlannedTemp;
    }
  }
  if( minActual !== Number.MAX_VALUE){
    const [actualPosition, actualWidth] = calculateWithAndPosition(minEdge, minActualTask.actualStartDate, minActualTask.actualFinishDate, unit)
    const [plannedPosition, plannedWidth] = calculateWithAndPosition(minEdge, minPlannedTask.plannedStartDate, minPlannedTask.plannedFinishDate, unit)
    return {
      minActual: {
        task: minActualTask,
        date: new Date(minActual),
        start: actualPosition,
        width: actualWidth,
        end: actualPosition + actualWidth,
        node: document.getElementById(minActualTask._id),
        parentsMinDepender: parentsMinDependencies.minActual
      },
      minPlanned: {
        task: minPlannedTask,
        date: new Date(minPlanned), 
        start: plannedPosition,
        width: plannedWidth,
        end: plannedPosition + plannedWidth,
        node: document.getElementById(IdPrefix + minPlannedTask._id),
        parentsMinDepender: parentsMinDependencies.minPlanned
      }
    }
  }
  else {
    return {
      minActual: {
        task: null,
        date: null,
        start: Number.MAX_VALUE,
        width: 0,
        end: 0,
        parentsMinDepender: parentsMinDependencies.minActual
      }, 
      minPlanned: {
        task: null,
        date: null,
        start: Number.MAX_VALUE,
        width: 0,
        end: 0,
        parentsMinDepender: parentsMinDependencies.minPlanned
      }
    }
  }
}
