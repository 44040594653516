// this component use handleBrowseFile function via props to save a file into the parent state

import React, { useState, useEffect } from 'react'
import { withStyles } from "@material-ui/core"
import AttachFile from "@material-ui/icons/AttachFile"
import Done from "@material-ui/icons/CheckCircleOutline"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"

const AddAttachmentsButtonWithDropdown = ({ handleBrowseFile, classes, propsStyle, propsButtonTitle, replaceSubmitClicked, handleCancelFile }) => {

    const [isAttachmentChoosed, setIsAttachmentChoosed] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [dropDownShow, setdropDownShow] = useState(false);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        setSubmitClicked(false)
    }, [replaceSubmitClicked])

    const handleFileChoosen = (e) => {
        setAttachment(e.target.files[0])
        setIsAttachmentChoosed(!!e.target.files[0])
    }

    const handleTagChecked = e => {
        if (e.target.checked)
            setTags([...tags, e.target.value])
        else
            setTags(tags.filter(tag => {
                return tag !== e.target.value
            }))
    }

    const handleSubmit = (e) => {
        handleBrowseFile(attachment, JSON.stringify(tags))
        setdropDownShow(false)
        setSubmitClicked(true)
        setAttachment(null)
        setIsAttachmentChoosed(false)
    }

    const handleCancel = () => {
        handleCancelFile()
        setAttachment(null)
        setIsAttachmentChoosed(false)
        // setTagChecked(null)
        setdropDownShow(false)
        setSubmitClicked(false)
    }

    return (
        <div className={classes.wrapper}>
            <button
                type='button'
                className={classes.attachmentButton} onClick={() => setdropDownShow(!dropDownShow)}
                style={propsStyle}
            >
                <span>
                    <span>
                        {propsButtonTitle ? propsButtonTitle : 'ADD'}
                    </span>
                    {submitClicked ?
                        <AttachFile style={{ fontSize: '17px', transform: 'rotate(45deg)', marginRight: '2px' }} /> :
                        <KeyboardArrowDown style={{ width: '20px' }} />
                    }
                </span>
            </button>
            {dropDownShow &&
                <div className={classes.dropDown}>
                    <label className={classes.chooseFile}>
                        <span>Choose a file
                                {isAttachmentChoosed && <Done className={classes.doneIcon} />}
                        </span>
                        <input type='file' onChange={e => handleFileChoosen(e)} />
                    </label>

                    <label htmlFor="Invoice" >
                        <input type="checkbox" id="Invoice" value="Invoice"
                            onChange={e => handleTagChecked(e)}
                        />
                        Invoice
                        </label>

                    <label htmlFor="Contract" >
                        <input type="checkbox" id="Contract" value="Contract"
                            onChange={e => handleTagChecked(e)}
                        />
                        Contract
                        </label>

                    <label htmlFor="Change Order" >
                        <input type="checkbox" id="Change Order" value="Change Order"
                            onChange={e => handleTagChecked(e)}
                        />
                        Change Order
                        </label>

                    <label htmlFor="Misc Document" >
                        <input type="checkbox" id="Misc Document" value="Misc Document"
                            onChange={e => handleTagChecked(e)}
                        />
                        Misc Document
                    </label>

                    <div>
                        <input
                            className={isAttachmentChoosed ? classes.submitButton : classes.disabledSubmitButton}
                            type='button'
                            value='Submit'
                            disabled={!isAttachmentChoosed}
                            onClick={handleSubmit} />
                        <input className={classes.cancelButton} type='button' value='Cancel'
                            onClick={handleCancel} />
                    </div>
                </div>
            }
        </div>
    )
}

const styles = {
    wrapper: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        margin: '0 2px'
    },
    'attachmentButton': {
        backgroundColor: '#155794',
        display: 'flex!important',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        fontSize: '10px',
        borderRadius: '5px',
        fontWeight: '900',
        flex: '1',
        height: '38px',
        boxSizing: 'border-box',
        color: '#ffffff',
        border: 0,
        cursor: 'pointer',
        position: 'relative',
        padding: 0,
        // '& :hover': {
        //     backgroundColor: '#216fb7'
        // },
        '& input': {
            opacity: 0,
            width: 0,
            height: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer',
        },
        '& > span': {
            width: '100%',
            height: '38px',
            backgroundColor: 'transparet',
            borderRadius: '5px',
            boxSizing: 'border-box',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '& > span > span': {
            paddingRight: '10px'
        },
        '& svg': {
            cursor: 'pointer',
        }
    },
    dropDown: {
        position: 'absolute',
        top: '50px',
        width: '150px',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        zIndex: '10',
        backgroundColor: 'white',
        border: '1px solid #d6d6d6',
        borderRadius: '5px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'boarder-box',
        boxShadow: '0 0 10px #a5a5a5',
        '& > *:not(:last-child)': {
            width: '100%'
        },
        '&  label': {
            height: '33px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontSize: '12px',
            color: '#525252',
            borderBottom: '1px solid #d6d6d6',
            cursor: 'pointer',
            position: 'realtive',
            '& input': {
                position: 'relative',
                top: 0,
                left: 0
            }
        },
        '&  label:last-of-type': {
            borderBottom: 'none',
        },
        '&  div:last-child': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        '&  div:last-child > input': {
            height: '33px',
            margin: '2px',
            borderRadius: '5px',
        }
    },
    chooseFile: {
        backgroundColor: '#155794',
        display: 'flex!important',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        fontSize: '10px',
        borderRadius: '5px',
        fontWeight: '900',
        flex: '1',
        width: '100%',
        height: '38px',
        boxsizing: 'border-box',
        border: 0,
        cursor: 'pointer',
        position: 'relative',
        boxSizing: 'border-box',
        color: 'white!important',
        '& :hover': {
            backgroundColor: '#1f6eb7',
            borderRadius: '5px'
        },
        '& input': {
            opacity: 0,
            width: 0,
            height: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer',
        },
        '& > span': {
            width: '100%',
            height: '35px',
            zIndex: 100,
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '& svg': {
            cursor: 'pointer',
        }
    },
    submitButton: {
        width: '48%',
        backgroundColor: '#4E9E63',
        color: 'white',
        fontWeight: 900,
        fontSize: '10px',
        cursor: 'pointer',
    },
    disabledSubmitButton: {
        width: '48%',
        backgroundColor: 'transparent',
        color: '#4E9E63',
        fontWeight: 900,
        fontSize: '10px',
        border: '1px solid #4E9E63'
    },
    cancelButton: {
        width: '48%',
        backgroundColor: '#BCBCBC',
        color: 'white',
        fontWeight: 900,
        fontSize: '10px',
        cursor: 'pointer'
    },
    doneIcon: {
        width: '20px'
    }
}

export default withStyles(styles)(AddAttachmentsButtonWithDropdown)