import React from "react";
import { connect } from "react-redux";
import PDatePicker from "../../DatePicker/DatePicker";
import DateRange from "@material-ui/icons/DateRange";
import { Colors } from "../../../styles/colors";
import { reportActions, filterActions } from "../../../actions";
import {
  CostReportTab,
  IssueAndCostReportTab,
  ScheduleReportTab,
  ProgramReportTab,
  ProjectReportTab
} from "../../reportTabs";
import { savePDF } from "@progress/kendo-react-pdf";

// #####[ MATERIAL UI ]#########################################################
import { withStyles, Button, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import ThemeContext from "../../ContextApi/Context";

// #############################################################################
// Component
// #############################################################################
const styles = theme => ({
  container: {
    display: "flex"
  },
  itemsButton: {
    fontSize: "10px",
    marginRight: "5px",
    marginTop: "5px",
    backgroundColor: "white",
    border: "1px solid #487599",
    color: "#487599",
    height: "43px",
    borderRadius: "35px",
    "&:hover": {
      borderColor: "#256598"
    }
  },
  selectedItemButton: {
    fontSize: "10px",
    marginRight: "5px",
    marginTop: "5px",
    backgroundColor: "#256598",
    border: "1px solid white",
    color: "white",
    height: "43px",
    borderRadius: "35px",
    "&:hover": {
      borderColor: "white",
      backgroundColor: "#256598"
    }
  },
  periodWrapper: {
    display: "flex",
    marginBottom: "5px",
    paddingTop: "8px"
  },
  periodFilterContainer: {
    margin: 0,
    display: "flex",
    position: "relative"
  },
  datePickerStartDate: {
    margin: "0 2px",
    fontSize: "15px",
    height: "34px",
    borderRadius: 0,
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    border: "1px solid" + Colors.lightGrey,
    paddingLeft: "15px",
    "&:focus": {
      border: "1px solid #2483FF",
      boxShadow: "0 0 3px #2483FF"
    }
  },
  datePickerEndDate: {
    margin: "0 2px",
    fontSize: "15px",
    height: "34px",
    borderRadius: 0,
    border: "1px solid" + Colors.lightGrey,
    paddingLeft: "15px",
    "&:focus": {
      border: "1px solid #2483FF",
      boxShadow: "0 0 3px #2483FF"
    }
  },
  dateRangeIcon: {
    color: Colors.grey
  },

  iconButton: {
    position: "absolute",
    top: "8px",
    right: "15px",
    padding: "5px!important"
  },
  closeIcon: {
    fontSize: "14px",
    color: Colors.grey
  },
  wrong: {
    border: "1px solid red",
    boxShadow: "0 0 3px red"
  },
  dateRangeIconContainer: {
    border: "1px solid" + Colors.lightGrey,
    borderRadius: 0,
    backgroundColor: "white",
    margin: "0 2px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    cursor: "pointer",
    height: "38px"
  },
  viewStyle: {
    width: "100%",
    margin: "5px 0",
    padding: "10px",
    boxSizing: "border-box"
  },
  tableCaption: {
    backgroundColor: "#256598",
    color: "white",
    width: "calc(100% - 4px)",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    left: "2px",
    position: "relative"
  },
  tableCaptionContainer: {
    backgroundColor: "transparent",
    color: "white",
    width: "calc(100% + 8px)",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    left: "-4px",
    position: "relative"
  },
  tableSubCaption: {
    backgroundColor: "#A5B3BF",
    color: "black",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    marginTop: "5px",
    border: "1px solid #eff0f5",
    height: "45px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontWeight: "bold"
  },
  tableBody: {
    width: "100%"
  },
  headStyle: {
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "#A8B2BE"
  },
  dataStyle: {
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "white",
    color: "#505152"
  },
  subCaptionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dataStyleParent: {
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "#A8B2BE",
    color: "black",
    width: "20px"
  },
  viewButtonStyle: {
    color: "white",
    border: "1px solid white",
    height: "40px",
    fontSize: "15px",
    borderRadius: "5px",
    backgroundColor: "#256598",
    width: "90px",
    cursor: "pointer",
    "&:hover": {
      borderColor: "white",
      backgroundColor: "#0e5b98"
    }
  }
});

// #############################################################################
// component
// ##########################################################
class AdminProjectReports extends React.PureComponent {
  static contextType = ThemeContext;
  state = {
    fromDate: null,
    toDate: null
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(filterActions.pageFilterEdit(""));
  }

  handleClear = name => {
    this.setState({
      [name]: null
    });
  };

  handleFilterChanges = ({ fromDate, toDate }) => {
    this.setState(prevState => ({
      fromDate: fromDate || fromDate === null ? fromDate : prevState.fromDate,
      toDate: toDate || toDate === null ? toDate : prevState.toDate
    }));
  };

  handleDateFilter = () => {
    const { fromDate, toDate, selected } = this.state;
    const { dispatch } = this.props;
    const projectId = this.context;
    const data = {
      startDate: fromDate,
      endDate: toDate,
      type: selected,
      projectId
    };
    dispatch(reportActions.report(data));
  };

  selectReport = e => {
    const { dispatch } = this.props;
    const projectId = this.context;
    const data = {
      type: e.currentTarget.name,
      projectId
    };
    this.setState({ selected: e.currentTarget.name });
    dispatch(reportActions.report(data));
  };

  createPdf = html => {
    savePDF(html, {
      paperSize: "auto",
      fileName: `${this.state.selected}-report.pdf`,
      margin: 3
    });
  };

  render() {
    const { classes, project, report } = this.props;
    const { fromDate, toDate, selected } = this.state;
    const bodyRef = React.createRef();

    return (
      <div>
        <div className={classes.container}>
          <div>
            <Button
              className={
                selected === "project"
                  ? classes.selectedItemButton
                  : classes.itemsButton
              }
              onClick={this.selectReport}
              name={"project"}
              disabled //FIXME: Problem in design
            >
              {"Project Summary Report"}
            </Button>
            <Button
              className={
                selected === "schedule"
                  ? classes.selectedItemButton
                  : classes.itemsButton
              }
              onClick={this.selectReport}
              name={"schedule"}
            >
              {"Schedule Report"}
            </Button>
            <Button
              className={
                selected === "cost"
                  ? classes.selectedItemButton
                  : classes.itemsButton
              }
              onClick={this.selectReport}
              name={"cost"}
            >
              {"Cost Report"}
            </Button>
            <Button
              className={
                selected === "issueTask"
                  ? classes.selectedItemButton
                  : classes.itemsButton
              }
              onClick={this.selectReport}
              name={"issueTask"}
            >
              {"Issue & Tasks Report"}
            </Button>
            {project && (
              <Button
                className={
                  selected === "program"
                    ? classes.selectedItemButton
                    : classes.itemsButton
                }
                onClick={this.selectReport}
                name={"program"}
                disabled={!project.isProgram}
              >
                {"Program Report"}
              </Button>
            )}
          </div>
          <div className={classes.periodWrapper}>
            <div className={classes.periodFilterContainer}>
              <PDatePicker
                onChange={fromDate => this.handleFilterChanges({ fromDate })}
                name="fromDate"
                selected={fromDate}
                placeholderText="Start Date"
                className={
                  fromDate && toDate && fromDate > toDate
                    ? `${classes.datePickerStartDate} ${classes.wrong}`
                    : classes.datePickerStartDate
                }
                autoComplete="off"
              />
              {fromDate && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => this.handleClear("fromDate")}
                  name="fromDate"
                >
                  <Close className={classes.closeIcon} />
                </IconButton>
              )}
            </div>
            <div className={classes.periodFilterContainer}>
              <PDatePicker
                onChange={toDate => this.handleFilterChanges({ toDate })}
                name="toDate"
                selected={toDate}
                placeholderText="End Date"
                className={
                  fromDate && toDate && fromDate > toDate
                    ? `${classes.datePickerEndDate} ${classes.wrong}`
                    : classes.datePickerEndDate
                }
                autoComplete="off"
              />
              {toDate && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => this.handleClear("toDate")}
                  name="toDate"
                >
                  <Close className={classes.closeIcon} />
                </IconButton>
              )}
            </div>
            <button
              className={classes.dateRangeIconContainer}
              onClick={this.handleDateFilter}
            >
              <Tooltip title={"filter date"}>
                <DateRange className={classes.dateRangeIcon} />
              </Tooltip>
            </button>
            <button
              className={classes.viewButtonStyle}
              onClick={() => this.createPdf(bodyRef.current)}
            >
              {"Download"}
            </button>
          </div>
        </div>
        {report.length !== 0 && project && (
          <div className={classes.viewStyle} ref={bodyRef}>
            {selected === "project" && report.type === "project" ? (
              <ProjectReportTab project={project} />
            ) : selected === "schedule" && report.type === "schedule" ? (
              <ScheduleReportTab report={report.data} project={project} />
            ) : selected === "cost" && report.type === "cost" ? (
              <CostReportTab report={report.data} project={project} />
            ) : selected === "issueTask" && report.type === "issueTask" ? (
              <IssueAndCostReportTab report={report.data} project={project} />
            ) : selected === "program" && report.type === "program" ? (
              <ProgramReportTab report={report.data} project={project} />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

// #############################################################################
// map REDUX state to props
// #############################################################################
const mapStateToProps = state => {
  return {
    project: state.projects.project,
    report: state.reports
  };
};
const connectedAdminProjectReports = connect(mapStateToProps)(
  AdminProjectReports
);
const AdminProjectReportsWithStyles = withStyles(styles)(
  connectedAdminProjectReports
);

// #############################################################################
// Dialog: Delete Cost
// #############################################################################
export { AdminProjectReportsWithStyles as AdminProjectReports };
