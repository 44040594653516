import React from "react";
import Select from "react-select";
import { ReactInput } from "../../";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
// ############################################################################
// options
// ############################################################################
const selectOptions = [
  { value: "Project-Admin", label: "Admin" },
  { value: "Project-Client", label: "Client" },
  { value: "Project-Manager", label: "Manager" }
];

// ############################################################################
// component
// ############################################################################
class UserInfo extends React.PureComponent {
  state = {
    originalProjectList: [],
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    projectList: [],
    company: {},
    title: ""
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedUser !== this.props.selectedUser ||
      (prevProps.selectedUser === this.props.selectedUser &&
        prevProps.openEditUser !== this.props.openEditUser)
    ) {
      const { selectedUser } = this.props;
      this.setState({
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        email: selectedUser.email,
        phoneNumber: selectedUser.phoneNumber,
        projectList: selectedUser.projectList,
        title: selectedUser.title,
        company: selectedUser.company
          ? {
              label: selectedUser.company.name,
              value: selectedUser.company.name,
              id: selectedUser.company._id
            }
          : {}
      });
    }
  }

  // #####[ handle changes ]###################################################
  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  // #####[ handle changes ]###################################################
  handleChangeProjectRoles = index => data => {
    const { projectList } = this.state;
    this.setState({
      projectList: projectList.map((project, index2) =>
        index === index2
          ? {
              ...project,
              role: data.value
            }
          : project
      )
    });
  };

  handleChangeSelect = name => e => {
    this.setState({
      [name]: e
    });
  };
  // #####[ clear the state ]###################################################

  clearState = () => {
    this.props.closeDrawer();
    setTimeout(() => {
      this.setState({
        _id: undefined,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNumber: "",
        projectList: [],
        company: null,
        title: ""
      });
    }, 500);
  };

  // ##########################################################################
  // this function will call a function in parent component
  handleSave = e => {
    e.preventDefault();
    this.props.closeDrawer();
    const {
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      company,
      title,
      projectList
    } = this.state;

    this.props.handleEditUser({
      id: this.props.selectedUser._id,
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      companyId: company.id,
      title,
      projectList
    });
  };

  // ##########################################################################
  render() {
    const { classes, companies } = this.props;
    const { company } = this.state;
    return (
      <form
        autoComplete="off"
        onSubmit={this.handleSave}
        className={classes.userInfoRoot}
      >
        <Grid container className={classes.root} spacing={16}>
          <Grid item xs={12} container spacing={8}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">First Name</Typography>
              <ReactInput
                required={true}
                className={classes.userInfoInput}
                placeholder="First Name"
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Last Name</Typography>
              <ReactInput
                required
                className={classes.userInfoInput}
                placeholder="Last Name"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
                type="text"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={8}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Password</Typography>
              <ReactInput
                autocomplete="new-password"
                className={classes.userInfoInput}
                placeholder="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Phone Number</Typography>
              <ReactInput
                required
                className={classes.userInfoInput}
                placeholder="phone number"
                name="phoneNumber"
                value={this.state.phoneNumber}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={8}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Title</Typography>
              <ReactInput
                required
                className={classes.userInfoInput}
                placeholder="Title"
                name="title"
                value={this.state.title}
                onChange={this.handleChange}
                type="text"
              />
            </Grid>
            <Grid item xs={6} style={{ position: "relative" }}>
              <Typography variant="subtitle1">Company</Typography>
              <Select
                type="text"
                name="company"
                required
                onChange={this.handleChangeSelect("company")}
                placeholder="Company"
                options={companies.map(comp => ({
                  label: comp.name,
                  value: comp.name,
                  id: comp._id
                }))}
                className={classes.roleAssignSelect}
                value={company}
                pageSize={3}
              />
              <input
                tabIndex={-1}
                style={{
                  height: 0,
                  width: 0,
                  padding: 0,
                  margin: 0,
                  opacity: 0,
                  position: "absolute",
                  top: 30,
                  left: "50px"
                }}
                value={company}
                required
                onChange={this.handleChange}
                name="hiddenInput"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container >
            <Typography variant="subtitle1">Email</Typography>
            <ReactInput
              required
              className={classes.userInfoInput}
              placeholder="Email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              type="email"
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={8}
            className={classes.userInfoListHeader}
          >
            <Grid item xs={6}>
              PROJECTS
            </Grid>
            <Grid item xs={6}>
              ROLE
            </Grid>
          </Grid>
          {this.state.projectList.map((project, index) => (
            <Grid container item xs={12} key={index}>
              <Grid item xs={6} className={classes.userInfoProjectList}>
                {project.projectName}
              </Grid>
              <Grid item xs={6} className={classes.userInfoInputItems}>
                <Select
                  placeholder={project.role}
                  name={index}
                  options={selectOptions}
                  value={{
                    value: project.role,
                    label: project.role
                  }}
                  onChange={this.handleChangeProjectRoles(index)}
                />
              </Grid>
            </Grid>
          ))}
          <Grid
            container
            justify="flex-end"
            className={`${classes.userInfoFlexBox} ${classes.userInfoButtonHolder}`}
          >
            <Button className={classes.cancelButton} onClick={this.clearState}>
              {"cancel"}
            </Button>
            <Button type="submit" className={classes.saveButton}>
              {"save"}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const styles = () => ({
  userInfoRoot: {
    width: "500px",
    position: "relative",
    transition: "height 200ms linear",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    padding: "8px 10px 65px 10px",
    minHeight: "calc(100vh - 65px)"
  },
  userInfoHeader: {
    color: "#155794",
    fontSize: "14px",
    padding: "0px 5px",
    fontWeight: 400
  },
  userInfoFlexBox: {
    display: "flex"
  },

  userInfoInputItems: {
    padding: "5px",
    flex: 1
  },

  userInfoInput: {
    width: "100%"
  },
  roleAssignSelect: {},
  userInfoListHeader: {
    display: "flex",
    color: "#155794",
    fontSize: "14px",
    borderBottom: "1px solid #155794",
    margin: "12px 5px"
  },

  userInfoProjectList: {
    alignItems: "center",
    padding: "0px 10px",
    color: "#000000b3",
    fontSize: "14px",
    display: "flex",
    flex: 1
  },
  userInfoButtonHolder: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    width: "320px",
    padding: "0px 5px"
  },
  cancelButton: {
    color: "#fff",
    fontSize: "10px",
    fontWeight: 900,
    marginRight: "5px",
    backgroundColor: "#bcbcbc",
    width: "120px",
    height: "43px",
    "&:hover": {
      backgroundColor: "#afadad"
    }
  },
  saveButton: {
    color: "#fff",
    fontSize: "10px",
    fontWeight: 900,
    backgroundColor: "#58b370",
    height: "43px",
    width: "120px",
    "&:hover": {
      backgroundColor: "#4a965e"
    }
  }
});
// ############################################################################
// component export
// ############################################################################

const userInfoWithStyles = withStyles(styles)(UserInfo);
export { userInfoWithStyles as UserInfo };
