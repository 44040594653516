// React //
import React from "react";

// Redux //

// Material Ui //
import { withStyles } from "@material-ui/core";
import DateRange from '@material-ui/icons/DateRange'
import Close from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
// Components //
import PDatePicker from '../DatePicker/DatePicker'

// Reusable Functions //

// Styles //
import { Colors } from '../../styles/colors'

class NoteFilter extends React.PureComponent {
    state = {
        fromDate: null,
        toDate: null
    };

    handleClear = (name) => {
        this.setState({
            [name]: null
        })
    }

    handleFilterChanges = ({ fromDate, toDate, textFilter }) => {
        this.setState((prevState) => ({
            fromDate: fromDate || fromDate === null ? fromDate : prevState.fromDate,
            toDate: toDate || toDate === null ? toDate : prevState.toDate,
            textFilter: textFilter || textFilter === null ? textFilter : prevState.textFilter
        }))
    }

    handleNoteFilter = () => {
        const { fromDate, toDate, textFilter } = this.state
        this.props.handleFilterChanges({ fromDate, toDate, textFilter })
    }

    render() {
        const { classes } = this.props;
        const { fromDate, toDate } = this.state
        return (
            <div className={classes.periodWrapper}>
                <div className={classes.periodFilterContainer}>
                    <PDatePicker
                        onChange={(fromDate) => this.handleFilterChanges({ fromDate })}
                        name="fromDate"
                        selected={fromDate}
                        placeholderText="Start Date"
                        className={fromDate && toDate &&
                            fromDate > toDate ? `${classes.datePickerStartDate} ${classes.wrong}` : classes.datePickerStartDate}
                        autoComplete='off'
                    />
                    {fromDate &&
                        <IconButton className={classes.iconButton} onClick={() => this.handleClear('fromDate')} name='fromDate'>
                            <Close className={classes.closeIcon} />
                        </IconButton>
                    }
                </div>
                <div className={classes.periodFilterContainer}>
                    <PDatePicker
                        onChange={(toDate) => this.handleFilterChanges({ toDate })}
                        name="toDate"
                        selected={toDate}
                        placeholderText="End Date"
                        className={fromDate && toDate &&
                            fromDate > toDate ? `${classes.datePickerEndDate} ${classes.wrong}` : classes.datePickerEndDate}
                        autoComplete='off'
                    />
                    {toDate &&
                        <IconButton className={classes.iconButton} onClick={() => this.handleClear('toDate')} name='toDate' >
                            <Close className={classes.closeIcon} />
                        </IconButton>
                    }
                </div>
                <button className={classes.dateRangeIconContainer} onClick={this.handleNoteFilter}>
                    <DateRange className={classes.daterangeIcon} />
                </button>
            </div>
        );
    }
}

const Styles = {
    periodWrapper: {
        display: 'flex',
        marginBottom: '5px',
        position: 'absolute',
        right: -2,
        top: 6
    },
    searchIcon: {
        position: 'absolute',
        top: '7px',
        left: '10px',
        fontSize: '25px',
        color: Colors.grey
    },
    periodFilterContainer: {
        margin: 0,
        display: 'flex',
        position: 'relative'
    },
    datePickerStartDate: {
        margin: '0 2px',
        fontSize: '15px',
        height: '34px',
        borderRadius: 0,
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        border: '1px solid' + Colors.lightGrey,
        paddingLeft: '15px',
        '&:focus': {
            border: '1px solid #2483FF',
            boxShadow: '0 0 3px #2483FF'
        }
    },
    datePickerEndDate: {
        margin: '0 2px',
        fontSize: '15px',
        height: '34px',
        borderRadius: 0,
        border: '1px solid' + Colors.lightGrey,
        paddingLeft: '15px',
        '&:focus': {
            border: '1px solid #2483FF',
            boxShadow: '0 0 3px #2483FF'
        }
    },
    daterangeIcon: {
        color: Colors.grey
    },

    iconButton: {
        position: 'absolute',
        top: '8px',
        right: '15px',
        padding: '5px!important'
    },
    closeIcon: {
        fontSize: '14px',
        color: Colors.grey,
    },
    wrong: {
        border: '1px solid red',
        boxShadow: '0 0 3px red'
    },
    dateRangeIconContainer: {
        border: '1px solid' + Colors.lightGrey,
        borderRadius: 0,
        backgroundColor: 'white',
        margin: '0 2px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        cursor: 'pointer'
    }
};

export default withStyles(Styles)(NoteFilter)

