// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
// Components //
import { Header } from "./";
import { DownloadIcon } from "../Icons";
import { savePDF } from "@progress/kendo-react-pdf";

// Reusable Functions //
import TimeMonthDayYear from "../reusebleFunctions/TimeMonthDayYear";
// Styles //
import { Colors } from "../../styles/colors";

import {
  projectActions,
  clientActions,
  constructionActions
} from "../../actions";
import {
  projectUserActions,
  clientUserActions,
  constructionUserActions
} from "../../userActions";

class PMDGShopDrawingsSubmittalLogPdf extends React.PureComponent {
  componentDidMount() {
    const { dispatch, match, role } = this.props;
    const url = match.url.split("/");
    const projectId = url[url.length - 4];
    const formId = url[url.length - 1];
    const formName =
      url[url.length - 2] === "getendums"
        ? "PMDG Addendum Form"
        : url[url.length - 2] === "getDailyLog"
          ? "PMDG Daily Log Form"
          : url[url.length - 2] === "getRFI"
            ? "PMDG RFI Form"
            : url[url.length - 2] === "getShopDrawingsSubmittal"
              ? "PMDG Shop Drawings Submittal Log Form"
              : "";

    if (role === "admin") {
      dispatch(projectActions.getProjectUsers({ projectId }));
      dispatch(clientActions.getAllCompanies());
      dispatch(constructionActions.getForm({ formId, formName }));
    } else {
      dispatch(projectUserActions.getProjectUsers({ projectId }));
      dispatch(clientUserActions.getAllCompanies());
      dispatch(
        constructionUserActions.getForm({ formId, formName, projectId })
      );
    }
  }

  bodyRef = React.createRef();

  handleDownload = formName => () => {
    savePDF(this.bodyRef.current, {
      paperSize: "auto",
      fileName: `${formName}.pdf`,
      margin: 20
    });
  };

  render() {
    const {
      classes,
      formData,
      project,
      companies
    } = this.props;
    const company =
      companies &&
      project &&
      companies.find(com => com._id === project.client._id);
    function isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    }
    return (
      <div className={classes.root} >
        <div ref={this.bodyRef}>
          <Header />
          {formData && project && !isEmpty(formData) && !isEmpty(project) && (
            <>
              <div className={classes.title}>Addendums</div>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>PM DESIGN GROUP, INC</th>
                    <th>Addendums NO</th>
                    <th>Client</th>
                    <th>Project Name</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formData.address}</td>
                    <td>{formData.formNumber}</td>
                    <td>{company ? company.name : null}</td>
                    <td>{project && project.name}</td>
                    <td>{TimeMonthDayYear(formData.createDate)}</td>
                  </tr>
                </tbody>
              </table>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>For</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {formData.assignedTo &&
                        formData.assignedTo.map((person, index) => (
                          <span className={classes.assignedTo} key={index}>
                            {person.firstName} {person.lastName}
                            {formData.assignedTo.length - 1 !== index
                              ? ","
                              : null}
                          </span>
                        ))}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th className={classes.sheet}>Sheet</th>
                    <th className={classes.description}>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.sheets &&
                    formData.sheets.map((row, index) => (
                      <tr key={index}>
                        <td className={classes.sheet}>{row.title}</td>
                        <td className={classes.description}>{row.description}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.downloadButton}
            onClick={this.handleDownload("Addendums")}
          >
            Download Form
          </Button>
        </div>
        <div className={classes.attachmentsContainer}>
          {formData
            ? formData.attachments
              ? formData.attachments.map((file, index) => (
                <span className={classes.fileRepresent} key={index}>
                  <span className={classes.downloadIconAttachment}>
                    <DownloadIcon
                      link={file.link}
                    // onClick={this.handleRemoveAttachment(index)}
                    />
                  </span>
                  <span className={classes.fileName}>{file.name}</span>
                </span>
              ))
              : null
            : null}
        </div>
      </div>
    );
  }
}

const Styles = {
  title: {
    backgroundColor: Colors.darkBlue,
    color: "white",
    fontSize: "23px",
    padding: "10px",
    margin: "15px 7px 0 7px",
    fontWeight: "500"
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "7px",
    fontSize: "14px",
    "& thead tr th": {
      backgroundColor: "#A7B2BE",
      fontWeight: "500",
      width: "20%",
      height: "18px",
      padding: "10px"
    },
    "& tbody tr td": {
      textAlign: "center",
      padding: "10px",
      backgroundColor: "white",
      height: "18px",
      border: "1px solid #C2CBD1"
    }
  },
  assignedTo: {
    marginRight: "10px"
  },
  description: {
    width: "70%"
  },
  sheet: {
    width: "30%"
  },
  downloadButton: {
    backgroundColor: "#58B370",
    color: "white",
    width: "150px",
    marginTop: "20px",
    marginLeft: "7px",
    "&:hover": {
      backgroundColor: "#439c5a"
    }
  },
  fileRepresent: {
    borderRadius: "5px",
    border: "1px solid #D6D6D8",
    padding: "30px",
    margin: "5px",
    position: "relative",
    display: "inline-flex"
  },
  attachmentsContainer: {
    marginTop: "15px"
  },
  downloadIconAttachment: {
    marginRight: "10px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
const mapStateToProps = state => ({
  role: state.authentication.user.role,
  project: state.projects.project,
  companies: state.companies,
  formData: state.construction
});
export default connect(mapStateToProps)(
  withStyles(Styles)(PMDGShopDrawingsSubmittalLogPdf)
);
