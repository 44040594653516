// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { commentActions } from "../../actions";
import { commentUserActions } from "../../userActions";

// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// Components //
import AttachmentElement from "../Attachments/AttachmentElement";
import { EditIcon, DeleteIcon, ArrowAttachment } from "../Icons";
import AddNewComment from "./AddNewComment";
import { CancelButton, SaveButton } from "../Buttons";
import AddAttachmentsButton from "../Buttons/AddAttachment/AddAttachmentsButton";
import DeleteModal from "../Modal/DeleteModal";
import ThemeContext from "../ContextApi/Context";

// Reusable Functions //
import TimeMonthDayFullTime from "../reusebleFunctions/TimeMonthDayFullTime";

// Styles //
import { Colors } from "../../styles";

class CommentItem extends React.PureComponent {
  static contextType = ThemeContext;

  state = {
    editMode: false,
    isMaximizeForAttachments: false,
    isAddNewComment: false,
    comment: this.props.comment,
    text: this.props.comment.text,
    openDeleteModal: false,
    selectedOptionForDelete: {}
  };

  componentDidUpdate(prevProps) {
    if (prevProps.comment !== this.props.comment) {
      this.setState({
        comment: this.props.comment,
        text: this.props.comment.text
      });
    }
  }

  handleReplyButton = () => {
    this.setState(prevState => ({
      isAddNewComment: !prevState.isAddNewComment,
      isMaximizeForAttachments: false
    }));
  };

  handleAddCommentButton = () => {
    this.setState(prevState => ({
      isAddNewComment: !prevState.isAddNewComment
    }));
  };

  handleAttachmentsMaximizer = () => {
    this.setState(prevState => ({
      isMaximizeForAttachments: !prevState.isMaximizeForAttachments,
      isAddNewComment: false
    }));
  };

  hanldeEditMode = () => {
    this.setState(prevState => ({ editMode: !prevState.editMode }));
  };

  handleBrowseFile = attachment => {
    this.setState({
      attachment
    });
  };

  handleCommentCancel = () => {
    this.setState({
      editMode: false,
      commentText: ""
    });
  };

  handleCommentEdit = data => {
    const { dispatch, noteId, role, comment } = this.props;
    const { attachment, text } = this.state;
    const projectId = this.context;

    if (attachment) {
      let document = new FormData();
      document.append("projectId", projectId);
      // document.append("noteId", noteId);
      document.append("commentId", comment._id);
      document.append("document", attachment);

      dispatch(
        role === "adminFlag"
          ? commentActions.editComment(
            { projectId, noteId, commentId: comment._id, text },
            document
          )
          : commentUserActions.editComment(
            { projectId, noteId, commentId: comment._id, text },
            document
          )
      );
    } else {
      dispatch(
        role === "adminFlag"
          ? commentActions.editComment({
            projectId,
            noteId,
            commentId: comment._id,
            text
          })
          : commentUserActions.editComment({
            projectId,
            noteId,
            commentId: comment._id,
            text
          })
      );
    }

    this.setState({ editMode: false, text: "" });
  };

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
        openDeleteModal: true,
        selectedOptionForDelete
      })
      : this.setState({
        openDeleteModal: false
      });
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    });
  };

  render() {
    const {
      classes,
      noteId,
      allComments = [],
      dynamicMargin,
      role,
      dispatch,
      widget,
      userId
    } = this.props;
    const {
      editMode,
      isMaximizeForAttachments,
      isAddNewComment,
      comment,
      text
    } = this.state;
    return (
      <>
        <Grid
          container
          className={classes.commentWrapper}
          style={{ marginLeft: `${dynamicMargin}px` }}
        >
          <Grid item xs>
            <Grid container>
              <Grid item className={classes.senderName}>
                {this.props.comment.owner.firstName +
                  " " +
                  this.props.comment.owner.lastName}
                :
              </Grid>
              <Grid item xs className={classes.commentText}>
                {editMode ? (
                  <textarea
                    className={classes.textarea}
                    value={text}
                    onChange={this.handleChange}
                    name="text"
                  />
                ) : (
                    <>{this.props.comment.text}</>
                  )}
              </Grid>
            </Grid>
            {isMaximizeForAttachments && (
              <Grid container className={classes.AttachmentElementWrapper}>
                {comment.attachments.map((attachment, index) => (
                  <AttachmentElement
                    key={index}
                    attachment={attachment}
                    editMode={editMode}
                    className={classes.AttachmentElement}
                    handleDeleteModal={this.handleDeleteModal}
                  />
                ))}
              </Grid>
            )}
          </Grid>

          <Grid
            item
            className={classes.actionAndReplyContainer}
            style={
              editMode
                ? {
                  width: "235px",
                  display: "flex"
                }
                : {}
            }
          >
            <Grid container className={classes.actionContainer}>
              {editMode ? (
                <Grid container>
                  <AddAttachmentsButton
                    handleBrowseFile={this.handleBrowseFile}
                  />
                  <SaveButton
                    disabled={!text}
                    onClick={this.handleCommentEdit}
                  />
                  <CancelButton onClick={this.handleCommentCancel} />
                </Grid>
              ) : (
                  <Grid container className={classes.iconsContainer}>
                    <Button
                      className={classes.replyButton}
                      onClick={this.handleReplyButton}
                    >
                      Reply
                  </Button>
                    {comment.attachments && comment.attachments[0] && (
                      <ArrowAttachment
                        onClick={this.handleAttachmentsMaximizer}
                        isMaximizeForAttachments={isMaximizeForAttachments}
                      />
                    )}
                    {!widget && comment.owner._id === userId &&
                      <>
                        <EditIcon onClick={this.hanldeEditMode} />
                        <DeleteIcon onClick={() => this.handleDeleteModal(comment)} />
                      </>
                    }
                  </Grid>
                )}
            </Grid>
            {!editMode && (
              <>
                <Grid container className={classes.date}>
                  {TimeMonthDayFullTime(comment.createDate)}
                  {/* {this.props.comment.createDate} */}
                </Grid>
              </>
            )}
          </Grid>

          {isAddNewComment && (
            <AddNewComment
              handleAddCommentButton={this.handleAddCommentButton}
              isAddNewComment={isAddNewComment}
              noteId={noteId}
              parentComment={comment._id}
            />
          )}
        </Grid>
        {allComments
          .filter(
            subComment => subComment.parentComment === this.props.comment._id
          )
          .map((subComment, index) => (
            <CommentItem
              key={index}
              noteId={noteId}
              comment={subComment}
              allComments={allComments}
              classes={classes}
              dynamicMargin={Number(dynamicMargin) + 30}
              role={role}
              dispatch={dispatch}
              widget={widget}
            />
          ))}

        <DeleteModal
          open={this.state.openDeleteModal}
          handleDeleteModal={this.handleDeleteModal}
          selectedOptionForDelete={{
            commentId: this.state.selectedOptionForDelete._id,
            deleteOrAdd: "delete",
            noteId
          }}
          adminAction={commentActions.deleteComment}
          userAction={commentUserActions.deleteComment}
          //optional
          modalTitle={"Delete Comment"}
        />
      </>
    );
  }
}

const Styles = {
  commentWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    borderTop: "1px solid" + Colors.lightGrey,
    padding: "5px 5px",
    boxSizing: "border-box",
    marginTop: "5px",
    margin: " 0 5px 0 30px"
  },
  senderName: {
    width: "120px",
    height: "100%",
    color: Colors.darkBlue,
    fontWeight: 500,
    padding: "0 5px",
    boxSizing: "border-box",
    fontSize: "14px"
  },
  actionAndReplyContainer: {
    width: "180px",
    color: Colors.darkBlue,
    fontWeight: 500,
    padding: "0 5px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  date: {
    width: "170px",
    height: "100%",
    color: Colors.darkenThanLightGrey,
    padding: "0 5px",
    boxSizing: "border-box",
    // marginTop: '10px',
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "12px",
    fontWeight: 400,
    alignItems: "flex-end"
  },
  commentText: {
    height: "100%",
    fontSize: "14px",
    marginRight: "20px",
    boxSizing: "border-box"
  },
  replyButtonWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end"
    // marginTop: '10px',
  },
  replyButton: {
    color: Colors.darkBlue,
    padding: "0",
    fontSize: "13px",
    width: "30px"
  },
  AttachmentElementWrapper: {
    marginTop: "10px"
  },
  textarea: {
    width: "100%",
    borderRadius: "30px"
  },
  actionContainerNotEditMode: {
    width: "200px"
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "flex-end"
  }
};

const mapStateToProps = state => ({
  userId: state.authentication.user._id
});

export default connect(mapStateToProps)(withStyles(Styles)(CommentItem));
