import { calculateWithAndPosition } from "./"
// #############################################################################
// this function will calculates the height of the tasks in the tasks board
// #############################################################################

let HEIGHT;

export function calculateHeightOfTasks(minEdge, tasks, heightUnit = 56, widthUnit = 25){
  HEIGHT = heightUnit * (-1) ;
  const keys = Object.keys(tasks).filter(key => tasks[key].parentTask === null).sort((a,b) => (tasks[a].order - tasks[b].order));
  keys.forEach(key => {
    const [ left, width ]  = calculateWithAndPosition(minEdge, tasks[key].actualStartDate, tasks[key].actualFinishDate, widthUnit);
    tasks[key].height = HEIGHT = heightUnit + HEIGHT;
    tasks[key].left = left;
    tasks[key].width = width;
    tasks[key].right = left + width;

    if(tasks[key].isExpandForm){
      HEIGHT += tasks[key].additionalHeight;
    }

    if(tasks[key].subTask.length > 0 &&  tasks[key].expand )
      calculateHeightOfSubTasks(minEdge, tasks, tasks[key], heightUnit, widthUnit);
  })
  return [tasks, HEIGHT + heightUnit]; 
}


// #############################################################################
// this function will calculate the height of the sub tasks
// #############################################################################


function calculateHeightOfSubTasks(minEdge, tasks, parent, heightUnit, widthUnit){
  const subTasks = parent.subTask.sort((a,b) => (tasks[a].order - tasks[b].order));
  subTasks.forEach(key => {
    const [ left, width ]  = calculateWithAndPosition(minEdge, tasks[key].actualStartDate, tasks[key].actualFinishDate, widthUnit);
    tasks[key].height = HEIGHT = heightUnit + HEIGHT;
    tasks[key].left = left;
    tasks[key].width = width;
    tasks[key].right = left + width
    if(tasks[key].isExpandForm){
      HEIGHT += tasks[key].additionalHeight;
    }
    if(tasks[key].subTask.length > 0 &&  tasks[key].expand)
      calculateHeightOfSubTasks(minEdge, tasks, tasks[key], heightUnit)
  })
}