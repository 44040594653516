//
import React from "react";
import { connect } from "react-redux";
import { projectUserActions } from "../../../userActions";
import { filterActions } from "../../../actions";
import { Router, Switch, Route, withRouter, Link } from "react-router-dom";
import { ReactInput, GeneralInfo } from "../../";
import {
  UserProjectDashboard,
  UserProjectUsers,
  UserProjectCosts
} from "../ProjectDashboard";
import {
  UserProjectSchedule,
  UserProjectIssues,
  UserProjectNotes,
  UserProjectConstructionDocuments,
  UserProjectReports
} from "../ProjectDashboard";
import { UserProjectProjectDocuments } from "../ProjectDashboard";
import { history } from "../../../helpers";
import ThemeContext from "../../ContextApi/Context";
import { BackButton } from "../../Buttons";

// #####[ MATERIAL UI ]#########################################################
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

// #####[ MATERIAL ICON ]#######################################################
import SearchIcon from "@material-ui/icons/Search";

// #############################################################################
// Styles
// #############################################################################

const styles = theme => ({
  // #####[ search bar ]########################################################
  searchBar: {
    borderBottom: "1px solid #e1e1e1",
    padding: 5,
    position: "sticky",
    top: 0,
    zIndex: 1001,
    backgroundColor: "#f4f5f9",
    minHeight: "49px",
    boxSizing: "border-box"
  },
  searchInput: {
    backgroundColor: "#ffffff00",
    color: "#00000099",
    paddingLeft: "40px",
    border: "none",
    zIndex: "0",
    "&:focus": {
      boxShadow: "none"
    }
  },
  searchIcon: {
    color: "#00000070",
    position: "absolute",
    marginLeft: "5px",
    zIndex: "0"
  },
  searchInputHolder: {
    position: "relative",
    display: "flex",
    alignItems: "center"
  },

  // #####[ TABS STYLES ]#######################################################
  tabPanelContainer: {
    padding: 10,
    overflowY: "auto",
    boxSizing: "border-box",
    height: "calc(100vh - 120px)"
  },
  generalInfo: {
    margin: "60px 20px 0px"
  },
  tabs: {
    flex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column"
  },
  tabButtons: {
    display: "flex",
    alignItems: "center",
    top: "49px",
    margin: 0,
    position: "sticky",
    borderBottom: "1px solid #e1e1e1",
    padding: "3px 15px",
    zIndex: "999",
    backgroundColor: "#f4f5f9",
    boxShadow: "0 -2px 9px #0000000d",

    "& > a > div": {
      borderRadius: "5px !important",
      padding: "2px 10px"
    },
    "& > a > div:hover": {
      backgroundColor: "#00000014",
      color: "#0048a79c"
    },
    "& > a": {
      textDecoration: "none",
      fontWeight: 600,
      color: "#000000a1",
      fontSize: 13,
      marginRight: "5px",
      lineHeight: "25px"
    }
  },
  linkSelected: {
    backgroundColor: "#487599 !important",
    color: "white !important"
  },
  // ###########################################################################
  info: {
    boxSizing: "border-box",
    width: "100%",
    padding: "0 8px",
    transition: "all 150ms",
    zIndex: "1"
  },
  // ###########################################################################
  waiting: {
    position: "fixed",
    top: "50%",
    left: "50%"
  }
});

// #############################################################################
// component
// #############################################################################

class UserProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      showSearchInput: true,
      projectId: this.props.match.params.projectId
    };
  }

  // ###########################################################################
  // component did mount
  // ###########################################################################

  componentDidMount = () => {
    const projectId = this.props.match.params.projectId;
    this.props.dispatch(projectUserActions.getProject({ projectId }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.pageFilter !== this.props.pageFilter) {
      this.setState({ filter: this.props.pageFilter });
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const projectId = props.match.params.projectId;

    if (state.projectId !== projectId) {
      props.dispatch(projectUserActions.getProject({ projectId }));
      return { projectId };
    } else {
      return null;
    }
  };

  // ###########################################################################
  // clear search
  // ###########################################################################

  clearSearch = () => {
    this.setState({ filter: "" });
  };

  // ###########################################################################
  // hide search input
  // ###########################################################################

  toggleSearchInput = condition => {
    this.setState({ showSearchInput: condition });
  };

  handleSetFilter = ({ target: { value } }) => {
    this.props.dispatch(filterActions.pageFilterEdit(value));
    this.setState({ filter: value });
  };

  // ###########################################################################
  // create routes for the selected project
  // ###########################################################################

  routes = () => {
    const { projectId } = this.props.match.params;
    return {
      default: `/user-dashboard/projects/${projectId}`,
      projectDashboard: `/user-dashboard/projects/${projectId}/project-dashboard`,
      projectUsers: `/user-dashboard/projects/${projectId}/project-users`,
      projectSchedule: `/user-dashboard/projects/${projectId}/project-schedule`,
      projectCosts: `/user-dashboard/projects/${projectId}/project-costs`,
      projectIssues: `/user-dashboard/projects/${projectId}/project-issues`,
      projectNotes: `/user-dashboard/projects/${projectId}/project-journals`,
      projectDocuments: `/user-dashboard/projects/${projectId}/project-documents`,
      constructionProject: `/user-dashboard/projects/${projectId}/construction-project`,
      projectReports: `/user-dashboard/projects/${projectId}/project-reports`
    };
  };

  // ###########################################################################
  // Render
  // ###########################################################################

  render() {
    const { classes, project } = this.props;
    const { pathname = "" } = this.props.location;

    if (project) {
      return (
        <React.Fragment>
          <ThemeContext.Provider value={this.state.projectId}>
            {/* ##################[ search top bar ]########################## */}

            <Grid container className={classes.searchBar}>
              <BackButton />
              <Grid
                style={{
                  display: this.state.showSearchInput ? "flex" : "none"
                }}
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.searchInputHolder}
              >
                <SearchIcon className={classes.searchIcon} />
                <ReactInput
                  name="filter"
                  value={this.state.filter}
                  onChange={this.handleSetFilter}
                  placeholder="Filter lists"
                  className={classes.searchInput}
                  type="text"
                />
              </Grid>
            </Grid>

            {/* #####[ TABS ]################################################# */}

            <div className={classes.tabButtons}>
              <Link to={this.routes().projectDashboard}>
                <div
                  className={
                    pathname === this.routes().projectDashboard ||
                    pathname === this.routes().default
                      ? classes.linkSelected
                      : null
                  }
                >
                  PROJECT DASHBOARD
                </div>
              </Link>

              <Link to={this.routes().projectUsers}>
                <div
                  className={
                    pathname === this.routes().projectUsers
                      ? classes.linkSelected
                      : null
                  }
                >
                  USERS
                </div>
              </Link>

              <Link to={this.routes().projectSchedule}>
                <div
                  className={
                    pathname === this.routes().projectSchedule
                      ? classes.linkSelected
                      : null
                  }
                >
                  SCHEDULE
                </div>
              </Link>

              <Link to={this.routes().projectCosts}>
                <div
                  className={
                    pathname === this.routes().projectCosts
                      ? classes.linkSelected
                      : null
                  }
                >
                  COSTS
                </div>
              </Link>

              <Link to={this.routes().projectIssues}>
                <div
                  className={
                    pathname === this.routes().projectIssues
                      ? classes.linkSelected
                      : null
                  }
                >
                  ISSUE-TASKS
                </div>
              </Link>

              <Link to={this.routes().projectNotes}>
                <div
                  className={
                    pathname === this.routes().projectNotes
                      ? classes.linkSelected
                      : null
                  }
                >
                  JOURNALS
                </div>
              </Link>

              <Link to={this.routes().projectDocuments}>
                <div
                  className={
                    pathname === this.routes().projectDocuments
                      ? classes.linkSelected
                      : null
                  }
                >
                  DOCUMENTS
                </div>
              </Link>

              <Link to={this.routes().constructionProject}>
                <div
                  className={
                    pathname.includes(this.routes().constructionProject)
                      ? classes.linkSelected
                      : null
                  }
                >
                  CONSTRUCTION
                </div>
              </Link>

              <Link to={this.routes().projectReports}>
                <div
                  className={
                    pathname === this.routes().projectReports
                      ? classes.linkSelected
                      : null
                  }
                >
                  REPORTS
                </div>
              </Link>
            </div>

            <div className={classes.tabPanelContainer}>
              <Router history={history}>
                <Switch>
                  <Route
                    path={this.routes().projectDashboard}
                    render={() => <UserProjectDashboard />}
                  />
                  <Route
                    path={this.routes().projectUsers}
                    render={() => <UserProjectUsers />}
                  />
                  <Route
                    path={this.routes().projectSchedule}
                    render={() => <UserProjectSchedule />}
                  />
                  <Route
                    path={this.routes().projectCosts}
                    render={() => <UserProjectCosts />}
                  />
                  <Route
                    path={this.routes().projectIssues}
                    render={() => <UserProjectIssues />}
                  />
                  <Route
                    path={this.routes().projectNotes}
                    render={() => <UserProjectNotes />}
                  />
                  <Route
                    path={this.routes().projectDocuments}
                    render={() => <UserProjectProjectDocuments />}
                  />
                  <Route
                    path={this.routes().constructionProject}
                    render={() => (
                      <UserProjectConstructionDocuments
                        toggleSearchInput={this.toggleSearchInput}
                        searchQuery={this.state.filter}
                        clearSearch={this.clearSearch}
                      />
                    )}
                  />
                  <Route
                    path={this.routes().projectReports}
                    render={() => <UserProjectReports />}
                  />

                  <Route
                    exact
                    path={this.routes().default}
                    render={() => <UserProjectDashboard />}
                  />
                </Switch>
              </Router>
            </div>

            <GeneralInfo />
          </ThemeContext.Provider>
        </React.Fragment>
      );
    } else {
      return (
        <div className={classes.waiting}>
          <CircularProgress thickness={1} />
        </div>
      );
    }
  }
}

// #############################################################################
// map state to props and connect material UI styles to the project
// #############################################################################

const mapStateToProps = state => {
  return {
    project: state.projects.project,
    role: state.users.userProject[0].role,
    pageFilter: state.filters.pageFilter
  };
};

const UserProjectAdminWithRouter = withRouter(
  connect(mapStateToProps)(withStyles(styles)(UserProject))
);

// #############################################################################
// export
// #############################################################################

export { UserProjectAdminWithRouter as UserProject };
