import React from "react";
import { connect } from "react-redux";
import {
  projectUserActions,
  clientUserActions,
  issueUserActions
} from "../../../userActions";

import { ReactInput } from "../../";
import { LocalList } from "../../LocalList";

// Material UI /////////////////////////////////////////////////////////////////
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// Material UI ICONS ///////////////////////////////////////////////////////////
import SearchIcon from "@material-ui/icons/Search";
import { BackButton } from "../../Buttons";

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  // ///////////////////// search bar //////////////////////////////////////////
  searchBar: {
    borderBottom: "1px solid #e1e1e1",
    padding: 5,
    position: "fixed",
    zIndex: 1000,
    backgroundColor: "#f4f5f9"
  },
  searchInput: {
    backgroundColor: "#ffffff00",
    color: "#00000099",
    paddingLeft: "40px",
    border: "none",
    zIndex: "0",
    "&:focus": {
      boxShadow: "none"
    }
  },
  searchIcon: {
    color: "#00000070",
    position: "absolute",
    marginLeft: "5px",
    zIndex: "0"
  },
  searchInputHolder: {
    position: "relative",
    display: "flex",
    alignItems: "center"
  },
  // ///////////////////////////////////////////////////////////////////////////
  projects: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px 20px 0px",
    marginTop: "48px",
    boxSizing: "border-box",
    transition: "padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms"
  },
  listHeader: {
    display: "flex",
    flexDirection: "row"
  },
  spacer: {
    flex: 1
  },
  title: {
    color: "#000000b5",
    height: "25px",
    margin: "0px",
    paddingLeft: "5px"
  },
  checkbox: {
    padding: "7px!important"
  },
  checkboxLabel: {
    marginRight: "30px"
  }
});

// #############################################################################
// component
// #############################################################################
class UserProjects extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      openIssueList: false,
      parentProjectForAddingSubProject: null,
      projectsStatusForFiltering: [],
      open: false,
      "on hold": false,
      finished: false,
      canceled: false,
      "not started": false
    };
  }

  // ###########################################################################
  // component did mount
  // ###########################################################################

  componentDidMount = () => {
    this.props.dispatch(projectUserActions.getMyProjects());
    this.props.dispatch(clientUserActions.getAllCompanies());
    this.props.dispatch(issueUserActions.getUserIssues());
  };

  // ###########################################################################
  // handle open issue list
  // ###########################################################################

  handleListShift = flag => {
    this.setState({ openIssueList: flag });
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.checked
    });

    target.checked
      ? this.setState(prevState => ({
          projectsStatusForFiltering: [
            ...prevState.projectsStatusForFiltering,
            target.name
          ]
        }))
      : this.setState(prevState => ({
          projectsStatusForFiltering: prevState.projectsStatusForFiltering.filter(
            item => item !== target.name
          )
        }));
  };

  // #####[ render ]############################################################
  render() {
    const { classes, projects = [], issues, userId } = this.props;
    return (
      <React.Fragment>
        {/* //////////////////// search top bar ////////////////////////////// */}

        <Grid container className={classes.searchBar}>
          <BackButton />
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            className={classes.searchInputHolder}
          >
            <SearchIcon className={classes.searchIcon} />
            <ReactInput
              name="searchKeyWord"
              value={this.state.filter}
              onChange={e => this.setState({ filter: e.target.value })}
              placeholder="Filter Projects"
              className={classes.searchInput}
              type="text"
            />
          </Grid>
        </Grid>

        <div
          className={classes.projects}
          style={{ paddingRight: this.state.openIssueList ? "440px" : "20px" }}
        >
          <LocalList
            filter={this.state.filter}
            handleListShift={this.handleListShift.bind(this)}
            data={projects}
            link={"user-dashboard/projects/"}
            adminRole={false}
            dispatch={this.props.dispatch}
            companies={this.props.companies}
            issues={issues}
            userId={userId}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    rootprojects: state.projects.rootProjects,
    projects: state.projects ? state.projects.projects : [],
    architects: state.projects.architects,
    users: state.users.users ? state.users.users : [],
    companies: state.companies ? state.companies : [],
    issues: state.issues ? state.issues : [],
    userId: state.authentication.user._id
  };
};

const UserProjectsConnect = connect(mapStateToProps)(UserProjects);
const UserProjectsStyles = withStyles(styles)(UserProjectsConnect);

export { UserProjectsStyles as UserProjects };
