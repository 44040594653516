import React from "react";
import { connect } from "react-redux";
import {
  projectActions,
  userActions,
  clientActions,
  issueActions,
} from "../../../actions";
import { ReactInput } from "../../";
import { ProjectForm } from "../../";
import { LocalList } from "../../LocalList";
import classNames from "classnames";
import { CircularWaiting } from "../../";
// ######[ MATERIAL COMPOENNTS ]################################################

import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import { BackButton } from "../../Buttons";
// #############################################################################
// styles
// #############################################################################

const styles = (theme) => ({
  searchBar: {
    borderBottom: "1px solid #e1e1e1",
    padding: 5,
    position: "fixed",
    top: 0,
    zIndex: 1000,
    backgroundColor: "#f4f5f9",
  },
  searchInput: {
    backgroundColor: "#ffffff00",
    color: "#00000099",
    paddingLeft: "40px",
    border: "none",
    zIndex: "0",
    "&:focus": {
      boxShadow: "none",
    },
  },
  searchIcon: {
    color: "#00000070",
    position: "absolute",
    marginLeft: "5px",
    zIndex: "0",
  },
  searchInputHolder: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },

  // ///////////////////////////////////////////////////////////////////////////
  projects: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "48px",
    padding: "15px 20px 0px",
    boxSizing: "border-box",
    transition: "padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
  },
  content: {
    height: "100vh",
    overflowY: "auto",
  },
  //////////////////////////////////////////////////////////////////////////////
  listHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "15px",
  },
  spacer: {
    flex: 1,
  },
  addProjectButton: {},
  desc: {
    margin: "20px 0",
    fontSize: "12px",
    color: "gray",
  },
  closeButton: {
    position: "absolute",
    right: "20px",
    cursor: "pointer",
    color: "white",
  },
  modalHeader: {
    height: "60px",
  },
  modalForm: {
    maxHeight: "500px",
    overflowY: "scroll",
  },
  checkbox: {
    padding: "7px!important",
  },
  checkboxLabel: {
    marginRight: "30px",
  },
});

// #############################################################################
// component
// #############################################################################

class AdminProjects extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      openAddProject: false,
      openIssueList: false,
      parentProjectForAddingSubProject: null,
    };
  }

  // ###########################################################################
  //
  // ###########################################################################

  componentDidMount = () => {
    this.props.dispatch(projectActions.getAllProjects());
    this.props.dispatch(projectActions.getAllArchitects());
    this.props.dispatch(userActions.getAllUsers());
    this.props.dispatch(clientActions.getAllCompanies());
    this.props.dispatch(issueActions.getUserIssues());
  };

  // ###########################################################################
  // Cost Order
  // ###########################################################################
  dispatchChangeProjectOrder = (projectId, order) => {
    const { dispatch } = this.props;
    dispatch(
      projectActions.changeProjectOrder({
        projectId,
        order,
      })
    );
  };

  // ###########################################################################
  // handle open issue list
  // ###########################################################################

  handleListShift = (flag) => {
    this.setState({ openIssueList: flag, openAddProject: false });
  };

  // ###########################################################################
  // handle open issue list
  // ###########################################################################

  handleAddProject = (data) => {
    this.props.dispatch(projectActions.addProject(data));
    this.setState((prevState) => ({
      openAddProject: !prevState.openAddProject,
    }));
  };

  // ###########################################################################
  // handle Toggle add Project from
  // ###########################################################################

  handleToggleAddProject = () => {
    this.setState((prevState) => ({
      openAddProject: !prevState.openAddProject,
    }));
  };

  // ###########################################################################
  // handle add subproject
  // ###########################################################################

  handleAddSubProject = ({ parentProject, type }) => {
    this.setState({
      openAddProject: true,
      parentProjectForAddingSubProject: parentProject,
      type,
    });
  };

  handleRemoveAddSubProjectFlag = () => {
    this.setState({
      parentProjectForAddingSubProject: null,
    });
  };

  // ###########################################################################
  // Render
  // ###########################################################################

  render() {
    const {
      classes,
      projects = [],
      issues,
      userId,
      issueRequest,
      clientRequest,
      projectRequest,
    } = this.props;
    const {
      openAddProject,
      openIssueList,
      parentProjectForAddingSubProject,
      type,
    } = this.state;
    return (
      <>
        {!(issueRequest || clientRequest || projectRequest) ? (
          <div className={classes.root}>
            {/* /////////////////////////main Projects ///////////////////////// */}
            <main
              className={classNames(classes.content, {
                [classes.contentShift]: openAddProject,
              })}
            >
              {/* //////////////////// search top bar ////////////////////////// */}
              <Grid container className={classes.searchBar}>
                <Grid container item xs={12} justify="flex-start">
                  <BackButton />
                  <Grid item xs container className={classes.searchInputHolder}>
                    <SearchIcon className={classes.searchIcon} />
                    <ReactInput
                      name="searchKeyWord"
                      value={this.state.filter}
                      onChange={(e) =>
                        this.setState({ filter: e.target.value })
                      }
                      placeholder="Filter Projects"
                      className={classes.searchInput}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* //////////////////// Add User In-line ///////////////////////// */}
              <div
                className={classes.projects}
                style={{
                  paddingRight:
                    openIssueList || openAddProject ? "440px" : "20px",
                }}
              >
                <div className={classes.listHeader} />
                <LocalList
                  openAddProject={openAddProject}
                  filter={this.state.filter}
                  handleListShift={this.handleListShift}
                  data={projects}
                  issues={issues}
                  link={"admin-dashboard/projects/"}
                  adminRole={true}
                  dispatchChangeProjectOrder={this.dispatchChangeProjectOrder}
                  dispatch={this.props.dispatch}
                  handleAddSubProject={this.handleAddSubProject}
                  companies={this.props.companies}
                  userId={userId}
                  issueRequest={issueRequest}
                  clientRequest={clientRequest}
                  projectRequest={projectRequest}
                />
              </div>
            </main>
            {/* /////////////////////////Open button /////////////////////////// */}
            <ProjectForm
              fullForm={true}
              parentProjectForAddingSubProject={
                parentProjectForAddingSubProject
              }
              type={type}
              openAddProject={openAddProject}
              handleToggleAddProject={this.handleToggleAddProject}
              architects={this.props.architects}
              companies={this.props.companies}
              users={this.props.users}
              allProjects={projects}
              handleAddProject={this.handleAddProject}
              handleRemoveAddSubProjectFlag={this.handleRemoveAddSubProjectFlag}
            />
            {/* //////////////////////////////////////////////////////////////// */}
          </div>
        ) : (
          <CircularWaiting />
        )}
      </>
    );
  }
}

// #############################################################################
// connect the redux states and material styles to the component
// #############################################################################

const mapStateToProps = (state) => {
  return {
    rootprojects: state.projects.rootProjects,
    projects: state.projects ? state.projects.projects : [],
    architects: state.projects.architects,
    users: state.users.users ? state.users.users : [],
    companies: state.companies ? state.companies : [],
    issues: state.issues ? state.issues : [],
    userId: state.authentication.user._id,
    issueRequest: state.progressBar.issueRequest,
    clientRequest: state.progressBar.clientRequest,
    projectRequest: state.progressBar.projectRequest,
  };
};

const AdminProjectsConnect = connect(mapStateToProps)(AdminProjects);
const AdminProjectsStyles = withStyles(styles)(AdminProjectsConnect);

// #############################################################################
// export
// #############################################################################

export { AdminProjectsStyles as AdminProjects };
