// React //
import React from "react";

// Redux //
import { connect } from "react-redux";

// Material Ui //
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ViewHeadline from "@material-ui/icons/ViewHeadline";
import Dialog from "@material-ui/core/Dialog";
// React Select //

// Components //
import { ReactInput } from "../";

// Reusable Functions //

// Styles //
import { Colors } from "../../styles/colors";
class purchaseIDModalContent extends React.PureComponent {
  state = {
    purchaseId: ""
  };

componentDidUpdate (){  
  if (!this.props.open){
    this.setState({
      purchaseId: ''
    })
  }
}

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    });
  };

  render() {
    const {
      classes,
      handleOpenPurchaseModal,
      handleSetPurchaseId,
      open
    } = this.props;
    const { purchaseId } = this.state;
    return (
      <Dialog
        open={open}
        keepMounted
        onClose={() => handleOpenPurchaseModal()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.wrapper}
      >
        <div className={classes.modalWrapper}>
          <div className={classes.headerWrapper}>
            <ViewHeadline className={classes.headerIcon} />
            <p className={classes.headerText}>Purchase ID</p>
          </div>
          <div className={classes.bodyWrapper}>
            <p className={classes.message}>Please enter the purchase ID.</p>
            <ReactInput
              value={purchaseId}
              onChange={this.handleChange}
              name="purchaseId"
              placeholder='Purchase ID'
            />
            <div className={classes.buttonsWrapper}>
              <Button
                color="primary"
                disabled={!purchaseId}
                onClick={() => handleSetPurchaseId(purchaseId)}
              >
                OK
              </Button>
              <Button color="secondary" onClick={() => handleSetPurchaseId()}>
                later
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

const Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column"
  },
  modalWrapper: {
    width: "600px"
  },
  headerWrapper: {
    height: "50px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: Colors.darkBlue,
    fontSize: "19px",
    fontWeight: "bold",
    color: "white"
  },
  headerIcon: {
    color: "white",
    fontSize: "32px",
    transform: "rotate(90deg)",
    margin: "0 10px"
  },
  bodyWrapper: {
    margin: "10px 0",
    padding: "0 20px",
    display: "flex",
    flexDirection: "column"
  },
  message: {
    fontSize: "16px",
    fontWeight: 500,
    color: Colors.darkBlue
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "16px 0"
  }
};

const mapStateToProps = state => ({
  role: state.projects.project ? state.projects.project.role : undefined,
  projectId: state.projects.project ? state.projects.project._id : undefined
});

export default connect(mapStateToProps)(
  withStyles(Styles)(purchaseIDModalContent)
);
