export const constructionConstants = {
  GET_ALL_FORMS_SUCCESS: "GET_ALL_FORMS_SUCCESS",
  GET_ALL_FORMS_FAILURE: "GET_ALL_FORMS_FAILURE",

  ADD_FORM_SUCCESS: "ADD_FORM_SUCCESS",
  ADD_FORM_FAILURE: "ADD_FORM_FAILURE",

  EDIT_FORM_SUCCESS: "EDIT_FORM_SUCCESS",
  EDIT_FORM_FAILURE: "EDIT_FORM_FAILURE",

  DELETE_FORM_SUCCESS: "DELETE_FORM_SUCCESS",
  DELETE_FORM_FAILURE: "DELETE_FORM_FAILURE",

  GET_FORM_SUCCESS: "GET_FORM_SUCCESS",
  GET_FORM_FAILURE: "GET_FORM_FAILURE"
};
