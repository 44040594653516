import React from "react";
import { ReactInput } from "../";
import PDatePicker from "components/DatePicker/DatePicker";
import { connect } from "react-redux";
import { usaCities, projectStatus } from "../../constants";
import Select from "react-select";
import DeleteModal from "../Modal/DeleteModal";
import { projectActions } from "../../actions";
import { projectUserActions } from "../../userActions";

// #####[ material ]############################################################

import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Tooltip from "@material-ui/core/Tooltip";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  select: {
    flex: 1
  },
  compHeader: {
    width: "100%"
    // flex: 1
  },
  desc: {
    margin: "20px 0",
    fontSize: "12px",
    color: "gray"
  },
  button: {
    width: "200px",
    height: "38px",
    fontSize: "0.7rem",
    color: "#fff",
    borderRadius: "5px",
    fontWeight: "700"
  },
  deleteButton: {
    backgroundColor: "#f44336",
    "&:hover": {
      backgroundColor: "#c62828"
    }
  },
  editButton: {
    backgroundColor: "#6f6f6f",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: "#5d5d5d"
    }
  },
  saveButton: {
    backgroundColor: "#58b370",
    marginRight: "10px",
    width: "80px",
    "&:hover": {
      backgroundColor: "#49965e"
    }
  },
  cancelButton: {
    backgroundColor: "#6f6f6f",
    width: "80px",
    "&:hover": {
      backgroundColor: "#5d5d5d"
    }
  },

  label: {
    padding: "0px 13px",
    fontSize: "14px",
    color: "#545454"
  },
  data: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "3px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#0006",
    "& > div": {
      flex: 1
    },
    minHeight: "38px",
    margin: "3px",
    padding: "3px",
    border: "1px solid #00000017"
  },

  dataNotEditable: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#dcdcdc5c",
    borderRadius: "5px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#0006",
    minHeight: "38px",
    margin: "3px",
    padding: "3px",
    border: "1px solid #00000008"
  },
  buttonHolder: {
    padding: "15px 5px"
  },
  projectNameHolder: {
    padding: "6px 0px"
  },
  projectName: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    border: "1px solid #e3e4e8",
    borderRadius: "3px",
    padding: "5px 13px"
  },
  projectNameText: {
    // height: "38px",
    fontSize: "16px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    color: "#0000009e",
    padding: "5px 0"
  },
  projectNameInput: {
    flex: 1,
    backgroundColor: "#00000000 !important",
    fontSize: "16px",
    fontWeight: "700",
    color: "#0000009e",
    width: "600px"
  },
  confirmText: {
    width: "100%",
    marginTop: "3px"
  },
  commandText: {
    marginTop: "15px"
  },
  alertText: {
    color: "red"
  },
  DatePicker: {
    width: "100%"
  },
  dataTextarea: {
    minHeight: "32px!important",
    height: "auto",
    padding: "3px",
    display: "flex",
    justifyContent: "left",
    alignItems: "start"
  },
  inputs: {
    flex: 1
  },
  textarea: {
    flex: 1,
    boxSizing: "border-box",
    borderRadius: "5px",
    border: "1px solid #d7d7d7",
    margin: 0,
    padding: "0px 5px",
    backgroundColor: "#ffffff00 !important",
    fontSize: "14px",
    color: "#000000bd",
    resize: "vertical",
    minHeight: "64px",
    fontFamily: "Arial",
    lineHeight: "24px"
  },
  preTag: {
    flex: 1,
    whiteSpace: "pre-wrap",
    color: "#0006 !important",
    fontSize: "14px!important",
    lineHeight: "24px",
    margin: 0,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif',
    minHeight: "64px",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    WebkitHyphens: "auto",
    MsHyphens: "auto",
    hyphens: "auto",
    paddingRight: "20px"
  },
  minimizeIconsWrapper: {
    padding: "4px"
  },
  minimizeIcon: {
    transition: "0.2s"
  },
  IconsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    height: "100%",
    alignItems: "flex-start",
    paddingTop: "7px"
  },
  inlineStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "200px",
    paddingTop: "6px",
    fontWeight: 500,
    fontSize: "14px"
  }
});

// #############################################################################
// component
// #############################################################################

class ProjectInfo extends React.PureComponent {
  state = {
    // [ project info ] ////////////////////////////////////////////////
    projectId: "",
    name: "",
    city: "",
    state: "",
    region: "",
    address: "",
    siteNumber: "",
    realEstateManager: "",
    constructionManager: "",
    architecturalFirm: "",
    architectPM: "",
    developer: "",
    generalContractor: "",
    startDate: undefined,
    estimatedEndDate: undefined,
    description: "",
    PMdgJobCode: "",
    client: "",
    ////////////////////////////////////////////////////////////////////
    editMode: false,
    ////////////////////////////////////////////////////////////////////
    isProjectInfoMinimize: false,
    openDeleteModal: false,
    selectedOptionForDelete: {}
  };

  componentDidUpdate(prevProps) {
    const { project } = this.props;
    if (prevProps.project !== this.props.project) {
      this.setState({
        projectId: project._id,
        name: project.name,
        status: { label: project.status, value: project.status },
        city: { label: project.city, value: project.city },
        state: { label: project.state, value: project.state },
        region: project.region,
        address: project.address,
        siteNumber: project.siteNumber,
        realEstateManager: project.realEstateManager
          ? {
              label:
                project.realEstateManager.firstName +
                " " +
                project.realEstateManager.lastName,
              value:
                project.realEstateManager.firstName +
                " " +
                project.realEstateManager.lastName,
              _id: project.realEstateManager._id
            }
          : "",
        architectPM: project.architectPM
          ? {
              label:
                project.architectPM.firstName +
                " " +
                project.architectPM.lastName,
              value:
                project.architectPM.firstName +
                " " +
                project.architectPM.lastName,
              _id: project.architectPM._id
            }
          : "",
        constructionManager: project.constructionManager
          ? {
              label:
                project.constructionManager.firstName +
                " " +
                project.constructionManager.lastName,
              value:
                project.constructionManager.firstName +
                " " +
                project.constructionManager.lastName,
              _id: project.constructionManager._id
            }
          : "",
        architecturalFirm: project.architecturalFirm
          ? {
              label:
                project.architecturalFirm.firstName +
                " " +
                project.architecturalFirm.lastName,
              value:
                project.architecturalFirm.firstName +
                " " +
                project.architecturalFirm.lastName,
              _id: project.architecturalFirm._id
            }
          : "",
        developer: project.developer
          ? {
              label:
                project.developer.firstName + " " + project.developer.lastName,
              value:
                project.developer.firstName + " " + project.developer.lastName,
              _id: project.developer._id
            }
          : "",
        generalContractor: project.generalContractor
          ? {
              label:
                project.generalContractor.firstName +
                " " +
                project.generalContractor.lastName,
              value:
                project.generalContractor.firstName +
                " " +
                project.generalContractor.lastName,
              _id: project.generalContractor._id
            }
          : "",
        startDate:
          project.startDate !== null ? new Date(project.startDate) : undefined,
        estimatedEndDate:
          project.estimatedEndDate !== null
            ? new Date(project.estimatedEndDate)
            : undefined,
        description: project.description,
        PMdgJobCode: project.PMdgJobCode,
        isProjectInfoMinimize: false,
        client: project.client
          ? {
              label: project.client.name,
              value: project.client.name,
              _id: project.client._id
            }
          : ""
      });
    }
  }

  // #######################################################################################
  // ####################              Dialog: Delete              ####################
  // #######################################################################################

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
          openDeleteModal: true,
          selectedOptionForDelete
        })
      : this.setState({
          openDeleteModal: false
        });
  };

  // #########################################################################
  // Dialog: Delete Cost
  // #########################################################################
  // this will handle the change in the inputs in the component state

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleStartDate = date => {
    this.setState({ startDate: date });
  };

  handleEndDateDate = date => {
    this.setState({ estimatedEndDate: date });
  };

  dateFromatter = dateString => {
    const date = new Date(dateString);
    return (
      date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()
    );
  };

  // ###########################################################################
  // Dialog: Delete Cost
  // ###########################################################################
  // this function handles the edit in the project

  handleEditMode = event => {
    event.preventDefault();
    const { project } = this.props;
    this.setState(prevState => ({
      projectId: project._id,
      name: project.name,
      status: { label: project.status, value: project.status },
      city: { label: project.city, value: project.city },
      state: { label: project.state, value: project.state },
      region: project.region,
      address: project.address,
      siteNumber: project.siteNumber,
      realEstateManager: project.realEstateManager
        ? {
            label:
              project.realEstateManager.firstName +
              " " +
              project.realEstateManager.lastName,
            value:
              project.realEstateManager.firstName +
              " " +
              project.realEstateManager.lastName,
            _id: project.realEstateManager._id
          }
        : "",
      architectPM: project.architectPM
        ? {
            label:
              project.architectPM.firstName +
              " " +
              project.architectPM.lastName,
            value:
              project.architectPM.firstName +
              " " +
              project.architectPM.lastName,
            _id: project.architectPM._id
          }
        : "",
      constructionManager: project.constructionManager
        ? {
            label:
              project.constructionManager.firstName +
              " " +
              project.constructionManager.lastName,
            value:
              project.constructionManager.firstName +
              " " +
              project.constructionManager.lastName,
            _id: project.constructionManager._id
          }
        : "",
      architecturalFirm: project.architecturalFirm
        ? {
            label:
              project.architecturalFirm.firstName +
              " " +
              project.architecturalFirm.lastName,
            value:
              project.architecturalFirm.firstName +
              " " +
              project.architecturalFirm.lastName,
            _id: project.architecturalFirm._id
          }
        : "",
      developer: project.developer
        ? {
            label:
              project.developer.firstName + " " + project.developer.lastName,
            value:
              project.developer.firstName + " " + project.developer.lastName,
            _id: project.developer._id
          }
        : "",
      generalContractor: project.generalContractor
        ? {
            label:
              project.generalContractor.firstName +
              " " +
              project.generalContractor.lastName,
            value:
              project.generalContractor.firstName +
              " " +
              project.generalContractor.lastName,
            _id: project.generalContractor._id
          }
        : "",
      startDate:
        project.startDate !== null ? new Date(project.startDate) : undefined,
      estimatedEndDate:
        project.estimatedEndDate !== null
          ? new Date(project.estimatedEndDate)
          : undefined,
      description: project.description,
      PMdgJobCode: project.PMdgJobCode,
      isProjectInfoMinimize: true,
      client: project.client
        ? {
            label: project.client.name,
            value: project.client.name,
            _id: project.client._id
          }
        : "",
      editMode: !prevState.editMode
    }));
  };

  // ###########################################################################
  // Dialog: Delete Cost
  // ###########################################################################

  cancelEditMode = event => {
    event.preventDefault();
    this.setState({ editMode: false });
  };

  // ###########################################################################
  // this function handles the edit in the project
  // ###########################################################################

  handleSubmitEdits = event => {
    event.preventDefault();
    this.props.editProject({
      ...this.props.project, // this will maintaine the rest of the project info
      name: this.state.name,
      city: this.state.city.value,
      state: this.state.state.value,
      status: this.state.status.value,
      region: this.state.region,
      address: this.state.address,
      siteNumber: this.state.siteNumber,
      realEstateManager: this.state.realEstateManager._id,
      constructionManager: this.state.constructionManager._id,
      architecturalFirm: this.state.architecturalFirm._id,
      architectPM: this.state.architectPM._id,
      developer: this.state.developer._id,
      generalContractor: this.state.generalContractor._id,
      startDate: new Date(this.state.startDate).toJSON(),
      estimatedEndDate: new Date(this.state.estimatedEndDate).toJSON(),
      description: this.state.description,
      projectId: this.props.project._id,
      PMdgJobCode: this.state.PMdgJobCode,
      client: this.state.client._id,
      editProject: true
    });
    this.setState(prevProps => ({ editMode: !prevProps.editMode }));
  };

  handleSelectChange = (option, event) => {
    this.setState({ [event.name]: option });
  };

  // #########################################################################
  // this will handle the list of labels in the cities select box
  // #########################################################################

  handleCities = iniState => {
    if (this.state.state) {
      let { value } = this.state.state;
      value = value ? value : iniState;
      const cities =
        usaCities[value] &&
        usaCities[value].map(city => ({ label: city, value: city }));
      return cities;
    } else {
      return [{ label: "", value: "" }];
    }
  };

  // #########################################################################
  // this function labels all the states for the select box
  // #########################################################################
  //
  handleStates = () => {
    let states = [];
    for (const eachState in usaCities) {
      states.push({ label: eachState, value: eachState });
    }
    return states;
  };

  // #########################################################################
  // options of the project stattus
  // #########################################################################
  //
  projectStatuses = () => {
    return projectStatus.map(item => ({ label: item, value: item }));
  };

  handleprojectInfoMinimizer = () => {
    this.setState(prevState => ({
      isProjectInfoMinimize: !prevState.isProjectInfoMinimize,
      editMode: false
    }));
  };

  handleUsers = () => {
    return this.props.users.map(item => ({
      label: item.firstName + " " + item.lastName,
      value: item.firstName + " " + item.lastName,
      _id: item._id
    }));
  };

  handleCompanies = () => {
    return this.props.companies.map(item => ({
      label: item.name,
      value: item.name,
      _id: item._id
    }));
  };

  projectStatusColor = () => {
    if (this.props.project.status)
      switch (this.props.project.status.toLowerCase()) {
        case "not started":
          return "#80809F";
        case "canceled":
          return "#E64E00";
        case "finished":
          return "#00DE67";
        case "open":
          return "#0CBBD0";
        case "on hold":
          return "#FFA500";
        default:
          return "#80809F";
      }
    else return "#80809F";
  };
  // #########################################################################
  // render
  // #########################################################################

  render = () => {
    const { classes, project, editable, role } = this.props;
    const { isProjectInfoMinimize } = this.state;
    let states = this.handleStates();
    const dropDownStyle = {
      control: styles => ({ ...styles, height: "34px" })
    };
    return (
      <React.Fragment>
        <Grid container className={classes.projectNameHolder}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.projectName}
            style={
              role === "Project-Admin" || role === "Project-Client"
                ? { backgroundColor: "#E8E8EB", cursor: "inherit" }
                : {}
            }
          >
            <Typography variant="h6" className={classes.projectNameText}>
              {this.state.editMode ? (
                <ReactInput
                  type="text"
                  required
                  value={this.state.name}
                  name="name"
                  onChange={this.handleChange}
                  className={classes.projectNameInput}
                />
              ) : (
                <span>{project.name} </span>
              )}
            </Typography>
            <Grid className={classes.IconsWrapper}>
              {!isProjectInfoMinimize && (
                <div
                  className={classes.inlineStatus}
                  style={{ color: this.projectStatusColor() }}
                >
                  {project.status}
                </div>
              )}
              {!this.state.editMode ? (
                <React.Fragment>
                  {role !== "Project-Admin" && role !== "Project-Client" && (
                    <React.Fragment>
                      <Tooltip title="Edit Project Info">
                        <IconButton
                          component="span"
                          onClick={this.handleEditMode}
                          className="cost-list-edit"
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Project Info">
                        <IconButton
                          component="span"
                          onClick={this.handleDeleteModal}
                          className="cost-list-delete"
                        >
                          <CancelOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  )}
                  <Tooltip
                    title={!isProjectInfoMinimize ? "Maximize" : "Minimize"}
                  >
                    <IconButton
                      className={classes.minimizeIconsWrapper}
                      onClick={this.handleprojectInfoMinimizer}
                    >
                      <KeyboardArrowDown
                        className={classes.minimizeIcon}
                        style={
                          isProjectInfoMinimize
                            ? { transform: "rotate(180deg)" }
                            : { transform: "rotate(0deg)" }
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid>
                    <Button
                      onClick={this.handleSubmitEdits}
                      className={`${classes.saveButton} ${classes.button}`}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={`${classes.cancelButton} ${classes.button}`}
                      type="button"
                      onClick={this.cancelEditMode}
                    >
                      cancel
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>

        {isProjectInfoMinimize && (
          <form>
            <Grid container>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Client:</span>
                  {this.state.editMode ? (
                    <Select
                      options={this.handleCompanies()}
                      className={classes.select}
                      name={"client"}
                      value={this.state.client}
                      onChange={this.handleSelectChange}
                      styles={dropDownStyle}
                      required
                      placeholder="Client:"
                      defaultValue="null"
                      maxMenuHeight={250}
                    />
                  ) : (
                    <React.Fragment>
                      {project.client && project.client.name}
                    </React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Region:</span>
                  {this.state.editMode ? (
                    <ReactInput
                      type="text"
                      value={this.state.region}
                      name="region"
                      placeholder="Region"
                      onChange={this.handleChange}
                      className={classes.inputs}
                    />
                  ) : (
                    <React.Fragment>{project.region}</React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Project Status:</span>
                  {this.state.editMode ? (
                    <Select
                      options={this.projectStatuses()}
                      name={"status"}
                      value={this.state.status}
                      onChange={this.handleSelectChange}
                      styles={dropDownStyle}
                      required
                      placeholder="Project Status"
                      maxMenuHeight={250}
                    />
                  ) : (
                    <React.Fragment>
                      {project.status ? project.status : null}
                    </React.Fragment>
                  )}
                </div>
              </Grid>

              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>PMDG Job Code:</span>
                  {this.state.editMode ? (
                    <ReactInput
                      type="text"
                      value={this.state.PMdgJobCode}
                      placeholder="PMDG Job Code"
                      name="PMdgJobCode"
                      onChange={this.handleChange}
                      className={classes.inputs}
                    />
                  ) : (
                    <React.Fragment>{project.PMdgJobCode}</React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>State:</span>
                  {this.state.editMode ? (
                    <Select
                      options={states}
                      name={"state"}
                      onChange={this.handleSelectChange}
                      styles={dropDownStyle}
                      required
                      placeholder="Select State"
                      maxMenuHeight={250}
                      defaultValue={{
                        value: project.state,
                        label: project.state
                      }}
                    />
                  ) : (
                    <React.Fragment>{project.state}</React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>City:</span>
                  {this.state.editMode ? (
                    <Select
                      options={this.handleCities(project.state)}
                      name={"city"}
                      onChange={this.handleSelectChange}
                      styles={dropDownStyle}
                      required
                      placeholder="Select city"
                      maxMenuHeight={250}
                      defaultValue={{
                        value: project.city,
                        label: project.city
                      }}
                    />
                  ) : (
                    <React.Fragment>{project.city}</React.Fragment>
                  )}
                </div>
              </Grid>

              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Site Number:</span>
                  {this.state.editMode ? (
                    <ReactInput
                      type="number"
                      value={this.state.siteNumber}
                      name="siteNumber"
                      placeholder="Site Number"
                      min="0"
                      onChange={this.handleChange}
                      className={classes.inputs}
                    />
                  ) : (
                    <React.Fragment>{project.siteNumber}</React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Real Estate Manager:</span>
                  {this.state.editMode ? (
                    <Select
                      options={this.handleUsers()}
                      name={"realEstateManager"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Real Estate Manager"
                      defaultValue="null"
                      value={this.state.realEstateManager}
                      maxMenuHeight={170}
                    />
                  ) : (
                    <React.Fragment>
                      {project.realEstateManager &&
                        project.realEstateManager.firstName +
                          " " +
                          project.realEstateManager.lastName}
                    </React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Construction Manager:</span>
                  {this.state.editMode ? (
                    <Select
                      options={this.handleUsers()}
                      name={"constructionManager"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Construction Manager"
                      defaultValue="null"
                      value={this.state.constructionManager}
                      maxMenuHeight={250}
                    />
                  ) : (
                    <React.Fragment>
                      {project.constructionManager &&
                        project.constructionManager.firstName +
                          " " +
                          project.constructionManager.lastName}
                    </React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Architectural Firm:</span>
                  {this.state.editMode ? (
                    <Select
                      options={this.handleUsers()}
                      name={"architecturalFirm"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Architectural Firm"
                      defaultValue="null"
                      value={this.state.architecturalFirm}
                      maxMenuHeight={250}
                    />
                  ) : (
                    <React.Fragment>
                      {project.architecturalFirm &&
                        project.architecturalFirm.firstName +
                          " " +
                          project.architecturalFirm.lastName}
                    </React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Architect PM:</span>
                  {this.state.editMode ? (
                    <Select
                      options={this.handleUsers()}
                      name={"architectPM"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Architect PM"
                      defaultValue="null"
                      value={this.state.architectPM}
                      maxMenuHeight={250}
                    />
                  ) : (
                    <React.Fragment>
                      {project.architectPM &&
                        project.architectPM.firstName +
                          " " +
                          project.architectPM.lastName}
                    </React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Developer:</span>
                  {this.state.editMode ? (
                    <Select
                      options={this.handleUsers()}
                      name={"developer"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="Developer"
                      defaultValue="null"
                      value={this.state.developer}
                      maxMenuHeight={250}
                    />
                  ) : (
                    <React.Fragment>
                      {project.developer &&
                        project.developer.firstName +
                          " " +
                          project.developer.lastName}
                    </React.Fragment>
                  )}
                </div>
              </Grid>

              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>General Contractor:</span>
                  {this.state.editMode ? (
                    <Select
                      options={this.handleUsers()}
                      name={"generalContractor"}
                      onChange={this.handleSelectChange}
                      className={classes.select}
                      placeholder="General Contractor"
                      defaultValue="null"
                      value={this.state.generalContractor}
                      maxMenuHeight={250}
                    />
                  ) : (
                    <React.Fragment>
                      {project.generalContractor &&
                        project.generalContractor.firstName +
                          " " +
                          project.generalContractor.lastName}
                    </React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Start Date:</span>
                  {this.state.editMode ? (
                    <PDatePicker
                      onChange={this.handleStartDate}
                      name="startDate"
                      selected={this.state.startDate}
                      placeholderText="Start Date"
                      className={`${classes.DatePicker} item-edit-cost-inLine`}
                      autoComplete={"off"}
                    />
                  ) : (
                    <React.Fragment>
                      {project.startDate &&
                        this.dateFromatter(project.startDate)}
                    </React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Estimated End Date:</span>
                  {this.state.editMode ? (
                    <PDatePicker
                      onChange={this.handleEndDateDate}
                      name="estimatedEndDate"
                      selected={this.state.estimatedEndDate}
                      placeholderText="Estimated End Date"
                      minDate={this.state.startDate}
                      className={`${classes.DatePicker} item-edit-cost-inLine`}
                      autoComplete={"off"}
                    />
                  ) : (
                    <React.Fragment>
                      {project.estimatedEndDate &&
                        this.dateFromatter(project.estimatedEndDate)}
                    </React.Fragment>
                  )}
                </div>
              </Grid>

              {/* ############################################################## */}
              <Grid item xs={12} sm={12} md={12}>
                <div
                  className={editable ? classes.data : classes.dataNotEditable}
                >
                  <span className={classes.label}>Address:</span>
                  {this.state.editMode ? (
                    <ReactInput
                      type="text"
                      value={this.state.address}
                      placeholder="Address"
                      name="address"
                      onChange={this.handleChange}
                      className={classes.inputs}
                    />
                  ) : (
                    <React.Fragment>{project.address}</React.Fragment>
                  )}
                </div>
              </Grid>
              {/* ############################################################## */}
              <Grid item xs={12} sm={12} md={12}>
                <div
                  className={`${
                    editable ? classes.data : classes.dataNotEditable
                  } ${classes.dataTextarea}`}
                >
                  <span className={classes.label} style={{ paddingTop: "3px" }}>
                    Project Description:
                  </span>
                  {this.state.editMode ? (
                    <textarea
                      className={classes.textarea}
                      value={this.state.description}
                      name="description"
                      onChange={this.handleChange}
                      autoComplete="off"
                      type="text"
                      rows={1}
                    />
                  ) : (
                    <React.Fragment>
                      <pre className={classes.preTag}>
                        {project.description}
                      </pre>
                    </React.Fragment>
                  )}
                </div>
              </Grid>
            </Grid>
            {/* ############################################################## */}
          </form>
        )}

        {/* //////////////////// Delete User Dialog ///////////////////////// */}
        <DeleteModal
          open={this.state.openDeleteModal}
          handleDeleteModal={this.handleDeleteModal}
          selectedOptionForDelete={{ projectId: project._id }}
          adminAction={projectActions.deleteProject}
          userAction={projectUserActions.deleteProject}
          //optional
          modalTitle={"Delete Project"}
          messageText={`This action will delete the entire project and the users !!!.You won't be able to restore, are you sure you want to delete?`}
          //confirm
          isConfirmNeeded={true}
          confirmInputPlaceHolder="Project Name"
          confirmWithName={this.props.projectName}
        />
      </React.Fragment>
    );
  };
}

// #############################################################################
// connect styles to the component
// #############################################################################

const mapStateToProps = state => {
  return {
    state,
    project: state.projects.project ? state.projects.project : {},
    role: state.projects.project ? state.projects.project.role : undefined,
    projectName: state.projects.project
      ? state.projects.project.name
      : undefined,
    editable: state.projects.project
      ? state.projects.project.role !== "Project-Admin" &&
        state.projects.project.role !== "Project-Client"
        ? true
        : false
      : undefined,
    users: state.users.users ? state.users.users : [],
    companies: state.companies ? state.companies : []
  };
};

const ProjectInfoWithStyles = withStyles(styles)(ProjectInfo);
const connectedProjectInfo = connect(mapStateToProps)(ProjectInfoWithStyles);

// #############################################################################
// export
// #############################################################################

export { connectedProjectInfo as ProjectInfo };
