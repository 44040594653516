// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { costActions } from "../../actions";
import { costUserActions } from "../../userActions";
// Material Ui //
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddAttachmentsButton from '../Buttons/AddAttachment/AddAttachmentsButtonWithDropdown'

// React Select //
import Select from "react-select";

// Components //
import { ReactInput } from "../";
import ThemeContext from "../ContextApi/Context";

// Reusable Functions //

// Styles //

class AddCostInline extends React.PureComponent {
  static contextType = ThemeContext;
  state = {
    name: "",
    approvalStatus: { value: "needs review", label: "needs review" },
    budget: "",
    revised: "",
    actual: "",
    purchaseId: "",
    note: '',
    replaceSubmitClicked: false
  };

  // #######################################################################################
  // #####################               Add Cost                  #########################
  // #######################################################################################

  handleAddCost = e => {
    e.preventDefault();
    const projectId = this.context;
    const { dispatch } = this.props;
    let {
      name,
      note,
      budget,
      revised,
      actual,
      parentId,
      approvalStatus,
      purchaseId,
      tags,
      attachment
    } = this.state;

    parentId = parentId ? parentId : "null";
    parentId = parentId.value ? parentId.value : parentId;
    purchaseId = purchaseId ? purchaseId : undefined;
    const document = new FormData()
    document.append('name', name)
    document.append('budget', budget)
    revised && document.append('revised', revised)
    document.append('actual', actual)
    document.append('parentId', parentId)
    document.append('projectId', projectId)
    note && document.append('note', note)
    document.append('approvalStatus', approvalStatus.value)
    purchaseId && document.append('purchaseId', purchaseId)
    document.append("tags", tags);
    attachment && document.append("document", attachment);


    dispatch(
      this.props.role === "adminFlag"
        ? costActions.addCost(document)
        : costUserActions.addCost(document)
    );
    this.setState(prevState => ({
      modalAddCost: false,
      name: "",
      note: "",
      budget: "",
      revised: "",
      actual: "",
      parentId: "null",
      projectId: "",
      approvalStatus: { value: "needs review", label: "needs review" },
      purchaseId: "",
      replaceSubmitClicked: !prevState.replaceSubmitClicked,
      attachment: null,
      tags: null
    }))
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSelectChange = (option, event) => {
    this.setState(prevState => ({
      [event.name]: option,
      purchaseId: event.value !== "approved" ? "" : prevState.purchaseId
    }));
  };

  handleChangeNumber = e => {
    const { name, value } = e.target;
    const valueToNumber = Number(value);
    const typeOfValue = typeof valueToNumber;
    if (typeOfValue === "number" && !isNaN(Number(value))) {
      this.setState({ [name]: value });
    }
  };

  decorateApprovalStatus = () => {
    const roles = [
      { value: "needs review", label: "needs review" },
      { value: "approved", label: "Approved" },
      { value: "denied", label: "Denied" }
    ];
    return roles;
  };

  handleBrowseFile = (attachment, tags) => {
    this.setState({
      attachment,
      tags
    });
  };

  handleCancelFile = () => {
    this.setState({
      attachment: null, tags: null
    })
  }


  render() {
    const { classes, role } = this.props;
    const {
      name,
      budget,
      revised,
      actual,
      approvalStatus,
      purchaseId,
      note,
      replaceSubmitClicked
    } = this.state;
    return (
      <form onSubmit={this.handleAddCost} className={classes.addCostForm}>
        <Grid
          container
          className={classes.assignUserBox}
          justify={"space-evenly"}
          alignItems="center"
        >
          <Grid
            container
            className={classes.selectAddGrid}
            alignItems="center"
            justify={"space-evenly"}
          >
            <Grid item className={classes.selectAddGridItem}>
              <ReactInput
                name={"name"}
                value={name}
                onChange={this.handleChange}
                required
                placeholder="Cost Name"
                variant="outlined"
                autoComplete="off"
                type="text"
                className={classes.inputs}
              />
            </Grid>
            <Grid item className={classes.selectAddGridItem}>
              <ReactInput
                name={"note"}
                value={note}
                onChange={this.handleChange}
                placeholder="Note"
                variant="outlined"
                autoComplete="off"
                type="text"
                className={classes.inputs}
              />
            </Grid>
            {role !== "Project-Client" && (
              <Grid
                item
                className={classes.selectAddGridItem}
                style={{ position: "relative", zIndex: 1001 }}
              >
                <Select
                  name={"approvalStatus"}
                  value={approvalStatus}
                  onChange={this.handleSelectChange}
                  required
                  placeholder="Approval Status"
                  className={classes.textFieldItem}
                  options={this.decorateApprovalStatus()}
                  pageSize={5}
                  maxMenuHeight={120}
                />
                <input
                  tabIndex={-1}
                  style={{
                    height: 0,
                    width: 0,
                    padding: 0,
                    margin: 0,
                    opacity: 0,
                    position: "absolute",
                    top: 30,
                    left: "100px"
                  }}
                  value={approvalStatus}
                  required
                  onChange={this.handleSelectChange}
                  name="hiddenInput"
                />
              </Grid>
            )}
            <Grid item className={classes.selectAddGridItem}>
              <ReactInput
                name="purchaseId"
                value={purchaseId}
                // onChange={this.handleChange}
                onChange={this.handleChange}
                placeholder="Purchase ID"
                variant="outlined"
                autoComplete="off"
                // type="number"
                className={classes.purchaseIDInputs}
                disabled={
                  !approvalStatus || approvalStatus.value !== "approved"
                }
                type="text"
              />
            </Grid>
            <Grid item className={classes.selectAddGridItem}>
              <ReactInput
                name="budget"
                value={budget}
                // onChange={this.handleChange}
                onChange={this.handleChangeNumber}
                placeholder="Budget"
                variant="outlined"
                autoComplete="off"
                // type="number"
                className={classes.inputs}
                required
              />
            </Grid>
            <Grid item className={classes.selectAddGridItem}>
              <ReactInput
                name="revised"
                value={revised}
                // onChange={this.handleChange}
                onChange={this.handleChangeNumber}
                placeholder="Revised"
                variant="outlined"
                autoComplete="off"
                // type="number"
                className={classes.inputs}
                disabled={true}
              />
            </Grid>
            <Grid item className={classes.selectAddGridItem}>
              <ReactInput
                name="actual"
                value={actual}
                // onChange={this.handleChange}
                onChange={this.handleChangeNumber}
                placeholder="Actual"
                variant="outlined"
                autoComplete="off"
                // type="number"
                className={classes.inputs}
              />
            </Grid>
          </Grid>
          <div style={{ position: 'relative' }}>
            <AddAttachmentsButton handleBrowseFile={this.handleBrowseFile}
              propsStyle={{ height: '48px', backgroundColor: '#58B370', fontWeight: '500', fontSize: '13px', width: '165px' }}
              propsButtonTitle='ADD ATTACHMENT'
              replaceSubmitClicked={replaceSubmitClicked}
              handleCancelFile={this.handleCancelFile}

            />
          </div>
          <div className={classes.addButtonHolder}>
            <Button type="submit" className={classes.customButton}>
              {"ADD COST"}
            </Button>
          </div>
        </Grid>
      </form>
    );
  }
}

const Styles = {
  addCostForm: {
    margin: "10px 0",
    width: "100%"
  },
  assignUserBox: {
    backgroundColor: "#eff0f5",
    borderRadius: "5px",
    border: "1px solid #bdbdbd",
    width: "100%",
    direction: "row",
    justify: "space-between",
    alignItems: "center"
  },
  selectAddGrid: {
    flex: 1,
    direction: "row",
    backgroundColor: "#ffffff",
    margin: "5px",
    borderRadius: "5px"
  },
  selectAddGridItem: {
    flex: 1,
    padding: "5px"
  },
  inputs: {
    width: "100%",
    "&:disabled": {
      backgroundColor: "#ebebeb"
    }
  },
  purchaseIDInputs: {
    width: "100%",
    "&:disabled": {
      backgroundColor: "#ebebeb"
    }
  },
  addButtonHolder: {
    width: "fit-content",
    margin: "5px 5px 5px 5px"
  },
  customButton: {
    backgroundColor: "#155794",
    width: "100px",
    height: "48px",
    fontSize: "12px",
    color: "#fff",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#3f51b5"
    }
  }
};

const mapStateToProps = state => ({
  role: state.projects.project ? state.projects.project.role : undefined
});

export default connect(mapStateToProps)(withStyles(Styles)(AddCostInline));
