// React //
import React from "react";

// Redux //
import { connect } from "react-redux";

// Material Ui //
import { withStyles } from "@material-ui/core";
import { Colors } from "../../../styles";
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
// Components //

// Reusable Functions //

// Styles //

class UsersModalTableTitles extends React.PureComponent {
    state = {
        highlightedArrow: '',
        directionHighlightedArrow: null
    };

    handleSort = (name) => {
        this.setState((prevState) => ({
            highlightedArrow: name,
            isNameChanged: prevState.highlightedArrow !== name
        }), () => {
            this.setState(prevState => ({
                directionHighlightedArrow: prevState.isNameChanged ? true : !prevState.directionHighlightedArrow,
            }), () => this.props.handleSort(this.state.highlightedArrow, this.state.directionHighlightedArrow))
        })

    }

    sortArrows = (text) => (
        <>
            <KeyboardArrowUp className={this.props.classes.keyboardArrowUp}
                style={
                    this.state.highlightedArrow === text &&
                        this.state.directionHighlightedArrow ? { color: Colors.darkBlue } : {}
                }
            />
            <KeyboardArrowDown className={this.props.classes.keyboardArrowDown}
                style={
                    this.state.highlightedArrow === text &&
                        !this.state.directionHighlightedArrow ? { color: Colors.darkBlue } : {}
                }
            />
        </>
    )

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.titleWrapper}>
                <div className={classes.titleName} style={{ flex: 2 }}>
                    <div className={classes.title} onClick={() => this.handleSort('lastName')}>Name</div>
                    <div className={classes.arrowsContainer} onClick={() => this.handleSort('lastName')}>
                        {this.sortArrows('lastName')}
                    </div>

                </div>
                <div className={classes.titleName} style={{ flex: 2 }}>
                    <div className={classes.title} onClick={() => this.handleSort('email')}>Email</div>
                    <div className={classes.arrowsContainer} onClick={() => this.handleSort('email')}>
                        {this.sortArrows('email')}
                    </div>
                </div>
                <div className={classes.titleName} style={{ flex: 2 }}>
                    <div className={classes.title} onClick={() => this.handleSort('phoneNumber')}>Phone Number</div>
                    <div className={classes.arrowsContainer} onClick={() => this.handleSort('phoneNumber')}>
                        {this.sortArrows('phoneNumber')}
                    </div>
                </div>
                <div className={classes.titleName} style={{ flex: 2 }}>
                    <div className={classes.title} onClick={() => this.handleSort('company')}>Company</div>
                    <div className={classes.arrowsContainer} onClick={() => this.handleSort('company')}>
                        {this.sortArrows('company')}
                    </div>
                </div>
                <div className={classes.titleName} style={{ flex: 2 }}>
                    <div className={classes.title} onClick={() => this.handleSort('title')}>Title</div>
                    <div className={classes.arrowsContainer} onClick={() => this.handleSort('title')}>
                        {this.sortArrows('title')}
                    </div>
                </div>
                {/* <div className={classes.titleName} style={{ flex: 2 }}>
                    <div className={classes.title} onClick={() => this.handleSort('category')}>Category</div>
                    <div className={classes.arrowsContainer} onClick={() => this.handleSort('category')}>
                        {this.sortArrows('category')}
                    </div>
                </div> */}
                <div className={classes.titleName} style={{ flex: 1 }}></div>
            </div >
        );
    }
}

const Styles = {
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
        borderBottom: '1px solid' + Colors.darkenThanLightGrey,
        width: '100%',
        padding: '2px 10px'
    },
    titleName: {
        color: Colors.darkBlue,
        textAlign: 'left',
        fontWeight: '400',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '0 3px'
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-3px',
        cursor: 'pointer',
        paddingRight: '3px',
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        ' -moz-user-select': 'none',
        '-ms-user-select': 'none'
    },
    arrowsContainer: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#FCFDFE'
        }
    },
    keyboardArrowDown: {
        marginTop: '-8px',
        fontSize: '17px',
        color: Colors.darkenThanLightGrey
    },
    keyboardArrowUp: {
        fontSize: '17px',
        color: Colors.darkenThanLightGrey
    }
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withStyles(Styles)(UsersModalTableTitles));
