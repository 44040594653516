// React //
import React from "react";

// Redux //
import { connect } from "react-redux";

// Material Ui //
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

// Components //
import { ReactInput } from "../reactInput/reactInput";

// Reusable Functions //

// Styles //

class DeleteModal extends React.Component {

    state = {
        open: false,
        confirmText: ''
    };

    componentDidMount() {
        this.setState({ open: this.props.open })
    }
    shouldComponentUpdate(nextProps, nextState) {
        return nextState.confirmText !== this.state.confirmText || !nextProps.open === this.state.open

    }

    componentDidUpdate(pervProps) {
        if (this.props.open === false)
            this.setState({ confirmText: '' })

        if (this.props.open !== pervProps.open)
            this.setState({ open: this.props.open })
    }

    handleChange = ({ target }) => {
        this.setState({
            [target.name]: target.value
        })
    }
    handleDeleteDocument = (e) => {
        e.preventDefault();
        const {
            dispatch,
            selectedOptionForDelete,
            adminAction,
            userAction,
            role,
            projectId,
            handleDeleteModal,
            confirmWithName
        } = this.props;

        if (!confirmWithName || confirmWithName === this.state.confirmText) {
            if (role === 'adminFlag')
                dispatch(adminAction({
                    projectId,
                    ...selectedOptionForDelete

                }));
            else
                dispatch(userAction({
                    projectId,
                    ...selectedOptionForDelete
                }));
            handleDeleteModal();
        }
    };

    render() {
        const { open, isConfirmTextfault } = this.state;
        const {
            optionNameForDelete,
            isConfirmNeeded,
            messageText,
            classes,
            modalTitle,
            handleDeleteModal,
            confirmInputPlaceHolder,
            confirmWithName,
            submitButtonText
        } = this.props

        return (
            <div>
                <Dialog
                    open={open}
                    keepMounted
                    onClose={() => handleDeleteModal()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.wrapper}
                >
                    <Grid container>
                        <Grid item xs={12} className={classes.modalHeader}>
                            <WarningRoundedIcon className={classes.warn} />
                            <h3 className={classes.headerText}>
                                {modalTitle ? modalTitle : "Alert!"}
                            </h3>
                            <IconButton
                                onClick={() => handleDeleteModal()}
                                aria-label="Delete"
                                className={classes.cancelButton}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} className={classes.DialogContent}>
                            <DialogContent style={{ padding: 0 }} >
                                <DialogContentText className={classes.messageText}>
                                    {messageText ? messageText :
                                        "You won't be able to restore, are you sure you want to delete?"
                                    }
                                </DialogContentText>
                                {optionNameForDelete &&
                                    <ul className={classes.optionNameForDelete}>
                                        <li>{optionNameForDelete}</li>
                                    </ul>
                                }
                            </DialogContent>
                        </Grid>
                        <Grid item xs={12} >
                            <form onSubmit={this.handleDeleteDocument} >
                                {isConfirmNeeded &&
                                    <div className={classes.confirm}>
                                        <DialogContentText
                                            className={classes.commandText}
                                        >
                                            {`confirm this action by typing the ${confirmInputPlaceHolder.toLowerCase()}`}
                                        </DialogContentText>
                                        <ReactInput
                                            required
                                            onChange={this.handleChange}
                                            value={this.state.confirmText}
                                            type="text"
                                            className={classes.confirmText}
                                            name="confirmText"
                                            placeholder={confirmInputPlaceHolder ?
                                                confirmInputPlaceHolder : 'Name'}
                                        />
                                        <p
                                            className={classes.confirmTextfaultText}
                                            style={isConfirmTextfault ? { visibility: 'visible' } : { visibility: 'hidden' }}
                                        >
                                            The name is incorrect
                                        </p>
                                    </div>
                                }

                                <DialogActions className={classes.DialogActions}>
                                    <Button type='button' onClick={() => handleDeleteModal()} color="primary">
                                        Cancel
                                    </Button>

                                    <Button type='submit' color='secondary'
                                        disabled={confirmWithName && !(confirmWithName === this.state.confirmText)}>
                                        {submitButtonText ? submitButtonText : 'Delete'}
                                    </Button>

                                </DialogActions>
                            </form>
                        </Grid>
                    </Grid>
                </Dialog>
            </div >
        );
    }
}

const Styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column'
    },

    confirmText: {
        width: '100%'
    },

    DialogContent: {
        padding: '25px 25px 10px 25px'
    },
    confirm: {
        padding: '25px 25px 0 25px'
    },
    DialogActions: {
        padding: '10px 25px 10px 25px'
    },
    modalHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "#ff7600",
        height: "50px",
        padding: "0px 50px"
    },
    headerText: {
        margin: "3px 0px 0px",
        color: "white"
    },
    warn: {
        color: "white",
        position: "absolute",
        left: "10px"
    },
    cancelButton: {
        position: "absolute",
        right: "10px",
        padding: "7px",
        color: 'white'
    },
    messageText: {
        color: 'red'
    },
    commandText: {
        margin: '7px 0'
    },
    confirmTextfaultText: {
        margin: 0,
        fontSize: '14px',
        color: 'red'
    },
    optionNameForDelete: {
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        WebkitHyphens: 'auto',
        MsHyphens: 'auto',
        hyphens: 'auto',
        paddingRight: '30px'
    }
};

const mapStateToProps = state => ({
    role: state.projects.project ? state.projects.project.role : undefined,
    projectId: state.projects.project ? state.projects.project._id : undefined
});

export default connect(mapStateToProps)(withStyles(Styles)(DeleteModal));

                // #######################################################################################
                // ####################              Dialog: Delete component            ####################
                // #######################################################################################

// {/* <DeleteModal
// open={this.state.openDeleteModal}
// handleDeleteModal={this.handleDeleteModal}
//   selectedOptionForDelete={{ issueTaskId: this.state.selectedOptionForDelete._id }}
// adminAction={attachemntActions.deleteAttachment}
// userAction={attachemntUserActions.deleteAttachment}
// //optional
// modalTitle={'Delete Attachment'}
// optionNameForDelete={this.state.selectedOptionForDelete.name}
// messageText={`This action will delete the entire project and the users !!!.You won't be able to restore, are you sure you want to delete?`}
// submitButtonText='Unassign'


//confirm
// confirmInputPlaceHolder ={}
// isConfirmNeeded={true}
// confirmWithName={'123'}
// /> */}

// #######################################################################################
// ####################              Dialog: Delete handle            ####################
// #######################################################################################

// handleDeleteModal = selectedOptionForDelete => {
//     selectedOptionForDelete ?
//         this.setState({
//             openDeleteModal: true,
//             selectedOptionForDelete
//         }) :
//         this.setState({
//             openDeleteModal: false,
//         })
// }
