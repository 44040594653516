import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseIcon from "@material-ui/icons/Close";
import EditOutLinedIcon from "@material-ui/icons/EditOutlined";


// ############################################################################
// styles
// ############################################################################

const styles = theme => ({
  root: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    top: 40,
    right: 0,
    left: 0,
    maxHeight: 200,
    borderRadius: 4,
    padding: "3px 0px",
    zIndex: 1200,
    overflowY: "auto",
    backgroundColor: "white",
    boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
  },
  item:{
    color: "#214841de",
    padding: "5px 10px",
    fontSize: "14px",
    "&:hover":{
      backgroundColor:"#007cb914",
      cursor:"pointer"
    }
  },
  noOption:{
    color: "#214841de",
    padding: "5px 10px",
    fontSize: "13px",
  },
  selectedItem:{
    backgroundColor:"#599892 !important",
    color:"white",
    "&:hover":{
      backgroundColor:"#54908a  !important",
    }
  },
  input:{
    border:"none",
    minWidth:"20px",
    backgroundColor:"#00000000",
    outline:"none",
    padding: "0px 10px",
    minHeight: "28px",
    fontSize: "13px",
    color: "#214841de",
    flex:1,
    "&:hover":{
     cursor:"default"
    },
    "&::-ms-input-placeholder":{
      color: "#214841de",
    },
    "&:-ms-input-placeholder":{
      color: "#214841de",
    },
    "&::placeholder":{
      color: "#214841de",
    },
  },
  icon:{
    color:"#0000004a",
    transition:"color 200ms, transform 200ms",
    "&:hover":{
      color: "#6f000075",
      cursor: "pointer"
    }
  },
  iconExpand:{
    transform: "rotate(180deg)",
  },
  iconHolder:{
    height: "24px",
    padding: "0px 4px",
    borderLeft: "1px solid #00000026"
  },
  selecedHolder:{
    minHeight:"32px",
    backgroundColor:"white",
    display: "flex",
    borderRadius: "4px",
    border: "1px solid #cccccc",
    alignItems: "center",
    "&:hover":{
      borderColor: "hsl(0, 0%, 70%)",
    },
    "&:focus-within":{
      borderColor: "hsl(214, 100%, 57%)",
      boxShadow: "0px 0px 0px 1px hsl(214, 100%, 57%)"
    },
  },
  closeIcon: {
    color:"#0000004a",
    fontSize:"20px",
    "&:hover":{
      color: "#6f000075",
      cursor: "pointer"
    }
  },
  iconCloseHolder:{
    height: "24px",
    padding: "0px 4px",
    display: "flex",
    alignItems: "center"
  },

  // ###########################################################################
  chipsHolder:{
    display: "flex",
    padding:"0px 4px",
    fontSize: 13,
    color: "#214841de",
    flexWrap: "wrap"
  },
  chipsHolderEmpty:{
    flex: 1,
    padding:"0px 8px",
    fontSize: 13,
    color: "#214841de"
  },
  chip: {
    boxSizing: "border-box",
    background: "#00000021",
    borderRadius: 2,
    fontSize: 12,
    margin: "2px 3px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  removeButton: {
    width: 18,
    height: "100%",
    padding: "0px 2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: "1px solid white",
    "&:hover":{
      background: "#00000010",
      cursor: "pointer",
    },
    "&:active":{
      background: "#00000015",
    }
  },
   saveButton: {
    width: 18,
    background: '#58b370',
    height: "100%",
    padding: "0px 2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover":{
      background: "#4e9c62",
      cursor: "pointer",
    },
    "&:active":{
      background: "#3e8851",
    },
    "& > svg": {
      color: 'white',
    }
  },

  removeIcon: {
    fontSize: 14,
    color: "#0000009c"
  },
  chipLabel:{
    margin: "2px 4px",
    color: "#0000009c",
    fontWeight: 600,
    whiteSpace: "nowrap"
  },
  numberInput:{
    border: 'none',
    padding: "0px 5px",
    width: 60,
    boxSizing: 'border-box',
    height: '100%',
  }
});

// ############################################################################
// component
// ############################################################################

class Select extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      searchQuery:"",
      input:"",
      selected:{},
      lag: props.defaultLag ? props.defaultLag : "",
      selectedItem: "",
    };
    this.paper = React.createRef();
  }

  // ##########################################################################
  // 
  // ##########################################################################

  handleLagChange = event => {
    const lag = parseInt(event.target.value) ? parseInt(event.target.value): 0;
    this.setState({lag});
  }

  // ##########################################################################
  // 
  // ##########################################################################

  handleSelect = (selected) => () => {
    const { isMulti, defaultLag } = this.props;
    this.setState({ input:"", open: false});
    if(isMulti) this.handleMultiSelect({...selected, lag: defaultLag !== undefined ? defaultLag : undefined});
    else this.handleSingleSelect(selected);
  }

  // ##########################################################################
  // 
  // ##########################################################################

  handleSingleSelect = (selected) =>{
    this.props.onChange({target:{name: this.props.name, value: selected}});
  }

  // ##########################################################################
  // 
  // ##########################################################################

  handleMultiSelect = (selected) => {
    const { value = [] } = this.props;
    if(Array.isArray(value))
      this.props.onChange({target:{name: this.props.name, value: [...this.removeLag(value) , selected]}});
    else 
      this.props.onChange({target:{name: this.props.name, value: [selected]}});
  }

  // ##########################################################################
  // 
  // ##########################################################################

  handleClear = () => {
    this.setState({ input:""})
    this.props.onChange({target:{name: this.props.name, value: undefined}}) 
  }

  // ##########################################################################
  // 
  // ##########################################################################

  handleChange = event =>{
    this.setState({
      [event.target.name]:event.target.value,
      input:event.target.value,
    })
  }

  // ##########################################################################
  // 
  // ##########################################################################

  handleClick = () => {
    this.setState(state => ({ open: !state.open, searchQuery: "" }));
  };

  // ##########################################################################
  // 
  // ##########################################################################

  handleFocuse = () => {
    this.setState(state => ({ open: true , searchQuery: "" }));
  }

  // ##########################################################################
  // 
  // ##########################################################################

  handleClickAway = () => {
    this.setState({ open: false });
  };

  // ##########################################################################
  // 
  // ##########################################################################

  filterData = (array = [], searchQuery = "") => {
    const keyWordLowerCase = searchQuery.toLowerCase();
    return array.filter(item => {
      return item.label.toLowerCase().includes(keyWordLowerCase);
    })
  }

  // ##########################################################################
  // remove selected items
  // ##########################################################################

  removeSelectedFromList = (array = [], selectedList = [], isMulti = false) => {

    let outputArray = []
    if(isMulti && Array.isArray(selectedList)) {
      outputArray = array.filter(item => {
        const searchResult = selectedList.filter(selected =>  selected.value === item.value)
        return searchResult.length === 0;
      })
     return outputArray;
    }
    else return array;
  }

  // ##########################################################################
  // remove multi selected
  // ##########################################################################

  removeItem = (clickedItem) => () => {
    const value = this.props.value.filter(item => clickedItem !== item.value);
    this.props.onChange({target:{name: this.props.name, value}}) 
  }


  // ##########################################################################
  // selected
  // ##########################################################################

  editItem = (selectedItem) => () => {
    this.setState({selectedItem});
  } 

  // ##########################################################################
  // selected
  // ##########################################################################

  cancelEdit = (selectedItem) => () => {
    if(this.state.selectedItem === selectedItem)
      this.setState({selectedItem: ""});
  }

  // ##########################################################################
  // selected
  // ##########################################################################

  saveEdit = (selectedItem , index) => () => {
    const { value } = this.props;
    const { lag } = this.state;
    const removedLag = this.removeLag(value)
    const newValue = [ ...removedLag.slice(0, index), { ...removedLag[index], lag }, ...removedLag.slice(index + 1) ];
    this.setState({selectedItem: ""})
    this.props.onChange({target:{name: this.props.name, value: newValue}});
  }

  // ##########################################################################
  // selected
  // ##########################################################################

  removeLag = (array = []) => {
    return array.map(item => ({...item, lag: undefined}));
  }

  // ##########################################################################
  // selected
  // ##########################################################################

  generateChips = (value = []) => {
    const { classes, withLag = false } = this.props;

    const chips = value.map((item, index) => {
      const selected = this.state.selectedItem === item.value;
      return (
        <div key={item.value} className={classes.chip}>
          <div className={classes.chipLabel}>
            {`${item.label} ${item.lag !== undefined ? ` - Lag: ${item.lag}`: ''} ${item.lag ? item.lag > 1 ? "Days" : "Day" : "" }`}
          </div>

          { withLag && !selected ?
            <div onClick={this.editItem(item.value)} className={classes.removeButton}>
              <EditOutLinedIcon classes={{root: classes.removeIcon}}/>
            </div>
            : 
            null
          }

          { selected ? null : 
            <div onClick={this.removeItem(item.value)} className={classes.removeButton}>
              <CloseRoundedIcon classes={{root: classes.removeIcon}}/>
            </div>
          }
          { !selected ?  null : 
            <React.Fragment>
              <input pattern="[0-9]*" placeholder="LAG" type="number" onChange={this.handleLagChange} className={classes.numberInput}/>

              <div onClick={this.saveEdit(item.value, index)} className={classes.saveButton}>
                <CheckRoundedIcon classes={{root: classes.removeIcon}}/>
              </div>

              <div onClick={this.cancelEdit(item.value)} className={classes.removeButton}>
                <CloseRoundedIcon classes={{root: classes.removeIcon}}/>
              </div>

            </React.Fragment>
          }
        </div>
      )});

    return (<div className={classes.chipsHolder}>{chips}</div>)
  }

  // ##########################################################################
  // render
  // ##########################################################################

  render() {

    const { classes, options = [], value = {}, placeholder = "" } = this.props;
    const { isMulti, className, withClearButton, required = false } = this.props;
    const { open, searchQuery, input } = this.state;
    const removedSelected = this.removeSelectedFromList(options, value, isMulti)
    const filteredOptions = this.filterData(removedSelected, searchQuery);

    return (
      <div className={`${classes.root} ${className}`}>
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <div>
            <div className={classes.selecedHolder}>

              { isMulti ? (this.generateChips(value)) : (null) }
            
              <input
                required={required && value.value === undefined}
                autoComplete="off"
                className={classes.input}
                value={input}
                name="searchQuery"
                onChange={this.handleChange}
                onClick={this.handleFocuse}
                placeholder={value.label ? value.label : placeholder}
              />

              
            {!withClearButton ? null :
              <div onClick={this.handleClear} className={classes.iconCloseHolder}>
                <CloseIcon classes={{root: classes.closeIcon}}/>
              </div>
            }
            <div onClick={this.handleClick} className={classes.iconHolder}>
              <ExpandMoreIcon classes={{root:`${classes.icon} ${open ? classes.iconExpand : null}`}}/>
            </div>
            </div>
            {open ? (
              <div className={classes.paper}>
                {filteredOptions.length <= 0 ? 
                  <div className={classes.noOption}>No Options...</div>
                  : 
                  filteredOptions.map((item, index) => (
                    <div
                      className={`${classes.item} ${item.value === value.value ? classes.selectedItem : null}`}
                      onClick={this.handleSelect(item)}
                      key={index}
                    >
                      {item.label}
                    </div>
                  ))
                }
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

// ############################################################################
// connect to material styles
// ############################################################################

const SelectWithStyles = withStyles(styles)(Select);

// ############################################################################
// exports
// ############################################################################

export { SelectWithStyles as Select};