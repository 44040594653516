import React, { Component } from "react";
import "./styles/style.css";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import AppRouter from "./routers";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { light } from "./styles";

const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={light}>
          <AppRouter />
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
