import React from "react";
import { withStyles } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Colors } from "../../styles/colors";

const styles = {
  iconButton: {
    padding: "3px !important",
    color: Colors.lightGreen
  },
  AddCircleOutlined: {
    color: "#06BFBA"
  }
};

export const AddCircleFilledIcon = React.memo(
  withStyles(styles)(({ classes, onClick }) => (
    <Tooltip title="Add Subproject">
      <IconButton
        onClick={onClick}
        className={classes.iconButton}
        component="span"
      >
        <AddCircle className={classes.AddCircleOutlined} />
      </IconButton>
    </Tooltip>
  ))
);
