// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { attachmentActions } from '../../actions'
import { attachmentUserActions } from '../../userActions'
// Material Ui //
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import CreateNewFolder from '@material-ui/icons/CreateNewFolder'
// Components //
import ThemeContext from "../ContextApi/Context";

// Reusable Functions //

// Styles //
import { Colors } from '../../styles/colors'

class InlineActionsButtons extends React.PureComponent {
  static contextType = ThemeContext;
  state = {};

  handlefileSubmit = e => {
    e.preventDefault();
    const projectId = this.context;

    const { dispatch, role } = this.props
    if (e.target.files[0]) {
      let document = new FormData();

      document.append("projectId", projectId);
      document.append('projectDocument', true)

      for (var i = 0; i < e.target.files.length; i++)
        document.append("document", e.target.files[i]);

      role === 'adminFlag' ?
        dispatch(attachmentActions.addAttachment(document, this.handleUploadPercentage)) :
        dispatch(attachmentUserActions.addAttachment(document, this.handleUploadPercentage));
    }
    e.target.value = null
  };

  render() {
    const { classes, handleToggleAddNewFolder } = this.props;
    return (
      <Grid container className={classes.container}>
        <Grid item className={classes.uploadButtonsContainer}>

          {/* Upload Files */}
          <Button className={classes.materialButton}>
            <label className={classes.chooseFile}>
              <div className={classes.uploadIcon}>
                <InsertDriveFile className={classes.insertIcon} />
                <ArrowUpward className={classes.arrowUpwardIcon} style={{ top: '8px', left: '6px', }} />
              </div>
              <span>
                Upload Files
          </span>
              <input name='files' type='file' onChange={this.handlefileSubmit} multiple />
            </label>
          </Button>

          {/* New Folder */}
          <Button className={classes.materialButton}>
            <label className={classes.chooseFile} onClick={handleToggleAddNewFolder}>
              <div className={classes.uploadIcon}>
                <CreateNewFolder className={classes.insertIcon} />
              </div>
              <span>
                New Folder
          </span>
            </label>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const Styles = {
  container: {
    marginTop: '10px'
  },
  uploadButtonsContainer: {
    padding: '5px',
    border: `1px solid ${Colors.lightGrey}`,
    borderRadius: '5px',
    display: 'flex',
  },
  materialButton: {
    padding: 0,
    margin: '0 5px',
    fontSize: '13px',
    fontWeight: '400'
  },
  chooseFile: {
    backgroundColor: Colors.darkenThanLightGrey,
    display: 'flex!important',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: '5px',
    height: '38px',
    boxsizing: 'border-box',
    border: 0,
    cursor: 'pointer',
    position: 'relative',
    boxSizing: 'border-box',
    color: 'white!important',
    padding: '20px 10px',
    '& input': {
      opacity: 0,
      width: 0,
      height: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      cursor: 'pointer',
    },
    '& > span': {
      width: '100%',
      height: '100%',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '& svg': {
      cursor: 'pointer',
    }
  },
  uploadIcon: {
    position: 'relative',
    marginRight: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  insertIcon: {
    fontSize: '21px',

  },
  arrowUpwardIcon: {
    position: 'absolute',
    fontSize: '9px',
    color: Colors.darkenThanLightGrey
  }
};

const mapStateToProps = state => ({
  role: state.projects.project ? state.projects.project.role : undefined
});

export default connect(mapStateToProps)(withStyles(Styles)(InlineActionsButtons));
