import React from "react";
import classNames from "classnames";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import withStyles from "@material-ui/core/styles/withStyles";

//###########################################################################
// style
//###########################################################################

const styles = theme => ({
  success: {
    backgroundImage: "linear-gradient(90deg, #00E9A1 0%, #59B269 100%)",
    [theme.breakpoints.up("md")]: {
      borderRadius: "5px",
      display: "flex",
      flexWrap: "nowrap"
    }
  },
  error: {
    backgroundImage: "linear-gradient(90deg, #ff3f3f 0%, #ff558f 100%)",
    [theme.breakpoints.up("md")]: {
      borderRadius: "5px",
      display: "flex",
      flexWrap: "nowrap"
    }
  },
  info: {
    backgroundImage: "linear-gradient(90deg, #00B8E9 0%, #00EBC9 100%)",
    [theme.breakpoints.up("md")]: {
      borderRadius: "5px",
      display: "flex",
      flexWrap: "nowrap"
    }
  },
  warning: {
    backgroundImage: "linear-gradient(90deg, #ffa93f 0%, #ffd27f 100%)",
    [theme.breakpoints.up("md")]: {
      borderRadius: "5px",
      display: "flex",
      flexWrap: "nowrap"
    }
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.8,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    maxWidth: "450px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "calc(100vw - 80px)"
    }
  },
  messageText:{
    display: "block",
    alignItems: "center",
    fontWeight: "600",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  snackbar: {
    backgroundColor: "#111"
  },
  padding: {
    padding: "6px 10px"
  },
  close: {
    padding:"10px"
  }
});

//###########################################################################
// constants
//###########################################################################

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

//###########################################################################
// component
//###########################################################################

const CustomSnackbar = props => {
  const {
    classes,
    key,
    className,
    message,
    messageContent,
    onClose,
    action,
    variant,
    anchorOrigin,
    open,
    autoHideDuration,
    ...other
  } = props;
  const Icon = variantIcon[variant];
  return (
    <Snackbar
      key={key}
      anchorOrigin={
        anchorOrigin || {
          vertical: "bottom",
          horizontal: "left"
        }
      }
      open={open || false}
      autoHideDuration={autoHideDuration || 4000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classNames(classes[variant], className, classes.padding)}
        aria-describedby="client-snackbar"
        message={
          messageContent || (
            <span id="client-snackbar" className={classNames(classes.message, " iranyekan")}>
              <Icon className={classNames(classes.icon, classes.infoIcon, classes.iconVariant)} />
             <span className={classes.messageText}> {message} </span>
            </span>
          )
        }
        action={
          action || [
            <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]
        }
        {...other}
      />
    </Snackbar>
  );
};

//###########################################################################
// export
//###########################################################################

const CustomSnackbarStyles = withStyles(styles)(CustomSnackbar);
export { CustomSnackbarStyles as CustomSnackbar };
