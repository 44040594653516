import { userConstants } from "../constants";
import { userService, generalServices } from "../services";
import { progressBarActions, snackBarActions } from "./";
import { handleRouting } from "../routers/routing-roles";

export const userActions = {
  login,
  getAllUsers,
  setProjectRole,
  getMyProjects,
  getUser,
  getUserProjects,
  addUser,
  clearAssignedUsersLocal,
  updateProjectRole,
  getAllUsersRoles,
  deleteProjectRole,
  deleteUser,
  editUser,
  whoAmI,
  getCompanyUsers,
  AddCompanyUser,
  deleteCompanyUser,
  editCompanyUser
};

//################################################################################
// Login
//################################################################################

function login(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    userService.login(data).then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.user) {
          dispatch(success(response.user));
          handleRouting(response.user);
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError("Failed To Connect To Server"));
      }
    );
  };
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, payload: user };
  }
  // function failure(error) {
  //   return { type: userConstants.LOGIN_FAILURE, payload: error };
  // }
}

//################################################################################
// GET All Users
//################################################################################

function getAllUsers() {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getAllUsers").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.users));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };

  function success(users) {
    return { type: userConstants.GET_ALL_USERS_SUCCESS, payload: users };
  }
  // function failure(error) {
  //   return { type: userConstants.GET_ALL_USERS_FAILURE, payload: error };
  // }
}

//################################################################################
// SET Project Role
//################################################################################

function setProjectRole(data, page) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("setProjectRole", data).then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.user, response.user_project));
          dispatch(snackBarActions.snackBarSuccess("user"));

          if (page === "user") {
            dispatch(getUserProjects(data.userId));
          }
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(user, userProject) {
    const newUser = {
      _id: userProject._id,
      project: userProject.project,
      role: userProject.role,
      user: user
    };
    return { type: userConstants.SET_PROJECT_ROLE_SUCCESS, payload: newUser };
  }
  // function failure(error) {
  //   return { type: userConstants.SET_PROJECT_ROLE_FAILURE, payload: error };
  // }
}

//################################################################################
// Get my Projects
//################################################################################

function getMyProjects() {
  return dispatch => {
    dispatch(progressBarActions.start());
    // setTimeout(() => {
    generalServices.service("myProjects").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.projects));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    // }, 5000);
  };
  function success(projects) {
    return { type: userConstants.GET_MY_PROJECTS_SUCCESS, payload: projects };
  }
  // function failure(error) {
  //   return { type: userConstants.GET_MY_PROJECTS_FAILURE, payload: error };
  // }
}

//################################################################################
// GET User
//################################################################################

function getUser(userId) {
  return dispatch => {
    dispatch(request());
    dispatch(progressBarActions.start());
    generalServices.service("getUser", userId).then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.user));
        } else {

          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function request() {
    return { type: userConstants.GET_USER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GET_USER_SUCCESS, payload: user };
  }
  // function failure(error) {
  //   return { type: userConstants.GET_USER_FAILURE, payload: error };
  // }
}

//################################################################################
// GET User Projects
//################################################################################

function getUserProjects(userId) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getUserProjects", userId).then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.projects));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(projects) {
    return { type: userConstants.GET_USER_PROJECTS_SUCCESS, payload: projects };
  }
  // function failure(error) {
  //   return { type: userConstants.GET_USER_PROJECTS_FAILURE, payload: error };
  // }
}

//################################################################################
// Add User
//################################################################################

function addUser(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("createUser", data).then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.savedUser));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };

  function success(user) {
    return {
      type: userConstants.ADD_USER_SUCCESS,
      payload: { ...user, projectList: [] }
    };
  }
  // function failure(error) {
  //   return { type: userConstants.ADD_USER_FAILURE, payload: error };
  // }
}

//################################################################################
// CLEAR_ASSIGNED_USERS_LOCAL
//################################################################################
function clearAssignedUsersLocal() {
  return { type: userConstants.CLEAR_ASSIGNED_USERS_LOCAL };
}

//################################################################################
// Update Project Role
//################################################################################

function updateProjectRole(data, page) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editRole", data).then(
      response => {
        dispatch(progressBarActions.stop());

        if (response.success === true) {
          dispatch(success(response.userProject));
          dispatch(snackBarActions.snackBarSuccess("Successful update!!"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(userProject) {
    return {
      type: userConstants.UPDATE_PROJECT_ROLE_SUCCESS,
      payload: userProject
    };
  }
  // function failure(error) {
  //   return { type: userConstants.UPDATE_PROJECT_ROLE_FAILURE, payload: error };
  // }
}

//################################################################################
// GET All Users Roles
//################################################################################

function getAllUsersRoles() {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getAllUsersWithRoleProjects").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.users));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(users) {
    return { type: userConstants.GET_ALL_USERS_ROLES_SUCCESS, payload: users };
  }
  // function failure(error) {
  //   return { type: userConstants.GET_ALL_USERS_ROLES_FAILURE, payload: error };
  // }
}

//################################################################################
// DELETE Project Role // Unassign User From Project
//################################################################################

function deleteProjectRole(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    // setTimeout(() => {
    generalServices.service("deleteProjectRole", data).then(
      response => {
        if (response.success === true) {
          dispatch(success(response.userProject));
          dispatch(snackBarActions.snackBarSuccess("Unassign successfully!!"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    // }, 5000);
  };
  function success(userProject) {
    return {
      type: userConstants.DELETE_PROJECT_ROLE_SUCCESS,
      payload: userProject
    };
  }
  // function failure(error) {
  //   return { type: userConstants.DELETE_PROJECT_ROLE_FAILURE, payload: error };
  // }
}

//################################################################################
// DELETE User
//################################################################################

function deleteUser(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    // setTimeout(() => {
    generalServices.service("deleteUser", data).then(
      response => {
        if (response.success === true) {
          if (response.user) {
            dispatch(success(response.user._id));
            dispatch(snackBarActions.snackBarSuccess("Successful"));
          }
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };

  function success(id) {
    return { type: userConstants.DELETE_USER_SUCCESS, payload: id };
  }
  // function failure(error) {
  //   return { type: userConstants.DELETE_USER_FAILURE, payload: error };
  // }
}

//################################################################################
// EDIT User
//################################################################################

function editUser(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    // setTimeout(() => {
    generalServices.service("editUser", data).then(
      response => {
        if (response.success === true) {
          dispatch(success(data));
          dispatch(snackBarActions.snackBarSuccess("Successful"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
    // }, 5000);
  };
  function success(data) {
    return { type: userConstants.EDIT_USER_SUCCESS, payload: data };
  }
  // function failure(error) {
  //   return { type: userConstants.EDIT_USER_FAILURE, payload: error };
  // }
}

//################################################################################
// Who Am I
//################################################################################

function whoAmI() {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("whoAmI", undefined, "user").then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.me) {
          dispatch(success(response.me));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, payload: user };
  }
  // function failure(error) {
  //   return { type: userConstants.LOGIN_FAILURE, payload: error };
  // }
}

//################################################################################
// Get company users
//################################################################################

function getCompanyUsers(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getCompanyUsers", data).then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.success) {
          dispatch(success(response.users));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(data) {
    return {
      type: userConstants.GET_COMPANY_USERS_SUCCESS,
      payload: data
    };
  }
  // function failure(error) {
  //   return {
  //     type: userConstants.GET_COMPANY_USERS_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// ADD COMPANY USER
//################################################################################

function AddCompanyUser(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("createUser", data).then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.me) {
          dispatch(success(response.savedUser));
          dispatch(
            snackBarActions.snackBarSuccess("User added successfully!!")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };

  function success(user) {
    return {
      type: userConstants.ADD_COMPANY_USER_SUCCESS,
      payload: { ...user, projectList: [] }
    };
  }
  // function failure(error) {
  //   return { type: userConstants.ADD_COMPANY_USER_FAILURE, payload: error };
  // }
}

//################################################################################
// ADD COMPANY USER
//################################################################################

function deleteCompanyUser(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteUser", data).then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.me) {
          dispatch(success(data));
          dispatch(
            snackBarActions.snackBarSuccess("User added successfully!!")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };

  function success(user) {
    return {
      type: userConstants.DELETE_COMPANY_USERS_SUCCESS,
      payload: data
    };
  }
  // function failure(error) {
  //   return { type: userConstants.DELETE_COMPANY_USERS_FAILURE, payload: error };
  // }
}

//################################################################################
// EDIT COMPANY USER
//################################################################################

function editCompanyUser(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editUser", data).then(
      response => {
        dispatch(progressBarActions.stop());
        if (response.me) {
          dispatch(success(response.user));
          dispatch(
            snackBarActions.snackBarSuccess("User added successfully!!")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };

  function success(user) {
    return {
      type: userConstants.EDIT_COMPANY_USERS_SUCCESS,
      payload: user
    };
  }
  // function failure(error) {
  //   return { type: userConstants.EDIT_COMPANY_USERS_FAILURE, payload: error };
  // }
}
