import React from "react";
import { withStyles } from "@material-ui/core";
import Logo from "../../components/img/PM.jpg";
import Typography from "@material-ui/core/Typography";
import { ReactInput } from "../../components";
import Button from "@material-ui/core/Button";
import { userUserActions } from "../../userActions";
import { snackBarActions } from "../../actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CustomSnackbar } from "../../components";
import { Link } from "react-router-dom";
class ChangePassword extends React.PureComponent {
  state = {
    password: "",
    reenterPassword: ""
  };

  handleSubmit = () => {
    const { password, reenterPassword } = this.state;
    const { dispatch } = this.props;
    const {
      location: { pathname }
    } = this.props;

    if (password.length < 8 || password.length > 20) {
      dispatch(
        snackBarActions.snackBarError(
          "Password must be between 8 and 30 characters"
        )
      );
      return;
    }
    if (password !== reenterPassword) {
      dispatch(
        snackBarActions.snackBarError("The password confirmation doesn't match")
      );
      return;
    }

    dispatch(
      userUserActions.verification({
        password,
        verificationCode: pathname.split("/").pop()
      })
    );
  };

  handleChange = ({ target: { value, name } }) => {
    this.setState({
      [name]: value
    });
  };

  handleCloseSnackBar = () => {
    this.props.dispatch(snackBarActions.snackBarClose());
  };

  render() {
    const { classes, snackBar } = this.props;
    const { password, reenterPassword } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <img src={Logo} alt="PM Design" className={classes.image} />
        </div>
        <div className={classes.cardContainer}>
          <div className={classes.card}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.changePassword}
            >
              Change Password
            </Typography>
            <div className={classes.inputContainer}>
              <div className={classes.label}>Create a new password</div>
              <ReactInput
                className={classes.input}
                type="password"
                value={password}
                name="password"
                onChange={this.handleChange}
                autocomplete="off"
              />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.label}>Reenter your new password</div>
              <ReactInput
                className={classes.input}
                type="password"
                value={reenterPassword}
                name="reenterPassword"
                onChange={this.handleChange}
                autocomplete="off"
              />
            </div>
            <div className={classes.loginLink}>
              <Link to={"/login"} className={classes.link}>
                Go To Login Page
              </Link>
            </div>
            <div className={classes.submitButtonContainer}>
              <Button
                className={classes.submitButton}
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
        {snackBar && (
          <CustomSnackbar
            autoHideDuration={4000}
            {...snackBar}
            onClose={this.handleCloseSnackBar}
          />
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  logoContainer: {},
  cardContainer: {},
  card: {
    width: "350px",
    boxShadow: "0 0 3px #a8a8a8",
    borderRadius: "4px",
    padding: "15px"
  },
  image: {
    width: "170px"
  },
  changePassword: {
    borderBottom: "1px solid #EDEDED",
    paddingBottom: "10px",
    color: "#4d4d4d"
  },
  inputContainer: {
    marginTop: "20px"
  },
  input: {
    width: "100%",
    marginTop: "10px"
  },
  submitButton: {
    width: "100%",
    backgroundColor: "#1F7991",
    transition: "300ms",
    color: "white",
    "&:hover": {
      backgroundColor: "#2da2c2"
    }
  },
  loginLink: {
    marginTop: "10px",
    textAlign: "center"
  },
  submitButtonContainer: {
    marginTop: "20px"
  },
  link: {
    color: "#2DA2C2",
    textDecoration: "none"
  }
};

const mapToStateProps = state => ({
  snackBar: state.snackBar.snackBar
});

const changePasswordWithStyles = withRouter(withStyles(styles)(ChangePassword));
const changePasswordWithConnect = connect(mapToStateProps)(
  changePasswordWithStyles
);
export { changePasswordWithConnect as ChangePassword };
