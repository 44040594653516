import { issueConstants } from "../constants";

export function issues(state = [], action) {
  switch (action.type) {
    case issueConstants.GET_USER_ISSUES_SUCCESS:
      return action.payload;

    case issueConstants.ADD_ISSUE_SUCCESS:
      return [...state, action.payload];

    case issueConstants.DELETE_ISSUE_SUCCESS:
      return state.filter(issue => issue._id !== action.payload);

    case issueConstants.EDIT_ISSUE_SUCCESS: {
      return state.map(item =>
        item._id !== action.payload._id ? item : action.payload
      );
    }

    case issueConstants.CLEARING_ISSUES_DATA:
      return [];

    default:
      return state;
  }
}

// #############################################################################
//  find the index of searched item in the array
// #############################################################################
