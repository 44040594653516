import { noteConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "../actions";

export const noteUserActions = {
  getProjectNotes,
  addNote,
  editNote,
  deleteNote
};

//################################################################################
// GET All Notes
//################################################################################

function getProjectNotes(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getProjectNotes", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.notes));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(notes) {
    return {
      type: noteConstants.GET_ALL_NOTES_SUCCESS,
      payload: notes
    };
  }
  // function failure(error) {
  //   return { type: noteConstants.GET_ALL_NOTES_FAILURE, payload: error };
  // }
}

//################################################################################
// Add Note
//################################################################################

function addNote(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("addNote", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.note));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(note) {
    return { type: noteConstants.ADD_NOTE_SUCCESS, payload: note };
  }
  // function failure(error) {
  //   return { type: noteConstants.ADD_NOTE_FAILURE, payload: error };
  // }
}

//################################################################################
// Edit Note
//################################################################################

function editNote(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("editNote", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.note));
          dispatch(
            snackBarActions.snackBarSuccess("Saved changes successfully")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(data) {
    return { type: noteConstants.EDIT_NOTE_SUCCESS, payload: data };
  }
  // function failure(error) {
  //   return { type: noteConstants.EDIT_NOTE_FAILURE, payload: error };
  // }
}

//################################################################################
// Delete Note
//################################################################################

function deleteNote(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteNote", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(
            snackBarActions.snackBarSuccess("The Note Successfully removed!")
          );
          dispatch(success(data));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(data) {
    return {
      type: noteConstants.DELETE_NOTE_SUCCESS,
      payload: data
    };
  }
  // function failure(error) {
  //   return { type: noteConstants.DELETE_NOTE_FAILURE, payload: error };
  // }
}
