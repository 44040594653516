import { commentConstants } from "../constants";

export function comments(state = [], action) {
  switch (action.type) {
    case commentConstants.GET_ALL_COMMENTS_SUCCESS:
      return action.payload;

    case commentConstants.ADD_COMMENT_SUCCESS:
      return [...state, action.payload];

    case commentConstants.EDIT_COMMENT_SUCCESS: {
      return state.map(item =>
        item._id !== action.payload._id ? item : action.payload
      );
    }

    case commentConstants.DELETE_COMMENT_SUCCESS:
      let itemsToDelete = [action.payload.commentId];
      shouldDeleteItems(action.payload.commentId, itemsToDelete, state);
      return state.filter(item => !itemsToDelete.includes(item._id));

    case commentConstants.ADD_ATTACHMENT_TO_COMMENT_SUCCESS:
      return state.map(note => {
        return note._id !== action.noteId
          ? note
          : { ...note, attachments: [...note.attachments, action.payload] };
      });

    case commentConstants.GET_ISSUE_TASK_COMMENTS_SUCCESS:
      return action.payload;

    case commentConstants.ADD_ISSUE_TASK_COMMENTS_SUCCESS:
      return [...state, action.payload];

    default:
      return state;
  }
}

let shouldDeleteItems = (parentId, itemsToDelete, state) => {
  for (var i = 0; i < state.length; i++) {
    if (state[i].parentComment === parentId) {
      itemsToDelete.push(state[i]._id);
      shouldDeleteItems(state[i]._id, itemsToDelete, state);
    }
  }
};

// #############################################################################
//  find the index of searched item in the array
// #############################################################################
