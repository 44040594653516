import React from "react";

import { connect } from "react-redux";
import { ReactInput } from "../../";
import { clientActions } from "../../../actions";
import ClientList from "./ClientList";
// #####[ Material UI ]#########################################################
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// #####[ Material ICONS ]######################################################
import SearchIcon from "@material-ui/icons/Search";

// Components //
import AddClientInline from "./AddClientInline";
import { BackButton } from "../../Buttons";
// #############################################################################
// component
// #############################################################################

class Clients extends React.Component {
  state = {
    searchKeyWord: "",
    selected: {},
    isnewClientAdded: ""
  };

  // ###########################################################################
  //
  // ###########################################################################

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(clientActions.getAllCompanies());
  };

  // ###########################################################################
  //
  // ###########################################################################

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  // ###########################################################################
  // Render
  // ###########################################################################

  render() {
    const { classes } = this.props;
    const { searchKeyWord } = this.state;
    return (
      <React.Fragment>
        {/* ############################################################### */}
        <Grid container className={classes.searchBar}>
          <BackButton />
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            className={classes.searchInputHolder}
          >
            <SearchIcon className={classes.searchIcon} />
            <ReactInput
              autoComplete="off"
              name="searchKeyWord"
              value={searchKeyWord}
              onChange={this.handleChange}
              placeholder="Filter Clients"
              className={classes.searchInput}
              type="text"
            />
          </Grid>
        </Grid>

        {/* ################################################################ */}
        <div className={classes.tabelContainer}>
          <AddClientInline />
          {/* ################################################################ */}
          <ClientList
            searchKeyWord={searchKeyWord}
            handleSlider={this.handleSlider}
          />
        </div>
        {/* ################################################################ */}
      </React.Fragment>
    );
  }
}

// #############################################################################
// styles
// #############################################################################
const styles = theme => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 22px",
    color: "#000000a3",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    height: "48px !important",
    minHeight: "48px !important"
  },

  // ///////////////////// search bar //////////////////////////
  searchBar: {
    borderBottom: "1px solid #e1e1e1",
    padding: 5,
    position: "sticky",
    top: 0,
    zIndex: 1000,
    backgroundColor: "#f4f5f9"
  },
  searchInput: {
    backgroundColor: "#ffffff00",
    color: "#00000099",
    paddingLeft: "40px",
    border: "none",
    zIndex: "0",
    "&:focus": {
      boxShadow: "none"
    }
  },
  searchIcon: {
    color: "#00000070",
    position: "absolute",
    marginLeft: "5px",
    zIndex: "0"
  },
  searchInputHolder: {
    position: "relative",
    display: "flex",
    alignItems: "center"
  },

  tabelContainer: {
    height: "calc(100vh - 49px)",
    overflow: "auto"
  }
});

// #############################################################################
// exports
// #############################################################################

const ClientsConnect = connect()(Clients);
const ClientsStyles = withStyles(styles)(ClientsConnect);

export { ClientsStyles as Clients };
