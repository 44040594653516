
// #############################################################################
// this function will calculate the percent of progress in the project 
// #############################################################################

export function calculateProgress(tasks = []){
  let numberOfTasks = 0;
  let sum = 0;
  tasks.forEach(task =>{
    if(task.parentTask === null) {
      sum += task.complete;
      numberOfTasks++;
    }
  })
  return sum / numberOfTasks
}