import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return localStorage.getItem("user") &&
          localStorage.getItem("token") !== "undefined" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export const FirstRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (
          localStorage.getItem("user") &&
          localStorage.getItem("token") !== "undefined"
        ) {
          const user = JSON.parse(localStorage.getItem("user"));

          if (user.role === "admin") {
            return (
              <Redirect
                to={{
                  pathname: "/admin-dashboard",
                  state: { from: props.location }
                }}
              />
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/user-dashboard",
                  state: { from: props.location }
                }}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};
