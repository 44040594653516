import React from "react";

//#####[ MATERIAL Ui ]##################################################
import { withStyles } from "@material-ui/core";

///////////////////////// components /////////////////////////////
import { AttachmentUploadFile } from "../../Attachments/AttachmentUploadFile";
import AddCostInline from "../../CostList/AddCostInline";
import { List } from "../../CostList/List";
import TotalCost from "../../CostList/TotalCost";
//##############################################################################
// component
//##############################################################################
class UserProjectCosts extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.costs}>
        {/* //////////////////// Add Costs Inline /////////////////////////// */}
        <AddCostInline />

        {/* ////////////////////// Attachment ////////////////////// */}
        <AttachmentUploadFile />

        {/* //////////////////////// total cost ////////////////////// */}
        <TotalCost />

        {/* //////////////////////// cost List ////////////////////// */}

        <List />
      </div>
    );
  }
}

//##############################################################################
// styles
//##############################################################################

const styles = theme => ({
  costs: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }
});

//##############################################################################
// map REDUX state to props and map material UI to the component
//##############################################################################
//

const UserProjectCostsStyles = withStyles(styles)(UserProjectCosts);

//##############################################################################
// export
//##############################################################################

export { UserProjectCostsStyles as UserProjectCosts };
