import React from 'react';
import { withStyles } from "@material-ui/core";
import { ReactInputDropdown } from './React-Input-Dropdown';
import onClickOutside from "react-onclickoutside";
import Close from '@material-ui/icons/Close';


class Dropdown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            cursor: 0,
            selectedItem: '',
            dropdownShowtoggle: false,
            dropdownData: this.props.dropdownData
        }
    }


    componentDidUpdate() {
        if (this.state.dropdownData.toString() !== this.props.dropdownData.toString()) {
            this.setState({
                dropdownData: this.props.dropdownData,
                cursor: 0
            })
        }
    }

    // This method active arrow key on dropdown
    handleKeyDown = (e) => {

        const { cursor, dropdownShowtoggle } = this.state
        const dropdownContainer = this.refs.dropdownContainer;
        const result = this.props.dropdownData

        // arrow up/down button should select next/previous list element
        if (e.keyCode === 38 && cursor > 0) {
            const Element = this.refs[`li${cursor - 1}`];

            // arrow up key
            if (dropdownContainer && Element) {
                var dropdownContainerTop = dropdownContainer.scrollTop;
                var dropdownContainerBottom = dropdownContainer.offsetHeight + dropdownContainerTop;


                var ElementTop = Element.offsetTop;
                var elementMiddle = Element.offsetHeight / 2 + ElementTop;

                if (!(elementMiddle < dropdownContainerBottom && dropdownContainerTop < elementMiddle)) {
                    Element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                }

            }

            this.setState(prevState => ({
                cursor: prevState.cursor - 1,
                dropdownShowtoggle: true
            }))

            //arrow down key
        } else if (e.keyCode === 40 && cursor < result.length - 1) {
            const Element = this.refs[`li${cursor + 1}`];

            if (dropdownContainer && Element) {
                ElementTop = Element.offsetTop;
                elementMiddle = Element.offsetHeight / 2 + ElementTop;

                if (!(elementMiddle < dropdownContainerBottom && dropdownContainerTop < elementMiddle)) {
                    Element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                }

            }

            this.setState(prevState => ({
                cursor: prevState.cursor + 1,
                dropdownShowtoggle: true
            }))
        }

        //  for hide dropdown when ENTER is clicked
        else if (e.keyCode === 13) {
            e.preventDefault();
            dropdownShowtoggle === true &&
                this.props.onChange({ target: { name: 'architectPM', value: result[`${cursor}`] } })
            this.setState({
                dropdownShowtoggle: false
            })
        }

        //  for show dropdown when BACKSPACE is clicked
        else if (e.keyCode === 8) {
            this.setState({
                dropdownShowtoggle: true,
                cursor: 0
            })
        }

        // for close dropdown when ESC is clicked
        else if (e.keyCode === 27) {
            this.setState({
                dropdownShowtoggle: false,
                cursor: 0
            })
        }

    }

    //toggle dropdown
    handleDropdownShowtoggle = (e) => {
        e.preventDefault();
        this.setState({
            dropdownShowtoggle: !this.state.dropdownShowtoggle
        })
    }
    // close dropdown by clicking outside
    handleClickOutside = evt => {
        this.setState({
            dropdownShowtoggle: false
        })
    };
    //show dropdown by click on the input tag
    handleOnClick = e => {
        this.setState({
            dropdownShowtoggle: true
        })
    }

    // send selected anchor-tag value to parents state
    handlerDropdownClicked = (e) => {
        this.props.onChange({ target: { value: e.target.value, name: 'architectPM' } })
    }

    render() {
        const { classes, dropdownData, onChange, value, placeholder } = this.props
        const { cursor, dropdownShowtoggle } = this.state
        return (
            <div
                className={classes.dropdown}
            >
                <div className={classes.inputContainer}>
                    <ReactInputDropdown
                        type="text"
                        value={value}
                        name="architectPM"
                        placeholder={placeholder}
                        handleFocus={this.handleDropdownShowtoggle}
                        onChange={onChange}
                        handleOnClick={this.handleOnClick}
                        className={classes.input}
                        onKeyDown={this.handleKeyDown}
                    />
                    <Close
                        className={classes.clearInputIcon}
                        onClick={() => onChange({ target: { value: '', name: 'architectPM' } })}
                    />
                </div>
                {dropdownShowtoggle && dropdownData[0] !== undefined &&
                    <div
                        className={classes.dropdownContainer}
                    >
                        <ul
                            className={classes.dropdownList}
                            onClick={this.handlerDropdownClicked}
                            ref={'dropdownContainer'}
                        >
                            {dropdownData && dropdownData.map((architect, index) => (
                                <li
                                    className={cursor === index ? `${classes.active}` : null}
                                    key={index}
                                    tabIndex={index}
                                    ref={`li${index}`}
                                >
                                    <button
                                        className={classes.linkButton}
                                        name="architectPM"
                                        onClick={this.handleDropdownShowtoggle}
                                        value={architect}
                                    >
                                        {architect}
                                    </button>
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                }
            </div>
        )
    }
}

const sytles = {
    input: {
        width: "100%",
        height: "38px",
        boxSizing: "border-box",
        padding: "8px",
        backgroundColor: "#ffffff",
        borderRadius: "5px",
        display: "flex",
        border: "1px solid #d7d7d7"
    },
    dropdown: {
        position: 'relative',
    },
    inputContainer: {
        position: 'realative'
    },
    clearInputIcon: {
        position: 'absolute',
        top: '7px',
        right: '6px',
        color: '#B3B3B3',
        cursor: 'pointer'
    },
    dropdownContainer: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #B3B3B3',
        backgroundColor: 'white',
        position: 'absolute',
        top: '42px',
        borderRadius: '5px',
        width: '100%',
        overflow: 'hidden',
        zIndex: 10
    },
    dropdownList: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '0',
        overflowY: 'overlay',
        overflowX: 'hidden',
        maxHeight: '160px',
        margin: 0,
        '& li': {
            cursor: 'pointer',
            '& :hover': {
                backgroundColor: '#bcd6ff'
            }
        }
    },
    linkButton: {
        display: 'block',
        width: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        textAlign: 'left',
        height: '39px',
        padding: '8px',
        borderBottom: '1px solid #f7f7f7',
        textDecoration: 'none',
        color: 'inherit',
        cursor: 'pointer',
        fontSize:"15px"
    },
    active: {
        backgroundColor: '#DEEBFF'
    }
}

const DropdownWithStyle = withStyles(sytles)(onClickOutside(Dropdown))
export { DropdownWithStyle as Dropdown }

