import React from "react";
import { connect } from "react-redux";

import { userUserActions } from '../../userActions';
import { UserProject } from '../UserDashboard/Projects/UserProject'


// #############################################################################
// 
// #############################################################################
// 
class PAC extends React.PureComponent {
  componentDidMount = () => {
    const { dispatch } = this.props;
    const { projectId } = this.props.match.params;
    dispatch(userUserActions.getMyRole({projectId}));
  };

  render() {
    const { userProject } = this.props;
    if (userProject && userProject.length > 0) {
      return (
        <>
          {
            (userProject[0].role === 'Project-Manager' ||
              userProject[0].role === 'Project-Admin' ||
              userProject[0].role === 'Project-Client') ?
              < UserProject projectId={userProject[0].project} userId={userProject[0].user} /> :
              <div />
          }
        </>
      )
    }
    return <div />
  }
}
// #############################################################################
//
// #############################################################################

const mapStateToProps = state => {
  return {
    userProject: state.users.userProject
  };
};
const PACConnect = connect(mapStateToProps)(PAC);

// #############################################################################
//
// #############################################################################

export { PACConnect as PAC };
