import React from "react";
import { withStyles } from "@material-ui/core";
import Header from "./Header";
import addingCommaToCost from "../reusebleFunctions/addingCommaToCost";

// #############################################################################
// Component
// #############################################################################
const styles = theme => ({
  tableCaption: {
    backgroundColor: "#256598",
    color: "white",
    width: "calc(100% - 4px)",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    left: "2px",
    position: "relative"
  },
  tableCaptionContainer: {
    backgroundColor: "transparent",
    color: "white",
    width: "calc(100% + 8px)",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    left: "-4px",
    position: "relative"
  },
  tableSubCaption: {
    backgroundColor: "#A5B3BF",
    color: "black",
    padding: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    marginTop: "5px",
    border: "1px solid #eff0f5",
    height: "45px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontWeight: "bold"
  },
  tableBody: {
    width: "100%"
  },
  headStyle: {
    height: "35px",
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "#A8B2BE"
  },
  dataStyle: {
    height: "35px",
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "white",
    color: "#505152"
  },
  subCaptionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dataStyleParent: {
    height: "35px",
    boxSizing: "border-box",
    padding: "5px",
    border: "1px solid #A8B2BE",
    backgroundColor: "#A8B2BE",
    color: "black",
    width: "20px"
  },
  dataStyleSpecial: {
    color: "#1B5A96",
    border: "1px solid #A8B2BE",
    padding: "5px",
    boxSizing: "border-box",
    backgroundColor: "white",
    textAlign: "center",
    height: "50px",
    fontWeight: "bold"
  }
});

// #############################################################################
// component
// ##########################################################
class ProgramReportTab extends React.PureComponent {
  formatDate = strData => {
    // to format given data
    if (strData === null || strData === undefined || strData === "") {
      return "";
    } else {
      let date = new Date(strData);
      return `${date.getMonth() + 1}/${date.getDay()}/${date.getFullYear()}`;
    }
  };

  render() {
    const { classes, project, report } = this.props;
    return (
      <div>
        <Header />
        {/* Table for Team members */}
        <table className={classes.tableBody}>
          <caption className={classes.tableCaption}>{"TEAM MEMBERS"}</caption>
          <thead>
            <tr>
              <td className={classes.headStyle}>{"Real Estate Manager"}</td>
              <td className={classes.headStyle}>{"Construction Manager"}</td>
              <td className={classes.headStyle}>{"Architect PM"}</td>
              <td className={classes.headStyle}>{"Developer"}</td>
              <td className={classes.headStyle}>{"General Contractor"}</td>
            </tr>
          </thead>

          {project && (
            <tbody>
              <tr>
                <td className={classes.dataStyle}>
                  {project.realEstateManager !== null &&
                    project.realEstateManager.firstName +
                    " " +
                    project.realEstateManager.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.constructionManager !== null &&
                    project.constructionManager.firstName +
                    " " +
                    project.constructionManager.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.architectPM !== null &&
                    project.architectPM.firstName +
                    " " +
                    project.architectPM.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.developer !== null &&
                    project.developer.firstName +
                    " " +
                    project.developer.lastName}
                </td>
                <td className={classes.dataStyle}>
                  {project.generalContractor !== null &&
                    project.generalContractor.firstName +
                    " " +
                    project.generalContractor.lastName}
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <br />
        <br />

        {/* Table for program summary */}
        <table className={classes.tableBody}>
          <caption className={classes.tableCaption}>
            {"PROGRAM SUMMARY"}
          </caption>
          <thead>
            <tr>
              <td className={classes.headStyle}>{"Planned Start"}</td>
              <td className={classes.headStyle}>{"Planned Finish"}</td>
              <td className={classes.headStyle}>{"Total Budget"}</td>
              <td className={classes.headStyle}>{"Total Actual"}</td>
              <td className={classes.headStyle}>{"Program Status"}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.dataStyleSpecial}>
                {this.formatDate(report.programPlannedStartDate)}
              </td>
              <td className={classes.dataStyleSpecial}>
                {this.formatDate(report.programPlannedFinishDate)}
              </td>
              <td className={classes.dataStyleSpecial}>
                {addingCommaToCost(report.programTotalBudget)}
              </td>
              <td className={classes.dataStyleSpecial}>
                {addingCommaToCost(report.programTotalActual)}
              </td>
              <td
                className={classes.dataStyleSpecial}
              >{`${report.programComplete}%`}</td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />

        {/* Table for Team schedule */}
        <table className={classes.tableBody}>
          <caption className={classes.tableCaptionContainer}>
            <div className={classes.tableCaption}>
              {"PROGRAM SUMMARY REPORT"}
            </div>
          </caption>
          <thead>
            <tr>
              <td className={classes.headStyle}>{"Project"}</td>
              <td className={classes.headStyle}>{"Status"}</td>
              <td className={classes.headStyle}>{"Complete"}</td>
              <td className={classes.headStyle}>{"Total Budget"}</td>
              <td className={classes.headStyle}>{"Total Actual"}</td>
              <td className={classes.headStyle}>{"Planned Start"}</td>
              <td className={classes.headStyle}>{"Planned Finished"}</td>
              <td className={classes.headStyle}>{"Actual Start"}</td>
              <td className={classes.headStyle}>{"Actual Finish"}</td>
              <td className={classes.headStyle}>{"Project Manager"}</td>
            </tr>
          </thead>
          <tbody>
            {report.projects.map(project => {
              return (
                <tr key={project._id}>
                  <td className={classes.dataStyle}>{project.name}</td>
                  <td className={classes.dataStyle}>{project.status}</td>
                  <td
                    className={classes.dataStyle}
                  >{`${project.complete}%`}</td>
                  <td className={classes.dataStyle}>
                    {addingCommaToCost(project.totals.budgetTotal)}
                  </td>
                  <td className={classes.dataStyle}>
                    {addingCommaToCost(project.totals.actualTotal)}
                  </td>
                  <td className={classes.dataStyle}>
                    {this.formatDate(project.plannedStartDate)}
                  </td>
                  <td className={classes.dataStyle}>
                    {this.formatDate(project.plannedFinishDate)}
                  </td>
                  <td className={classes.dataStyle}>
                    {this.formatDate(project.actualStartDate)}
                  </td>
                  <td className={classes.dataStyle}>
                    {this.formatDate(project.actualFinishDate)}
                  </td>
                  <td className={classes.dataStyle}>
                    {project.managers.filter(
                      manager => manager.role === "Project-Manager"
                    )[0] && //  to check if the project has project manager or not
                      project.managers.filter(
                        manager => manager.role === "Project-Manager"
                      )[0].user.firstName + //and it shows first project manager only
                      " " +
                      project.managers.filter(
                        manager => manager.role === "Project-Manager"
                      )[0].user.lastName}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const ProgramReportTabWithStyles = withStyles(styles)(ProgramReportTab);

export { ProgramReportTabWithStyles as ProgramReportTab };
