import { commentConstants, noteConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "../actions";

export const commentUserActions = {
  getComments,
  addComment,
  editComment,
  deleteComment,
  getIssueTaskComments,
  addIssueTaskComment
};

//################################################################################
// GET All comments
//################################################################################

function getComments(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getComments", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.comments));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(comments) {
    return {
      type: commentConstants.GET_ALL_COMMENTS_SUCCESS,
      payload: comments
    };
  }
  // function failure(error) {
  //   return { type: commentConstants.GET_ALL_COMMENTS_FAILURE, payload: error };
  // }
}

//################################################################################
// Add Comment
//################################################################################

function addComment(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("addComment", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(
            snackBarActions.snackBarSuccess("The Comment Added successfully!")
          );
          dispatch(success(response.comment));
          dispatch(changingNoteCommentsNumber(data));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(comment) {
    return { type: commentConstants.ADD_COMMENT_SUCCESS, payload: comment };
  }
  function changingNoteCommentsNumber(data) {
    return {
      type: noteConstants.CHANGEING_NUMBER_OF_COMMENTS,
      payload: data
    };
  }
  // function failure(error) {
  //   return { type: commentConstants.ADD_COMMENT_FAILURE, payload: error };
  // }
}

//################################################################################
// Edit Comment
//################################################################################
function editComment(data, document) {
  return dispatch => {
    dispatch(progressBarActions.start());
    if (document) {
      generalServices.service("addAttachment", document, "user").then(res => {
        if (res.success === true) {
          generalServices.service("editComment", data, "user").then(
            response => {
              if (response.success === true) {
                dispatch(success(response.comment));
                dispatch(
                  snackBarActions.snackBarSuccess("Changes saved successfully")
                );
              } else {
                dispatch(snackBarActions.snackBarError(response.msg));
              }
              dispatch(progressBarActions.stop());
            },
            error => {
              dispatch(progressBarActions.stop());
              dispatch(snackBarActions.snackBarError(error.message));
            }
          );
        }
      });
    } else {
      generalServices.service("editComment", data, "user").then(
        response => {
          if (response.success === true) {
            dispatch(success(response.comment));
            dispatch(
              snackBarActions.snackBarSuccess("Changes saved successfully")
            );
          } else {
            dispatch(snackBarActions.snackBarError(response.msg));
          }
          dispatch(progressBarActions.stop());
        },
        error => {
          dispatch(progressBarActions.stop());
          dispatch(snackBarActions.snackBarError(error.message));
        }
      );
    }
  };
  function success(data) {
    return { type: commentConstants.EDIT_COMMENT_SUCCESS, payload: data };
  }
  // function failure(error) {
  //   return { type: commentConstants.EDIT_COMMENT_FAILURE, payload: error };
  // }
}

//################################################################################
// Delete Comment
//################################################################################

function deleteComment(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteComment", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(
            snackBarActions.snackBarSuccess("The Comment Successfully removed!")
          );
          dispatch(success(data));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(data) {
    return {
      type: commentConstants.DELETE_COMMENT_SUCCESS,
      payload: data
    };
  }
  // function failure(error) {
  //   return { type: commentConstants.DELETE_COMMENT_FAILURE, payload: error };
  // }
}

//################################################################################
// GET Issue comments
//################################################################################

function getIssueTaskComments(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("getIssueTaskComments", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.issueTaskComments));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(comments) {
    return {
      type: commentConstants.GET_ISSUE_TASK_COMMENTS_SUCCESS,
      payload: comments
    };
  }
  // function failure(error) {
  //   return {
  //     type: commentConstants.GET_ISSUE_TASK_COMMENTS_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Add Issue comments
//################################################################################

function addIssueTaskComment(data) {
  return dispatch => {
    dispatch(progressBarActions.start());
    generalServices.service("addIssueTaskComment", data, "user").then(
      response => {
        if (response.success === true) {
          dispatch(success(response.issueTaskComment));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
        dispatch(progressBarActions.stop());
      },
      error => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(comments) {
    return {
      type: commentConstants.ADD_ISSUE_TASK_COMMENTS_SUCCESS,
      payload: comments
    };
  }
  // function failure(error) {
  //   return {
  //     type: commentConstants.ADD_ISSUE_TASK_COMMENTS_FAILURE,
  //     payload: error
  //   };
  // }
}
