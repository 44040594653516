import { clientConstants } from "../constants";
import { generalServices } from "../services";
import { progressBarActions, snackBarActions } from "./";
import { projectActions } from "./";

// userActions.getAllCompanies
// userActions.addCompany
// userActions.editCompany
// userActions.deleteCompany

export const clientActions = {
  getAllCompanies,
  addCompany,
  editCompany,
  deleteCompany,
  deleteCompanyImage,
  updateCompanyImage,
  changeCompanyOrder,
};

//################################################################################
// GET All Companies
//################################################################################

function getAllCompanies() {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    dispatch(progressBarActions.clientRequestStart());
    generalServices.service("getAllCompanies").then(
      (response) => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.companies));
          dispatch(progressBarActions.clientRequestEnd());
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
          dispatch(progressBarActions.clientRequestEnd());
        }
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(progressBarActions.clientRequestEnd());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(companies) {
    return {
      type: clientConstants.GET_ALL_COMPANIES_SUCCESS,
      payload: companies,
    };
  }
  // function failure(error) {
  //   return { type: clientConstants.GET_ALL_COMPANIES_FAILURE, payload: [] };
  // }
}

//################################################################################
// Add Company
//################################################################################

function addCompany(data, getprojectAfterClientAdded) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("createCompany", data).then(
      (response) => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          getprojectAfterClientAdded &&
            dispatch(projectActions.getAllProjects());

          dispatch(success(response.company));
          dispatch(
            snackBarActions.snackBarSuccess("Company added successfully!!")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(company) {
    return { type: clientConstants.ADD_COMPANY_SUCCESS, payload: company };
  }
  // function failure(error) {
  //   return { type: clientConstants.ADD_COMPANY_FAILURE, payload: error };
  // }
}

//################################################################################
// Edit Company
//################################################################################

function editCompany(data) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("editCompany", data).then(
      (response) => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.company));
          dispatch(
            snackBarActions.snackBarSuccess("Company edited successfully!!")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(company) {
    return { type: clientConstants.EDIT_COMPANY_SUCCESS, payload: company };
  }
  // function failure(error) {
  //   return { type: clientConstants.EDIT_COMPANY_FAILURE, payload: error };
  // }
}

//################################################################################
// Delete Company
//################################################################################

function deleteCompany(data) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteCompany", data).then(
      (response) => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.company));
          dispatch(
            snackBarActions.snackBarSuccess("Company deleted successfully!!")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };

  function success(company) {
    return { type: clientConstants.DELETE_COMPANY_SUCCESS, payload: company };
  }
  // function failure(error) {
  //   return { type: clientConstants.DELETE_COMPANY_FAILURE, payload: error };
  // }
}

//################################################################################
// DELETE COMPANY IMAGE
//################################################################################

function deleteCompanyImage(data) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("deleteCompanyImage", data).then(
      (response) => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.company));
          dispatch(
            snackBarActions.snackBarSuccess("Company deleted successfully!!")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(companyId) {
    return {
      type: clientConstants.DELETE_COMPANY_IMAGE_SUCCESS,
      payload: companyId,
    };
  }
  // function failure(error) {
  //   return {
  //     type: clientConstants.DELETE_COMPANY_IMAGE_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// UPDATE COMPANY IMAGE
//################################################################################

function updateCompanyImage(data) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("updateCompanyImage", data).then(
      (response) => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(response.company));
          dispatch(
            snackBarActions.snackBarSuccess("Company deleted successfully!!")
          );
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(data) {
    return {
      type: clientConstants.UPDATE_COMPANY_IMAGE_SUCCESS,
      payload: data,
    };
  }
  // function failure(error) {
  //   return {
  //     type: clientConstants.UPDATE_COMPANY_IMAGE_FAILURE,
  //     payload: error
  //   };
  // }
}

//################################################################################
// Change Project Order
//################################################################################

function changeCompanyOrder(data, allCompanies) {
  return (dispatch) => {
    dispatch(progressBarActions.start());
    generalServices.service("changeCompanyOrder", data).then(
      (response) => {
        dispatch(progressBarActions.stop());
        if (response.success === true) {
          dispatch(success(allCompanies));
          dispatch(snackBarActions.snackBarSuccess("successful!!"));
        } else {
          dispatch(snackBarActions.snackBarError(response.msg));
        }
      },
      (error) => {
        dispatch(progressBarActions.stop());
        dispatch(snackBarActions.snackBarError(error.message));
      }
    );
  };
  function success(data) {
    return {
      type: clientConstants.CHANGE_CLIENT_ORDER_SUCCESS,
      payload: data,
    };
  }
  // function failure() {
  //   return {
  //     type: clientConstants.CHANGE_CLIENT_ORDER_FAILURE,
  //     payload: []
  //   };
  // }
}
