
// #############################################################################
// this function will detect if the element scroll to the end of its parent of not
// #############################################################################

export function detectScroll(element = {}){
  if(!isElement(element)) return;

  const {scrollWidth , scrollLeft, clientWidth  } = element;
  const {scrollHeight , scrollTop, clientHeight } = element;
  
  const atTop = scrollTop === 0;
  const atLeft = scrollLeft === 0;
  const atRight = scrollWidth === scrollLeft + clientWidth;
  const atBottom = scrollHeight === scrollTop + clientHeight;
  const horizontalScroll = clientWidth < scrollWidth;
  const verticalScroll = clientHeight < scrollHeight;

  const scrollRight = scrollWidth - (scrollLeft + clientWidth)

  return {
    atBottom,
    atRight,
    atLeft,
    atTop,
    horizontalScroll,
    verticalScroll,
    scrollWidth,
    scrollLeft,
    scrollRight,
    clientWidth,
    scrollHeight,
    scrollTop,
    clientHeight
  }
}


// #############################################################################
// this function will check if the passed variable is element or not
// #############################################################################

function isElement(element){
  if(!element.clientWidth)
    console.warn("There is no element properties in the passed object!");
  return (element.clientWidth ? true : false);
}

