import React from "react";
import { Portal } from 'react-overlays'
import DatePicker from 'react-datepicker'
import "./react-datepicker.css";

const CalendarContainer = ({children}) => {
  const el = document.getElementById('calendar-portal')

  return (
    <Portal style={{zIndex: 2000}} container={el}>
      {children}
    </Portal>
  )
}


const PDatePicker = props => {
  return (
    <DatePicker popperContainer={CalendarContainer} {...props} />
  )
}

export default PDatePicker;