import React from "react";
import {findRootProject,  findRoadToParent } from "./helpers";
import { calculateLevel, showProjectOnProjectTree } from "./helpers";
import { calculateChartWidth, convertToObject, calculateOrders } from "./helpers"

import { ProjectBean } from "./project-bean"
import { withRouter } from "react-router-dom";
import { NavigationButtons } from "./navigation-buttons"

import { isEqual } from "../"
import { Lines } from "./lines";
import { Search } from "./search-result"

// #####[ MATERILA UI ]#########################################################
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';

// #####[ MATERIAL ICON ]#######################################################
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// #############################################################################
// component
// #############################################################################

class ProjectTreeChart extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = {
      projects: undefined,
      projectsObject: undefined,
      rootProject: undefined,
      highlight: undefined,
      focus: false,
      noData: true,
      selectedProject: undefined,
      roadToParent: [],
      searchQuery: ""
    }
  }

  // ###########################################################################
  // get the props
  // ###########################################################################

  static getDerivedStateFromProps(nextProps, prevState){
    const { projects = [] } = nextProps;
    const selectedProject = prevState.selectedProject ? prevState.selectedProject : nextProps.selectedProject
    const noData = projects.length === 0;
    let nextState = { noData };

    if(!prevState.selectedProject){
      nextState = {...nextState, selectedProject}
    }

    if(!isEqual(projects, prevState.projects)){
      let projectsObject = convertToObject(projects);
      projectsObject = calculateLevel(projectsObject);
      calculateOrders(projectsObject)
      const rootProject = findRootProject(projectsObject[selectedProject], projectsObject);
      const highlight = rootProject;
      nextState = { ...nextState, projectsObject, rootProject, highlight, projects};
    }

    if(!noData && selectedProject) {
      const rawData = convertToObject(projects);
      const roadToParent = findRoadToParent(selectedProject, rawData);
      nextState = { ...nextState, roadToParent}
    }

    return nextState;
  }

  // ###########################################################################
  // component will unmount
  // ###########################################################################
  
  componentWillUnmount = () =>  {
    this.removeEventListeners();
  }

  // ###########################################################################
  // remove event listener
  // ###########################################################################

  removeEventListeners = () => {
    document.removeEventListener("keydown", this.handleNavigation);
    this.setState({focus: false})
  }

  // ###########################################################################
  // add event listener
  // ###########################################################################

  addEventListeners = () => {
    document.addEventListener("keydown", this.handleNavigation);
    if(!this.state.focus) this.setState({focus: true})
  }

  // ###########################################################################
  // convert the array to object
  // ###########################################################################

  convertToObject = (projects = []) => {
    let projectsObject = {};
    projects.forEach(item => {
      projectsObject[item._id] = item;
      projectsObject[item._id].expand = false;
    })
    return projectsObject;
  }

  // ###########################################################################
  // set highlight
  // ###########################################################################

  setHighlight = (highlight) =>{ this.setState({highlight}) }

  // ###########################################################################
  // find index
  // ###########################################################################

  findIndex = (projects = [], selected) => {
    let output = 0
    projects.forEach((id ,index) => { output = id === selected ? index : output })
    return output;
  }


  // ###########################################################################
  // find next sibling
  // ###########################################################################

  findNextSibling = () => {
    const { highlight, projectsObject } = this.state;
    const parent = projectsObject[highlight.parentProject];
    const arrayLength = parent.subProject.length;
    const index = this.findIndex(parent.subProject, highlight._id);
    const left = projectsObject[parent.subProject[Math.max(index - 1, 0)]]
    const right = projectsObject[parent.subProject[Math.min(index + 1, arrayLength - 1)]];
    return {left, right};
  }

  // ###########################################################################
  // this function will change the navigation buttons color for a few moment to 
  // show which button has been pressed on the keyboard
  // ###########################################################################

  handleHighlight = (id) => {
    const node = document.getElementById(id);
    const defaultColor = "#487599";
    const highlightColor = "#2d495f";
    node.style.backgroundColor = highlightColor;
    setTimeout(() => {
      node.style.backgroundColor = defaultColor;
    }, 120)
  }

  // ###########################################################################
  // this function will check if the key is in the acceptable list of not
  // ###########################################################################


  isAcceptable = (evnetCode) => {
    const keys = ["ArrowLeft", "ArrowRight", "ArrowDown", "ArrowUp", "Enter", "Space"];
    let acceptable = false;

    keys.forEach(key => { acceptable = acceptable || key === evnetCode });

    return acceptable;
  }

  // ###########################################################################
  // handle navigation: this function will lister to the keyboard and act like 
  // the user is navigating through the project
  // ###########################################################################

  handleNavigation = (event, shouldHighlight = true) => {
    if(this.isAcceptable(event.code)) event.preventDefault();
    const { highlight, projectsObject  } = this.state;

    const parent  = projectsObject[highlight.parentProject];
    const hasChild = highlight.subProject.length > 0;
    const nextState = {};

    if(event.code === "ArrowLeft" && parent){
     const { left } =  this.findNextSibling();
     this.setHighlight(left);
     if(shouldHighlight) this.handleHighlight("NavigationButtonArrowLeft");
     
    }

    else if(event.code === "ArrowRight" && parent){
    const { right } =  this.findNextSibling();
     this.setHighlight(right);
     if(shouldHighlight) this.handleHighlight("NavigationButtonArrowRight");
    }

    else if(event.code === "ArrowDown" && hasChild){
      this.setHighlight(projectsObject[highlight.subProject[0]]);
      this.handleExpand(highlight, parent, true)();
      if(shouldHighlight) this.handleHighlight("NavigationButtonArrowDown");
    }

    else if(event.code === "ArrowUp" && parent){
      this.setHighlight(parent);
      this.handleExpand(parent, undefined, false)();
      if(shouldHighlight) this.handleHighlight("NavigationButtonArrowUp");
    }

    else if( event.code === "Enter" || event.code === "Space"){
      const link = `/${this.props.adminRole ? "admin-dashboard": "user-dashboard"}/projects/${highlight._id}`;
      this.props.history.push(link);
      this.setState({selectedProject: highlight._id});
    }

    this.setState(nextState);

  }

  // ###########################################################################
  // this function will handle the navigation of at the projects tree with mouse
  // click
  // ###########################################################################

  handleNavigationOnMouseClick = (code) => {
    const preventDefault = () => {};
    this.handleNavigation({code, preventDefault}, false)
  }

  // ###########################################################################
  // handle Expand
  // ###########################################################################

  handleExpand = (selected = {}, parent = {}, value = false, eventType = "" ) =>  () => {
    const projects = { ...this.state.projectsObject };
    if(parent.subProject)
      parent.subProject.forEach(child => {
        projects[child].expand = projects[child]._id === selected._id && (!projects[child].expand || value);
      })
    else {
      projects[selected._id].expand = !projects[selected._id].expand || value;
    }
    if(eventType === "onClick")
      this.setState({ projectsObject: projects, highlight: selected });
    else 
      this.setState({ projectsObject: projects });
  }

  // ###########################################################################
  // this handle the link click
  // ###########################################################################

  goToLink = (selectedProject) => {
    const link = `/${this.props.adminRole ? "admin-dashboard": "user-dashboard"}/projects/${selectedProject._id}/project-dashboard`
    this.setHighlight(selectedProject);
    this.setState({selectedProject: selectedProject._id})
    this.props.history.push(link)
  }

  // ###########################################################################
  // render
  // ###########################################################################

  handleSearch = (projectId) => {
    this.setHighlight(this.state.projectsObject[projectId]);
    const projectsObject = showProjectOnProjectTree(projectId, this.state.projectsObject);
    this.setState({ selectedProject: projectId, projectsObject })

    const link = `/${this.props.adminRole ? "admin-dashboard": "user-dashboard"}/projects/${projectId}`
    this.props.history.push(link)
  }

  // ###########################################################################
  // render
  // ###########################################################################

  render = () => {
    const { adminRole, classes } = this.props;
    const { highlight, projectsObject = {}, rootProject } = this.state;
    const { focus, noData, roadToParent = [], selectedProject = ""} = this.state;
    const chartWidth = calculateChartWidth(projectsObject, 170);

    if(rootProject){

      const isSelected = selectedProject === rootProject._id;
      const isExpand = projectsObject[rootProject._id].expand;

      return (
        <ClickAwayListener onClickAway={this.removeEventListeners}>
        <div className={classes.root}>

          {/* ############################################################## */}
          <NavigationButtons handleNavigation={this.handleNavigationOnMouseClick} />

          {/* ############################################################## */}

          <Search selectedProject={highlight} clearFucos={this.removeEventListeners} handleSearch={this.handleSearch} projects={projectsObject}/>

          {/* ############################################################## */}

          <div className={`${classes.chartHolder} ${focus ? classes.focus : ""}`} onClick={this.addEventListeners} >

          {/* ############################################################## */}

          {noData ? null : <Lines width={chartWidth} projects={projectsObject} roadToParent={roadToParent} /> }

          {/* ############################################################## */}

          <div className={classes.rootButtonHolder}>
            <div className={`${classes.projectButton} ${highlight._id === rootProject._id ? classes.highlight : "" } ${isExpand ? classes.expanded : "" } ${isSelected ? classes.currentProject: ""}`}>

              <IconButton className={classes.expandButton} onClick={this.handleExpand(rootProject, {}, !isExpand, "onClick" )}>
                <ExpandMoreIcon classes={{root: `${classes.icon} ${isExpand ? isSelected ? classes.iconExpandWhite : classes.iconExpandDark : classes.default  }`}}/>
              </IconButton>

              <div 
                className={`${classes.text} ${isExpand || isSelected ? classes.currentProjectText  : "" }`}
                onClick={() => {this.goToLink(rootProject)}}
              >
              {`${rootProject.name} (ROOT PROJECT)`} 
              </div>

            </div>
          </div>

          {/* ############################################################## */}

            {!isExpand && rootProject.subProject && rootProject.subProject.length > 0 ? null :
              <div style={{flex: 1, zIndex: 2, position: "relative"}}>
                <ProjectBean
                  goToLink={this.goToLink}
                  highlight={highlight}
                  adminRole={adminRole}
                  currentProject={selectedProject}
                  parent={rootProject}
                  projects={projectsObject}
                  handleExpand={this.handleExpand}
                />
              </div>
            }

          {/* ############################################################## */}

          </div>
          </div>
        </ClickAwayListener>
      );
    }
    else return null
  }
}

// #############################################################################
// styles
// #############################################################################

const styles = theme => ({
  root:{
    width: "100%",
  },
  chartHolder:{
    position:"relative",
    overflowX:"auto",
    width: "100%",
    padding: "0px 5px",
    margin: "10px 0px",
    boxSizing: "border-box",
    transition:"box-shadow 200ms, background 150ms",
    "&:hover ":{
      background: "#00000012",
    }
  },
  rootButtonHolder:{
    display: "flex",
    padding:"12px 3px",
    overflowX:"auto",
    position: "relative",
    zIndex: 2,
  },
  focus: {
    boxShadow: "0px 0px 0px 4px #ff860057",
    background: "#00000000 !important",
  },
  projectButton:{
    height: 30,
    boxSizing: "border-box",
    width: "fit-content",
    padding:"2px",
    margin: "0px 5px",
    fontSize: "12px",
    color: "#487599",
    display: "flex",
    alignItems: "center",
    borderTop: "2px solid #487599",
    borderBottom: "2px solid #00000000",
    backgroundColor: "white",
    boxShadow: "0px 0px 3px 0px #0000001c",
    "&:hover":{
      cursor: "pointer",
    }
  },
  highlight:{
    boxShadow: "0px 0px 0px 3px #ffaa0091",
  },
  expanded:{
    backgroundColor:"#4875999c !important",
    color: "white"
  },
  currentProject:{
    backgroundColor:"#487599 !important",
    "& > a": {
      color: "white !important"
    }
  },

  // ###########################################################################

  text: {
    padding:"6px 30px 6px 5px",
    fontWeight: 700,
    color:"#70818e",
    textDecoration:"none"
  },
  expandedText:{
    color: "white !important",
  },
  currentProjectText: {
    color: "white !important",
    maxWidth: "400px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  // ###########################################################################

  expandButton: {
    marginLeft: 5,
    padding: 0,
    width: 20,
    height: 20,
    "& > span":{
      width: 20,
      height: 20,
    }
  },
  icon:{
    transition:"transform 200ms"
  },
  iconExpandDark:{
    transform: "rotate(180deg)",
    color: "white",
  },
  iconExpandWhite:{
    transform: "rotate(180deg)",
    color: "white",
  },
  default: {
    transform: "rotate(0deg)",
  },

})

const ProjectTreeChartWithStyles = withStyles(styles)(ProjectTreeChart);
const ProjectTreeChartWithRouter = withRouter(ProjectTreeChartWithStyles)

// #############################################################################
// export
// #############################################################################

export { ProjectTreeChartWithRouter as  ProjectTreeChart}
