import React from "react";
import { withStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

//############################################################################
// styles
//############################################################################
const styles = () => ({
  textField: {
    display: "flex",
    direction: "ltr",
    flexDirection:"row-reverse",
    alignItems: "center",
    height: "45px",
    borderRadius: "22px",
    backgroundColor: "#e6e6e6",
    border: "2px solid #e6e6e6"
  },
  selectInput: {
    borderRadius: "0 22px 22px 0",
    fontSize: "14px",
    border: "none",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    color: "#6d6d6d",
    backgroundColor: "#e6e6e600",
    transition:"fontSize 150ms",
    "&:focus": {
      outline: "none"
    },
    "&:focus + div": {
      padding: "4px 8px 0px 10px",
    },
    "&::-ms-expand": {
      display: "none"
    },
    "-webkit-appearance": "none",
    appearance: "none"
  },
  icon: {
    padding: "4px 10px 0px 15px",
    float: "left",
    color: "#c6c6c6",
    fontSize: "16px",
    transition: " padding 150ms"
  }
});
//############################################################################
// component
//############################################################################

function Select(props) {
  const { htmlFor, classes, required, children, name, className, value, defaultValue } = props;
  return (
    <div className={`${classes.textField} ${className}`}>
      <select htmlFor={htmlFor} defaultValue={defaultValue} value={value} required={required} name={name} onChange={props.onChange} className={classes.selectInput} type={"text"}>
        {children}
      </select>
      <div className={classes.icon}>
        <KeyboardArrowDownIcon color="action" style={{ fontSize: "20px" }} />
      </div>
    </div>
  );
}

//############################################################################
// export
//############################################################################
const SelectWithStyles = withStyles(styles)(Select);
export { SelectWithStyles as Select };
