import React from "react";

// #####[ material ui ]##############################################################
import { withStyles } from "@material-ui/core";
import Person from "@material-ui/icons/Person";

// #####[ redux ]##############################################################
import { connect } from "react-redux";
import { activityActions } from "../../actions";
import { activityUserActions } from "../../userActions";
// #####[ reusable functions ]##############################################################
import pastTimeSoFar from "../reusebleFunctions/pastTimeSoFar";

// #####[ styles ]##############################################################
import { Colors } from "../../styles";

class ActivityModalTab extends React.PureComponent {
  componentDidMount() {
    if (this.props.userRoleType === "admin") {
      this.props.dispatch(
        activityActions.getActivities({
          issueTaskId: this.props.clickedRowDataId
        })
      );
    } else {
      this.props.dispatch(
        activityUserActions.getActivities({
          issueTaskId: this.props.clickedRowDataId
        })
      );
    }
  }

  render() {
    const { classes, activities } = this.props;
    // const {} = this.state;
    return (
      <>
        {/* // NOTE Activity */}
        <section className={classes.activity}>
          <article className={classes.subContainerActivityList}>
            {activities &&
              activities.map((activity, index) => (
                <div className={classes.activityItem} key={index}>
                  <Person className={classes.attachmentItemUserIcon} />
                  <span>
                    <span>
                      {activity.user.firstName + " " + activity.user.lastName}
                    </span>
                    <span>{activity.action}</span>
                  </span>
                  <span>{pastTimeSoFar(activity.createDate)}</span>
                </div>
              ))}
          </article>
        </section>
      </>
    );
  }
}

const styles = {
  activity: {
    // width: "50%",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    flexGrow: 100,
    maxHeight: "600px",
    overflowY: 'overlay',
    padding: '0 10px'
  },
  subContainerActivityList: {
    overflowY: "overlay",
    paddingRight: "10px",
    flexGrow: 100
  },
  activityItem: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "row",
    padding: "15px 10px",
    marginBottom: " 6px ",
    borderRadius: "5px",
    "& span": {
      fontSize: "12px",
      color: Colors.grey
    },
    "& span > span:first-child": {
      color: Colors.darkGreen,
      marginRight: "4px",
      fontWeight: "bold",
      width: "20px"
    },
    "& > span:nth-child(2)": {
      paddingRight: "15px",
      flexGrow: "100",
      textOverflow: "ellipsis",
      overflow: "hidden",
      hyphens: "auto"
    },
    "& > span:nth-child(3)": {
      fontSize: "10px",
      width: "120px",
      textAlign: "center",
      minWidth: '120px'
    }
  },
  attachmentItemUserIcon: {
    color: Colors.grey,
    fontSize: "16px",
    height: "100%",
    marginRight: "4px"
  }
};

const mapStateToProps = state => {
  return {
    projectId: state.projects.project._id,
    role: state.projects.project.role,
    activities: state.activities
  };
};

const tempTemp = connect(mapStateToProps)(withStyles(styles)(ActivityModalTab));

export { tempTemp as ActivityModalTab };
