// React //
import React from "react";

// Redux //
import { connect } from "react-redux";
import { noteActions, attachmentActions } from "../../actions";
import { noteUserActions, attachmentUserActions } from "../../userActions";
// Material Ui //
import { withStyles } from "@material-ui/core";

// Components //
import NoteItem from "./NoteItem";
import DeleteModal from "../Modal/DeleteModal";
import NoteModalContent from "./NoteModalContent";
import HOCModal from "../Modal/Modal";
// Reusable Functions //

// Styles //
import { Colors } from "../../styles/colors";
class NoteMonthContainer extends React.PureComponent {
  state = {
    openDeleteModal: false,
    selectedOptionForDelete: {},
    selectedOption: {},
    clickedRowDataId: "",
    infoModalIsShowed: false
  };

  handleDeleteModal = selectedOptionForDelete => {
    selectedOptionForDelete
      ? this.setState({
          openDeleteModal: true,
          selectedOptionForDelete
        })
      : this.setState({
          openDeleteModal: false
        });
  };

  handleAddAttachment = data => {
    const { role, dispatch } = this.props;
    dispatch(
      role === "adminFlag"
        ? attachmentActions.addAttachment(data)
        : attachmentUserActions.addAttachment(data)
    );
  };

  handleEditNote = data => {
    const { role, dispatch } = this.props;
    dispatch(
      role === "adminFlag"
        ? noteActions.editNote(data)
        : noteUserActions.editNote(data)
    );
  };

  handlerInfoModal = (bool, noteId) => {
    this.setState({
      infoModalIsShowed: !!bool,
      noteId
    });
  };

  render() {
    const { classes, notes, date, role, comments, avatar } = this.props;
    const { infoModalIsShowed, noteId } = this.state;
    return (
      <div className={classes.NoteMonthContainer}>
        <p className={classes.monthTitle}>{date}</p>
        {notes.map((note, index) => (
          <NoteItem
            key={index}
            handleDeleteModal={this.handleDeleteModal}
            note={note}
            dispatch={this.props.dispatch}
            handleAddAttachment={this.handleAddAttachment}
            handleEditNote={this.handleEditNote}
            handlerInfoModal={this.handlerInfoModal}
            role={role}
            comments={comments}
            avatar={avatar}
          />
        ))}

        <DeleteModal
          open={this.state.openDeleteModal}
          handleDeleteModal={this.handleDeleteModal}
          selectedOptionForDelete={
            this.state.selectedOptionForDelete.link
              ? { attachmentId: this.state.selectedOptionForDelete._id }
              : { noteId: this.state.selectedOptionForDelete._id }
          }
          adminAction={
            this.state.selectedOptionForDelete.link
              ? attachmentActions.deleteAttachment
              : noteActions.deleteNote
          }
          userAction={
            this.state.selectedOptionForDelete.link
              ? attachmentUserActions.deleteAttachment
              : noteUserActions.deleteNote
          }
          //optional
          modalTitle={
            this.state.selectedOptionForDelete.link
              ? "Delete Attachment"
              : "Delete Journal"
          }
        />

        <HOCModal
          isShowed={infoModalIsShowed}
          handlerInfoModal={this.handlerInfoModal}
        >
          <NoteModalContent
            handlerInfoModal={this.handlerInfoModal}
            // clickedRowDataId={this.state.clickedRowDataId}
            noteId={noteId}
          />
        </HOCModal>
      </div>
    );
  }
}

const Styles = {
  NoteMonthContainer: {
    display: "flex",
    flexDirection: "column"
  },
  monthTitle: {
    color: Colors.darkBlue,
    borderBottom: "1px solid" + Colors.lightGrey,
    padding: "5px",
    fontWeight: "500",
    margin: "20px 0 5px"
  }
};

const mapStateToProps = state => ({
  role: state.projects.project ? state.projects.project.role : undefined,
  comments: state.comments ? state.comments : []
});

export default connect(mapStateToProps)(withStyles(Styles)(NoteMonthContainer));
