import { constructionConstants } from "../constants";

export function construction(state = {}, action) {
  switch (action.type) {
    case constructionConstants.GET_FORM_SUCCESS:
      return action.payload;

    default:
      return state;
  }
}
