import React from 'react'
import { withStyles } from '@material-ui/core'
import DateRange from "@material-ui/icons/DateRange";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '../../styles/colors'

const styles = {
    iconButton: {
        padding: '3px !important',
        color: Colors.grey,
    },
}

export const DateRangeIcon = React.memo(withStyles(styles)(({ classes, onClick, title }) => (
    <Tooltip title={title}>
        <IconButton onClick={onClick} className={classes.iconButton} component="span">
            <DateRange />
        </IconButton>
    </Tooltip>
))
)
