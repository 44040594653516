import React from "react";
import { withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppIcon from "@material-ui/icons/Apps";
import MailIcon from '@material-ui/icons/Mail';

const styles = theme => ({
  topBar: {
    boxShadow: "none",
    backgroundColor: "White",
    color: '#55C5FF'
  },
  spacer: {
      flex: 1,
  }
});

const TopBar = props => {
  const { classes } = props;
  return (
    <AppBar position="fixed" className={classes.topBar}>
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={props.toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.spacer}></div>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Apps"
          onClick={props.handleApps}
        >
          <AppIcon />
        </IconButton>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Messages"
        >
          <MailIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

const TopBarWithStyle = withStyles(styles)(TopBar);
export { TopBarWithStyle as TopBar };
