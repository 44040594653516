import React from "react";
import { ReactInput } from "../../";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Colors } from "../../../styles";
import Creatable from "react-select/lib/Creatable";

// ############################################################################
// component
// ############################################################################
class ClientEditSlider extends React.PureComponent {
  state = {
    openEditClient: false,
    name: this.props.company.name,
    pointOfContact: this.props.company.pointOfContact,
    primaryContact: this.props.company.primaryContact,
    email: this.props.company.email,
    address: this.props.company.address,
    phone: this.props.company.phone,
    faxOrMobile: this.props.company.faxOrMobile,
    _id: this.props.company._id,
    categories: []
  };

  componentDidUpdate(prevProps) {
    const { company } = this.props;
    prevProps.company !== this.props.company &&
      this.setState({
        name: company.name,
        pointOfContact: company.pointOfContact,
        primaryContact: company.primaryContact,
        email: company.email,
        address: company.address,
        phone: company.phone,
        faxOrMobile: company.faxOrMobile,
        _id: this.props.company._id,
        categories: []
      });
  }

  // #####[ this function get data from parent ]###############################
  selectClient = client => {
    this.setState({ ...client });
  };

  // #####[ handle changes ]###################################################
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // #####[ clear the state ]###################################################
  clearState = () => {
    const { company } = this.props;
    this.setState({
      _id: company._id,
      name: company.name,
      pointOfContact: company.pointOfContact,
      primaryContact: company.primaryContact,
      email: company.email,
      address: company.address,
      phone: company.phone,
      faxOrMobile: company.faxOrMobile,
      categories: company.category
    });
    this.props.handleSlider();
  };

  // ##########################################################################
  // this function will call a function in parent component
  handleSave = e => {
    e.preventDefault();
    this.props.handleSlider();
    const {
      name,
      pointOfContact,
      primaryContact,
      email,
      address,
      phone,
      faxOrNumber,
      _id,
      categories
    } = this.state;
    this.props.handleEditClient({
      companyId: _id,
      name,
      pointOfContact,
      primaryContact,
      email,
      address,
      phone,
      faxOrNumber,
      category: Array.from(
        new Set([
          ...categories.map(cat => cat.value),
          ...this.props.company.category
        ])
      )
    });
  };

  handleChangeSelect = (e, name) => {
    this.setState({
      [name]: e
    });
  };

  handleCategoryChange = categories => {
    this.setState({
      categories
    });
  };

  // ##########################################################################
  render() {
    const { classes } = this.props;
    const { categories } = this.state;
    return (
      <form
        autoComplete="off"
        onSubmit={this.handleSave}
        className={classes.clientInfoRoot}
        onKeyPress={event => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Grid container>
          <Grid container>
            <Grid item xs={12} className={classes.clientInfoInputItems}>
              <div className={classes.infoTitle}>Name</div>
              <ReactInput
                // required={this.state._id !== undefined}
                className={classes.clientInfoInput}
                placeholder="Name"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.clientInfoInputItems}>
              <div className={classes.infoTitle}>Point Of Contact</div>
              <ReactInput
                // required={this.state._id !== undefined}
                className={classes.clientInfoInput}
                placeholder="Point Of Contact"
                name="pointOfContact"
                value={this.state.pointOfContact}
                onChange={this.handleChange}
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.clientInfoInputItems}>
              <div className={classes.infoTitle}> Primary Contact</div>
              <ReactInput
                // required={this.state._id !== undefined}
                className={classes.clientInfoInput}
                placeholder="Primary Contact"
                name="primaryContact"
                value={this.state.primaryContact}
                onChange={this.handleChange}
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.clientInfoInputItems}>
              <div className={classes.infoTitle}>Email Address</div>
              <ReactInput
                // required={this.state._id !== undefined}
                className={classes.clientInfoInput}
                placeholder="Email Address"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                type="email"
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.clientInfoInputItems}>
              <div className={classes.infoTitle}>Programs</div>
              <Creatable
                isMulti
                onChange={this.handleCategoryChange}
                options={[]}
                value={categories}
                placeholder="Add More Programs"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Menu: () => null
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.clientInfoInputItems}>
              <div className={classes.infoTitle}> Phone </div>
              <ReactInput
                // required={this.state._id !== undefined}
                className={classes.clientInfoInput}
                placeholder="Phone"
                name="phone"
                value={this.state.phone}
                onChange={this.handleChange}
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.clientInfoInputItems}>
              <div className={classes.infoTitle}>Fax Or Mobile</div>
              <ReactInput
                // required={this.state._id !== undefined}
                className={classes.clientInfoInput}
                placeholder="Fax Or Mobile"
                name="faxOrMobile"
                value={this.state.faxOrMobile}
                onChange={this.handleChange}
                required
                type="number"
              />
            </Grid>
            <Grid item xs={12} className={classes.clientInfoInputItems}>
              <div className={classes.infoTitle}>Address</div>
              <ReactInput
                // required={this.state._id !== undefined}
                className={classes.clientInfoInput}
                placeholder="Address"
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
                type="text"
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.buttonsWrapper}>
            <Button
              className={classes.clientInfoCancelButton}
              onClick={this.clearState}
            >
              {"cancel"}
            </Button>
            <Button type="submit" className={classes.clientInfoSaveButton}>
              {"save"}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const Styles = theme => ({
  selectAddGridItem: {
    flex: 1,
    padding: "5px"
  },
  headerInputs: {
    padding: "5px",
    flex: 1
  },
  headerButtonHolder: {
    padding: "5px 5px 5px 0px",
    width: "95px"
  },
  header: {
    padding: "20px 20px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 20px 10px"
    }
  },
  titleTopHeader: {
    width: "90px",
    padding: "10px 5px 10px 10px",
    color: "#155794",
    textAlign: "center",
    fontSize: "15px"
  },
  halfWidth: {
    width: "50% !important",
    "& > div": {
      padding: "15px 10px"
    }
  },
  buttonHolder: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px !important"
  },
  selectedPage: {
    color: "#155794",
    backgroundColor: "rgba(0, 0, 0, 0.03)"
  },
  pageBtn: {
    fontSize: "14px",
    minWidth: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "15px",
    color: "#00000040",
    marginRight: "5px"
  },
  pagination: {
    padding: "0px 20px"
  },
  nextButton: {
    padding: "5px 0px"
  },
  clientInfoCancelButton: {
    color: "#fff",
    fontSize: "10px",
    fontWeight: 900,
    marginRight: "5px",
    backgroundColor: "#bcbcbc",
    height: "43px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#afadad"
    }
  },
  clientInfoButtonHolder: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    width: "320px",
    padding: "0px 5px"
  },
  pageNumbersHolder: {
    padding: "5px 0px",
    display: "flex",
    flex: 1,
    flexWrap: "no-wrap",
    overflowX: "auto"
  },
  clientInfoInput: {
    width: "100%"
  },
  clientInfoProjectList: {
    alignItems: "center",
    padding: "0px 5px",
    color: "#000000b3",
    fontSize: "14px",
    display: "flex",
    flex: 1
  },
  clientInfoSaveButton: {
    color: "#fff",
    fontSize: "10px",
    fontWeight: 900,
    backgroundColor: "#58b370",
    height: "43px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#4a965e"
    }
  },
  // ######[ styles for ClientEditSlider component ]########
  clientInfoRoot: {
    position: "relative",
    transition: "height 200ms linear",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 65px)",
    width: "400px",
    padding: "20px 30px"
  },
  clientInfoListHeader: {
    display: "flex",
    color: "#155794",
    fontSize: "14px",
    borderBottom: "1px solid #155794",
    margin: "12px 5px"
  },
  infoTitle: {
    fontSize: "14px",
    color: Colors.darkBlue,
    paddingLeft: "4px"
  },
  clientInfoInputItems: {
    padding: "5px 0 "
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 0",
    marginTop: "20px"
  }
});

// ############################################################################
// component export
// ############################################################################
export default withStyles(Styles)(ClientEditSlider);
