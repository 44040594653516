import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import PDatePicker from 'components/DatePicker/DatePicker';
import { ReactInput } from "../";
import { snackBarActions } from "../../actions";

// #####[ MATERIAL UI ]#########################################################

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";


// #############################################################################
// select styles
// #############################################################################

const selectStyles = {
  menu: (provided, state) => ({
    ...provided,
    minWidth: "350px",
    width: "350px",
  }),
}

// #############################################################################
// styles 
// #############################################################################
const styles = theme =>({
  root:{
    border: "1px solid #c2c2c2",
    borderRadius: "5px",
    display: "flex",
    padding: "5px"
  },

	input: {
    height: '38px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: '1px solid #d7d7d7',
    width: '100%',
    padding: '10px',
    resize: 'none',
    fontFamily: 'inherit',
    fontSize: "15px",
    color: "#000000c2"
  },
  inputContainer: {
		flex: 1,
		backgroundColor: "White",
		display: "flex",
		zIndex:2,
    padding: "2px",
    borderRadius:"3px"
  },
  textareaHolder:{
    padding:"4px"
  },
  textarea: {
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: '1px solid #d7d7d7',
    width: '100%',
    padding: '10px',
    resize: 'none',
    fontFamily: 'inherit',
    fontSize: '13px',
    height: '100%',
  },
  customButton: {
    backgroundColor: "#155794",
    width: "100%",
    height: "48px",
    fontSize: "0.8rem",
    color: "#fff",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#3f51b5"
    }
	},
  inputHolder:{
    padding: "4px"
  },
  buttonHolder:{
    padding: "4px"
  }
})

// #############################################################################
// component
// #############################################################################
class TaskForm extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
      projectId: this.props.projectId,
			description: "",
  		priority: "",
  		status: "",
      userId:"",
  		plannedStartDate: undefined,
  		plannedFinishDate: undefined,
      actualFinishDate: undefined,
      actualStartDate: undefined,
      dependencies: [],
  		name: "",
      parentId: this.props.parentId ? this.props.parentId : "null"
		}
	}

  // ###########################################################################
  // handle changes in the input
  // ###########################################################################

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value});
  }

  // ###########################################################################
  // handle changes in the select inputs
  // ###########################################################################

  handleSelectChange = (option, event) => {
    this.setState({ [event.name]: option });
  };

	// ###########################################################################
	// Decorate Priority
	// ###########################################################################

	decoratedPriorities = () => ([
    { value: "A", label: "Level1" },
    { value: "B", label: "Level2" },
    { value: "C", label: "Level3" },
    { value: "D", label: "Level4" },
    { value: "E", label: "Level5" }
	]);

	// ###########################################################################
	// list of all the statuses for the status select in task form
	// ###########################################################################

	decoratedStatuses = () => ([
		{value:'G',label:'Green'},
		{value:'O',label:'Orange'},
		{value:'Y',label:'Yellow'},
		{value:'R',label:'Red'}
	]);

  // ###########################################################################
  // clear state
  // ###########################################################################

  clearState = () =>{
    this.setState({
      description: "",
      priority: "",
      status: "",
      plannedStartDate: undefined,
      plannedFinishDate: undefined,
      actualFinishDate: undefined,
      actualStartDate: undefined,
      name: "",
      userId: "",
    });
  }

	// ###########################################################################
	// return list of users with label font he select component
	// ###########################################################################

	decorateProjectUsers = (projectUsers = []) => {
    return projectUsers.map(item => ({ value: item.user._id, label: `${item.user.firstName} ${item.user.lastName}` }));
  };

  // ###########################################################################
  // handle add task
  // ###########################################################################

  handleAddTask = event =>{
    event.preventDefault();
    const selectData = {
      priority: this.state.priority.value,
      status: this.state.status.value,
      userId: this.state.userId.value,
    }

    const dependencies = this.exportDependenciesValue(this.state.dependencies)

    if(this.checkFormInputs(this.state)) {
      this.props.handleAddTask({ ...this.state, ...this.trimText(), ...selectData, dependencies });
      this.clearState();
    } else this.props.dispatch(snackBarActions.snackBarWarning("Please Fill All Of The Form!"))
  }

  // ###########################################################################
  // this function will create a array of tasks ids
  // ###########################################################################

  exportDependenciesValue = (array) => {
    return array.map(item => (item.value))
  }

  // ###########################################################################
  // validates the form before submit
  // ###########################################################################

  checkFormInputs = (object = {}) => {
    let validation = true
    Object.keys(object).forEach(item => {
      if(object[item] === "" || object[item] === undefined) validation =  false;
    })
    return validation
  }

  // ###########################################################################
  // this function will trim the projectName and the description
  // ###########################################################################

  trimText = () => {
    return { name: this.state.name.trim(), description: this.state.description.trim() }
  }

  decorateTasksList = (array = [], labelName= "name", key = "_id") => {
    return array.map(item => ({label: item[labelName], value: item[key]}))
  }

  // ###########################################################################
  // render
  // ###########################################################################

	render = () => {

		const { classes, tasks = [] } = this.props
		const { description, priority, status, actualStartDate , plannedStartDate } = this.state;
		const { plannedFinishDate, name, userId, actualFinishDate, dependencies } = this.state;
		const decoratedUsers = this.decorateProjectUsers(this.props.usersInProject)

		return (
      <div className={classes.root}>
      <div className={classes.inputContainer}>
        <form  onSubmit={this.handleAddTask}>
          <Grid container className={classes.modalForm} >
            <Grid item xs={12} sm={8} container >
              <Grid item xs={12} md={3} className={classes.inputHolder}>
                <ReactInput
                  name="name"
                  placeholder="Task Name"
                  className={classes.input}
                  value={name}
                  onChange={this.handleChange}
                  required
                  autoComplete="off"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.inputHolder}>
                <Select
                  options={this.decoratedPriorities()}
                  name={"priority"}
                  onChange={this.handleSelectChange}
                  className={classes.select}
                  required
                  placeholder="Priority"
                  pageSize={5}
                  value={priority}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.inputHolder}>
                <Select
                  options={decoratedUsers}
                  styles={selectStyles}
                  name={"userId"}
                  onChange={this.handleSelectChange}
                  className={classes.select}
                  placeholder="Owner Of Task"
                  maxMenuHeight={120}
                  value={userId}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.inputHolder}>
              <Select
                  options={this.decoratedStatuses()}
                  name={"status"}
                  onChange={this.handleSelectChange}
                  className={classes.select}
                  required
                  placeholder="Status"
                  maxMenuHeight={120}
                  value={status}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.inputHolder}>
                <PDatePicker
                  onChange={date=>{this.setState({actualStartDate: date,plannedStartDate: date})}}
                  name="plannedStartDate"
                  selected={plannedStartDate}
                  placeholderText="Planned Start Date"
                  // maxDate={addDays(new Date(), 5)}
                  className={classes.input}
                  autoComplete = {"off"}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.inputHolder}>
                <PDatePicker
                  onChange={date=>{this.setState({actualFinishDate: date,plannedFinishDate: date})}}
                  name="plannedFinishDate"
                  selected={plannedFinishDate}
                  placeholderText="Planned Finish Date"
                  minDate={plannedStartDate}
                  // maxDate={addDays(new Date(), 5)}
                  className={classes.input}
                  autoComplete = {"off"}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.inputHolder}>
                <PDatePicker
                  onChange={date=>{this.setState({actualStartDate: date})}}
                  name="actualStartDate"
                  selected={actualStartDate}
                  placeholderText="Actual Start Date"
                  // minDate={new Date()}
                  // maxDate={addDays(new Date(), 5)}
                  className={classes.input}
                  autoComplete = {"off"}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.inputHolder}>
                <PDatePicker
                  onChange={date=>{this.setState({actualFinishDate: date})}}
                  name="actualFinishDate"
                  selected={actualFinishDate}
                  placeholderText="Actual Finish Date"
                  minDate={actualStartDate}
                  // maxDate={addDays(new Date(), 5)}
                  className={classes.input}
                  autoComplete = {"off"}
                />
              </Grid>
              <Grid item xs={12} className={classes.inputHolder}>
                <Select
                  options={this.decorateTasksList(tasks)}
                  name="dependencies"
                  onChange={this.handleSelectChange}
                  className={classes.select}
                  isMulti
                  required
                  placeholder="Dependencies"
                  pageSize={7}
                  value={dependencies}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.textareaHolder}>
              <textarea
                name="description"
                placeholder="Description"
                className={classes.textarea}
                value={description}
                onChange={this.handleChange}
                autoComplete="off"
                type="text"
                rows={3}
              />
            </Grid>
            <Grid item xs={12} sm={1} className={classes.buttonHolder}>
                <Button type="submit" className={classes.customButton} style={{height:'100%'}}>
                  {"Add Task"}
                </Button>
              </Grid>
          </Grid>
        </form>
      </div>
    </div>
    );
	}
};



// #############################################################################
// connect redux states and css-js to the component
// #############################################################################
const mapStateToProps = state => ({
  usersInProject: state.projects.users
});

const connectedTaskForm = connect(mapStateToProps)(TaskForm);
const TaskFormWithStyles = withStyles(styles)(connectedTaskForm) 
// #############################################################################
// export 
// #############################################################################
export {TaskFormWithStyles as  TaskForm}