import { userConstants, clientConstants } from "../constants";

export function companies(state = [], action) {
  switch (action.type) {
    //Get COMPANY
    case clientConstants.GET_ALL_COMPANIES_SUCCESS:
      return action.payload;
    case userConstants.GET_ALL_COMPANIES_FAILURE:
      return state;

    //Add COMPANY
    case clientConstants.ADD_COMPANY_SUCCESS:
      return [...state, action.payload];

    case clientConstants.ADD_COMPANY_FAILURE:
      return state;

    //EDIT USER
    case clientConstants.EDIT_COMPANY_SUCCESS: {
      return state.map(company =>
        company._id !== action.payload._id ? company : action.payload
      );
    }

    //DELETE COMPANY
    case clientConstants.DELETE_COMPANY_SUCCESS: {
      return state.filter(company => company._id !== action.payload._id);
    }

    case clientConstants.UPDATE_COMPANY_IMAGE_SUCCESS: {
      return state.map(client =>
        client._id !== action.payload._id ? client : action.payload
      );
    }

    case clientConstants.DELETE_COMPANY_IMAGE_SUCCESS: {
      return state.map(client =>
        client._id !== action.payload._id ? client : action.payload
      );
    }

    case clientConstants.CHANGE_CLIENT_ORDER_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
}
